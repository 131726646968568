import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.GET_COUPON_QUOTATION_ACTION](_, payload) {
    let quotation = await api.getCouponQuotation(payload);
    return quotation.data;
  },

  async [types.GET_COUPON_AGENTS_ACTION](_, payload) {
    let agents = await api.getAgents(payload);
    return agents.data;
  },
  async [types.GET_GENERATED_COUPONS_ACTION]({ commit }, payload) {
    let generated_coupons = await api.getGeneratedCoupons(payload);
    commit(types.SET_GENERATED_COUPONS_LOG, generated_coupons.data);
    commit(types.SET_GENERATED_COUPONS_META, generated_coupons.meta);
    return generated_coupons;
  },

  async [types.REQUEST_COUPON_ACTION](_, payload) {
    let coupon = await api.requestCoupon(payload);
    return coupon.data;
  },

  async [types.INITIATE_COUPON_PAYMENT_ACTION](_, payload) {
    let coupon_payment = await api.initiateCouponPayment(payload);
    return coupon_payment.data;
  },
  async [types.DOWNLOAD_COUPON_CODES_ACTION](_, generated_coupon) {
    let response = (await api.downloadCouponCodes(generated_coupon)).data;
    return response;
  },
  async [types.VERIFY_COUPON_CODE_ACTION](_, payload) {
    let response = (await api.verifyCouponCode(payload)).data;
    return response;
  },
  async [types.EXPORT_GENERATED_COUPONS_ACTION](_, payload) {
    let response = await api.exportGeneratedCoupons(payload);
    return response;
  },
  async [types.SEARCH_COUPON_BY_REFERENCE](_, agent_id) {
    let response = (await api.searchCouponByReference(agent_id)).data;
    return response;
  }
};

export default actions;
