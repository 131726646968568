import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  verifications_log: [],
  verifications_meta: null,
  verification_report: null,
  infraction_photos: []
};

export default {
  state,
  actions,
  getters,
  mutations
};
