import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.SUBMIT_CONTACT_FORM_ACTION](_, payload) {
    let response = (await api.submitContactForm(payload)).data;
    return response;
  }
};

export default actions;
