import http from "@/helpers/http";
// import { myApp } from "@/main";

class Api {
  static get axios() {
    return http;
  }

  static getTransactions(payload) {
    if (payload && payload.status) {
      return http.get("/transactions", {
        params: payload
      });
    }
    return http.get("/transactions", {
      params: { page: payload.page }
    });
  }

  static searchTransactions(payload) {
    return http.get("/transactions/search", {
      params: {
        query: payload.query,
        page: payload.page,
        paginate: 1
      }
    });
  }

  static getTransaction(payload) {
    let { transaction_id } = payload;
    return http.get("/transactions/" + transaction_id);
  }

  static reconcileTransaction(payload) {
    let { transaction, transaction_amount } = payload;
    let amount_in_kobo = parseInt(transaction_amount) * 100;
    return http.post("transactions/" + transaction.id + "/reconcile", {
      amount_in_kobo
    });
  }

  static exportTransactions(payload) {
    return http.get("/transactions/export", { params: payload });
  }
}

export default Api;
