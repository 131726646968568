const getters = {
  getPermitsLog: state => {
    return state.permits_log;
  },
  getPermitsLogMeta: state => {
    return state.permits_meta;
  },
  getPermitsLogPagination: state => {
    return state.permits_meta && state.permits_meta.pagination;
  }
};

export default getters;
