<template>
  <div>
    <div class="form-group">
      <div class="alert alert-info" role="alert">
        The address specified below should be your residential address
      </div>
    </div>

    <form @submit.prevent="updateAgentAddress">
      <div class="row">
        <!--        <div class="col-md-6">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">Flat Number</label>-->
        <!--            <div class="col-md-12">-->
        <!--              <input-->
        <!--                      type="text"-->
        <!--                      class="form-control rounded-0"-->
        <!--                      placeholder="Room 5"-->
        <!--                      v-model.trim="flat_number"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="col-md-6">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">Building Name</label>-->
        <!--            <div class="col-md-12">-->
        <!--              <input-->
        <!--                      type="text"-->
        <!--                      class="form-control rounded-0"-->
        <!--                      placeholder="1004 building"-->
        <!--                      v-model.trim="building_name"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="col-md-6">
          <label class="col-md-12 font-bold form-label">
            Address
            <span class="text-danger">*</span>
            <span
              class="mdi mdi-help-circle-o ml-1"
              v-tooltip="
                'A building or place that is easily seen and recognized from a distance, especially one that enables someone to establish the location'
              "
            ></span>
          </label>
          <div class="col-md-12">
            <vue-google-autocomplete
              ref="address"
              id="map"
              classname="form-control"
              placeholder="Start typing"
              v-on:placechanged="getAddressData"
              v-on:inputChange="setAddress"
              :country="['ng']"
              :value="address"
            >
            </vue-google-autocomplete>
          </div>
        </div>

        <!--        <div class="col-md-6">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">Sub-street</label>-->
        <!--            <div class="col-md-12">-->
        <!--              <input-->
        <!--                      type="text"-->
        <!--                      class="form-control rounded-0"-->
        <!--                      placeholder="Off Esther street"-->
        <!--                      v-model.trim="sub_street"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="col-md-6">
          <div class="form-group m-b-10">
            <label class="col-md-12 font-bold form-label">
              Landmark
              <span class="text-danger">*</span>
              <span
                class="mdi mdi-help-circle-o ml-1"
                v-tooltip="
                  'A building or place that is easily seen and recognized from a distance, especially one that enables someone to establish the location'
                "
              ></span>
            </label>
            <div class="col-md-12">
              <input
                type="text"
                class="form-control rounded-0"
                placeholder="Opposite Ilupeju police station"
                v-model.trim="landmark"
                :class="{ 'form-error animated shake': $v.landmark.$error }"
                @input="$v.landmark.$touch"
              />
            </div>
          </div>
        </div>

        <!--        <div class="col-md-6">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">Postcode</label>-->
        <!--            <div class="col-md-12">-->
        <!--              <input-->
        <!--                      type="text"-->
        <!--                      class="form-control rounded-0"-->
        <!--                      placeholder="10010"-->
        <!--                      v-model.trim="postcode"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="col-md-6">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">-->
        <!--              Area-->
        <!--              <span class="text-danger">*</span>-->
        <!--              <span-->
        <!--                      class="mdi mdi-help-circle-o ml-1"-->
        <!--                      v-tooltip="-->
        <!--                'A very common name in which the region of the location is referred to'-->
        <!--              "-->
        <!--              ></span>-->
        <!--            </label>-->
        <!--            <div class="col-md-12">-->
        <!--              <input-->
        <!--                      type="text"-->
        <!--                      class="form-control rounded-0"-->
        <!--                      placeholder="Ilupeju"-->
        <!--                      v-model.trim="city"-->
        <!--                      :class="{ 'form-error animated shake': $v.city.$error }"-->
        <!--                      @input="$v.city.$touch"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="col-md-6">
          <div class="form-group m-b-10">
            <label class="col-md-12 font-bold form-label">
              LCA/LCDA
              <span class="text-danger">*</span>
            </label>
            <div class="col-md-12">
              <select
                v-model.trim="region"
                :class="{ 'form-error animated shake': $v.region.$error }"
                @change="$v.region.$touch"
                class="form-control rounded-0"
              >
                <option value="" disabled>-- select LGA/LCDA --</option>
                <option
                  v-for="region in regions"
                  :key="region"
                  :value="region"
                  >{{ region }}</option
                >
              </select>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group m-b-10">
            <label class="col-md-12 font-bold form-label">
              State
              <span class="text-danger">*</span>
            </label>
            <div class="col-md-12">
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="state"
                :class="{ 'form-error animated shake': $v.state.$error }"
                @change="$v.state.$touch"
              >
                <!--                <option value>&#45;&#45; select state &#45;&#45;</option>-->
                <!--                <option value="Abia">Abia</option>-->
                <!--                <option value="Adamawa">Adamawa</option>-->
                <!--                <option value="Akwa Ibom">Akwa Ibom</option>-->
                <!--                <option value="Anambra">Anambra</option>-->
                <!--                <option value="Bauchi">Bauchi</option>-->
                <!--                <option value="Bayelsa">Bayelsa</option>-->
                <!--                <option value="Benue">Benue</option>-->
                <!--                <option value="Borno">Borno</option>-->
                <!--                <option value="Cross River">Cross River</option>-->
                <!--                <option value="Delta">Delta</option>-->
                <!--                <option value="Ebonyi">Ebonyi</option>-->
                <!--                <option value="Edo">Edo</option>-->
                <!--                <option value="Ekiti">Ekiti</option>-->
                <!--                <option value="Enugu">Enugu</option>-->
                <!--                <option value="Abuja">FCT-Abuja</option>-->
                <!--                <option value="Gombe">Gombe</option>-->
                <!--                <option value="Imo">Imo</option>-->
                <!--                <option value="Jigawa">Jigawa</option>-->
                <!--                <option value="Kaduna">Kaduna</option>-->
                <!--                <option value="Kano">Kano</option>-->
                <!--                <option value="Kastina">Kastina</option>-->
                <!--                <option value="Kebbi">Kebbi</option>-->
                <!--                <option value="Kogi">Kogi</option>-->
                <!--                <option value="Kwara">Kwara</option>-->
                <option value="Lagos">Lagos</option>
                <!--                <option value="Nasarawa">Nasarawa</option>-->
                <!--                <option value="Niger">Niger</option>-->
                <!--                <option value="Ogun">Ogun</option>-->
                <!--                <option value="Ondo">Ondo</option>-->
                <!--                <option value="Osun">Osun</option>-->
                <!--                <option value="Oyo">Oyo</option>-->
                <!--                <option value="Plateau">Plateau</option>-->
                <!--                <option value="Rivers">Rivers</option>-->
                <!--                <option value="Sokoto">Sokoto</option>-->
                <!--                <option value="Taraba">Taraba</option>-->
                <!--                <option value="Yobe">Yobe</option>-->
                <!--                <option value="Zamfara">Zamfara</option>-->
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group m-b-10">
            <label class="col-md-12 font-bold form-label">
              Country
              <span class="text-danger">*</span>
            </label>
            <div class="col-md-12">
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="country"
              >
                <option value="Nigeria">Nigeria</option>
              </select>
            </div>
          </div>
        </div>
        <!--        <div class="col-md-12">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">-->
        <!--              NIPOST Digital Address-->
        <!--              <img-->
        <!--                      src="/static/nipost_logo.png"-->
        <!--                      class="d-inline-block w-auto ml-1"-->
        <!--                      style="height: 14px;"-->
        <!--              />-->
        <!--            </label>-->

        <!--            <div class="col-md-12">-->
        <!--              <input-->
        <!--                      type="text"-->
        <!--                      class="form-control rounded-0"-->
        <!--                      placeholder="e.g. ABCD-123456"-->
        <!--                      v-model.trim="nipost_address"-->
        <!--                      :class="{ 'form-error animated shake': $v.nipost_address.$error }"-->
        <!--                      @input="$v.nipost_address.$touch"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->

        <!--        <div class="col-md-12">-->
        <!--          <div class="form-group m-b-10">-->
        <!--            <label class="col-md-12 font-bold form-label">Description</label>-->
        <!--          </div>-->
        <!--          <div class="col-md-12">-->
        <!--          <textarea-->
        <!--                  rows="4"-->
        <!--                  placeholder="Task Description"-->
        <!--                  class="form-control rounded-0"-->
        <!--                  v-model.trim="description"-->
        <!--          ></textarea>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div class="row">
        <div class="col-md-6 m-t-10">
          <div class="form-group m-b-10">
            <div class="col-md-12 m-t-0">
              <router-link
                tag="button"
                :to="{ name: 'AgentAddressView' }"
                class="btn btn-outline-inverse waves-effect rounded-0 w-100"
              >
                <span class="mdi mdi-arrow-left-thick"></span>
                Back
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-md-6 m-t-10">
          <div class="form-group m-b-10">
            <div class="col-md-12 m-t-0">
              <button
                class="btn btn-warning waves-effect rounded-0 w-100"
                :disabled="
                  $v.$invalid ||
                    isLoading ||
                    verification.status === 'verified' ||
                    verification.status === 'pending'
                "
                type="submit"
              >
                Save & Continue
                <span
                  :class="{
                    'mdi mdi-loading mdi-spin': isLoading,
                    'mdi mdi-arrow-right-thick': !isLoading
                  }"
                ></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { minLength, required } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },
  data() {
    return {
      //description: "",

      //nipost_address: "",
      //flat_number: "",
      //building_name: "",
      building_number: "",
      landmark: "",
      address: "",
      latitude: "",
      longitude: "",
      street: "",
      //sub_street: "",
      state: "",
      //city: "",
      //postcode: "",
      country: "",
      region: "",
      isLoading: false
    };
  },
  validations() {
    return {
      address: {
        required,
        minLength: minLength(3)
      },
      landmark: {
        required
      },
      state: {
        required
      },
      region: {
        required,
        mustBeAValidRegion(region) {
          return this.regions.includes(region);
        }
      },
      // city: {
      //   required
      // },
      country: {
        required
      }
      // nipost_address: this.nipost_address
      //   ? {
      //       isValidNipostAddress
      //     }
      //   : {}
    };
  },
  mounted() {
    this.latitude = this.verification.residential_address.latitude;
    this.longitude = this.verification.residential_address.longitude;
    this.description = this.verification.residential_address.description;
    this.nipost_address = this.verification.residential_address.nipost_address;
    this.flat_number = this.verification.residential_address.flat_number;
    this.building_name = this.verification.residential_address.building_name;
    this.building_number = this.verification.residential_address.building_number;
    this.landmark = this.verification.residential_address.landmark;
    this.address = this.verification.residential_address.address;
    this.address = this.verification.residential_address.address;
    this.street = this.verification.residential_address.street;
    this.sub_street = this.verification.residential_address.sub_street;
    this.state = this.verification.residential_address.state || "Lagos";
    this.city = this.verification.residential_address.city;
    this.region = this.verification.residential_address.region || "";
    this.postcode = this.verification.residential_address.postcode;
    this.country = this.verification.residential_address.country || "Nigeria";
  },
  methods: {
    setAddress({ newVal }) {
      this.address = newVal;
      this.latitude = null;
      this.longitude = null;
    },
    updateAgentAddress() {
      let vm = this;
      vm.$v.$touch();
      if (
        vm.$v.$invalid ||
        vm.isLoading ||
        vm.verification.status === "verified" ||
        vm.verification.status === "pending"
      ) {
        return;
      }
      vm.isLoading = true;

      vm.$store
        .dispatch(types.UPDATE_AGENT_RESIDENTIAL_ADDRESS_ACTION, {
          ...vm.$data,
          verification_id: vm.$route.params.verification_id
        })
        .then(verification => {
          vm.$notify.success({
            message: "Agent residential address saved successfully"
          });
          vm.$router.push({
            name: "GuarantorInformationView",
            params: { verification_id: verification.id }
          });
        })
        .catch(error => {
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    getAddressData: function(addressData) {
      console.log(addressData);
      if ("street_number" in addressData) {
        this.address = `${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      } else {
        this.address = `${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      }
      this.latitude = addressData.latitude;
      this.longitude = addressData.longitude;
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification",
      regions: "getRequestVerificationRegions"
    })
  }
};
</script>
