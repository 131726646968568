<template>
  <footer class="footer text-center" :class="{ 'b-0': !isPageContentReady }">
    <div class="text-center transition" v-if="isPageContentReady">
      <a href="#">Terms</a>
      <a href="#">Privacy</a>
      <a href="#">Status</a>
      <a href="#">API Docs</a>
    </div>

    <div style="width: 100%; height: 45px;" v-if="!isPageContentReady">
      <content-loader
        style="width:100%;"
        :height="45"
        :width="width"
        :speed="2"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect :x="rectMargin" y="10" rx="5" ry="5" :width="50" height="25" />
        <rect
          :x="rectMargin + 50 + 20"
          y="10"
          rx="5"
          ry="5"
          :width="50"
          height="25"
        />
        <rect
          :x="rectMargin + 50 + 20 + 50 + 20"
          y="10"
          rx="5"
          ry="5"
          :width="50"
          height="25"
        />
        <rect
          :x="rectMargin + 50 + 20 + 50 + 20 + 50 + 20"
          y="10"
          rx="5"
          ry="5"
          :width="50"
          height="25"
        />
      </content-loader>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { ContentLoader } from "vue-content-loader";

export default {
  name: "app-footer",
  data() {
    return {
      currentYear: moment().format("YYYY"),
      width: 0,
      rectMargin: 0
    };
  },
  components: {
    ContentLoader
  },
  created() {
    let vm = this;
    vm.width = $(window).width();
    $(window).resize(function() {
      vm.width = $(window).width();
      vm.rectMargin = Math.floor(
        (vm.width - 50 - 50 - 50 - 50 - 20 - 20 - 20) / 2
      );
    });
  },
  computed: {
    ...mapGetters(["isPageContentReady"])
  }
};
</script>
