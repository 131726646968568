<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Get Started" />

      <!-- ============================================================== -->
      <!-- GetStartedContainer component -->
      <!-- ============================================================== -->
      <get-started></get-started>
    </div>
  </div>
</template>

<script>
import GetStarted from "./_components/GetStarted";
import AppTitle from "@/components/layouts/AppTitle";

export default {
  components: {
    AppTitle,
    GetStarted
  },
  computed: {},
  created() {},
  mounted() {}
};
</script>
