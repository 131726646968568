<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Search result for ..." />
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs customtab" role="tablist">
              <li class="nav-item">
                <a
                  href="#reports-search"
                  class="nav-link active"
                  role="tab"
                  data-toggle="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-file-chart"></i>
                  </span>
                  <span class="hidden-xs-down">Reports (0)</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  href="#agents-search"
                  class="nav-link"
                  role="tab"
                  data-toggle="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-card-account-details"></i>
                  </span>
                  <span class="hidden-xs-down">Agents (0)</span>
                </a>
              </li>
            </ul>
            <!-- Tab panes -->
            <!-- /content -->
            <!-- ============================================================== -->
            <!-- Router view -->
            <!-- ============================================================== -->
            <div class="tab-content m-t-15">
              <div
                class="tab-pane active"
                id="reports-search"
                role="tabpanel"
                aria-expanded="false"
              >
                <reports-search-result></reports-search-result>
              </div>
              <div
                class="tab-pane"
                id="agents-search"
                role="tabpanel"
                aria-expanded="true"
              >
                <agents-search-result></agents-search-result>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import ReportsSearchResult from "./_components/ReportsSearchResult";
import AgentsSearchResult from "./_components/AgentsSearchResult";
export default {
  name: "search",
  components: {
    AppTitle,
    ReportsSearchResult,
    AgentsSearchResult
  },
  created() {}
};
</script>
