<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 500px;">
      <div class="card-block form-horizontal text-center">
        <template v-if="verified">
          <h1>Email Verification Successful</h1>
          <i
            class="text-success mdi mdi-check-circle-outline ml-1 display-1"
          ></i>
        </template>
        <template v-if="!verified">
          <div class="form-group text-center">
            <h4 class="m-b-20">Token Not Valid</h4>
            <!-- <i class="text-danger mdi mdi-cancel ml-1 display-1"></i> -->
            <p class="font-14">
              Hey there, you're trying to verify your email address with a token
              that has already been used or is invalid.
              <br />
              <br />If you're not sure, please click the link sent to your email
              address or check your agent profile using
              <router-link :to="{ name: 'AgentProfileLogin' }"
                >this link</router-link
              >.
              <br />
              <br />Thank you.
            </p>
          </div>
          <div class="form-group m-b-0">
            <div class="col-sm-12 text-center">
              <router-link
                :to="{ name: 'HomeView' }"
                class="btn btn-warning btn-block"
                >Go Home</router-link
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";
import store from "@/store";

export default {
  name: "RequestVerificationVerifyEmailView",
  data() {
    return {
      verified: false
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.VERIFY_EMAIL_ADDRESS_ACTION, to.params.token);
      next(vm => {
        vm.verified = true;
      });
      return;
    } catch (error) {
      // error && error.data && notify.error({ message: error.data.message });
      next(vm => {
        vm.verified = false;
      });
      return;
    }
  }
};
</script>
