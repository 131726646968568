<template>
  <label :for="value" class="label" :class="{ 'opacity-50': disabled }">
    <slot name="label-before"></slot>
    <input
      :id="value"
      :checked="checked"
      type="checkbox"
      :value="value"
      :disabled="disabled"
      :required="required"
      class="input"
      @change="updateInput"
    />
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "InputCheckbox",
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    value: {
      type: [String, Boolean],
      default: undefined
    },
    modelValue: {
      type: [Array, Boolean],
      default: () => [],
      required: true
    },
    trueValue: {
      type: Boolean,
      default: true
    },
    falseValue: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    checked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      return this.modelValue === this.trueValue;
    }
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
    }
  }
};
</script>

<style scoped>
.label {
  display: flex;
  align-items: center;
  padding: none;
  color: inherit;
  gap: 0.75rem;
}
input {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  color: currentColor;
  border: 1px solid #5c5c5c;
  display: grid;
  border-radius: 0.25rem;
  place-content: center;
}
input[type="checkbox"]:checked {
  border: 1px solid rgb(255, 180, 46);
  background-color: rgb(255, 180, 46);
}
input[type="checkbox"]::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.75rem 0.75rem #fff;
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.section + .section {
  margin-top: 0.25rem;
}
.opacity-50 {
  opacity: 0.5;
}
</style>
