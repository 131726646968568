<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <h3 class="card-title">Generated Coupons</h3>
      <form class="filter-report-form">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="category" class="control-label">
                Category
                <span class="text-danger">*</span>
              </label>
              <select
                id="category"
                class="form-control custom-select rounded-0"
                v-model.trim="category"
                :class="{ 'form-error animated shake': $v.category.$error }"
              >
                <option value disabled>-- select category --</option>
                <option value="all">All</option>
                <option value="single">Single</option>
                <option value="multiple">Multiple</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label
                for="start_date"
                class="control-label"
                style="display:block"
              >
                Start Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <div class="clearfix">
                <flat-pickr
                  v-model.trim="start_date"
                  :class="{ 'form-error animated shake': $v.start_date.$error }"
                  class="form-control rounded-0"
                  :config="startDateConfig"
                  placeholder="Start Date"
                ></flat-pickr>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="end_date" class="control-label" style="display:block">
                End Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <!-- <div
                class="clearfix"
                v-tooltip="!start_date ? 'Please select a start date' : ''"
              > -->
              <!-- <input type="text" class="form-control" placeholder="End Date"> -->
              <flat-pickr
                v-model.trim="end_date"
                :class="{ 'form-error animated shake': $v.end_date.$error }"
                class="form-control rounded-0"
                :config="endDateConfig"
                placeholder="End Date"
              ></flat-pickr>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label class="control-label" style="display:block">&nbsp;</label>
              <button
                :disabled="isLoading || $v.$invalid"
                class="btn btn-warning normalize-filter-btn rounded-0 m-r-5"
                @click.stop.prevent="filterGeneratedCouponLogs"
              >
                <i class="mdi mdi-filter"></i>
                Filter
              </button>
              <span
                v-tooltip="'CSV will be sent to your email address'"
                v-if="$User.hasPermission('coupon-export')"
              >
                <button
                  :disabled="isLoading || $v.$invalid"
                  class="btn btn-warning normalize-filter-btn rounded-0"
                  @click.stop.prevent="
                    filterGeneratedCouponLogs({ exportCsv: true })
                  "
                >
                  <i class="mdi mdi-cloud-download"></i>
                  Export CSV
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <div class="p-t-10 p-b-10" v-if="isFilter">
            <label class="text-info font-normal font-14">
              Generated coupons log filtered by
              <span
                class="badge badge-pill badge-default mx-1"
                v-if="$route.query.category"
                ><strong>Category:</strong> {{ $route.query.category }}</span
              ><span v-if="$route.query.start_date || $route.query.end_date">
                between {{ startDateDefault | date }} and
                {{ endDateDefault | date }}
              </span>
            </label>
            <button
              @click.stop="clearFilter"
              class="ml-3 btn btn-outline-info btn-xs font-bold"
            >
              clear filter
            </button>
          </div>
          <div class="p-t-10 p-b-10" v-if="!isFilter">
            <label class="text-info font-medium font-14"
              >Showing all generated coupons
            </label>
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="table-responsive">
                <template v-if="generated_coupons.length > 0">
                  <table class="table table-hover">
                    <thead>
                      <tr class="link-row">
                        <th width="70" class="text-center">#</th>
                        <!-- <th>Category</th> -->
                        <!-- <th>Discount</th> -->
                        <th>Code</th>
                        <th class="text-nowrap">Used/Quantity</th>
                        <!-- <th class="text-nowrap">Initiated By</th> -->
                        <!-- <th>Status</th> -->
                        <!-- <th>Action</th> -->
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(generated_coupon, index) in generated_coupons"
                        class="link-row"
                        :class="
                          selectedCoupon &&
                          selectedCoupon.id === generated_coupon.id
                            ? 'text-success active'
                            : ''
                        "
                        :key="generated_coupon.id"
                        @click.prevent.stop="selectCoupon(generated_coupon.id)"
                      >
                        <td class="text-center">
                          {{ pagination.from + index }}
                        </td>
                        <!-- <td>
                          <span
                            class="label text-capitalize font-bold rounded-0"
                            :class="[generated_coupon.category === 'single'? 'label-light-megna':'label-light-info']"
                          >{{generated_coupon.category}}</span>
                        </td> -->
                        <!-- <td>
                          {{generated_coupon.coupon.discount_type === 'amount'?'₦':''}}{{generated_coupon.coupon.value |
                          amount}}{{generated_coupon.coupon.discount_type === 'percent'?'%':''}}
                        </td> -->
                        <td class="text-uppercase">
                          {{ generated_coupon.coupon.code
                          }}{{
                            generated_coupon.category === "multiple"
                              ? "-****"
                              : ""
                          }}
                        </td>
                        <td>
                          {{ generated_coupon.num_used | amount }}/{{
                            generated_coupon.quantity | amount
                          }}
                        </td>
                        <!-- <td class="text-capitalize">{{generated_coupon.user.first_name}} {{generated_coupon.user.middle_name}} {{generated_coupon.user.last_name}}</td> -->
                        <!-- <td>
                          <template v-if="generated_coupon.generated">
                            <span class="label label-light-success font-bold text-capitalize">Generated</span>
                          </template>
                          <template v-else>
                            <span class="label label-light-info font-bold text-capitalize">Pending</span>
                          </template>
                        </td> -->
                        <!-- <td class="text-capitalize">
                          <template v-if="!generated_coupon.paid && $route.name !== 'AdminCouponView'">
                            <button
                              @click.stop.prevent="payForCoupon(generated_coupon)"
                              v-if="$User.hasPermission('coupon-generate')"
                              class="btn btn-outline-success btn-sm m-1"
                            >Pay</button>
                          </template>
                          <template v-if="generated_coupon.generated && $User.hasPermission('coupon-download')">
                            <span v-tooltip="'CSV will be sent to your email address'">
                              <button
                                class="btn btn-outline-primary btn-sm m-1"
                                :disabled="isDownloading"
                                @click.stop.prevent="downloadCoupon(generated_coupon)"
                              >
                                Download CSV
                                <i
                                  class="mdi mdi-loading mdi-spin"
                                  v-if="isDownloading === index"
                                ></i>
                              </button>
                            </span>
                          </template>
                        </td> -->
                        <td class="text-nowrap">
                          {{ generated_coupon.created_at | long_date }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="p-30 test font-medium text-muted">
                    Showing {{ pagination.from }} to {{ pagination.to }} of
                    {{ pagination.total }} entries
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    <img
                      src="/static/empty.svg"
                      class="img-fluid mb-3"
                      alt="empty"
                      style="width: 50px;"
                    />
                    <p class="font-bold font-14">
                      There are no generated coupons to display
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-5" id="generated-coupon-table-details">
              <transition
                enter-active-class="animated fastest fadeIn"
                leave-active-class="animated fastest fadeOut"
                mode="out-in"
              >
                <div
                  class="card"
                  v-if="selectedCoupon"
                  :key="selectedCoupon.id"
                >
                  <div class="card-block">
                    <h3 class="card-title">
                      Coupon Details #{{
                        pagination.from + selectedCoupon.index
                      }}
                    </h3>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Status</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        <template v-if="selectedCoupon.generated">
                          <span
                            class="label label-light-success font-bold text-capitalize"
                            >Generated</span
                          >
                        </template>
                        <template v-else>
                          <span
                            class="label label-light-info font-bold text-capitalize"
                            >Pending</span
                          >
                        </template>
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Code</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{ selectedCoupon.coupon.code
                        }}{{
                          selectedCoupon.category === "multiple" ? "-****" : ""
                        }}
                        <span
                          class="label text-capitalize font-bold rounded-0 ml-1"
                          :class="[
                            selectedCoupon.category === 'single'
                              ? 'label-light-megna'
                              : 'label-light-info'
                          ]"
                          >{{ selectedCoupon.category }}</span
                        >
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Discount</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{
                          selectedCoupon.coupon.discount_type === "amount"
                            ? "₦"
                            : ""
                        }}{{ selectedCoupon.coupon.value | amount
                        }}{{
                          selectedCoupon.coupon.discount_type === "percent"
                            ? "%"
                            : ""
                        }}
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Used/Quantity</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14"
                        >{{ selectedCoupon.num_used | amount }}/{{
                          selectedCoupon.quantity | amount
                        }}</span
                      >
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Initiated By</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14 text-capitalize"
                        >{{ selectedCoupon.user.first_name }}
                        {{ selectedCoupon.user.middle_name }}
                        {{ selectedCoupon.user.last_name }}</span
                      >
                    </div>

                    <div class="col-xs-12">
                      <span class="font-bold font-14">Date</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">{{
                        selectedCoupon.created_at | long_date
                      }}</span>
                    </div>

                    <!-- <div class="col-xs-12">
                      <span class="font-bold font-14">Action</span>
                    </div> -->
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        <template
                          v-if="
                            !selectedCoupon.paid &&
                              $route.name !== 'AdminCouponView'
                          "
                        >
                          <button
                            @click.stop.prevent="payForCoupon(selectedCoupon)"
                            v-if="$User.hasPermission('coupon-generate')"
                            class="btn btn-outline-success btn-sm mr-1 px-4"
                          >
                            Pay
                          </button>
                        </template>
                        <template
                          v-if="
                            selectedCoupon.generated &&
                              $User.hasPermission('coupon-download')
                          "
                        >
                          <span
                            v-tooltip="'CSV will be sent to your email address'"
                          >
                            <button
                              class="btn btn-outline-primary btn-sm mr-1"
                              :disabled="isDownloading"
                              @click.stop.prevent="
                                downloadCoupon(selectedCoupon)
                              "
                            >
                              Download CSV
                              <!-- <i
                                  class="mdi mdi-loading mdi-spin"
                                  v-if="isDownloading === index"
                                ></i> -->
                            </button>
                          </span>
                        </template>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="card"
                  v-if="!selectedCoupon && generated_coupons.length > 0"
                >
                  <div class="card-block">
                    <div class="my-3 text-center">
                      <img
                        src="/static/select.svg"
                        class="img-fluid mb-3"
                        alt="select"
                        style="width: 50px;"
                      />
                      <p class="font-14 font-bold">
                        Select a coupon to view details
                      </p>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
          <div class="text-center m-t-20">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";

export default {
  name: "GeneratedCouponLogView",
  mounted() {
    eventBus.$on("lslb:coupon:refresh-generated-coupons-log", () => {
      this.getLogByPage(this.pagination.current_page);
    });
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      category: this.$route.query.category || "",
      start_date: this.$route.query.start_date || "",
      end_date: this.$route.query.end_date || "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      isLoading: false,
      isDownloading: false,
      selectedCouponId: ""
    };
  },
  validations: {
    category: { required },
    start_date: {},
    end_date: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      generated_coupons: "getGeneratedCouponsLog",
      pagination: "getGeneratedCouponsLogPagination",
      business_profile: "getBusinessProfile"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment(this.$store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        )
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return this.$route.query.category;
    },
    selectedCoupon() {
      let index;
      let selectedCoupon = this.generated_coupons.find((coupon, key) => {
        index = key;
        return coupon.id === this.selectedCouponId;
      });
      selectedCoupon && (selectedCoupon.index = index);
      return selectedCoupon;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterGeneratedCouponLogs(options = {}) {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let { start_date, end_date, category } = this;
          let { exportCsv } = options;
          if (exportCsv) {
            let response = await this.$store.dispatch(
              types.EXPORT_GENERATED_COUPONS_ACTION,
              {
                start_date: start_date || this.startDateDefault,
                end_date: end_date || this.endDateDefault,
                category
              }
            );
            this.$notify.success({
              message: response.message
            });
          } else {
            let query = {
              ...(category && category !== "all" && { category }),
              ...(start_date && { start_date }),
              ...(end_date && { end_date })
            };
            this.$router.push({
              name: this.$route.name,
              query
            });
          }
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      let { category, start_date, end_date } = this.$route.query;

      let query = {
        [Date.now()]: null,
        ...(category && { category }),
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date })
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    async clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.category = "";
        this.start_date = "";
        this.end_date = "";
        this.$v.$reset();
      });
    },
    payForCoupon(requested_coupon) {
      let quotation = {
        price_quote_in_kobo: parseInt(requested_coupon.amount) * 100,
        coupon: requested_coupon.coupon,
        quantity: requested_coupon.quantity,
        category: requested_coupon.category
      };
      if (requested_coupon.agent) {
        quotation.agent = requested_coupon.agent;
      }
      eventBus.$emit("lslb:coupon:coupon_quotation", quotation);
      eventBus.$emit("lslb:requested_coupon", requested_coupon);
      $("#get-coupon-quotation-modal").modal("show");
    },
    async downloadCoupon(generated_coupon) {
      try {
        this.isDownloading = true;
        await this.$store.dispatch(
          types.DOWNLOAD_COUPON_CODES_ACTION,
          generated_coupon
        );
        this.$notify.success({
          message: "Coupon generated CSV has been sent to your mail."
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isDownloading = false;
      }
    },
    selectCoupon(id) {
      let offset = $("#generated-coupon-table-details").offset();
      offset.top -= 100;
      $("html, body").animate({
        scrollTop: offset.top
      });
      this.selectedCouponId = id;
    }
  }
};
</script>
