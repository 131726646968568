<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="set-permit-fee-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="set-permit-fee-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Set Permit Fee</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="set-permit-fee-form">
            <div
              class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
              v-if="message"
            >
              {{ message }}
            </div>
            <div class="col-md-12 font-14">
              Current Price - ₦<span class="text-sm font-bold font-18 mb-2">{{
                (getVerificationPrice / 100) | amount
              }}</span>
            </div>
            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="fee" class="control-label form-label">
                  Fee
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="fee"
                  :class="{ 'form-error animated shake': $v.fee.$error }"
                  @input="$v.fee.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Permit Fee"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              @click.prevent="setPermitFee"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Set Permit Fee
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numeric, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  name: "SetPermitFeeModal",
  mounted() {
    $("#set-permit-fee-modal").on("shown.bs.modal", () => {
      setTimeout(() => {
        this.fee = this.getVerificationPrice / 100;
      }, 0);
    });
  },
  data() {
    return {
      fee: "",
      isLoading: false,
      message: ""
    };
  },
  validations: {
    fee: { required, numeric }
    // role: { required }
  },
  methods: {
    async setPermitFee() {
      //check if form is valid
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      //clear message if exists
      this.message = "";
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.SET_PERMIT_FEE_ACTION, {
          fee: this.fee
        });
        //eventBus.$emit("lslb:permit:refresh-permits-list");
        this.fee = "";
        // this.role = "";
        this.isLoading = false;
        this.$v.$reset();
        // this.message = "Permit fee set successfully";
        this.$notify.success({ message: "Permit fee set successfully" });

        $("#set-permit-fee-modal").modal("hide");
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    }
  },
  computed: {
    ...mapGetters(["getVerificationPrice"])
  }
};
</script>

<style scoped></style>
