<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="col-md-12 mb-3">
        <h3 class="font-bold" style="color: rgb(3, 27, 77);">
          Guarantor's Personal Information
        </h3>
      </div>
      <!--      <div class="col-md-6">-->
      <!--        <div class="form-group m-b-10">-->
      <!--          <label class="col-md-12 font-bold form-label">-->
      <!--            Guarantor's Picture-->
      <!--            <span class="text-danger">*</span>-->
      <!--            <span-->
      <!--              class="mdi mdi-help-circle-o ml-1"-->
      <!--              v-tooltip="-->
      <!--                'Uploaded photos should be with the sufficient brightness and the keep the face fully visible, please make sure the photo is as clear as possible.'-->
      <!--              "-->
      <!--            ></span>-->
      <!--          </label>-->
      <!--          <div class="col-md-12">-->
      <!--            <input-->
      <!--              type="file"-->
      <!--              data-max-file-size="15M"-->
      <!--              data-allowed-file-extensions="jpeg jpg png"-->
      <!--              data-height="100"-->
      <!--              data-show-loader="true"-->
      <!--              class="form-control rounded-0 dropify"-->
      <!--              @change="setImage"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Last Name or Surname
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="last_name"
              :class="{ 'form-error animated shake': $v.last_name.$error }"
              @input="$v.last_name.$touch"
              class="form-control rounded-0"
              placeholder="Enter Guarantor's last name or Surname"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">Middle Name</label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="middle_name"
              class="form-control rounded-0"
              placeholder="Enter Agent's middle name"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            First Name
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="first_name"
              :class="{ 'form-error animated shake': $v.first_name.$error }"
              @input="$v.first_name.$touch"
              class="form-control rounded-0"
              placeholder="Enter Guarantor's first name"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Email Address
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="email"
              v-model.trim="email"
              :class="{ 'form-error animated shake': $v.email.$error }"
              @input="$v.email.$touch"
              class="form-control rounded-0"
              placeholder="Enter Guarantor's email address"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Phone Number
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="mobile"
              :class="{ 'form-error animated shake': $v.mobile.$error }"
              @input="$v.mobile.$touch"
              class="form-control rounded-0"
              placeholder="Enter Guarantor's phone number"
            />
          </div>
        </div>
      </div>
      <!--      <div class="col-md-6 mb-3">-->
      <!--        <div class="form-group m-b-10">-->
      <!--          <label class="col-md-12 font-bold form-label"-->
      <!--            >Other Mobile Number</label-->
      <!--          >-->
      <!--          <div class="col-md-12">-->
      <!--            <input-->
      <!--              type="text"-->
      <!--              v-model.trim="other_mobile"-->
      <!--              :class="{ 'form-error animated shake': $v.other_mobile.$error }"-->
      <!--              @input="$v.other_mobile.$touch"-->
      <!--              class="form-control rounded-0"-->
      <!--              placeholder="Enter Other mobile number"-->
      <!--            />-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-md-12 mb-3">-->
      <!--        <h3 class="font-bold" style="color: rgb(3, 27, 77);">-->
      <!--          Guarantor's Identity Information-->
      <!--        </h3>-->
      <!--      </div>-->
      <!--      <div class="col-md-6 form-group m-b-10">-->
      <!--        <label class="col-md-12 font-bold form-label">-->
      <!--          Identity Type-->
      <!--          <span class="text-danger">*</span>-->
      <!--        </label>-->
      <!--        <div class="col-md-12">-->
      <!--          <select-->
      <!--            name="identity_type"-->
      <!--            id="identity_type"-->
      <!--            class="form-control rounded-0"-->
      <!--            :class="{ 'form-error animated shake': $v.identity_type.$error }"-->
      <!--            v-model.trim="identity_type"-->
      <!--            @change="$v.identity_type.$touch"-->
      <!--            required="required"-->
      <!--          >-->
      <!--            <option value="bvn">BVN</option>-->
      <!--            <option value="nin">NIN</option>-->
      <!--          </select>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="col-md-6 form-group m-b-10">-->
      <!--        <label class="col-md-12 font-bold form-label">-->
      <!--          {{-->
      <!--            identity_type === "bvn"-->
      <!--              ? "BVN"-->
      <!--              : identity_type === "nin"-->
      <!--              ? "NIN"-->
      <!--              : "Identity"-->
      <!--          }}-->
      <!--          Number-->
      <!--          <span class="text-danger">*</span>-->
      <!--        </label>-->
      <!--        <div class="col-md-12">-->
      <!--          <input-->
      <!--            class="form-control rounded-0"-->
      <!--            v-model.trim="identity_number"-->
      <!--            :class="{ 'form-error animated shake': $v.identity_number.$error }"-->
      <!--            @input="$v.identity_number.$touch"-->
      <!--            :placeholder="-->
      <!--              'Enter ' +-->
      <!--                (identity_type === 'bvn'-->
      <!--                  ? 'BVN'-->
      <!--                  : identity_type === 'nin'-->
      <!--                  ? 'NIN'-->
      <!--                  : 'Identity') +-->
      <!--                ' Number'-->
      <!--            "-->
      <!--          />-->
      <!--          <p class="font-12 mt-2">-->
      <!--            <template v-if="identity_type === 'nin'">-->
      <!--              To get your NIN, dial <span class="font-bold">*346#</span> from-->
      <!--              your registered mobile number.-->
      <!--            </template>-->
      <!--            <template v-if="identity_type === 'bvn'">-->
      <!--              To get your BVN, dial <span class="font-bold">*565*0#</span> from-->
      <!--              your registered mobile number.-->
      <!--            </template>-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="row">
      <div class="col-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-md-12 m-t-0">
            <router-link
              v-if="verification.guarantor_info.status !== 'needs_update'"
              tag="button"
              :to="{ name: 'AgentResidentialAddressView' }"
              class="btn btn-outline-inverse waves-effect rounded-0 w-100"
            >
              <span class="mdi mdi-arrow-left-thick"></span>
              Back
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-md-12 m-t-5">
            <button
              v-if="verification.guarantor_info.status === 'needs_update'"
              class="btn btn-warning waves-effect rounded-0 w-100"
              :disabled="$v.$invalid || isLoading"
              type="submit"
            >
              Update & Re-submit
              <span
                :class="{
                  'mdi mdi-loading mdi-spin': isLoading,
                  'mdi mdi-arrow-right-thick': !isLoading
                }"
              ></span>
            </button>
            <button
              v-else
              class="btn btn-warning waves-effect rounded-0 w-100"
              :disabled="
                $v.$invalid ||
                  isLoading ||
                  verification.status === 'verified' ||
                  verification.status === 'pending'
              "
              type="submit"
            >
              Save & Continue
              <span
                :class="{
                  'mdi mdi-loading mdi-spin': isLoading,
                  'mdi mdi-arrow-right-thick': !isLoading
                }"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  name: "GuarantorsInformation",
  data() {
    return {
      image: "",
      imageUploaded: false,
      photo: "",
      last_name: "",
      middle_name: "",
      first_name: "",
      email: "",
      mobile: "",
      other_mobile: "",
      identity_type: "bvn",
      identity_number: "",
      isLoading: false,
      dateConfig: {
        maxDate: moment()
          .subtract(18, "years")
          .format("YYYY-MM-DD")
      }
    };
  },
  mounted() {
    // initialize dropify
    let drEvent = $(".dropify").dropify({
      messages: {
        default: "Drag and drop a passport image here or click",
        replace: "Drag and drop or click to replace",
        remove: "REMOVE",
        error: "Ooops, something happened."
      }
    });

    drEvent.on("dropify.afterClear", () => {
      this.image = "";
      this.imageUploaded = false;
    });

    let photo =
      this.verification.guarantor_info.personal_info.photo_meta.url || "";
    // if (photo) {
    //   let dropifyDestroy = drEvent.data("dropify");
    //   if (dropifyDestroy.isDropified()) {
    //     dropifyDestroy.resetPreview();
    //     dropifyDestroy.setPreview(true, photo);
    //   }
    // }
    this.image = photo;
    this.imageUploaded = photo;
    //this.photo = photo;
    this.last_name = this.verification.guarantor_info.personal_info.last_name;
    this.middle_name = this.verification.guarantor_info.personal_info.middle_name;
    this.first_name = this.verification.guarantor_info.personal_info.first_name;
    this.email = this.verification.guarantor_info.personal_info.email;
    this.mobile = this.verification.guarantor_info.personal_info.mobile;
    //this.other_mobile = this.verification.guarantor_info.personal_info.other_mobile;
    // this.identity_type =
    //   this.verification.guarantor_info.identity_info.id_type || "bvn";
    // this.identity_number = this.verification.guarantor_info.identity_info.id_number;
  },
  validations: {
    // let validations = {
    // image: {
    //   required
    // },
    last_name: {
      required
    },
    first_name: {
      required
    },
    email: {
      required,
      email
    },
    mobile: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    }
    // other_mobile: {
    //   numeric,
    //   minLength: minLength(11),
    //   maxLength: maxLength(11)
    // },
    // identity_number: {
    //   required,
    //   numeric,
    //   minLength: minLength(10),
    //   maxLength: maxLength(11)
    // },
    // identity_type: {
    //   required,
    //   mustBeAValidType: function(value) {
    //     return ["nin", "bvn"].includes(value);
    //   }
    // }
  },
  methods: {
    async handleSubmit() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid || this.isLoading) {
          return;
        }
        if (
          this.verification.submitted &&
          this.verification.guarantor_info.status !== "needs_update"
        ) {
          return;
        }
        this.isLoading = true;

        // if (this.image && !this.imageUploaded) {
        //   await this.uploadPassport();
        //   if (this.verification.guarantor_info.status === "needs_update") {
        //     this.submitUpdatedGuarantorInformation();
        //   } else {
        //     this.updateGuarantorInformation();
        //   }
        // } else {
        //if (this.photo || this.imageUploaded) {
        if (this.verification.guarantor_info.status === "needs_update") {
          this.submitUpdatedGuarantorInformation();
        } else {
          this.updateGuarantorInformation();
        }
        //}
        //}
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    },
    setImage(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.$v.image.$touch();
        this.imageUploaded = false;
      }
    },
    async uploadPassport() {
      let passport = await this.$store.dispatch(
        types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION,
        {
          file: this.image
        }
      );
      this.photo = passport.location;
      this.imageUploaded = true;
    },
    updateGuarantorInformation() {
      let vm = this;
      let payload = { ...vm.$data };
      vm.$store
        .dispatch(types.UPDATE_GUARANTOR_INFORMATION_ACTION, {
          ...payload,
          verification_id: this.verification.id
        })
        .then(verification => {
          vm.$notify.success({
            message: "Guarantor information saved successfully"
          });
          vm.$router.push({
            name: "RequestVerificationPreviewView",
            params: { verification_id: verification.id }
          });
        })
        .catch(error => {
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    submitUpdatedGuarantorInformation() {
      let vm = this;

      this.$swal({
        title: "Are you sure?",
        text:
          "Please confirm that your Guarantor's information is up to date and accurate",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, let me review!"
      }).then(result => {
        if (result.value) {
          let payload = { ...vm.$data };
          vm.$store
            .dispatch(types.UPDATE_AND_SUBMIT_GUARANTOR_INFORMATION_ACTION, {
              ...payload,
              verification_id: this.verification.id
            })
            .then(() => {
              vm.$notify.success({
                message:
                  "Guarantor information updated and submitted successfully"
              });
              this.$router.push({ name: "FormSubmittedSuccessfullyView" });
            })
            .catch(error => {
              error &&
                error.data &&
                vm.$notify.error({
                  message: error.data.message
                });
            })
            .finally(() => {
              vm.isLoading = false;
            });
        } else {
          vm.isLoading = false;
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification"
    })
  }
};
</script>
