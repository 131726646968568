<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="remove-comment-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Remove Comment</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <div class="col-md-12">
            <p class="font-14">Are you sure you want to remove this comment?</p>
          </div>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-danger rounded-0 waves-effect p-10 w-100 p-10"
              @click="removeComment"
              :disabled="isLoading"
            >
              Yes, Remove Comment
              <i class="mdi mdi-loading mdi-spin ml-1" v-show="isLoading"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoveCommentModal",
  props: ["removeComment", "isLoading"]
};
</script>
