<template>
  <div id="plain-content" class="d-flex flex-column">
    <!-- <router-link :to="{ name: 'HomeView' }" class="logo-image-container">
      <img
        src="@/assets/images/new-lslb-logo.png"
        alt="logo"
        class="logo-image"
      />
    </router-link> -->
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: "app-content-plain"
};
</script>

<style lang="scss">
#plain-content {
  position: relative;
  min-height: 100vh;
  // padding-top: 82px;
  // padding-bottom: 40px;
  background-image: url("/static/lagos_state_logo-blurred.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>
