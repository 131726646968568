const getters = {
  getRequestVerification: state => state.request_verification,
  getRequestVerificationTransactions: state =>
    state.request_verification_transactions,
  getRequestVerificationRegions: state => state.request_verification_regions,
  getSuccessfulTransaction: state =>
    state.request_verification_transactions.find(
      transaction => transaction.status === "success"
    ),
  // getVerificationPrice: state => state.request_verification.base_price_in_kobo
  getVerificationPrice: state => state.request_verification_price
};

export default getters;
