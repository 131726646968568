<template>
  <div class="clearfix">
    <div class="col-xs-12">
      <span class="font-bold font-14">Status</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span
        class="text-muted font-14 font-bold text-capitalize text-nowrap"
        :class="[
          driver_license_info.status === 'verified'
            ? 'text-success'
            : driver_license_info.status === 'not_verified'
            ? 'text-danger'
            : driver_license_info.status === 'pending'
            ? 'text-warning'
            : driver_license_info.status === 'incomplete'
            ? 'text-primary'
            : ''
        ]"
      >
        {{ driver_license_info.status | remove_underscore }}
        <i
          class="mdi ml-1"
          :class="[
            driver_license_info.status === 'verified'
              ? 'mdi-check-circle'
              : driver_license_info.status === 'not_verified'
              ? 'mdi-alert'
              : driver_license_info.status === 'pending'
              ? 'mdi-clock'
              : driver_license_info.status === 'incomplete'
              ? 'mdi-alert-outline'
              : ''
          ]"
        ></i>
        <span
          v-if="driver_license_info.license_expired"
          class="label label-danger font-bold ml-1"
        >
          expired
        </span>
      </span>
      <p class="small" v-if="driver_license_info.reason">
        ({{ driver_license_info.reason }})
      </p>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="font-bold font-14">Driver Permit Photo</span>
    </div>
    <div class="col-xs-12">
      <a
        :href="[
          driver_license_info.photo
            ? driver_license_info.photo
            : '/static/avatar.png'
        ]"
        class="live-photo-popup"
        v-tooltip.right="'Click to enlarge image'"
      >
        <img
          class="img-responsive img-thumbnail font-12 bg-light d-block m-b-10"
          :src="[
            driver_license_info.photo
              ? driver_license_info.photo
              : '/static/avatar.png'
          ]"
          alt="Driver Permit Photo"
          style="width: 150px; height: 150px; object-fit: cover;"
          onerror="this.src='/static/license.jpg'"
        />
      </a>
    </div>
    <div class="col-xs-6">
      <div class="col-xs-12 m-b-10">
        <span class="font-bold font-14">Driver Permit Card Image</span>
      </div>
      <div class="col-xs-12">
        <a
          :href="[
            driver_license_info.license_image
              ? driver_license_info.license_image
              : '/static/license.jpg'
          ]"
          class="live-photo-popup"
          v-tooltip.right="'Click to enlarge image'"
        >
          <img
            class="img-responsive img-thumbnail font-12 bg-light d-block m-b-10"
            :src="[
              driver_license_info.license_image
                ? driver_license_info.license_image
                : '/static/license.jpg'
            ]"
            alt="Driver Permit Card Image"
            style="width: 25%"
            onerror="this.src='/static/license.jpg'"
          />
        </a>
      </div>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Driver Permit Number</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        driver_license_info.license_number || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Name on ID</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        full_name_id || "Not available"
      }}</span>
    </div>
    <template v-if="driver_license_info.mobile">
      <div class="col-xs-12">
        <span class="font-bold font-14">Phone Number</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">
          {{ driver_license_info.mobile }}
        </span>
      </div>
    </template>
    <template v-if="driver_license_info.dob">
      <div class="col-xs-12">
        <span class="font-bold font-14">Date of Birth</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.dob | date
        }}</span>
      </div>
    </template>
    <template v-if="driver_license_info.address">
      <div class="col-xs-12">
        <span class="font-bold font-14">Address</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14 text-capitalize">
          {{ driver_license_info.address }}
        </span>
      </div>
    </template>

    <div class="col-xs-12">
      <span class="font-bold font-14">
        Date of Registration/First State of Issuance
      </span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14"
        >{{ driver_license_info.date_of_reg | date }}/{{
          driver_license_info.first_state_of_issuance || "Not available"
        }}</span
      >
    </div>

    <template v-if="driver_license_info.application_first_issued_date">
      <div class="col-xs-12">
        <span class="font-bold font-14">Application First Issued Date</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.application_first_issued_date | date
        }}</span>
      </div>
    </template>
    <template v-if="driver_license_info.application_expiry_date">
      <div class="col-xs-12">
        <span class="font-bold font-14">Application Expiry Date</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.application_expiry_date | date
        }}</span>
      </div>
    </template>
    <template v-if="driver_license_info.date_of_expiry">
      <div class="col-xs-12">
        <span class="font-bold font-14">Application Expiry Date</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">
          {{ driver_license_info.date_of_expiry | date }}
        </span>
      </div>
    </template>

    <template v-if="driver_license_info.mother_maiden_name">
      <div class="col-xs-12">
        <span class="font-bold font-14">Mother's Maiden Name</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.mother_maiden_name || "Not available"
        }}</span>
      </div>
    </template>

    <template
      v-if="driver_license_info.state_of_origin || driver_license_info.lga"
    >
      <div class="col-xs-12">
        <span class="font-bold font-14">State of Origin/LGA</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">
          {{ driver_license_info.state_of_origin || "Not available" }}/{{
            driver_license_info.lga || "Not available"
          }}
        </span>
      </div>
    </template>
    <template v-if="driver_license_info.class_of_vehicle">
      <div class="col-xs-12">
        <span class="font-bold font-14">Class of Vehicle</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.class_of_vehicle || "Not available"
        }}</span>
      </div>
    </template>

    <template v-if="driver_license_info.class_of_vehicle_description">
      <div class="col-xs-12">
        <span class="font-bold font-14">Class of Vehicle Description</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.class_of_vehicle_description || "Not available"
        }}</span>
      </div>
    </template>

    <template v-if="driver_license_info.next_of_kin_phone">
      <div class="col-xs-12">
        <span class="font-bold font-14">Next-of-Kin Phone Number</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">{{
          driver_license_info.next_of_kin_phone || "Not available"
        }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: ["driver_license_info"],
  mounted() {
    this.$nextTick(() => {
      $(".live-photo-popup").magnificPopup({
        type: "image",
        closeOnContentClick: true,
        mainClass: "mfp-img-mobile mfp-with-zoom",
        image: {
          verticalFit: true
        },
        zoom: {
          enabled: true, // By default it's false, so don't forget to enable it
          duration: 300, // duration of the effect, in milliseconds
          easing: "ease-in-out", // CSS transition easing function
          // The "opener" function should return the element from which popup will be zoomed in
          // and to which popup will be scaled down
          // By defailt it looks for an image tag:
          opener: function(openerElement) {
            // openerElement is the element on which popup was initialized, in this case its <a> tag
            // you don't need to add "opener" option if this code matches your needs, it's defailt one.
            return openerElement.is("img")
              ? openerElement
              : openerElement.find("img");
          }
        }
      });
    });
  },
  computed: {
    full_name_id() {
      if (
        this.driver_license_info.first_name ||
        this.driver_license_info.middle_name ||
        this.driver_license_info.last_name
      ) {
        return (
          this.driver_license_info.first_name +
          " " +
          this.driver_license_info.middle_name +
          " " +
          this.driver_license_info.last_name.toUpperCase()
        );
      }
      return "Not available";
    }
  }
};
</script>
