var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container w-100 position-relative",staticStyle:{"z-index":"2"}},[_c('h1',{staticClass:"mt-5 text-center",staticStyle:{"color":"rgb(3, 27, 77)"}},[_vm._v(" Agent Profile ")]),_c('div',{staticClass:"row m-t-15 mb-5",staticStyle:{"margin-left":"10rem"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"no-card bordered-box",attrs:{"id":"report-box"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.75rem"}},[(
              _vm.$route.name === 'AgentDashboard' &&
                Object.keys(_vm.$route.query).length > 0
            )?_c('router-link',{staticClass:"btn btn-link text-dark",attrs:{"to":{
              name: 'AgentDashboard',
              params: { profile_id: _vm.agent_profile.id }
            }}},[_vm._v(" ← Back ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end align-items-center",staticStyle:{"flex":"1"}},[_c('div',{staticClass:"dropdown mr-4 position-relative",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"mdi mdi-24px mdi-bell-outline",attrs:{"id":"notificationBell","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}}),(_vm.unreadNotifications.length > 0)?_c('div',{staticClass:"badge badge-pill badge-danger position-absolute",staticStyle:{"right":"-8px"}},[_vm._v(" "+_vm._s(_vm.unreadNotifications.length)+" ")]):_vm._e(),_c('div',{staticClass:"dropdown-menu pull-left p-3",attrs:{"aria-labelledby":"notificationBell"}},[(_vm.agent_profile.notifications.length === 0)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("No notifications")])]):_vm._l((_vm.agent_profile.notifications),function(notification,i){return _c('div',{key:("notification-" + i),staticClass:"mb-3"},[_c('small',{staticClass:"text-gray-dark",staticStyle:{"line-height":"0.5"}},[_vm._v(_vm._s(_vm._f("date")(notification.created_at)))]),_c('div',[_vm._v(_vm._s(notification.message))]),(!notification.read)?_c('button',{staticClass:"btn btn-sm btn-link pl-0",attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.markNotificationAsRead(notification._id)}}},[_vm._v(" Mark as read ")]):_vm._e()])})],2)]),_c('div',[_c('router-link',{staticClass:"btn btn-sm btn-outline-danger font-bold",attrs:{"to":{
                  name: 'AgentProfileLogin'
                }}},[_c('i',{staticClass:"mdi mdi-logout mr-1"}),_vm._v(" Log out ")])],1)])],1)])])]),_c('ul',{staticClass:"nav nav-tabs customtab scrollable bg-light mb-3 d-md-none rounded",attrs:{"role":"tablist"}},_vm._l((_vm.navLinks),function(link){return _c('li',{key:link.title,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: link.linkName },"role":"tab"}},[_c('span',{staticClass:"mr-2",class:link.linkIcon}),_vm._v(" "+_vm._s(link.title)+" ")])],1)}),0),_c('div',{staticClass:"vtabs customvtab mx-auto w-100"},[_c('ul',{staticClass:"nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded",attrs:{"role":"tablist"}},_vm._l((_vm.navLinks),function(link){return _c('li',{key:link.title,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link d-flex",attrs:{"active-class":"active","to":{ name: link.linkName },"role":"tab","aria-expanded":"false"}},[_c('span',{staticClass:"mr-2",class:link.linkIcon}),_vm._v(" "+_vm._s(link.title)+" ")])],1)}),0),_c('div',{staticClass:"tab-content w-100 p-t-0"},[_c('div',{staticClass:"tab-pane active w-100",attrs:{"role":"tabpanel","aria-expanded":"true"}},[_c('div',{staticClass:"card bordered-box position-relative w-100"},[_c('div',{staticClass:"card-block w-100"},[(_vm.$route.name !== 'AgentCompanies')?_c('h2',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$route.name === "AgentApplications" ? "Applications" : _vm.$route.name === "AgentCompanies" ? null : _vm.$route.name === "AgentPayments" ? "Payment History" : _vm.$route.name === "AgentDashboard" ? _vm.$route.query.renew_permit ? "Current Renewal (1 Year)" : _vm.$route.query.permit_renewal ? "Permit Renewal" : "Dashboard" : _vm.$route.name === "AgentComments" ? "Comments" : null)+" ")]):_vm._e(),_c('router-view',_vm._b({},'router-view',_vm.sub_data,false))],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }