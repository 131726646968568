<template>
  <div>
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Manage User component -->
    <!-- ============================================================== -->
    <manage-user></manage-user>
    <!-- ============================================================== -->
    <!-- New User Modal -->
    <!-- ============================================================== -->
    <new-user-modal></new-user-modal>
    <!-- New User Modal -->
    <!-- ============================================================== -->
    <new-multiple-users-modal></new-multiple-users-modal>
    <!-- Multi User Action Modal -->
    <!-- ============================================================== -->
    <multi-user-action-modal></multi-user-action-modal>
  </div>
</template>

<script>
import ManageUser from "./_components/ManageUser";
import NewUserModal from "./_components/NewUserModal";
import NewMultipleUsersModal from "./_components/NewMultipleUsersModal";
import MultiUserActionModal from "./_components/MultiUserActionModal";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";

export default {
  name: "UsersComponent",
  components: {
    ManageUser,
    NewUserModal,
    NewMultipleUsersModal,
    MultiUserActionModal
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await Promise.all([
        store.dispatch(types.GET_ALL_USERS_ACTION, {
          page: 1
        }),
        store.dispatch(types.GET_ALL_ROLES_ACTION, true)
      ]);
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  beforeDestroy() {
    // $("#add-multiple-users-modal").modal("dispose");
    // $("#add-single-user-modal").modal("dispose");
    // $("#multi-user-modal").modal("dispose");

    $("#add-multiple-users-modal").modal("hide");
    $("#add-multiple-users-modal").on("hidden.bs.modal", function() {
      $("#add-multiple-users-modal").modal("dispose");
    });
    $("#add-single-user-modal").modal("hide");
    $("#add-single-user-modal").on("hidden.bs.modal", function() {
      $("#add-single-user-modal").modal("dispose");
    });
    $("#multi-user-modal").modal("hide");
    $("#multi-user-modal").on("hidden.bs.modal", function() {
      $("#multi-user-modal").modal("dispose");
    });
  }
};
</script>
