<template>
  <div class="row page-titles">
    <div class="col-md-6 col-8 align-self-center">
      <h3 class="text-themecolor m-b-0 m-t-0 app-title">{{ title }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    }
  }
};
</script>

<style lang="scss">
.app-title {
  // font-size: 24px;
  font-weight: 300;
  font-size: 2.2rem;
  color: #555 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
