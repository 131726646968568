<template>
  <!-- <div class="auth-container">
        <div class="login-box card">
            <div class="card-block auth-box-block"> -->
  <router-view></router-view>
  <!-- </div>
        </div>
    </div> -->
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style lang="scss">
.auth-box-block {
  box-shadow: 0 2px 16px 0 rgba(33, 43, 54, 0.2),
    0 31px 41px 0 rgba(33, 43, 54, 0.08);
}
</style>
