<template>
  <!-- <div
    class="h-100 mx-auto mt-5"
    style="max-width: 400px; padding-top: 5%;"
  >
  <div class="card bordered-box">-->
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 400px;">
      <form
        class="card-block form-horizontal form-material2"
        @submit.prevent="sendPasswordResetLink"
      >
        <div class="form-group">
          <div class="col-xs-12">
            <h3 class="text-center">Recover Password</h3>
            <p class="font-14 font-bold text-center">
              Enter your email and password reset instructions will be sent to
              you!
            </p>
          </div>
          <div
            class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
            v-if="message"
          >
            {{ message }}
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <input
              class="form-control rounded-0"
              type="text"
              placeholder="Email Address"
              :class="{ 'form-error animated shake': $v.email.$error }"
              v-model.trim="email"
              @input="$v.email.$touch"
            />
          </div>
        </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button
              :disabled="isLoading || $v.$invalid"
              class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
              type="submit"
            >
              Reset
              <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
            </button>
          </div>
        </div>

        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center">
            <router-link :to="{ name: 'LoginView' }" class="text-dark m-l-5">
              <b>Back to login</b>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";
export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      message: "",
      isLoading: false
    };
  },
  validations: {
    email: {
      required,
      email
    }
  },
  methods: {
    async sendPasswordResetLink() {
      if (!this.$v.$invalid) {
        this.isLoading = true;
        this.message = "";
        try {
          let response = await this.$store.dispatch(
            types.SEND_PASSWORD_RESET_LINK_ACTION,
            this.email
          );
          // notify.success({
          //   message: response.message
          // });
          this.message = response.message;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          error &&
            error.data &&
            notify.error({
              message: error.data.message
            });
        }
      }
    }
  }
};
</script>
