<template>
  <div
    class="flex-box d-flex flex-column justify-content-center align-items-center flex-grow w-100 p-3"
  >
    <div class="row w-100">
      <div class="col-6 d-none d-md-block">
        <img
          style="
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            object-fit: cover;"
          class="h-100 w-50"
          src="@/assets/images/lagos-bg.png"
          alt="Lagos BG"
        />
      </div>
      <div class="col-12 col-md-6 position-relative" style="z-index:2;">
        <div class="row text-center text-md-right">
          <div class="col-12 mb-5">
            <div class="reveal-wrapper">
              <div
                class="d-flex justify-content-center justify-content-md-end image-container"
              >
                <div class="align-self-end" style="width: 150px;">
                  <img
                    class="img-fluid align-bottom"
                    src="@/assets/images/new-lslb-logo.png"
                    alt="LSLB Logo"
                  />
                </div>
                <!--                <div style="width: 200px">
                  <img
                    class="img-fluid"
                    src="@/assets/images/lagos_state_logo.png"
                    alt="Lagos State Logo"
                  />
                </div>-->
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="reveal-wrapper">
              <h2 class="mb-3 font-bold">
                LSLGA Permit Portal
              </h2>
            </div>
            <div class="reveal-wrapper">
              <p>
                Apply to be an authorised gaming or casino agent in Lagos State
              </p>
            </div>
            <div class="reveal-wrapper">
              <router-link
                :to="{ name: 'RequestVerificationView' }"
                class="btn btn-warning btn-lg"
              >
                Register as an Agent
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlainHome"
};
</script>

<style lang="scss" scoped>
.reveal-wrapper {
  overflow: hidden;
  .image-container {
    transform: translateY(150%);
    animation: reveal 0.5s ease-in-out 0.5s forwards;
  }
  h2 {
    transform: translateY(150%);
    animation: reveal 0.5s ease-in-out 0.7s forwards;
  }
  p {
    transform: translateY(150%);
    animation: reveal 0.5s ease-in-out 0.8s forwards;
  }
  a {
    transform: translateY(150%);
    animation: reveal 0.5s ease-in-out 0.85s forwards;
  }
}

@keyframes reveal {
  to {
    transform: translateY(0);
  }
}
</style>
