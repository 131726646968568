const getters = {
  getCheckAgentProfile: state => {
    return state.check_agent_profile;
  },
  getCheckAgentVerifications: state => {
    return state.check_agent_profile && state.check_agent_profile.transactions;
  },
  getCheckAgentVerification: state => {
    return state.check_agent_profile && state.check_agent_profile.verification;
  },
  getAgentProfileReport: state => {
    return state.agent_profile_report;
  }

  // getTransactionLog: state => {
  //   return state.transactionLog;
  // }
};

export default getters;
