<template>
  <div>
    <div
      class="modal animated fade pulse_ modal-border"
      id="single-upload-modal"
      data-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="change-role-modal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-border-radius">
          <div class="modal-header b-0 p-l-30">
            <h3 class="modal-title text-center font-medium">Single upload</h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-b-0">
            <form @submit.prevent="handleSubmit">
              <div class="row">
                <div v-if="false" class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="permit-type" class="form-label control-label">
                      Permit Type
                    </label>
                    <select
                      class="form-control custom-select rounded-0"
                      id="permit-type"
                      v-model.trim="permit_type"
                      :class="{
                        'form-error animated shake': $v.permit_type.$error
                      }"
                    >
                      <option
                        v-for="permit in permits"
                        :key="permit.category_slug"
                        :value="permit.category_slug"
                        >{{ permit.category_name }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="col-md-12 my-2">
                  <div class="form-group m-b-10">
                    <label for="permit-type" class="form-label control-label">
                      Operator
                    </label>
                    <select
                      class="form-control custom-select rounded-0"
                      id="permit-type"
                      v-model.trim="operator"
                      :class="{
                        'form-error animated shake': $v.operator.$error
                      }"
                    >
                      <option value disabled>-- Select operator --</option>
                      <option
                        v-for="operator in operators"
                        :key="operator.id"
                        :value="operator.id"
                        >{{
                          operator.trade_name || operator.business_name
                        }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="col-12 my-2">
                  <label for="uploadPhoto" class="upload-photo">
                    <img
                      :src="photo.value || '/static/user.svg'"
                      alt="user photo"
                      class="rounded-circle"
                      style="width: 8rem; height: 8rem;"
                    />
                    <input
                      id="uploadPhoto"
                      accept="image/*"
                      type="file"
                      name="profile_picture"
                      class="sr-only"
                      @change="handleImgChange"
                    />
                    <img
                      src="/static/camera.svg"
                      alt="camera icon"
                      class="camera"
                    />
                  </label>
                  <span
                    v-if="photo.error"
                    class="text-sm block text-red-600 my-4 text-center text-danger font-14"
                    >{{ photo.error[0] }}</span
                  >
                </div>
                <div class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="first_name" class="font-bold form-label">
                      First Name
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      v-model.trim="first_name"
                      :class="{
                        'form-error animated shake': $v.first_name.$error
                      }"
                      @input="$v.first_name.$touch"
                      class="form-control rounded-0"
                      id="first_name"
                      placeholder="Enter First Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="first_name" class="font-bold form-label">
                      Last Name
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      v-model.trim="last_name"
                      :class="{
                        'form-error animated shake': $v.last_name.$error
                      }"
                      @input="$v.last_name.$touch"
                      class="form-control rounded-0"
                      id="last_name"
                      placeholder="Enter Last Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="first_name" class="font-bold form-label">
                      Middle Name
                    </label>
                    <input
                      type="text"
                      v-model.trim="middle_name"
                      class="form-control rounded-0"
                      id="middle_name"
                      placeholder="Enter Middle Name"
                    />
                  </div>
                </div>
                <div class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="phone" class="font-bold form-label">
                      Phone Number
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      v-model.trim="phone"
                      :class="{ 'form-error animated shake': $v.phone.$error }"
                      @input="$v.phone.$touch"
                      class="form-control rounded-0"
                      id="phone"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                </div>
                <div class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="email" class="font-bold form-label">
                      Email Address
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      v-model.trim="email"
                      :class="{
                        'form-error animated shake': $v.email.$error
                      }"
                      @input="$v.email.$touch"
                      class="form-control rounded-0"
                      id="email"
                      placeholder="Enter Email Address"
                    />
                  </div>
                </div>
                <div class="col-md-6 my-2">
                  <div class="form-group m-b-10">
                    <label for="nin" class="font-bold form-label">
                      NIN
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      v-model.trim="nin"
                      :class="{
                        'form-error animated shake': $v.nin.$error
                      }"
                      @input="$v.nin.$touch"
                      class="form-control rounded-0"
                      id="nin"
                      placeholder="Enter NIN  number"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group m-b-10">
                    <label for="shopAddress" class="font-bold form-label">
                      Shop Addresses</label
                    >
                    <span class="text-danger">*</span>
                    <vue-google-autocomplete
                      v-for="(_, index) in shop_addresses"
                      :key="index"
                      classname="form-control address-input-field my-1 z-9999"
                      :id="'shopAddress-' + index"
                      :placeholder="'Enter Shop Address ' + (Number(index) + 1)"
                      v-on:placechanged="getShopAddress"
                    >
                    </vue-google-autocomplete>
                    <span
                      v-if="shopAddressError"
                      class="text-sm block text-red-600 my-4 text-center text-danger font-14"
                      >{{ shopAddressError }}</span
                    >
                  </div>
                  <button
                    type="button"
                    class="btn-sm btn btn-outline-warning"
                    @click="handleAddAddress"
                  >
                    Add Address
                  </button>
                </div>
                <div class="col-md-12 my-3">
                  <div class="form-group">
                    <label
                      for="residentialAddress"
                      class="font-bold form-label"
                    >
                      Residential Address</label
                    >
                    <span class="text-danger">*</span>
                    <vue-google-autocomplete
                      id="residentialAddress"
                      classname="form-control address-input-field z-9999"
                      placeholder="Enter Residential Address"
                      v-on:placechanged="getResAddressData"
                    >
                    </vue-google-autocomplete>
                    <span
                      v-if="resAddressError"
                      class="text-sm block text-red-600 my-4 text-center text-danger font-14"
                      >{{ resAddressError }}</span
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer b-0">
            <div class="justify-content-end d-flex" style="flex-grow: 1">
              <button
                type="button"
                :disabled="$v.$invalid || isLoading"
                @click="handleSubmit"
                class="btn btn-warning rounded waves-effect p-10"
              >
                Onboard Agent
                <span v-if="isLoading">
                  <i class="mdi mdi-loading mdi-spin"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <success-modal />
    <error-modal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  required,
  minLength,
  maxLength,
  numeric,
  email
} from "vuelidate/lib/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import * as types from "@/store/types";
import SuccessModal from "./success-modal";
import ErrorModal from "./error-modal";

export default {
  components: { SuccessModal, ErrorModal, VueGoogleAutocomplete },
  props: {
    permits: Array,
    operators: Array
  },
  data() {
    return {
      isLoading: false,
      operator: "",
      permit_type: "online-sports-betting",
      first_name: "",
      last_name: "",
      middle_name: "",
      email: "",
      phone: "",
      nin: "",
      shop_addresses: { 0: { address: "" } },
      shopAddressError: null,
      resAddressError: null,
      res_address: { address: "" },
      addressCount: 0,
      photo: { value: "", error: null, file: null, link: "" }
    };
  },
  validations: {
    operator: {
      required
    },
    permit_type: {
      required
    },
    first_name: {
      required
    },
    last_name: {
      required
    },
    email: {
      required,
      email
    },
    phone: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    nin: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    res_address: {
      required
    }
  },
  computed: {
    ...mapGetters({
      agent_profile: "getAgentProfile"
    }),
    action() {
      if (this.bulk_upload_action === "renew_permit") {
        return types.DOWNLOAD_SAMPLE_BULK_RENEW_CSV;
      }
      return types.DOWNLOAD_SAMPLE_BULK_UPLOAD_CSV;
    }
  },
  methods: {
    validateImage(image) {
      const errs = [];
      const validTypes = ["image/png", "image/jpeg", "image/gif", "image/webp"];

      if (validTypes.every(type => image.type !== type)) {
        errs.push(`Only .png, .jpeg, .gif, .webp image types are allowed`);
      }
      if (image.size > 1024 * 1024 * 25) {
        errs.push(`Image must be less than 25mb`);
      }
      return errs;
    },
    handleImgChange(e) {
      this.photo.error = [];
      const file = e.target.files.item(0);
      if (file) {
        const errs = this.validateImage(file);
        if (errs.length > 0) {
          this.photo.error = errs;
          return;
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          this.photo.value = reader.result;
          this.photo.file = file;
        });
        reader.readAsDataURL(file);
      }
    },
    async handlePhotoUpload() {
      const formData = new FormData();
      formData.append("file", this.photo.file);

      try {
        const response = await this.$store.dispatch(
          types.UPLOAD_FILE,
          formData
        );
        this.photo.link = response.data.location;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    async handleSubmit() {
      const shops = Object.values(this.shop_addresses).filter(shop => shop.address);
      const business = this.operators.find(
        operator => operator.id === this.operator
      );
      const license_type = this.permits.find(
        permit => permit.category_slug === this.permit_type
      );

      if (!this.photo.file) {
        this.photo.error = [`Image is required`];
        return;
      }
      if (!shops.length) {
        this.shopAddressError = `Shop address is required`;
        return;
      }
      if (!this.res_address.address) {
        this.resAddressError = `Residential address is required`;
        return;
      }
      if (this.photo.file) {
        await this.handlePhotoUpload();
      }

      const payload = {
        business_id: business?.id,
        license_type: license_type?._id,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        mobile: this.phone,
        nin: this.nin,
        shop_addresses: shops,
        residential_address: this.res_address
      };

      if (this.middle_name) {
        payload["middle_name"] = this.middle_name;
      }

      if (this.photo.link) {
        payload["photo"] = this.photo.link;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch(types.SINGLE_AGENT_ONBOARD, payload);
        await this.$store.dispatch(types.GET_AGENTS_ACTION, {
          page: 1,
          status: "all",
          start_date: moment("2020-12-03T10:10:42.266Z").format("YYYY-MM-DD"),
          end_date: moment().format("YYYY-MM-DD")
        });
        this.resetForm();
        this.closeModal();
        this.isLoading = false;
        this.$notify.success({ message: "Agent successfully onboarded" });
        this.handleSuccessModal();
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
        this.closeModal();
        // this.handleErrorModal();
      }
    },
    handleAddAddress() {
      this.addressCount += 1;
      this.shop_addresses = {
        ...this.shop_addresses,
        [this.addressCount]: { address: "" }
      };
    },
    async updateAgentAddress() {
      if (!this.partners.length) {
        this.$notify.error({
          message: "Please select an operator in this address"
        });
      }
      if (!this.newAddress) {
        this.$notify.error({ message: "Please input an address" });
      }
      this.loading = true;
      try {
        await this.$store.dispatch(types.ADD_NEW_AGENT_ADDRESS, {
          business_id: this.partners[0],
          address: this.newAddress,
          latitude: this.newAddressLatitude,
          longitude: this.newAddressLongitude,
          verification_id: this.agentProfile.verification.id
        });
        this.newAddress = "";
        this.addNewAddress = false;
        this.currentRequest = null;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },
    getResAddressData(addressData) {
      if (addressData.street_number) {
        this.res_address = {
          address: `${addressData.street_number} ${addressData.route} ${addressData.locality} ${addressData.country}`
        };
      } else {
        this.res_address = {
          address: `${addressData.route} ${addressData.locality} ${addressData.country}`
        };
      }
    },
    getShopAddress(addressData, _, id) {
      const index = id.split("-")[1];
      if (addressData.street_number) {
        this.shop_addresses[index] = {
          address: `${addressData.street_number} ${addressData.route} ${addressData.locality} ${addressData.country}`
        };
      } else {
        this.shop_addresses[index] = {
          address: `${addressData.route} ${addressData.locality} ${addressData.country}`
        };
      }
    },
    resetForm() {
      this.permit_type = "online-sports-betting";
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.phone = "";
      this.nin = "";
      this.num_shops = 0;
      this.shop_address = "";
      this.res_address = "";
      this.photo = { value: "", error: null, file: null, link: "" };
      this.operator = "";
      this.$v.$reset();
    },
    handleSuccessModal() {
      $("#success-modal").modal("show");
    },
    handleErrorModal() {
      $("#error-modal").modal("show");
    },
    closeModal() {
      $("#single-upload-modal").modal("hide");
    }
  }
};
</script>

<style scoped>
.upload-photo {
  width: 120px;
  height: 120px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.upload-photo > .camera {
  position: absolute;
  bottom: 0.5rem;
  right: 0;
}

.z-9999 {
  z-index: 9999 !important;
}
</style>
