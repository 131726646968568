import * as types from "@/store/types";
const mutations = {
  async [types.SET_AGENTS_LOG](state, payload) {
    state.agents_log = payload;
  },
  async [types.SET_AGENTS_META](state, payload) {
    state.agents_meta = payload;
  },
  async [types.SET_AGENT_PROFILE](state, payload) {
    state.agent_profile = payload;
  },
  async [types.SET_AGENT_COMMENTS](state, payload) {
    state.agent_profile.comments = payload;
  }
};

export default mutations;
