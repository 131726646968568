<template>
  <div>
    <div v-if="(currentRequest === null) & (showDissociation === false)">
      <div class="row mb-3 justify-content-between align-items-center">
        <div class="col-xs-12 col-sm-7">
          <h2 class="mb-0">
            Operators
          </h2>
          <p>
            This table lists all the lottery companies you are associated with
          </p>
        </div>
        <div class="col-xs-12 col-sm-5">
          <div>
            <div
              @click="showSearchResults = !showSearchResults"
              v-if="showSearchResults"
              class="position-fixed"
              style="top: 0; left: 0; right: 0; bottom: 0"
            ></div>
            <p v-if="!selected_license_type" class="mb-1 text-danger font-12">
              Please select a category to filter by.
            </p>
            <div class="input-group">
              <div class="dropdown input-group-addon p-0 bg-warning">
                <span
                  class="btn btn-warning  color-white dropdown-toggle m-0"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {{
                    !selected_license_type
                      ? "Filter by"
                      : selected_license_type.category_name
                  }}
                </span>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <ul class="list-unstyled px-2">
                    <li
                      @click="
                        selected_license_type = license_type;
                        searchForCompaniesByLicense();
                      "
                      class="mb-2"
                      style="cursor: pointer"
                      v-for="license_type in license_types"
                      :key="license_type.category_slug"
                    >
                      {{ license_type.category_name }}
                    </li>
                  </ul>
                </div>
              </div>

              <div
                class="form-control form-control-sm position-relative"
                style=""
              >
                <input
                  type="text"
                  autocomplete="off"
                  class="w-100 "
                  id="stuff"
                  placeholder="Search for company"
                  style="border: 0"
                  v-model="query"
                  @input="searchForCompaniesByLicense"
                  :disabled="!selected_license_type"
                />
                <div
                  v-if="showSearchResults"
                  class="position-absolute w-100 rounded p-3"
                  style="top: 38px; left: 0; right: 0; background: white; box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important; overflow-y: auto; max-height: 200px"
                >
                  <div
                    v-if="companiesFromSearch.length === 0"
                    class="text-center"
                  >
                    <p>Oops, no results found</p>
                  </div>
                  <div v-else>
                    <div
                      v-for="(companyFromSearch, i) in companiesFromSearch"
                      :key="`company-from-search-${i}`"
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <h5>{{ companyFromSearch.trade_name }}</h5>
                      <template>
                        <button
                          class="btn btn-sm btn-outline-success"
                          v-if="
                            companies
                              .map(company => company.id)
                              .indexOf(companyFromSearch.id) === -1
                          "
                          :disabled="loading"
                          @click="
                            joinCompany(
                              companyFromSearch.id,
                              companyFromSearch.trade_name
                            )
                          "
                        >
                          Join
                        </button>
                        <i v-else
                          ><small style="font-weight: 200"
                            >Joined already</small
                          ></i
                        >
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <span
                :disabled="!selected_license_type || isSearching"
                @click="searchForCompaniesByLicense"
                class="input-group-addon btn btn-default"
                style="background: white"
              >
                <span
                  v-if="isSearching"
                  class="mdi mdi-spin mdi-loading"
                ></span>
                <span v-else class="mdi mdi-magnify"></span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="companies.length > 0">
        <div class="col-md-12">
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr class="link-row">
                  <th class="text-center">#</th>
                  <th class="text-nowrap">Operator Name</th>
                  <th>Date joined</th>
                  <!--                <th>Permit type</th>-->
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(company, index) in companies"
                  :key="company.id"
                  class=""
                  @click="sendToCompany(index)"
                >
                  <td class="text-center">{{ index + 1 }}</td>
                  <!--                <td class="text-center">-->
                  <!--                  <img-->
                  <!--                    src="@/assets/images/lslb_logo.png"-->
                  <!--                    alt=" Betway Logo"-->
                  <!--                    class="img-responsive"-->
                  <!--                    style="width: 50px;"-->
                  <!--                  />-->
                  <!--                </td>-->

                  <td>
                    {{
                      company.business &&
                        (company.business.trade_name ||
                          company.business.business_name)
                    }}
                  </td>
                  <td class="text-capitalize">
                    {{ company.created_at | date }}
                  </td>
                  <!--                <td class="text-capitalize">-->
                  <!--                  {{ company.permit_type }}-->
                  <!--                </td>-->
                  <td>
                    <!--                  {{company.status}}-->
                    <span
                      class="label text-capitalize font-bold rounded-0 text-nowrap"
                      :class="[
                        company.status === 'verified' ||
                        company.status === 'active'
                          ? 'label-light-success'
                          : company.status === 'not_verified'
                          ? 'label-light-danger'
                          : company.status === 'revoked'
                          ? 'label-light-danger'
                          : company.status === 'awaiting_acceptance'
                          ? 'label-light-info'
                          : company.status === 'pending'
                          ? 'label-light-warning'
                          : company.status === 'awaiting_dissociation'
                          ? 'label-light-warning'
                          : company.status === 'incomplete'
                          ? 'label-light-primary'
                          : ''
                      ]"
                      >{{ company.status | remove_underscore }}</span
                    >
                  </td>
                  <td class="d-flex">
                    <template v-if="company.status === 'awaiting_acceptance'">
                      <button
                        :disabled="loading"
                        @click="
                          showResolveAgentApplicationDialog(
                            company.business.id,
                            true
                          )
                        "
                        class="btn btn-success btn-xs"
                      >
                        Accept
                      </button>
                      <button
                        :disabled="loading"
                        @click="
                          showResolveAgentApplicationDialog(
                            company.business.id,
                            false
                          )
                        "
                        class="btn btn-danger btn-xs ml-1"
                      >
                        Reject
                      </button>
                    </template>
                    <template v-else-if="company.status === 'active'">
                      <span
                        class="label text-capitalize font-bold rounded-0 text-nowrap label-light-danger pointer"
                        @click.prevent.stop="
                          showDissociation = true;
                          currentDissociationReasonId = company.business.id;
                        "
                        >Dissociate</span
                      >
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p-30 test font-medium text-muted">
              <span
                >Showing 1 to {{ companies.length }} of
                {{ companies.length }} entries</span
              >
            </div>
          </div>
        </div>
      </div>

      <div v-else class="text-center">
        <img src="/static/empty.svg" class="w-25 img-fluid mb-3" alt="empty" />
        <p class="font-bold font-14">There are no companies to display</p>
      </div>
    </div>

    <div v-else-if="showDissociation === true">
      <div class="row mb-3 justify-content-between align-items-center">
        <div class="col-12 col-sm-7 col-xs-12 px-4">
          <h3 class="mb-0">
            Reason for Dissociation
          </h3>
          <small class="col-12 px-0">
            Why do you want to leave this lotto company?
          </small>
        </div>
        <div class="col-12 px-4">
          <textarea
            name=""
            id=""
            rows="10"
            class="p-4 mt-3"
            placeholder="They have too many stringent policies. "
            v-model="dissociationReason"
          ></textarea>
        </div>
        <div class="col-12 d-block d-sm-inline-flex px-4 mt-4">
          <button
            class="col-5 col-sm-3 px-0 py-2 text-center"
            style="background-color: #FFB42E; color: white"
            v-if="currentRequest"
            @click.prevent.stop="
              showDissociateCountryApplicationDialog(currentRequest.business.id)
            "
          >
            Submit
          </button>
          <button
            class="col-5 col-sm-3 px-0 py-2 text-center"
            style="background-color: #FFB42E; color: white"
            v-else
            @click.prevent.stop="
              showDissociateCountryApplicationDialog(
                currentDissociationReasonId
              )
            "
          >
            Submit
          </button>
          <button
            class="col-5 col-sm-3 ml-0 ml-md-4 px-0 py-2 text-center"
            style="border: 1px solid #404E5D;
                      border-radius: 3px;
                      background-color: white;
                      color: black"
            @click="showDissociation = false"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="row mb-3 justify-content-between align-items-center">
        <div class="col-12 col-sm-7 col-xs-12 px-4">
          <!--          <h3 class="mb-0">-->
          <!--            Operator-->
          <!--          </h3>-->
          <!--          <small class="col-12 px-0">-->
          <!--            This table lists all the lottery companies that you are associated-->
          <!--            with.-->
          <!--          </small>-->
          <h1 class="page-title mt-4">
            Operator
          </h1>
          <svg
            class="mb-0 pb-0"
            width="183"
            height="4"
            viewBox="0 0 183 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="2" x2="183" y2="2" stroke="#FFB42E" stroke-width="4" />
          </svg>
        </div>
        <div
          class="col-12 d-block d-md-inline-flex px-0 mt-2 info-section information-group"
        >
          <div class="col-12 col-md-6 px-0">
            <h4 class="col-12 info-title pt-2 px-4">Company Name:</h4>
            <h4 class="col-12 info-content font-weight-bold pt-1 pb-2 px-4">
              {{ currentRequest.business.business_name }}
            </h4>
          </div>
          <div class="col-12 col-md-6 px-0">
            <h4 class="col-12 info-title pt-2 px-4">Operator Name:</h4>
            <h4 class="col-12 info-content font-weight-bold pt-1 pb-2 px-4">
              {{ currentRequest.business.trade_name }}
            </h4>
          </div>
        </div>
        <div
          class="col-12 d-block d-md-inline-flex px-0 mt-2 info-section information-group"
        >
          <div class="col-12 col-md-6 px-0">
            <h4 class="col-12 info-title pt-2 px-4">Date I joined:</h4>
            <h4 class="col-12 info-content font-weight-bold pt-1 pb-2 px-4">
              {{ currentRequest.created_at.slice(0, 10) }}
            </h4>
          </div>
          <div class="col-12 col-md-6 px-0">
            <h4 class="col-12 info-title pt-2 px-4">Status:</h4>
            <div class="col-12">
              <h4
                class="info-content text-blue text-capitalize font-weight-bold pt-1 pb-2 px-4"
              >
                {{ currentRequest.status | remove_underscore }}
              </h4>
            </div>
          </div>
        </div>
        <div class="col-12 d-block d-md-inline-flex px-0 mt-2 info-section ">
          <div class="col-12 px-0">
            <h4 class="col-12 info-title font-weight-bold pt-2 px-4">
              My Shop address(es):
            </h4>
            <div
              class="col-12 px-4"
              v-for="(address, index) in currentRequest.shop_addresses"
              :key="index"
            >
              <div
                class="col-12 px-0 d-inline-flex information-group mb-4"
                v-if="!checkForEditing(address._id)"
              >
                <h5 class="pt-2">
                  {{ address.address }}
                </h5>
                <h6
                  class="label pointer text-capitalize font-bold rounded-0 text-nowrap ml-auto px-4 pt-1 mt-2"
                  style="background: #404E5D; border-radius: 6px; height: 20px"
                  @click="showAddressEdit(address)"
                >
                  Edit
                </h6>
                <h6
                  class="text-capitalize pointer font-bold ml-3 delete-button py-2 px-2"
                  @click="
                    deleteAddress(
                      currentRequest.business._id,
                      address._id,
                      index
                    )
                  "
                >
                  <img
                    src="../../assets/images/red-delete.svg"
                    width="15"
                    height="15"
                    class=""
                    alt=""
                  />
                </h6>
              </div>
              <div class="col-12 address-input p-4 mb-4" v-else>
                <vue-google-autocomplete
                  id="map1"
                  classname="form-control address-input-field"
                  :placeholder="editAddressContent"
                  v-on:placechanged="getAddressDataEdit"
                >
                </vue-google-autocomplete>
                <!--                <input
                  type="text"
                  class="address-input-field col-12 p-3"
                  placeholder="Enter new address"
                  v-model="editAddressContent"
                />-->
                <div class="col-12 d-block d-sm-inline-flex px-0 mt-4">
                  <button
                    class="col-5 col-sm-3 px-0 py-2 text-center"
                    style="background-color: #FFB42E; color: white"
                    @click="
                      updateEditAgentAddress(
                        currentRequest.business._id,
                        address._id
                      )
                    "
                  >
                    Submit
                  </button>
                  <button
                    class="col-5 col-sm-3 ml-0 ml-md-4 px-0 py-2 text-center"
                    style="border: 1px solid #404E5D;
                      box-sizing: border-box;
                      border-radius: 3px;
                      color: black"
                    @click="hideAddressEdit"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 address-input p-4 mb-4" v-if="addNewAddress">
              <vue-google-autocomplete
                id="map2"
                classname="form-control address-input-field"
                placeholder="Enter new address"
                v-on:placechanged="getAddressDataCreate"
              >
              </vue-google-autocomplete>
              <div class="col-12 d-block d-sm-inline-flex px-0 mt-4">
                <button
                  class="col-5 col-sm-3 px-0 py-2 text-center"
                  style="background-color: #FFB42E; color: white"
                  @click="updateAgentAddress(currentRequest.business._id)"
                >
                  Submit
                </button>
                <button
                  class="col-5 col-sm-3 ml-0 ml-md-4 px-0 py-2 text-center"
                  style="border: 1px solid #404E5D;
                      box-sizing: border-box;
                      border-radius: 3px;
                      color: black"
                  @click="addNewAddress = !addNewAddress"
                >
                  Cancel
                </button>
              </div>
            </div>
            <div class="col-12 px-4">
              <button
                class="col-12 px-0 py-2 text-center"
                style="background-color: #FFB42E; color: white"
                @click="addNewAddress = !addNewAddress"
                v-if="!addNewAddress"
              >
                <img src="../../assets/images/add.svg" alt="" />
                Add Address
              </button>
            </div>
            <div class="col-12 px-4 my-2">
              <img
                src="../../assets/images/zebra-line.svg"
                class="col-12 px-0"
                width="100%"
                alt=""
              />
            </div>
            <div class="col-12 px-4">
              <button
                class="col-12 px-0 py-2 text-center"
                style="background-color: transparent; border: 1px #FF5454 solid; color: #FF5454"
                @click="showDissociation = true"
              >
                <img src="../../assets/images/dissociate-btn.svg" alt="" />
                Dissociate from operator
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notifyMe from "@/helpers/notifyMe";
import * as types from "@/store/types";
import store from "@/store";
import api from "@/modules/agent-profile/_api";
import notify from "@/helpers/notifyMe";
import { mapGetters } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "AgentCompanies",
  props: ["companies", "profile_id", "dissociation_requests"],
  components: { VueGoogleAutocomplete },
  data() {
    return {
      license_types: [],
      selected_license_type: null,
      showSearchResults: false,
      isSearching: false,
      query: "",
      loading: false,
      companiesFromSearch: [],
      currentRequest: null,
      addNewAddress: false,
      newAddress: "",
      newAddressLatitude: "",
      newAddressLongitude: "",
      dissociationReason: "",
      currentDissociationReasonId: null,
      currentAddressEditId: null,
      editAddressContentAddress: null,
      editAddressContentLatitude: null,
      editAddressContentLongitude: null,
      showDissociation: false
    };
  },

  async beforeRouteEnter(_, __, next) {
    try {
      let [businessLicenseCategories] = await Promise.all([
        //store.dispatch(types.GET_ALL_BUSINESSES),
        store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES)
      ]);
      //let businessLicenseCategories = await store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES);
      //console.log(this.companies);
      next(vm => {
        //vm.companies = businesses;
        vm.license_types = businessLicenseCategories;
      });
    } catch (error) {
      error?.data?.message && notifyMe.error({ message: error.data.message });
      next(false);
    }
  },

  mounted() {
    if (localStorage.getItem("request_deeplink")) {
      const deeplink = localStorage.getItem("request_deeplink");
      const deeplink_array = deeplink.split("/");
      const last_segment = deeplink_array.pop();
      for (let i = 0; i < this.companies.length; i++) {
        if (this.companies[i]["_id"] === last_segment) {
          this.currentRequest = this.companies[i];
        }
      }
      localStorage.removeItem("request_deeplink");
      // this.currentRequest = [];
    }
    console.log(this.companies);
  },

  computed: {
    ...mapGetters({
      verification: "getCheckAgentVerification",
      checkAgentProfile: "getCheckAgentProfile"
    })
  },

  methods: {
    getAddressDataEdit(addressData) {
      console.log(addressData);
      if ("street_number" in addressData) {
        this.editAddressContentAddress = `${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      } else {
        this.editAddressContentAddress = `${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      }
      this.editAddressContentLatitude = addressData.latitude;
      this.editAddressContentLongitude = addressData.longitude;
    },
    getAddressDataCreate(addressData) {
      if ("street_number" in addressData) {
        this.newAddress = `${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      } else {
        this.newAddress = `${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      }
      this.newAddressLatitude = addressData.latitude;
      this.newAddressLongitude = addressData.longitude;
    },
    showAddressEdit(address) {
      this.currentAddressEditId = address;
      this.editAddressContent = address.address;
    },
    hideAddressEdit() {
      this.currentAddressEditId = null;
      this.editAddressContent = null;
    },
    checkForEditing(id) {
      if (this.currentAddressEditId !== null) {
        console.log("hello");
        if (this.currentAddressEditId._id === id) {
          return true;
        }
      }
      return false;
    },
    async updateEditAgentAddress(business_id, _id = "") {
      if (this.editAddressContentAddress === "") {
        this.$notify.error({ message: "Please input an address" });
      }
      this.loading = true;
      try {
        console.log(this.profile_id);
        await this.$store
          .dispatch(types.ADD_NEW_AGENT_ADDRESS, {
            business_id,
            address: this.editAddressContentAddress,
            latitude: this.editAddressContentLatitude,
            longitude: this.editAddressContentLongitude,
            _id,
            verification_id: this.verification.id
          })
          .then(() => {
            this.$notify.success("Address successfully edited");
            this.currentAddressEditId = "";
            this.editAddressContent = "";
          });
        this.hideAddressEdit();
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },

    async updateAgentAddress(business_id, _id = "") {
      if (this.newAddress === "") {
        this.$notify.error({ message: "Please input an address" });
      }
      this.loading = true;
      try {
        console.log(this.profile_id);
        if (_id !== "") {
          _id = this.currentAddressEditId;
        }
        await this.$store.dispatch(types.ADD_NEW_AGENT_ADDRESS, {
          business_id,
          address: this.newAddress,
          latitude: this.newAddressLatitude,
          longitude: this.newAddressLongitude,
          _id,
          verification_id: this.verification.id
        });
        this.newAddress = "";
        this.addNewAddress = false;
        this.currentRequest = null;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },

    async searchForCompaniesByLicense() {
      if (
        !(
          this.selected_license_type && this.selected_license_type.category_slug
        ) ||
        !this.query
      ) {
        return;
      }
      this.isSearching = true;
      try {
        const searchResults = (
          await api.searchCompaniesByLicenseCategory(
            this.selected_license_type.category_slug,
            this.query
          )
        ).data;
        this.companiesFromSearch = searchResults;
        this.showSearchResults = true;
      } catch (error) {
        error?.data?.message && notifyMe.error({ message: error.data.message });
      } finally {
        this.isSearching = false;
      }
    },

    showResolveAgentApplicationDialog(business_id, accept) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to ${
          accept ? "accept" : "reject"
        } an association request.`,
        type: "input",
        showCancelButton: true,
        confirmButtonText: `Yes, please ${
          accept ? "accept" : "reject"
        } request.`,
        cancelButtonText: "No, cancel!"
      }).then(result => {
        if (result.value) {
          this.resolveAgentApplication(business_id, accept);
        }
      });
    },

    async resolveAgentApplication(business_id, accepted) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.RESOLVE_AGENT_INVITATION, {
          business_id,
          accepted,
          profile_id: this.profile_id
        });
        this.currentRequest = null;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },

    showDissociateCountryApplicationDialog(business_id) {
      this.$swal({
        title: "Submit Dissociation Request",
        type: "question",
        showCancelButton: true,
        confirmButtonText: `Dissociate from operator`,
        cancelButtonText: "No, cancel!"
      }).then(() => {
        this.dissociateFromOperator(business_id, this.dissociationReason).then(
          () => {
            this.dissociationReason = "";
            this.currentRequest = null;
            this.currentDissociationReasonId = null;
            this.showDissociation = false;
          }
        );
      });
    },

    async dissociateFromOperator(business_id, reason) {
      this.loading = true;
      try {
        await this.$store
          .dispatch(types.DISSOCIATE_FROM_OPERATOR, {
            business_id,
            reason,
            profile_id: this.profile_id
          })
          .then(response => {
            notify.success({
              message: response.data.message
            });
          });
        this.currentRequest = null;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },

    async joinCompany(business_id, trade_name) {
      this.loading = true;
      try {
        await api
          .requestJoinBusiness({
            business_id,
            agent_id: this.profile_id
          })
          .then(response => {
            this.$store.commit(
              types.SET_AGENT_PROFILE_AGENT,
              Object.assign({}, this.checkAgentProfile, {
                agent_businesses: response.data.agent.agent_businesses
              })
            );
          });
        this.$swal({
          title: "Request successful",
          text: `You have requested to join ${trade_name}. Kindly exercise some patience while we process your application.`,
          type: "success",
          showCancelButton: false,
          confirmButtonText: "Okay!"
        }).then(() => {
          this.showSearchResults = false;
        });
      } catch (error) {
        error?.data?.message && notifyMe.error({ message: error.data.message });
      } finally {
        this.loading = false;
      }
    },

    sendToCompany(index) {
      this.currentRequest = this.companies[index];
    },
    async deleteAddress(business_id, _id = "", item) {
      // deleteShopAddress
      try {
        console.log(this.profile_id);
        await this.$store.dispatch(types.DELETE_AGENT_ADDRESS, {
          business_id,
          _id,
          verification_id: this.verification.id
        });
        this.$notify.success({ message: "Address successfully deleted" });
        this.currentRequest.shop_addresses.splice(item, 1);
        this.currentAddressEditId = "";
        this.editAddressContent = "";
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
#stuff:disabled {
  cursor: not-allowed;
}

.information-group {
  border-bottom: 1px solid rgba(78, 82, 83, 0.3);
}

.delete-button {
  background-color: rgba(255, 84, 84, 0.2);
  border-radius: 4px;
  border: none;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

button {
  border: none;
  border-radius: 3px;
  cursor: pointer !important;
}

.address-input {
  background-color: rgba(218, 219, 220, 0.3);
}

.address-input-field {
  border: none;
  background-color: white;
}

.address-input-field::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #c4c4c4;
}

textarea {
  width: 100%;
  border: 1px solid #404e5d;
  box-sizing: border-box;
  border-radius: 3px;
}
</style>
