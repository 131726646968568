<template>
  <div class="card bordered-box m-t-15">
    <div class="card-block">
      <div class="row">
        <div class="col-sm-6">
          <h3 class="card-title">Users ({{ users.length }})</h3>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <router-link
            v-if="$User.hasPermission('user-manage-role')"
            tag="button"
            :to="{ name: 'RolesView' }"
            class="btn btn-warning mr-2"
          >
            <i class="mr-1 mdi mdi-account-switch"></i>
            Manage Roles
          </router-link>
          <button
            v-if="$User.hasPermission('user-add')"
            class="btn btn-warning mr-2"
            @click.stop="showAddSingleUserModal"
          >
            <i class="mr-1 mdi mdi-account-plus"></i>
            Invite User
          </button>
          <button
            v-if="$User.hasPermission('user-add-multiple')"
            class="btn btn-warning"
            @click.stop="showAddMultipleUsersModal"
          >
            <i class="mdi mdi-account-multiple-plus"></i>
            Upload CSV
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-xs-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8">
          <div class="form-group m-b-10">
            <input
              type="text"
              class="form-control rounded-0"
              placeholder="Search using name..."
              v-model.trim="search_term"
              @input="searchUsers"
            />
          </div>
        </div>
      </div>
      <form id="filter-report-form" class="filter-report-form">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="status" class="control-label">Role</label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="role"
                :class="{ 'form-error animated shake': $v.role.$error }"
              >
                <option value disabled>-- select role --</option>
                <option value="all">All</option>
                <option v-for="role in roles" :value="role.id" :key="role.id">{{
                  role.name
                }}</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label class="control-label" style="display:block">&nbsp;</label>
              <button
                :disabled="isLoading || $v.$invalid"
                class="btn btn-warning normalize-filter-btn rounded-0 m-r-5"
                @click.stop.prevent="filterUserLogs"
              >
                <i class="mdi mdi-filter"></i>
                Filter
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <template v-if="!is_searching">
            <div class="p-t-10 p-b-10" v-if="isFilter">
              <label class="text-info font-normal font-14">
                <span class="p-r-10">{{
                  role === "all"
                    ? "Showing all users"
                    : "Users log filtered by role"
                }}</span>
              </label>
              <button
                @click.stop.prevent="clearFilter"
                class="p-l-5 btn btn-outline-info btn-xs font-bold"
              >
                clear filter
              </button>
            </div>
            <div class="p-t-10 p-b-10" v-if="!isFilter">
              <label class="text-info font-medium font-14"
                >Showing all users</label
              >
            </div>
          </template>
          <template v-else>
            <div class="p-t-10 p-b-10">
              <label class="text-info font-medium font-14">
                <span class="p-r-10"
                  >Showing all users by search term "{{
                    meta.search_term || ""
                  }}"</span
                >
                <a
                  href="javascript:void(0)"
                  @click.stop="clearFilter"
                  class="p-l-5 btn btn-outline-info btn-xs font-bold"
                  >clear search</a
                >
              </label>
            </div>
          </template>

          <div class="row">
            <div class="col-md-7">
              <div class="table-responsive">
                <template v-if="users.length > 0">
                  <table class="table table-hover manage-u-table-">
                    <thead>
                      <tr class="link-row">
                        <th class="text-center">#</th>
                        <!-- <th>Status</th> -->
                        <th>Name</th>
                        <!-- <th>Email</th> -->
                        <!-- <th>Mobile</th> -->
                        <th>Role</th>
                        <!-- <th>Action</th> -->
                        <!-- <th>Date</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(user, index) in users"
                        class="link-row"
                        :class="
                          selectedUser && selectedUser.id === user.id
                            ? 'text-success active'
                            : ''
                        "
                        :key="user.id"
                        @click.prevent.stop="selectUser(user.id)"
                      >
                        <td class="text-center">
                          {{ 1 + index }}
                        </td>
                        <!-- <td>
                          <label
                            class="label font-bold"
                            :class="[user.active?'label-light-success':'label-light-warning']"
                          >{{user.active ? 'active':'inactive'}}</label>
                        </td> -->
                        <td class="text-nowrap">
                          <template v-if="user.last_name">
                            <span class="text-capitalize">{{
                              user.first_name +
                                " " +
                                (user.middle_name
                                  ? user.middle_name + " "
                                  : "") +
                                user.last_name
                            }}</span>
                            <label
                              class="label font-bold mx-1"
                              :class="[
                                user.active
                                  ? 'label-light-success'
                                  : 'label-light-warning'
                              ]"
                              >{{ user.active ? "active" : "inactive" }}</label
                            >
                            <label
                              class="label label-light-primary font-bold mx-1"
                              v-if="user.id === current_user.id"
                              >You</label
                            >
                          </template>
                          <template v-else>
                            {{ user.email }}
                            <label
                              class="label label-light-megna font-bold mx-1"
                              >invited</label
                            >
                          </template>
                        </td>
                        <!-- <td>{{user.email}}</td> -->
                        <!-- <td>{{user.mobile || "Not available"}}</td> -->
                        <td>
                          {{ user.role.name }}
                          <!-- <label class="label label-light-megna text-nowrap font-bold">{{user.role.name}}</label> -->
                          <label
                            class="label label-light-primary ml-1 text-nowrap font-bold"
                            v-if="user.is_owner"
                            >owner</label
                          >
                        </td>
                        <!-- <td>
                          <button
                            v-if="(!user.has_accepted_invite) && $User.hasPermission('user-add')"
                            class="m-1 btn btn-outline-success btn-xs"
                            @click.stop="reinviteUserModal(user)"
                          >Re-invite User</button>
                          <button
                            v-if="((user.id != current_user.id) && !user.is_owner) && $User.hasPermission('user-change-role')"
                            class="m-1 btn btn-outline-warning btn-xs"
                            @click.stop="changeUserRoleModal(user)"
                          >Change User Role</button>
                          <button
                            v-if="((user.active && (user.id != current_user.id)) && !user.is_owner) && $User.hasPermission('user-deactivate')"
                            href="javascript:void(0)"
                            class="m-1 btn btn-outline-danger btn-xs"
                            @click.stop="deactivateUserModal(user)"
                          >Deactivate User</button>
                          <button
                            v-if="((!user.active && (user.id != current_user.id)) && !user.is_owner) && $User.hasPermission('user-activate')"
                            href="javascript:void(0)"
                            class="m-1 btn btn-outline-info btn-xs"
                            @click.stop="activateUserModal(user)"
                          >Re-activate User</button>
                        </td> -->
                        <!-- <td>{{user.created_at | long_date}}</td> -->
                      </tr>
                    </tbody>
                  </table>
                  <div
                    v-if="pagination"
                    class="p-30 test font-medium text-muted"
                  >
                    <span
                      >Showing {{ pagination.from || 1 }} to
                      {{ pagination.to }} of
                      {{ pagination.total }} entries</span
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    <img
                      src="/static/empty.svg"
                      class="img-fluid mb-3"
                      alt="empty"
                      style="width: 50px;"
                    />
                    <p class="font-bold font-14">
                      There are no users to display
                    </p>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-5" id="users-table-details">
              <transition
                enter-active-class="animated fastest fadeIn"
                leave-active-class="animated fastest fadeOut"
                mode="out-in"
              >
                <div class="card" v-if="selectedUser" :key="selectedUser.id">
                  <div class="card-block">
                    <h3 v-if="pagination" class="card-title">
                      User Details #{{
                        pagination.from || 1 + selectedUser.index
                      }}
                    </h3>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">{{
                        selectedUser.last_name ? "Name" : "Status"
                      }}</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        <template v-if="selectedUser.last_name">
                          {{
                            selectedUser.first_name +
                              " " +
                              (selectedUser.middle_name
                                ? selectedUser.middle_name + " "
                                : "") +
                              selectedUser.last_name
                          }}
                          <label
                            class="label font-bold mr-1"
                            :class="[
                              selectedUser.active
                                ? 'label-light-success'
                                : 'label-light-warning'
                            ]"
                            >{{
                              selectedUser.active ? "active" : "inactive"
                            }}</label
                          >
                          <label
                            class="label label-light-primary font-bold"
                            v-if="selectedUser.id === current_user.id"
                            >You</label
                          >
                        </template>
                        <template v-else>
                          <label class="label label-light-megna font-bold"
                            >invited</label
                          >
                        </template>
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Phone Number</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14 text-capitalize">{{
                        selectedUser.mobile || "Not available"
                      }}</span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Email Address</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">{{
                        selectedUser.email
                      }}</span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Role</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14 text-capitalize">
                        {{ selectedUser.role.name }}
                        <!-- <label class="label label-light-megna text-nowrap font-bold">{{selectedUser.role.name}}</label> -->
                        <label
                          class="label label-light-primary ml-1 text-nowrap font-bold"
                          v-if="selectedUser.is_owner"
                          >owner</label
                        >
                      </span>
                    </div>

                    <div class="col-xs-12">
                      <span class="font-bold font-14">Date</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">{{
                        selectedUser.created_at | long_date
                      }}</span>
                    </div>

                    <!-- <div class="col-xs-12">
                      <span class="font-bold font-14">Action</span>
                    </div> -->
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14 text-capitalize">
                        <button
                          v-if="
                            !selectedUser.has_accepted_invite &&
                              $User.hasPermission('user-add')
                          "
                          class="mr-1 btn btn-outline-success btn-xs"
                          @click.stop="reinviteUserModal(selectedUser)"
                        >
                          Re-invite User
                        </button>
                        <button
                          v-if="
                            selectedUser.id != current_user.id &&
                              !selectedUser.is_owner &&
                              $User.hasPermission('user-change-role')
                          "
                          class="mr-1 btn btn-outline-warning btn-xs"
                          @click.stop="changeUserRoleModal(selectedUser)"
                        >
                          Change User Role
                        </button>
                        <button
                          v-if="
                            selectedUser.active &&
                              selectedUser.id != current_user.id &&
                              !selectedUser.is_owner &&
                              $User.hasPermission('user-deactivate')
                          "
                          href="javascript:void(0)"
                          class="mr-1 btn btn-outline-danger btn-xs"
                          @click.stop="deactivateUserModal(selectedUser)"
                        >
                          Deactivate User
                        </button>
                        <button
                          v-if="
                            !selectedUser.active &&
                              selectedUser.id != current_user.id &&
                              !selectedUser.is_owner &&
                              $User.hasPermission('user-activate')
                          "
                          href="javascript:void(0)"
                          class="mr-1 btn btn-outline-info btn-xs"
                          @click.stop="activateUserModal(selectedUser)"
                        >
                          Re-activate User
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="card" v-if="!selectedUser && users.length > 0">
                  <div class="card-block">
                    <div class="my-3 text-center">
                      <img
                        src="/static/select.svg"
                        class="img-fluid mb-3"
                        alt="select"
                        style="width: 50px;"
                      />
                      <p class="font-14 font-bold">
                        Select a user to view details
                      </p>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <div class="text-center m-t-20" v-if="pagination">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import eventBus from "@/helpers/event";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import store from "@/store";
import debounce from "@/helpers/debounce";

export default {
  name: "ManageUsersView",
  mounted() {
    eventBus.$on("lslb:user:refresh-users-list", () => {
      this.getLogByPage(this.pagination.current_page);
    });
  },
  data() {
    return {
      role: "",
      search_term: "",
      isLoading: false,
      isFilter: false,
      is_searching: false,
      selectedUserId: ""
    };
  },
  validations: {
    role: { required }
  },
  computed: {
    ...mapGetters({
      users: "getUsersLog",
      pagination: "getUsersLogPagination",
      meta: "getUsersLogMeta",
      roles: "getAllRoles",
      current_user: "getCurrentUser"
    }),
    selectedUser() {
      let index;
      let selectedUser = this.users.find((user, key) => {
        index = key;
        return user.id === this.selectedUserId;
      });
      selectedUser && (selectedUser.index = index);
      return selectedUser;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterUserLogs() {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let { role } = this;
          await this.$store.dispatch(types.GET_ALL_USERS_ACTION, {
            role,
            page: 1
          });
          this.is_searching = false;
          this.isFilter = true;
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    async getLogByPage(page) {
      if (!this.is_searching) {
        if (this.isFilter) {
          try {
            let { role } = this;
            await this.$store.dispatch(types.GET_ALL_USERS_ACTION, {
              role,
              page
            });
            this.is_searching = false;
          } catch (error) {
            error &&
              error.data &&
              this.$notify.error({ message: error.data.message });
          }
        } else {
          try {
            await this.$store.dispatch(types.GET_ALL_USERS_ACTION, { page });
          } catch (error) {
            error &&
              error.data &&
              this.$notify.error({ message: error.data.message });
          }
        }
      } else {
        try {
          await store.dispatch(types.SEARCH_USERS_ACTION, {
            query: this.search_term,
            page: page
          });
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        }
      }
    },
    async clearFilter() {
      try {
        await this.$store.dispatch(types.GET_ALL_USERS_ACTION, {
          page: 1
        });
        this.is_searching = false;
        this.role = "";
        this.$v.$reset();
        this.isFilter = false;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    },
    searchUsers: debounce(async function(e) {
      console.log("serach");
      if (e.target.value.trim()) {
        try {
          await store.dispatch(types.SEARCH_USERS_ACTION, {
            query: e.target.value.trim(),
            page: 1
          });
          this.is_searching = true;
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        }
      }
    }, 500),
    selectUser(id) {
      let offset = $("#users-table-details").offset();
      offset.top -= 100;
      $("html, body").animate({
        scrollTop: offset.top
      });
      this.selectedUserId = id;
    },
    async showAddSingleUserModal() {
      $("#add-single-user-modal").modal("show");
    },
    showAddMultipleUsersModal() {
      $("#add-multiple-users-modal").modal("show");
    },
    changeUserRoleModal(user) {
      eventBus.$emit("lslb:user:show-multi-user-modal", {
        user,
        mode: "change-user-role"
      });
      $("#multi-user-modal").modal("show");
    },
    deactivateUserModal(user) {
      eventBus.$emit("lslb:user:show-multi-user-modal", {
        user,
        mode: "deactivate-user"
      });
      $("#multi-user-modal").modal("show");
    },
    activateUserModal(user) {
      eventBus.$emit("lslb:user:show-multi-user-modal", {
        user,
        mode: "activate-user"
      });
      $("#multi-user-modal").modal("show");
    },
    reinviteUserModal(user) {
      eventBus.$emit("lslb:user:show-multi-user-modal", {
        user,
        mode: "reinvite-user"
      });
      $("#multi-user-modal").modal("show");
    }
  }
};
</script>
