<template>
  <div class="container-fluid">
    <app-title title="Manage Roles" />
    <div class="card bordered-box">
      <div class="card-block">
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div class="vtabs customvtab mx-auto">
                  <ul
                    class="nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <p class="card-title font-bold p-2">Default Roles</p>
                    </li>
                    <template v-if="default_roles.length > 0">
                      <li
                        class="nav-item"
                        v-for="role in default_roles"
                        :key="role.id"
                      >
                        <a
                          :class="[
                            selected_role.id === role.id ? 'active' : ''
                          ]"
                          @click.prevent="setSelectedRole(role)"
                          class="nav-link font-14 text-capitalize pointer"
                          role="tab"
                          aria-expanded="false"
                          >{{ role.name }}</a
                        >
                      </li>
                    </template>
                    <li class="nav-item" v-if="default_roles.length === 0">
                      <p class="font-12 text-muted">
                        There are no default roles
                      </p>
                    </li>
                    <li class="nav-item">
                      <p class="card-title font-bold mt-5 p-2">Custom Roles</p>
                    </li>
                    <template v-if="custom_roles.length > 0">
                      <li
                        class="nav-item"
                        v-for="role in custom_roles"
                        :key="role.id"
                      >
                        <a
                          :class="[
                            selected_role.id === role.id ? 'active' : ''
                          ]"
                          @click.prevent="setSelectedRole(role)"
                          class="nav-link font-14 text-capitalize pointer"
                          role="tab"
                          aria-expanded="false"
                          >{{ role.name }}</a
                        >
                      </li>
                    </template>
                    <li class="nav-item" v-if="custom_roles.length === 0">
                      <p class="font-12 text-muted p-2">
                        You can create your own custom role and choose exactly
                        what team members can do
                      </p>
                    </li>
                    <div class="p-2">
                      <button
                        class="btn btn-sm btn-warning"
                        @click.prevent="createCustomRole"
                      >
                        <i class="mdi mdi-plus"></i>Add a Custom Role
                      </button>
                    </div>
                  </ul>
                  <!-- Tab panes -->
                  <div class="tab-content w-100 p-20">
                    <div
                      class="tab-pane active"
                      role="tabpanel"
                      aria-expanded="true"
                    >
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="w-100 d-flex justify-content-between">
                            <p class="card-title font-bold text-capitalize">
                              {{ selected_role.name }}
                            </p>
                            <div v-if="selected_role.is_custom">
                              <button
                                class="btn btn-sm btn-outline-secondary m-1 font-bold"
                                @click.prevent="editCustomRole"
                              >
                                <i class="mdi mdi-pencil mr-1"></i>Edit
                              </button>
                              <button
                                class="btn btn-sm btn-outline-secondary m-1 font-bold"
                                @click.prevent="deleteCustomRole"
                                v-if="selected_role.users.length === 0"
                              >
                                <i class="mdi mdi-delete mr-1"></i>Delete
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <p class="font-14">{{ selected_role.description }}</p>
                          <p class="font-12 text-muted">
                            <i class="mdi mdi-account-multiple mr-1"></i>
                            <template
                              v-if="
                                selected_role && selected_role.users.length > 0
                              "
                            >
                              Team Member(s);
                              {{
                                `${selected_role.users[0].first_name} ${selected_role.users[0].middle_name} ${selected_role.users[0].last_name}`
                              }}
                              <template v-if="selected_role.users.length > 1">
                                and
                                <a
                                  v-tooltip="'Click to view more'"
                                  class="transition fade-link pointer"
                                  @click.prevent="openViewAllRoleUsersModal"
                                >
                                  {{ selected_role.users.length - 1 }} other(s)
                                  <i class="mdi mdi-dots-horizontal"></i>
                                </a>
                              </template>
                            </template>
                            <template v-else
                              >There are no team members in this role</template
                            >
                          </p>
                        </div>
                        <div class="col-sm-12">
                          <ul class="list-group list-group-flush">
                            <li
                              class="list-group-item list-group-item-success font-14"
                            >
                              What this role can access
                            </li>
                            <template
                              v-if="
                                selected_role &&
                                  selected_role.permissions.length > 0
                              "
                            >
                              <li
                                class="list-group-item font-14 p-l-0 text-capitalize"
                                v-for="permission in permission_object_list"
                                :key="permission.permission"
                              >
                                <span class="font-bold mr-1">Can </span
                                >{{ permission.name }}
                              </li>
                            </template>
                            <template v-else>
                              <li class="list-group-item">
                                <p class="font-12 text-muted">
                                  There are no permissions for this role
                                </p>
                              </li>
                            </template>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import { mapGetters } from "vuex";
import getPermissionName from "@/helpers/getPermissionName";
import eventBus from "@/helpers/event";
import * as types from "@/store/types";

export default {
  name: "RolesView",
  components: {
    AppTitle
  },
  computed: {
    ...mapGetters({
      default_roles: "getAllDefaultRoles",
      custom_roles: "getAllCustomRoles",
      all_permissions: "getAllPermissions",
      selected_role: "getSelectedRole"
    }),
    permission_object_list() {
      let permission_object_list = [];
      this.selected_role.permissions.forEach(permission =>
        permission_object_list.push(
          getPermissionName(permission, this.all_permissions)
        )
      );
      return permission_object_list.filter(permission => !!permission);
    }
  },
  methods: {
    openViewAllRoleUsersModal() {
      $("#view-all-role-users-modal").modal("show");
    },
    async setSelectedRole(role) {
      try {
        await this.$store.dispatch(types.GET_ROLE_WITH_USERS_ACTION, role);
      } catch (error) {
        this.$notify.error({ message: error.data.message });
      }
    },
    createCustomRole() {
      eventBus.$emit("lslb:role:open-crud-custom-role-modal", {
        role: "",
        mode: "create"
      });
      $("#crud-custom-role-modal").modal("show");
    },
    editCustomRole() {
      eventBus.$emit("lslb:role:open-crud-custom-role-modal", {
        role: this.selected_role,
        mode: "update"
      });
      $("#crud-custom-role-modal").modal("show");
    },
    deleteCustomRole() {
      eventBus.$emit("lslb:role:open-crud-custom-role-modal", {
        role: this.selected_role,
        mode: "delete"
      });
      $("#crud-custom-role-modal").modal("show");
    }
  }
};
</script>
