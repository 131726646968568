function getPermissionName(permission, allPermissions) {
  //get all permissions from business
  let permissions = allPermissions;

  let permission_object;
  //iterate over each permission

  for (let _permission of permissions) {
    permission_object = _permission.permissions.find(__permission => {
      return __permission.permission === permission;
    });
    if (permission_object) {
      break;
    }
  }
  if (permission_object) {
    return permission_object;
  }
}
export default getPermissionName;
