import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.GET_AUDIT_LOG_EVENTS_ACTION]({ commit }) {
    let response = (await api.getAuditLogEvents()).data;
    commit(types.SET_AUDIT_LOG_EVENTS, response);
    return response;
  },
  async [types.GET_AUDIT_LOG_ACTION]({ commit }, payload) {
    let audit = await api.getAuditLog(payload);
    commit(types.SET_AUDIT_LOG, audit.data);
    commit(types.SET_AUDIT_META, audit.meta);
    return audit;
  },
  async [types.EXPORT_AUDIT_LOG_ACTION](_, payload) {
    let response = await api.exportAuditLog(payload);
    return response;
  }
};

export default actions;
