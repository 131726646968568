<template>
  <div></div>
</template>

<script>
import logout from "@/helpers/logout";

export default {
  name: "LogoutModule",
  beforeRouteEnter(to, from, next) {
    logout();
    return next({
      name: "LoginView"
    });
  }
};
</script>
