import http from "@/helpers/http";

class api {
  static get axios() {
    return http;
  }
  static getAllRoles() {
    return http.get("/roles");
  }
  static createNewCustomRole(payload) {
    let { name, description, selected_permissions } = payload;
    payload = {
      description,
      name,
      permissions: selected_permissions
    };
    return http.post("/roles", payload);
  }
  static updateCustomRole(payload) {
    let { name, description, selected_permissions, role } = payload;
    payload = {
      description,
      name,
      permissions: selected_permissions
    };
    return http.put("/roles/" + role.id, payload);
  }
  static deleteCustomRole(payload) {
    let { role } = payload;
    return http.delete("/roles/" + role.id);
  }
  static getARoleWithUsers(role) {
    return http.get("/roles/" + role.id + "/users");
  }
}

export default api;
