<template>
  <div class="card bordered-box m-t-15  pagination-center">
    <div class="card-block">
      <div class="row">
        <div class="col-sm-6">
          <h3 class="card-title">Companies ({{ pagination.total }})</h3>
        </div>
        <div class="col-sm-6 d-flex justify-content-end">
          <button
            v-if="$User.hasPermission('user-add')"
            class="btn btn-warning mr-2"
            @click.stop="showAddNewCompanyModal"
          >
            <i class="mr-1 mdi mdi-account-plus"></i>
            Invite Company
          </button>
        </div>
      </div>
      <!--      <form id="filter-report-form" class="filter-report-form">-->
      <!--        <div class="row">-->
      <!--          <div class="col-md-3">-->
      <!--            <div class="form-group m-b-5">-->
      <!--              <label for="status" class="control-label">Status</label>-->
      <!--              <select-->
      <!--                class="form-control custom-select rounded-0"-->
      <!--                v-model.trim="status"-->
      <!--                :class="{ 'form-error animated shake': $v.status.$error }"-->
      <!--              >-->
      <!--                <option value disabled>&#45;&#45; select status &#45;&#45;</option>-->
      <!--                <option value="all">All</option>-->
      <!--                <option value="active">Active</option>-->
      <!--                <option value="inactive">Inactive</option>-->
      <!--              </select>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="col-md-3">-->
      <!--            <div class="form-group m-b-5">-->
      <!--              <label class="control-label hidden" style="display:block">-->
      <!--                Filter-->
      <!--              </label>-->
      <!--              <button-->
      <!--                :disabled="isLoading || $v.$invalid"-->
      <!--                class="btn btn-warning normalize-filter-btn rounded-0 m-r-5"-->
      <!--                @click.stop.prevent="filterCompanyLogs"-->
      <!--              >-->
      <!--                <i class="mdi mdi-filter"></i>-->
      <!--                Filter-->
      <!--              </button>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </form>-->

      <div class="row">
        <div class="col-md-12">
          <div class="p-t-10 p-b-10" v-if="isFilter">
            <label class="text-info font-normal font-14">
              <span class="p-r-10">{{
                status === "all"
                  ? "Showing all companies"
                  : "Companies log filtered by status"
              }}</span>
            </label>
            <button
              @click.stop.prevent="clearFilter"
              class="p-l-5 btn btn-outline-info btn-xs font-bold"
            >
              clear filter
            </button>
          </div>
          <div class="p-t-10 p-b-10" v-if="!isFilter">
            <label class="text-info font-medium font-14">
              Showing all companies
            </label>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <template v-if="companies.length > 0">
                  <table class="table table-hover manage-u-table-">
                    <thead>
                      <tr class="link-row">
                        <th class="text-center">#</th>
                        <!-- <th>Status</th> -->
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Admin</th>
                        <th>Status</th>
                        <th>Last Seen</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(company, index) in companies"
                        class="link-row"
                        :key="company.id"
                      >
                        <td class="text-center">
                          {{ pagination.from + index }}
                        </td>
                        <td class="text-nowrap">
                          <template>
                            {{ company.business_name }}
                          </template>
                        </td>
                        <td>
                          {{ company.support_email }}
                        </td>
                        <td class="text-nowrap">
                          {{ company.phone || company.primary_contact_phone }}
                        </td>
                        <td>
                          <template>
                            {{ company.primary_contact_name }}
                          </template>
                        </td>
                        <td>
                          <h6
                            class="px-4 py-2 text-center invited"
                            v-if="company.status === 'invited'"
                          >
                            {{ company.status }}
                          </h6>
                          <h6
                            class="px-4 py-2 text-center active-status"
                            v-if="company.status === 'active'"
                          >
                            {{ company.status }}
                          </h6>
                          <h6
                            class="px-4 py-2 text-center deactive-status"
                            v-if="company.status === 'deactivated'"
                          >
                            {{ company.status }}
                          </h6>
                        </td>
                        <td>
                          <template>
                            {{ company.last_seen }}
                          </template>
                        </td>
                        <td>
                          <div class="dropdown">
                            <button
                              class="btn btn-link dropdown-toggle"
                              style="color: black"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="mdi mdi-dots-horizontal font-20"></i>
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <router-link
                                :to="{
                                  name: 'EditCompaniesView',
                                  params: { company_id: company.id },
                                  query: {
                                    email: company.primary_contact_email,
                                    phone: company.phone
                                  }
                                }"
                                class="dropdown-item text-muted"
                              >
                                Edit
                              </router-link>
                              <button
                                type="button"
                                class="dropdown-item"
                                v-if="company.status === 'invited'"
                                @click="resendInvite(company._id)"
                              >
                                Resend Invite
                              </button>
                              <button
                                type="button"
                                @click="deactivateUser(company._id)"
                                class="dropdown-item"
                                v-if="company.status === 'active'"
                              >
                                De-Activate
                              </button>
                              <button
                                type="button"
                                @click="reactivateUser(company._id)"
                                class="dropdown-item"
                                v-if="company.status === 'deactivated'"
                              >
                                Re-Activate
                              </button>
                            </div>
                          </div>
                        </td>
                        <edit-business-modal :business="company" />
                      </tr>
                    </tbody>
                  </table>
                  <div class="p-30 test font-medium text-muted">
                    <span
                      >Showing {{ pagination.from }} to {{ pagination.to }} of
                      {{ pagination.total }} entries</span
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    <img
                      src="/static/empty.svg"
                      class="img-fluid mb-3"
                      alt="empty"
                      style="width: 50px;"
                    />
                    <p class="font-bold font-14">
                      There are no companies to display
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <div class="text-center m-t-20">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import eventBus from "@/helpers/event";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import http from "@/helpers/http";

export default {
  name: "ManageCompaniesView",
  mounted() {
    eventBus.$on("lslb:company:refresh-companies-list", () => {
      this.getLogByPage(this.pagination.current_page);
    });
  },
  data() {
    return {
      status: "",
      search_term: "",
      isLoading: false,
      isFilter: false,
      selectedCompanyId: ""
    };
  },
  validations: {
    status: { required }
  },
  computed: {
    ...mapGetters({
      companies: "getCompaniesLog",
      pagination: "getCompaniesLogPagination",
      meta: "getCompaniesLogMeta",
      businessLicenseCategories: "getBusinessLicenseCategories"
    })
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterCompanyLogs() {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          await this.$store.dispatch(types.GET_ALL_COMPANIES_ACTION, {
            page: 1,
            status: this.status
          });
          this.isFilter = true;
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      try {
        this.$router.push({
          name: this.$route.name,
          query: {
            page
          }
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    async clearFilter() {
      try {
        await this.$store.dispatch(types.GET_ALL_COMPANIES_ACTION, {
          page: 1
        });
        this.status = "";
        this.$v.$reset();
        this.isFilter = false;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    },
    async showAddNewCompanyModal() {
      $("#add-company-modal").modal("show");
    },
    async showEditCompanyModal() {
      console.log("first");
      $("#edit-company-modal").modal("show");
    },
    async deactivateUser(user_id) {
      http
        .post("/businesses/deactivate", {
          business_id: user_id
        })
        .then(response => {
          this.$notify.success({
            message: response.message
          });
          this.getLogByPage(this.pagination.current_page);
        })
        .catch(error => {
          this.$notify.error({
            message: error.response.message
          });
        });
    },
    async reactivateUser(user_id) {
      http
        .post("/businesses/activate", {
          business_id: user_id
        })
        .then(response => {
          this.$notify.success({
            message: response.message
          });
          this.getLogByPage(this.pagination.current_page);
        })
        .catch(error => {
          this.$notify.error({
            message: error.response.message
          });
        });
    },
    async resendInvite(user_id) {
      http
        .post("/businesses/resend-invite", {
          business_id: user_id
        })
        .then(response => {
          this.$notify.success({
            message: response.message
          });
        })
        .catch(error => {
          this.$notify.error({
            message: error.response.message
          });
        });
    },
    handleEdit() {
      $("#edit-business-modal").modal("show");
    }
  }
};
</script>

<style>
.invited {
  background-color: #ecf8f9;
  color: #2ab0bc;
  text-transform: capitalize;
  border-radius: 4.58182px;
}
.active-status {
  background-color: #e9f7eb;
  color: #3ab54a;
  text-transform: capitalize;
  border-radius: 4.58182px;
}
.deactive-status {
  background: #ffeeee;
  color: #ff5454;
  text-transform: capitalize;
  border-radius: 4.58182px;
}

.dropdown-toggle::after {
  display: none !important;
}
</style>
