<template>
  <div class="row">
    <!-- Column -->
    <div class="col-lg-6 col-sm-12">
      <div class="card ">
        <div class="card-wrapper">
          <div class="row">
            <div class="col-6 col-sm-12">
              <div class="d-flex flex-wrap">
                <div>
                  <h3 class="card-title m-4">Agent Metrics</h3>
                  <!-- <h6 class="card-subtitle"></h6> -->
                </div>
              </div>
            </div>
            <div class="col-12 mb-3">
              <canvas id="agent-metrics"></canvas>
            </div>
            <!--            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">-->
            <!--              <div class="row">-->
            <!--                <div class="col-sm-6 col-md-12 mb-3 text-left">-->
            <!--                  <div class="b-all rounded dashboard-metric-card p-2">-->
            <!--                    <div-->
            <!--                      class="d-flex justify-content-between align-items-center"-->
            <!--                    >-->
            <!--                      <div>-->
            <!--                        <h3 class="m-b-0 font-medium">-->
            <!--                          <i-count-up-->
            <!--                            :startVal="0"-->
            <!--                            :endVal="this.agentAnalytics.verified"-->
            <!--                            :decimals="0"-->
            <!--                            :duration="1.5"-->
            <!--                            :options="options"-->
            <!--                            :callback="noop"-->
            <!--                          ></i-count-up>-->
            <!--                        </h3>-->
            <!--                        <h6 class="text-muted">Verified</h6>-->
            <!--                      </div>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-sm-6 col-md-12 mb-3 text-left">-->
            <!--                  <div class="b-all rounded dashboard-metric-card p-2">-->
            <!--                    <h3 class="m-b-0 font-medium">-->
            <!--                      <i-count-up-->
            <!--                        :startVal="0"-->
            <!--                        :endVal="this.agentAnalytics.not_verified"-->
            <!--                        :decimals="0"-->
            <!--                        :duration="1.5"-->
            <!--                        :options="options"-->
            <!--                        :callback="noop"-->
            <!--                      ></i-count-up>-->
            <!--                    </h3>-->
            <!--                    <h6 class="text-muted">Not Verified</h6>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-sm-6 col-md-12 mb-3 text-left">-->
            <!--                  <div class="b-all rounded dashboard-metric-card p-2">-->
            <!--                    <h3 class="m-b-0 font-medium">-->
            <!--                      <i-count-up-->
            <!--                        :startVal="0"-->
            <!--                        :endVal="this.agentAnalytics.pending"-->
            <!--                        :decimals="0"-->
            <!--                        :duration="1.5"-->
            <!--                        :options="options"-->
            <!--                        :callback="noop"-->
            <!--                      ></i-count-up>-->
            <!--                    </h3>-->
            <!--                    <h6 class="text-muted">Pending</h6>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-sm-6 col-md-12 mb-3 text-left">-->
            <!--                  <div class="b-all rounded dashboard-metric-card p-2">-->
            <!--                    <h3 class="m-b-0 font-medium">-->
            <!--                      <i-count-up-->
            <!--                        :startVal="0"-->
            <!--                        :endVal="this.agentAnalytics.invited"-->
            <!--                        :decimals="0"-->
            <!--                        :duration="1.5"-->
            <!--                        :options="options"-->
            <!--                        :callback="noop"-->
            <!--                      ></i-count-up>-->
            <!--                    </h3>-->
            <!--                    <h6 class="text-muted">Invited</h6>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-sm-6 col-md-12 mb-3 text-left">-->
            <!--                  <div class="b-all rounded dashboard-metric-card p-2">-->
            <!--                    <h3 class="m-b-0 font-medium">-->
            <!--                      <i-count-up-->
            <!--                        :startVal="0"-->
            <!--                        :endVal="this.agentAnalytics.incomplete"-->
            <!--                        :decimals="0"-->
            <!--                        :duration="1.5"-->
            <!--                        :options="options"-->
            <!--                        :callback="noop"-->
            <!--                      ></i-count-up>-->
            <!--                    </h3>-->
            <!--                    <h6 class="text-muted">Incomplete</h6>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="col-sm-6 col-md-12 mb-3 text-left">-->
            <!--                  <div class="b-all rounded dashboard-metric-card p-2">-->
            <!--                    <h3 class="m-b-0 font-medium">-->
            <!--                      <i-count-up-->
            <!--                        :startVal="0"-->
            <!--                        :endVal="this.agentAnalytics.total"-->
            <!--                        :decimals="0"-->
            <!--                        :duration="1.5"-->
            <!--                        :options="options"-->
            <!--                        :callback="noop"-->
            <!--                      ></i-count-up>-->
            <!--                    </h3>-->
            <!--                    <h6 class="text-muted">Total</h6>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-sm-12">
      <div class="card">
        <div class="card-wrapper">
          <div class="row">
            <div class="col-6 col-sm-12">
              <div class="d-flex flex-wrap">
                <div>
                  <h3 class="card-title m-4">Verification Metrics</h3>
                  <!-- <h6 class="card-subtitle"></h6> -->
                </div>
              </div>
            </div>
            <div class="col-12 mb-3">
              <canvas id="verification-metrics"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
Chart.pluginService.register({
  beforeDraw: function(chart) {
    if (chart.config.options.elements.center) {
      //Get ctx from string
      var ctx = chart.chart.ctx;
      var ctx1 = chart.chart.ctx;

      //Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || "Arial";
      var txt = centerConfig.text;
      var color = centerConfig.color || "#000";
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2);
      //Start with a base font of 30px
      ctx.font = "30px " + fontStyle;
      ctx1.font = "30px " + fontStyle;

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = chart.innerRadius * 2;

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight);

      //Set font settings to draw it correctly.
      ctx.textAlign = "center";
      ctx1.textAlign = "center";

      ctx.textBaseline = "middle";
      ctx1.textBaseline = "middle";
      var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx1.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;
      ctx1.fillStyle = color;

      //Draw text in center
      ctx.fillText(txt, centerX, centerY);
      ctx1.fillText(txt, centerX, centerY);
    }
  }
});
// import ICountUp from "vue-countup-v2";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      }
    };
  },
  // components: {
  //   ICountUp
  // },
  methods: {
    noop() {},
    buildAnalyticChart() {
      // destroy chart if it has been created
      if (this.myChart) {
        this.myChart.destroy();
      }
      var ctx = document.getElementById("agent-metrics").getContext("2d");
      var ctx1 = document
        .getElementById("verification-metrics")
        .getContext("2d");

      let config = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [
                this.agentAnalytics.verified,
                this.agentAnalytics.not_verified,
                this.agentAnalytics.pending,
                this.agentAnalytics.invited,
                this.agentAnalytics.incomplete
              ],
              backgroundColor: [
                "rgba(64, 223, 109, 0.7)",
                "rgba(255, 99, 132, 0.7)",
                "rgba(140, 122, 230, 0.7)",
                "rgba(54, 162, 235, 0.7)"
              ]
            }
          ],
          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: [
            "Verified",
            "Not Verified",
            "Pending",
            "Invited",
            "Incomplete"
          ]
        },
        options: {
          responsive: true,
          legend: {
            position: "bottom",
            labels: {
              padding: 20
            }
          }
        }
      };
      let config2 = {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: [
                this.verificationMetrics.total_completed,
                this.verificationMetrics.total_guarantor_outstanding
              ],
              backgroundColor: ["#00EF35", "#FF89A1"]
            }
          ],
          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: ["Completed", "Outstanding Guarantor"]
        },
        options: {
          responsive: true,
          legend: {
            position: "bottom",
            labels: {
              padding: 40
            }
          }
          // elements: {
          //   center: {
          //     text: this.agentAnalytics.total,
          //     color: "#2c2b2e", // Default is #000000
          //     fontStyle: "Rubik" // Default is Arial
          //     // sidePadding: 20 // Defualt is 20 (as a percentage)
          //   }
          // }
        }
      };

      this.myChart = new Chart(ctx, config);
      this.myChart = new Chart(ctx1, config2);
    }
  },
  mounted() {
    this.buildAnalyticChart();
  },
  computed: {
    ...mapGetters({
      agentAnalytics: "getAgentAnalytics",
      verificationMetrics: "getVerificationMetrics"
    })
  }
};
</script>
