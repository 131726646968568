const getters = {
  getBusinessAccountBalance: state => state.business_account_balance,
  getBusinessTransactionTotal: state => state.business_transaction_total,
  getAgentAnalytics: state => state.agent_analytics,
  getDashboardMetrics: state => state.dashboard_metrics,
  getVerificationByCities: state => state.verification_by_cities,
  getVerificationMetrics: state => state.verification_metrics,
  getIdentityMetrics: state => state.identity_metrics
};

export default getters;
