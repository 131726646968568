<template>
  <div class="clearfix">
    <div class="col-xs-12">
      <span class="font-bold font-14">Status</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span
        class="text-muted font-14 font-bold text-capitalize text-nowrap"
        :class="
          address_info.status === 'verified'
            ? 'text-success'
            : address_info.status === 'completed'
            ? 'text-info'
            : address_info.status === 'not_verified'
            ? 'text-danger'
            : address_info.status === 'pending'
            ? 'text-info'
            : address_info.status === 'incomplete'
            ? 'text-primary'
            : address_info.status === 'awaiting_qa'
            ? 'text-info'
            : null
        "
      >
        <template v-if="address_info.status === 'awaiting_qa'"
          >Awaiting Quality Assurance Check</template
        >
        <template v-else>
          {{ address_info.status | remove_underscore }}
        </template>
        <i
          class="mdi ml-1"
          :class="
            address_info.status === 'verified'
              ? 'mdi-check-circle'
              : address_info.status === 'completed'
              ? 'mdi-check-circle'
              : address_info.status === 'not_verified'
              ? 'mdi-alert'
              : address_info.status === 'pending'
              ? 'mdi-clock'
              : address_info.status === 'incomplete'
              ? 'mdi-alert-outline'
              : address_info.status === 'awaiting_qa'
              ? 'mdi-clock'
              : null
          "
        ></i>
      </span>
      <p class="small" v-if="address_info.reason">
        ({{ address_info.reason }})
      </p>
    </div>
    <template v-if="address_info.verification_completed_at">
      <div class="col-xs-12">
        <span class="font-bold font-14">Application Completed At</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">
          {{ address_info.verification_completed_at | long_date }}
        </span>
      </div>
    </template>
    <template v-if="address_info.verification_started_at">
      <div class="col-xs-12">
        <span class="font-bold font-14">Application Started At</span>
      </div>
      <div class="col-xs-12 m-b-10">
        <span class="text-muted font-14">
          {{ address_info.verification_started_at | long_date }}
        </span>
      </div>
    </template>
    <div class="col-xs-12">
      <span class="font-bold font-14">Address</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span
        v-if="address_info.address"
        class="text-muted font-14 text-capitalize"
      >
        {{ address_info.address }}
      </span>
      <span v-else class="text-muted font-14 text-capitalize">
        {{ address }}
      </span>
      <!-- <template v-if="showRequestAddressButton">
        <br />
        <label class="label label-warning font-bold">
          This address has not been verified
        </label>
        <button
          class="ml-1 d-inline-block btn btn-link"
          @click="openVerifyAddressModal"
        >
          Click to verify address
          <span class="mdi mdi-arrow-right"></span>
        </button>
      </template> -->
    </div>
    <!-- <div class="col-xs-12">
      <span class="font-bold font-14">Postcode</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{address_info.postcode || "Not available"}}.</span>
    </div>-->
    <div class="col-xs-12">
      <span class="font-bold font-14">Unique Verification Code</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">
        {{ address_info.report_id || "Not available" }}
      </span>
    </div>
    <div class="col-xs-12" v-if="address_info.is_rejected">
      <span class="font-bold font-14">Reason for Failure</span>
    </div>
    <div class="col-xs-12 m-b-10" v-if="address_info.is_rejected">
      <span class="text-danger font-medium font-14">
        {{ address_info.reason_for_rejection || "Not available" }}
      </span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Address by NIPOST</span>
      <img
        src="/static/nipost_logo.png"
        class="d-inline-block w-auto ml-1"
        style="height: 14px;"
      />
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14 text-capitalize">
        <template v-if="address_info.nipost_address">
          <a :href="nipost_address_link" target="_blank">
            {{ address_info.nipost_address }}
            <i class="mdi mdi-open-in-new small"></i>
          </a>
        </template>
        <template v-else>
          <span class="text-muted font-14">Not available</span>
        </template>
      </span>
    </div>
    <div
      class="col-xs-12 m-b-10"
      v-if="
        address_info.verification_started_at || address_info.report_available
      "
    >
      <span
        v-tooltip="
          !address_info.report_available
            ? 'Download will be enabled as soon as the address report is available for viewing'
            : null
        "
      >
        <a
          :href="
            address_info.report_available ? address_info.download_url : '#'
          "
          class="btn btn-warning"
          :class="address_info.report_available ? null : 'disabled'"
        >
          Download Address Report
          <i class="mdi mdi-download"></i>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["address_info"],
  data() {
    return {
      downloadingReport: false
    };
  },
  computed: {
    address() {
      let address = [
        this.address_info.building_number,
        this.address_info.street,
        this.address_info.city,
        this.address_info.state,
        this.address_info.country
      ];
      if (address.includes("")) return "Not available";
      return address.join(", ");
    },
    nipost_address_link() {
      return (
        this.address_info.nipost_address &&
        "https://address.gov.ng/Home/address/" +
          this.address_info.nipost_address
      );
    }
    // showRequestAddressButton() {
    //   return (
    //     !this.$route.meta.plainLayout &&
    //     !this.address_info.address_requested &&
    //     this.$User.hasPermission("address-request")
    //   );
    // }
  },
  methods: {
    openVerifyAddressModal() {
      $("#verify-address-modal").modal("show");
    }
  }
};
</script>
