import Vue from "vue";
import Vuex from "vuex";

import auth from "@/modules/auth/_store";
import profile from "@/modules/profile/_store";
import agents from "@/modules/agents/_store";
import loader from "@/store/modules/loader";
import dashboard from "@/modules/dashboard/_store";
import report from "@/modules/reports/_store";
import address from "@/modules/address/_store";
import transactions from "@/modules/transactions/_store";
import settings from "@/modules/settings/_store";
import search from "@/modules/search/_store";
import requestVerification from "@/modules/request-verification/_store";
import agentProfile from "@/modules/agent-profile/_store";
import coupon from "@/modules/coupon/_store";
import users from "@/modules/users/_store";
import roles from "@/modules/roles/_store";
import generateLinks from "@/modules/generate-links/_store";
import contactUs from "@/modules/contact-us/_store";
import audit from "@/modules/audit/_store";
import identity from "@/modules/identity/_store";
import companies from "@/modules/companies/_store";
import guarantor from "@/modules/guarantor/_store";
import printLicenseIds from "@/modules/print-license-ids/_store";
import permits from "@/modules/permits/_store";

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    $_profile: profile,
    $_page_loaders: loader,
    $_dashboard: dashboard,
    $_auth: auth,
    $_report: report,
    $_address: address,
    $_transactions: transactions,
    $_settings: settings,
    $_search: search,
    $_request_verification: requestVerification,
    $_agent_profile: agentProfile,
    $_agents: agents,
    $_coupon: coupon,
    $_users: users,
    $_roles: roles,
    $_generate_links: generateLinks,
    $_contact_us: contactUs,
    $_audit: audit,
    $_identity: identity,
    $_companies: companies,
    $_guarantor: guarantor,
    $_print_license_ids: printLicenseIds,
    $_permits: permits
  },
  strict: debug
});
