import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.GET_PERMITS_ACTION]({ commit }, payload) {
    console.log(payload);
    let permits = await api.getPermits(payload);
    commit(types.SET_PERMITS_LOG, permits.data);
    commit(types.SET_PERMITS_META, permits.meta);
    return permits;
  },
  async [types.SEARCH_PERMITS_ACTION]({ commit }, payload) {
    let permits = await api.searchPermits(payload);
    commit(types.SET_PERMITS_LOG, permits.data);
    commit(types.SET_PERMITS_META, permits.meta);
    return permits;
  },
  async [types.ADD_NEW_PERMIT_ACTION](_, payload) {
    return (await api.addNewPermit(payload)).data;
  },
  async [types.SET_PERMIT_FEE_ACTION](_, payload) {
    return (await api.setPermitFee(payload)).data;
  }
};

export default actions;
