<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="reconcile-transaction-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            Reconcile Transaction
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <div class="col-md-12">
            <template
              v-if="transaction && transaction.payment_mode != 'bank_transfer'"
            >
              <p class="font-14">
                This transaction cannot be reconciled because it's not a bank
                transfer
              </p>
            </template>
            <template
              v-else-if="transaction && transaction.status != 'initiated'"
            >
              <p class="font-14">
                This transaction cannot be reconciled because it has a status of
                <span class="font-bold">{{ transaction.status }}</span>
              </p>
            </template>
            <template v-else>
              <p class="font-14">
                You are about to reconcile a transaction of type
                <span class="font-bold">{{
                  transaction.item_type | title_case
                }}</span>
                and amount of
                <span class="font-bold"
                  >₦{{
                    (parseInt(transaction.amount_in_kobo_approved) / 100)
                      | amount
                  }}</span
                >
                initiated on
                <span class="font-bold">{{
                  transaction.created_at | long_date
                }}</span>
              </p>
              <p class="font-14">
                Please enter the transaction amount to confirm reconciliation
              </p>
              <form id="reconcile-transaction-form">
                <div class="form-group m-b-10">
                  <label for="user-name" class="control-label form-label">
                    Transaction Amount
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model.trim="transaction_amount"
                    @input="$v.transaction_amount.$touch"
                    class="form-control rounded-0"
                    placeholder="Enter Transaction Amount"
                  />
                </div>
              </form>
            </template>
          </div>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              v-if="
                transaction &&
                  transaction.payment_mode === 'bank_transfer' &&
                  transaction.status === 'initiated'
              "
              type="button"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
              @click.prevent="reconcileTransaction"
            >
              Yes, Reconcile Transaction
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numeric, required } from "vuelidate/lib/validators";
import eventBus from "@/helpers/event";
import * as types from "@/store/types";

export default {
  mounted() {
    eventBus.$on(
      "lslb:transaction:show-reconcile-transaction-modal",
      transaction => {
        this.transaction = transaction;
        this.isLoading = false;
        this.transaction_amount = "";
        this.$v.$reset();
      }
    );
  },
  data() {
    return {
      isLoading: false,
      transaction: "",
      transaction_amount: ""
    };
  },
  validations: {
    transaction_amount: {
      required,
      numeric,
      sameAsTransactionAmount: function(amount) {
        return (
          parseInt(amount) * 100 === this.transaction.amount_in_kobo_approved
        );
      }
    }
  },
  methods: {
    async reconcileTransaction() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.RECONCILE_TRANSACTION_ACTION, {
          ...this.$data
        });
        this.$notify.success({
          message: "Transaction reconciliation successful"
        });
        eventBus.$emit("lslb:transaction:transaction-requery-successful");
        eventBus.$emit("lslb:transaction:refresh-transaction-log");
        $("#reconcile-transaction-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
