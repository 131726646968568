import * as types from "@/store/types";

const mutations = {
  async [types.SET_COMPANIES_LOG](state, payload) {
    state.companies_log = payload;
  },
  async [types.SET_COMPANIES_META](state, payload) {
    state.companies_meta = payload;
  },
  async [types.SET_BUSINESS_LICENSE_CATEGORIES](state, payload) {
    state.business_license_categories = payload;
  },
  async [types.SET_ALL_BUSINESSES](state, payload) {
    state.businesses = payload;
  }
};

export default mutations;
