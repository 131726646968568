const getters = {
  getGeneratedLinksLog: state => {
    return state.generated_links_log;
  },
  getGeneratedLinksLogPagination: state => {
    return state.generated_links_meta && state.generated_links_meta.pagination;
  },
  getGeneratedLinksLogMeta: state => state.generated_links_meta
};

export default getters;
