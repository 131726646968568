import * as types from "@/store/types";
const mutations = {
  async [types.SET_TRANSACTIONS_LOG](state, payload) {
    state.transactions_log = payload;
  },
  async [types.SET_TRANSACTIONS_META](state, payload) {
    state.transactions_meta = payload;
  },
  async [types.SET_TRANSACTION](state, payload) {
    state.transaction = payload;
  }
};

export default mutations;
