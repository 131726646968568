<template>
  <section
    id="headerNav"
    :class="$route.meta.isPageTypeHome ? 'home' : 'no-home'"
  >
    <header class="d-flex align-items-center justify-content-between">
      <router-link :to="{ name: 'HomeView' }" class="logo-image-container">
        <img
          src="@/assets/images/new-lslb-logo.png"
          alt="logo"
          class="logo-image"
        />
      </router-link>
      <div>
        <span class="menu-control-open" id="menuControlOpen">
          Menu
        </span>
        <div class="menu">
          <div class="menu-inner">
            <span class="menu-control-close" id="menuControlClose">Close</span>
            <nav>
              <router-link
                :to="{ name: 'HomeView' }"
                class="fade-link menu-link"
              >
                Go Home
              </router-link>
              <template v-if="!$route.fullPath.includes('/agent-profile/')">
                <!--                <router-link-->
                <!--                  :to="{ name: 'RequestVerificationView' }"-->
                <!--                  class="fade-link menu-link"-->
                <!--                >-->
                <!--                  Start Application-->
                <!--                </router-link>-->
                <router-link
                  :to="{ name: 'AgentProfileLogin' }"
                  class="fade-link menu-link"
                >
                  Agent Login
                </router-link>
              </template>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </section>
</template>
<script>
export default {
  name: "AppHeaderPlain",
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        var nav = document.getElementById("headerNav");
        var openMenu = document.getElementById("menuControlOpen"); // or whatever triggers the toggle
        var closeMenu = document.getElementById("menuControlClose"); // or whatever triggers the toggle
        var links = document.getElementsByClassName("menu-link");

        openMenu.addEventListener("click", () => {
          nav.classList.add("menu--active"); // or whatever your active class is
        });

        closeMenu.addEventListener("click", () => {
          nav.classList.remove("menu--active"); // or whatever your active class is
        });

        links.forEach(link => {
          link.addEventListener("click", () => {
            nav.classList.remove("menu--active"); // or whatever your active class is
          });
        });
      }, 0);
    });
  }
};
</script>

<style lang="scss" scoped>
/* START OF USEFUL CODE */
.logo-image {
  width: 6rem;
}

header {
  position: absolute;
  overflow: visible;
  z-index: 100;
  width: 100%;
  height: 5rem;
  padding: 1.5rem;

  a {
    text-decoration: none;
  }

  span.menu-control-open,
  span.menu-control-close,
  nav,
  a {
    font-weight: bold;
    text-transform: uppercase;
  }

  span.menu-control-open,
  span.menu-control-close,
  nav a {
    margin-bottom: 1.5rem;
  }

  span.menu-control-open,
  span.menu-control-close {
    display: block;
    cursor: pointer;
  }

  span.menu-control-open {
    position: relative;
    font-size: 1.2rem;
    line-height: 150%;
    float: right;
    margin-top: 5px;
    svg {
      position: absolute;
      top: -12px;
      left: 12px;
      line,
      circle {
        fill: none;
        stroke: #ffffff;
        stroke-width: 3;
      }
    }
    &:before {
      padding-top: 1px;
    }
    &:before,
    &:after {
      content: "";
      display: block;
      width: 100%;
      border-top: 3px solid wheat;
    }
  }

  span.menu-control-close {
    font-size: 1.2rem;
    text-align: right;
  }

  nav {
    display: flex;
    flex-direction: column;
  }
}

.menu {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #373331;
  height: 100vh;
  width: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  a {
    transform: translate3d(-150px, 0, 0);
    transition: transform 0.15s ease-in;
  }

  .menu-inner {
    position: relative;
    height: 100%;
    width: 80vw;
    padding: 1.5rem;
  }
}

.menu--active {
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  nav a {
    transform: translate3d(0, 0, 0);
    transition: transform 500ms ease-out;

    /* Handles items within the slide-out to give them the delayed slide-in */
    &:first-child {
      transition-delay: 0.2s;
    }
    &:nth-child(2) {
      transition-delay: 0.3s;
    }
    &:last-child {
      transition-delay: 0.4s;
    }
  }
}

// This media query sets the width of the menu to be larger on a smaller screen
@media screen and (max-width: 767px) {
  .menu--active .menu {
    width: 80%;
  }
}

// This media query sets the menu width to be slightly smaller on a larger screen (think an iPad)
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .menu--active .menu {
    width: 40%;
    .menu-inner {
      width: 40vw;
    }
  }
}

// This media query styles the menu for a large environment (think a desktop)
@media screen and (min-width: 1024px) {
  header {
    height: 7rem;
    padding: 2.5rem;

    span.menu-control-open,
    span.menu-control-close {
      display: none;
    }

    .menu {
      position: static;
      overflow: visible;
      background-color: transparent;
      width: auto;
      height: auto;

      .menu-inner {
        padding: 0;
        width: 100%;
      }

      a {
        transform: none;
        transition: none;
      }
    }

    nav {
      flex-direction: row;
      justify-content: flex-end;
      a {
        margin-left: 4rem;
      }
    }
  }
}
</style>
