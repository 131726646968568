var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clearfix"},[_vm._m(0),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14 font-bold text-capitalize text-nowrap",class:[
        _vm.identity_info.status === 'verified'
          ? 'text-success'
          : _vm.identity_info.status === 'not_verified'
          ? 'text-danger'
          : _vm.identity_info.status === 'pending'
          ? 'text-warning'
          : _vm.identity_info.status === 'incomplete'
          ? 'text-primary'
          : ''
      ]},[_vm._v(" "+_vm._s(_vm._f("remove_underscore")(_vm.identity_info.status))+" "),_c('i',{staticClass:"mdi ml-1",class:[
          _vm.identity_info.status === 'verified'
            ? 'mdi-check-circle'
            : _vm.identity_info.status === 'not_verified'
            ? 'mdi-alert'
            : _vm.identity_info.status === 'pending'
            ? 'mdi-clock'
            : _vm.identity_info.status === 'incomplete'
            ? 'mdi-alert-outline'
            : ''
        ]})]),(_vm.identity_info.reason)?_c('p',{staticClass:"small"},[_vm._v(" ("+_vm._s(_vm.identity_info.reason)+") ")]):_vm._e()]),_vm._m(1),_c('div',{staticClass:"col-xs-12"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Click to enlarge image'),expression:"'Click to enlarge image'",modifiers:{"right":true}}],staticClass:"live-photo-popup",attrs:{"href":[
        _vm.identity_info.photo ? _vm.identity_info.photo : '/static/avatar.png'
      ]}},[_c('img',{staticClass:"img-responsive img-thumbnail font-12 bg-light d-block m-b-10",staticStyle:{"width":"150px","height":"150px","object-fit":"cover"},attrs:{"src":[
          _vm.identity_info.photo ? _vm.identity_info.photo : '/static/avatar.png'
        ],"alt":"Agent photo","onerror":"this.src='/static/avatar.png'"}})])]),_vm._m(2),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.identity_info.id_type === "bvn" ? "Bank Verification" : _vm.identity_info.id_type === "nin" ? "National Identification" : _vm.identity_info.id_type === "passport" ? "International Passport" : _vm.identity_info.id_type === "drivers_license" ? "Driver's License" : "Identity"))])]),_c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v(" "+_vm._s(_vm.identity_info.id_type === "bvn" ? "Bank Verification Number" : _vm.identity_info.id_type === "nin" ? "National Identification Number" : _vm.identity_info.id_type === "passport" ? "International Passport Number" : _vm.identity_info.id_type === "drivers_license" ? "Driver's License Number" : "Identity Number")+" ")])]),_c('div',{staticClass:"col-xs-12 m-b-10"},[(
        _vm.identityUpdateState === 'idle' ||
          _vm.identityUpdateState !== 'isIdUpdating'
      )?[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.identity_info.id_number || "Not available"))]),(
          _vm.identityUpdateState === 'idle' &&
            _vm.$User.hasPermission('agent-edit') &&
            _vm.identityNumber
        )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to edit ID number'),expression:"'Click to edit ID number'"}],staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();_vm.identityUpdateState = 'isIdUpdating'}}},[_c('i',{staticClass:"mdi mdi-pencil"}),_vm._v(" Edit ")]):_vm._e()]:_vm._e(),(_vm.identityUpdateState === 'isIdUpdating')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.identityNumber),expression:"identityNumber",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.identityNumber.$error
            },attrs:{"type":"text","name":"identityNumber","placeholder":"Enter Identity Number"},domProps:{"value":(_vm.identityNumber)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.identityNumber=$event.target.value.trim()},_vm.$v.identityNumber.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-12 mt-1"},[_c('button',{staticClass:"btn btn-sm btn-outline-success font-bold",attrs:{"disabled":_vm.loading === 'identityNumberUpdate' ||
                _vm.$v.identityNumber.$invalid ||
                _vm.identityNumber === _vm.identity_info.id_number},on:{"click":function($event){$event.preventDefault();return _vm.updateAgentInformation($event)}}},[_c('i',{staticClass:"mdi",class:_vm.loading === 'identityNumberUpdate'
                  ? 'mdi-loading mdi-spin'
                  : 'mdi-floppy'}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger ml-1 font-bold",attrs:{"disabled":_vm.loading === 'identityNumberUpdate'},on:{"click":function($event){$event.preventDefault();return _vm.resetForms($event)}}},[_c('i',{staticClass:"mdi mdi-cancel"}),_vm._v(" Cancel ")])])])]:_vm._e()],2),_vm._m(3),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.full_name_id || "Not available"))])]),_vm._m(4),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.identity_info.mobile || "Not available"))])]),_vm._m(5),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.identity_info.dob || "Not available"))])]),_c('div',{staticClass:"col-xs-12 m-b-10"},[(_vm.identity_info.can_retry && _vm.$User.hasPermission('identity-submit'))?_c('button',{staticClass:"btn btn-warning",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.retryAgentIdentityVerification($event)}}},[_vm._v(" Retry Agent Verification "),(_vm.loading === 'retryingAgentVerification')?_c('i',{staticClass:"ml-1 mdi mdi-loading mdi-spin"}):_vm._e()]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Identity Image")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Identity Type")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Name on ID")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Phone Number")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Date of Birth")])])}]

export { render, staticRenderFns }