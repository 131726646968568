"use strict";

export default function(nStr) {
  //check for blank, use jquery trim for ie8 compat
  let x, x1, x2;
  if ("" == $.trim(nStr)) {
    return 0;
  }

  // Check if it is a number
  if (!$.isNumeric(nStr)) {
    return nStr;
  }

  nStr += "";
  x = nStr.split(".");
  x1 = x[0];
  x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}
