<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Coupons" />

    <!-- ============================================================== -->
    <!--  component -->
    <!-- ============================================================== -->
    <div class="row m-t-15">
      <div class="col-md-12">
        <search-coupon-by-reference
          v-if="$User.hasPermission('coupon-view')"
        ></search-coupon-by-reference>
        <request-coupon-view
          v-if="$User.hasPermission('coupon-generate')"
        ></request-coupon-view>
        <generated-coupons-log
          v-if="$User.hasPermission('coupon-view')"
        ></generated-coupons-log>
        <get-coupon-quotation-modal></get-coupon-quotation-modal>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import GeneratedCouponsLog from "@/components/coupon/GeneratedCouponsLog";
import RequestCouponView from "./_components/RequestCoupon";
import SearchCouponByReference from "./_components/SearchCouponByReference";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";
import $User from "@/helpers/User";
import store from "@/store";
import GetCouponQuotationModal from "@/modules/coupon/_components/GetCouponQuotationModal";
import moment from "moment";

// import eventBus from "@/helpers/event";

export default {
  components: {
    AppTitle,
    GeneratedCouponsLog,
    RequestCouponView,
    SearchCouponByReference,
    GetCouponQuotationModal
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        page = 1,
        category = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;

      if (
        $User.hasPermission("coupon-generate") &&
        $User.hasPermission("coupon-view")
      ) {
        await Promise.all([
          store.dispatch(types.GET_VERIFICATION_PRICE),
          store.dispatch(types.GET_GENERATED_COUPONS_ACTION, {
            page,
            category,
            start_date,
            end_date
          })
        ]);
      } else if ($User.hasPermission("coupon-generate")) {
        await store.dispatch(types.GET_VERIFICATION_PRICE);
      } else if ($User.hasPermission("coupon-view")) {
        await store.dispatch(types.GET_GENERATED_COUPONS_ACTION, {
          page: 1
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        page = 1,
        category = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      await store.dispatch(types.GET_GENERATED_COUPONS_ACTION, {
        page,
        category,
        start_date,
        end_date
      });
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  beforeDestroy() {
    $("#get-coupon-quotation-modal").modal("hide");
    $("#get-coupon-quotation-modal").on("hidden.bs.modal", function() {
      $("#get-coupon-quotation-modal").modal("dispose");
    });
  }
};
</script>
