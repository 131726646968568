import * as types from "@/store/types";
const mutations = {
  async [types.SET_AUDIT_LOG_EVENTS](state, payload) {
    state.audit_log_events = payload;
  },
  async [types.SET_AUDIT_LOG](state, payload) {
    state.audit_log = payload;
  },
  async [types.SET_AUDIT_META](state, payload) {
    state.audit_meta = payload;
  }
};

export default mutations;
