<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="success-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30 justify-content-end">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div
            class="d-flex flex-column align-items-center mx-auto"
            style="max-width: 24rem"
          >
            <img
              src="/static/check-mark.svg"
              alt="bulk upload icon"
              style="width: 6rem"
            />
            <p class="mt-4 lead font-weight-bold text-dark">Success!!!</p>
            <p v-if="single" class="font-14" style="text-align: center">
              Uploaded and processed successfully
              {{ email }}
            </p>
            <p v-else class="font-14" style="text-align: center">
              You file has been successfully uploaded, and is being processed.
              Please check your email (
              <span class="text-primary">{{ userEmail }}</span> ) for the
              result.
            </p>
            <button
              type="button"
              class="btn text-warning btn-link"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    email: {
      type: String,
      default: ""
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      admin: "getCurrentUser"
    }),
    userEmail() {
      return this.email || this.admin.email;
    }
  },
  methods: {
    closeModal() {
      $("#success-modal").modal("hide");
    }
  }
};
</script>
