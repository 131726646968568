import * as types from "@/store/types";
import api from "../_api";
import VerificationService from "@/services/verifications";

const actions = {
  async [types.REQUEST_AGENT_PROFILE_OTP_ACTION](_, payload) {
    let response = await api.requestAgentProfileOTP(payload);
    return response.data;
  },
  async [types.RESOLVE_AGENT_INVITATION]({ commit, getters }, payload) {
    let response = await api.resolveAgentInvitation(payload);

    commit(
      types.SET_AGENT_PROFILE_AGENT,
      Object.assign({}, getters.getCheckAgentProfile, {
        agent_businesses: response.data.agent_businesses
      })
    );
    return response.data.agent_businesses;
  },
  async [types.DISSOCIATE_FROM_OPERATOR]({ commit, getters }, payload) {
    let response = await api.dissociateAgentInvitation(payload);

    commit(
      types.SET_AGENT_PROFILE_AGENT,
      Object.assign({}, getters.getCheckAgentProfile, {
        agent_businesses: response.data.agent_businesses
      })
    );
    return response.data.agent_businesses;
  },
  async [types.DISSOCIATE_AGENT_FROM_OPERATOR]({ commit, getters }, payload) {
    let response = await api.dissociateAgentInvitation(payload);

    commit(
      types.SET_AGENT_PROFILE_AGENT,
      Object.assign({}, getters.getCheckAgentProfile, {
        agent_businesses: response.data.agent_businesses
      })
    );
    return response.data.agent_businesses;
  },
  async [types.DISSOCIATE_AGENT_FROM_OPERATOR]({ commit, getters }, payload) {
    let response = await api.dissociateFromAgentInvitation(payload);

    commit(
      types.SET_AGENT_PROFILE_AGENT,
      Object.assign({}, getters.getCheckAgentProfile, {
        agent_businesses: response.data.agent_businesses
      })
    );
    return response.data.agent_businesses;
  },
  async [types.ADD_NEW_AGENT_ADDRESS]({ commit, getters }, payload) {
    let response = await api.updateAgentAddress(payload);

    commit(
      types.SET_AGENT_PROFILE_AGENT,
      Object.assign({}, getters.getCheckAgentProfile, {
        agent_businesses: response.data.agent_businesses
      })
    );
    return response.data.agent_businesses;
  },
  async [types.MARK_NOTIFICATION_AS_READ]({ commit, getters }, payload) {
    //console.log(getters.profile.getCurrentUser);
    //return;
    let response = await api.markNotificationAsRead(payload);
    // let profile = getters.getCheckAgentProfile;
    // profile.notifications = response.data.notifications;

    commit(
      types.SET_AGENT_PROFILE_AGENT,
      Object.assign({}, getters.getCheckAgentProfile, {
        notifications: response.data.notifications
      })
    );
    return response.data.notifications;
  },
  async [types.AGENT_PROFILE_ACTION]({ commit }, payload) {
    let profile = await api.agentProfile(payload);
    commit(types.SET_AGENT_PROFILE_AGENT, profile.data);
    return profile.data;
  },
  async [types.GET_AGENT_PROFILE_REPORT_ACTION](
    { commit },
    { verification_id }
  ) {
    let verification = await VerificationService.getRequestVerification(
      verification_id
    );
    commit(types.SET_AGENT_PROFILE_REPORT, verification.data);
    return verification.data;
  },
  async [types.REQUEST_AGENT_DATA_ACTION](_, payload) {
    let response = await api.requestAgentData(payload);
    return response.data;
  },
  async [types.DOWNLOAD_AGENT_DATA_ACTION](_, payload) {
    let response = await api.downloadAgentData(payload);
    return response.data;
  },
  async [types.DELETE_AGENT_DATA_ACTION](_, payload) {
    let response = await api.deleteAgentData(payload);
    return response;
  },
  async [types.DELETE_AGENT_ADDRESS](_, payload) {
    let response = await api.deleteShopAddress(payload);
    console.log(response);
    return response;
  },
  async [types.INITIATE_PERMIT_RENEWAL](_, payload) {
    let response = await api.agentInitiatePermitRenewal(payload);
    return response.data;
  },
  async [types.VERIFY_PERMIT_RENEWAL](_, id) {
    let response = await api.agentVerifyPermitRenewal(id);
    return response;
  }
};

export default actions;
