<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="edit-profile-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            Edit Profile
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="add-single-user-form" class="row">
            <div
              class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
              v-if="message"
            >
              {{ message }}
            </div>

            <!-- <div class="col-md-6 my-2">
              <div class="form-group m-b-10">
                <label for="first_name" class="control-label form-label">
                  First Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="first_name"
                  :class="{ 'form-error animated shake': $v.first_name.$error }"
                  @input="$v.first_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter First Name"
                />
              </div>
            </div>

            <div class="col-md-6 my-2">
              <div class="form-group m-b-10">
                <label for="last_name" class="control-label form-label">
                  Last Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="last_name"
                  :class="{ 'form-error animated shake': $v.last_name.$error }"
                  @input="$v.last_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Last Name"
                />
              </div>
            </div> -->

            <div class="col-md-12 my-2">
              <div class="form-group m-b-10">
                <label for="email" class="control-label form-label">
                  Email Addreess
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="email"
                  :class="{ 'form-error animated shake': $v.email.$error }"
                  @input="$v.email.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Email Address"
                />
              </div>
            </div>

            <div class="col-md-12 my-2">
              <div class="form-group m-b-10">
                <label for="phone_number" class="control-label form-label">
                  Mobile Number
                  <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control rounded-0"
                  type="text"
                  :class="{
                    'form-error animated shake': $v.phone_number.$error
                  }"
                  v-model.trim="phone_number"
                  @input="$v.phone_number.$touch"
                  required="required"
                  placeholder="Enter Phone Number"
                />
              </div>
            </div>

            <!-- <div class="col-md-12 my-2">
              <div class="form-group">
                <label for="res_address" class="font-bold form-label">
                  Residential Address</label
                >
                <span class="text-danger">*</span>
                <vue-google-autocomplete
                  id="res_address"
                  :value="res_address"
                  classname="form-control address-input-field z-9999"
                  placeholder="Enter Residential Address"
                  v-on:placechanged="getResAddressData"
                >
                </vue-google-autocomplete>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="shopAddress" class="font-bold form-label">
                  Shop Addresses</label
                >
                <span class="text-danger">*</span>
                <vue-google-autocomplete
                  v-for="(shop, index) in shop_addresses"
                  :key="index"
                  :value="shop.address"
                  classname="form-control address-input-field my-1 z-9999"
                  :id="'shopAddress-' + index"
                  :placeholder="'Enter Shop Address ' + (Number(index) + 1)"
                  v-on:placechanged="getShopAddress"
                >
                </vue-google-autocomplete>
                <span
                  v-if="shopAddressError"
                  class="text-sm block text-red-600 my-4 text-center text-danger font-14"
                  >{{ shopAddressError }}</span
                >
              </div>
              <button
                type="button"
                class="btn-sm btn btn-outline-warning"
                @click="handleAddAddress"
              >
                Add Address
              </button>
            </div> -->
          </form>
        </div>
        <div class="modal-footer b-0 justify-content-end">
          <div class="col-sm-12">
            <button
              type="button"
              @click.prevent="handleSubmit"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning rounded-0 w-100 waves-effect p-10 p-10"
            >
              Submit
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  email,
  required,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import * as types from "@/store/types";

export default {
  // components: { VueGoogleAutocomplete },
  props: {
    profile: Object
  },
  data() {
    return {
      email: this.profile.email?.value || "",
      phone_number: this.profile.mobile?.value || "",
      // first_name: this.profile.first_name || "",
      // last_name: this.profile.last_name?.value || "",
      // shop_addresses: { 0: { address: "" } },
      // addressCount: 0,
      // res_address: "",
      // shopAddressError: null,

      isLoading: false,
      message: ""
    };
  },
  validations: {
    email: { required, email },
    // first_name: { required },
    // last_name: { required },
    phone_number: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    }
  },
  methods: {
    setShopAddresses() {
      let shopAddresses = [];
      this.profile.agent_businesses
        .filter(item => item.status === "active")
        .forEach(shops => {
          const addresses = shops.shop_addresses.map(shop => {
            if (shopAddresses.includes(shop)) return;
            return shop.address;
          });
          shopAddresses.push(...addresses);
        });

      shopAddresses.forEach((shop, i) => {
        this.shop_addresses[i] = { address: shop };
        this.addressCount = i;
      });
    },
    async handleSubmit() {
      if (this.$v.$invalid || this.isLoading) {
        return;
      }

      this.message = "";
      this.isLoading = true;
      const payload = {
        email: this.email,
        mobile: this.phone_number,
        id: this.profile.id
        // first_name: this.first_name,
        // last_name: this.last_name,
        // shop_addresses: Object.values(this.shop_addresses),
        // residential_address: this.res_address
      };

      try {
        await this.$store.dispatch(types.UPDATE_AGENT_DETAILS, payload);
        this.isLoading = false;
        this.$v.$reset();
        $("#edit-profile-modal").modal("hide");
        this.$notify.success({ message: "Profile updated successfully" });
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    handleAddAddress() {
      this.addressCount += 1;
      this.shop_addresses = {
        ...this.shop_addresses,
        [this.addressCount]: { address: "", region: "" }
      };
    },
    getResAddressData(addressData) {
      if (addressData.street_number) {
        this.res_address = {
          address: `${addressData.street_number} ${addressData.route} ${addressData.locality} ${addressData.country}`
        };
      } else {
        this.res_address = {
          address: `${addressData.route}, ${addressData.locality}, ${addressData.country}`
        };
      }
    },
    getShopAddress(addressData, _, id) {
      const index = id.split("-")[1];
      if (addressData.street_number) {
        this.shop_addresses[index] = {
          address: `${addressData.street_number} ${addressData.route} ${addressData.locality} ${addressData.country}`
          // region: addressData.locality
        };
      } else {
        this.shop_addresses[index] = {
          address: `${addressData.route}, ${addressData.locality}, ${addressData.country}`
          // region: addressData.locality
        };
      }
    },
    resetForm() {
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.phone = "";
      this.shop_addresses = { 0: { address: "" } };
      this.res_address = "";
      this.$v.$reset();
    },
    handleSuccessModal() {
      $("#success-modal").modal("show");
    }
  }
};
</script>
