<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="add-multiple-users-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            Add Multiple Users
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="add-multiple-users-form">
            <div
              class="col-xs-12 alert font-normal font-14 font-bold rounded-0 text-center"
              v-if="response"
              :class="
                response.status === 'error'
                  ? 'alert-danger-light'
                  : 'alert-success-light'
              "
            >
              {{ response.message }}
            </div>
            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label class="font-bold form-label">
                  Upload CSV List
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="file"
                  data-max-file-size="15M"
                  data-allowed-file-extensions="csv"
                  data-height="100"
                  class="form-control rounded-0 dropify w-100"
                  @change="setUpload"
                />
                <a
                  :href="VUE_APP_DOWNLOAD_BASE_URL + 'add-multiple-users'"
                  target="_blank"
                  class="small"
                  >Download example CSV template</a
                >
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              :disabled="isLoading || $v.$invalid"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
              @click.prevent="addMultipleUsers"
            >
              <i class="mr-1 mdi mdi-upload"></i>
              Upload CSV
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as types from "@/store/types";

export default {
  mounted() {
    // initialize dropify
    this.dropifyEvent = $(".dropify").dropify({
      messages: {
        default: "Drag and drop csv file here or click",
        replace: "Drag and drop or click to replace",
        remove: "X",
        error: "Ooops, something happened."
      }
    });

    this.dropifyEvent.on("dropify.afterClear", () => {
      this.upload = "";
      this.csv = "";
    });
  },
  data() {
    return {
      VUE_APP_DOWNLOAD_BASE_URL: process.env.VUE_APP_DOWNLOAD_BASE_URL,
      dropifyEvent: "",
      upload: "",
      csv: "",
      response: "",
      isLoading: false
    };
  },
  validations: {
    upload: {
      required
    }
  },
  methods: {
    async addMultipleUsers() {
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        this.response = "";
        if (!this.csv) {
          let csv = await this.$store.dispatch(types.UPLOAD_CSV, {
            file: this.upload
          });
          this.csv = csv.location;
        }
        let response = await this.$store.dispatch(
          types.ADD_MULTIPLE_USERS_ACTION,
          {
            ...this.$data
          }
        );
        this.response = {
          message: response.message,
          status: "success"
        };
        let dropifyDestroy = this.dropifyEvent.data("dropify");
        if (dropifyDestroy.isDropified()) {
          dropifyDestroy.resetPreview();
        }
        this.csv = "";
        this.upload = "";
        this.$v.$reset();
        // this.$notify.success({
        //   message: response.message
        // });
      } catch (error) {
        if (error && error.data) {
          this.response = {
            message: error.data.message,
            status: "error"
          };
          // this.$notify.error({
          //   message: error.data.message
          // });
        }
      } finally {
        this.isLoading = false;
      }
    },
    setUpload(input) {
      if (input.target.files && input.target.files[0]) {
        this.upload = input.target.files[0];
        this.csv = "";
        this.$v.upload.$touch();
      }
    }
  }
};
</script>
