<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Account" />
      <!-- ============================================================== -->
      <!-- ============================================================== -->
      <!-- Tab component -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-12">
          <div class="no-card">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs customtab" role="tablist">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'ProfileView' }"
                  active-class="active"
                  class="nav-link"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-account-circle"></i>
                  </span>
                  <span class="hidden-xs-down">User Profile</span>
                </router-link>
              </li>
              <li
                class="nav-item"
                v-if="$User.hasPermission('transaction-view')"
              >
                <router-link
                  :to="{ name: 'TransactionLogView' }"
                  active-class="active"
                  class="nav-link"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-cash-usd"></i>
                  </span>
                  <span class="hidden-xs-down">Transactions</span>
                </router-link>
              </li>
            </ul>
            <!-- Tab panes -->
            <!-- /content -->
            <!-- ============================================================== -->
            <!-- Router view -->
            <!-- ============================================================== -->
            <div class="tab-content">
              <div class="tab-pane active" role="tabpanel">
                <div class="m-t-15">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
export default {
  name: "AccountContainer",
  components: {
    AppTitle
  },
  created() {}
};
</script>
