<template>
  <div class="card bordered-box">
    <div class="card-block row">
      <div class="col-md-8">
        <h3 class="card-title">Search Agent by Permit Number</h3>
        <form class="m-b-10" @submit.prevent="searchCouponByLicenseNumber">
          <div class="form-group m-b-10">
            <div class="input-group">
              <input
                type="text"
                v-model.trim="lslb_license_number"
                @input="$v.lslb_license_number.$touch"
                :class="{
                  'form-error animated shake': $v.lslb_license_number.$error
                }"
                placeholder="Enter Agent's LSLGA Permit Number"
                class="form-control rounded-0"
              />
              <button
                class="btn btn-warning input-group-addon"
                type="submit"
                :disabled="$v.$invalid || state === 'loading'"
              >
                Search
                <i
                  :class="
                    state === 'loading'
                      ? 'mdi mdi-loading mdi-spin'
                      : 'mdi mdi-magnify'
                  "
                ></i>
              </button>
            </div>
            <h3 class="card-title mt-5" v-if="state === 'error'">
              {{ errorMessage }}
            </h3>
          </div>
        </form>
      </div>
      <div
        v-if="admin.user_type === 'lslb' || admin.user_type === 'youverify'"
        class="col-md-4"
      >
        <div
          class="d-flex mb-4 align-center justify-content-end"
          style="gap: 1rem"
        >
          <button
            class="btn btn-outline-warning"
            @click="showSingleOnbaordModal"
          >
            Single Update
          </button>
          <button class="btn btn-warning" @click="showBulkUploadModal">
            Bulk Upload
          </button>
        </div>
      </div>
      <div class="col-12" v-if="state === 'success'">
        <div class="col-md-12 m-b-10 clearfix">
          <h3 class="card-title float-left">
            Search result for "{{ agent.email && agent.email.value }}"
          </h3>
          <a
            href="javascript:void(0)"
            @click.prevent="state = 'idle'"
            class="m-l-5 btn btn-outline-danger btn-xs font-bold"
          >
            close
          </a>
        </div>
        <div class="col-md-12 m-b-10">
          <div class="d-flex flex-wrap align-items-center">
            <img
              class="img-responsive rounded-circle img-thumbnail font-12 bg-light d-block mr-3 m-b-10"
              :src="[agent.photo ? agent.photo.url : '/static/avatar.png']"
              alt="Agent photo"
              style="width: 100px; height: 100px; object-fit: cover;"
              onerror="this.src='/static/avatar.png'"
            />
            <div>
              <div class="col-xs-12">
                <span class="font-bold">
                  <template v-if="agent.last_name">{{
                    `${agent.last_name.value.toUpperCase()}, ${
                      agent.first_name
                    } ${agent.middle_name}`
                  }}</template>
                  <template v-else>Name not available</template>
                </span>
              </div>
              <div class="col-xs-12">
                <span class="text-muted font-14">
                  {{
                    (agent.email && agent.email.value) || "Email not available"
                  }}
                </span>
              </div>
              <div class="col-xs-12">
                <span class="text-muted font-14">
                  {{
                    (agent.mobile && agent.mobile.value) ||
                      "Phone number not available"
                  }}
                </span>
              </div>
              <div class="col-xs-12">
                <router-link
                  :to="{
                    name: 'AgentView',
                    params: {
                      agent_id: agent.id
                    }
                  }"
                  class="btn btn-outline-warning btn-sm"
                >
                  Go to profile <span class="mdi mdi-arrow-right"></span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <single-onboard-modal
      :operators="businesses"
      :permits="business_license_categories"
    />
    <bulk-upload-modal
      :operators="businesses"
      :permits="business_license_categories"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import BulkUploadModal from "@/modules/settings/_components/bulk-upload-modal";
import SingleOnboardModal from "@/modules/settings/_components/single-onboard-modal";

export default {
  name: "SearchCouponsByReferenceID",
  components: { BulkUploadModal, SingleOnboardModal },
  data() {
    return {
      agent: "",
      lslb_license_number: "",
      state: "idle",
      errorMessage: ""
    };
  },
  validations: {
    lslb_license_number: {
      required
    }
  },
  computed: {
    ...mapGetters({
      admin: "getCurrentUser",
      businesses: "getAllBusinesses",
      business_license_categories: "getBusinessLicenseCategories"
    })
  },
  mounted() {
    this.$store.dispatch(types.GET_ALL_BUSINESSES),
      this.$store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES);
  },
  methods: {
    async searchCouponByLicenseNumber() {
      if (this.$v.$invalid || this.state === "loading") return false;
      this.state = "loading";
      try {
        let response = await this.$store.dispatch(
          types.SEARCH_AGENT_BY_PERMIT_NUMBER,
          this.lslb_license_number
        );
        this.lslb_license_number = "";
        this.$v.$reset();
        console.log(response.agent);
        this.agent = response.agent;
        this.state = "success";
      } catch (error) {
        if (error && error.data) {
          this.errorMessage = error.data.message;
          this.state = "error";
        }
      }
    },
    showSingleOnbaordModal() {
      $("#single-upload-modal").modal("show");
    },
    showBulkUploadModal() {
      $("#bulk-upload-modal").modal("show");
    }
  }
};
</script>
