import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.GET_ALL_ROLES_ACTION]({ commit }) {
    let all_roles = (await api.getAllRoles()).data;
    commit(types.SET_ALL_ROLES, all_roles);
    return all_roles;
  },
  async [types.CREATE_NEW_CUSTOM_ROLE_ACTION]({ commit, state }, payload) {
    let role = (await api.createNewCustomRole(payload)).data;
    role.users = [];
    commit(types.SET_ALL_ROLES, [...state.all_roles, role]);
    commit(types.SET_SELECTED_ROLE, role);
    return role;
  },
  async [types.UPDATE_CUSTOM_ROLE_ACTION]({ commit, state }, payload) {
    let role = (await api.updateCustomRole(payload)).data;
    role.users = state.selected_role.users;
    let all_roles = JSON.parse(JSON.stringify(state.all_roles));
    all_roles.forEach((_role, index) => {
      if (_role.id === state.selected_role.id) {
        all_roles[index] = role;
      }
    });
    commit(types.SET_ALL_ROLES, all_roles);
    commit(types.SET_SELECTED_ROLE, role);
    return role;
  },
  async [types.DELETE_CUSTOM_ROLE_ACTION](
    { commit, dispatch, getters },
    payload
  ) {
    let role = (await api.deleteCustomRole(payload)).data;
    let id = getters.getSelectedRole.id;
    await dispatch(
      types.GET_ROLE_WITH_USERS_ACTION,
      getters.getAllDefaultRoles[0]
    );
    let all_roles = JSON.parse(JSON.stringify(getters.getAllRoles));
    all_roles.forEach((_role, index) => {
      if (_role.id === id) {
        all_roles.splice(index, 1);
      }
    });
    commit(types.SET_ALL_ROLES, all_roles);
    return role;
  },
  async [types.GET_ROLE_WITH_USERS_ACTION]({ commit, state }, payload) {
    let role;
    if (state.selected_role && state.selected_role.id === payload.id) {
      role = state.selected_role;
    } else {
      role = (await api.getARoleWithUsers(payload)).data;
    }
    commit(types.SET_SELECTED_ROLE, role);
    return role;
  }
};
export default actions;
