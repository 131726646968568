<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <h3 class="card-title">Upload CSV</h3>
      <div class="row m-t-15">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 col-sm-12 p-l-0">
              <div class="form-group m-b-10">
                <label class="col-md-12 font-bold form-label">
                  Upload CSV List
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-12">
                  <input
                    type="file"
                    data-max-file-size="15M"
                    data-allowed-file-extensions="csv"
                    data-height="100"
                    class="form-control rounded-0 dropify"
                    @change="setUpload"
                  />
                  <a
                    :href="VUE_APP_DOWNLOAD_BASE_URL + 'generate-links'"
                    target="_blank"
                    class="small"
                    >Download example CSV template</a
                  >
                  <div class="font-14">
                    <strong class="font-weight-bold">Note: </strong>Only allowed
                    regions are
                    <template v-for="(region, index) in regions"
                      >{{ region
                      }}{{
                        regions.length - 1 === index ? "." : ", "
                      }}</template
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 p-l-0">
              <div class="form-group m-b-10">
                <label class="col-md-12 font-bold form-label">
                  Title
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-12">
                  <input
                    type="text"
                    class="rounded-0 form-control"
                    v-model.trim="title"
                    placeholder="Enter Title Here"
                    @input="$v.title.$touch"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 p-l-0">
              <div class="form-group m-b-10">
                <label class="col-md-12 font-bold form-label">
                  Receipient Email Address(es)
                  <!-- <span class="text-danger">*</span> -->
                  <i
                    class="mdi mdi-help-circle-o text-muted ml-1"
                    v-tooltip="{
                      content:
                        'Type in the email addresses of receipient separated by either a comma, space, tab or enter key',
                      classes: ['light-tooltip'],
                      placement: 'top'
                    }"
                  ></i>
                </label>
                <div class="col-md-12">
                  <div class="email-tag-input-container">
                    <vue-tags-input
                      :validation="validation"
                      :add-on-key="[13, 188, 9, 32]"
                      :allow-edit-tags="true"
                      :avoid-adding-duplicates="true"
                      :separators="[',']"
                      v-model.trim="tag"
                      @before-adding-tag="checkTag"
                      :tags="tags"
                      @tags-changed="setTags"
                      type="text"
                      class="rounded-0"
                      placeholder="Enter receipient(s) email"
                    />
                  </div>
                  <p
                    class="text-danger font-bold font-12"
                    v-if="isEmailTagError"
                  >
                    Please enter a valid email address
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 m-t-10 p-l-0">
              <div class="form-group m-b-10">
                <div class="col-md-12 m-t-5">
                  <button
                    class="btn btn-warning waves-effect rounded-0 w-100"
                    :disabled="$v.$invalid || isLoading"
                    @click.prevent="generateCsvLinks"
                  >
                    <span class="mdi mdi-upload"></span>
                    Submit List
                    <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";
import * as types from "@/store/types";
import { required } from "vuelidate/lib/validators";
import eventBus from "@/helpers/event";
import { mapGetters } from "vuex";

export default {
  mounted() {
    // initialize dropify
    this.dropifyEvent = $(".dropify").dropify({
      messages: {
        default: "Drag and drop csv file here or click",
        replace: "Drag and drop or click to replace",
        remove: "X",
        error: "Ooops, something happened."
      }
    });

    this.dropifyEvent.on("dropify.afterClear", () => {
      this.upload = "";
      this.csv = "";
    });

    // initialize tag select
    $(".email-tag-input-container .new-tag-input")
      .on("focus", function() {
        $(this)
          .parents(".email-tag-input-container")
          .addClass("is-hover");
      })
      .on("blur", function() {
        $(this)
          .parents(".email-tag-input-container")
          .removeClass("is-hover");
      });
    // focus tag input on wrapper click
    $(".email-tag-input-container").on("click", function() {
      $(".new-tag-input").focus();
    });
  },
  components: {
    VueTagsInput
  },
  data() {
    return {
      VUE_APP_DOWNLOAD_BASE_URL: process.env.VUE_APP_DOWNLOAD_BASE_URL,
      dropifyEvent: "",
      csv: "",
      upload: "",
      title: "",
      isEmailTagError: false,
      tag: "",
      tags: [],
      validation: [
        {
          type: "email",
          rule: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,})+$/,
          disableAdd: false
        }
      ],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      regions: "getRequestVerificationRegions"
    })
  },
  validations: {
    // tags: {
    //   required
    // },
    upload: {
      required
    },
    title: {
      required
    }
  },
  methods: {
    async generateCsvLinks() {
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.csv) {
          let csv = await this.$store.dispatch(types.UPLOAD_CSV, {
            file: this.upload
          });
          this.csv = csv.location;
        }
        await this.$store.dispatch(types.GENERATE_LINKS_ACTION, {
          ...this.$data
        });
        this.tags = [];
        this.title = "";
        let dropifyDestroy = this.dropifyEvent.data("dropify");
        if (dropifyDestroy.isDropified()) {
          dropifyDestroy.resetPreview();
        }
        this.csv = "";
        this.upload = "";
        this.$v.$reset();
        eventBus.$emit("lslb:link:refresh-generated-links-log");
        this.$notify.success({
          message: "Links request has been sent successfully"
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    setUpload(input) {
      if (input.target.files && input.target.files[0]) {
        this.upload = input.target.files[0];
        this.csv = "";
        this.$v.upload.$touch();
      }
    },
    checkTag(obj) {
      let re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,})+$/;
      if (!re.test(obj.tag.text.toLowerCase())) {
        this.isEmailTagError = true;
        return;
      }
      // add the tag
      this.isEmailTagError = false;
      obj.addTag();
    },
    setTags(newTags) {
      //set the tags
      this.tags = newTags;
      //validate
      this.$v.tags.$touch();
    }
  }
};
</script>
