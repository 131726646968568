var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clearfix"},[_vm._m(0),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14 font-bold text-capitalize text-nowrap",class:_vm.nin_info.status === 'verified'
          ? 'text-success'
          : _vm.nin_info.status === 'not_verified'
          ? 'text-danger'
          : _vm.nin_info.status === 'pending'
          ? 'text-warning'
          : _vm.nin_info.status === 'incomplete'
          ? 'text-primary'
          : null},[_vm._v(" "+_vm._s(_vm._f("remove_underscore")(_vm.nin_info.status))+" "),_c('i',{staticClass:"mdi ml-1",class:_vm.nin_info.status === 'verified'
            ? 'mdi-check-circle'
            : _vm.nin_info.status === 'not_verified'
            ? 'mdi-alert'
            : _vm.nin_info.status === 'pending'
            ? 'mdi-clock'
            : _vm.nin_info.status === 'incomplete'
            ? 'mdi-alert-outline'
            : null})]),(_vm.nin_info.reason)?_c('p',{staticClass:"small"},[_vm._v("("+_vm._s(_vm.nin_info.reason)+")")]):_vm._e()]),_vm._m(1),_c('div',{staticClass:"col-xs-12"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.right",value:('Click to enlarge image'),expression:"'Click to enlarge image'",modifiers:{"right":true}}],staticClass:"live-photo-popup",attrs:{"href":_vm.nin_info.photo ? _vm.nin_info.photo : '/static/avatar.png'}},[_c('img',{staticClass:"img-responsive img-thumbnail font-12 bg-light d-block m-b-10",staticStyle:{"width":"150px","height":"150px","object-fit":"cover"},attrs:{"src":_vm.nin_info.photo ? _vm.nin_info.photo : '/static/avatar.png',"alt":"Agent photo","onerror":"this.src='/static/avatar.png'"}})])]),_vm._m(2),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.nin_info.id_type === "bvn" ? "Bank Verification" : _vm.nin_info.id_type === "nin" ? "National Identification" : _vm.nin_info.id_type === "passport" ? "International Passport" : _vm.nin_info.id_type === "drivers_license" ? "Driver's License" : "Identity"))])]),_vm._m(3),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.nin_info.id_number || "Not available"))])]),_vm._m(4),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.full_name_id || "Not available"))])]),_vm._m(5),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.nin_info.mobile || "Not available"))])]),_vm._m(6),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.nin_info.dob || "Not available"))])]),_vm._m(7),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.nin_info.address || "Not available"))])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("NIN Image")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Identity Type")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Identity Number")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Name on ID")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Phone Number")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Date of Birth")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Address")])])}]

export { render, staticRenderFns }