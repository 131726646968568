<template>
  <div class="card bordered-box">
    <div class="card-block">
      <h3 class="card-title">Profile Information</h3>
      <form @submit.prevent="updateUserProfile">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                User's Picture
                <span class="text-danger">*</span>
              </label>
              <input
                type="file"
                data-max-file-size="15M"
                data-allowed-file-extensions="jpeg jpg png"
                data-height="100"
                data-show-loader="true"
                class="form-control rounded-0 dropify"
                @change="setImage"
              />
            </div>
          </div>
          <div class="w-100"></div>

          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="first-name" class="font-bold form-label">
                First Name
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="first_name"
                :class="{ 'form-error animated shake': $v.first_name.$error }"
                @input="$v.first_name.$touch"
                class="form-control rounded-0"
                id="first-name"
                placeholder="Your First Name"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="middle-name" class="font-bold form-label"
                >Middle Name</label
              >
              <input
                type="text"
                v-model.trim="middle_name"
                @input="$v.middle_name.$touch"
                class="form-control rounded-0"
                id="middle-name"
                placeholder="Your Middle Name"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="last-name" class="font-bold form-label">
                Last Name
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="last_name"
                :class="{ 'form-error animated shake': $v.last_name.$error }"
                @input="$v.last_name.$touch"
                class="form-control rounded-0"
                id="last-name"
                placeholder="Your Last Name"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="phone-number" class="font-bold form-label">
                Phone Number
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="mobile"
                :class="{ 'form-error animated shake': $v.mobile.$error }"
                @input="$v.mobile.$touch"
                class="form-control rounded-0"
                id="phone-number"
                placeholder="Your Phone Number"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="gender" class="font-bold form-label">Gender</label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="gender"
                @change="$v.gender.$touch"
                :disabled="current_user.gender"
              >
                <option value disabled>-- select gender --</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="email-address" class="font-bold form-label">
                Email
                <span class="text-danger">*</span>
              </label>
              <input
                type="email"
                readonly="readonly"
                v-model.trim="email"
                class="form-control rounded-0"
                id="email-address"
                placeholder="Your Email Address"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="form-group m-b-10">
              <label class="control-label font-bold form-label">Role</label>
              <br />
              <label class="label label-light-megna p-t-5 p-b-5">{{
                current_user.role.name
              }}</label>
            </div>
          </div>
        </div>

        <div class="row" v-if="!$User.isOperator()">
          <div class="col-md-12">
            <div class="form-group m-b-10">
              <label class="control-label font-bold form-label">Password</label>
              <br />
              <button
                class="btn btn-outline-success"
                @click.prevent="showChangePasswordModal"
              >
                Change Password
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group m-b-0">
              <button
                :disabled="$v.$invalid || isLoading || !$v.$anyDirty"
                type="submit"
                class="btn btn-warning w-100 p-t-10 p-b-10 rounded-0 waves-effect m-t-10"
              >
                Save Changes
                <span v-if="isLoading">
                  <i class="mdi mdi-loading mdi-spin"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- ============================================================== -->
    <!-- Change Password Modal -->
    <!-- ============================================================== -->
    <change-password-modal />
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric
} from "vuelidate/lib/validators";
import ChangePasswordModal from "./_components/ChangePasswordModal";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  data() {
    return {
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile: "",
      image: "",
      gender: "",
      photo: "",
      imageUploaded: "",
      email: "",
      isLoading: false
    };
  },
  mounted() {
    // initialize dropify
    let drEvent = $(".dropify").dropify({
      messages: {
        default: "Drag and drop a passport image here or click",
        replace: "Drag and drop or click to replace",
        remove: "REMOVE",
        error: "Ooops, something happened."
      }
    });

    drEvent.on("dropify.afterClear", () => {
      this.image = "";
      this.imageUploaded = false;
    });

    if (this.current_user.photo) {
      let dropifyDestroy = drEvent.data("dropify");
      if (dropifyDestroy.isDropified()) {
        dropifyDestroy.resetPreview();
        dropifyDestroy.setPreview(true, this.current_user.photo);
      }
    }

    this.photo = this.current_user.photo;
    this.imageUploaded = this.current_user.photo;
    this.image = this.current_user.photo;
    this.first_name = this.current_user.first_name;
    this.middle_name = this.current_user.middle_name;
    this.last_name = this.current_user.last_name;
    this.mobile = this.current_user.mobile;
    this.email = this.current_user.email;
    this.gender = this.current_user.gender;
    this.$v.image.$reset();
  },
  components: {
    ChangePasswordModal
  },
  validations: {
    first_name: { required },
    last_name: { required },
    middle_name: {},
    gender: {},
    mobile: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    image: {
      required
    }
  },
  computed: {
    ...mapGetters({
      current_user: "getCurrentUser"
    })
  },
  methods: {
    showChangePasswordModal() {
      $("#change-password-modal").modal("show");
    },
    updateUserProfile() {
      this.$v.$touch();
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      this.isLoading = true;
      if (this.image && !this.imageUploaded) {
        this._uploadPassport();
      } else {
        if (this.photo || this.imageUploaded) {
          this._updateUserProfile();
        }
      }
    },
    setImage(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.$v.image.$touch();
        this.imageUploaded = false;
      }
    },
    async _uploadPassport() {
      try {
        let passport = await this.$store.dispatch(
          types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION,
          {
            file: this.image
          }
        );
        this.photo = passport.location;
        this.imageUploaded = true;
        this._updateUserProfile();
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    },
    async _updateUserProfile() {
      try {
        let payload = { ...this.$data };
        if (this.current_user.gender) {
          payload.gender = this.current_user.gender;
        }
        payload.email = this.current_user.email;
        await this.$store.dispatch(types.UPDATE_USER_PROFILE_ACTION, payload);
        this.$notify.success({
          message: "User profile updated successfully"
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    }
  }
};
</script>
