const getters = {
  getCompaniesLog: state => state.companies_log,
  getCompaniesLogMeta: state => state.companies_meta,
  getCompaniesLogPagination: state => {
    return state.companies_meta && state.companies_meta.pagination;
  },
  getBusinessLicenseCategories: state => {
    return state.business_license_categories;
  },
  getAllBusinesses: state => {
    return state.businesses;
  },
  getBusinessById: state => {
    return state.business;
  }
};

export default getters;
