// components
import RolesView from "@/modules/roles/Index";

export default [
  {
    path: "/ecosystems/users/roles",
    component: RolesView,
    name: "RolesView",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["user-manage-role"],
      documentTitle: "Roles"
    }
  }
];
