import * as types from "@/store/types";

const mutations = {
  async [types.SET_GENERATED_LINKS_LOG](state, payload) {
    state.generated_links_log = payload;
  },
  async [types.SET_GENERATED_LINKS_META](state, payload) {
    state.generated_links_meta = payload;
  }
};

export default mutations;
