import Vue from "vue";
import NProgress from "vue-nprogress";
import Vuelidate from "vuelidate";
import flatpickr from "flatpickr";
import * as VueGoogleMaps from "vue2-google-maps";

flatpickr.setDefaults({
  disableMobile: "true",
  errorHandler: () => {}
});
import flatPickr from "vue-flatpickr-component";
import VTooltip from "v-tooltip";
import VSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import LaravelVuePagination from "laravel-vue-pagination";
// import "flatpickr/dist/flatpickr.css";
import App from "./App.vue";
import router from "@/routes";
import store from "@/store/index";
// register notify globally as $notify
import notify from "@/helpers/notifyMe";
Vue.prototype.$notify = notify;
import User from "@/helpers/User";
Vue.prototype.$User = User;
import filters from "./filters/";
import VModal from "vue-js-modal";
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;
VTooltip.enabled = window.innerWidth > 768;

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCEcRlmpz1871b0RH5Uuq-RYhD09piUy0I",
    libraries: "places"
  }
});

Vue.use(Vuelidate);
Vue.use(VTooltip);
Vue.use(flatPickr);
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#40df6d",
  cancelButtonColor: "#d9534f",
  position: "top",
  focusCancel: true,
  animation: false,
  customClass: "animated fadeInDown faster",
  confirmButtonClass: "btn btn-warning mr-3",
  cancelButtonClass: "btn btn-danger",
  buttonsStyling: false
});
Vue.use(filters);
Vue.component("pagination", LaravelVuePagination);
Vue.component("v-select", VSelect);
Vue.use(NProgress, {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false, // Show progressbar when doing Vue.http, default: true
  axios: true
});
Vue.use(VModal);
Vue.use(VueClipboard);

const nprogress = new NProgress({
  parent: ".nprogress-container"
});

const myApp = new Vue({
  nprogress,
  store,
  router,
  render: h => h(App)
}).$mount("#app");

export { myApp };
