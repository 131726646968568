<template>
  <div class="row">
    <!-- <div class="col-sm-12 col-md-8 offset-md-2"> -->
    <div class="col-sm-12">
      <div class="no-card mb-0">
        <div class="card-block">
          <div class="py-5 px-2 flex-box d-flex w-100">
            <div class="text-center align-self-center m-auto">
              <h1>{{ title }}</h1>
              <i
                class="text-success mdi mdi-check-circle-outline ml-1 display-1"
              ></i>
              <p class="font-18">
                {{ message }}
              </p>
              <p class="display-6">Thank you.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuarantorFormSubmittedSuccessfullyView",
  data() {
    return {
      title: "Response received Successfully",
      message: "Your response has been received successfully"
    };
  },
  beforeRouteEnter(to, from, next) {
    //console.log(to.params)
    if (
      ["GuarantorPreviewView", "GuarantorConsentActionView"].includes(from.name)
    ) {
      if (to.params.title && to.params.message) {
        return next(vm => {
          vm.title = to.params.title;
          vm.message = to.params.message;
        });
      }
      return next();
    }
    return next({ name: "GuarantorConsentActionView" });
  }
  // computed: {
  //   ...mapGetters({
  //     verification: "getRequestVerification"
  //   })
  // }
};
</script>
