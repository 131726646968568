<template>
  <div class="w-100">
    <report-component
      v-bind="{ verification }"
      :hide_report="hide_report"
      @show_report="show_report = true"
    >
      <template slot="header">
        <table style="table-layout: fixed; width: 100%;">
          <tr>
            <td style="width: 100%">
              <div style="width: 100%; overflow-x: auto;">
                <ul
                  class="nav nav-tabs customtab scrollable bg-light rounded"
                  role="tablist"
                >
                  <li
                    class="nav-item"
                    v-for="{
                      name,
                      componentName,
                      verificationKey
                    } in actualTabRoutes"
                    :key="name"
                  >
                    <a
                      @click.prevent="active_component = componentName"
                      href="#"
                      class="nav-link"
                      :class="
                        active_component === componentName ? 'active' : null
                      "
                      role="tab"
                    >
                      {{ name }}
                      <i
                        class="mdi"
                        :class="
                          verification[verificationKey].status === 'verified'
                            ? 'text-success mdi-check-circle'
                            : verification[verificationKey].status ===
                              'not_verified'
                            ? 'text-danger mdi-alert'
                            : verification[verificationKey].status === 'pending'
                            ? 'text-warning mdi-clock'
                            : verification[verificationKey].status ===
                              'incomplete'
                            ? 'text-primary mdi-alert-outline'
                            : verification[verificationKey].status ===
                              'awaiting_qa'
                            ? 'text-info mdi-clock'
                            : verification[verificationKey].status ===
                              'completed'
                            ? null
                            : 'text-warning mdi-alert'
                        "
                      ></i>
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </table>
      </template>
      <component :is="active_component" v-bind="verification"></component>
    </report-component>
    <div v-if="hide_report" class="overlay"></div>
  </div>
</template>

<script>
import ReportComponent from "@/components/report/ReportComponent";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import { mapGetters } from "vuex";

import IdentityVerificationReport from "@/components/report/IdentityVerificationReport";
import NinVerificationReport from "@/components/report/NinVerificationReport";
import DriverLicenseReport from "@/components/report/DriverLicenseReport";
import AddressReport from "@/components/report/AddressReport";
import ResidentialAddressReport from "@/components/report/ResidentialAddressReport";
import GuarantorReport from "@/components/report/GuarantorReport";

export default {
  name: "AgentProfileReportView",
  components: {
    IdentityVerificationReport,
    NinVerificationReport,
    DriverLicenseReport,
    AddressReport,
    ResidentialAddressReport,
    GuarantorReport,
    ReportComponent
  },
  data() {
    return {
      active_component: "IdentityVerificationReport",
      tabRoutes: [
        {
          name: "Identity Verification",
          componentName: "IdentityVerificationReport",
          verificationKey: "identity_info"
        },
        {
          name: "Business Address",
          componentName: "AddressReport",
          verificationKey: "address_info"
        },
        {
          name: "Residential Address",
          componentName: "ResidentialAddressReport",
          verificationKey: "residential_address"
        },
        {
          name: "Guarantor",
          componentName: "GuarantorReport",
          verificationKey: "guarantor_info"
        }
      ],
      show_report: false
    };
  },
  computed: {
    ...mapGetters({
      verification: "getAgentProfileReport"
    }),
    actualTabRoutes() {
      return this.tabRoutes.filter(tabRoute => {
        return this.verification[tabRoute.verificationKey].completed;
      });
    },
    hide_report() {
      return (
        (this.verification.status === "pending" ||
          this.verification.status === "incomplete") &&
        !this.show_report
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch(types.GET_AGENT_PROFILE_REPORT_ACTION, {
        verification_id: to.params.verification_id
      })
      .then(() => {
        next();
      })
      .catch(error => {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
        next(false);
      });
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
