import * as types from "@/store/types";
const mutations = {
  async [types.SET_PERMITS_LOG](state, payload) {
    state.permits_log = payload;
  },
  async [types.SET_PERMITS_META](state, payload) {
    state.permits_meta = payload;
  }
};

export default mutations;
