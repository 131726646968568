import * as types from "@/store/types";
const mutations = {
  async [types.SET_AGENT_PROFILE_AGENT](state, payload) {
    state.check_agent_profile = payload;
  },
  async [types.SET_AGENT_PROFILE_REPORT](state, payload) {
    state.agent_profile_report = payload;
  }
};

export default mutations;
