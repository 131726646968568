<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <h3 class="card-title">Application Links</h3>
      <form class="filter-report-form">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="status" class="control-label">
                Status
                <span class="text-danger">*</span>
              </label>
              <select
                id="status"
                class="form-control custom-select rounded-0"
                v-model.trim="status"
                :class="{ 'form-error animated shake': $v.status.$error }"
              >
                <option value disabled>-- select status --</option>
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="generated">Generated</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label
                for="start_date"
                class="control-label"
                style="display:block"
              >
                Start Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <div class="clearfix">
                <flat-pickr
                  v-model.trim="start_date"
                  :class="{ 'form-error animated shake': $v.start_date.$error }"
                  class="form-control rounded-0"
                  :config="startDateConfig"
                  placeholder="Start Date"
                ></flat-pickr>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="end_date" class="control-label" style="display:block">
                End Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <!-- <div
                class="clearfix"
                v-tooltip="!start_date ? 'Please select a start date' : ''"
              > -->
              <!-- <input type="text" class="form-control" placeholder="End Date"> -->
              <flat-pickr
                v-model.trim="end_date"
                :class="{ 'form-error animated shake': $v.end_date.$error }"
                class="form-control rounded-0"
                :config="endDateConfig"
                placeholder="End Date"
              ></flat-pickr>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label class="control-label" style="display:block">&nbsp;</label>
              <button
                :disabled="isLoading || $v.$invalid"
                class="btn btn-warning normalize-filter-btn rounded-0 m-r-5"
                @click.stop.prevent="filterGeneratedLinkLogs"
              >
                <i class="mdi mdi-filter"></i>
                Filter
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <div class="p-t-10 p-b-10" v-if="isFilter">
            <label class="text-info font-normal font-14">
              Application links log filtered by
              <span
                class="badge badge-pill badge-default mx-1"
                v-if="$route.query.status"
                ><strong>Status: </strong
                >{{ $route.query.status | remove_underscore }}</span
              ><span v-if="$route.query.start_date || $route.query.end_date">
                between {{ startDateDefault | date }} and
                {{ endDateDefault | date }}
              </span>
            </label>
            <button
              @click.stop="clearFilter"
              class="ml-3 btn btn-outline-info btn-xs font-bold"
            >
              clear filter
            </button>
          </div>
          <div class="p-t-10 p-b-10" v-if="!isFilter">
            <label class="text-info font-medium font-14"
              >Showing all application links</label
            >
          </div>
          <div class="table-responsive">
            <template v-if="generated_links.length > 0">
              <table class="table table-hover">
                <thead>
                  <tr class="link-row">
                    <th width="70" class="text-center">#</th>
                    <th>Status</th>
                    <th>Title</th>
                    <th class="text-nowrap">Generated/Total - Dups</th>
                    <th class="text-nowrap">Initiated By</th>
                    <th>Action</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="link-row"
                    v-for="(generated_link, index) in generated_links"
                    :key="generated_link.id"
                  >
                    <td class="text-center">{{ pagination.from + index }}</td>
                    <td>
                      <span
                        class="label text-capitalize font-bold rounded-0"
                        :class="[
                          generated_link.status === 'pending'
                            ? 'label-light-megna'
                            : 'label-light-info'
                        ]"
                        >{{ generated_link.status }}</span
                      >
                    </td>
                    <td class="text-capitalize">{{ generated_link.title }}</td>
                    <td class="text-uppercase" v-if="generated_link.generated">
                      {{ generated_link.num_processed | amount }}/{{
                        generated_link.num_of_verifications | amount
                      }}
                      - {{ generated_link.duplicates | amount }}
                    </td>
                    <td class="text-uppercase" v-else>
                      {{ generated_link.num_of_verifications | amount }}
                    </td>
                    <td class="text-capitalize">
                      {{ generated_link.user.first_name }}
                      {{ generated_link.user.middle_name }}
                      {{ generated_link.user.last_name }}
                    </td>
                    <td>
                      <template
                        v-if="
                          generated_link.generated &&
                            $User.hasPermission('verification-link-download')
                        "
                      >
                        <span
                          v-tooltip="'CSV will be sent to your email address'"
                        >
                          <button
                            class="btn btn-xs btn-outline-info"
                            @click.stop.prevent="
                              downloadLinks(generated_link, index)
                            "
                            :disabled="isDownloading"
                          >
                            Download CSV
                            <!-- <i
                              v-if="isDownloading === index"
                              class="mdi mdi-loading mdi-spin"
                            ></i>-->
                          </button>
                        </span>
                      </template>
                      <template v-else>
                        <span
                          class="label label-light-megna font-bold text-capitalize"
                          >Pending</span
                        >
                      </template>
                    </td>
                    <td>{{ generated_link.created_at | long_date }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="p-30 test font-medium text-muted">
                <span>
                  Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.total }} entries
                </span>
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                <img
                  src="/static/empty.svg"
                  class="img-fluid mb-3"
                  alt="empty"
                  style="width: 50px;"
                />
                <p class="font-bold font-14">
                  There are no application links to display
                </p>
              </div>
            </template>
          </div>
          <div class="text-center p-l-30 m-t-20">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";

export default {
  name: "GeneratedLinkLogView",
  created() {
    eventBus.$on("lslb:link:refresh-generated-links-log", () => {
      this.getLogByPage(this.pagination.current_page);
    });
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      status: "",
      start_date: "",
      end_date: "",
      isDownloading: false,
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      isLoading: false
    };
  },
  validations: {
    status: { required },
    start_date: {},
    end_date: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      generated_links: "getGeneratedLinksLog",
      pagination: "getGeneratedLinksLogPagination",
      business_profile: "getBusinessProfile"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment(this.$store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        )
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return this.$route.query.status;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterGeneratedLinkLogs() {
      if (this.validateForm()) {
        let { start_date, end_date, status } = this;

        let query = {
          ...(status && status !== "all" && { status }),
          ...(start_date && { start_date }),
          ...(end_date && { end_date })
        };
        this.$router.push({
          name: this.$route.name,
          query
        });
      }
    },
    getLogByPage(page) {
      let { status, start_date, end_date } = this.$route.query;

      let query = {
        [Date.now()]: null,
        ...(status && { status }),
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date })
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    async downloadLinks(generated_link) {
      try {
        this.isDownloading = true;
        await this.$store.dispatch(
          types.DOWNLOAD_GENERATED_LINKS_ACTION,
          generated_link
        );
        this.$notify.success({
          message: "Generated links CSV has been sent to your mail."
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isDownloading = false;
      }
    },
    async clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.status = "";
        this.start_date = "";
        this.end_date = "";
        this.$v.$reset();
      });
    }
  }
};
</script>
