<template>
  <div>
    <div class="row mb-3 justify-content-between align-items-center">
      <div class="col-12 col-sm-7 col-xs-12 px-4">
        <h3 class="mb-0">
          {{ currentRequest.created_at }}
        </h3>
        <h1 class="page-title mt-4">
          Operator’s information
        </h1>
        <svg
          class="mb-0 pb-0"
          width="183"
          height="4"
          viewBox="0 0 183 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="2" x2="183" y2="2" stroke="#FFB42E" stroke-width="4" />
        </svg>
      </div>
      <div class="col-12 px-0 mt-2">
        <h4 class="col-12 info-title pt-2 px-4">Company Name:</h4>
        <h4 class="col-12 info-content font-weight-bolder pt-1 pb-2 px-4">
          {{ currentRequest.business.business_name }}
        </h4>
        <hr />
        <h4 class="col-12 info-title pt-2 px-4">Trade Name:</h4>
        <h4 class="col-12 info-content font-weight-bolder pt-1 pb-2 px-4">
          {{ currentRequest.business.trade_name }}
        </h4>
        <hr />
        <h4 class="col-12 info-title pt-2 px-4">Date Sent:</h4>
        <h4 class="col-12 info-content font-weight-bolder pt-1 pb-2 px-4">
          {{ currentRequest.created_at }}
        </h4>
        <hr />
      </div>
      <div class="col-12 col-xs-12 px-4">
        <img src="../../assets/images/zebra-line.svg" width="100%" alt="" />
        <div
          class="d-block d-md-inline-flex col-12 px-0 justify-content-between mt-3"
        >
          <button
            @click="success"
            class="col-5 text-center py-3 btn btn-success"
          >
            Accept
          </button>
          <button class="col-5 text-center py-3 ml-auto btn btn-outline-danger">
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";

export default {
  name: "AgentCompanyAssociationRequest",
  data() {
    return {};
  },
  props: ["currentRequest"],
  created() {
    localStorage.removeItem("twk_deeplink");
  },
  methods: {
    success() {
      this.$swal({
        title: "Request Completed",
        type: "success",
        confirmButtonText: "Okay Great",
        position: "center"
      }).then(() => {
        // this.$router.push({ name: "AgentProfile"})
        this.$router.push({ name: "AgentCompanies" });
      });
    },
    async resolveAgentApplication(business_id, accepted) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.RESOLVE_AGENT_INVITATION, {
          business_id,
          accepted,
          profile_id: this.profile_id
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.page-title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 21px;
  color: #f5b600;
}

.info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 17px;
  color: #7b7c7d;
}

.info-content {
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 19px;
  color: #404e5d;
}

.btn-success {
  background-color: #4c963f;
}
</style>
