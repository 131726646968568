import { myApp } from "@/main";

class User {
  static hasPermission(permission) {
    let user_permissions = myApp.$store.getters.getUserPermissions;
    return user_permissions && user_permissions.indexOf(permission) !== -1;
  }

  static isEnforcer() {
    return myApp.$store.getters.getCurrentUser?.role?.name === "Enforcer";
  }

  static isOperator() {
    return myApp.$store.getters.getCurrentUser?.user_type === "company";
  }
}

export default User;
