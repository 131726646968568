// components
import TransactionLogView from "@/modules/transactions/Index";
import TransactionView from "@/components/transaction/TransactionView";
import AuditView from "@/modules/audit/Index";
import ProfileView from "@/modules/profile/Index";
import AccountContainer from "@/containers/Account";

export default [
  {
    path: "/account",
    component: AccountContainer,
    redirect: {
      name: "ProfileView"
    },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "profile",
        component: ProfileView,
        name: "ProfileView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: [],
          documentTitle: "User Profile"
        }
      },
      {
        path: "transactions",
        component: TransactionLogView,
        name: "TransactionLogView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["transaction-view"],
          documentTitle: "Transactions"
        }
      }
    ]
  },
  {
    path: "/account/audit-log",
    component: AuditView,
    name: "AuditView",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["audit-log-view"],
      documentTitle: "Audit Log"
    }
  },
  {
    path: "/account/transactions/:transaction_id",
    component: TransactionView,
    name: "TransactionView",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: [],
      documentTitle: "Transaction View"
    }
  }
];
