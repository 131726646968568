<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 400px;">
      <form
        class="card-block form-horizontal form-material2"
        @submit.prevent="resetUserPassword"
      >
        <template v-if="!hasResetPassword">
          <div class="form-group">
            <div class="col-xs-12">
              <h3 class="text-center">Reset Password</h3>
            </div>
            <div
              class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
              v-if="message"
            >
              {{ message }}
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="form-label" for="reset_new_password">
                New Password
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-xs-12 input-group">
              <input
                id="reset_new_password"
                class="form-control rounded-0"
                type="password"
                v-model.trim="new_password"
                :class="{ 'form-error animated shake': $v.new_password.$error }"
                @input="$v.new_password.$touch"
                required
                placeholder="Enter New Password"
              />
            </div>
            <div class="form-group mt-2" v-show="new_password">
              <div class="col-xs-12 font-14">
                At least;
                <ul class="list-unstyled">
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.new_password.containsLowerCase
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One lowercase letter
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.new_password.containsUpperCase
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One uppercase letter
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.new_password.containsSpecialCharacter
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One special character (allowed characters ! @ # $ % ^ & _ -)
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.new_password.containsNumber
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One number
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.new_password.isGreaterThanEight
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    Eight characters
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <transition
            enter-active-class="animated fastest fadeIn"
            leave-active-class="animated fastest fadeOut"
          >
            <div class="form-group" v-show="!$v.new_password.$invalid">
              <div class="col-xs-12">
                <label class="form-label" for="reset_confirm_password">
                  Confirm Password
                  <span class="text-danger">*</span>
                </label>
              </div>
              <div class="col-xs-12">
                <input
                  id="reset_confirm_password"
                  class="form-control rounded-0"
                  type="password"
                  v-model.trim="confirm_password"
                  :class="{
                    'form-error animated shake': $v.confirm_password.$error
                  }"
                  @input="$v.confirm_password.$touch"
                  required
                  placeholder="Confirm New Password"
                />
                <div
                  v-if="!$v.confirm_password.sameAsNewPassword"
                  class="text-danger m-t-5 font-12 font-bold"
                >
                  Must be the same as new password
                </div>
              </div>
            </div>
          </transition>
          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button
                :disabled="isLoading || $v.$invalid"
                class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                type="submit"
              >
                Reset Password
                <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-if="hasResetPassword">
          <div class="form-group text-center">
            <h1>Password Reset Successful</h1>
            <i
              class="text-success mdi mdi-check-circle-outline ml-1 display-1"
            ></i>
            <p class="font-14 font-bold">
              Please click the link below to go back to login
            </p>
          </div>
        </template>
        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center">
            <router-link :to="{ name: 'LoginView' }" class="text-dark m-l-5">
              <strong>Back to login</strong>
            </router-link>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";

import {
  isGreaterThanEight,
  containsNumber,
  containsLowerCase,
  containsUpperCase,
  containsSpecialCharacter
} from "@/helpers/customValidations";

export default {
  name: "ResetPassword",
  async beforeRouteEnter(to, from, next) {
    try {
      // await store.dispatch(
      //   types.VERIFY_RESET_PASSWORD_TOKEN_ACTION,
      //   to.params.token
      // );
      next();
      return;
    } catch (error) {
      if (error && error.data) {
        notify.error({ message: error.data.message });
        store.commit(types.SET_LOGIN_ERROR, error.data.message);
        next({
          name: "LoginView"
        });
        return;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      new_password: "",
      confirm_password: "",
      hasResetPassword: false,
      message: ""
    };
  },
  validations: {
    new_password: {
      required,
      isGreaterThanEight,
      containsLowerCase,
      containsUpperCase,
      containsNumber,
      containsSpecialCharacter
    },
    confirm_password: {
      sameAsNewPassword: sameAs("new_password")
    }
  },
  methods: {
    async resetUserPassword() {
      this.message = "";
      if (!this.$v.$invalid) {
        this.isLoading = true;
        try {
          await store.dispatch(types.RESET_USER_PASSWORD_ACTION, {
            password: this.new_password,
            confirm_password: this.confirm_password,
            token: this.$route.params.token
          });
          this.isLoading = false;
          this.hasResetPassword = true;
          // notify.success({
          //   message: "Password reset successfully. You can now login."
          // });
          this.message = "Password reset successfully. You can now login.";
          // this.$router.push({
          //   name: "LoginView"
          // });
        } catch (error) {
          this.isLoading = false;
          error && error.data && notify.error({ message: error.data.message });
        }
      }
    }
  }
};
</script>
