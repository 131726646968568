<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Agent Profile" />

    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <div class="row m-t-15">
      <div class="col-md-12">
        <div id="report-box" class="no-card bordered-box">
          <div id="report-block">
            <div class="d-flex align-items-center">
              <router-link
                :to="{ name: 'AgentsView' }"
                class="btn btn-link rounded-0 p-10 text-warning mr-4"
              >
                <i class="mdi mdi-arrow-left"></i>
                Go Back
              </router-link>
              <h3 class="card-title" style="flex-grow: 1; margin-bottom: 0;">
                Report Log for "{{
                  agent_profile.lslb_license_number ||
                    agent_profile.email.value
                }}"
              </h3>
              <button
                v-if="
                  admin.user_type === 'lslb' || admin.user_type === 'youverify'
                "
                class="btn btn-outline-warning"
                @click="handleEdit"
              >
                Edit Profile
              </button>
            </div>
            <div class="row m-t-15">
              <div class="col-md-4">
                <agent-profile
                  :agent_profile="agent_profile"
                  :show_business_address="profile.user_type !== 'company'"
                ></agent-profile>
              </div>
              <div class="col-md-8">
                <div class="card bordered-box">
                  <div class="card-block">
                    <ul
                      class="nav nav-tabs customtab"
                      role="tablist"
                      v-show="!showDissociation"
                    >
                      <li class="nav-item">
                        <a
                          @click.prevent="
                            active_component = 'AgentVerificationsLog'
                          "
                          href="#"
                          class="nav-link"
                          :class="{
                            active: active_component === 'AgentVerificationsLog'
                          }"
                          role="tab"
                        >
                          All Applications
                        </a>
                      </li>
                      <!--                      <li class="nav-item" v-if="false">-->
                      <!--                        <a-->
                      <!--                          @click.prevent="-->
                      <!--                            active_component = 'AgentTransactionsLog'-->
                      <!--                          "-->
                      <!--                          href="#"-->
                      <!--                          class="nav-link"-->
                      <!--                          :class="{-->
                      <!--                            active: active_component === 'AgentTransactionsLog'-->
                      <!--                          }"-->
                      <!--                          role="tab"-->
                      <!--                        >-->
                      <!--                          All Transactions-->
                      <!--                        </a>-->
                      <!--                      </li>-->
                      <li class="nav-item">
                        <a
                          @click.prevent="active_component = 'AgentComments'"
                          href="#"
                          class="nav-link"
                          :class="{
                            active: active_component === 'AgentComments'
                          }"
                          role="tab"
                        >
                          All Comments
                        </a>
                      </li>
                    </ul>
                    <div
                      class="col-12 col-sm-7 col-xs-12 px-4"
                      v-show="showDissociation"
                    >
                      <h3 class="mb-0">
                        Reason for Dissociation
                      </h3>
                      <small class="col-12 px-0">
                        Why do you want to dissociate from this agent?
                      </small>
                    </div>
                    <!-- Tab panes -->
                    <!-- /content -->
                    <!-- ============================================================== -->
                    <!-- Router view -->
                    <!-- ============================================================== -->
                    <div class="tab-content">
                      <div class="tab-pane active" role="tabpanel">
                        <div class="m-t-15" v-show="!showDissociation">
                          <component
                            :is="active_component"
                            v-bind="sub_data"
                          ></component>
                          <div class="col-12 px-4 mt-5">
                            <button
                              class="col-12 px-0 py-2 text-center"
                              style="background-color: transparent; border: 1px #FF5454 solid; color: #FF5454"
                              v-if="!showDissociationRequest"
                              @click="showDissociation = true"
                            >
                              <img
                                src="../../../assets/images/dissociate-btn.svg"
                                alt=""
                              />
                              Dissociate from agent
                            </button>
                            <button
                              class="col-12 px-0 py-2 text-center mb-3"
                              style="background-color: transparent; border: 1px #f4b700 solid; color: #f4b700"
                              v-else
                              @click="acceptDissociationRequest(true)"
                            >
                              Accept Agent Dissociation Request
                            </button>
                            <button
                              class="col-12 px-0 py-2 text-center"
                              style="background-color: transparent; border: 1px #FF5454 solid; color: #FF5454"
                              v-if="showDissociationRequest"
                              @click="acceptDissociationRequest(false)"
                            >
                              <img
                                src="../../../assets/images/dissociate-btn.svg"
                                alt=""
                              />
                              Reject Agent Dissociation Request
                            </button>
                          </div>
                        </div>
                        <div class="m-t-15" v-show="showDissociation">
                          <div class="col-12 px-4">
                            <textarea
                              name=""
                              id=""
                              rows="10"
                              class="p-4 mt-3"
                              placeholder="Eg. They have too many stringent policies. "
                              v-model="dissociationReason"
                            ></textarea>
                          </div>
                          <div
                            class="col-12 d-block d-sm-inline-flex px-4 mt-4"
                          >
                            <button
                              class="col-5 col-sm-3 px-0 py-2 text-center"
                              style="background-color: #FFB42E; color: white"
                              @click.prevent="
                                showDissociateCountryApplicationDialog
                              "
                            >
                              Submit
                            </button>
                            <button
                              class="col-5 col-sm-3 ml-0 ml-md-4 px-0 py-2 text-center"
                              style="border: 1px solid #404E5D;
                      border-radius: 3px;
                      background-color: white;
                      color: black"
                              @click="showDissociation = false"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <edit-profile-modal :profile="agent_profile" />
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";
import AgentProfile from "@/components/agent/AgentProfile";
import AgentVerificationsLog from "@/components/agent/AgentVerificationsLog";
import AgentTransactionsLog from "@/components/agent/AgentTransactionsLog";
import AgentComments from "./AgentComments";
import http from "@/helpers/http";
import EditProfileModal from "@/modules/agents/_components/edit-profile-modal";

import { mapGetters } from "vuex";

export default {
  name: "AgentView",
  components: {
    AppTitle,
    AgentProfile,
    AgentVerificationsLog,
    AgentTransactionsLog,
    AgentComments,
    EditProfileModal
  },
  data() {
    return {
      active_component: "AgentVerificationsLog",
      dissociationReason: "",
      showDissociation: false,
      showDissociationRequest: false
    };
  },
  computed: {
    ...mapGetters({
      admin: "getCurrentUser",
      agent_profile: "getAgentProfile",
      verifications: "getAgentVerifications",
      transactions: "getAgentVerifications",
      profile: "getCurrentUser"
    }),
    sub_data() {
      localStorage.setItem("id", this.profile.business._id);
      switch (this.active_component) {
        case "AgentVerificationsLog":
          return {
            verifications: this.verifications,
            verification: this.agent_profile.verification,
            type: "ReportView"
          };
        case "AgentTransactionsLog":
          return { transactions: this.transactions, type: "ReportView" };
        case "AgentComments":
          return { agent: this.agent_profile };
        default:
          return { verifications: this.verifications };
      }
    }
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.GET_AGENT_PROFILE_ACTION, to.params.agent_id);
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
    }
  },
  methods: {
    showDissociateCountryApplicationDialog() {
      this.$swal({
        title: "Submit Dissociation Request",
        type: "question",
        showCancelButton: true,
        confirmButtonText: `Dissociate from agent`,
        cancelButtonText: "No, cancel!"
      }).then(() => {
        this.dissociateFromOperator().then(() => {
          this.dissociationReason = "";
          this.showDissociation = false;
        });
      });
    },

    async dissociateFromOperator() {
      this.loading = true;
      try {
        await this.$store
          .dispatch(types.DISSOCIATE_AGENT_FROM_OPERATOR, {
            reason: this.dissociationReason,
            agent_id: this.sub_data.agent._id
          })
          .then(response => {
            this.$notify.success({
              message: "Dissociation request successfully submitted"
            });
            notify.success({
              message: response.data.message
            });
          });
        this.currentRequest = null;
        this.$router.push("/ecosystems/agents");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },
    async acceptDissociationRequest(accepted) {
      this.loading = true;
      try {
        await http
          .post("businesses/dissociation-request", {
            agent_id: this.agent_profile._id,
            accepted: accepted
          })
          .then(response => {
            this.$notify.success({
              message: response.message
            });
            localStorage.removeItem("request_deeplink");
            this.$router.push({ name: "AgentsView" });
          });
        this.currentRequest = null;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },
    handleEdit() {
      $("#edit-profile-modal").modal("show");
    }
  }
};
</script>

<style scoped>
textarea {
  width: 100%;
  border: 1px solid #404e5d;
  box-sizing: border-box;
  border-radius: 3px;
}

button {
  border: none;
  border-radius: 3px;
  cursor: pointer !important;
}
</style>
