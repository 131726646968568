<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 400px;">
      <form class="card-block form-horizontal">
        <div class="form-group mb-0">
          <div
            class="col-xs-12 alert alert-danger-light font-normal font-14 font-bold rounded-0 text-center"
            v-if="error"
          >
            {{ error }}
          </div>

          <div
            class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
            v-if="showPasswordForm && auth_type === 'otp'"
          >
            Please enter the code sent to your email address
          </div>
          <div class="col-xs-12 text-center mb-3">
            <h3 v-if="!showPasswordForm">Login</h3>
            <h3 v-else-if="showPasswordForm && auth_type === 'password'">
              Enter Password
            </h3>
            <h3 v-else-if="showPasswordForm && auth_type === 'otp'">
              Enter OTP
            </h3>
          </div>
        </div>
        <transition
          mode="out-in"
          enter-active-class="animated fastest zoomIn"
          leave-active-class="animated fastest zoomOut"
        >
          <div class="email" key="email" v-if="!showPasswordForm">
            <div class="form-group text-center">
              <span class="d-block mb-3 font-14 font-bold"
                >Continue to LSLGA Permit Portal Dashboard</span
              >
              <div class="col-xs-12">
                <input
                  class="form-control rounded-0"
                  type="text"
                  :class="{ 'form-error animated shake': $v.email.$error }"
                  v-model.trim="email"
                  name="email"
                  id="login-email"
                  required="required"
                  @input="$v.email.$touch"
                  placeholder="Email Address"
                  autocomplete="email"
                />
              </div>
            </div>
            <div class="form-group clearfix">
              <div class="checkbox checkbox-warning p-t-0">
                <input
                  id="checkbox-signin"
                  type="checkbox"
                  v-model="remember_me"
                  :disabled="$v.email.$invalid || isLoading"
                />
                <label class="no-select font-14 font-bold" for="checkbox-signin"
                  >Remember me</label
                >
              </div>
            </div>
            <div class="form-group text-center m-t-20">
              <div class="col-xs-12">
                <button
                  :disabled="$v.email.$invalid || isLoading"
                  @click.prevent="lookupEmail"
                  class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                  type="submit"
                >
                  Continue
                  <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="password" key="password" v-else>
            <div class="form-group text-center">
              <a
                href="#"
                @click.prevent="showPasswordForm = false"
                v-if="showPasswordForm"
                class="d-block text-info font-14 font-bold mb-3"
                ><i class="mdi mdi-arrow-left font-20 align-middle"></i>
                {{ email }}</a
              >

              <div
                class="col-xs-12 input-group"
                v-if="auth_type === 'password'"
              >
                <input
                  id="login-password"
                  class="form-control rounded-0"
                  :type="password_visibility ? 'text' : 'password'"
                  :class="{ 'form-error animated shake': $v.password.$error }"
                  v-model.trim="password"
                  @input="$v.password.$touch"
                  required="required"
                  placeholder="Password"
                />
                <div
                  @click="password_visibility = !password_visibility"
                  class="input-group-addon"
                  v-if="password_visibility"
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
                    />
                  </svg>
                </div>
                <div
                  @click="password_visibility = !password_visibility"
                  class="input-group-addon"
                  v-else
                >
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z"
                    />
                  </svg>
                </div>
                <!--                <div-->
                <!--                  @click="password_visibility = !password_visibility"-->
                <!--                  class="input-group-addon rounded-0 pointer no-select"-->
                <!--                >-->
                <!--                  <i-->
                <!--                    :class="password_visibility ? 'mdi mdi-eye' : 'mdi mdi-eye-slash'"-->
                <!--                    aria-hidden="true"-->
                <!--                    style="width: 100%"-->
                <!--                  ></i>-->
                <!--                  &lt;!&ndash; {{password_visibility ? 'HIDE' : 'SHOW'}} &ndash;&gt;-->
                <!--                </div>-->
              </div>

              <div class="col-xs-12 input-group" v-if="auth_type === 'otp'">
                <input
                  id="login-otp"
                  class="form-control rounded-0"
                  type="text"
                  :class="{ 'form-error animated shake': $v.password.$error }"
                  v-model.trim="password"
                  @input="$v.password.$touch"
                  required="required"
                  placeholder="OTP"
                />
              </div>
            </div>
            <div class="form-group clearfix" v-if="auth_type !== 'otp'">
              <router-link
                :to="{ name: 'ForgotPasswordView' }"
                class="text-dark font-14 font-bold"
              >
                <i class="mdi mdi-lock m-r-5"></i>
                Forgot password?
              </router-link>
            </div>
            <div class="form-group text-center m-t-20">
              <div class="col-xs-12">
                <button
                  v-if="auth_type === 'password'"
                  :disabled="$v.$invalid || isLoading"
                  @click.prevent="loginUser"
                  class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                  type="submit"
                >
                  Log In
                  <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                </button>
                <button
                  v-if="auth_type === 'otp' && password.length < 6"
                  :disabled="isLoading"
                  @click.prevent="loginUser"
                  class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                  type="submit"
                >
                  Log In
                  <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                </button>
                <button
                  v-if="auth_type === 'otp' && password.length >= 6"
                  :disabled="isLoading"
                  @click.prevent="loginUser"
                  class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                  type="submit"
                >
                  Log In
                  <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                </button>
              </div>
            </div>
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import StorageService from "@/helpers/storage";

export default {
  name: "LoginModule",
  data() {
    return {
      email: "",
      password: "",
      challenge_token: "",
      isLoading: false,
      password_visibility: false,
      showPasswordForm: false,
      remember_me: false,
      auth_type: ""
    };
  },
  computed: {
    ...mapGetters({
      error: "getLoginError",
      intended_to: "getIntendedTo"
    })
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        let remember_me = StorageService.getItem("remember_me");
        if (remember_me) {
          remember_me = JSON.parse(remember_me);
          this.email = remember_me.email;
          this.remember_me = remember_me.remember_me;
        }
      }, 0);
    });
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    challenge_token: {
      required
    }
  },
  methods: {
    async loginUser() {
      this.isLoading = true;
      try {
        const { user } = await this.$store.dispatch(types.LOGIN_USER_ACTION, {
          email: this.email,
          password: this.password,
          auth_type: this.auth_type,
          challenge_token: this.challenge_token
        });
        // if (profile.user.first_time_login) {
        //   vm.$router.push({
        //     name: "ChangePasswordView"
        //   });
        //   notify.warning({
        //     message: "Please change your password to continue"
        //   });
        // } else {
        //   vm.$router.push({
        //     name: "DashboardContainer"
        //   });
        //   notify.success({
        //     message: "Login successfully!"
        //   });
        // }
        if (user.role.name === "Enforcer") {
          this.isLoading = false;
          this.$notify.warning({
            message: "You don't have access to this portal"
          });

          return;
        }

        if (localStorage.getItem("request_deeplink")) {
          this.$router.push(
            `ecosystems/agents/${
              localStorage.getItem("request_deeplink").split("/")[2]
            }`
          );
        }
        if (this.intended_to) {
          let intended_to = this.intended_to;
          this.$store.commit(types.SET_INTENDED_TO, "");
          this.$router.push(intended_to);
        } else {
          this.$router.push({
            name:
              user.user_type === "company" ? "AgentsView" : "DashboardContainer"
          });
        }
        this.$notify.success({
          message: "Login successfully!"
        });
      } catch (error) {
        // show error notification
        error &&
          error.data &&
          // notify.error({ message: error.data.message });
          this.$store.commit(types.SET_LOGIN_ERROR, error.data.message);
        this.isLoading = false;
      }
    },
    async lookupEmail() {
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          types.LOOKUP_EMAIL_ACTION,
          this.email
        );
        this.challenge_token = response.challenge_token;
        this.auth_type = response.auth_type;
        console.log(response);
        // this.auth_type = response.auth_type;
        if (this.remember_me) {
          let remember_me = JSON.stringify({
            remember_me: this.remember_me,
            email: this.email
          });
          StorageService.setItem("remember_me", remember_me);
        } else {
          StorageService.removeItem("remember_me");
        }
        this.$store.commit(types.SET_LOGIN_ERROR, "");
        this.showPasswordForm = true;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  },
  beforeDestroy() {
    this.$store.commit(types.SET_LOGIN_ERROR, "");
  }
};
</script>
