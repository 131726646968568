import PrintLicenseIdsView from "@/modules/print-license-ids/Index";
import PreviouslyPrinted from "@/modules/print-license-ids/_components/PreviouslyPrinted.vue";
import PendingIDs from "@/modules/print-license-ids/_components/PendingIDs.vue";

export default [
  {
    path: "/print-license-ids",
    name: "AgentsLicensePrintView",
    component: PrintLicenseIdsView,
    redirect: {
      name: "PrintPending"
    },
    children: [
      {
        path: "pending",
        component: PendingIDs,
        name: "PrintPending",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["agent-license-print"]
        }
      },
      {
        path: "reprint",
        component: PreviouslyPrinted,
        name: "Reprint",
        meta: {
          requiresAuth: true,
          allowed_permissions: ["agent-license-print"],
          showProgressBar: true
        }
      }
    ],
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["agent-license-print"],
      documentTitle: "Print License Ids"
    }
  }
];
