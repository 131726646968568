import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  showSearchResult: false,
  identityResult: [],
  vformResult: [],
  addressResult: [],
  backgroundResult: []
};

export default {
  state,
  actions,
  getters,
  mutations
};
