<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <div class="row">
        <div class="col-xs-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8">
          <div class="form-group m-b-10">
            <input
              type="text"
              class="form-control rounded-0"
              placeholder="Search using name, email address..."
              v-model.trim="search_term"
              @input="searchAddressLog"
            />
            <p
              class="text-danger font-14 font-bold mt-1"
              v-if="isSearching && verifications.length === 0"
            >
              No address verifications found for '{{
                $route.query.search_term
              }}'
            </p>
          </div>
        </div>
      </div>
      <form id="filter-report-form" class="filter-report-form">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="status" class="control-label">
                Status
                <span class="text-danger">*</span>
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="status"
                :class="{ 'form-error animated shake': $v.status.$error }"
              >
                <option value disabled>-- select status --</option>
                <option value="all">All</option>
                <option value="verified">Verified</option>
                <option value="not_verified">Not Verified</option>
                <option value="pending">Pending</option>
                <option value="awaiting_qa">Awaiting QA</option>
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label
                for="start_date"
                class="control-label"
                style="display:block"
              >
                Start Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <div class="clearfix">
                <flat-pickr
                  v-model.trim="start_date"
                  :class="{ 'form-error animated shake': $v.start_date.$error }"
                  class="form-control rounded-0"
                  :config="startDateConfig"
                  placeholder="Start Date"
                ></flat-pickr>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="end_date" class="control-label" style="display:block">
                End Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <!-- <div
                class="clearfix"
                v-tooltip="!start_date ? 'Please select a start date' : ''"
              > -->
              <!-- <input type="text" class="form-control" placeholder="End Date"> -->
              <flat-pickr
                v-model.trim="end_date"
                :class="{ 'form-error animated shake': $v.end_date.$error }"
                class="form-control rounded-0"
                :config="endDateConfig"
                placeholder="End Date"
              ></flat-pickr>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="status" class="control-label">
                LGA/LCDA
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="region"
                :class="{ 'form-error animated shake': $v.region.$error }"
              >
                <option value="" disabled>-- select LGA/LCDA --</option>
                <option value="all">All</option>
                <option
                  v-for="region in regions"
                  :key="region"
                  :value="region"
                  >{{ region }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-md-3 mt-2">
            <div class="form-group m-b-5">
              <label for="address_type" class="control-label">
                Address Type
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="address_type"
                :class="{ 'form-error animated shake': $v.address_type.$error }"
              >
                <option value="" disabled>-- select Address Type --</option>
                <!--                <option value="all">All</option>-->
                <option
                  v-for="address_type in address_types"
                  :key="address_type.key"
                  :value="address_type.key"
                  >{{ address_type.name }}</option
                >
              </select>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group m-b-5 float-right">
              <label class="control-label" style="display:block">&nbsp;</label>
              <button
                class="btn btn-warning btn-sm normalize-filter-btn rounded-0 m-r-5"
                :disabled="isLoading || $v.$invalid"
                @click.prevent="filterVerificationLogs"
              >
                <i class="mdi mdi-filter"></i>
                Filter
              </button>
              <span
                v-tooltip="'CSV will be sent to your email address'"
                v-if="$User.hasPermission('address-report-export')"
              >
                <button
                  class="btn btn-warning btn-sm normalize-filter-btn rounded-0"
                  :disabled="isLoading || $v.$invalid"
                  @click.prevent="filterVerificationLogs({ exportCsv: true })"
                >
                  <i class="mdi mdi-cloud-download"></i>
                  Export CSV
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <div class="p-t-10 p-b-10" v-if="isFilter">
            <label class="text-info font-normal font-14">
              Address verification log filtered by
              <span
                class="badge badge-pill badge-default mx-1"
                v-if="$route.query.status"
                ><strong>Status: </strong
                >{{ $route.query.status | remove_underscore }}</span
              >
              <span
                class="badge badge-pill badge-default mx-1"
                v-if="$route.query.region"
                ><strong>LGA/LCDA: </strong
                >{{ $route.query.region | remove_underscore }}</span
              ><span v-if="$route.query.start_date || $route.query.end_date">
                between {{ startDateDefault | date }} and
                {{ endDateDefault | date }}
              </span>
            </label>
            <button
              class="ml-3 btn btn-outline-info btn-xs font-bold"
              @click="clearFilter"
            >
              clear filter
            </button>
          </div>
          <div class="p-t-10 p-b-10" v-if="!isFilter">
            <label class="text-info font-medium font-14"
              >Showing all applications</label
            >
          </div>
          <div class="table-responsive">
            <template v-if="verifications.length > 0">
              <table class="table table-hover">
                <thead>
                  <tr class="link-row">
                    <th width="70" class="text-center">#</th>
                    <th>Status</th>
                    <th class="text-nowrap">Verification ID</th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">LGA/LCDA</th>
                    <th class="text-nowrap">Requested by</th>
                    <th class="text-nowrap">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="link-row"
                    v-for="(verification, index) in verifications"
                    :key="verification.verification_id"
                  >
                    <td class="text-center">
                      <router-link
                        :to="{
                          name: 'ReportView',
                          params: {
                            verification_id: verification.verification_id
                          }
                        }"
                      >
                        {{ pagination.from + index }}</router-link
                      >
                    </td>
                    <td>
                      <router-link
                        :to="{
                          name: 'ReportView',
                          params: {
                            verification_id: verification.verification_id
                          }
                        }"
                      >
                        <span
                          class="label font-bold text-capitalize rounded-0 text-nowrap"
                          :class="[
                            verification.address_info.status === 'verified'
                              ? 'label-light-success'
                              : verification.address_info.status ===
                                'not_verified'
                              ? 'label-light-danger'
                              : verification.address_info.status === 'pending'
                              ? 'label-light-warning'
                              : verification.address_info.status ===
                                'incomplete'
                              ? 'label-light-primary'
                              : 'label-light-warning'
                          ]"
                        >
                          {{
                            verification.address_info.status | remove_underscore
                          }}
                        </span>
                      </router-link>
                    </td>
                    <td class="text-capitalize">
                      <router-link
                        :to="{
                          name: 'ReportView',
                          params: {
                            verification_id: verification.verification_id
                          }
                        }"
                        class="btn btn-link p-0 text-success"
                      >
                        {{ verification.verification_id }}
                      </router-link>
                    </td>
                    <td class="text-capitalize">
                      <router-link
                        :to="{
                          name: 'AgentView',
                          params: {
                            agent_id: verification.agent_id
                          }
                        }"
                        class="btn btn-link p-0 text-success"
                      >
                        <template v-if="verification.personal_info.completed"
                          >{{ verification.personal_info.first_name }}
                          {{
                            verification.personal_info.middle_name
                              ? verification.personal_info.middle_name + " "
                              : ""
                          }}{{ verification.personal_info.last_name }}</template
                        >
                        <template v-else>
                          Not available
                        </template>
                      </router-link>
                    </td>
                    <td class="text-capitalize">
                      <router-link
                        :to="{
                          name: 'ReportView',
                          params: {
                            verification_id: verification.verification_id
                          }
                        }"
                      >
                        {{
                          verification.address_info.region || "Not available"
                        }}
                      </router-link>
                    </td>
                    <td class="text-capitalize">
                      <router-link
                        :to="{
                          name: 'ReportView',
                          params: {
                            verification_id: verification.verification_id
                          }
                        }"
                      >
                        {{ requested_by(index) }}
                      </router-link>
                    </td>
                    <td class="text-capitalize">
                      <router-link
                        :to="{
                          name: 'ReportView',
                          params: {
                            verification_id: verification.verification_id
                          }
                        }"
                      >
                        {{ verification.created_at | long_date }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="p-30 font-medium text-muted">
                <span>
                  Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.total }} entries
                </span>
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                <img
                  src="/static/empty.svg"
                  class="img-fluid mb-3"
                  alt="empty"
                  style="width: 50px;"
                />
                <p class="font-bold font-14">
                  There are no address verifications to display
                </p>
              </div>
            </template>
          </div>
          <div class="text-center p-l-30 m-t-20">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import debounce from "@/helpers/debounce";

export default {
  name: "AddressLogView",
  created() {
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      status: this.$route.query.status || "",
      start_date: this.$route.query.start_date || "",
      end_date: this.$route.query.end_date || "",
      region: this.$route.query.region || "",
      search_term: this.$route.query.search_term || "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment(this.start_date).format("YYYY-MM-DD")
      },
      isLoading: false,
      address_type: "",
      address_types: [
        {
          key: "residential",
          name: "Residential Address"
        },
        {
          key: "business",
          name: "Business Address"
        }
      ]
    };
  },
  validations: {
    status: { required },
    start_date: {},
    end_date: {},
    region: {},
    address_type: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      verifications: "getAddressVerificationsLog",
      pagination: "getAddressVerificationsLogPagination",
      business_profile: "getBusinessProfile",
      regions: "getRequestVerificationRegions"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment(this.$store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        )
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return this.$route.query.status || this.$route.query.region;
    },
    isSearching() {
      return this.$route.query.search_term;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterVerificationLogs(options = {}) {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let { start_date, end_date, status, region, address_type } = this;

          let { exportCsv } = options;
          if (exportCsv) {
            let response = await this.$store.dispatch(
              types.EXPORT_ADDRESS_VERIFICATIONS_ACTION,
              {
                start_date: start_date || this.startDateDefault,
                end_date: end_date || this.endDateDefault,
                status,
                ...(region && { region }),
                ...(address_type && { address_type })
              }
            );
            this.$notify.success({ message: response.message });
          } else {
            let query = {
              ...(status && status !== "all" && { status }),
              page: 1,
              ...(start_date && { start_date }),
              ...(end_date && { end_date }),
              ...(region && region !== "all" && { region }),
              ...(address_type && { address_type })
            };
            this.$router.push({
              name: this.$route.name,
              query
            });
          }
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      let {
        status,
        start_date,
        end_date,
        region,
        address_type
      } = this.$route.query;

      let query = {
        ...(status && { status }),
        [Date.now()]: null,
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date }),
        ...(region && { region }),
        ...(address_type && { address_type })
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.status = "";
        this.start_date = "";
        this.end_date = "";
        this.region = "";
        this.address_type = "";
        this.$v.$reset();
      });
    },
    requested_by(index) {
      if (!this.verifications[index].address_requested_by) {
        return "Not available";
      }
      let first_name = this.verifications[index].address_requested_by
        .first_name;
      let middle_name = this.verifications[index].address_requested_by
        .middle_name;
      let last_name = this.verifications[index].address_requested_by.last_name;
      if (first_name || middle_name || last_name) {
        return `${first_name} ${middle_name} ${last_name}`;
      }
      return "Not available";
    },
    searchAddressLog: debounce(async function(e) {
      if (e.target.value.trim()) {
        this.$router.push({
          name: this.$route.name,
          query: {
            search_term: e.target.value.trim()
          }
        });
      }
    }, 750)
  }
};
</script>
