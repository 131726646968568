<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Application Links" />
    <!-- ============================================================== -->
    <!--  component -->
    <!-- ============================================================== -->
    <div class="row m-t-15">
      <div class="col-md-12">
        <upload-csv-view
          v-if="$User.hasPermission('verification-link-generate')"
        ></upload-csv-view>
        <generated-links-log></generated-links-log>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import GeneratedLinksLog from "./_components/GeneratedLinksLog";
import UploadCsvView from "./_components/UploadCsv";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    AppTitle,
    GeneratedLinksLog,
    UploadCsvView
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        page = 1,
        status = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      await Promise.all([
        store.dispatch(types.GET_VERIFICATION_REGIONS),
        store.dispatch(types.GET_GENERATED_LINKS_ACTION, {
          page,
          status,
          start_date,
          end_date
        })
      ]);
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        page = 1,
        status = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      await store.dispatch(types.GET_GENERATED_LINKS_ACTION, {
        page,
        status,
        start_date,
        end_date
      });
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
