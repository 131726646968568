<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Identity" />

    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <div class="row m-t-15">
      <div class="col-md-12">
        <report-log></report-log>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import ReportLog from "./_components/ReportLog";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import moment from "moment";

export default {
  components: {
    AppTitle,
    ReportLog
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        page = 1,
        status = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD"),
        region
      } = to.query;
      await Promise.all([
        store.dispatch(types.GET_VERIFICATIONS_ACTION, {
          page,
          status,
          start_date,
          end_date,
          ...(region && { region })
        }),
        store.dispatch(types.GET_VERIFICATION_REGIONS)
      ]);
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        page = 1,
        status = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD"),
        region
      } = to.query;
      await store.dispatch(types.GET_VERIFICATIONS_ACTION, {
        page,
        status,
        start_date,
        end_date,
        ...(region && { region })
      });
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
