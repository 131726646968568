import * as types from "@/store/types";
import StorageService from "@/helpers/storage";

const mutations = {
  async [types.SET_PROFILE](state, payload) {
    // set current user
    state.current_user = payload;
    StorageService.saveUserProfileID(payload.id);
    StorageService.saveBusinessProfileID(payload.business.id);
  },
  async [types.SET_BUSINESS_PROFILE](state, payload) {
    let current_user = JSON.parse(JSON.stringify(state.current_user));
    current_user.business = payload;
    state.current_user = current_user;
    StorageService.saveBusinessProfileID(payload.id);
  }
};

export default mutations;
