<template>
  <div class="row mx-auto mb-5" style="max-width: 600px;">
    <div class="col-sm-12">
      <h1 style="color: rgb(3, 27, 77);" class="mt-5 text-center">
        Consent
      </h1>
    </div>
    <div class="col-sm-12">
      <p class="font-14 text-justify">
        By clicking on “I consent,” you give us permission to use the data you
        provide to conduct a background check on you for your application for an
        Agent Permit with the Lagos State Lotteries and Gaming Authority.
        <br />
        <br />
        <br />
        In this process, we will conduct checks related to your identity, and we
        are entrusted with the protection and security of the identity data
        provided to us. Our
        <a
          href="../../../assets/doc/LSLB%20PRIVACY%20POLICY.pdf"
          disabled
          target="_blank"
          download
        >
          Privacy Policy </a
        >, lists the information we need to provide Agent Verification Services,
        helps you understand how we use the information we collect to provide
        across our system and provides other relevant information relating to
        your personal data.
        <br />
        <br />
        <br />
        If you do not wish to proceed, kindly click “I do not consent”
        otherwise, click on "I understand" to continue with your application.
      </p>
    </div>
    <div class="col-sm-6 mb-3">
      <button
        type="button"
        @click.prevent="requestVerification"
        class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
      >
        I Understand, Continue
        <i class="mdi mdi-loading mdi-spin ml-1" v-if="isLoading"></i>
      </button>
    </div>
    <div class="col-sm-6 mb-3">
      <router-link
        tag="button"
        :to="{
          name: 'RequestVerificationView'
        }"
        class="btn btn-danger rounded-0 waves-effect p-10 w-100 p-10"
        >I do not consent</router-link
      >
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import store from "@/store";

export default {
  name: "RequestVerificationConsentView",
  beforeRouteEnter(to, from, next) {
    if (
      to.params.verification_id ||
      (store.getters.getRequestVerification.personal_info.email &&
        store.getters.getRequestVerification.personal_info.mobile)
    ) {
      next();
      return;
    }
    next({ name: "RequestVerificationView" });
    return;
  },
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    async requestVerification() {
      // console.log(this.$store.getters.getRequestVerification);
      // return;

      if (this.$route.params.verification_id) {
        this.$router.push({
          name: "NewVerificationContainer",
          params: { verification_id: this.$route.params.verification_id }
        });
      } else if (
        this.$store.getters.getRequestVerification.personal_info.email &&
        this.$store.getters.getRequestVerification.personal_info.mobile &&
        this.$store.getters.getRequestVerification.license_types.length
      ) {
        const payload = {
          email: this.$store.getters.getRequestVerification.personal_info.email,
          mobile: this.$store.getters.getRequestVerification.personal_info
            .mobile,
          license_types: this.$store.getters.getRequestVerification
            .license_types,
          agent_business: this.$store.getters.getRequestVerification
            .agent_business
        };
        if (this.$store.getters.getRequestVerification.invite_code) {
          payload.invite_code = this.$store.getters.getRequestVerification.invite_code;
        }
        this.requestNewVerifcation(payload);
      } else {
        this.$router.push({ name: "RequestVerificationView" });
      }
    },
    async requestNewVerifcation(payload) {
      this.isLoading = true;
      try {
        let verification = await this.$store.dispatch(
          types.REQUEST_VERIFICATION_ACTION,
          payload
        );
        if (
          verification.status === "verified" ||
          verification.status === "pending"
        ) {
          this.$notify.error({
            message: "Application submitted"
          });
          return this.$router.push({ name: "RequestVerificationView" });
        }
        return this.$router.push({
          name: "NewVerificationContainer",
          params: { verification_id: verification.id }
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
