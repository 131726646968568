var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clearfix"},[_vm._m(0),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14 font-bold text-capitalize text-nowrap",class:_vm.residential_address.status === 'verified'
          ? 'text-success'
          : _vm.residential_address.status === 'completed'
          ? 'text-info'
          : _vm.residential_address.status === 'not_verified'
          ? 'text-danger'
          : _vm.residential_address.status === 'pending'
          ? 'text-info'
          : _vm.residential_address.status === 'incomplete'
          ? 'text-primary'
          : _vm.residential_address.status === 'awaiting_qa'
          ? 'text-info'
          : null},[(_vm.residential_address.status === 'awaiting_qa')?[_vm._v("Awaiting Quality Assurance Check")]:[_vm._v(" "+_vm._s(_vm._f("remove_underscore")(_vm.residential_address.status))+" ")],_c('i',{staticClass:"mdi ml-1",class:_vm.residential_address.status === 'verified'
            ? 'mdi-check-circle'
            : _vm.residential_address.status === 'completed'
            ? 'mdi-check-circle'
            : _vm.residential_address.status === 'not_verified'
            ? 'mdi-alert'
            : _vm.residential_address.status === 'pending'
            ? 'mdi-clock'
            : _vm.residential_address.status === 'incomplete'
            ? 'mdi-alert-outline'
            : _vm.residential_address.status === 'awaiting_qa'
            ? 'mdi-clock'
            : null})],2),(_vm.residential_address.reason)?_c('p',{staticClass:"small"},[_vm._v(" ("+_vm._s(_vm.residential_address.reason)+") ")]):_vm._e()]),(_vm.residential_address.verification_completed_at)?[_vm._m(1),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(" "+_vm._s(_vm._f("long_date")(_vm.residential_address.verification_completed_at))+" ")])])]:_vm._e(),(_vm.residential_address.verification_started_at)?[_vm._m(2),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(" "+_vm._s(_vm._f("long_date")(_vm.residential_address.verification_started_at))+" ")])])]:_vm._e(),_vm._m(3),_c('div',{staticClass:"col-xs-12 m-b-10"},[(_vm.residential_address.address)?_c('span',{staticClass:"text-muted font-14 text-capitalize"},[_vm._v(" "+_vm._s(_vm.residential_address.address)+" ")]):_c('span',{staticClass:"text-muted font-14 text-capitalize"},[_vm._v(" "+_vm._s(_vm.address)+" ")])]),_vm._m(4),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(" "+_vm._s(_vm.residential_address.report_id || "Not available")+" ")])]),(_vm.residential_address.is_rejected)?_c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Reason for Failure")])]):_vm._e(),(_vm.residential_address.is_rejected)?_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-danger font-medium font-14"},[_vm._v(" "+_vm._s(_vm.residential_address.reason_for_rejection || "Not available")+" ")])]):_vm._e(),_vm._m(5),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14 text-capitalize"},[(_vm.residential_address.nipost_address)?[_c('a',{attrs:{"href":_vm.nipost_address_link,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.residential_address.nipost_address)+" "),_c('i',{staticClass:"mdi mdi-open-in-new small"})])]:[_c('span',{staticClass:"text-muted font-14"},[_vm._v("Not available")])]],2)]),(
      _vm.residential_address.verification_started_at ||
        _vm.residential_address.report_available
    )?_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        !_vm.residential_address.report_available
          ? 'Download will be enabled as soon as the address report is available for viewing'
          : null
      ),expression:"\n        !residential_address.report_available\n          ? 'Download will be enabled as soon as the address report is available for viewing'\n          : null\n      "}]},[_c('a',{staticClass:"btn btn-warning",class:_vm.residential_address.report_available ? null : 'disabled',attrs:{"href":_vm.residential_address.report_available
            ? _vm.residential_address.download_url
            : '#'}},[_vm._v(" Download Address Report "),_c('i',{staticClass:"mdi mdi-download"})])])]):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Application Completed At")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Application Started At")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Address")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Unique Verification Code")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Address by NIPOST")]),_c('img',{staticClass:"d-inline-block w-auto ml-1",staticStyle:{"height":"14px"},attrs:{"src":"/static/nipost_logo.png"}})])}]

export { render, staticRenderFns }