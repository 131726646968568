<template>
  <div class="row">
    <!-- <div class="col-sm-12 col-md-8 offset-md-2"> -->
    <div class="col-sm-12">
      <div id="report-box" class="no-card mb-0">
        <div id="report-block" class="p-r-20 p-l-20">
          <section class="personal-information">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Personal Information
                  <router-link
                    :to="{ name: 'GuarantorPersonalInformationView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <div class="col-md-12 col-sm-12 mt-3">
                    <span class="font-bold">Passport</span>
                    <br />
                    <img
                      class="img-fluid rounded-circle"
                      :src="
                        guarantor.personal_info.photo || '/static/avatar.png'
                      "
                      onerror="this.src='/static/avatar.png'"
                      style="width: 150px; height: 150px; object-fit: cover;"
                    />
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Last Name</span>
                    <br />
                    <span>{{
                      guarantor.personal_info.last_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">First Name</span>
                    <br />
                    <span>{{
                      guarantor.personal_info.first_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Middle Name</span>
                    <br />
                    <span>{{
                      guarantor.personal_info.middle_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Email Address</span>
                    <br />
                    <span>{{ guarantor.personal_info.email || "N/A" }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Phone Number</span>
                    <br />
                    <span>{{ guarantor.personal_info.mobile || "N/A" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="identity-information">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Identity Information
                  <router-link
                    :to="{ name: 'GuarantorIdentityDetailsView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Identification Type</span>
                    <br />
                    <span>
                      {{
                        guarantor.identity_info.id_type === "bvn"
                          ? "Bank Verification"
                          : guarantor.identity_info.id_type === "nin"
                          ? "National Identification"
                          : guarantor.identity_info.id_type === "passport"
                          ? "International Passport"
                          : guarantor.identity_info.id_type ===
                            "drivers_license"
                          ? "Driver's License"
                          : "Identity"
                      }}
                    </span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Identification Number</span>
                    <br />
                    <span>{{
                      guarantor.identity_info.id_number || "N/A"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="row mt-3">
            <div class="col-md-6 col-sm-6 m-b-10">
              <router-link
                tag="button"
                :to="{ name: 'GuarantorIdentityDetailsView' }"
                class="btn btn-outline-inverse waves-effect rounded-0 w-100"
              >
                <span class="mdi mdi-arrow-left-thick"></span>
                Back
              </router-link>
            </div>
            <div class="col-md-6 col-sm-6">
              <button
                @click="submitGuarantorDetailsConfirmation"
                class="btn btn-warning waves-effect rounded-0 w-100"
              >
                Submit and Finish
                <i class="mdi mdi-arrow-right-thick"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notify from "@/helpers/notifyMe";
import store from "@/store";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  methods: {
    submitGuarantorDetailsConfirmation() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to change your information after submission!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit!",
        cancelButtonText: "No, let me review!"
      }).then(result => {
        if (result.value) {
          this.submitGuarantorDetails();
        }
      });
    },

    submitGuarantorDetails() {
      let vm = this;

      vm.$store
        .dispatch(types.SUBMIT_GUARANTOR_DETAILS, {
          token: vm.$route.params.guarantor_token
        })
        .then(() => {
          vm.$notify.success({
            message: "Guarantor details recorded successfully!"
          });
          vm.$router.replace({
            name: "GuarantorFormSubmittedSuccessfullyView"
            //guarantor_token: vm.$route.guarantor_token
          });
        })
        .catch(error => {
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        });
    }
  },
  computed: {
    ...mapGetters({
      guarantorDetails: "getGuarantorDetails"
    }),
    guarantor() {
      return this.guarantorDetails && this.guarantorDetails.guarantor;
    }
  },
  beforeRouteEnter(to, from, next) {
    let guarantorDetails = store.getters.getGuarantorDetails;
    if (
      !guarantorDetails.guarantor.personal_info.completed ||
      !guarantorDetails.guarantor.identity_info.completed
    ) {
      notify.error({ message: "Please complete the form before previewing" });
      next({
        name: "GuarantorPersonalInformationView"
        //params: { token: verification.id }
      });
      return;
    } else {
      next();
    }
  }
};
</script>
