import * as types from "@/store/types";
const mutations = {
  async [types.SET_ADDRESS_VERIFICATIONS_LOG](state, payload) {
    state.address_verifications_log = payload;
  },
  async [types.SET_ADDRESS_VERIFICATIONS_META](state, payload) {
    state.address_verifications_meta = payload;
  }
};

export default mutations;
