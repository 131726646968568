<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="change-password-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Change Password</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form
            id="change-password-form"
            class="form-horizontal"
            @submit.prevent="changeUserPassword"
          >
            <div class="form-group col-md-12 m-b-10">
              <label for="user-password-c" class="form-label"
                >Current Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                v-model.trim="current_password"
                :class="{
                  'form-error animated shake': $v.current_password.$error
                }"
                @input="$v.current_password.$touch"
                class="form-control rounded-0"
                id="user-password-c"
                placeholder="Enter your current password"
              />
            </div>
            <div class="form-group col-md-12 m-b-10">
              <label for="user-password-new" class="form-label"
                >New Password <span class="text-danger">*</span></label
              >
              <input
                type="password"
                v-model.trim="new_password"
                :class="{ 'form-error animated shake': $v.new_password.$error }"
                @input="$v.new_password.$touch"
                class="form-control rounded-0"
                id="user-password-new"
                placeholder="Enter your new password"
              />
              <div class="col-xs-12 form-group mt-2 mb-0" v-show="new_password">
                <div class="col-xs-12 font-14">
                  At least;
                  <ul class="list-unstyled">
                    <li class="font-14">
                      <i
                        class="mdi"
                        :class="
                          $v.new_password.containsLowerCase
                            ? 'mdi-check-circle text-success'
                            : 'mdi-circle text-muted'
                        "
                      ></i>
                      One lowercase letter
                    </li>
                    <li class="font-14">
                      <i
                        class="mdi"
                        :class="
                          $v.new_password.containsUpperCase
                            ? 'mdi-check-circle text-success'
                            : 'mdi-circle text-muted'
                        "
                      ></i>
                      One uppercase letter
                    </li>
                    <li class="font-14">
                      <i
                        class="mdi"
                        :class="
                          $v.new_password.containsSpecialCharacter
                            ? 'mdi-check-circle text-success'
                            : 'mdi-circle text-muted'
                        "
                      ></i>
                      One special character (allowed characters ! @ # $ % ^ & _
                      -)
                    </li>
                    <li class="font-14">
                      <i
                        class="mdi"
                        :class="
                          $v.new_password.containsNumber
                            ? 'mdi-check-circle text-success'
                            : 'mdi-circle text-muted'
                        "
                      ></i>
                      One number
                    </li>
                    <li class="font-14">
                      <i
                        class="mdi"
                        :class="
                          $v.new_password.isGreaterThanEight
                            ? 'mdi-check-circle text-success'
                            : 'mdi-circle text-muted'
                        "
                      ></i>
                      Eight characters
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <transition
              enter-active-class="animated fastest fadeIn"
              leave-active-class="animated fastest fadeOut"
            >
              <div
                class="form-group col-md-12 m-b-10"
                v-show="!$v.new_password.$invalid"
              >
                <label for="user-password-confirm" class="form-label"
                  >Confirm Password</label
                >
                <input
                  type="password"
                  v-model.trim="confirm_password"
                  :class="{
                    'form-error animated shake': $v.confirm_password.$error
                  }"
                  @input="$v.confirm_password.$touch"
                  class="form-control rounded-0"
                  id="user-password-confirm"
                  placeholder="Confirm your new password"
                />
                <p
                  v-if="!$v.confirm_password.sameAsNewPassword"
                  class="text-danger font-12 font-bold"
                >
                  Must be the same as new password
                </p>
              </div>
            </transition>
          </form>
        </div>
        <div class="modal-footer b-0">
          <div class="col-md-12">
            <button
              type="button"
              :disabled="$v.$invalid || isLoading"
              @click="changeUserPassword"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Change Password
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength, required, sameAs } from "vuelidate/lib/validators";
import * as types from "@/store/types";

import {
  isGreaterThanEight,
  containsNumber,
  containsLowerCase,
  containsUpperCase,
  containsSpecialCharacter
} from "@/helpers/customValidations";

export default {
  data() {
    return {
      current_password: "",
      new_password: "",
      confirm_password: "",
      isLoading: false
    };
  },
  validations: {
    current_password: { required, minLength: minLength(6) },
    new_password: {
      required,
      isGreaterThanEight,
      containsLowerCase,
      containsUpperCase,
      containsNumber,
      containsSpecialCharacter
    },
    confirm_password: {
      sameAsNewPassword: sameAs("new_password")
    }
  },
  methods: {
    async changeUserPassword() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.CHANGE_USER_PASSWORD_ACTION, {
          current_password: this.current_password,
          new_password: this.new_password,
          confirm_password: this.confirm_password
        });
        this.isLoading = false;
        this.$notify.success({ message: "Password changed successfully" });
        this.resetForm();
        this.closeModal();
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    resetForm() {
      this.current_password = "";
      this.new_password = "";
      this.confirm_password = "";
      this.$v.$reset();
      ``;
    },
    closeModal() {
      $("#change-password-modal").modal("hide");
    }
  }
};
</script>
