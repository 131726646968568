import http from "@/helpers/http";
class profileService {
  static get axios() {
    return http;
  }
  static getUserProfile() {
    return http.get("/users/me");
  }
  static updateUserProfile(payload) {
    let { photo, last_name, first_name, middle_name, mobile, gender } = payload;
    payload = { photo, last_name, first_name, middle_name, mobile };
    if (gender) {
      payload.gender = gender;
    }
    return http.put("/users/me", payload);
  }
  static changeUserPassword(payload) {
    return http.post("/users/me/password/change", payload);
  }
}

export default profileService;
