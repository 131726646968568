<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <h3 class="card-title">Reconcile Transaction</h3>
      <div class="row m-t-15">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 col-sm-12 p-l-0">
              <div class="form-group m-b-10">
                <label class="col-md-12 font-bold form-label">
                  Transaction ID
                  <span class="text-danger">*</span>
                </label>
                <div class="col-md-12">
                  <input
                    type="text"
                    class="rounded-0 form-control"
                    v-model.trim="transaction_id"
                    placeholder="Enter Transaction ID"
                    @input="$v.transaction_id.$touch"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12 m-t-10 p-l-0">
              <div class="form-group m-b-10">
                <div class="col-md-12 m-t-5">
                  <button
                    class="btn btn-warning waves-effect rounded-0 w-100"
                    :disabled="$v.$invalid || isLoading"
                    @click.prevent="getTransaction"
                  >
                    <span class="mdi mdi-cash-100"></span>
                    Reconcile Transaction
                    <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  alphaNum,
  maxLength,
  minLength,
  required
} from "vuelidate/lib/validators";
import eventBus from "@/helpers/event";

export default {
  mounted() {
    eventBus.$on("lslb:transaction:transaction-requery-successful", () => {
      this.transaction_id = "";
      this.$v.$reset();
    });
  },
  data() {
    return {
      transaction_id: "",
      isLoading: false
    };
  },
  validations: {
    transaction_id: {
      required,
      alphaNum,
      minLength: minLength(24),
      maxLength: maxLength(24)
    }
  },
  methods: {
    async getTransaction() {
      try {
        this.isLoading = true;
        let transaction = await this.$store.dispatch("GET_TRANSACTION_ACTION", {
          ...this.$data
        });
        eventBus.$emit(
          "lslb:transaction:show-reconcile-transaction-modal",
          transaction
        );
        $("#reconcile-transaction-modal").modal("show");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
