// components
import SettingsView from "@/modules/settings/Index";
import BusinessProfileView from "@/modules/settings/_components/BusinessProfile";
import ManageSuperAgentsView from "@/modules/settings/_components/ManageSuperAgents";

export default [
  {
    path: "/settings",
    name: "SettingsView",
    component: SettingsView,
    redirect: {
      name: "BusinessProfileView"
    },
    children: [
      {
        path: "business-profile",
        component: BusinessProfileView,
        name: "BusinessProfileView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["business-manage"],
          documentTitle: "Business Profile"
        }
      },
      {
        path: "manage-super-agents",
        component: ManageSuperAgentsView,
        name: "ManageSuperAgentsView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["business-manage"],
          documentTitle: "Manage Super Agents"
        }
      }
    ]
  }
];
