import * as types from "@/store/types";
import api from "../_api";
import moment from "moment";

const actions = {
  async [types.GET_BUSINESS_ACCOUNT_BALANCE_ACTION]({ commit }) {
    let business_account_balance = (await api.getBusinessAccountBalance()).data;
    commit(types.SET_BUSINESS_ACCOUNT_BALANCE, business_account_balance);
    // reject(business_account_balance);
    return business_account_balance;
  },
  async [types.GET_BUSINESS_TRANSACTION_TOTAL_ACTION]({ commit }) {
    let business_transaction_total = (await api.getTransactionTotal()).data;
    commit(types.SET_BUSINESS_TRANSACTION_TOTAL, business_transaction_total);
    // reject(business_account_balance);
    return business_transaction_total;
  },
  async [types.GET_AGENT_ANALYTICS_ACTION]({ commit }) {
    let agent_analytics = (await api.getAgentAnalytics()).data;
    commit(types.SET_AGENT_ANALYTICS, agent_analytics);
    return agent_analytics;
  },
  async [types.GET_DASHBOARD_METRICS_ACTION]({ commit }) {
    let dashboard_metrics = (await api.getDashboardAnalytics()).data;
    commit(types.SET_DASHBOARD_METRICS, dashboard_metrics);
    return dashboard_metrics;
  },
  async [types.GET_VERIFICATIONS_BY_CITIES_ACTION](
    { commit, getters },
    payload
  ) {
    let verification_by_cities = (
      await api.getVerificationByCities({
        start_date:
          (payload && payload.start_date) ||
          moment(getters.getBusinessProfile.created_at).format("YYYY-MM-DD"),
        end_date: (payload && payload.end_date) || moment().format("YYYY-MM-DD")
      })
    ).data;
    commit(types.SET_VERIFICATIONS_BY_CITIES, verification_by_cities);
    return verification_by_cities;
  },
  async [types.GET_VERIFICATIONS_METRICS_ACTION]({ commit }) {
    let verification_metrics = (await api.getVerificationMetrics()).data;
    commit(types.SET_VERIFICATIONS_METRICS, verification_metrics);
    return verification_metrics;
  },
  async [types.GET_IDENTITY_METRICS_ACTION]({ commit }) {
    let identity_metrics = (await api.getIdentityMetrics()).data;
    commit(types.SET_IDENTITY_METRICS, identity_metrics);
    return identity_metrics;
  }
};

export default actions;
