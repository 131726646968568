<template>
  <div class="clearfix">
    <div class="col-xs-12">
      <span class="font-bold font-14">Status</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span
        class="text-muted font-14 font-bold text-capitalize text-nowrap"
        :class="
          nin_info.status === 'verified'
            ? 'text-success'
            : nin_info.status === 'not_verified'
            ? 'text-danger'
            : nin_info.status === 'pending'
            ? 'text-warning'
            : nin_info.status === 'incomplete'
            ? 'text-primary'
            : null
        "
      >
        {{ nin_info.status | remove_underscore }}
        <i
          class="mdi ml-1"
          :class="
            nin_info.status === 'verified'
              ? 'mdi-check-circle'
              : nin_info.status === 'not_verified'
              ? 'mdi-alert'
              : nin_info.status === 'pending'
              ? 'mdi-clock'
              : nin_info.status === 'incomplete'
              ? 'mdi-alert-outline'
              : null
          "
        ></i>
      </span>
      <p class="small" v-if="nin_info.reason">({{ nin_info.reason }})</p>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="font-bold font-14">NIN Image</span>
    </div>
    <div class="col-xs-12">
      <a
        :href="nin_info.photo ? nin_info.photo : '/static/avatar.png'"
        class="live-photo-popup"
        v-tooltip.right="'Click to enlarge image'"
      >
        <img
          class="img-responsive img-thumbnail font-12 bg-light d-block m-b-10"
          :src="nin_info.photo ? nin_info.photo : '/static/avatar.png'"
          alt="Agent photo"
          style="width: 150px; height: 150px; object-fit: cover;"
          onerror="this.src='/static/avatar.png'"
        />
      </a>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Identity Type</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        nin_info.id_type === "bvn"
          ? "Bank Verification"
          : nin_info.id_type === "nin"
          ? "National Identification"
          : nin_info.id_type === "passport"
          ? "International Passport"
          : nin_info.id_type === "drivers_license"
          ? "Driver's License"
          : "Identity"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Identity Number</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        nin_info.id_number || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Name on ID</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        full_name_id || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Phone Number</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        nin_info.mobile || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Date of Birth</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        nin_info.dob || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Address</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        nin_info.address || "Not available"
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["nin_info"],
  mounted() {
    this.$nextTick(() => {
      $(".live-photo-popup").magnificPopup({
        type: "image",
        closeOnContentClick: true,
        mainClass: "mfp-img-mobile mfp-with-zoom",
        image: {
          verticalFit: true
        },
        zoom: {
          enabled: true, // By default it's false, so don't forget to enable it
          duration: 300, // duration of the effect, in milliseconds
          easing: "ease-in-out", // CSS transition easing function
          // The "opener" function should return the element from which popup will be zoomed in
          // and to which popup will be scaled down
          // By defailt it looks for an image tag:
          opener: function(openerElement) {
            // openerElement is the element on which popup was initialized, in this case its <a> tag
            // you don't need to add "opener" option if this code matches your needs, it's defailt one.
            return openerElement.is("img")
              ? openerElement
              : openerElement.find("img");
          }
        }
      });
    });
  },
  computed: {
    full_name_id() {
      if (
        this.nin_info.first_name ||
        this.nin_info.middle_name ||
        this.nin_info.last_name
      ) {
        return (
          this.nin_info.first_name +
          " " +
          this.nin_info.middle_name +
          " " +
          this.nin_info.last_name.toUpperCase()
        );
      }
      return "Not available";
    }
  }
};
</script>
