var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.verifications.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.verifications),function(verification,index){return _c('tr',{key:verification.id,staticClass:"link-row"},[_c('td',{staticClass:"text-center"},[_c('router-link',{attrs:{"to":{
                    name: _vm.type,
                    params: {
                      verification_id: verification.id,
                      profile_id: _vm.profile_id
                    }
                  }}},[_vm._v(" "+_vm._s(index + 1)+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
                    name: _vm.type,
                    params: {
                      verification_id: verification.id,
                      profile_id: _vm.profile_id
                    }
                  }}},[_vm._v(" "+_vm._s(verification.id)+" ")])],1),_c('td',{staticClass:"text-capitalize"},[_c('router-link',{attrs:{"to":{
                    name: _vm.type,
                    params: {
                      verification_id: verification.id,
                      profile_id: _vm.profile_id
                    }
                  }}},[_vm._v(" "+_vm._s(verification.license_types.length > 0 ? verification.license_types .map( function (license_type) { return license_type.license_type.category_name; } ) .join(", ") : "None")+" ")])],1),_c('td',{staticClass:"text-capitalize"},[_c('router-link',{attrs:{"to":{
                    name: _vm.type,
                    params: {
                      verification_id: verification.id,
                      profile_id: _vm.profile_id
                    }
                  }}},[_vm._v(" "+_vm._s(_vm._f("long_date")(verification.created_at))+" ")])],1),_c('td',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.statusMeanings[verification.status]),expression:"statusMeanings[verification.status]"}]},[_c('router-link',{attrs:{"to":{
                    name: _vm.type,
                    params: {
                      verification_id: verification.id,
                      profile_id: _vm.profile_id
                    }
                  }}},[_c('span',{staticClass:"label text-capitalize font-bold rounded-0 text-nowrap",class:[
                      verification.status === 'verified'
                        ? 'label-light-success'
                        : verification.status === 'not_verified'
                        ? 'label-light-danger'
                        : verification.status === 'pending'
                        ? 'label-light-warning'
                        : verification.status === 'incomplete'
                        ? 'label-light-primary'
                        : ''
                    ]},[_vm._v(" "+_vm._s(_vm._f("remove_underscore")(verification.status))+" ")])])],1)])}),0)])])])]):_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid mb-3",staticStyle:{"width":"50px"},attrs:{"src":"/static/empty.svg","alt":"empty"}}),_c('p',{staticClass:"font-bold font-14"},[_vm._v("There are no applications to display")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"link-row"},[_c('th',{staticClass:"text-center",attrs:{"width":"70"}},[_vm._v("#")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Application ID")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("License Type(s)")]),_c('th',[_vm._v("Date")]),_c('th',[_vm._v("Status")])])])}]

export { render, staticRenderFns }