<template>
  <div
    class="modal _animated fade _pulse modal-border"
    id="get-coupon-quotation-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Coupon Request</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <template v-if="quotation">
            <template v-if="quotation.category === 'single'">
              <div class="col-md-12" v-if="!bank_transfer_complete">
                <h5>Hello there,</h5>
                <p class="font-14">
                  You are about to create a coupon for
                  <strong
                    >{{
                      quotation.agent.first_name
                        ? quotation.agent.first_name + " "
                        : ""
                    }}{{
                      quotation.agent.middle_name
                        ? quotation.agent.middle_name + " "
                        : ""
                    }}{{
                      quotation.agent.last_name.value
                        ? quotation.agent.last_name.value
                        : ""
                    }}</strong
                  >
                  with coupon code
                  <strong class="text-uppercase">{{
                    quotation.coupon.code
                  }}</strong>
                  and discount value of
                  <strong
                    >{{ quotation.coupon.discount_type === "amount" ? "₦" : ""
                    }}{{ quotation.coupon.value | amount
                    }}{{
                      quotation.coupon.discount_type === "percent" ? "%" : ""
                    }}</strong
                  >
                </p>
                <h4 class="font-bold">
                  Price
                  <span class="float-right"
                    >₦{{
                      (parseInt(quotation.price_quote_in_kobo) / 100) | amount
                    }}</span
                  >
                </h4>
              </div>
            </template>
            <template v-if="quotation.category === 'multiple'">
              <div class="col-md-12" v-if="!bank_transfer_complete">
                <h5>Hello there,</h5>
                <p class="font-14">
                  You are about to create
                  <strong>{{ quotation.quantity }}</strong> coupons with coupon
                  code
                  <strong class="text-uppercase">{{
                    quotation.coupon.code
                  }}</strong>
                  and discount value of
                  <strong
                    >{{ quotation.coupon.discount_type === "amount" ? "₦" : ""
                    }}{{ quotation.coupon.value | amount
                    }}{{
                      quotation.coupon.discount_type === "percent" ? "%" : ""
                    }}</strong
                  >
                </p>
                <h4 class="font-bold">
                  Price
                  <span class="float-right"
                    >₦{{
                      (parseInt(quotation.price_quote_in_kobo) / 100) | amount
                    }}</span
                  >
                </h4>
              </div>
            </template>
          </template>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <template v-if="!requested_coupon">
              <button
                type="button"
                :disabled="is_requesting"
                class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
                @click="requestCoupon"
              >
                Request for Coupon
                <i v-if="is_requesting" class="mdi mdi-loading mdi-spin"></i>
              </button>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-sm-12" v-if="!bank_transfer_complete">
                  <div class="form-check radio-info">
                    <h5>Payment Mode</h5>
                    <label class="custom-control custom-radio" v-if="true">
                      <input
                        value="online"
                        name="payment_mode"
                        v-model.trim="payment_mode"
                        type="radio"
                        class="custom-control-input"
                      />
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description"
                        >Card/Bank Account</span
                      >
                    </label>
                    <label class="custom-control custom-radio">
                      <input
                        value="bank_transfer"
                        name="payment_mode"
                        v-model.trim="payment_mode"
                        type="radio"
                        class="custom-control-input"
                        v-tooltip="'Option not available at the moment'"
                      />
                      <span class="custom-control-indicator"></span>
                      <span class="custom-control-description">
                        Bank Transfer</span
                      >
                    </label>
                  </div>
                </div>
                <div class="col-sm-12" v-if="is_bank_transfer">
                  <div
                    class="alert alert-success-light font-normal font-14 rounded-0"
                  >
                    <div>
                      Complete the transaction by transferring ₦{{
                        (parseInt(coupon_payment.amount_in_kobo_approved) /
                          100)
                          | amount
                      }}
                      to;
                      <br />
                      <span class="font-bold">Account Name:</span> Lagos State
                      Lotteries Board Distribution Trust Account
                      <br />
                      <span class="font-bold">Bank:</span> Access Bank Plc
                      <br />
                      <span class="font-bold">Account Number:</span> 0724019405
                      <br />
                      Please send Transaction ID ({{ coupon_payment.id }}) and
                      proof of payment to
                      <a
                        class="text-info"
                        href="mailto:lslb_billing@youverify.co"
                        >lslb_billing@youverify.co</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                v-if="!bank_transfer_complete"
                :disabled="is_paying || bank_transfer_complete"
                class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
                @click="initiateCouponPayment"
              >
                Proceed to Payment
                <i v-if="is_paying" class="mdi mdi-loading mdi-spin"></i>
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import eventBus from "@/helpers/event";

export default {
  created() {
    let src = process.env.VUE_APP_PAYSTACK_SCRIPT_URL;
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.async = true;
    document.head.appendChild(script);

    this.$once("hook:beforeDestroy", () => {
      script.remove();
    });
  },
  mounted() {
    let vm = this;
    this.$nextTick(() => {
      setTimeout(() => {
        eventBus.$on("lslb:coupon:coupon_quotation", coupon_quotation => {
          vm.quotation = coupon_quotation;
        });
        eventBus.$on("lslb:coupon:coupon_data", coupon_data => {
          vm.coupon_data = coupon_data;
          vm.requested_coupon = "";
        });
        eventBus.$on("lslb:requested_coupon", requested_coupon => {
          vm.requested_coupon = requested_coupon;
          vm.coupon_data = "";
        });

        $("#get-coupon-quotation-modal").on("hidden.bs.modal", function() {
          vm.is_requesting = false;
          vm.is_paying = false;
          vm.coupon_data = "";
          vm.quotation = "";
          vm.requested_coupon = "";
          vm.payment_mode = "online";
          vm.is_bank_transfer = false;
          vm.coupon_payment = "";
          vm.bank_transfer_complete = false;
        });
      }, 0);
    });
  },
  data() {
    return {
      is_requesting: false,
      is_paying: false,
      coupon_data: "",
      quotation: "",
      requested_coupon: "",
      payment_mode: "online",
      // payment_mode: "bank_transfer",
      is_bank_transfer: false,
      coupon_payment: "",
      bank_transfer_complete: false
    };
  },
  methods: {
    async requestCoupon() {
      this.is_requesting = true;
      try {
        this.requested_coupon = await this.$store.dispatch(
          types.REQUEST_COUPON_ACTION,
          {
            category: this.coupon_data.category,
            coupon_code: this.coupon_data.coupon_code,
            discount_type: this.coupon_data.discount_type,
            value: this.coupon_data.discount_value,
            quantity: this.coupon_data.quantity,
            start_date: this.coupon_data.start_date,
            end_date: this.coupon_data.end_date,
            agent_id: this.coupon_data.agent_id
          }
        );
        this.is_requesting = false;
        this.refreshGeneratedCouponsLog();
        this.$notify.success({ message: "Coupon request successful" });

        // this.initiateCouponPayment();
      } catch (error) {
        this.is_requesting = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    async initiateCouponPayment() {
      this.bank_transfer_complete = false;
      this.is_paying = true;
      this.is_bank_transfer = false;
      try {
        let coupon_payment = await this.$store.dispatch(
          types.INITIATE_COUPON_PAYMENT_ACTION,
          {
            item_id: this.requested_coupon.id,
            payment_mode: this.payment_mode
          }
        );
        this.coupon_payment = coupon_payment;
        if (this.payment_mode === "online") {
          this.payWithPaytack(
            this.current_user.email,
            coupon_payment.txn_reference,
            coupon_payment.id,
            coupon_payment.amount_in_kobo_approved,
            this.current_user.mobile,
            coupon_payment.paystack_public_key
          );
        } else {
          this.is_bank_transfer = true;
          this.is_paying = false;
          this.bank_transfer_complete = true;
          this.refreshGeneratedCouponsLog();
        }
      } catch (error) {
        this.is_paying = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    payWithPaytack(
      email,
      tx_ref,
      transaction_id,
      amount_in_kobo_approved,
      mobile,
      public_key
    ) {
      let vm = this;
      // eslint-disable-next-line
      let handler = PaystackPop.setup({
        key: public_key,
        email: email,
        amount: amount_in_kobo_approved,
        currency: "NGN",
        split_code: process.env.VUE_APP_PAYSTACK_SPLIT_CODE,
        ref: tx_ref,
        metadata: {
          custom_fields: [
            {
              display_name: "Phone Number",
              variable_name: "mobile",
              value: mobile
            }
          ]
        },
        callback: async function() {
          try {
            let transaction = await vm.verifyTransaction(transaction_id);
            if (transaction.status === "success") {
              //coupon has been paid for
              vm.$notify.success({
                message: "Coupon payment successful"
              });
              eventBus.$emit("lslb:coupon:coupon_payment_successful");
            } else {
              //probable fraud transaction
              vm.$notify.warning({
                message:
                  "There was an issue with the coupon payment. Go to transactions to check status."
              });
            }
            vm.is_paying = false;
            vm.couponQuotationModal("hide");
            //refresh coupons log
            vm.refreshGeneratedCouponsLog();
          } catch (error) {
            vm.refreshGeneratedCouponsLog();
            error &&
              error.data &&
              vm.$notify.error({
                message: error.data.message
              });
          }
        },
        onClose: function() {
          vm.is_paying = false;
          vm.$notify.warning({
            message: "Coupon payment cancelled"
          });
          //refresh coupons log
          vm.refreshGeneratedCouponsLog();
        }
      });
      handler.openIframe();
    },
    verifyTransaction(transaction_id) {
      return this.$store.dispatch(
        types.VERIFY_VERIFICATION_PAYMENT_ACTION,
        transaction_id
      );
    },
    async refreshGeneratedCouponsLog() {
      eventBus.$emit("lslb:coupon:refresh-generated-coupons-log");
    },
    couponQuotationModal(action = "show") {
      $("#get-coupon-quotation-modal").modal(action);
    }
  },
  computed: {
    ...mapGetters({
      current_user: "getCurrentUser"
    })
  }
};
</script>
