// components
import HomeView from "@/modules/home/Index";

export default [
  {
    path: "/",
    component: HomeView,
    name: "HomeView",
    meta: {
      showProgressBar: true,
      documentTitle: "",
      plainLayout: true,
      isPageTypeHome: true
    }
  }
];
