import http from "@/helpers/http";
// import { myApp } from "@/main";

class Api {
  static get axios() {
    return http;
  }

  static submitContactForm(payload) {
    let { first_name, last_name, email, mobile, subject, message } = payload;
    payload = { first_name, last_name, email, mobile, subject, message };
    return http.post("/contact-us", payload);
  }
}
export default Api;
