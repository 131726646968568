<template>
  <div>
    <div class="row" v-if="transactions.length > 0">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr class="link-row">
                <th width="70" class="text-center">#</th>
                <th>Status</th>
                <th>Amount(NGN)</th>
                <th class="text-nowrap">Transaction ID</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(transaction, index) in transactions"
                :key="transaction.id"
              >
                <td class="text-center">
                  <!--                  <router-link-->
                  <!--                    :to="{-->
                  <!--                      name: type,-->
                  <!--                      params: {-->
                  <!--                        verification_id: transaction.id,-->
                  <!--                        profile_id: profile_id-->
                  <!--                      }-->
                  <!--                    }"-->
                  <!--                  >-->
                  {{ index + 1 }}
                  <!--                  </router-link>-->
                </td>
                <td>
                  <!--                  <router-link-->
                  <!--                    :to="{-->
                  <!--                      name: type,-->
                  <!--                      params: {-->
                  <!--                        verification_id: transaction.id,-->
                  <!--                        profile_id: profile_id-->
                  <!--                      }-->
                  <!--                    }"-->
                  <!--                  >-->
                  <span
                    class="label text-capitalize font-bold rounded-0 text-nowrap"
                    :class="[
                      transaction.status === 'verified' ||
                      transaction.status === 'success'
                        ? 'label-light-success'
                        : transaction.status === 'not_verified'
                        ? 'label-light-danger'
                        : transaction.status === 'pending'
                        ? 'label-light-warning'
                        : transaction.status === 'incomplete'
                        ? 'label-light-primary'
                        : ''
                    ]"
                    >{{ transaction.status | remove_underscore }}</span
                  >
                  <!--                  </router-link>-->
                </td>
                <td>{{ (transaction.amount_in_kobo / 100) | amount }}</td>
                <td>
                  <!--                  <router-link-->
                  <!--                    :to="{-->
                  <!--                      name: type,-->
                  <!--                      params: {-->
                  <!--                        verification_id: transaction.id,-->
                  <!--                        profile_id: profile_id-->
                  <!--                      }-->
                  <!--                    }"-->
                  <!--                  >-->
                  {{ transaction.id }}
                  <!--                  </router-link>-->
                </td>
                <td class="text-capitalize">
                  <!--                  <router-link-->
                  <!--                    :to="{-->
                  <!--                      name: type,-->
                  <!--                      params: {-->
                  <!--                        verification_id: transaction.id,-->
                  <!--                        profile_id: profile_id-->
                  <!--                      }-->
                  <!--                    }"-->
                  <!--                  >-->
                  {{ transaction.created_at | long_date }}
                  <!--                  </router-link>-->
                </td>
              </tr>
            </tbody>
          </table>
          <div class="p-30 test font-medium text-muted">
            <span
              >Showing 1 to {{ transactions.length }} of
              {{ transactions.length }} entries</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <img
        src="/static/empty.svg"
        class="img-fluid mb-3"
        alt="empty"
        style="width: 50px;"
      />
      <p class="font-bold font-14">There are notransactions to display</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentTransactionsLog",
  props: ["transactions", "type", "profile_id"],
  mounted() {
    console.log(this.transactions);
  }
};
</script>
