<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Addresses" />

    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <div class="row m-t-15">
      <div class="col-md-12">
        <address-log></address-log>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import AddressLog from "./_components/AddressLog";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import moment from "moment";

export default {
  components: {
    AppTitle,
    AddressLog
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD"),
        region,
        address_type
      } = to.query;
      if (search_term) {
        await Promise.all([
          store.dispatch(types.SEARCH_ADDRESS_VERIFICATIONS_ACTION, {
            query: search_term,
            paginate: 1,
            page: 1
          }),
          store.dispatch(types.GET_VERIFICATION_REGIONS)
        ]);
      } else {
        await Promise.all([
          store.dispatch(types.GET_ADDRESS_VERIFICATIONS_ACTION, {
            page,
            status,
            start_date,
            end_date,
            ...(region && { region }),
            ...(address_type && { address_type })
          }),
          store.dispatch(types.GET_VERIFICATION_REGIONS)
        ]);
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD"),
        region,
        address_type
      } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_ADDRESS_VERIFICATIONS_ACTION, {
          query: search_term,
          paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_ADDRESS_VERIFICATIONS_ACTION, {
          page,
          status,
          start_date,
          end_date,
          ...(region && { region }),
          ...(address_type && { address_type })
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
