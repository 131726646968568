import http from "@/helpers/http";
// import { myApp } from "@/main";

class Api {
  static get axios() {
    return http;
  }
  static getPermits(payload) {
    if (payload && payload.status) {
      return http.get("/businesses/categories", {
        params: payload
      });
    }
    return http.get("/businesses/categories", {
      params: { page: payload.page }
    });
  }

  static searchPermits(payload) {
    let params = {
      q: payload.query,
      page: payload.page
      //paginate: payload.paginate
    };
    if (payload.status) {
      params.status = payload.status;
    }
    return http.get("/businesses/categories", {
      params
    });
  }

  //resolvePermitActivation
  static resolvePermitActivation(payload) {
    const { slug, is_disabled } = payload;
    return http.put("/businesses/categories", {
      slug,
      is_disabled
      //role
    });
  }

  static addNewPermit(payload) {
    const { category_name } = payload;
    return http.post("/businesses/categories", {
      category_name
      //role
    });
  }

  static setPermitFee(payload) {
    const { fee } = payload;
    return http.put("/verifications/price", {
      fee
      //role
    });
  }
}
export default Api;
