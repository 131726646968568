<template>
  <div class="card">
    <div class="card-block">
      <h3 class="card-title">Identity Report</h3>
      <div
        class="alert rounded-0"
        :class="
          report.status === 'verified'
            ? 'alert-success'
            : report.status === 'not_verified'
            ? 'alert-danger'
            : report.status === 'pending'
            ? 'alert-warning'
            : 'alert-info'
        "
      >
        <h5 class="text-capitalize">
          <template
            v-if="report.status !== 'pending' && report.last_name.response"
          >
            <strong class="text-uppercase">
              <span v-if="report.last_name.is_equal">{{
                report.last_name.request
              }}</span>
              <span v-else
                >{{ report.last_name.response }}
                <del>{{ report.last_name.request }}</del></span
              >,
            </strong>
            {{
              `${report.first_name.request} ${report.middle_name.request ||
                report.middle_name.response}`
            }}
          </template>
          <template v-else>
            <strong class="text-uppercase"
              >{{ report.last_name.request }},</strong
            >
            {{
              `${report.first_name.request} ${report.middle_name.request ||
                report.middle_name.response}`
            }}
          </template>
          <span
            class="label text-capitalize"
            :class="
              report.status === 'verified'
                ? 'label-success'
                : report.status === 'not_verified'
                ? 'label-danger'
                : report.status === 'pending'
                ? 'label-warning'
                : 'label-info'
            "
            >{{ report.status | remove_underscore }}</span
          >
        </h5>
        <span class="font-14" v-if="report.reason_for_failure">{{
          report.reason_for_failure
        }}</span>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-5 text-center">
          <div class="row">
            <div class="col-sm-4 offset-sm-4 col-md-12 offset-md-0">
              <a
                :href="report.photo || '/static/avatar.png'"
                class="live-photo-popup"
              >
                <img
                  :src="report.photo || '/static/avatar.png'"
                  onerror="this.src='/static/avatar.png'"
                  class="font-12 bg-light h-100 w-100"
                  alt="Identity Photo"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-7">
          <div class="col-xs-12" v-if="report.type">
            <span class="font-bold font-14">Type</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-uppercase">{{
                report.type
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.id_number">
            <span class="font-bold font-14">ID Number</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.id_number
              }}</span>
            </div>
          </div>

          <!-- <div
              class="col-xs-12"
              v-if="report.last_name.request"
            >
              <span class="font-bold font-14">Last Name</span>
              <div class="col-xs-12 m-b-10">
                <span class="text-muted font-14 text-capitalize">
                  <template v-if="report.status !== 'pending' && report.last_name.response">
                    <span v-if="report.last_name.is_equal">{{report.last_name.request}}</span>
                    <span v-else>{{report.last_name.response}} <del>{{report.last_name.request}}</del></span>
                  </template>
                  <template v-else>
                    {{report.last_name.request}}
                  </template>
                </span>
              </div>
            </div>

            <div
              class="col-xs-12"
              v-if="report.middle_name.request || report.middle_name.response"
            >
              <span class="font-bold font-14">Middle Name</span>
              <div class="col-xs-12 m-b-10">
                <span class="text-muted font-14 text-capitalize">{{report.middle_name.request || report.middle_name.response}}</span>
              </div>
            </div>

            <div
              class="col-xs-12"
              v-if="report.first_name.request"
            >
              <span class="font-bold font-14">First Name</span>
              <div class="col-xs-12 m-b-10">
                <span class="text-muted font-14 text-capitalize">{{report.first_name.request}}</span>
              </div>
            </div> -->

          <div class="col-xs-12" v-if="report.dob.request">
            <span class="font-bold font-14">Date of Birth</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">
                <template
                  v-if="report.status !== 'pending' && report.dob.response"
                >
                  <span v-if="report.dob.is_equal">{{
                    report.dob.request | date
                  }}</span>
                  <span v-else
                    >{{ report.dob.response | date }}
                    <del>{{ report.dob.request | date }}</del></span
                  >
                </template>
                <template v-else>
                  {{ report.dob.request | date }}
                </template>
              </span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.mobile">
            <span class="font-bold font-14">Phone Number</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.mobile
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.address">
            <span class="font-bold font-14">Address</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.address
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.gender">
            <span class="font-bold font-14">Gender</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.gender
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.birth_country">
            <span class="font-bold font-14">Country of Birth</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.birth_country
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.birth_state">
            <span class="font-bold font-14">State of Birth</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.birth_state
              }}</span>
            </div>
          </div>
          <div class="col-xs-12" v-if="report.birth_lga">
            <span class="font-bold font-14">Local Government of Birth</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.birth_lga
              }}</span>
            </div>
          </div>
          <div class="col-xs-12" v-if="report.next_of_kin_phone_number">
            <span class="font-bold font-14">Next of Kin Phone Number</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.next_of_kin_phone_number
              }}</span>
            </div>
          </div>
          <div class="col-xs-12" v-if="report.marital_status">
            <span class="font-bold font-14">Marital Status</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.marital_status
              }}</span>
            </div>
          </div>
          <div class="col-xs-12" v-if="report.educational_level">
            <span class="font-bold font-14">Educational Level</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.educational_level
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.first_state_of_issuance">
            <span class="font-bold font-14">First State of Issuance</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.first_state_of_issuance
              }}</span>
            </div>
          </div>

          <div class="col-xs-12" v-if="report.application_first_issued_date">
            <span class="font-bold font-14">Application First Issued Date</span>
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize">{{
                report.application_first_issued_date | date
              }}</span>
            </div>
          </div>

          <div
            class="col-xs-12"
            v-if="
              report.license &&
                (report.license.description || report.license.class)
            "
          >
            <span class="font-bold font-14"
              >Class and Description of License</span
            >
            <div class="col-xs-12 m-b-10">
              <span class="text-muted font-14 text-capitalize"
                ><strong>Class {{ report.license.class }}: </strong
                >{{ report.license.description }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "IdentityReportComponent",
  mounted() {
    $(".live-photo-popup").magnificPopup({
      type: "image",
      closeOnContentClick: true,
      mainClass: "mfp-img-mobile mfp-with-zoom",
      image: {
        verticalFit: true
      },
      zoom: {
        enabled: true, // By default it's false, so don't forget to enable it
        duration: 300, // duration of the effect, in milliseconds
        easing: "ease-in-out", // CSS transition easing function
        // The "opener" function should return the element from which popup will be zoomed in
        // and to which popup will be scaled down
        // By defailt it looks for an image tag:
        opener: function(openerElement) {
          // openerElement is the element on which popup was initialized, in this case its <a> tag
          // you don't need to add "opener" option if this code matches your needs, it's defailt one.
          return openerElement.is("img")
            ? openerElement
            : openerElement.find("img");
        }
      }
    });
  },
  computed: {
    ...mapGetters({
      report: "getIdentityReport"
    })
  }
};
</script>
