import * as types from "@/store/types";

const state = {
  pageContentReady: false
};

const getters = {
  isPageContentReady: state => {
    return state.pageContentReady;
  }
};

const mutations = {
  async [types.SET_PAGE_READY](state) {
    state.pageContentReady = true;
  }
};

// const actions = {
//   async [types.SET_PAGE_READY_ACTION]({ commit }) {
//     commit(types.SET_PAGE_READY);
//   }
// };

export default {
  state,
  getters,
  mutations
  // actions
};
