var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.transactions.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.transactions),function(transaction,index){return _c('tr',{key:transaction.id},[_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('td',[_c('span',{staticClass:"label text-capitalize font-bold rounded-0 text-nowrap",class:[
                    transaction.status === 'verified' ||
                    transaction.status === 'success'
                      ? 'label-light-success'
                      : transaction.status === 'not_verified'
                      ? 'label-light-danger'
                      : transaction.status === 'pending'
                      ? 'label-light-warning'
                      : transaction.status === 'incomplete'
                      ? 'label-light-primary'
                      : ''
                  ]},[_vm._v(_vm._s(_vm._f("remove_underscore")(transaction.status)))])]),_c('td',[_vm._v(_vm._s(_vm._f("amount")((transaction.amount_in_kobo / 100))))]),_c('td',[_vm._v(" "+_vm._s(transaction.id)+" ")]),_c('td',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("long_date")(transaction.created_at))+" ")])])}),0)]),_c('div',{staticClass:"p-30 test font-medium text-muted"},[_c('span',[_vm._v("Showing 1 to "+_vm._s(_vm.transactions.length)+" of "+_vm._s(_vm.transactions.length)+" entries")])])])])]):_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid mb-3",staticStyle:{"width":"50px"},attrs:{"src":"/static/empty.svg","alt":"empty"}}),_c('p',{staticClass:"font-bold font-14"},[_vm._v("There are notransactions to display")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"link-row"},[_c('th',{staticClass:"text-center",attrs:{"width":"70"}},[_vm._v("#")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Amount(NGN)")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Transaction ID")]),_c('th',[_vm._v("Date")])])])}]

export { render, staticRenderFns }