<template>
  <div class="card">
    <div class="card-block">
      <h3 class="card-title">
        Nigeria Driver License (NDL) Verification
        <i
          class="mdi mdi-help-circle-outline ml-1"
          v-tooltip="
            `Use the form below to verify an individual's Nigeria Driver License`
          "
        ></i>
      </h3>
      <div class="row m-t-15">
        <div class="col-md-6 form-group m-b-10">
          <label class="font-bold form-label">
            Last Name
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="rounded-0 form-control"
            v-model.trim="last_name"
            placeholder="Enter Last Name"
            @input="$v.last_name.$touch"
            :class="{ 'form-error animated shake': $v.last_name.$error }"
          />
        </div>
        <div class="col-md-6 form-group m-b-10">
          <label class="font-bold form-label">
            First Name
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="rounded-0 form-control"
            v-model.trim="first_name"
            placeholder="Enter First Name"
            @input="$v.first_name.$touch"
            :class="{ 'form-error animated shake': $v.first_name.$error }"
          />
        </div>
        <div class="col-md-6 form-group m-b-10">
          <label class="font-bold form-label">
            Date of Birth
            <span class="text-danger">*</span>
          </label>
          <flat-pickr
            v-model.trim="dob"
            :class="{ 'form-error animated shake': $v.dob.$error }"
            @input="$v.dob.$touch"
            class="form-control rounded-0"
            :config="dateConfig"
            placeholder="Enter Date of Birth"
            ref="dob"
          ></flat-pickr>
        </div>
        <div class="col-md-6 form-group m-b-10">
          <label class="font-bold form-label">
            Nigeria Driver License Number
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="rounded-0 form-control"
            v-model.trim="id_number"
            placeholder="Enter Nigeria Driver License Number"
            @input="$v.id_number.$touch"
            :class="{ 'form-error animated shake': $v.id_number.$error }"
          />
        </div>
        <div class="col-md-6 form-group m-b-10 m-t-10">
          <button
            @click.prevent="verifyIdentity"
            class="btn btn-warning btn-block"
            :disabled="isLoading || $v.$invalid"
          >
            <i class="mdi mdi-check-all mr-1"></i>
            Verify Candidate
            <i class="ml-1 mdi mdi-loading mdi-spin" v-show="isLoading"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, alphaNum } from "vuelidate/lib/validators";
import moment from "moment";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";

export default {
  name: "AgentLicenseVerification",
  data() {
    return {
      last_name: "",
      first_name: "",
      dob: "",
      id_number: "",
      dateConfig: {
        maxDate: moment()
          .subtract(18, "years")
          .format("YYYY-MM-DD")
      },
      isLoading: false
    };
  },
  validations: {
    last_name: { required },
    first_name: { required },
    id_number: {
      required,
      alphaNum
    },
    dob: { required }
  },
  methods: {
    async verifyIdentity() {
      if (this.isLoading && this.$v.$invalid) {
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.VERIFY_IDENTITY_ACTION, {
          type: "frsc",
          id_number: this.id_number,
          first_name: this.first_name,
          last_name: this.last_name,
          dob: this.dob
        });
        this.clearForm();
        // this.$router.push({
        //   name: "IdentityReportComponent",
        //   params: { report_id: response.id }
        // });
        eventBus.$emit("lslb:identity:show-identity-report-component");
        eventBus.$emit("lslb:identity:refresh-identity-log");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      } finally {
        this.isLoading = false;
      }
    },
    clearForm() {
      this.last_name = "";
      this.first_name = "";
      this.id_number = "";
      this.dob = "";
      this.$v.$reset();
    }
  }
};
</script>
