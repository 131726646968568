<template>
  <div
    class="modal fade modal-border"
    id="multi-user-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="multi-user-modal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center">
            {{
              mode === "change-user-role"
                ? "Change User Role"
                : mode === "reinvite-user"
                ? "Re-invite User"
                : mode === "activate-user"
                ? "Re-activate User"
                : "Deactivate User"
            }}
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="multi-user-form" v-if="user && mode === 'change-user-role'">
            <div class="col-sm-12">
              <p>
                Change
                <span class="font-bold">{{ user.email }}</span>
                role from {{ user.role.name }} to
              </p>
              <div class="form-group">
                <select
                  class="form-control custom-select rounded-0"
                  v-model.trim="role"
                  :class="{ 'form-error animated shake': $v.role.$error }"
                  @change="$v.role.$touch"
                >
                  <option value selected disabled
                    >-- select new user role --</option
                  >
                  <option
                    :key="role.id"
                    v-for="role in all_roles"
                    :value="role.name"
                    :disabled="role.id === user.role.id"
                    class="text-capitalize"
                    >{{ role.name }}</option
                  >
                </select>
              </div>
            </div>
          </form>
          <div class="col-sm-12" v-if="user && mode === 'reinvite-user'">
            <p>
              Resend invitation mail to
              <span class="font-bold">{{ user.email }}</span
              >?
            </p>
          </div>
          <div class="col-sm-12" v-if="user && mode === 'activate-user'">
            <p>
              This will give
              <span class="font-bold">{{ user.email }}</span> access to the
              dashboard
            </p>
          </div>
          <div class="col-sm-12" v-if="user && mode === 'deactivate-user'">
            <p>
              Deactivating
              <span class="font-bold">{{ user.email }}</span> will revoke their
              access to the dashboard, are you sure?
            </p>
          </div>
        </div>
        <div class="modal-footer b-0 p-t-0">
          <div class="col-md-12">
            <button
              type="button"
              @click.prevent="changeUserRole"
              v-if="mode === 'change-user-role'"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning waves-effect w-100 rounded-0 p-10"
            >
              Change Role
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
            <button
              type="button"
              @click.prevent="reinviteUser"
              v-if="mode === 'reinvite-user'"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning waves-effect w-100 rounded-0 p-10"
            >
              Re-invite User
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
            <button
              type="button"
              @click.prevent="activateUser"
              v-if="mode === 'activate-user'"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning waves-effect w-100 rounded-0 p-10"
            >
              Re-activate User
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
            <button
              type="button"
              @click.prevent="deactivateUser"
              v-if="mode === 'deactivate-user'"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-danger waves-effect w-100 rounded-0 p-10"
            >
              Yes, Deactivate User
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import eventBus from "@/helpers/event";
import * as types from "@/store/types";

export default {
  mounted() {
    eventBus.$on("lslb:user:show-multi-user-modal", ({ user, mode }) => {
      this.user = user;
      this.mode = mode;
    });
  },
  data() {
    return {
      user: "",
      role: "",
      isLoading: false,
      mode: "change-user-role"
    };
  },
  validations: {
    role: {
      required: requiredIf(function() {
        return this.mode === "change-user-role";
      })
    }
  },
  computed: {
    ...mapGetters({
      all_roles: "getAllRoles"
    })
  },
  methods: {
    async changeUserRole() {
      if (this.$v.invalid || this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.CHANGE_USER_ROLE_ACTION, {
          user: this.user,
          role: this.role
        });
        eventBus.$emit("lslb:user:refresh-users-list");
        this.$notify.success({ message: "User role changed successfully" });
        $("#multi-user-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async reinviteUser() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.REINVITE_USER_ACTION, this.user);
        eventBus.$emit("lslb:user:refresh-users-list");
        this.$notify.success({ message: "Invitation resent successfully" });
        $("#multi-user-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async activateUser() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.ACTIVATE_USER_ACTION, this.user);
        eventBus.$emit("lslb:user:refresh-users-list");
        this.$notify.success({ message: "User activated successfully" });
        $("#multi-user-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async deactivateUser() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.DEACTIVATE_USER_ACTION, this.user);
        eventBus.$emit("lslb:user:refresh-users-list");
        this.$notify.success({ message: "User deactivated successfully" });
        $("#multi-user-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
