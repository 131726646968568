<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Settings" />

      <!-- ============================================================== -->
      <!-- Tab component -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-12">
          <div class="no-card">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs customtab" role="tablist">
              <li
                class="nav-item"
                v-if="$User.hasPermission('business-manage')"
              >
                <router-link
                  :to="{ name: 'BusinessProfileView' }"
                  class="nav-link"
                  active-class="active"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-settings"></i>
                  </span>
                  <span class="hidden-xs-down">Business Profile</span>
                </router-link>
              </li>
              <li
                v-if="
                  admin.user_type === 'lslb' || admin.user_type === 'youverify'
                "
                class="nav-item"
              >
                <router-link
                  :to="{ name: 'ManageSuperAgentsView' }"
                  class="nav-link"
                  active-class="active"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-settings"></i>
                  </span>
                  <span class="hidden-xs-down">Manage Super Agents</span>
                </router-link>
              </li>
            </ul>
            <!-- Tab panes -->
            <!-- /content -->
            <!-- ============================================================== -->
            <!-- Router view -->
            <!-- ============================================================== -->
            <div class="tab-content">
              <div class="tab-pane active" role="tabpanel">
                <div class="m-t-15">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppTitle from "@/components/layouts/AppTitle";

export default {
  components: {
    AppTitle
  },
  computed: {
    ...mapGetters({
      admin: "getCurrentUser"
    })
  },
  created() {}
};
</script>
