import http from "@/helpers/http";

class Api {
  static getBusinessAccountBalance() {
    return http.get("/businesses/balance");
  }

  static getAgentAnalytics() {
    return http.get("/analytics/agents");
  }

  static getDashboardAnalytics() {
    return http.get("/analytics/dashboard");
  }

  static getTransactionTotal() {
    return http.get("/transactions/total");
  }

  static getVerificationByCities({ start_date, end_date }) {
    return http.get(
      `/analytics/verifications/cities?start_date=${start_date}&end_date=${end_date}`
    );
  }
  static getVerificationMetrics() {
    return http.get("/analytics/verifications");
  }
  static getIdentityMetrics() {
    return http.get("/analytics/identity");
  }
}

export default Api;
