<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <div class="row">
        <ul class="nav nav-tabs customtab" role="tablist">
          <li
            :class="{
              'nav-item': true,
              active: this.addAgentInviteType === 'single'
            }"
            @click="addAgentInviteType = 'single'"
            role="tab"
          >
            <span class="nav-link hidden-xs-down">Single Invite</span>
          </li>
          <li
            :class="{
              'nav-item': true,
              active: this.addAgentInviteType === 'multiple'
            }"
            @click="addAgentInviteType = 'multiple'"
            role="tab"
          >
            <span class="nav-link hidden-xs-down">Bulk Invite</span>
          </li>
        </ul>
        <div class="col-12">
          <div class="" v-if="this.addAgentInviteType === 'single'">
            <div class="row mt-5">
              <div class="form-group col-md-6 m-b-5">
                <label for="first-name" class="control-label">
                  First Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control rounded-0 col-12"
                  id="first-name"
                  :class="{ 'form-error animated shake': $v.firstName.$error }"
                  @input="$v.firstName.$touch"
                  v-model.trim="firstName"
                  required="required"
                />
              </div>
              <div class="form-group col-md-6 m-b-5">
                <label for="last-name" class="control-label">
                  Last Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control rounded-0 col-12"
                  id="last-name"
                  :class="{ 'form-error animated shake': $v.lastName.$error }"
                  @input="$v.lastName.$touch"
                  v-model.trim="lastName"
                  required="required"
                />
              </div>
              <div class="form-group col-md-6 m-b-5">
                <label for="mobile-number" class="control-label">
                  Email
                  <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control rounded-0 col-12"
                  id="email"
                  :class="{ 'form-error animated shake': $v.email.$error }"
                  @input="$v.email.$touch"
                  v-model.trim="email"
                />
              </div>
              <div class="form-group col-md-6 m-b-5">
                <label for="mobile-number" class="control-label">
                  Phone
                  <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control rounded-0 col-12"
                  id="mobile-number"
                  :class="{ 'form-error animated shake': $v.phone.$error }"
                  @input="$v.phone.$touch"
                  v-model.trim="phone"
                />
              </div>
              <img
                src="../../../assets/images/zebra-line.svg"
                width="100%"
                class="my-4 px-3"
                alt=""
              />
              <div class="col-12">
                <button
                  v-if="
                    $User.hasPermission('user-add') &&
                      profile.user_type === 'company'
                  "
                  @click="inviteNewAgent"
                  class="btn btn-warning ml-2 text-center col-12 col-sm-4 px-0 mx-0"
                  :disabled="$v.$invalid || isLoading"
                >
                  Send Invite
                  <span v-if="isLoading">
                    <i class="mdi mdi-loading mdi-spin"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="" v-else>
            <div class="row mt-5 justify-content-center">
              <div
                class="col-12 col-sm-2 px-0 d-flex justify-content-center justify-content-sm-start"
              >
                <div class="step-circle text-center mt-auto mb-auto">
                  <h6>Step 1</h6>
                </div>
              </div>
              <div class="col-12 col-sm-9">
                <div class="col-12 step-info p-4">
                  <div class="row my-3">
                    <div class="col-12 col-md-7">
                      <h5 class="col-12 step-title">Download template</h5>
                      <p class="col-12 mt-3">
                        Download this template and fill-in the details of the
                        new set of users you want to add.
                      </p>
                    </div>
                    <div class="col-12 col-md-5">
                      <a
                        href="https://api.lslb.youverify.co/v1/templates/invite-multiple-agents"
                        download
                        class="btn download-btn col-12 col-sm-10 text-center mt-4"
                      >
                        <img
                          src="../../../assets/images/download-icon.svg"
                          alt=""
                          class="mr-3"
                        />
                        Download template
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 justify-content-center">
              <div
                class="col-12 col-sm-2 px-0 d-flex justify-content-center justify-content-sm-start"
              >
                <div class="step-circle text-center mt-auto mb-auto">
                  <h6>Step 2</h6>
                </div>
              </div>
              <div class="col-12 col-sm-9">
                <div class="col-12 step-info p-4">
                  <div class="row my-3">
                    <div class="col-12 col-md-7">
                      <h5 class="col-12 step-title">Upload template</h5>
                      <p class="col-12 mt-3">
                        Only upload the template downloaded from step 1.
                      </p>

                      <div
                        class="alert alert-warning"
                        role="alert"
                        v-if="csvError.length"
                      >
                        <span
                          v-for="(error, index) in csvError"
                          :key="index"
                          class="font-12"
                        >
                          <strong>{{ error.entryIndex }}:</strong>
                          {{ error.message }}
                          <br />
                        </span>
                      </div>
                    </div>
                    <div class="col-12 col-md-5">
                      <div
                        class="col-12 justify-content-center step-info-upload"
                      >
                        <h6
                          class="col-12 justify-content-center text-center mt-4"
                        >
                          Click the button below to upload a csv
                          <i
                            class="mdi mdi-help-circle-outline ml-1"
                            v-tooltip="
                              `Please ensure phone number column is set as text to prevent Excel from removing leading 0`
                            "
                          ></i>
                        </h6>
                        <h6
                          class="col-12 justify-content-center text-center text-warning mt-4"
                          v-if="csvFile"
                        >
                          File uploaded - {{ csvFile.name }}
                        </h6>
                        <div class="row justify-content-center">
                          <input
                            type="file"
                            ref="csvInput"
                            class="d-none"
                            @change="handleCSVChange"
                            accept="text/csv"
                            pattern="^.+\.(csv)$"
                          />

                          <button
                            class="btn upload-btn col-12 col-sm-10 text-center mt-3 mb-3"
                            @click="requireFileUpload"
                          >
                            <img
                              src="../../../assets/images/upload.svg"
                              alt=""
                              class="mr-3"
                            />
                            Upload CSV
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <button
                v-if="
                  $User.hasPermission('user-add') &&
                    profile.user_type === 'company'
                "
                @click="submitCSV"
                class="btn btn-warning ml-2 text-center col-12 col-sm-10 px-0 mt-4 mb-5"
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  numeric,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import UploadService from "@/services/upload";
import csvToArray from "@/helpers/csvToArray";
import notify from "@/helpers/notifyMe";

export default {
  name: "AgentInviteView",
  data() {
    return {
      csvError: [],
      isLoading: false,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      addAgentInviteType: "single",
      csvFile: null
    };
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    phone: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    email: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters({
      agents: "getAgentsLog",
      pagination: "getAgentsLogPagination",
      business_profile: "getBusinessProfile",
      regions: "getRequestVerificationRegions",
      businesses: "getAllBusinesses",
      business_license_categories: "getBusinessLicenseCategories",
      profile: "getCurrentUser"
    })
  },
  methods: {
    handleCSVChange(event) {
      const file = event.target.files[0];

      const reader = new FileReader();

      const vm = this;

      reader.onload = function(e) {
        vm.$refs.csvInput.value = null;

        const text = e.target.result;
        const csvArray = csvToArray(text);

        if (csvArray.length <= 1) {
          notify.error({
            message: `Please enter at least 2 entries`
          });
          return;
        }

        if (csvArray.length > 5000) {
          notify.error({
            message: `Exceeded maximum number of entries (5000)`
          });
          return;
        }

        let csvError = [];
        vm.csvError = csvError;

        for (let i = 0; i < csvArray.length - 1; i++) {
          const entry = csvArray[i];

          const firstName = entry["First name"];
          const lastName = entry["Last name"];
          const emailAddress = entry["Email"];
          const phoneNumber = entry["Phone number"];

          if (!required(emailAddress)) {
            // throw email error
            csvError.push({
              entryIndex: `Entry ${i + 1}`,
              message: `Email Address is required`
            });
          }

          if (!email(emailAddress)) {
            // throw email error
            csvError.push({
              entryIndex: `Entry ${i + 1}`,
              message: `Email Address invalid`
            });
          }

          if (!firstName?.trim()) {
            // throw name error

            csvError.push({
              entryIndex: `Entry ${i + 1}`,
              message: `Please enter a first name`
            });
          }
          if (!lastName?.trim()) {
            // throw name error

            csvError.push({
              entryIndex: `Entry ${i + 1}`,
              message: `Please enter a last name`
            });
          }
          if (!(phoneNumber?.trim() && phoneNumber.length === 11)) {
            //throw number error

            csvError.push({
              entryIndex: `Entry ${i + 1}`,
              message: `Phone Number should be 11 characters`
            });
          }

          if (!numeric(phoneNumber)) {
            //throw number error

            csvError.push({
              entryIndex: `Entry ${i + 1}`,
              message: `Phone Number should contain only numbers`
            });
          }
        }

        if (csvError.length) {
          notify.error({
            message: `Error selecting file. Please fix the errors before re-uploading.`
          });

          vm.csvError = csvError;
          return;
        }

        vm.csvFile = file;
      };

      reader.readAsText(file);
    },
    requireFileUpload() {
      this.$refs.csvInput.click();
    },
    async submitCSV() {
      if (!this.csvFile) {
        notify.error({ message: "Please select a valid csv file" });
        return;
      }
      this.isLoading = true;

      try {
        const response = await UploadService.uploadCsv({ file: this.csvFile });
        const csvLocation = response.data.location;

        await this.$store.dispatch(types.REQUEST_BULK_NEW_AGENT_ACTION, {
          csv_file_url: csvLocation
        });
        this.csvFile = null;

        this.$notify.success({
          message: "Successfully sent invites for processing."
        });

        await this.$router.push({
          name: "AgentsView"
        });
      } catch (error) {
        error && error.data && notify.error({ message: error.data.message });
        this.isLoading = false;
      }
    },
    async inviteNewAgent() {
      if (this.$v.$invalid || this.isLoading) return;
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.REQUEST_NEW_AGENT_ACTION, {
          email: this.email,
          phone: this.phone,
          first_name: this.firstName,
          last_name: this.lastName
        });

        notify.success({
          message: "Invitation sent successfully"
        });

        await this.$router.push({
          name: "AgentsView"
        });
      } catch (error) {
        error && error.data && notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.nav-item {
  cursor: pointer;
}

.nav-link.active {
  border-bottom-color: #f4b700 !important;
}

.nav-item:hover {
  border: none;
}

.step-circle {
  border: 1px solid black;
  border-radius: 50%;
  width: 74px;
  padding-top: 30px;
  height: 74px;
}

.step-info {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}

.step-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #ffb42e;
}

.download-btn {
  border: 1px solid #404e5d;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #404e5d;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.upload-btn {
  border: 1px solid #404e5d;
  border-radius: 5px;
  background-color: #4c963f;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.step-info-upload {
  border: 1px dashed #404e5d;
  border-radius: 5px;
  background: rgba(235, 235, 235, 0.5);
}
</style>
