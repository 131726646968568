import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  companies_log: [],
  companies_meta: null,
  business_license_categories: [],
  businesses: [],
  business: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
