<template>
  <div class="container-fluid position-relative w-100">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Report" />
    <report-component
      v-bind="{ verification }"
      :hide_report="hide_report"
      @show_report="show_report = true"
    >
      <template slot="header">
        <ul
          class="nav nav-tabs customtab scrollable bg-light rounded"
          role="tablist"
        >
          <li
            class="nav-item"
            v-for="{ name, componentName, verificationKey } in tabRoutes"
            :key="name"
          >
            <router-link
              :to="{ name: componentName }"
              class="nav-link"
              active-class="active"
              role="tab"
            >
              {{ name }}
              <i
                class="mdi"
                :class="
                  verification[verificationKey].status === 'verified'
                    ? 'text-success mdi-check-circle'
                    : verification[verificationKey].status === 'not_verified'
                    ? 'text-danger mdi-alert'
                    : verification[verificationKey].status === 'pending'
                    ? 'text-info mdi-clock'
                    : verification[verificationKey].status === 'incomplete'
                    ? 'text-primary mdi-alert-outline'
                    : verification[verificationKey].status === 'awaiting_qa'
                    ? 'text-info mdi-clock'
                    : verification[verificationKey].status === 'completed'
                    ? null
                    : 'text-warning mdi-alert'
                "
              ></i>
            </router-link>
          </li>
        </ul>
      </template>
      <router-view v-bind="verification"></router-view>
    </report-component>
    <div
      v-if="hide_report && profile.user_type !== 'company'"
      class="overlay"
    ></div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import ReportComponent from "@/components/report/ReportComponent";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import { mapGetters } from "vuex";

export default {
  components: {
    AppTitle,
    ReportComponent
  },
  data() {
    return {
      show_report: false,
      tabRoutes: [
        {
          name: "Identity Verification",
          componentName: "IdentityVerificationReport",
          verificationKey: "identity_info"
        },
        {
          name: "Business Address",
          componentName: "AddressReport",
          verificationKey: "address_info"
        },
        {
          name: "Residential Address",
          componentName: "ResidentialAddressReport",
          verificationKey: "residential_address"
        },
        {
          name: "Guarantor",
          componentName: "GuarantorReport",
          verificationKey: "guarantor_info"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      verification: "getVerificationReport",
      profile: "getCurrentUser"
    }),
    hide_report() {
      return (
        (this.verification.status === "pending" ||
          this.verification.status === "incomplete") &&
        !this.show_report
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch(types.GET_VERIFICATION_REPORT_ACTION, {
        verification_id: to.params.verification_id
      })
      .then(() => {
        next();
      })
      .catch(error => {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });

        next(false);
      });
  }
};
</script>

<style lang="scss" scoped>
.overlay {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
