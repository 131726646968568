import * as types from "@/store/types";

const mutations = {
  async [types.SET_GENERATED_COUPONS_LOG](state, payload) {
    state.generated_coupons_log = payload;
  },
  async [types.SET_GENERATED_COUPONS_META](state, payload) {
    state.generated_coupons_meta = payload;
  }
};

export default mutations;
