<template>
  <form @submit.prevent="updateLicenseInfo">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Scanned Driver license
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="file"
              data-max-file-size="15M"
              data-allowed-file-extensions="jpeg jpg png"
              data-height="100"
              data-show-loader="true"
              class="form-control rounded-0 dropify"
              @change="setImage"
            />
          </div>
        </div>
      </div>
      <div class="w-100"></div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Driver license Number
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              v-model.trim="license_number"
              :class="{ 'form-error animated shake': $v.license_number.$error }"
              @input="$v.license_number.$touch"
              class="form-control rounded-0 text-uppercase"
              placeholder="Enter driver license number"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-12">
            <router-link
              tag="button"
              :to="{ name: 'IdentityVerificationNumberView' }"
              class="btn btn-outline-inverse waves-effect rounded-0 w-100"
            >
              <span class="mdi mdi-arrow-left-thick"></span>
              Back
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-12">
            <button
              class="btn btn-warning waves-effect rounded-0 w-100"
              :disabled="
                $v.$invalid ||
                  isLoading ||
                  verification.status === 'verified' ||
                  verification.status === 'pending'
              "
              type="submit"
            >
              Save & Continue
              <span
                :class="{
                  'mdi mdi-loading mdi-spin': isLoading,
                  'mdi mdi-arrow-right-thick': !isLoading
                }"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import { required, alphaNum } from "vuelidate/lib/validators";
import * as types from "@/store/types";

export default {
  data() {
    return {
      license_number: "",
      isLoading: false,
      image: "",
      license_image: "",
      imageUploaded: false
    };
  },
  validations: {
    license_number: {
      required,
      alphaNum
    },
    image: {
      required
    }
  },
  mounted() {
    // initialize dropify
    let drEvent = $(".dropify").dropify({
      messages: {
        default: "Drag and drop a passport image here or click",
        replace: "Drag and drop or click to replace",
        remove: "REMOVE",
        error: "Ooops, something happened."
      }
    });

    drEvent.on("dropify.afterClear", () => {
      this.image = "";
      this.imageUploaded = false;
    });

    if (this.verification.driver_license_info.license_image) {
      let dropifyDestroy = drEvent.data("dropify");
      if (dropifyDestroy.isDropified()) {
        dropifyDestroy.resetPreview();
        dropifyDestroy.setPreview(
          true,
          this.verification.driver_license_info.license_image
        );
      }
    }
    this.image = this.verification.driver_license_info.license_image;
    this.imageUploaded = this.verification.driver_license_info.license_image;
    this.license_image = this.verification.driver_license_info.license_image;
    this.license_number = this.verification.driver_license_info.license_number;
  },
  methods: {
    updateLicenseInfo() {
      this.$v.$touch();
      if (
        this.$v.$invalid ||
        this.isLoading ||
        this.verification.status === "verified" ||
        this.verification.status === "pending"
      ) {
        return;
      }
      this.isLoading = true;
      if (this.image && !this.imageUploaded) {
        this._uploadPassport();
      } else {
        if (this.license_image || this.imageUploaded) {
          this._updateLicenseInfo();
        }
      }
    },
    setImage(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.$v.image.$touch();
        this.imageUploaded = false;
      }
    },
    _uploadPassport() {
      let vm = this;
      vm.$store
        .dispatch(types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION, {
          file: vm.image
        })
        .then(passport => {
          vm.license_image = passport.location;
          vm.imageUploaded = true;
          vm._updateLicenseInfo();
        })
        .catch(error => {
          vm.isLoading = false;
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        });
    },
    _updateLicenseInfo() {
      let vm = this;
      vm.$v.$touch();
      vm.$store
        .dispatch(types.UPDATE_LICENSE_INFO_ACTION, {
          license_number: vm.license_number.toUpperCase(),
          license_image: vm.license_image,
          verification_id: vm.$route.params.verification_id
        })
        .then(verification => {
          vm.$notify.success({
            message: "License information saved successfully"
          });
          vm.$router.push({
            name: "AgentAddressView",
            params: { verification_id: verification.id }
          });
        })
        .catch(error => {
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification"
    })
  }
};
</script>
