<template>
  <div class="clearfix">
    <div class="col-xs-12">
      <span class="font-bold font-14">Status</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span
        class="text-muted font-14 font-bold text-capitalize text-nowrap"
        :class="[
          identity_info.status === 'verified'
            ? 'text-success'
            : identity_info.status === 'not_verified'
            ? 'text-danger'
            : identity_info.status === 'pending'
            ? 'text-warning'
            : identity_info.status === 'incomplete'
            ? 'text-primary'
            : ''
        ]"
      >
        {{ identity_info.status | remove_underscore }}
        <i
          class="mdi ml-1"
          :class="[
            identity_info.status === 'verified'
              ? 'mdi-check-circle'
              : identity_info.status === 'not_verified'
              ? 'mdi-alert'
              : identity_info.status === 'pending'
              ? 'mdi-clock'
              : identity_info.status === 'incomplete'
              ? 'mdi-alert-outline'
              : ''
          ]"
        ></i>
      </span>
      <p class="small" v-if="identity_info.reason">
        ({{ identity_info.reason }})
      </p>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="font-bold font-14">Identity Image</span>
    </div>
    <div class="col-xs-12">
      <a
        :href="[
          identity_info.photo ? identity_info.photo : '/static/avatar.png'
        ]"
        class="live-photo-popup"
        v-tooltip.right="'Click to enlarge image'"
      >
        <img
          class="img-responsive img-thumbnail font-12 bg-light d-block m-b-10"
          :src="[
            identity_info.photo ? identity_info.photo : '/static/avatar.png'
          ]"
          alt="Agent photo"
          style="width: 150px; height: 150px; object-fit: cover;"
          onerror="this.src='/static/avatar.png'"
        />
      </a>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Identity Type</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        identity_info.id_type === "bvn"
          ? "Bank Verification"
          : identity_info.id_type === "nin"
          ? "National Identification"
          : identity_info.id_type === "passport"
          ? "International Passport"
          : identity_info.id_type === "drivers_license"
          ? "Driver's License"
          : "Identity"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">
        {{
          identity_info.id_type === "bvn"
            ? "Bank Verification Number"
            : identity_info.id_type === "nin"
            ? "National Identification Number"
            : identity_info.id_type === "passport"
            ? "International Passport Number"
            : identity_info.id_type === "drivers_license"
            ? "Driver's License Number"
            : "Identity Number"
        }}
      </span>
    </div>
    <div class="col-xs-12 m-b-10">
      <template
        v-if="
          identityUpdateState === 'idle' ||
            identityUpdateState !== 'isIdUpdating'
        "
      >
        <span class="text-muted font-14">{{
          identity_info.id_number || "Not available"
        }}</span>
        <button
          class="btn btn-sm btn-outline-secondary m-1 font-bold"
          v-tooltip="'Click to edit ID number'"
          v-if="
            identityUpdateState === 'idle' &&
              $User.hasPermission('agent-edit') &&
              identityNumber
          "
          @click.prevent="identityUpdateState = 'isIdUpdating'"
        >
          <i class="mdi mdi-pencil"></i> Edit
        </button>
      </template>
      <template v-if="identityUpdateState === 'isIdUpdating'">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control rounded-0 input-sm"
              type="text"
              :class="{
                'form-error animated shake': $v.identityNumber.$error
              }"
              v-model.trim="identityNumber"
              name="identityNumber"
              @input="$v.identityNumber.$touch"
              placeholder="Enter Identity Number"
            />
          </div>
          <div class="col-12 mt-1">
            <button
              class="btn btn-sm btn-outline-success font-bold"
              @click.prevent="updateAgentInformation"
              :disabled="
                loading === 'identityNumberUpdate' ||
                  $v.identityNumber.$invalid ||
                  identityNumber === identity_info.id_number
              "
            >
              <i
                class="mdi"
                :class="
                  loading === 'identityNumberUpdate'
                    ? 'mdi-loading mdi-spin'
                    : 'mdi-floppy'
                "
              ></i>
              Save
            </button>
            <button
              @click.prevent="resetForms"
              class="btn btn-sm btn-outline-danger ml-1 font-bold"
              :disabled="loading === 'identityNumberUpdate'"
            >
              <i class="mdi mdi-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </template>
    </div>

    <div class="col-xs-12">
      <span class="font-bold font-14">Name on ID</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        full_name_id || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Phone Number</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        identity_info.mobile || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Date of Birth</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        identity_info.dob || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <button
        v-if="identity_info.can_retry && $User.hasPermission('identity-submit')"
        @click.prevent="retryAgentIdentityVerification"
        class="btn btn-warning"
        :disabled="loading"
      >
        Retry Agent Verification
        <i
          v-if="loading === 'retryingAgentVerification'"
          class="ml-1 mdi mdi-loading mdi-spin"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import { getIdentityValidations } from "@/helpers/getIdentityValidations";

export default {
  data() {
    return {
      loading: null,
      identityUpdateState: "idle",
      identityNumber: null,
      identityType: null
    };
  },
  created() {
    this.resetForms();
  },
  validations() {
    return getIdentityValidations.bind(this)("identityType", "identityNumber");
  },
  methods: {
    resetForms() {
      this.identityUpdateState = "idle";
      this.loading = null;
      this.identityNumber = this.identity_info.id_number;
      this.identityType = this.identity_info.id_type;
    },
    async updateAgentInformation() {
      if (this.loading === "identityNumberUpdate") {
        return;
      }

      try {
        this.loading = "identityNumberUpdate";

        await this.$store.dispatch(
          types.UPDATE_AGENT_INFORMATION_ACTION_ADMIN,
          {
            verification_id: this.verification.id,
            agentInfo: {
              ...(this.identityNumber && {
                id_number: this.identityNumber
              })
            }
          }
        );

        this.$notify.success({
          message: "Agent information updated successfully"
        });

        this.identityUpdateState = "idle";
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      } finally {
        this.loading = null;
      }
    },
    async retryAgentIdentityVerification() {
      if (this.loading === "retryingAgentVerification") {
        return;
      }

      try {
        this.loading = "retryingAgentVerification";
        let response = await store.dispatch(
          types.RETRY_AGENT_VERIFICATION,
          this.verification.id
        );
        this.$notify.success({
          message: response.message
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = null;
      }
    }
  },
  props: ["identity_info"],
  mounted() {
    this.$nextTick(() => {
      $(".live-photo-popup").magnificPopup({
        type: "image",
        closeOnContentClick: true,
        mainClass: "mfp-img-mobile mfp-with-zoom",
        image: {
          verticalFit: true
        },
        zoom: {
          enabled: true, // By default it's false, so don't forget to enable it
          duration: 300, // duration of the effect, in milliseconds
          easing: "ease-in-out", // CSS transition easing function
          // The "opener" function should return the element from which popup will be zoomed in
          // and to which popup will be scaled down
          // By default it looks for an image tag:
          opener: function(openerElement) {
            // openerElement is the element on which popup was initialized, in this case its <a> tag
            // you don't need to add "opener" option if this code matches your needs, it's default one.
            return openerElement.is("img")
              ? openerElement
              : openerElement.find("img");
          }
        }
      });
    });
  },
  computed: {
    ...mapGetters({
      verification: "getVerificationReport"
    }),
    full_name_id() {
      if (
        this.identity_info.first_name ||
        this.identity_info.middle_name ||
        this.identity_info.last_name
      ) {
        return `${this.identity_info.first_name} ${
          this.identity_info.middle_name
        } ${this.identity_info.last_name.toUpperCase()}`;
      }
      return "Not available";
    }
  }
};
</script>
