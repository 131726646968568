<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="add-company-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            Invite New Company
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="add-single-user-form">
            <div
              class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
              v-if="message"
            >
              {{ message }}
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="email" class="control-label form-label">
                  Email
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="email"
                  :class="{ 'form-error animated shake': $v.email.$error }"
                  @input="$v.email.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Email Address"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="business_name" class="control-label form-label">
                  Business Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="business_name"
                  :class="{
                    'form-error animated shake': $v.business_name.$error
                  }"
                  @input="$v.business_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Business Name"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="trade_name" class="control-label form-label">
                  Trade Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="trade_name"
                  :class="{ 'form-error animated shake': $v.trade_name.$error }"
                  @input="$v.trade_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Trade Name"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="category_id" class="control-label form-label">
                  Business category
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control rounded-0 custom-select"
                  v-model.trim="category_id"
                  :class="{
                    'form-error animated shake': $v.category_id.$error
                  }"
                >
                  <option value selected disabled
                    >-- select business category --</option
                  >
                  <option
                    :key="businessLicenseCategory._id"
                    v-for="businessLicenseCategory in businessLicenseCategories"
                    :value="businessLicenseCategory._id"
                    class="text-capitalize"
                    >{{ businessLicenseCategory.category_name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="first_name" class="control-label form-label">
                  Admin's First Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="first_name"
                  :class="{ 'form-error animated shake': $v.first_name.$error }"
                  @input="$v.first_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Admin First Name"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="last_name" class="control-label form-label">
                  Admin's Last Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="last_name"
                  :class="{ 'form-error animated shake': $v.last_name.$error }"
                  @input="$v.last_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Admin Last Name"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="phone_number" class="control-label form-label">
                  Phone number
                  <span class="text-danger">*</span>
                </label>
                <input
                  class="form-control rounded-0"
                  type="text"
                  :class="{
                    'form-error animated shake': $v.phone_number.$error
                  }"
                  v-model.trim="phone_number"
                  @input="$v.phone_number.$touch"
                  required="required"
                  placeholder="Enter Phone Number"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              @click.prevent="inviteNewUser"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Invite New Company
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  email,
  required,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";

export default {
  data() {
    return {
      email: "",
      business_name: "",
      trade_name: "",
      first_name: "",
      last_name: "",
      phone_number: "",
      category_id: "",

      isLoading: false,
      message: ""
    };
  },
  validations: {
    email: { required, email },
    // role: { required }
    business_name: { required },
    trade_name: { required },
    first_name: { required },
    last_name: { required },
    phone_number: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    category_id: { required }
  },
  methods: {
    async inviteNewUser() {
      //check if form is valid
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      //clear message if exists
      this.message = "";
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.INVITE_NEW_COMPANY_ACTION, {
          ...this.$data
        });
        eventBus.$emit("lslb:company:refresh-companies-list");
        this.email = "";
        this.business_name = "";
        this.trade_name = "";
        this.first_name = "";
        this.last_name = "";
        this.phone_number = "";
        this.category_id = "";
        this.isLoading = false;
        this.$v.$reset();
        //this.message = "Invitation sent successfully";
        $("#add-company-modal").modal("hide");
        this.$notify.success({ message: "Invitation sent successfully" });
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    }
  },
  computed: {
    ...mapGetters({
      businessLicenseCategories: "getBusinessLicenseCategories"
    })
  }
};
</script>
