<template>
  <div>
    <div class="comment-widgets font-14">
      <form
        class="row pl-2"
        @submit.prevent="addAgentComment"
        v-if="$User.hasPermission('agent-comment-add')"
      >
        <div class="font-14 m-b-10 col-md-12">
          <label class="form-label font-bold">
            Add New Comment
          </label>
          <textarea
            class="form-control rounded-0 mb-3"
            v-model.trim="comment"
            @input="$v.comment.$touch"
            :class="{ 'form-error animated shake': $v.comment.$error }"
            rows="4"
            placeholder="Enter Comment Here"
          ></textarea>
          <button
            class="btn btn-warning float-right"
            type="submit"
            :disabled="isLoading || $v.$invalid"
          >
            <i class="mdi mdi-floppy"></i> Add Comment
            <i class="mdi mdi-spin mdi-loading ml-1" v-show="isLoading"></i>
          </button>
        </div>
      </form>
      <!-- Comment Row -->
      <div
        class="d-flex flex-row comment-row"
        v-for="comment in comments"
        :key="comment.id"
      >
        <div class="p-2">
          <img
            alt="user"
            :src="comment.user.photo"
            onerror="this.src='/static/avatar.png'"
            class="profile-pic"
          />
        </div>
        <div class="comment-text w-100 p-l-15">
          <h5>
            {{
              comment.user.first_name +
                " " +
                (comment.user.middle_name
                  ? comment.user.middle_name + " "
                  : "") +
                comment.user.last_name
            }}
          </h5>
          <p class="mb-1" v-html="comment.comment"></p>
          <div class="comment-footer">
            <span class="text-muted float-right font-12 font-bold">{{
              comment.created_at | long_date
            }}</span>
            <!-- <span class="label label-light-info">Pending</span> -->
            <span class="action-icons">
              <button
                class="btn btn-outline-danger btn-sm mt-3"
                v-if="
                  $User.hasPermission('agent-comment-remove') &&
                    profile.id === comment.user.id
                "
                @click="showRemoveCommentModal(comment)"
              >
                <i class="mdi mdi-delete"></i>
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row" v-if="comments.length === 0">
        <div class="comment-text w-100 text-center">
          <img
            alt="user"
            src="@/assets/images/not_found.svg"
            onerror="this.src='/static/avatar.png'"
            class="w-25 mb-3"
          />
          <p class="mb-1 font-bold">
            There are no comments on this agent
          </p>
        </div>
      </div>
    </div>
    <remove-comment-modal
      :removeComment="removeAgentComment"
      :isLoading="isLoading"
    ></remove-comment-modal>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { required } from "vuelidate/lib/validators";
import RemoveCommentModal from "./RemoveCommentModal";
import { mapGetters } from "vuex";

export default {
  name: "AgentComments",
  props: ["agent"],
  components: { RemoveCommentModal },
  data() {
    return {
      comment: "",
      isLoading: false,
      selectedComment: ""
    };
  },
  validations() {
    return {
      comment: {
        required
      }
    };
  },
  computed: {
    comments() {
      //console.log(this.profile);
      return this.agent.comments || [];
    },

    ...mapGetters({
      profile: "getCurrentUser"
    })
  },
  methods: {
    async addAgentComment() {
      try {
        if (this.$v.$invalid || this.isLoading) {
          return;
        }
        this.isLoading = true;
        await this.$store.dispatch(types.ADD_AGENT_COMMENT, {
          agent_id: this.agent.id,
          comment: this.comment
        });
        this.$notify.success({ message: "Comment added successfully" });
        this.comment = "";
        this.$v.$reset();
      } catch (error) {
        console.log(error);
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async removeAgentComment() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(types.REMOVE_AGENT_COMMENT, {
          agent_id: this.agent.id,
          comment: this.selectedComment
        });
        this.$notify.success({ message: "Comment removed successfully" });
        $("#remove-comment-modal").modal("hide");
      } catch (error) {
        console.log("error :", error);
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    showRemoveCommentModal(comment) {
      this.selectedComment = comment;
      $("#remove-comment-modal").modal("show");
    }
  }
};
</script>
