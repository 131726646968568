<template>
  <div class="row">
    <!-- <div class="col-sm-12 col-md-8 offset-md-2"> -->
    <div class="col-sm-12">
      <div id="report-box" class="no-card mb-0">
        <div id="report-block" class="p-r-20 p-l-20">
          <section class="personal-information">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Personal Information
                  <router-link
                    :to="{ name: 'PersonalInformationView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <div class="col-md-12 col-sm-12 mt-3">
                    <span class="font-bold">Passport</span>
                    <br />
                    <img
                      class="img-fluid rounded-circle"
                      :src="
                        verification.personal_info.photo_meta.url ||
                          '/static/avatar.png'
                      "
                      onerror="this.src='/static/avatar.png'"
                      style="width: 150px; height: 150px; object-fit: cover;"
                    />
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Last Name</span>
                    <br />
                    <span>{{
                      verification.personal_info.last_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">First Name</span>
                    <br />
                    <span>{{
                      verification.personal_info.first_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Middle Name</span>
                    <br />
                    <span>{{
                      verification.personal_info.middle_name || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Date of Birth</span>
                    <br />
                    <span>{{ verification.personal_info.dob | date }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Email Address</span>
                    <br />
                    <span>{{ verification.personal_info.email || "N/A" }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Phone Number</span>
                    <br />
                    <span>{{
                      verification.personal_info.mobile || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Other Mobile Number</span>
                    <br />
                    <span>{{
                      verification.personal_info.other_mobile || "N/A"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="identity-information">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Identity Information
                  <router-link
                    :to="{ name: 'IdentityVerificationNumberView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Identification Type</span>
                    <br />
                    <span>
                      {{
                        verification.identity_info.id_type === "bvn"
                          ? "Bank Verification"
                          : verification.identity_info.id_type === "nin"
                          ? "National Identification"
                          : verification.identity_info.id_type === "passport"
                          ? "International Passport"
                          : verification.identity_info.id_type ===
                            "drivers_license"
                          ? "Driver's License"
                          : "Identity"
                      }}
                    </span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Identification Number</span>
                    <br />
                    <span>{{
                      verification.identity_info.id_number || "N/A"
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="agent-address">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Business Address
                  <router-link
                    :to="{ name: 'AgentAddressView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Flat Number</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.flat_number || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Building Number</span>
                    <br />
                    <span>{{
                      verification.address_info.building_number || "N/A"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Building Name</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.building_name || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Street</span>
                    <br />
                    <span>{{ verification.address_info.street || "N/A" }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Sub-street</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.sub_street || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Landmark</span>
                    <br />
                    <span>{{
                      verification.address_info.landmark || "N/A"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Postcode</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.postcode || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Town/City</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{ verification.address_info.city || "N/A" }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">LCA/LCDA</span>
                    <br />
                    <span>{{ verification.address_info.region || "N/A" }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">State</span>
                    <br />
                    <!--                    <span>{{ verification.address_info.state || "N/A" }}</span>-->
                    <span>{{
                      verification.address_info.state || "Lagos"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Country</span>
                    <br />
                    <!--                    <span>{{-->
                    <!--                      verification.address_info.country || "N/A"-->
                    <!--                    }}</span>-->
                    <span>{{
                      verification.address_info.country || "Nigeria"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-12 col-sm-12 mt-3">-->
                  <!--                    <span class="font-bold">NIPOST Digital Address</span>-->
                  <!--                    <img-->
                  <!--                      src="/static/nipost_logo.png"-->
                  <!--                      class="d-inline-block w-auto ml-1"-->
                  <!--                      style="height: 14px;"-->
                  <!--                    />-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.nipost_address || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-12 col-sm-12 mt-3">-->
                  <!--                    <span class="font-bold">Description</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.description || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </section>

          <section class="agent-address">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Residential Address
                  <router-link
                    :to="{ name: 'AgentResidentialAddressView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Flat Number</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.flat_number || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Building Number</span>
                    <br />
                    <span>{{
                      verification.residential_address.building_number || "N/A"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Building Name</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.residential_address.building_name || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Street</span>
                    <br />
                    <span>{{
                      verification.residential_address.street || "N/A"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Sub-street</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.sub_street || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Landmark</span>
                    <br />
                    <span>{{
                      verification.residential_address.landmark || "N/A"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Postcode</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.postcode || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold">Town/City</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{ verification.address_info.city || "N/A" }}</span>-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">LCA/LCDA</span>
                    <br />
                    <span>{{
                      verification.residential_address.region || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">State</span>
                    <br />
                    <!--                    <span>{{ verification.residential_address.state || "N/A" }}</span>-->
                    <span>{{
                      verification.residential_address.state || "Lagos"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Country</span>
                    <br />
                    <!--                    <span>{{-->
                    <!--                      verification.residential_address.country || "N/A"-->
                    <!--                    }}</span>-->
                    <span>{{
                      verification.residential_address.country || "Nigeria"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-12 col-sm-12 mt-3">-->
                  <!--                    <span class="font-bold">NIPOST Digital Address</span>-->
                  <!--                    <img-->
                  <!--                      src="/static/nipost_logo.png"-->
                  <!--                      class="d-inline-block w-auto ml-1"-->
                  <!--                      style="height: 14px;"-->
                  <!--                    />-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.nipost_address || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                  <!--                  <div class="col-md-12 col-sm-12 mt-3">-->
                  <!--                    <span class="font-bold">Description</span>-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.address_info.description || "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </section>

          <section class="guarantor-personal-information">
            <div class="card bordered-box">
              <div class="card-block">
                <h2 class="card-title" style="color: rgb(3, 27, 77);">
                  Guarantor Personal Information
                  <router-link
                    :to="{ name: 'GuarantorInformationView' }"
                    class="font-14 ml-1"
                    >[ Edit ]</router-link
                  >
                </h2>
                <div class="row">
                  <!--                  <div class="col-md-12 col-sm-12 mt-3">-->
                  <!--                    <span class="font-bold">Guarantor Passport</span>-->
                  <!--                    <br />-->
                  <!--                    <img-->
                  <!--                      class="img-fluid rounded-circle"-->
                  <!--                      :src="-->
                  <!--                        verification.guarantor_info.personal_info.photo_meta-->
                  <!--                          .url || '/static/avatar.png'-->
                  <!--                      "-->
                  <!--                      onerror="this.src='/static/avatar.png'"-->
                  <!--                      style="width: 150px; height: 150px; object-fit: cover;"-->
                  <!--                    />-->
                  <!--                  </div>-->
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Guarantor's Last Name</span>
                    <br />
                    <span>{{
                      verification.guarantor_info.personal_info.last_name ||
                        "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Guarantor's First Name</span>
                    <br />
                    <span>{{
                      verification.guarantor_info.personal_info.first_name ||
                        "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Guarantor's Email Address</span>
                    <br />
                    <span>{{
                      verification.guarantor_info.personal_info.email || "N/A"
                    }}</span>
                  </div>
                  <div class="col-md-6 col-sm-6 mt-3">
                    <span class="font-bold">Guarantor's Phone Number</span>
                    <br />
                    <span>{{
                      verification.guarantor_info.personal_info.mobile || "N/A"
                    }}</span>
                  </div>
                  <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
                  <!--                    <span class="font-bold"-->
                  <!--                      >Guarantor's Other Mobile Number</span-->
                  <!--                    >-->
                  <!--                    <br />-->
                  <!--                    <span>{{-->
                  <!--                      verification.guarantor_info.personal_info.other_mobile ||-->
                  <!--                        "N/A"-->
                  <!--                    }}</span>-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </section>
          <!--          <section class="guarantor-identity-information">-->
          <!--            <div class="card bordered-box">-->
          <!--              <div class="card-block">-->
          <!--                <h2 class="card-title" style="color: rgb(3, 27, 77);">-->
          <!--                  Guarantor Identity Information-->
          <!--                  <router-link-->
          <!--                    :to="{ name: 'GuarantorInformationView' }"-->
          <!--                    class="font-14 ml-1"-->
          <!--                    >[ Edit ]</router-link-->
          <!--                  >-->
          <!--                </h2>-->
          <!--                <div class="row">-->
          <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
          <!--                    <span class="font-bold">Identification Type</span>-->
          <!--                    <br />-->
          <!--                    <span>{{-->
          <!--                      verification.guarantor_info.identity_info.id_type ===-->
          <!--                      "bvn"-->
          <!--                        ? "Bank Verification"-->
          <!--                        : verification.guarantor_info.identity_info.id_type ===-->
          <!--                          "nin"-->
          <!--                        ? "NIN Verification"-->
          <!--                        : "Identity Type"-->
          <!--                    }}</span>-->
          <!--                  </div>-->
          <!--                  <div class="col-md-6 col-sm-6 mt-3">-->
          <!--                    <span class="font-bold">Identification Number</span>-->
          <!--                    <br />-->
          <!--                    <span>{{-->
          <!--                      verification.guarantor_info.identity_info.id_number ||-->
          <!--                        "N/A"-->
          <!--                    }}</span>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </section>-->
          <div class="row mt-3">
            <div class="col-md-6 col-sm-6 m-b-10">
              <router-link
                tag="button"
                :to="{ name: 'GuarantorInformationView' }"
                class="btn btn-outline-inverse waves-effect rounded-0 w-100"
              >
                <span class="mdi mdi-arrow-left-thick"></span>
                Back
              </router-link>
            </div>
            <div class="col-md-6 col-sm-6">
              <button
                @click="proceedToPayment"
                class="btn btn-warning waves-effect rounded-0 w-100"
              >
                Proceed to Payment
                <i class="mdi mdi-arrow-right-thick"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notify from "@/helpers/notifyMe";
import store from "@/store";
import { mapGetters } from "vuex";

export default {
  name: "RequestVerificationPreviewView",
  methods: {
    proceedToPayment() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to change your information after submission!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, proceed to payment!",
        cancelButtonText: "No, let me review!"
      }).then(result => {
        if (result.value) {
          this.$router.push({
            name: "VerificationPaymentView"
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification"
    })
  },
  beforeRouteEnter(to, from, next) {
    let verification = store.getters.getRequestVerification;
    if (
      !verification.personal_info.completed ||
      !verification.identity_info.completed ||
      !verification.address_info.completed ||
      !verification.residential_address.completed ||
      !verification.guarantor_info.completed
    ) {
      notify.error({ message: "Please complete the form before previewing" });
      next({
        name: "PersonalInformationView",
        params: { verification_id: verification.id }
      });
      return next(false);
    } else {
      next();
    }
  }
};
</script>
