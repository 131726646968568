import capitalize from "./capitalize";
import date from "./date";
import long_date from "./long_date";
import from_now from "./from_now";
import amount from "./amount";
import remove_underscore from "./remove_underscore";
import two_digits from "./two_digits";
import title_case from "./title_case";
import not_available from "./not_available";
import slugify from "./slugify";

export default {
  install(Vue) {
    Vue.filter("capitalize", capitalize);
    Vue.filter("date", date);
    Vue.filter("long_date", long_date);
    Vue.filter("amount", amount);
    Vue.filter("remove_underscore", remove_underscore);
    Vue.filter("two_digits", two_digits);
    Vue.filter("title_case", title_case);
    Vue.filter("from_now", from_now);
    Vue.filter("not_available", not_available);
    Vue.filter("slugify", slugify);
  }
};
