var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid position-relative w-100"},[_c('app-title',{attrs:{"title":"Report"}}),_c('report-component',_vm._b({attrs:{"hide_report":_vm.hide_report},on:{"show_report":function($event){_vm.show_report = true}}},'report-component',{ verification: _vm.verification },false),[_c('template',{slot:"header"},[_c('ul',{staticClass:"nav nav-tabs customtab scrollable bg-light rounded",attrs:{"role":"tablist"}},_vm._l((_vm.tabRoutes),function(ref){
var name = ref.name;
var componentName = ref.componentName;
var verificationKey = ref.verificationKey;
return _c('li',{key:name,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: componentName },"active-class":"active","role":"tab"}},[_vm._v(" "+_vm._s(name)+" "),_c('i',{staticClass:"mdi",class:_vm.verification[verificationKey].status === 'verified'
                  ? 'text-success mdi-check-circle'
                  : _vm.verification[verificationKey].status === 'not_verified'
                  ? 'text-danger mdi-alert'
                  : _vm.verification[verificationKey].status === 'pending'
                  ? 'text-info mdi-clock'
                  : _vm.verification[verificationKey].status === 'incomplete'
                  ? 'text-primary mdi-alert-outline'
                  : _vm.verification[verificationKey].status === 'awaiting_qa'
                  ? 'text-info mdi-clock'
                  : _vm.verification[verificationKey].status === 'completed'
                  ? null
                  : 'text-warning mdi-alert'})])],1)}),0)]),_c('router-view',_vm._b({},'router-view',_vm.verification,false))],2),(_vm.hide_report && _vm.profile.user_type !== 'company')?_c('div',{staticClass:"overlay"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }