import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  check_agent_profile: null,
  agent_profile_report: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
