<template>
  <div>
    <account-balance
      v-if="$User.hasPermission('business-balance-view')"
    ></account-balance>
    <div v-if="$User.hasPermission('metric-view')">
      <verifications-analytics></verifications-analytics>
      <agents-analytics></agents-analytics>
      <IdentityMetrics />
    </div>
  </div>
</template>

<script>
import VerificationsAnalytics from "./_components/VerificationsAnalytics";
import AgentsAnalytics from "./_components/AgentsAnalytics";
import AccountBalance from "./_components/AccountBalance";
import notify from "@/helpers/notifyMe";
import store from "@/store";
import * as types from "@/store/types";
import IdentityMetrics from "@/modules/dashboard/_components/IdentityMetrics";

export default {
  components: {
    IdentityMetrics,
    AgentsAnalytics,
    AccountBalance,
    VerificationsAnalytics
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await Promise.all([
        store.dispatch(types.GET_AGENT_ANALYTICS_ACTION),
        store.dispatch(types.GET_DASHBOARD_METRICS_ACTION),
        store.dispatch(types.GET_VERIFICATIONS_BY_CITIES_ACTION),
        store.dispatch(types.GET_VERIFICATIONS_METRICS_ACTION),
        store.dispatch(types.GET_IDENTITY_METRICS_ACTION)
      ]);
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
    }
  }
};
</script>
