const getters = {
  getVerificationsLog: state => {
    return state.verifications_log;
  },
  getVerificationsLogPagination: state => {
    return state.verifications_meta && state.verifications_meta.pagination;
  },
  getVerificationsLogMeta: state => {
    return state.verifications_meta;
  },
  getVerificationReport: state => {
    return state.verification_report;
  },
  getInfractionPhoto: state => {
    return state.infraction_photos;
  }
};

export default getters;
