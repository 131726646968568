<template>
  <header class="topbar">
    <nav
      class="navbar top-navbar navbar-toggleable-sm navbar-light"
      v-if="isPageContentReady"
    >
      <!-- ============================================================== -->
      <!-- Logo -->
      <!-- ============================================================== -->
      <div class="navbar-header" style="padding-left: 20px; text-align: left;">
        <router-link :to="{ name: 'HomeView' }" class="navbar-brand">
          <!-- Logo icon -->
          <b>
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <!-- <img src="/static/icons/youverify_icon.svg" style="width: 34px; height: auto;" alt="homepage" class="dark-logo home-icon" /> -->
            <!-- Light Logo icon -->
            <img
              src="@/assets/images/lslb_logo.png"
              alt="homepage"
              class="light-logo home-icon img-responsive"
            />
          </b>
          <!--End Logo icon -->
          <!-- Logo text -->
          <span>
            <!-- dark Logo text -->
            <!-- <img src="/static/icons/youverify_icon_text.svg" style="width: 148px; height: auto;" alt="homepage" class="dark-logo" /> -->
            <!-- Light Logo text -->
            <img
              src="@/assets/images/lslb_logo.png"
              class="light-logo img-responsive center"
              style="width: 60px;"
              alt="homepage"
            />
          </span>
        </router-link>
      </div>
      <!-- ============================================================== -->
      <!-- End Logo -->
      <!-- ============================================================== -->
      <div
        class="navbar-collapse header-shadow"
        style="display: flex; align-items:center;"
      >
        <!-- ============================================================== -->
        <!-- toggle and nav items -->
        <!-- ============================================================== -->
        <ul class="navbar-nav mr-10 mt-md-0">
          <!-- This is  -->
          <li class="nav-item">
            <a
              class="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark"
              @click.prevent="toggleSideClass"
            >
              <i class="mdi mdi-menu"></i>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark"
              @click.prevent="toggleSidebar"
            >
              <i class="mdi mdi-arrow-left"></i>
            </a>
          </li>
          <!-- <li class="nav-item">
            <span
              class="nav-link font-12 mx-auto"
            >Last login: {{current_user.login_times[current_user.login_times.length - 1] | long_date}}</span>
          </li>-->
          <!-- ============================================================== -->
          <!-- Comment -->
          <!-- ============================================================== -->
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-muted text-muted waves-effect waves-dark"
              href
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-message"></i>
              <div class="notify">
                <span class="heartbit"></span>
                <span class="point"></span>
              </div>
            </a>
          </li>-->
          <!-- ============================================================== -->
          <!-- End Comment -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Messages -->
          <!-- ============================================================== -->
          <!-- <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
              href
              id="2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-email"></i>
              <div class="notify">
                <span class="heartbit"></span>
                <span class="point"></span>
              </div>
            </a>
          </li>-->
          <!-- ============================================================== -->
          <!-- End Messages -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- Messages -->
          <!-- ============================================================== -->
          <!-- <li class="nav-item dropdown mega-dropdown">
            <a
              class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
              href
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="mdi mdi-view-grid"></i>
            </a>
          </li>-->
          <!-- ============================================================== -->
          <!-- End Messages -->
          <!-- ============================================================== -->
        </ul>

        <h3 class="mr-auto mb-0">{{ business_profile.trade_name }}</h3>

        <!-- ============================================================== -->
        <!-- User profile and search -->
        <!-- ============================================================== -->
        <ul class="navbar-nav my-lg-0">
          <!-- <li class="nav-item hidden-sm-down">
            <form class="app-search">
              <input type="text" id="search-tour" class="form-control" placeholder="Search for...">
              <label class="srh-btn" for="search-tour">
                <i class="mdi mdi-magnify"></i>
              </label>
            </form>
          </li>-->
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
              href
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                class="profile-pic"
                alt="user"
                :src="[
                  current_user.photo
                    ? current_user.photo
                    : '/static/avatar@3x.png'
                ]"
                onerror="this.src='/static/avatar@3x.png'"
              />
              <span class="p-l-10 font-14 text-capitalize">
                {{ current_user.first_name }}
              </span>
              <span class="mdi mdi-chevron-down p-l-5 p-t-5"></span>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right animated fastest slideInRight p-b-0"
            >
              <ul class="dropdown-user">
                <li>
                  <div class="dw-user-box">
                    <div class="u-text p-l-0">
                      <h4>
                        {{ current_user.first_name }}
                        {{ current_user.last_name }}
                      </h4>
                      <p class="text-muted">{{ current_user.email }}</p>
                    </div>
                  </div>
                </li>
                <li role="separator" class="divider"></li>
                <template v-if="$User.hasPermission('setting-view')">
                  <li>
                    <router-link :to="{ name: 'ProfileView' }">
                      My Profile
                    </router-link>
                  </li>
                  <li role="separator" class="divider"></li>
                </template>

                <!-- <li>
                  <a href="javascript:void(0)">Account Setting</a>
                </li>-->
                <!-- <li role="separator" class="divider"></li> -->
                <li>
                  <router-link :to="{ name: 'LogoutView' }">Logout</router-link>
                </li>
              </ul>
            </div>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
              href="#"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></a>
          </li>-->
        </ul>
      </div>
    </nav>
    <div
      class="header-content-loader"
      style="height: 68px; width:100%;"
      v-if="!isPageContentReady"
    >
      <content-loader
        style="width:100%;"
        :height="68"
        :width="width"
        :speed="2"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <rect x="15" y="14" rx="5" ry="5" width="210" height="40" />
        <!-- <g>
                    <rect x="15" y="14" rx="5" ry="5" width="210" height="40" />
                    <text x="0" y="50" font-family="Verdana" font-size="35" fill="blue">Hello</text>
        </g>-->
        <rect
          :x="leftRectWidthMargin"
          y="24"
          rx="5"
          ry="5"
          :width="rectWidth"
          height="20"
        />
        <rect
          :x="rightRectWidthMargin"
          y="24"
          rx="5"
          ry="5"
          :width="rectWidth"
          height="20"
        />
        <circle :cx="circleWidth" cy="34" r="12" />
      </content-loader>
    </div>
  </header>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
import { mapGetters } from "vuex";
export default {
  name: "app-header",
  data() {
    return {
      width: 0,
      leftRectWidthMargin: 0,
      rightRectWidthMargin: 0,
      rectWidth: 0,
      rightRectWidth: 0,
      circleWidth: 0
    };
  },
  components: {
    ContentLoader
  },
  methods: {
    toggleSidebar() {
      if ($("body").hasClass("mini-sidebar")) {
        $(window).trigger("resize");
        $(".scroll-sidebar, .slimScrollDiv")
          .css("overflow", "hidden")
          .parent()
          .css("overflow", "visible");
        $("body").removeClass("mini-sidebar");
        $(".navbar-brand span").show();
        $(".sidebartoggler i").removeClass("mdi-menu");
        $(".sidebartoggler i").addClass("mdi-arrow-left");
      } else {
        $(window).trigger("resize");
        $(".scroll-sidebar, .slimScrollDiv")
          .css("overflow-x", "visible")
          .parent()
          .css("overflow", "visible");
        $("body").addClass("mini-sidebar");
        $(".navbar-brand span").hide();
        $(".sidebartoggler i").addClass("mdi-arrow-left");
        $(".sidebartoggler i").addClass("mdi-menu");
      }
    },
    toggleSideClass() {
      $("body").toggleClass("show-sidebar");
      $(".nav-toggler i").toggleClass("mdi-menu");
      $(".nav-toggler i").addClass("mdi-close");
    }
  },
  // updated() {
  //   $(".sidebartoggler").on("click", function() {
  //     if ($("body").hasClass("mini-sidebar")) {
  //       console.log('if')
  //       $(window).trigger("resize");
  //       $(".scroll-sidebar, .slimScrollDiv")
  //         .css("overflow", "hidden")
  //         .parent()
  //         .css("overflow", "visible");
  //       $("body").removeClass("mini-sidebar");
  //       $(".navbar-brand span").show();
  //       $(".sidebartoggler i").addClass("mdi-menu");
  //     } else {
  //        console.log('else')
  //       $(window).trigger("resize");
  //       $(".scroll-sidebar, .slimScrollDiv")
  //         .css("overflow-x", "visible")
  //         .parent()
  //         .css("overflow", "visible");
  //       $("body").addClass("mini-sidebar");
  //       $(".navbar-brand span").hide();
  //       $(".sidebartoggler i").removeClass("mdi-menu");
  //     }
  //   });
  //   // topbar stickey on scroll

  //   $(".fix-header .topbar").stick_in_parent({});

  //   // this is for close icon when navigation open in mobile view
  //   $(".nav-toggler").on("click", function() {
  //     $("body").toggleClass("show-sidebar");
  //     $(".nav-toggler i").toggleClass("mdi-menu");
  //     $(".nav-toggler i").addClass("mdi-close");
  //   });
  //   $(".sidebartoggler").on("click", function() {
  //     $(".sidebartoggler i").toggleClass("mdi-menu");
  //   });
  // },
  mounted() {
    // $(".sidebartoggler").on("click", function() {
    //   if ($("body").hasClass("mini-sidebar")) {
    //     $(window).trigger("resize");
    //     $(".scroll-sidebar, .slimScrollDiv")
    //       .css("overflow", "hidden")
    //       .parent()
    //       .css("overflow", "visible");
    //     $("body").removeClass("mini-sidebar");
    //     $(".navbar-brand span").show();
    //     $(".sidebartoggler i").addClass("mdi-menu");
    //   } else {
    //     $(window).trigger("resize");
    //     $(".scroll-sidebar, .slimScrollDiv")
    //       .css("overflow-x", "visible")
    //       .parent()
    //       .css("overflow", "visible");
    //     $("body").addClass("mini-sidebar");
    //     $(".navbar-brand span").hide();
    //     $(".sidebartoggler i").removeClass("mdi-menu");
    //   }
    // });
    // topbar stickey on scroll

    $(".fix-header .topbar").stick_in_parent({});

    // this is for close icon when navigation open in mobile view
    // $(".nav-toggler").on("click", function() {
    //   $("body").toggleClass("show-sidebar");
    //   $(".nav-toggler i").toggleClass("mdi-menu");
    //   $(".nav-toggler i").addClass("mdi-close");
    // });
    // $(".sidebartoggler").on("click", function() {
    //   $(".sidebartoggler i").toggleClass("mdi-menu");
    // });
  },
  created() {
    let vm = this;
    vm.width = $(window).width();
    $(window).resize(function() {
      vm.rectWidth = Math.ceil((vm.width - 240 - 30 - 100 - 20) / 2);
      vm.leftRectWidthMargin = 240 + 30;
      vm.rightRectWidthMargin = vm.leftRectWidthMargin + vm.rectWidth + 20;
      vm.circleWidth = vm.width - 50;
    });
  },
  computed: {
    ...mapGetters({
      isPageContentReady: "isPageContentReady",
      current_user: "getCurrentUser",
      business_profile: "getBusinessProfile"
    })
  }
};
</script>

<style lang="scss">
.header-shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.mini-sidebar .top-navbar .navbar-header {
  width: 60px;
  text-align: center;
  text-align: left !important;
  padding-left: 10px !important;
}
</style>
