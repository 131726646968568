<template>
  <form @submit.prevent="updateIdentityInfo">
    <div class="row">
      <div class="col-md-6 form-group m-b-10">
        <label class="col-md-12 font-bold form-label">
          Identity Type
          <span class="text-danger">*</span>
        </label>
        <div class="col-md-12">
          <select
            name="identity_type"
            id="identity_type"
            class="form-control rounded-0"
            :class="{ 'form-error animated shake': $v.identity_type.$error }"
            v-model.trim="identity_type"
            @change="$v.identity_type.$touch"
            required="required"
          >
            <option value="">--Select Identity Type--</option>
            <option value="bvn">BVN</option>
            <option value="nin">NIN</option>
            <!--            <option value="passport">Passport</option>-->
            <option value="drivers_license">Driver's License</option>
          </select>
        </div>
      </div>
      <div class="col-md-6 form-group m-b-10">
        <label class="col-md-12 font-bold form-label">
          {{
            identity_type === "bvn"
              ? "BVN"
              : identity_type === "nin"
              ? "NIN"
              : identity_type === "passport"
              ? "International Passport"
              : identity_type === "drivers_license"
              ? "Driver's License"
              : "Identity"
          }}
          Number
          <span class="text-danger">*</span>
        </label>
        <div class="col-md-12">
          <input
            class="form-control rounded-0"
            v-model.trim="identity_number"
            :class="{ 'form-error animated shake': $v.identity_number.$error }"
            @input="$v.identity_number.$touch"
            :placeholder="
              'Enter ' +
                (identity_type === 'bvn'
                  ? 'BVN'
                  : identity_type === 'nin'
                  ? 'NIN'
                  : identity_type === 'passport'
                  ? 'International Passport'
                  : identity_type === 'drivers_license'
                  ? 'Driver\'s License'
                  : 'Identity') +
                ' Number'
            "
          />
          <p class="font-12 mt-2">
            <template v-if="identity_type === 'nin'">
              To get your NIN, dial <span class="font-bold">*346#</span> from
              your registered mobile number.
            </template>
            <template v-if="identity_type === 'bvn'">
              To get your BVN, dial <span class="font-bold">*565*0#</span> from
              your registered mobile number.
            </template>
            <template v-if="identity_type === 'passport'">
              To get your Passport Number, check your
              <span class="font-bold">International passport</span>
            </template>
            <template v-if="identity_type === 'drivers_license'">
              To get your Driver's License Number, check your
              <span class="font-bold">Driver's License</span>
            </template>
          </p>
        </div>
      </div>
    </div>
    <div class="row bg-white">
      <div class="col-md-12">
        <div class="row flex align-items-end">
          <div
            class="col-md-12 text-center font-14 p-2 p-md-5"
            v-if="identity_type === ''"
          >
            <p>You've not selected an Identity Type</p>
          </div>
          <div
            class="col-md-12 text-center font-14 p-2 p-md-5"
            v-if="identity_type === 'bvn'"
          >
            <p class="font-40">
              e.g.: <span class="font-bold">2000456709</span>
            </p>
            <p class="font-20">
              <span class="font-bold">BVN</span> is an 11 digit number
            </p>
          </div>
          <template v-if="identity_type === 'nin'">
            <div class="col-md-6 p-2 p-md-5 text-center">
              <img
                src="@/assets/images/nin-slip.png"
                alt="NIMC Slip"
                class="img-responsive"
              />
              <p class="mt-4 font-14 font-italic">
                If you have the <span class="font-bold">NIN Slip</span>, your
                NIN can be found in the area highlighted in the sample above.
              </p>
            </div>
            <div class="col-md-6 p-2 p-md-5 text-center">
              <img
                src="@/assets/images/nin-card.png"
                alt="NIN Card"
                class="img-responsive"
              />
              <p class="mt-4 font-14 font-italic">
                If you have the
                <span class="font-bold">National Identity Card</span>, your
                document number can be found in the area highlighted in the
                sample ID card above.
              </p>
            </div>
          </template>
          <div
            class="col-md-12 p-2 p-md-5 text-center"
            v-if="identity_type === 'drivers_license'"
          >
            <img
              src="@/assets/images/license-card.png"
              alt="Driver License"
              class="img-responsive"
              style="max-width: 400px"
            />
            <p class="mt-4 font-14 font-italic">
              Your <span class="font-bold">Driver's License Number</span> can be
              found in the area highlighted<br />
              in the sample above.
            </p>
          </div>
          <div
            class="col-md-12 p-2 p-md-5 text-center"
            v-if="identity_type === 'passport'"
          >
            <img
              src="@/assets/images/passport.png"
              alt="Driver License"
              z
              class="img-responsive"
              style="max-width: 400px"
            />
            <p class="mt-4 font-14 font-italic">
              Your
              <span class="font-bold">International Passport Number</span> can
              be found in the area highlighted<br />
              in the sample above.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-md-12 m-t-0">
            <router-link
              tag="button"
              :to="{ name: 'GuarantorPersonalInformationView' }"
              class="btn btn-outline-inverse waves-effect rounded-0 w-100"
            >
              <span class="mdi mdi-arrow-left-thick"></span>
              Back
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-md-12 m-t-0">
            <button
              class="btn btn-warning waves-effect rounded-0 w-100"
              :disabled="$v.$invalid || isLoading"
              type="submit"
            >
              Save
              <span v-if="isLoading" class="mdi mdi-loading mdi-spin"></span>
              <span v-else class="mdi mdi-arrow-right-thick"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { getIdentityValidations } from "@/helpers/getIdentityValidations";

export default {
  data() {
    return {
      identity_type: "",
      identity_number: "",
      isLoading: false
    };
  },
  validations() {
    return getIdentityValidations.bind(this)();
  },

  mounted() {
    this.identity_type = this.guarantor.identity_info.id_type || "";
    this.identity_number = this.guarantor.identity_info.id_number;
  },
  methods: {
    updateIdentityInfo() {
      let vm = this;
      vm.$v.$touch();
      if (vm.$v.$invalid || vm.isLoading) {
        return;
      }
      vm.isLoading = true;

      vm.$store
        .dispatch(types.UPDATE_GUARANTOR_IDENTITY_INFO, {
          id_type: vm.identity_type,
          id_number: vm.identity_number,
          token: vm.$route.params.guarantor_token
        })
        .then(() => {
          vm.$notify.success({
            message: "Identity Information saved successfully"
          });
          vm.$router.push({
            name: "GuarantorPreviewView"
            //guarantor_token: vm.$route.guarantor_token
          });
          // vm.$router.replace({
          //   name: "GuarantorFormSubmittedSuccessfullyView",
          //   guarantor_token: vm.$route.guarantor_token
          // });
        })
        .catch(error => {
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters({
      guarantorDetails: "getGuarantorDetails",
      guarantorPayload: "getGuarantorPayload"
    }),
    guarantor() {
      return this.guarantorDetails && this.guarantorDetails.guarantor;
    }
  }
};
</script>
