import * as types from "@/store/types";

const mutations = {
  async [types.SET_GUARANTOR_DETAILS](state, payload) {
    state.guarantorDetails = payload;
  },
  async [types.SET_GUARANTOR_PAYLOAD](state, payload) {
    state.guarantorPayload = Object.assign({}, state.guarantorPayload, payload);
  }
};

export default mutations;
