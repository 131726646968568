<template>
  <div class="row">
    <!-- Column -->
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="row">
            <div class="col-12">
              <div class="d-flex flex-wrap">
                <div>
                  <h3 class="card-title">Application Metrics</h3>
                  <!-- <h6 class="card-subtitle"></h6> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-9 col-lg-9">
              <div class="mb-3">
                Showing metrics for
                <button class="btn dropdown di">
                  <span
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    <strong class="font-medium">{{ periodLabel }}</strong>
                    <i class="caret font-medium"></i>
                  </span>
                  <ul
                    role="menu"
                    class="
                      dropdown-menu
                      animated
                      pulse
                      m-t-10
                      address-metric-filter-popup
                      modal-border-radius
                    "
                  >
                    <li class="dropdown-item" @click="setPeriod('today')">
                      <a href="javascript:void(0);">Today</a>
                    </li>
                    <li class="dropdown-item" @click="setPeriod('this_week')">
                      <a href="javascript:void(0);">This Week</a>
                    </li>
                    <li class="dropdown-item" @click="setPeriod('this_month')">
                      <a href="javascript:void(0);">This Month</a>
                    </li>
                    <li
                      class="dropdown-item"
                      @click="setPeriod('last_30_days')"
                    >
                      <a href="javascript:void(0);">Last 30 days</a>
                    </li>
                    <li
                      class="dropdown-item"
                      @click="setPeriod('last_90_days')"
                    >
                      <a href="javascript:void(0);">Last 90 days</a>
                    </li>
                    <li class="dropdown-item" @click="setPeriod('this_year')">
                      <a href="javascript:void(0);">This Year</a>
                    </li>
                    <li class="dropdown-item" @click="setPeriod('all_time')">
                      <a href="javascript:void(0);">All Time</a>
                    </li>
                    <li
                      class="dropdown-item"
                      @click="setPeriod('custom_period')"
                    >
                      <a href="javascript:void(0);">Custom Period</a>
                    </li>
                  </ul>
                </button>
                <template v-if="showCustomPeriodDatePicker">
                  <flat-pickr
                    style="width: 130px"
                    v-model.trim="fromDate"
                    input-class="form-control form-control-sm"
                    class="inline-block"
                    placeholder="From"
                    name="start"
                    :config="fromDateConfig"
                  ></flat-pickr>
                  <span
                    class="
                      input-group-addon
                      bg-primary
                      b-0
                      text-white
                      inline-block
                      form-control-sm
                    "
                    style="width: 40px"
                    >to</span
                  >
                  <flat-pickr
                    style="width: 130px"
                    v-model.trim="toDate"
                    input-class="form-control form-control-sm"
                    class="inline-block"
                    placeholder="To"
                    name="end"
                    :config="toDateConfig"
                  ></flat-pickr>
                </template>
                <button
                  type="button"
                  @click.prevent="reloadChart"
                  :disabled="isChartLoading"
                  class="
                    btn btn-warning btn-sm
                    waves-effect waves-light
                    m-r-10 m-l-10
                  "
                >
                  <span v-if="!isChartLoading">Apply</span>
                  <span v-if="isChartLoading"
                    >Applying <i class="ml-1 mdi mdi-spin mdi-loading"></i
                  ></span>
                </button>
                <!-- <button
                  v-if="$User.hasPermission('metric-export')"
                  type="button"
                  class="btn btn-primary btn-sm waves-effect waves-light m-r-10 m-l-5"
                >
                  <span>Export as CSV</span>
                </button> -->
              </div>
              <canvas id="verification-city-metrics"></canvas>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <div class="row">
                <div class="col-sm-6 col-md-12 mb-3 text-left">
                  <div class="b-all rounded dashboard-metric-card p-2">
                    <h3 class="m-b-0 font-medium">
                      <i-count-up
                        :startVal="0"
                        :endVal="verification_by_cities.total.verified"
                        :decimals="0"
                        :duration="1.5"
                        :options="options"
                        :callback="noop"
                      ></i-count-up>
                    </h3>
                    <h6 class="text-muted">Verified</h6>
                  </div>
                  <div class="col-sm-6 p-0 col-md-12 mt-3 mb-3 text-left">
                    <div class="b-all rounded dashboard-metric-card p-2">
                      <h3 class="m-b-0 font-medium">
                        <i-count-up
                          :startVal="0"
                          :endVal="verification_by_cities.total.not_verified"
                          :decimals="0"
                          :duration="1.5"
                          :options="options"
                          :callback="noop"
                        ></i-count-up>
                      </h3>
                      <h6 class="text-muted">Not Verified</h6>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-12 mb-1 p-0 text-left">
                    <div class="b-all rounded dashboard-metric-card p-2">
                      <h3 class="m-b-0 font-medium">
                        <i-count-up
                          :startVal="0"
                          :endVal="verification_by_cities.total.pending"
                          :decimals="0"
                          :duration="1.5"
                          :options="options"
                          :callback="noop"
                        ></i-count-up>
                      </h3>
                      <h6 class="text-muted">Pending</h6>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-12 mb-3 text-left">
                  <div class="b-all rounded dashboard-metric-card p-2">
                    <h3 class="m-b-0 font-medium">
                      <i-count-up
                        :startVal="0"
                        :endVal="verification_by_cities.total.invited"
                        :decimals="0"
                        :duration="1.5"
                        :options="options"
                        :callback="noop"
                      ></i-count-up>
                    </h3>
                    <h6 class="text-muted">Invited</h6>
                  </div>
                </div>
                <div class="col-sm-6 col-md-12 mb-3 text-left">
                  <div class="b-all rounded dashboard-metric-card p-2">
                    <h3 class="m-b-0 font-medium">
                      <i-count-up
                        :startVal="0"
                        :endVal="verification_by_cities.total.incomplete"
                        :decimals="0"
                        :duration="1.5"
                        :options="options"
                        :callback="noop"
                      ></i-count-up>
                    </h3>
                    <h6 class="text-muted">Incomplete</h6>
                  </div>
                </div>

                <div class="col-sm-6 col-md-12 mb-3 text-left">
                  <div class="b-all rounded dashboard-metric-card p-2">
                    <h3 class="m-b-0 font-medium">
                      <i-count-up
                        :startVal="0"
                        :endVal="verification_by_cities.total.total_request"
                        :decimals="0"
                        :duration="1.5"
                        :options="options"
                        :callback="noop"
                      ></i-count-up>
                    </h3>
                    <h6 class="text-muted">Total</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="row">
        <!-- Column -->
        <div
          class="col-md-4 col-lg-4 col-xlg-4"
          v-tooltip="'Time taken to complete the application forms'"
        >
          <div class="card card-height">
            <div class="card-block flex-column">
              <div class="flex-grow justify-content-center align-items-center">
                <table class="table table-sm">
                  <tbody>
                    <tr
                      v-for="(key, value, index) in dashboard_metrics
                        .average_form_completion_time.datetime"
                      :key="index"
                    >
                      <th scope="row" class="text-black text-end pr-2">
                        {{ key }}
                      </th>
                      <td class="pl-2">
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6 class="text-muted text-uppercase">
                Average Form Completion Time
              </h6>
            </div>
          </div>
        </div>
        <!-- Column -->
        <div
          class="col-md-4 col-lg-4 col-xlg-4"
          v-tooltip="'Time taken to complete all application processes'"
        >
          <div class="card card-height">
            <div class="card-block flex-column">
              <div class="flex-grow justify-content-center align-items-center">
                <table class="table table-sm">
                  <tbody>
                    <tr
                      v-for="(key, value, index) in dashboard_metrics
                        .average_verification_time.datetime"
                      :key="index"
                    >
                      <th scope="row" class="text-black text-end pr-2">
                        {{ key }}
                      </th>
                      <td class="pl-2">
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h6 class="text-muted text-uppercase">
                Average Verification Time
              </h6>
            </div>
          </div>
        </div>
        <!-- Column -->
        <div
          class="col-md-4 col-lg-4 col-xlg-4"
          v-tooltip="
            'Rate of verified applications to total completed applications'
          "
        >
          <div class="card card-height">
            <div class="card-block flex-column">
              <div
                class="
                  d-flex
                  flex-grow flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <h3 class="font-bold mb-3" :style="{ fontSize: '1.75rem' }">
                  {{ dashboard_metrics.verification_success_rate.rate }}
                </h3>
                <h5 class="font-muted font-normal">
                  {{ dashboard_metrics.verification_success_rate.total_verified
                  }}<span class="mx-1"><small>Verified</small></span
                  >&nbsp;/&nbsp;{{
                    dashboard_metrics.verification_success_rate.total_completed
                  }}<span class="mx-1"><small>Total</small></span>
                </h5>
              </div>
              <h6 class="text-muted text-uppercase">
                Application Success Rate
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { mapGetters } from "vuex";
import moment from "moment";
import ICountUp from "vue-countup-v2";
import * as types from "@/store/types";
export default {
  data() {
    return {
      fromDate: null,
      toDate: null,
      fromDateConfig: {},
      toDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      periodLabel: null,
      showCustomPeriodDatePicker: false,
      isChartLoading: false,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      }
    };
  },
  watch: {
    fromDate() {
      this.toDateConfig.minDate = moment(this.fromDate)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  components: {
    ICountUp
  },
  mounted() {
    this.fromDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
    this.periodLabel = "All Time";
    // build chart
    this.buildAnalyticChart();
  },
  methods: {
    setPeriod(period) {
      switch (period) {
        case "today":
          this.periodLabel = "Today";
          this.fromDate = moment().format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "this_week":
          this.periodLabel = "This Week";
          this.fromDate = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("week")
            .format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "this_month":
          this.periodLabel = "This Month";
          this.fromDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("month")
            .format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "last_30_days":
          this.periodLabel = "Last 30 days";
          this.fromDate = moment()
            .subtract(29, "days")
            .format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "last_90_days":
          this.periodLabel = "Last 90 days";
          this.fromDate = moment()
            .subtract(89, "days")
            .format("YYYY-MM-DD");
          this.toDate = moment().format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "this_year":
          this.periodLabel = "This Year";
          this.fromDate = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
          this.toDate = moment()
            .endOf("year")
            .format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "all_time":
          this.periodLabel = "All Time";
          this.fromDate = moment(this.business_profile.created_at).format(
            "YYYY-MM-DD"
          );
          this.toDate = moment().format("YYYY-MM-DD");
          this.showCustomPeriodDatePicker = false;
          break;
        case "custom_period":
          this.periodLabel = "Custom Period";
          this.showCustomPeriodDatePicker = true;
          break;
      }
    },
    buildAnalyticChart() {
      const trending = {
        city_labels: this.city_labels,
        verified: this.city_labels.map(city => {
          return this.verification_by_cities.trending[city].verified;
        }),
        not_verified: this.city_labels.map(city => {
          return this.verification_by_cities.trending[city].not_verified;
        }),
        pending: this.city_labels.map(city => {
          return this.verification_by_cities.trending[city].pending;
        }),
        invited: this.city_labels.map(city => {
          return this.verification_by_cities.trending[city].invited;
        }),
        incomplete: this.city_labels.map(city => {
          return this.verification_by_cities.trending[city].incomplete;
        })
      };
      // destroy chart if it has been created
      if (this.myChart) {
        this.myChart.destroy();
      }
      var ctx = document
        .getElementById("verification-city-metrics")
        .getContext("2d");
      let config = {
        type: "bar",
        data: {
          labels: trending.city_labels,
          datasets: [
            {
              label: "Verified",
              data: trending.verified,
              backgroundColor: "rgba(64, 223, 109, 0.7)",
              borderColor: ["rgba(64, 223, 109, 0.7)"],
              borderWidth: 1,
              pointBackgroundColor: "rgba(64, 223, 109, 0.7)",
              pointBorderColor: "rgba(64, 223, 109, 0.7)",
              pointHoverBorderWidth: 4
            },
            {
              label: "Not Verified",
              data: trending.not_verified,
              backgroundColor: "rgba(255, 99, 132, 0.7)",
              borderColor: ["rgba(255,99,132,0.7)"],
              borderWidth: 1,
              pointBackgroundColor: "rgba(255,99,132,0.7)",
              pointBorderColor: "rgba(255,99,132,0.7)",
              pointHoverBorderWidth: 4
            },
            {
              label: "Pending",
              data: trending.pending,
              backgroundColor: "rgba(54, 162, 235, 0.7)",
              borderColor: ["rgba(54, 162, 235, 0.7)"],
              borderWidth: 1,
              pointBackgroundColor: "rgba(54, 162, 235, 0.7)",
              pointBorderColor: "rgba(54, 162, 235, 0.7)",
              pointHoverBorderWidth: 4
            },
            {
              label: "Invited",
              data: trending.invited,
              backgroundColor: "rgba(140, 122, 230, 0.7)",
              borderColor: ["rgba(140, 122, 230, 0.7)"],
              borderWidth: 1,
              pointBackgroundColor: "rgba(140, 122, 230, 0.7)",
              pointBorderColor: "rgba(140, 122, 230, 0.7)",
              pointHoverBorderWidth: 4
            },
            {
              label: "Incomplete",
              data: trending.incomplete,
              backgroundColor: "rgba(229, 229, 229, 1)",
              borderColor: ["rgba(229, 229, 229, 1)"],
              borderWidth: 1,
              pointBackgroundColor: "rgba(229, 229, 229, 1)",
              pointBorderColor: "rgba(229, 229, 229, 1)",
              pointHoverBorderWidth: 4
            }
          ]
        },
        options: {
          legend: {
            display: true
          },
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                },
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "VERIFICATIONS"
                },
                stacked: true
              }
            ],
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "CITIES"
                },
                stacked: true
              }
            ]
          },
          tooltips: {
            mode: "index",
            intersect: false
          },
          hover: {
            mode: "nearest",
            intersect: false
          }
        }
      };
      this.myChart = new Chart(ctx, config);
    },
    noop() {},
    async reloadChart() {
      try {
        this.isChartLoading = true;
        await this.$store.dispatch(types.GET_VERIFICATIONS_BY_CITIES_ACTION, {
          start_date: this.fromDate,
          end_date: this.toDate
        });
        this.buildAnalyticChart();
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isChartLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      verification_by_cities: "getVerificationByCities",
      dashboard_metrics: "getDashboardMetrics",
      business_profile: "getBusinessProfile"
    }),
    city_labels() {
      console.log(this.verification_by_cities);
      return Object.keys(this.verification_by_cities.trending);
    }
  }
};
</script>

<style scoped>
.card-height {
  height: 214px;
}

.table {
  width: auto;
}

.table td,
.table th {
  font-size: 1.25rem;
  line-height: 1;
}

.table th {
  color: #000000;
}

.table td {
  border-top: none !important;
  border-left: 1px solid #636c72;
  font-weight: 500;
}

.card-block h6 {
  font-size: 0.85rem;
}
</style>
