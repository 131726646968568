<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Identity" />
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <div class="row">
      <div class="col-12">
        <div class="no-card">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs customtab" role="tablist">
            <li
              class="nav-item"
              v-if="$User.hasPermission('verify-identity-nin')"
            >
              <router-link
                :to="{ name: 'NinView', query: $route.query }"
                class="nav-link"
                active-class="active"
                role="tab"
              >
                <span class="hidden-sm-up">
                  NIN
                </span>
                <span class="hidden-xs-down">National Identity</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="$User.hasPermission('verify-identity-ibvn')"
            >
              <router-link
                :to="{ name: 'BvnView', query: $route.query }"
                active-class="active"
                class="nav-link"
                role="tab"
              >
                <span class="hidden-sm-up">
                  BVN
                </span>
                <span class="hidden-xs-down">Bank Verification</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="$User.hasPermission('verify-identity-frsc')"
            >
              <router-link
                :to="{ name: 'AgentLicenseView', query: $route.query }"
                active-class="active"
                class="nav-link"
                role="tab"
              >
                <span class="hidden-sm-up">
                  NDL
                </span>
                <span class="hidden-xs-down">Driver License</span>
              </router-link>
            </li>
          </ul>
          <!-- Tab panes -->
          <!-- /content -->
          <!-- ============================================================== -->
          <!-- Router view -->
          <!-- ============================================================== -->
          <div class="tab-content">
            <div class="tab-pane active" role="tabpanel">
              <div class="m-t-15">
                <keep-alive>
                  <router-view
                    v-if="
                      $User.hasPermission('verify-identity-nin') ||
                        $User.hasPermission('verify-identity-ibvn') ||
                        $User.hasPermission('verify-identity-frsc')
                    "
                  ></router-view>
                </keep-alive>
                <identity-report-component
                  v-if="showIdentityReportComponent"
                ></identity-report-component>
                <identity-verification-history
                  v-if="$User.hasPermission('identity-view')"
                ></identity-verification-history>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import IdentityVerificationHistory from "./_components/IdentityVerificationHistory";
import IdentityReportComponent from "./_components/IdentityReportComponent";
import notify from "@/helpers/notifyMe";
import store from "@/store";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";
import moment from "moment";

export default {
  name: "IdentityContainer",
  components: {
    AppTitle,
    IdentityVerificationHistory,
    IdentityReportComponent
  },
  mounted() {
    eventBus.$on("lslb:identity:show-identity-report-component", () => {
      this.showIdentityReportComponent = true;
    });
  },
  data() {
    return {
      showIdentityReportComponent: false
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        type = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_IDENTITY_LOG_ACTION, {
          query: search_term,
          paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_IDENTITY_LOG_ACTION, {
          page,
          status,
          type,
          start_date,
          end_date
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //skip network fetch if changing routes on route update
    if (to.name !== from.name) {
      return next();
    }

    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        type = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_IDENTITY_LOG_ACTION, {
          query: search_term,
          paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_IDENTITY_LOG_ACTION, {
          page,
          status,
          type,
          start_date,
          end_date
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
