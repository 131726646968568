<template>
  <div class="container w-100 position-relative" style="z-index: 2;">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <!-- <app-title title="Report Log" /> -->
    <h1 style="color: rgb(3, 27, 77);" class="mt-5 text-center">
      Agent Profile
    </h1>

    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <div class="row m-t-15 mb-5" style="margin-left: 10rem">
      <div class="col-md-12">
        <div id="report-box" class="no-card bordered-box">
          <div class="d-flex align-items-center" style="gap: 0.75rem">
            <router-link
              v-if="
                $route.name === 'AgentDashboard' &&
                  Object.keys($route.query).length > 0
              "
              :to="{
                name: 'AgentDashboard',
                params: { profile_id: agent_profile.id }
              }"
              class="btn btn-link text-dark"
            >
              &larr; Back
            </router-link>
            <div
              class="d-flex justify-content-end align-items-center"
              style="flex: 1"
            >
              <div
                class="dropdown mr-4 position-relative"
                style="cursor: pointer"
              >
                <span
                  id="notificationBell"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  class="mdi mdi-24px mdi-bell-outline"
                ></span>
                <div
                  v-if="unreadNotifications.length > 0"
                  style="right: -8px"
                  class="badge badge-pill badge-danger position-absolute"
                >
                  {{ unreadNotifications.length }}
                </div>
                <div
                  class="dropdown-menu pull-left p-3"
                  aria-labelledby="notificationBell"
                >
                  <div
                    v-if="agent_profile.notifications.length === 0"
                    class="text-center"
                  >
                    <p>No notifications</p>
                  </div>
                  <template v-else>
                    <div
                      v-for="(notification, i) in agent_profile.notifications"
                      :key="`notification-${i}`"
                      class="mb-3"
                    >
                      <small class="text-gray-dark" style="line-height: 0.5">{{
                        notification.created_at | date
                      }}</small>
                      <div>{{ notification.message }}</div>
                      <button
                        @click="markNotificationAsRead(notification._id)"
                        :disabled="loading"
                        v-if="!notification.read"
                        class="btn btn-sm btn-link pl-0"
                      >
                        Mark as read
                      </button>
                    </div>
                  </template>
                </div>
              </div>

              <div>
                <router-link
                  :to="{
                    name: 'AgentProfileLogin'
                  }"
                  class="btn btn-sm btn-outline-danger font-bold"
                >
                  <i class="mdi mdi-logout mr-1"></i> Log out
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul
      class="nav nav-tabs customtab scrollable bg-light mb-3 d-md-none rounded"
      role="tablist"
    >
      <li class="nav-item" v-for="link in navLinks" :key="link.title">
        <router-link
          active-class="active"
          :to="{ name: link.linkName }"
          class="nav-link"
          role="tab"
        >
          <span class="mr-2" :class="link.linkIcon"></span>
          {{ link.title }}
        </router-link>
      </li>
    </ul>
    <div class="vtabs customvtab mx-auto w-100">
      <ul
        class="nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded"
        role="tablist"
      >
        <li class="nav-item" v-for="link in navLinks" :key="link.title">
          <router-link
            class="nav-link d-flex"
            active-class="active"
            :to="{ name: link.linkName }"
            role="tab"
            aria-expanded="false"
          >
            <span class="mr-2" :class="link.linkIcon"></span>
            {{ link.title }}
          </router-link>
        </li>
      </ul>

      <!-- Tab panes -->
      <div class="tab-content w-100 p-t-0">
        <div class="tab-pane active w-100" role="tabpanel" aria-expanded="true">
          <div class="card bordered-box position-relative w-100">
            <div class="card-block w-100">
              <h2 class="mb-3" v-if="$route.name !== 'AgentCompanies'">
                {{
                  $route.name === "AgentApplications"
                    ? "Applications"
                    : $route.name === "AgentCompanies"
                    ? null
                    : $route.name === "AgentPayments"
                    ? "Payment History"
                    : $route.name === "AgentDashboard"
                    ? $route.query.renew_permit
                      ? "Current Renewal (1 Year)"
                      : $route.query.permit_renewal
                      ? "Permit Renewal"
                      : "Dashboard"
                    : $route.name === "AgentComments"
                    ? "Comments"
                    : null
                }}
              </h2>
              <router-view v-bind="sub_data"></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import { myApp } from "@/main";

export default {
  name: "CheckAgentProfile",
  data() {
    return {
      navLinks: [
        {
          title: "Dashboard",
          linkName: "AgentDashboard",
          linkIcon: "mdi mdi-view-dashboard-outline"
        },
        {
          title: "Operators",
          linkName: "AgentCompanies",
          linkIcon: "mdi mdi-domain"
        },
        {
          title: "Applications",
          linkName: "AgentApplications",
          linkIcon: "mdi mdi-view-list"
        },
        // {
        //   title: "Comments",
        //   linkName: "AgentComments",
        //   linkIcon: "mdi mdi-comment-text-multiple-outline"
        // },
        {
          title: "Payment History",
          linkName: "AgentPayments",
          linkIcon: "mdi mdi-history"
        },
        {
          title: "Privacy Settings",
          linkName: "AgentSettings",
          linkIcon: "mdi mdi-account-cog"
        }
      ],
      notifications: [
        {
          _id: "5fdbf15fa78d88da542986e6",
          read: false,
          message: "Your agent application was successful",
          recipient: "5fd773388bd8f341f0d62f38",
          recipient_type: "Agent",
          type: "permit",
          created_at: "2020-12-18T00:01:35.531Z",
          updated_at: "2020-12-18T00:01:35.531Z",
          __v: 0
        },
        {
          _id: "5fdbf172a4af10dad3ca3c78",
          read: false,
          message: "Your agent application was successful",
          recipient: "5fd773388bd8f341f0d62f38",
          recipient_type: "Agent",
          type: "permit",
          created_at: "2020-12-18T00:01:54.202Z",
          updated_at: "2020-12-18T00:01:54.202Z",
          __v: 0
        }
      ],
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      agent_profile: "getCheckAgentProfile",
      verifications: "getCheckAgentVerifications",
      verification: "getCheckAgentVerification",
      transactions: "getCheckAgentVerifications"
    }),
    sub_data() {
      switch (this.$route.name) {
        case "AgentApplications":
          return {
            verifications: this.verifications,
            verification: this.verification,
            type: "AgentProfileReportView",
            profile_id: this.agent_profile.id
          };
        case "AgentCompanies":
          return {
            companies: this.agent_profile.agent_businesses,
            dissociation_requests: this.agent_profile.dissociation_requests,
            type: "AgentProfileReportView",
            profile_id: this.agent_profile.id
          };
        case "AgentPayments":
          return {
            transactions: this.transactions,
            type: "AgentProfileReportView",
            profile_id: this.agent_profile.id
          };
        case "AgentDashboard":
          return {
            agent_profile: this.agent_profile,
            card: "no",
            layout: "horizontal",
            showEditProfile: true
          };
        case "AgentComments":
          return {
            agent: this.agent_profile
          };
        case "AgentProfileReportView":
          return null;
        default:
          return { verifications: this.verifications };
      }
    },
    unreadNotifications() {
      return this.agent_profile.notifications.filter(
        notification => !notification.read
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    const agentProfile = myApp && myApp.$store.getters.getCheckAgentProfile;
    console.log(agentProfile);
    if (agentProfile) {
      let location;
      if (agentProfile.status === "invited") {
        location = `http://${window.location.host}/request-verification?c=${agentProfile.invite_code}`;
      }
      if (agentProfile.status === "incomplete") {
        location = agentProfile.verification.application_link;
      }
      {
        if (location) {
          window.location.replace(location);
          return next(false);
        }
      }
      return next();
    } else {
      return next({ name: "AgentProfileLogin" });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(types.SET_AGENT_PROFILE_AGENT, null);
    return next();
  },
  methods: {
    async markNotificationAsRead(notification_id) {
      this.loading = true;
      try {
        await this.$store.dispatch(types.MARK_NOTIFICATION_AS_READ, {
          notification_id,
          profile_id: this.agent_profile.id
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
