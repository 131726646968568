<template>
  <div class="card bordered-box">
    <div class="card-block">
      <h3 class="card-title">Business Information</h3>
      <form @submit.prevent="updateBusinessProfile">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="business-name" class="font-bold form-label"
                >Business Name</label
              >
              <input
                type="text"
                readonly
                v-model.trim="business_name"
                :class="{
                  'form-error animated shake': $v.business_name.$error
                }"
                @input="$v.business_name.$touch"
                class="form-control rounded-0"
                id="business-name"
                placeholder="Enter Business Name"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="business-address" class="font-bold form-label">
                Business Address</label
              >
              <span class="text-danger">*</span>
              <input
                type="text"
                v-model.trim="address"
                :class="{ 'form-error animated shake': $v.address.$error }"
                @input="$v.address.$touch"
                class="form-control rounded-0"
                id="business-address"
                placeholder="Enter Business Address"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="support-phone" class="font-bold form-label">
                Support Phone Number
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="phone"
                :class="{ 'form-error animated shake': $v.phone.$error }"
                @input="$v.phone.$touch"
                class="form-control rounded-0"
                id="support-phone"
                placeholder="Enter Support Phone Number"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="support-email" class="font-bold form-label">
                Support Email Address
                <span class="text-danger">*</span>
                <span
                  class="mdi mdi-help-circle-o ml-1"
                  v-tooltip="
                    'Support requested by your customers from contact us will go to this email'
                  "
                ></span>
              </label>
              <input
                type="email"
                v-model.trim="support_email"
                :class="{
                  'form-error animated shake': $v.support_email.$error
                }"
                @input="$v.support_email.$touch"
                class="form-control rounded-0"
                id="support-email"
                placeholder="Enter Support Email Address"
              />
            </div>
          </div>
          <div class="order-first col-md-6 push-md-6">
            <div class="form-group m-b-10">
              <label for="business-website" class="font-bold form-label">
                Business Website
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="website_url"
                :class="{ 'form-error animated shake': $v.website_url.$error }"
                @input="$v.website_url.$touch"
                class="form-control rounded-0"
                id="business-website"
                placeholder="Enter Business Website Address"
              />
            </div>
          </div>
          <div class="col-md-6 pull-md-6">
            <div class="form-group m-b-10">
              <label for="primary-contact-name" class="font-bold form-label">
                Primary Contact Name
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="primary_contact_name"
                :class="{
                  'form-error animated shake': $v.primary_contact_name.$error
                }"
                @input="$v.primary_contact_name.$touch"
                class="form-control rounded-0"
                id="primary-contact-name"
                placeholder="Enter Primary Contact Name"
              />
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="primary-contact-number" class="font-bold form-label">
                Primary Contact Phone Number
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="primary_contact_phone"
                :class="{
                  'form-error animated shake': $v.primary_contact_phone.$error
                }"
                @input="$v.primary_contact_phone.$touch"
                class="form-control rounded-0"
                id="primary-contact-number"
                placeholder="Enter Primary Contact Phone Number"
              />
            </div>
          </div>
          <div class="w-100"></div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label for="primary-contact-email" class="font-bold form-label">
                Primary Contact Email Address
                <span class="text-danger">*</span>
              </label>
              <input
                type="email"
                v-model.trim="primary_contact_email"
                :class="{
                  'form-error animated shake': $v.primary_contact_email.$error
                }"
                @input="$v.primary_contact_email.$touch"
                class="form-control rounded-0"
                id="primary-contact-email"
                placeholder="Enter Primary Contact Email Address"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group m-b-0">
              <button
                :disabled="$v.$invalid || isLoading || !$v.$anyDirty"
                type="submit"
                class="btn btn-warning w-100 p-t-10 p-b-10 rounded-0 waves-effect m-t-10"
              >
                Save Changes
                <span v-if="isLoading">
                  <i class="mdi mdi-loading mdi-spin"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  numeric,
  email,
  url
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
export default {
  data() {
    return {
      business_name: "",
      address: "",
      website_url: "",
      support_email: "",
      phone: "",
      primary_contact_name: "",
      primary_contact_phone: "",
      primary_contact_email: "",
      isLoading: false
    };
  },
  mounted() {
    this.business_name = this.business_profile.business_name;
    this.address = this.business_profile.address;
    this.website_url = this.business_profile.website_url;
    this.support_email = this.business_profile.support_email;
    this.phone = this.business_profile.phone;
    this.primary_contact_name = this.business_profile.primary_contact_name;
    this.primary_contact_email = this.business_profile.primary_contact_email;
    this.primary_contact_phone = this.business_profile.primary_contact_phone;
  },
  validations: {
    business_name: {
      required
    },
    address: {
      required
    },
    website_url: {
      required,
      url
    },
    support_email: {
      required,
      email
    },
    phone: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    primary_contact_name: { required },
    primary_contact_phone: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    primary_contact_email: {
      required,
      email
    }
  },
  computed: {
    ...mapGetters({
      current_user: "getCurrentUser",
      business_profile: "getBusinessProfile"
    })
  },
  methods: {
    async updateBusinessProfile() {
      try {
        let {
          address,
          website_url,
          support_email,
          phone,
          primary_contact_name,
          primary_contact_phone,
          primary_contact_email
        } = { ...this.$data };
        let payload = {
          address,
          website_url,
          support_email,
          phone,
          primary_contact_name,
          primary_contact_phone,
          primary_contact_email
        };
        await this.$store.dispatch(
          types.UPDATE_BUSINESS_PROFILE_ACTION,
          payload
        );
        this.$notify.success({
          message: "Business profile updated successfully"
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    }
  }
};
</script>
