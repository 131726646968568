<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Company’s Data" />

    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <!-- <div class=""> -->
    <!-- <div class="col-lg-4 hborder">
        <div style="height: 24rem"></div>
      </div> -->
    <div id="report-box" class="no-card bordered-box bg-white p-4">
      <div class="d-flex align-items-center justify-content-between">
        <router-link
          :to="{ name: 'CompaniesView' }"
          class="btn btn-link rounded-0 p-10 text-warning"
        >
          <i class="mdi mdi-arrow-left"></i>
          Go Back
        </router-link>
        <button
          v-if="!editing"
          type="button"
          class="btn btn-warning rounded-0 waves-effect p-10 p-10"
          @click="editing = true"
        >
          Edit Details
        </button>
      </div>
      <form
        id="add-single-user-form"
        class="row m-t-15"
        @submit.prevent="handleEditCompany"
      >
        <div class="col-lg-6">
          <div
            class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
            v-if="message"
          >
            {{ message }}
          </div>

          <!-- <div class="row"> -->
          <!-- <div class="col-md-6">
                <div class="form-group my-3">
                  <label for="trade_name" class="control-label form-label">
                    Trading Name
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model.trim="trade_name"
                    :class="{
                      'form-error animated shake': $v.trade_name.$error
                    }"
                    @input="$v.trade_name.$touch"
                    class="form-control rounded-0"
                    required="required"
                    :disabled="!editing"
                    placeholder="Enter Trading Name"
                  />
                </div>
              </div> -->

          <!-- <div class="col-md-6">
                <div class="form-group my-3">
                  <label for="business_name" class="control-label form-label">
                    Business Name
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model.trim="business_name"
                    :class="{
                      'form-error animated shake': $v.business_name.$error
                    }"
                    @input="$v.business_name.$touch"
                    class="form-control rounded-0"
                    required="required"
                    :disabled="!editing"
                    placeholder="Enter Business Name"
                  />
                </div>
              </div> -->

          <!-- <div class="col-md-6"> -->
          <div class="form-group my-4">
            <label for="email" class="control-label form-label">
              Email Addreess
              <span class="text-danger">*</span>
            </label>
            <input
              type="text"
              v-model.trim="email"
              :class="{
                'form-error animated shake': $v.email.$error
              }"
              @input="$v.email.$touch"
              :disabled="!editing"
              class="form-control rounded-0"
              required="required"
              placeholder="Enter Email Address"
            />
          </div>
          <!-- </div> -->

          <!-- <div class="col-md-6"> -->
          <div class="form-group my-4">
            <label for="phone" class="control-label form-label">
              Mobile Number
              <span class="text-danger">*</span>
            </label>
            <input
              class="form-control rounded-0"
              type="text"
              :class="{
                'form-error animated shake': $v.phone.$error
              }"
              :disabled="!editing"
              v-model.trim="phone"
              @input="$v.phone.$touch"
              required="required"
              placeholder="Enter Phone Number"
            />
          </div>
          <!-- </div> -->

          <div class="d-flex justify-content-end my-4">
            <button
              v-if="editing"
              type="submit"
              :disabled="isLoading || $v.$invalid"
              class="btn btn-warning rounded-0 waves-effect p-10 p-10"
            >
              Save Changes
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
          <!-- <div class="col-md-6">
                <div class="form-group my-3">
                  <label for="first_name" class="control-label form-label">
                    Admin's First Name
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model.trim="first_name"
                    :class="{
                      'form-error animated shake': $v.first_name.$error
                    }"
                    @input="$v.first_name.$touch"
                    class="form-control rounded-0"
                    required="required"
                    :disabled="!editing"
                    placeholder="Enter Admin First Name"
                  />
                </div>
              </div> -->

          <!-- <div class="col-md-6">
                <div class="form-group my-3">
                  <label for="last_name" class="control-label form-label">
                    Admin's Last Name
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    v-model.trim="last_name"
                    :class="{
                      'form-error animated shake': $v.last_name.$error
                    }"
                    @input="$v.last_name.$touch"
                    :disabled="!editing"
                    required="required"
                    class="form-control rounded-0"
                    placeholder="Enter Admin Last Name"
                  />
                </div>
              </div> -->

          <!-- <div class="col-md-6 my-3">
                <div class="form-group">
                  <label for="companyAddress" class="font-bold form-label">
                    Company Address</label
                  >
                  <span class="text-danger">*</span>
                  <vue-google-autocomplete
                    id="companyAddress"
                    classname="form-control address-input-field z-9999"
                    required="required"
                    placeholder="Enter Company Address"
                    :disabled="!editing"
                    v-on:placechanged="getCompanyAddressData"
                  >
                  </vue-google-autocomplete>
                </div>
              </div> -->
          <!-- </div> -->
        </div>
      </form>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import {
  email,
  required,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import AppTitle from "@/components/layouts/AppTitle";
import * as types from "@/store/types";

export default {
  components: { AppTitle },
  data() {
    return {
      email: this.$route.query.email || "",
      phone: this.$route.query.phone || "",
      editing: false,
      isLoading: false,
      message: ""
    };
  },
  validations: {
    email: { required, email },
    phone: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    }
  },
  computed: {
    ...mapGetters({ business: "getBusiness" })
  },
  methods: {
    async handleEditCompany() {
      //check if form is valid
      if (this.$v.$invalid || this.isLoading) {
        return;
      }

      this.isLoading = true;
      const payload = {
        primary_contact_email: this.email,
        primary_contact_phone: this.phone
      };

      try {
        await this.$store.dispatch(
          types.UPDATE_BUSINESS_PROFILE_ACTION,
          payload,
          { root: true }
        );
        this.$notify.success({
          message: "Business profile updated successfully"
        });
        this.editing = false;
        this.isLoading = false;
        this.$router.push(`/ecosystems/companies`);
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    getCompanyAddressData(addressData) {
      if (addressData.street_number) {
        this.company_address = {
          address: `${addressData.street_number} ${addressData.route} ${addressData.locality} ${addressData.country}`
        };
      } else {
        this.company_address = {
          address: `${addressData.route}, ${addressData.locality}, ${addressData.country}`
        };
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 991px) {
  .hborder {
    border-bottom: 1px solid #c4c4c480;
  }
}

@media only screen and (min-width: 992px) {
  .vborder {
    border-left: 1px solid #c4c4c480;
  }
}
</style>
