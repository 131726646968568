<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="card bordered-box">
          <div class="card-block">
            <h3 class="card-title">Payment Summary</h3>
            <form class="row m-t-10">
              <div class="col-md-12 col-sm-12 mb-3">
                <div v-if="!verification.paid" class="form-group m-b-10">
                  <div
                    class="d-inline-block p-2 mb-2"
                    style="border:1px solid rgba(120, 130, 140, 0.13); cursor: pointer"
                    @click.prevent="have_coupon = !have_coupon"
                    v-tooltip="'Click here if you have a coupon'"
                  >
                    <div class="checkbox checkbox-warning w-100">
                      <input
                        :disabled="
                          is_coupon_valid && is_coupon_valid.status === true
                        "
                        id="checkbox-signup"
                        type="checkbox"
                        v-model.trim="have_coupon"
                      />
                      <label
                        class="no-select form-bold form-label"
                        for="checkbox-signup"
                        >Have a coupon?</label
                      >
                    </div>
                  </div>
                  <template v-if="have_coupon">
                    <template
                      v-if="
                        !(is_coupon_valid && is_coupon_valid.status === true)
                      "
                    >
                      <div class="input-group">
                        <input
                          class="form-control rounded-0 coupon-field text-uppercase"
                          placeholder="Enter Coupon Code"
                          type="text"
                          v-model.trim="coupon_code"
                        />
                        <button
                          :disabled="$v.coupon_code.$invalid || isVerifying"
                          @click.prevent="verifyCoupon"
                          class="btn btn-warning input-group-addon rounded-0"
                          v-tooltip="'Apply coupon'"
                        >
                          <template v-if="isVerifying">
                            <i class="mdi mdi-loading mdi-spin"></i>
                          </template>
                          <template v-else>VERIFY</template>
                        </button>
                      </div>
                      <p
                        class="mt-1 text-danger font-12 font-bold text-capitalize"
                        v-if="
                          is_coupon_valid && is_coupon_valid.status === false
                        "
                      >
                        {{ is_coupon_valid.message }}
                      </p>
                    </template>
                    <template v-else>
                      <div class="clearfix">
                        <p class="font-16 font-bold float-left">
                          <label class="label label-success font-bold mr-1">
                            {{ coupon.discount_type === "amount" ? "₦" : ""
                            }}{{ coupon.value
                            }}{{
                              coupon.discount_type === "percent" ? "%" : ""
                            }}
                            off
                          </label>
                          {{ coupon.code }}
                        </p>
                        <button
                          @click.prevent="showRemoveCouponModal"
                          class="btn btn-sm btn-outline-danger float-right"
                          v-tooltip="'Remove coupon'"
                        >
                          <i class="mdi mdi-close"></i>
                        </button>
                      </div>
                    </template>
                  </template>
                </div>
                <div class="form-group m-b-10 clearfix display-6">
                  {{ verification.paid ? "Paid" : "Total" }}
                  <div class="float-right">
                    <span
                      class="mr-2 font-30"
                      style="color: #adadad; text-decoration: line-through;"
                      v-if="is_coupon_valid && is_coupon_valid.status === true"
                      >₦{{ parseInt(verificationPrice / 100) | amount }}</span
                    >
                    <span v-if="!successfulTransaction"
                      >₦{{ (parseInt(amount_in_kobo) / 100) | amount }}</span
                    >
                    <span v-else>
                      ₦{{
                        (parseInt(
                          transactions.length > 0
                            ? successfulTransaction.amount_in_kobo_approved
                            : amount_in_kobo
                        ) /
                          100)
                          | amount
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                v-tooltip="
                  !verification.personal_info.completed
                    ? 'Please complete the form before attempting to submit'
                    : ''
                "
                class="col-md-12 col-sm-12"
              >
                <button
                  class="btn btn-warning waves-effect rounded-0 w-100 mb-3"
                  :disabled="
                    isPaymentLoading ||
                      verification.status === 'verified' ||
                      verification.status === 'pending' ||
                      !verification.personal_info.completed
                  "
                  @click.prevent="showPaymentOptionModal"
                >
                  <template v-if="amount_in_kobo === 0"
                    >Confirm Payment</template
                  >
                  <template v-else>Click to Submit</template>
                  <span
                    :class="{
                      'mdi mdi-loading mdi-spin ml-1': isPaymentLoading,
                      'mdi mdi-arrow-right-thick ml-1': !isPaymentLoading
                    }"
                  ></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="card bordered-box">
          <div class="card-block">
            <h3 class="card-title">Payment History</h3>
            <table style="table-layout: fixed; width: 100%;">
              <tr>
                <td style="width: 100%">
                  <div style="width: 100%; overflow-x: auto;">
                    <template v-if="transactions.length > 0">
                      <table class="table table-hover">
                        <thead>
                          <tr class="link-row">
                            <th width="70" class="text-center">#</th>
                            <th>Status</th>
                            <th class="text-nowrap">Transaction ID</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th class="text-nowrap">Initiated By</th>
                            <th>Action</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="link-row"
                            v-for="(transaction, index) in transactions"
                            :key="transaction.id"
                          >
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>
                              <span
                                class="label font-bold text-capitalize rounded-0"
                                :class="[
                                  transaction.status === 'success'
                                    ? 'label-light-success'
                                    : transaction.status === 'failed'
                                    ? 'label-light-danger'
                                    : transaction.status === 'fraud'
                                    ? 'label-light-danger'
                                    : transaction.status === 'abandoned'
                                    ? 'label-light-warning'
                                    : transaction.status === 'initiated'
                                    ? 'label-light-megna'
                                    : 'label-light-primary'
                                ]"
                                >{{ transaction.status }}</span
                              >
                            </td>
                            <td>{{ transaction.id }}</td>
                            <td class="text-capitalize">
                              ₦{{
                                (parseInt(transaction.amount_in_kobo_approved) /
                                  100)
                                  | amount
                              }}
                            </td>
                            <td>{{ transaction.item_type | title_case }}</td>
                            <td class="text-capitalize">
                              <template
                                v-if="
                                  transaction.user.last_name &&
                                    transaction.user.last_name.value
                                "
                                >{{ transaction.user.last_name.value }}
                                {{ transaction.user.first_name }}</template
                              >
                              <template
                                v-else-if="
                                  transaction.user.last_name &&
                                    transaction.user.last_name.value != ''
                                "
                                >{{ transaction.user.last_name }}
                                {{ transaction.user.first_name }}</template
                              >
                              <template v-else>Not available</template>
                            </td>
                            <td>
                              <button
                                v-if="
                                  transaction.status === 'initiated' &&
                                    transaction.payment_mode !== 'bank_transfer'
                                "
                                class="btn btn-xs btn-warning"
                                :disabled="isRequerying"
                                @click="requeryTransaction(transaction.id)"
                              >
                                Re-query
                                <span
                                  v-if="isRequerying === transaction.id"
                                  class="mdi mdi-loading mdi-spin ml-1"
                                ></span>
                              </button>
                            </td>
                            <td class="text-capitalize">
                              {{ transaction.created_at | long_date }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="p-30 test font-medium text-muted">
                        <span
                          >Showing 1 to {{ transactions.length }} of
                          {{ transactions.length }} entries</span
                        >
                      </div>
                    </template>
                    <template v-else>
                      <div class="text-center">
                        <img
                          src="/static/empty.svg"
                          class="img-fluid mb-3"
                          alt="empty"
                          style="width: 50px;"
                        />
                        <p class="font-bold font-14">
                          There are no transactions to display
                        </p>
                      </div>
                    </template>
                  </div>
                </td>

                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <router-link
          tag="button"
          :to="{ name: 'RequestVerificationPreviewView' }"
          class="btn btn-outline-inverse waves-effect rounded-0 w-100"
        >
          <span class="mdi mdi-arrow-left-thick"></span>
          Back
        </router-link>
      </div>
      <remove-coupon-modal :removeCoupon="removeCoupon"></remove-coupon-modal>
    </div>

    <get-payment-option-modal
      :initiatePayment="initiatePayment"
      :have_coupon="have_coupon"
      :coupon_code="coupon_code"
      :verification="verification"
    ></get-payment-option-modal>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import { required } from "vuelidate/lib/validators";
import RemoveCouponModal from "./RemoveCouponModal.vue";
import GetPaymentOptionModal from "@/modules/request-verification/_components/GetPaymentOptionModal";

export default {
  components: {
    RemoveCouponModal,
    GetPaymentOptionModal
  },
  created() {
    let src = process.env.VUE_APP_PAYSTACK_SCRIPT_URL;
    const script = document.createElement("script");
    script.setAttribute("src", src);
    script.async = true;
    document.head.appendChild(script);

    this.$once("hook:beforeDestroy", () => {
      script.remove();
    });
  },
  mounted() {
    this.amount_in_kobo = this.verificationPrice;
  },
  async beforeRouteEnter(to, from, next) {
    let verification = store.getters.getRequestVerification;
    if (
      !verification.personal_info.completed ||
      !verification.identity_info.completed ||
      !verification.address_info.completed ||
      !verification.residential_address.completed ||
      !verification.guarantor_info.completed
    ) {
      notify.error({ message: "Please complete the form" });
      return next(false);
    }
    //get transaction log
    try {
      await Promise.all([
        store.dispatch(types.GET_REQUEST_VERIFICATION_TRANSACTIONS_ACTION, {
          verification_id: to.params.verification_id
        }),
        store.dispatch(types.GET_VERIFICATION_PRICE)
      ]);
      next();
    } catch (error) {
      error &&
        error.data &&
        notify.error({
          message: error.data.message
        });
      next(false);
    }
  },
  data() {
    return {
      have_coupon: false,
      coupon_code: "",
      coupon: "",
      amount_in_kobo: 0,
      isPaymentLoading: false,
      transaction_id: "",
      isVerifying: false,
      is_coupon_valid: false,
      isRequerying: null,
      payment: null
    };
  },
  validations: {
    coupon_code: {
      required
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification",
      transactions: "getRequestVerificationTransactions",
      successfulTransaction: "getSuccessfulTransaction",
      verificationPrice: "getVerificationPrice"
    })
  },
  methods: {
    async initiatePayment(payment, payment_mode) {
      this.isPaymentLoading = true;

      this.transaction_id = payment.id;
      this.amount_in_kobo = payment.amount_in_kobo_approved;
      this.payment = payment;

      //check if amount approved is 0
      if (payment.amount_in_kobo_approved === 0) {
        await this.verifyTransaction(payment.id);
        await this.refreshTransactionsLog(this.verification.id);
        await this.submitVerificationForm(this.verification.id);
        return;
      }

      if (payment_mode !== "online") return;

      try {
        await this.payWithPaytack({
          email: this.verification.personal_info.email,
          tx_ref: this.payment.txn_reference,
          amount_in_kobo_approved: this.payment.amount_in_kobo_approved,
          mobile: this.verification.personal_info.mobile,
          public_key: this.payment.paystack_public_key,
          firstName: this.verification.personal_info.first_name,
          middleName: this.verification.personal_info.middle_name,
          lastName: this.verification.personal_info.last_name
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isPaymentLoading = false;
      }
    },
    async payWithPaytack({
      email,
      tx_ref,
      amount_in_kobo_approved,
      mobile,
      public_key,
      firstName,
      middleName,
      lastName
    }) {
      console.log("Split code", process.env.VUE_APP_PAYSTACK_SPLIT_CODE);
      let vm = this;
      // eslint-disable-next-line
      let handler = PaystackPop.setup({
        key: public_key,
        email: email,
        amount: amount_in_kobo_approved,
        currency: "NGN",
        split_code: process.env.VUE_APP_PAYSTACK_SPLIT_CODE,
        ref: tx_ref,
        metadata: {
          custom_fields: [
            {
              display_name: "First Name",
              variable_name: "firstName",
              value: firstName
            },
            {
              display_name: "Middle Name",
              variable_name: "middleName",
              value: middleName
            },
            {
              display_name: "Last Name",
              variable_name: "lastName",
              value: lastName
            },
            {
              display_name: "Phone Number",
              variable_name: "mobile",
              value: mobile
            }
          ]
        },
        callback: async () => {
          try {
            vm.isPaymentLoading = false;
            await vm.verifyTransaction(vm.transaction_id);
            await vm.refreshTransactionsLog(vm.verification.id);
            await vm.submitVerificationForm(vm.verification.id);
          } catch (error) {
            error &&
              error.data &&
              vm.$notify.error({ message: error.data.message });
          } finally {
            vm.isPaymentLoading = false;
          }
        },
        onClose: () => {
          notify.warning({
            message: "Application payment cancelled"
          });
          vm.refreshTransactionsLog(vm.verification.id);
        }
      });
      handler.openIframe();
    },
    async verifyTransaction(transaction_id) {
      try {
        let transaction = await store.dispatch(
          types.VERIFY_VERIFICATION_PAYMENT_ACTION,
          transaction_id
        );
        if (transaction.status === "success") {
          let verification = JSON.parse(JSON.stringify(this.verification));
          verification.paid = true;
          notify.success({
            message: "Application payment successful"
          });
          this.commitVerificationToState(verification);
          return transaction;
        } else {
          notify.warning({
            message:
              "There was an issue with the application payment. Go to transactions to check status."
          });
          await this.refreshTransactionsLog(this.verification.id);
        }
        throw new Error("There was an issue with the application payment");
      } catch (error) {
        throw new Error(
          "There was an issue verifying the payment, please try again."
        );
      }
    },
    async submitVerificationForm(verification_id) {
      this.isPaymentLoading = true;
      try {
        await this.$store.dispatch(
          types.SUBMIT_REQUEST_VERIFICATION_FORM_ACTION,
          verification_id
        );
        return this.$router.push({ name: "FormSubmittedSuccessfullyView" });
      } catch (error) {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
      } finally {
        this.isPaymentLoading = false;
      }
    },

    async requeryTransaction(transaction_id) {
      try {
        this.isRequerying = transaction_id;
        await this.verifyTransaction(transaction_id);
        await this.refreshTransactionsLog(this.verification.id);
        await this.submitVerificationForm(this.verification.id);
      } catch (error) {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
        await this.refreshTransactionsLog(this.verification.id);
      } finally {
        this.isRequerying = null;
      }
    },
    commitVerificationToState(verification) {
      store.commit(types.SET_REQUEST_VERIFICATION, verification);
    },
    async refreshTransactionsLog(verification_id) {
      try {
        await store.dispatch(
          types.GET_REQUEST_VERIFICATION_TRANSACTIONS_ACTION,
          {
            verification_id
          }
        );
      } catch (error) {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
      }
    },
    async verifyCoupon() {
      try {
        this.is_coupon_valid = false;
        this.amount_in_kobo = this.verificationPrice;
        this.isVerifying = true;
        let response = await this.$store.dispatch(
          types.VERIFY_COUPON_CODE_ACTION,
          {
            coupon_code: this.coupon_code.toUpperCase(),
            verification: this.verification
          }
        );
        this.coupon = response;
        this.amount_in_kobo = response.amount_in_kobo_approved;
        this.is_coupon_valid = {
          status: true
        };
        this.$notify.success({ message: "Coupon applied successfully" });
      } catch (error) {
        if (error && error.data) {
          this.$notify.error({ message: error.data.message });
          this.is_coupon_valid = {
            status: false,
            message: error.data.message
          };
        }
      } finally {
        this.isVerifying = false;
      }
    },
    removeCoupon() {
      this.coupon = "";
      this.coupon_code = "";
      this.have_coupon = false;
      this.isVerifying = false;
      this.is_coupon_valid = false;
      this.amount_in_kobo = this.verificationPrice;
      this.$v.$reset();
      $("#remove-coupon-modal").modal("hide");
    },
    showRemoveCouponModal() {
      $("#remove-coupon-modal")
        .appendTo("body")
        .modal("show");
    },
    async showPaymentOptionModal() {
      if (
        !(
          this.isPaymentLoading ||
          this.verification.status === "verified" ||
          this.verification.status === "pending"
        ) &&
        this.verification.paid
      ) {
        return await this.submitVerificationForm(this.verification.id);
      }

      let payment = await this.$store.dispatch(
        types.INITIATE_VERIFICATION_PAYMENT_ACTION,
        {
          payment_mode: "online",
          coupon_code: this.have_coupon ? this.coupon_code : "",
          item_id: this.verification.id
        }
      );

      this.payment = payment;

      await this.initiatePayment(payment, "online");
      // return $("#get-payment-option-modal")
      //   .appendTo("body")
      //   .modal("show");
    }
  },
  beforeDestroy() {
    $("#remove-coupon-modal").modal("hide");
    $("#remove-coupon-modal").on("hidden.bs.modal", function() {
      $("#remove-coupon-modal").modal("dispose");
    });

    $("#get-payment-option-modal").modal("hide");
    $("#get-payment-option-modal").on("hidden.bs.modal", function() {
      $("#get-payment-option-modal").modal("dispose");
    });
  }
};
</script>

<style scoped>
.checkbox label::before {
  border: 1px solid #54667a;
}
</style>
