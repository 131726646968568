<template>
  <div class="row">
    <!-- <div class="col-sm-12 col-md-8 offset-md-2"> -->
    <div class="col-sm-12">
      <div class="no-card mb-0">
        <div class="card-block">
          <div class="py-5 px-2 flex-box d-flex w-100">
            <div class="text-center align-self-center m-auto">
              <h1>Form Submission Successful</h1>
              <i
                class="text-success mdi mdi-check-circle-outline ml-1 display-1"
              ></i>
              <p class="font-18">
                {{
                  `A report will be sent to your email address ${
                    verification &&
                    verification.personal_info &&
                    verification.personal_info.email
                      ? "(" + verification.personal_info.email + ")"
                      : ""
                  } as soon as it is ready.`
                }}
              </p>
              <p class="display-6">Thank you.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FormSubmittedSuccessfullyView",
  beforeRouteEnter(to, from, next) {
    if (
      ["VerificationPaymentView", "GuarantorInformationView"].includes(
        from.name
      )
    ) {
      return next();
    }
    return next({ name: "RequestVerificationView" });
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification"
    })
  }
};
</script>
