import * as types from "@/store/types";

const mutations = {
  async [types.SET_ALL_ROLES](state, payload) {
    state.all_roles = payload;
  },
  async [types.SET_SELECTED_ROLE](state, payload) {
    state.selected_role = payload;
  }
};

export default mutations;
