<template>
  <div class="row m-t-15">
    <div class="col-sm-12">
      <div id="report-box" class="no-card bordered-box">
        <div id="report-block">
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <h3 class="card-title mr-3">
              Report for "{{
                verification.lslb_license_number ||
                  verification.personal_info.email
              }}"
            </h3>
            <router-link
              v-if="$route.name === 'AgentProfileReportView'"
              :to="{
                name: 'NewVerificationContainer',
                params: { verification_id: verification.id }
              }"
              class="btn btn-outline-warning btn-sm"
              >Go to application form <i class="ml-1 mdi mdi-arrow-right"></i
            ></router-link>
          </div>
          <ul role="tablist" class="nav nav-tabs customtab">
            <li class="nav-item" v-if="profile.user_type !== 'company'">
              <a
                href="#"
                class="nav-link"
                :class="showReportProgress ? '' : 'active'"
                @click.prevent="showReportProgress = false"
                role="tab"
                ><span class="hidden-sm-up"
                  ><i class="mdi-card-account-details"></i></span
                ><span class="hidden-xs-down">Application Report</span></a
              >
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="showReportProgress ? 'active' : ''"
                @click.prevent="showReportProgress = true"
                role="tab"
                ><span class="hidden-sm-up"
                  ><i class="mdi mdi-account-group"></i></span
                ><span class="hidden-xs-down"
                  >Application Report Progress</span
                ></a
              >
            </li>
          </ul>
          <div class="row m-t-15">
            <template v-if="!showReportProgress">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xlg-3">
                <div class="card bordered-box position-relative w-100">
                  <div class="card-block">
                    <ImagePercentage
                      class="mx-auto"
                      :photoMeta="verification.personal_info.photo_meta"
                    />
                    <div class="text-center">
                      <button
                        class="btn btn-sm btn-outline-secondary m-1 font-bold"
                        v-tooltip="'Click to upload photo'"
                        v-if="$User.hasPermission('agent-edit')"
                        @click.prevent="selectFile"
                        :disabled="loading === 'agentUpdate'"
                      >
                        <i
                          class="mdi"
                          :class="
                            loading === 'agentUpdate'
                              ? 'mdi-loading mdi-spin'
                              : 'mdi-pencil'
                          "
                        ></i>
                        Upload Photo
                      </button>
                      <input
                        type="file"
                        accept="image/*"
                        ref="selectFile"
                        class="d-none"
                        @change="uploadPhoto"
                      />
                    </div>
                    <div class="text-center">
                      <h4 class="card-title m-t-10">
                        <template v-if="verification.personal_info.last_name">
                          {{
                            `${verification.personal_info.first_name} ${
                              verification.personal_info.middle_name
                            } ${verification.personal_info.last_name.toUpperCase()}`
                          }}
                          <span
                            v-if="verification.personal_info.last_name_verified"
                            class="text-success ml-1 mdi mdi-check-circle"
                          ></span>
                        </template>
                        <template v-else>
                          Not available
                        </template>
                      </h4>
                      <h6 class="card-subtitle">
                        <template
                          v-if="
                            agentUpdateState === 'idle' ||
                              agentUpdateState !== 'isMobileUpdating'
                          "
                        >
                          {{ verification.personal_info.mobile }}
                          <span
                            v-if="verification.personal_info.mobile_verified"
                            class="text-success ml-1 mdi mdi-check-circle"
                          ></span>
                          <button
                            class="btn btn-sm btn-outline-secondary m-1 font-bold"
                            v-tooltip="'Click to edit mobile number'"
                            v-if="
                              agentUpdateState === 'idle' &&
                                $User.hasPermission('agent-edit')
                            "
                            @click.prevent="
                              agentUpdateState = 'isMobileUpdating'
                            "
                          >
                            <i class="mdi mdi-pencil"></i> Edit
                          </button>
                        </template>
                        <template
                          v-if="agentUpdateState === 'isMobileUpdating'"
                        >
                          <div class="row">
                            <div class="col-12">
                              <input
                                class="form-control rounded-0 input-sm"
                                type="text"
                                :class="{
                                  'form-error animated shake':
                                    $v.agentMobile.$error
                                }"
                                v-model.trim="agentMobile"
                                name="agentMobile"
                                @input="$v.agentMobile.$touch"
                                placeholder="Agent Mobile Number"
                                autocomplete="mobile"
                              />
                            </div>
                            <div class="col-12 mt-1">
                              <button
                                class="btn btn-sm btn-outline-success font-bold"
                                @click.prevent="updateAgentInformation"
                                :disabled="
                                  loading === 'agentUpdate' ||
                                    $v.agentMobile.$invalid ||
                                    agentMobile ===
                                      verification.personal_info.mobile
                                "
                              >
                                <i
                                  class="mdi"
                                  :class="
                                    loading === 'agentUpdate'
                                      ? 'mdi-loading mdi-spin'
                                      : 'mdi-floppy'
                                  "
                                ></i>
                                Save
                              </button>
                              <button
                                @click.prevent="resetForms"
                                class="btn btn-sm btn-outline-danger ml-1 font-bold"
                                :disabled="loading === 'agentUpdate'"
                              >
                                <i class="mdi mdi-cancel"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </template>
                      </h6>

                      <h6 class="card-subtitle">
                        <template
                          v-if="
                            agentUpdateState === 'idle' ||
                              agentUpdateState !== 'isEmailUpdating'
                          "
                        >
                          {{
                            verification.personal_info.email ||
                              "Email not available"
                          }}
                          <span
                            v-if="verification.personal_info.email_verified"
                            class="text-success ml-1 mdi mdi-check-circle"
                          ></span>
                          <button
                            class="btn btn-sm btn-outline-secondary m-1 font-bold"
                            v-tooltip="'Click to edit email address'"
                            v-if="
                              agentUpdateState === 'idle' &&
                                $User.hasPermission('agent-edit')
                            "
                            @click.prevent="
                              agentUpdateState = 'isEmailUpdating'
                            "
                          >
                            <i class="mdi mdi-pencil"></i> Edit
                          </button>
                        </template>
                        <template v-if="agentUpdateState === 'isEmailUpdating'">
                          <div class="row">
                            <div class="col-12">
                              <input
                                class="form-control rounded-0 input-sm"
                                type="text"
                                :class="{
                                  'form-error animated shake':
                                    $v.agentEmail.$error
                                }"
                                v-model.trim="agentEmail"
                                name="agentEmail"
                                @input="$v.agentEmail.$touch"
                                placeholder="Agent Mobile Number"
                                autocomplete="mobile"
                              />
                            </div>
                            <div class="col-12 mt-1">
                              <button
                                class="btn btn-sm btn-outline-success font-bold"
                                @click.prevent="updateAgentInformation"
                                :disabled="
                                  loading === 'agentUpdate' ||
                                    $v.agentEmail.$invalid ||
                                    agentEmail ===
                                      verification.personal_info.email
                                "
                              >
                                <i
                                  class="mdi"
                                  :class="
                                    loading === 'agentUpdate'
                                      ? 'mdi-loading mdi-spin'
                                      : 'mdi-floppy'
                                  "
                                ></i>
                                Save
                              </button>
                              <button
                                @click.prevent="resetForms"
                                class="btn btn-sm btn-outline-danger ml-1 font-bold"
                                :disabled="loading === 'agentUpdate'"
                              >
                                <i class="mdi mdi-cancel"></i> Cancel
                              </button>
                            </div>
                          </div>
                        </template>
                      </h6>
                    </div>
                    <hr class="b-b" style="border-color: #f5f5f5;" />
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Status</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span
                        class="text-muted font-14 text-capitalize font-bold text-nowrap"
                        :class="
                          verification.status === 'verified'
                            ? 'text-success'
                            : verification.status === 'not_verified'
                            ? 'text-danger'
                            : verification.status === 'pending'
                            ? 'text-warning'
                            : verification.status === 'incomplete'
                            ? 'text-primary'
                            : null
                        "
                      >
                        {{ verification.status | remove_underscore }}
                        <i
                          class="mdi ml-1"
                          :class="
                            verification.status === 'verified'
                              ? 'mdi-check-circle'
                              : verification.status === 'not_verified'
                              ? 'mdi-alert'
                              : verification.status === 'pending'
                              ? 'mdi-clock'
                              : verification.status === 'incomplete'
                              ? 'mdi-alert-outline'
                              : null
                          "
                        ></i>
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Gender</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{ verification.personal_info.gender | title_case }}
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">LSLGA Permit Type</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14 text-capitalize">
                        {{
                          verification.license_types
                            .map(type => type.license_type.category_name)
                            .join(", ") || "Not Available"
                        }}
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">LSLGA Permit Number</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{
                          verification.lslb_license_number || "Not Available"
                        }}
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Other Mobile Number</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{
                          verification.personal_info.other_mobile ||
                            "Not available"
                        }}
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Address</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{ address }}
                      </span>
                      <template v-if="showRequestAddressButton">
                        <br />
                        <label class="label label-warning font-bold">
                          This address has not been verified
                        </label>
                        <button
                          class="ml-1 d-inline-block btn btn-link p-0"
                          @click="openVerifyAddressModal"
                        >
                          Click to verify address
                          <span class="mdi mdi-arrow-right"></span>
                        </button>
                      </template>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Date of Birth</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{ verification.personal_info.dob | date }}
                        <span
                          v-if="verification.personal_info.dob_verified"
                          class="text-success ml-1 mdi mdi-check-circle"
                        ></span>
                      </span>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Application Link</span>
                      <button
                        class="btn ml-2"
                        type="button"
                        v-clipboard:copy="verification.application_link"
                        v-clipboard:success="onCopy"
                      >
                        <i
                          v-tooltip="{
                            content: tooltipMessage,
                            delay: { hide: 500 }
                          }"
                          class="mdi mdi-content-copy text-warning"
                        >
                        </i>
                      </button>
                    </div>
                    <div class="col-xs-12">
                      <span class="font-bold font-14">Submissions</span>
                    </div>
                    <div class="col-xs-12 m-b-10">
                      <span class="text-muted font-14">
                        {{
                          verification.submissions.length === 0
                            ? "Not yet submitted"
                            : verification.submissions.length === 1
                            ? "Submitted only once"
                            : `Submitted ${verification.submissions.length} times`
                        }}
                        <ul class="mt-2 list-style-none">
                          <li
                            v-for="submission in verification.submissions"
                            :key="submission"
                            class="font-12"
                          >
                            <i class="mdi mdi-clock-in"></i>
                            {{ submission | long_date }}
                          </li>
                        </ul>
                      </span>
                    </div>
                    <span
                      v-tooltip="
                        verification.report_available
                          ? ''
                          : 'Download will be enabled as soon as the report is available for viewing'
                      "
                    >
                      <button
                        @click.prevent="downloadReport"
                        class="btn btn-block btn-warning"
                        :disabled="!verification.report_available"
                      >
                        Download Full Report
                        <i
                          class="mdi"
                          :class="
                            downloadingReport
                              ? 'mdi-loading mdi-spin'
                              : 'mdi-download'
                          "
                        ></i>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xlg-9">
                <!-- Tabbed Component -->
                <div class="card bordered-box position-relative">
                  <div class="card-block">
                    <!-- Nav tabs -->
                    <slot name="header"></slot>
                    <!-- Tab panes -->
                    <!-- /content -->
                    <!-- ============================================================== -->
                    <!-- Router view -->
                    <!-- ============================================================== -->
                    <div class="tab-content">
                      <div class="tab-pane active" role="tabpanel">
                        <div class="m-t-15">
                          <slot></slot>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="hide_report" class="overlay-content">
                    <h2>Application report is {{ verification.status }}</h2>
                    <a href="#" @click.prevent="$emit('show_report')">
                      View anyway
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <ReportProgress
              v-if="showReportProgress"
              :report="verification"
            ></ReportProgress>
          </div>
        </div>
      </div>
    </div>
    <verify-address-modal :verification="verification"></verify-address-modal>
  </div>
</template>

<script>
import ReportProgress from "./ReportProgress";
import ImagePercentage from "@/components/images/ImagePercentage";
import * as types from "@/store/types";
import VerifyAddressModal from "./VerifyAddressModal";
import {
  email,
  maxLength,
  minLength,
  numeric,
  required
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "AgentReportComponent",
  components: {
    ReportProgress,
    VerifyAddressModal,
    ImagePercentage
  },
  props: ["verification", "hide_report"],
  created() {
    this.resetForms();
    if (this.profile.user_type === "company") {
      this.showReportProgress = true;
    }
  },
  data() {
    return {
      loading: null,
      agentUpdateState: "idle",
      agentEmail: null,
      agentMobile: null,
      agentPhoto: null,
      downloadingReport: false,
      showReportProgress: false,
      tooltipMessage: "Copy to clipboard"
    };
  },
  validations() {
    return {
      agentEmail: { required, email },
      agentMobile: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      }
    };
  },
  computed: {
    ...mapGetters({
      profile: "getCurrentUser"
    }),
    address() {
      let address = [
        this.verification.address_info.building_number,
        this.verification.address_info.street,
        this.verification.address_info.city,
        this.verification.address_info.state,
        this.verification.address_info.country
      ];
      if (address.includes("")) return "Not available";
      return address.join(", ");
    },
    showRequestAddressButton() {
      return (
        !this.$route.meta.plainLayout &&
        !this.verification.address_info.address_requested &&
        this.verification.submitted &&
        this.$User.hasPermission("address-request") &&
        this.$User.hasPermission("address-report-view")
      );
    }
  },
  methods: {
    async uploadPhoto(input) {
      if (this.loading === "agentUpdate") {
        return;
      }

      if (input.target.files && input.target.files[0]) {
        const { files } = input.target;
        const file = files[0];

        try {
          this.loading = "agentUpdate";
          const response = await this.$store.dispatch(
            types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION_WITH_DETECTION,
            {
              file,
              detectFace: true
            }
          );

          console.log(response);

          this.loading = null;

          this.agentPhoto = response.location;

          await this.updateAgentInformation();
        } catch (error) {
          this.loading = null;

          error &&
            error.data &&
            this.$notify.error({
              message: error.data.message
            });
        }
      }
    },
    selectFile() {
      this.$refs.selectFile.click();
    },
    async updateAgentInformation() {
      if (this.loading === "agentUpdate") {
        return;
      }

      try {
        this.loading = "agentUpdate";

        await this.$store.dispatch(
          types.UPDATE_AGENT_INFORMATION_ACTION_ADMIN,
          {
            verification_id: this.verification.id,
            agentInfo: {
              email: this.agentEmail,
              mobile: this.agentMobile,
              ...(this.agentPhoto && { photo: this.agentPhoto })
            }
          }
        );

        this.$notify.success({
          message: "Agent information updated successfully"
        });

        this.agentUpdateState = "idle";
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      } finally {
        this.loading = null;
      }
    },
    resetForms() {
      this.loading = null;
      this.agentUpdateState = "idle";
      this.agentMobile = this.verification.personal_info.mobile;
      this.agentEmail = this.verification.personal_info.email;
      this.agentPhoto = this.verification.personal_info.photo_meta.url;
    },
    async downloadReport() {
      this.downloadingReport = true;
      try {
        let response = await this.$store.dispatch(
          types.GET_REPORT_DOWNLOAD_URL,
          this.$route.params.verification_id
        );
        window.location.replace(response.download_url);
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.downloadingReport = false;
      }
    },
    openVerifyAddressModal() {
      $("#verify-address-modal").modal("show");
    },
    onCopy() {
      this.tooltipMessage = "Copied!";
      setTimeout(() => (this.tooltipMessage = "Copy to clipboard"), 600);
    }
  },
  mounted() {
    //console.log(this.verification.license_types)
    this.$nextTick(() => {
      setTimeout(() => {
        $(".live-photo-popup").magnificPopup({
          type: "image",
          closeOnContentClick: true,
          mainClass: "mfp-img-mobile mfp-with-zoom",
          image: {
            verticalFit: true
          },
          zoom: {
            enabled: true, // By default it's false, so don't forget to enable it
            duration: 300, // duration of the effect, in milliseconds
            easing: "ease-in-out", // CSS transition easing function
            // The "opener" function should return the element from which popup will be zoomed in
            // and to which popup will be scaled down
            // By defailt it looks for an image tag:
            opener: function(openerElement) {
              // openerElement is the element on which popup was initialized, in this case its <a> tag
              // you don't need to add "opener" option if this code matches your needs, it's defailt one.
              return openerElement.is("img")
                ? openerElement
                : openerElement.find("img");
            }
          }
        });
      }, 0);
    });
  },
  beforeDestroy() {
    $("#verify-address-modal").modal("hide");
    $("#verify-address-modal").on("hidden.bs.modal", function() {
      $("#verify-address-modal").modal("dispose");
    });
  }
};
</script>

<style lang="scss" scoped>
.overlay-content {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
}
</style>
