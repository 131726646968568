import http from "@/helpers/http";

class api {
  static getAgents(query) {
    return http.get("/agents/search", {
      params: {
        query
      }
    });
  }
  static getCouponQuotation(payload) {
    return http.post("/coupons/request/quote", payload);
  }
  static getGeneratedCoupons(payload) {
    if (payload && payload.category) {
      return http.get("/coupons/request", {
        params: payload
      });
    }
    return http.get("/coupons/request", {
      params: { page: payload.page }
    });
  }
  static requestCoupon(payload) {
    return http.post("/coupons/request", payload);
  }
  static initiateCouponPayment(payload) {
    return http.post("/transactions/initiate/coupon", payload);
  }

  static downloadCouponCodes(generated_coupon) {
    return http.get("/coupons/request/" + generated_coupon.id + "/download");
  }

  static verifyCouponCode(payload) {
    let { coupon_code, verification } = payload;
    return http.get("/coupons/verify", {
      params: {
        coupon: coupon_code,
        vid: verification.id
      }
    });
  }

  static exportGeneratedCoupons(payload) {
    return http.get("/coupons/request/export", {
      params: payload
    });
  }

  static searchCouponByReference(agent_id) {
    return http.get("/agents/" + agent_id + "/coupons");
  }
}

export default api;
