<template>
  <div class="page-wrapper">
    <slot></slot>
    <SessionTimeoutModal></SessionTimeoutModal>
  </div>
</template>

<script>
import SessionTimeoutModal from "@/modules/session-timeout/_components/SessionTimeoutModal";

export default {
  name: "app-content",
  mounted() {
    // trigger resize
    $(window).trigger("resize");
  },
  components: {
    SessionTimeoutModal
  }
};
</script>
