import http from "@/helpers/http";
// import { myApp } from "@/main";

class Api {
  static get axios() {
    return http;
  }

  static markLicenseIdAsPrinted(payload) {
    const { agent_id } = payload;
    return http.get(`/agents/${agent_id}/print`);
  }
}
export default Api;
