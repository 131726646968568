<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Print License ID" />

      <!-- ============================================================== -->
      <!-- Agents view -->
      <!-- ============================================================== -->
      <!-- <verified-agents-log /> -->
      <div class="row">
        <div class="col-12">
          <div class="no-card">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs customtab" role="tablist">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'PrintPending' }"
                  class="nav-link"
                  active-class="active"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi-card-account-details"></i>
                  </span>
                  <span class="hidden-xs-down">Pending</span>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'Reprint' }"
                  class="nav-link"
                  active-class="active"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi-card-account-details"></i>
                  </span>
                  <span class="hidden-xs-down">Previously Printed</span>
                </router-link>
              </li>
            </ul>
            <!-- Tab panes -->
            <!-- /content -->
            <!-- ============================================================== -->
            <!-- Router view -->
            <!-- ============================================================== -->
            <div class="tab-content">
              <div class="tab-pane active" role="tabpanel">
                <div class="m-t-15">
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
// import VerifiedAgentsLog from "./_components/VerifiedAgentsLog";
// import * as types from "@/store/types";
// import store from "@/store";
// import notify from "@/helpers/notifyMe";
// import moment from "moment";

export default {
  components: {
    AppTitle
    // VerifiedAgentsLog,
  },
  computed: {},
  created() {},
  mounted() {}
  /* async beforeRouteEnter(to, from, next) {
    console.log("Na wa", to.query.search_term);
    try {
      let { search_term = "", page = 1, status = "verified" } = to.query;
      if (search_term) {
        await Promise.all([
          store.dispatch(types.SEARCH_VERIFIED_AGENTS_ACTION, {
            query: search_term,
            paginate: 1,
            page: 1,
            print: true,
          }),
        ]);
      } else {
        await Promise.all([
          store.dispatch(types.GET_VERIFIED_AGENTS_ACTION, {
            page,
            status,
            print: true,
          }),
        ]);
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }, */
  /* async beforeRouteUpdate(to, from, next) {
    try {
      let { search_term = "", page = 1, status = "verified" } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_VERIFIED_AGENTS_ACTION, {
          query: search_term,
          paginate: 1,
          page: 1,
          status,
          print: true,
        });
      } else {
        await store.dispatch(types.GET_VERIFIED_AGENTS_ACTION, {
          page,
          status,
          print: true,
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }, */
};
</script>
