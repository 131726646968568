import axios from "axios";
import NProgress from "nprogress";
import { ACCESS_TOKEN_KEY } from "@/config/constant";
import notify from "@/helpers/notifyMe";
import StorageService from "@/helpers/storage";
import { myApp } from "@/main";
import * as types from "@/store/types";
export const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

let http = axios.create({
  baseURL: `${API_BASE_URL}`,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json"
  }
});

//add content-type header
http.defaults.headers.common["Content-Type"] = `application/json`;
// http.defaults.headers.common[
//   "Authorization"
// ] = `Bearer ${StorageService.getItem(ACCESS_TOKEN_KEY) || "unsecure"}`;

// Add a request interceptor
http.interceptors.request.use(
  function(config) {
    NProgress.start();
    // add access_token
    config.headers.Authorization = `Bearer ${StorageService.getItem(
      ACCESS_TOKEN_KEY
    ) || "unsecure"}`;
    return config;
  },
  function(error) {
    NProgress.done();
    return Promise.reject(error.response);
  }
);

// Add a response interceptor
http.interceptors.response.use(
  function(response) {
    NProgress.done();
    return response.data;
  },
  function(error) {
    if (!error.response) {
      notify.error({
        message:
          "Something went wrong! Please check your network connection and refresh."
      });
    }
    NProgress.done();
    if (
      (error.response &&
        error.response.data &&
        error.response.data.message.toLowerCase().indexOf("unauthenticate") !==
          -1) ||
      (error.response &&
        error.response.data &&
        error.response.data.message.toLowerCase().indexOf("unauthorized") !==
          -1)
    ) {
      // StorageService.removeItem(ACCESS_TOKEN_KEY);
      // StorageService.removeUserProfileID();

      //push message to the store to be used by login component
      myApp.$store.commit(types.SET_LOGIN_ERROR, error.response.data.message);
      // myApp.$router.push({
      //   name: "LoginView"
      // });

      //log out the user
      myApp.$router.push({
        name: "LogoutView"
      });

      //notify of error
      notify.error({
        message: "Please login to continue"
      });
    }
    if (error.response && !error.response.status) {
      // network error
      notify.error({
        message:
          "Something went wrong! Please check your network connection and refresh."
      });
      NProgress.done();
    }
    return Promise.reject(error.response);
  }
);

export default http;
