import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  generated_coupons_log: [],
  generated_coupons_meta: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
