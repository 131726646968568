// components
import GetStartedView from "@/modules/get-started/Index";

export default [
  {
    path: "/get-started",
    name: "GetStartedView",
    component: GetStartedView,
    meta: {
      requiresAuth: true,
      documentTitle: "Get Started",
      allowed_permissions: []
    }
  }
];
