const getters = {
  getIdentityLog: state => {
    return state.identity_log;
  },
  getIdentityLogPagination: state => {
    return state.identity_log_meta && state.identity_log_meta.pagination;
  },
  getIdentityLogMeta: state => state.identity_log_meta,
  getIdentityReport: state => state.identity_report
};

export default getters;
