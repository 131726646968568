<template>
  <div
    v-if="verified"
    class="no-card w-100 position-relative"
    style="z-index: 2;"
  >
    <div class="card-block">
      <div class="text-center m-b-10">
        <h1 class="mt-5" style="color:#031b4d;">
          Guarantor Details Form
        </h1>
        <!-- <h4 class="card-title text-center">Field Agent Verification Form</h4> -->
        <h6 class="card-subtitle text-center">
          Please complete the form below and submit
        </h6>
      </div>
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs customtab scrollable bg-light mb-3 d-md-none rounded"
        role="tablist"
      >
        <li
          class="nav-item"
          v-for="{ name, routeName, verificationKey } in tabRoutes"
          :key="name"
        >
          <router-link
            class="nav-link d-flex"
            role="tab"
            :to="{ name: routeName }"
            active-class="active"
            aria-expanded="false"
          >
            <i
              v-if="guarantor[verificationKey].completed"
              class="mdi mdi-check-circle text-success mr-1"
            ></i>
            {{ name }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            v-if="!cannotSubmit"
            class="nav-link"
            :class="{ disabled: cannotSubmit }"
            :to="{ name: 'GuarantorPreviewView' }"
            active-class="active"
            role="tab"
            >Form Preview
          </router-link>
          <a
            v-if="cannotSubmit"
            href="javascript:void(0)"
            class="nav-link not-allowed disabled text-muted"
          >
            Form Preview
          </a>
        </li>
      </ul>
      <div class="vtabs customvtab mx-auto w-100">
        <ul
          class="nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded"
          role="tablist"
        >
          <li
            class="nav-item"
            v-for="{ name, routeName, verificationKey } in tabRoutes"
            :key="name"
          >
            <router-link
              class="nav-link d-flex"
              role="tab"
              :to="{ name: routeName }"
              active-class="active"
              aria-expanded="false"
            >
              <i
                v-if="guarantor[verificationKey].completed"
                class="mdi mdi-check-circle text-success mr-1"
              ></i>
              {{ name }}
            </router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link not-allowed"
              style="color: #adadad;"
              v-if="cannotSubmit"
              v-tooltip="'Please complete the form'"
              >Form Preview</a
            >
            <router-link
              v-if="!cannotSubmit"
              class="nav-link d-flex"
              :to="{ name: 'GuarantorPreviewView' }"
              active-class="active"
              role="tab"
              aria-expanded="false"
            >
              <!-- <span class="hidden-sm-up"><i class="mdi mdi-home"></i></span>
                                <span class="hidden-xs-down">Payment</span>-->
              <span>Form Preview</span>
            </router-link>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content w-100 p-t-0">
          <div
            class="tab-pane active bg-white rounded py-3 pr-3"
            role="tabpanel"
            aria-expanded="true"
          >
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="row">
    <!-- <div class="col-sm-12 col-md-8 offset-md-2"> -->
    <div class="col-sm-12">
      <div class="no-card mb-0">
        <div class="card-block">
          <div class="py-5 px-2 flex-box d-flex w-100">
            <div class="text-center align-self-center m-auto">
              <h1>Token doesn't exist</h1>
              <i class="text-danger mdi mdi-cancel ml-1 display-1"></i>
              <p class="font-18">
                Your details have either been saved successfully or token
                doesn't exist
              </p>
              <p class="display-6">Thank you.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";

export default {
  name: "Guarantor",
  data() {
    return {
      tabRoutes: [
        {
          name: "Personal Information",
          routeName: "GuarantorPersonalInformationView",
          verificationKey: "personal_info"
        },
        {
          name: "Identity Details",
          routeName: "GuarantorIdentityDetailsView",
          verificationKey: "identity_info"
        }
        // {
        //     name: "Residential Address",
        //     routeName: "GuarantorAddressDetailsView",
        //     verificationKey: "address_info"
        // },
        // {
        //     name: "Guarantor Information",
        //     routeName: "GuarantorInformationView",
        //     verificationKey: "guarantor_info"
        // }
      ],
      verified: false
    };
  },
  computed: {
    cannotSubmit() {
      return (
        !this.guarantor.personal_info.completed ||
        !this.guarantor.identity_info.completed
      );
    },
    ...mapGetters({
      guarantorDetails: "getGuarantorDetails"
    }),
    guarantor() {
      return this.guarantorDetails && this.guarantorDetails.guarantor;
    }
  },

  async beforeRouteEnter(to, from, next) {
    //confirm valid action
    // if (!["confim", "reject"].includes(to.params.action)) {
    //   return next({ name: "RequestVerificationView" });
    // }
    try {
      await store.dispatch(
        types.GET_GUARANTOR_DETAILS,
        to.params.guarantor_token
      );
      return next(vm => {
        vm.verified = true;
      });
    } catch (error) {
      console.log("error :>> ", error);
      return next(vm => {
        vm.verified = false;
      });
    }
  },

  async beforeRouteUpdate(to, from, next) {
    //confirm valid action
    // if (!["confim", "reject"].includes(to.params.action)) {
    //   return next({ name: "RequestVerificationView" });
    // }
    try {
      await store.dispatch(
        types.GET_GUARANTOR_DETAILS,
        to.params.guarantor_token
      );
      return next();
    } catch (error) {
      error &&
        error.data &&
        notify.error({
          message: error.data.message
        });
      return next(false);
    }
  }
};
</script>

<style scoped></style>
