import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  users_log: [],
  users_meta: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
