var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('app-title',{attrs:{"title":"Manage Roles"}}),_c('div',{staticClass:"card bordered-box"},[_c('div',{staticClass:"card-block"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"vtabs customvtab mx-auto"},[_c('ul',{staticClass:"nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded",attrs:{"role":"tablist"}},[_vm._m(0),(_vm.default_roles.length > 0)?_vm._l((_vm.default_roles),function(role){return _c('li',{key:role.id,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link font-14 text-capitalize pointer",class:[
                          _vm.selected_role.id === role.id ? 'active' : ''
                        ],attrs:{"role":"tab","aria-expanded":"false"},on:{"click":function($event){$event.preventDefault();return _vm.setSelectedRole(role)}}},[_vm._v(_vm._s(role.name))])])}):_vm._e(),(_vm.default_roles.length === 0)?_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"font-12 text-muted"},[_vm._v(" There are no default roles ")])]):_vm._e(),_vm._m(1),(_vm.custom_roles.length > 0)?_vm._l((_vm.custom_roles),function(role){return _c('li',{key:role.id,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link font-14 text-capitalize pointer",class:[
                          _vm.selected_role.id === role.id ? 'active' : ''
                        ],attrs:{"role":"tab","aria-expanded":"false"},on:{"click":function($event){$event.preventDefault();return _vm.setSelectedRole(role)}}},[_vm._v(_vm._s(role.name))])])}):_vm._e(),(_vm.custom_roles.length === 0)?_c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"font-12 text-muted p-2"},[_vm._v(" You can create your own custom role and choose exactly what team members can do ")])]):_vm._e(),_c('div',{staticClass:"p-2"},[_c('button',{staticClass:"btn btn-sm btn-warning",on:{"click":function($event){$event.preventDefault();return _vm.createCustomRole($event)}}},[_c('i',{staticClass:"mdi mdi-plus"}),_vm._v("Add a Custom Role ")])])],2),_c('div',{staticClass:"tab-content w-100 p-20"},[_c('div',{staticClass:"tab-pane active",attrs:{"role":"tabpanel","aria-expanded":"true"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"w-100 d-flex justify-content-between"},[_c('p',{staticClass:"card-title font-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.selected_role.name)+" ")]),(_vm.selected_role.is_custom)?_c('div',[_c('button',{staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();return _vm.editCustomRole($event)}}},[_c('i',{staticClass:"mdi mdi-pencil mr-1"}),_vm._v("Edit ")]),(_vm.selected_role.users.length === 0)?_c('button',{staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();return _vm.deleteCustomRole($event)}}},[_c('i',{staticClass:"mdi mdi-delete mr-1"}),_vm._v("Delete ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-sm-12"},[_c('p',{staticClass:"font-14"},[_vm._v(_vm._s(_vm.selected_role.description))]),_c('p',{staticClass:"font-12 text-muted"},[_c('i',{staticClass:"mdi mdi-account-multiple mr-1"}),(
                              _vm.selected_role && _vm.selected_role.users.length > 0
                            )?[_vm._v(" Team Member(s); "+_vm._s(((_vm.selected_role.users[0].first_name) + " " + (_vm.selected_role.users[0].middle_name) + " " + (_vm.selected_role.users[0].last_name)))+" "),(_vm.selected_role.users.length > 1)?[_vm._v(" and "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to view more'),expression:"'Click to view more'"}],staticClass:"transition fade-link pointer",on:{"click":function($event){$event.preventDefault();return _vm.openViewAllRoleUsersModal($event)}}},[_vm._v(" "+_vm._s(_vm.selected_role.users.length - 1)+" other(s) "),_c('i',{staticClass:"mdi mdi-dots-horizontal"})])]:_vm._e()]:[_vm._v("There are no team members in this role")]],2)]),_c('div',{staticClass:"col-sm-12"},[_c('ul',{staticClass:"list-group list-group-flush"},[_c('li',{staticClass:"list-group-item list-group-item-success font-14"},[_vm._v(" What this role can access ")]),(
                              _vm.selected_role &&
                                _vm.selected_role.permissions.length > 0
                            )?_vm._l((_vm.permission_object_list),function(permission){return _c('li',{key:permission.permission,staticClass:"list-group-item font-14 p-l-0 text-capitalize"},[_c('span',{staticClass:"font-bold mr-1"},[_vm._v("Can ")]),_vm._v(_vm._s(permission.name)+" ")])}):[_vm._m(2)]],2)])])])])])])])])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"card-title font-bold p-2"},[_vm._v("Default Roles")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"nav-item"},[_c('p',{staticClass:"card-title font-bold mt-5 p-2"},[_vm._v("Custom Roles")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"list-group-item"},[_c('p',{staticClass:"font-12 text-muted"},[_vm._v(" There are no permissions for this role ")])])}]

export { render, staticRenderFns }