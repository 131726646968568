const getters = {
  getAgentsLog: state => {
    return state.agents_log;
  },
  getAgentsLogMeta: state => {
    return state.agents_meta;
  },
  getAgentsLogPagination: state => {
    return state.agents_meta && state.agents_meta.pagination;
  },
  getAgentProfile: state => {
    return state.agent_profile;
  },
  getAgentComments: state => {
    return state.agent_profile && state.agent_profile.comments;
  },
  getAgentVerifications: state => {
    return state.agent_profile && state.agent_profile.verifications;
  }
};

export default getters;
