<template>
  <keep-alive>
    <component @toggle="toggleComponent" :is="displayComponent()"></component>
  </keep-alive>
</template>

<script>
import Report from "@/components/reports/Report.vue";
import InfractionReportPhoto from "@/components/reports/InfractionReportPhoto.vue";
export default {
  name: "ReportComponent",
  components: { Report, InfractionReportPhoto },

  data() {
    return {
      isInfractionPhoto: false
    };
  },
  methods: {
    displayComponent() {
      if (this.isInfractionPhoto) {
        return InfractionReportPhoto;
      } else {
        return Report;
      }
    },
    toggleComponent() {
      this.isInfractionPhoto = !this.isInfractionPhoto;
    }
  }
};
</script>

<style></style>
