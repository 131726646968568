<template>
  <div>
    <!-- ============================================================== -->
    <!-- AgentsLog component -->
    <!-- ============================================================== -->
    <agent-lookup></agent-lookup>
    <router-view></router-view>
    <new-agent-modal />
  </div>
</template>

<script>
import AgentLookup from "./_components/AgentLookup";
import NewAgentModal from "./_components/NewAgentModal";

export default {
  components: {
    AgentLookup,
    NewAgentModal
  },
  beforeDestroy() {
    $("#invite-agent-modal").modal("hide");
    $("#invite-agent-modal").on("hidden.bs.modal", function() {
      $("#invite-agent-modal").modal("dispose");
    });
  }
};
</script>
