import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  login_error: "",
  intended_to: "",
  auth_data: {
    email: "",
    auth_type: ""
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
