import * as types from "@/store/types";
const mutations = {
  async [types.SET_VERIFIED_AGENTS_LOG](state, payload) {
    state.verified_agents_log = payload;
  },
  async [types.SET_VERIFIED_AGENTS_META](state, payload) {
    state.verified_agents_meta = payload;
  }
};

export default mutations;
