<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="add-single-user-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Invite New User</h4>
          <button
            type="button"
            @click="clearForm"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="add-single-user-form">
            <div
              class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
              v-if="message"
            >
              {{ message }}
            </div>
            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="user-email" class="control-label form-label">
                  Email
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="email"
                  :class="{ 'form-error animated shake': $v.email.$error }"
                  @input="$v.email.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Email Address"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="user-role" class="control-label form-label">
                  Role
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control rounded-0 custom-select"
                  v-model.trim="role"
                  :class="{ 'form-error animated shake': $v.role.$error }"
                >
                  <option value selected disabled
                    >-- select user role --</option
                  >
                  <option
                    :key="key"
                    v-for="(role, key) in user_roles"
                    :value="role.name"
                    class="text-capitalize"
                    >{{ role.name }}</option
                  >
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              @click.prevent="inviteNewUser"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Invite New User
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";

export default {
  data() {
    return {
      email: "",
      role: "",
      isLoading: false,
      message: ""
    };
  },
  validations: {
    email: { required, email },
    role: { required }
  },
  methods: {
    async inviteNewUser() {
      //check if form is valid
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      //clear message if exists
      this.message = "";
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.INVITE_NEW_USER_ACTION, {
          ...this.$data
        });
        eventBus.$emit("lslb:user:refresh-users-list");
        this.email = "";
        this.role = "";
        this.isLoading = false;
        this.$v.$reset();
        // this.message = "Invitation sent successfully";
        this.$notify.success({ message: "Invitation sent successfully" });
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    clearForm() {
      this.email = "";
      this.role = "";
      this.$v.$reset();
    }
  },
  computed: {
    ...mapGetters({
      user_roles: "getAllRoles"
    })
  }
};
</script>
