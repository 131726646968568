var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-card w-100 position-relative",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"card-block"},[_vm._m(0),(_vm.$route.name !== 'VerificationPaymentView')?_c('div',{staticClass:"text-center text-md-right mb-3 font-bold"},[_vm._v(" Application Cost: ₦"+_vm._s(_vm._f("amount")((_vm.verification_price / 100)))+" ")]):_vm._e(),_c('ul',{staticClass:"nav nav-tabs customtab scrollable bg-light mb-3 d-md-none rounded",attrs:{"role":"tablist"}},[_vm._l((_vm.tabRoutes),function(ref){
var name = ref.name;
var routeName = ref.routeName;
return _c('li',{key:name,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"active-class":"active","to":{ name: routeName },"role":"tab"}},[_vm._v(" "+_vm._s(name)+" ")])],1)}),_c('li',{staticClass:"nav-item"},[(!_vm.cannotSubmit)?_c('router-link',{staticClass:"nav-link",class:{ disabled: _vm.cannotSubmit },attrs:{"to":{ name: 'RequestVerificationPreviewView' },"active-class":"active","role":"tab"}},[_vm._v("Form Preview")]):_vm._e(),(_vm.cannotSubmit)?_c('a',{staticClass:"nav-link not-allowed disabled text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v(" Form Preview ")]):_vm._e()],1),_c('li',{staticClass:"nav-item"},[(!_vm.cannotSubmit)?_c('router-link',{staticClass:"nav-link",class:{ disabled: _vm.cannotSubmit },attrs:{"to":{ name: 'VerificationPaymentView' },"active-class":"active","role":"tab"}},[_vm._v("Application Payment")]):_vm._e(),(_vm.cannotSubmit)?_c('a',{staticClass:"nav-link not-allowed disabled text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v(" Application Payment ")]):_vm._e()],1)],2),_c('div',{staticClass:"vtabs customvtab mx-auto w-100"},[_c('ul',{staticClass:"nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded",attrs:{"role":"tablist"}},[_vm._l((_vm.tabRoutes),function(ref){
var name = ref.name;
var routeName = ref.routeName;
var verificationKey = ref.verificationKey;
return _c('li',{key:name,staticClass:"nav-item"},[(
              _vm.verification.guarantor_info.status === 'needs_update' &&
                verificationKey !== 'guarantor_info'
            )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Please fill the Guarantor Information'),expression:"'Please fill the Guarantor Information'"}],staticClass:"nav-link not-allowed",staticStyle:{"color":"#adadad"}},[_vm._v(_vm._s(name))]):_c('router-link',{staticClass:"nav-link d-flex",attrs:{"role":"tab","to":{ name: routeName },"active-class":"active","aria-expanded":"false"}},[(_vm.verification[verificationKey].completed)?_c('i',{staticClass:"mdi mdi-check-circle text-success mr-1"}):_vm._e(),_vm._v(" "+_vm._s(name)+" ")])],1)}),_c('li',{staticClass:"nav-item"},[(
              _vm.cannotSubmit ||
                _vm.verification.guarantor_info.status === 'needs_update'
            )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.cannotSubmit
                ? 'Please complete the form'
                : 'Please fill the Guarantor Information'
            ),expression:"\n              cannotSubmit\n                ? 'Please complete the form'\n                : 'Please fill the Guarantor Information'\n            "}],staticClass:"nav-link not-allowed",staticStyle:{"color":"#adadad"}},[_vm._v("Form Preview")]):_c('router-link',{staticClass:"nav-link d-flex",attrs:{"to":{ name: 'RequestVerificationPreviewView' },"active-class":"active","role":"tab","aria-expanded":"false"}},[_c('span',[_vm._v("Form Preview")])])],1),_c('li',{staticClass:"nav-item"},[(
              _vm.cannotSubmit ||
                _vm.verification.guarantor_info.status === 'needs_update'
            )?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              _vm.cannotSubmit
                ? 'Please complete the form'
                : 'Please fill the Guarantor Information'
            ),expression:"\n              cannotSubmit\n                ? 'Please complete the form'\n                : 'Please fill the Guarantor Information'\n            "}],staticClass:"nav-link not-allowed",staticStyle:{"color":"#adadad"}},[_vm._v("Payment")]):_c('router-link',{staticClass:"nav-link d-flex",attrs:{"to":{ name: 'VerificationPaymentView' },"active-class":"active","role":"tab","aria-expanded":"false"}},[_c('span',[_vm._v(" Payment "),(_vm.verification.paid)?_c('i',{staticClass:"mdi mdi-check-cirlce text-success"}):_vm._e()])])],1)],2),_c('div',{staticClass:"tab-content w-100 p-t-0"},[_c('div',{staticClass:"tab-pane active bg-white rounded py-3 pr-3",attrs:{"role":"tabpanel","aria-expanded":"true"}},[_c('router-view')],1),(
            !(
              _vm.$route.name === 'VerificationPaymentView' ||
              _vm.$route.name === 'RequestVerificationPreviewView'
            )
          )?_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"offset-md-6 col-md-6"},[_c('router-link',{staticClass:"btn btn-warning waves-effect rounded-0 w-100",attrs:{"tag":"button","to":{
                  name: 'RequestVerificationPreviewView',
                  params: { verification_id: _vm.verification.id }
                },"disabled":_vm.cannotSubmit}},[_vm._v(" Preview and Submit ")])],1)])]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center m-b-10"},[_c('h1',{staticClass:"mt-5",staticStyle:{"color":"#031b4d"}},[_vm._v(" Agent Application Form ")]),_c('h6',{staticClass:"card-subtitle text-center"},[_vm._v(" Please complete the form below and submit ")])])}]

export { render, staticRenderFns }