import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  identity_log: [],
  identity_log_meta: null,
  identity_report: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
