<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Transaction" />
    <transaction-detail-component></transaction-detail-component>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import TransactionDetailComponent from "./TransactionDetailComponent";
import store from "@/store";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";

export default {
  name: "TransactionView",
  components: {
    AppTitle,
    TransactionDetailComponent
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.GET_TRANSACTION_ACTION, {
        transaction_id: to.params.transaction_id
      });
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
