<template>
  <div>
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Manage Company component -->
    <!-- ============================================================== -->
    <manage-company></manage-company>
    <new-company-modal></new-company-modal>
  </div>
</template>

<script>
import ManageCompany from "./_components/ManageCompany";
import NewCompanyModal from "./_components/NewCompanyModal";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";

export default {
  name: "UsersComponent",
  components: {
    ManageCompany,
    NewCompanyModal
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const profile = store.getters.getCurrentUser;
      if (profile.user_type === "company") {
        return next({
          name: "NotFound"
        });
      }

      let { page = 1 } = to.query;

      await Promise.all([
        store.dispatch(types.GET_ALL_COMPANIES_ACTION, {
          page
        }),
        store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES)
      ]);
      return next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      return next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let { page = 1 } = to.query;

      await Promise.all([
        store.dispatch(types.GET_ALL_COMPANIES_ACTION, {
          page
        }),
        store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES)
      ]);
      return next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      return next(false);
    }
  },
  beforeDestroy() {
    $("#add-company-modal").modal("hide");
    $("#add-company-modal").on("hidden.bs.modal", function() {
      $("#add-company-modal").modal("dispose");
    });
  }
};
</script>
