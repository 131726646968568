import http from "@/helpers/http";

class api {
  static get axios() {
    return http;
  }
  static getIdentityLog(payload) {
    if (payload && payload.status && payload.type) {
      return http.get("/verifications/identities", {
        params: payload
      });
    }
    return http.get("/verifications/identities", {
      params: { page: payload.page }
    });
  }
  static exportIdentityLog(payload) {
    return http.get("/verifications/identities/export", {
      params: payload
    });
  }
  static getIdentityReport(payload) {
    let { report_id } = payload;
    return http.get("/verifications/identities/" + report_id);
  }
  static verifyIdentity(payload) {
    return http.post("/verifications/identities", payload);
  }

  static searchIdentityLog(payload) {
    return http.get("/verifications/identities/search", {
      params: {
        query: payload.query,
        page: payload.page
        // paginate: 1
      }
    });
  }
}

export default api;
