// components
import AgentsView from "@/modules/agents/Index";
import AgentView from "@/modules/agents/_components/AgentView";
import AgentsLog from "@/modules/agents/_components/AgentsLog.vue";
import AgentsInvite from "@/modules/agents/_components/AgentsInvite.vue";
import UsersView from "@/modules/users/Index";
import CompaniesView from "@/modules/companies/Index";
import EditCompaniesView from "@/modules/companies/edit";
import PermitsView from "@/modules/permits/Index";
import EcosystemContainer from "@/containers/Ecosystems";

export default [
  {
    path: "/ecosystems",
    component: EcosystemContainer,
    redirect: {
      name: "AgentsView"
    },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "permits",
        component: PermitsView,
        name: "PermitsView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: [],
          documentTitle: "Permits"
        }
      },
      {
        path: "agents",
        component: AgentsView,
        name: "AgentsView",
        redirect: {
          name: "AgentLogView"
        },
        meta: {
          requiresAuth: true,
          allowed_permissions: ["agent-view"]
        },
        children: [
          {
            path: "",
            component: AgentsLog,
            name: "AgentLogView",
            meta: {
              requiresAuth: true,
              showProgressBar: true,
              allowed_permissions: ["agent-view"],
              documentTitle: "Agents"
            }
          },
          {
            path: "invite",
            component: AgentsInvite,
            name: "AgentsInvite",
            meta: {
              requiresAuth: true,
              showProgressBar: true,
              allowed_permissions: ["agent-view"],
              documentTitle: "Agents"
            }
          }
        ]
      },
      {
        path: "users",
        component: UsersView,
        name: "UsersView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["user-view"],
          documentTitle: "Users"
        }
      },
      {
        path: "companies",
        component: CompaniesView,
        name: "CompaniesView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["user-view"],
          documentTitle: "Companies"
        }
      }
    ]
  },
  {
    path: "/ecosystems/agents/:agent_id",
    component: AgentView,
    name: "AgentView",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["agent-view"],
      documentTitle: "Agent Profile"
    }
  },
  {
    path: "/ecosystems/companies/:company_id",
    component: EditCompaniesView,
    name: "EditCompaniesView",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["user-view"],
      documentTitle: "Companies"
    }
  }
];
