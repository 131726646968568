import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  agent_profile: null,
  agents_log: [],
  agents_meta: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
