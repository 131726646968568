<template>
  <div
    class="modal _animated fade _pulse modal-border"
    id="get-payment-option-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="get-payment-option-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            Select Payment Mode
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12" v-if="!is_bank_transfer">
                <div class="form-check radio-info">
                  <label class="custom-control custom-radio">
                    <input
                      value="online"
                      name="payment_mode"
                      v-model.trim="payment_mode"
                      type="radio"
                      class="custom-control-input"
                    />
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description"
                      >Card/Bank Account</span
                    >
                  </label>
                  <!--                  <label class="custom-control custom-radio">-->
                  <!--                    <input-->
                  <!--                      value="bank_transfer"-->
                  <!--                      name="payment_mode"-->
                  <!--                      v-model.trim="payment_mode"-->
                  <!--                      type="radio"-->
                  <!--                      class="custom-control-input"-->
                  <!--                    />-->
                  <!--                    <span class="custom-control-indicator"></span>-->
                  <!--                    <span class="custom-control-description"-->
                  <!--                      >Bank Transfer</span-->
                  <!--                    >-->
                  <!--                  </label>-->
                </div>
              </div>
              <div class="col-sm-12" v-if="is_bank_transfer">
                <div
                  class="alert alert-success-light font-normal font-14 rounded-0"
                >
                  <div>
                    Complete the transaction by transferring ₦{{
                      (parseInt(payment.amount_in_kobo_approved) / 100) | amount
                    }}
                    to;
                    <br />
                    <span class="font-bold">Account Name:</span> Lagos State
                    Lotteries Board Distribution Trust Account
                    <br />
                    <span class="font-bold">Bank:</span> Access Bank Plc
                    <br />
                    <span class="font-bold">Account Number:</span> 0724019405
                    <br />
                    Please send Transaction ID ({{ payment.id }}) and proof of
                    payment to
                    <a class="text-info" href="mailto:lslb_billing@youverify.co"
                      >lslb_billing@youverify.co</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              v-if="!is_bank_transfer"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
              @click="proceedToPayment"
            >
              Proceed to Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";

export default {
  props: ["initiatePayment", "have_coupon", "coupon_code", "verification"],
  data() {
    return {
      payment_mode: "online",
      // payment_mode: "bank_transfer",
      is_bank_transfer: false,
      payment: {}
    };
  },
  methods: {
    async proceedToPayment() {
      this.payment_mode = "online";
      let payment = await this.$store.dispatch(
        types.INITIATE_VERIFICATION_PAYMENT_ACTION,
        {
          payment_mode: this.payment_mode,
          coupon_code: this.have_coupon ? this.coupon_code : "",
          item_id: this.verification.id
        }
      );

      this.payment = payment;

      this.initiatePayment(payment, this.payment_mode);

      if (this.payment_mode === "online") {
        $("#get-payment-option-modal").modal("hide");
      } else {
        this.is_bank_transfer = true;
      }
    }
  }
};
</script>
