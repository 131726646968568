<template>
  <div>
    <div class="wrapper">
      <div v-for="(data, i) in infraction_photo" :key="i">
        <img :src="data" alt="" width="480px" height="360px" />
      </div>
    </div>
    <div class="btn-style">
      <button
        @click.prevent="$emit('toggle')"
        class="col-12 col-sm-5 col-md-3 ml-auto mr-6  text-center py-3 btn back-button mb-4"
      >
        Back
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      photo: []
    };
  },
  computed: {
    ...mapGetters({
      infraction_photo: "getInfractionPhoto"
    })
  }
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap;
}
img {
  margin-bottom: 20px;
}
.back-button {
  border: 1px solid #404e5d;
  box-sizing: border-box;
  border-radius: 3px;
  background: white;
  color: #404e5d;
}
.btn-style {
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
}</style
>>
