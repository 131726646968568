import * as types from "@/store/types";

const mutations = {
  async [types.SET_BUSINESS_ACCOUNT_BALANCE](state, payload) {
    state.business_account_balance = payload;
  },
  async [types.SET_BUSINESS_TRANSACTION_TOTAL](state, payload) {
    state.business_transaction_total = payload;
  },
  async [types.SET_AGENT_ANALYTICS](state, payload) {
    state.agent_analytics = payload;
  },
  async [types.SET_DASHBOARD_METRICS](state, payload) {
    state.dashboard_metrics = payload;
  },
  async [types.SET_VERIFICATIONS_BY_CITIES](state, payload) {
    state.verification_by_cities = payload;
  },
  async [types.SET_VERIFICATIONS_METRICS](state, payload) {
    state.verification_metrics = payload;
  },
  async [types.SET_IDENTITY_METRICS](state, payload) {
    state.identity_metrics = payload;
  }
};

export default mutations;
