// components
import LoginView from "@/modules/auth/Login";
import LogoutView from "@/modules/auth/Logout";
import ForgotPasswordView from "@/modules/auth/ForgotPassword";
import ResetPasswordView from "@/modules/auth/ResetPassword";
// import ChangePasswordView from "@/modules/auth/ChangePassword";
import RegisterView from "@/modules/auth/Register";
import AuthContainer from "@/containers/Auth";

export default [
  {
    path: "/auth",
    component: AuthContainer,
    redirect: {
      name: "LoginView"
    },
    children: [
      {
        path: "login",
        component: LoginView,
        name: "LoginView",
        meta: {
          plainLayout: true,
          documentTitle: "Login",
          guest: "true"
        }
      },
      {
        path: "logout",
        component: LogoutView,
        name: "LogoutView",
        meta: {
          plainLayout: true,
          documentTitle: "Logout",
          requiresAuth: true
        }
      },
      {
        path: "forgot",
        component: ForgotPasswordView,
        name: "ForgotPasswordView",
        meta: {
          plainLayout: true,
          documentTitle: "Forgot Password",
          guest: true
        }
      },
      {
        path: "reset-password/:token",
        component: ResetPasswordView,
        name: "ResetPasswordView",
        meta: {
          plainLayout: true,
          documentTitle: "Reset Password"
        }
      },
      // {
      //   path: "change-password",
      //   component: ChangePasswordView,
      //   name: "ChangePasswordView",
      //   meta: {
      //     plainLayout: true,
      //     documentTitle: "Change Password",
      //     guest: true
      //   }
      // },
      {
        path: "register/:token",
        component: RegisterView,
        name: "RegisterView",
        meta: {
          plainLayout: true,
          documentTitle: "Register"
        }
      }
    ]
  }
];
