// components
import ReportsView from "@/modules/reports/Index";
import ReportView from "@/modules/reports/_components/ReportView";
import VerificationContainer from "@/containers/Verifications";
import GeneratedLinksView from "@/modules/generate-links/Index";
import CouponView from "@/modules/coupon/Index";
import AddressView from "@/modules/address/Index";
import IdentityVerificationReport from "@/components/report/IdentityVerificationReport";
import AddressReport from "@/components/report/AddressReport";
import ResidentialAddressReport from "@/components/report/ResidentialAddressReport";
import GuarantorReport from "@/components/report/GuarantorReport";

export default [
  {
    path: "/verifications",
    name: "Verifications",
    component: VerificationContainer,
    redirect: {
      name: "ReportsView"
    },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "reports",
        component: ReportsView,
        name: "ReportsView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["report-view"],
          documentTitle: "Application Reports"
        }
      },
      {
        path: "reports/:verification_id",
        component: ReportView,
        name: "ReportView",
        redirect: {
          name: "IdentityVerificationReport"
        },
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["report-view"],
          documentTitle: "Application Report"
        },
        children: [
          {
            path: "bvn",
            component: IdentityVerificationReport,
            name: "IdentityVerificationReport",
            meta: {
              allowed_permissions: ["report-view", "report-progress-view"],
              documentTitle: "BVN - Verification Report"
            }
          },
          {
            path: "business-address",
            component: AddressReport,
            name: "AddressReport",
            meta: {
              allowed_permissions: ["address-report-view"],
              documentTitle: "Business Address - Verification Report"
            }
          },
          {
            path: "residential-address",
            component: ResidentialAddressReport,
            name: "ResidentialAddressReport",
            meta: {
              allowed_permissions: ["address-report-view"],
              documentTitle: "Residential Address - Verification Report"
            }
          },
          {
            path: "guarantor",
            component: GuarantorReport,
            name: "GuarantorReport",
            meta: {
              allowed_permissions: ["address-report-view"],
              documentTitle: "Address - Verification Report"
            }
          }
        ]
      },
      {
        path: "generate-links",
        component: GeneratedLinksView,
        name: "GeneratedLinksView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["verification-link-view"],
          documentTitle: "Generate Links"
        }
      },
      {
        path: "coupons",
        component: CouponView,
        name: "CouponView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["coupon-view"],
          documentTitle: "Coupons"
        }
      },
      {
        path: "addresses",
        component: AddressView,
        name: "AddressView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["address-report-view"],
          documentTitle: "Addresses"
        }
      }
    ]
  }
];
