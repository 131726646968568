<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="remove-coupon-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Remove Coupon</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <div class="col-md-12">
            <p class="font-14">Are you sure you want to remove the coupon?</p>
          </div>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-danger rounded-0 waves-effect p-10 w-100 p-10"
              @click="removeCoupon"
            >
              Yes, Remove Coupon
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RemoveCouponModal",
  props: ["removeCoupon"]
};
</script>
