<template>
  <div id="main-wrapper" :class="{ 'plain-layout': $route.meta.plainLayout }">
    <nprogress-container></nprogress-container>
    <template v-if="$route.meta.plainLayout">
      <app-content-plain>
        <app-header-plain />
        <div
          class="flex-grow d-flex justify-content-center w-100 mx-auto pt-5"
          style="max-width: 1500px;"
        >
          <transition
            enter-active-class="animated fadeIn faster"
            leave-active-class="animated fadeOut faster"
            mode="out-in"
            appear
          >
            <router-view></router-view>
          </transition>
        </div>
        <app-footer-plain />
      </app-content-plain>
    </template>
    <template v-if="!$route.meta.plainLayout">
      <section id="main-wrapper">
        <app-header />
        <app-sidebar-left />
        <app-content>
          <transition name="fade_" mode="out-in">
            <router-view v-if="isPageContentReady"></router-view>
          </transition>
          <content-placeholder v-if="!isPageContentReady"></content-placeholder>

          <!-- <session-timeout-modal
            v-if="!$route.meta.plainLayout"
          ></session-timeout-modal> -->

          <app-footer />
        </app-content>
      </section>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppHeader from "@/components/layouts/AppHeader";
import AppHeaderPlain from "@/components/layouts/AppHeaderPlain";
import AppSidebarLeft from "@/components/layouts/AppLeftSidebar";
import AppFooter from "@/components/layouts/AppFooter";
import AppFooterPlain from "@/components/layouts/AppFooterPlain";
import AppContent from "@/components/layouts/AppContent";
import AppContentPlain from "@/components/layouts/AppContentPlain";
import ContentPlaceholder from "@/components/layouts/ContentPlaceholder";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
// import SessionTimeoutModal from "@/modules/session-timeout/_components/SessionTimeoutModal";

export default {
  name: "App",
  components: {
    AppHeader,
    AppSidebarLeft,
    AppFooter,
    AppContent,
    ContentPlaceholder,
    NprogressContainer,
    // SessionTimeoutModal,
    AppFooterPlain,
    AppHeaderPlain,
    AppContentPlain
  },
  mounted() {
    this.$nprogress.configure({
      parent: "#main-wrapper",
      showSpinner: true,
      trickleSpeed: 200
    });

    //enable multiple bootstrap modals
    $(document).on(
      {
        "show.bs.modal": function() {
          var zIndex = 888 + 10 * $(".modal:visible").length;
          $(this).css("z-index", zIndex);
          setTimeout(function() {
            $(".modal-backdrop")
              .not(".modal-stack")
              .css("z-index", zIndex - 1)
              .addClass("modal-stack");
          }, 0);
        },
        "hidden.bs.modal": function() {
          if ($(".modal:visible").length > 0) {
            // restore the modal-open class to the body element, so that scrolling works
            // properly after de-stacking a modal.
            setTimeout(function() {
              $(document.body).addClass("modal-open");
            }, 0);
          }
        }
      },
      ".modal"
    );
  },
  computed: {
    ...mapGetters(["isPageContentReady"]),
    isContentAvailable() {
      return this.isPageContentReady;
    }
  }
};
</script>

<style lang="scss">
/* load fonts */
@import "/static/css/load-font.css";
@import "./../node_modules/izitoast/dist/css/iziToast.min.css";
@import "./../node_modules/dropify/dist/css/dropify.min.css";
@import "./../node_modules/flatpickr/dist/flatpickr.min.css";
@import "./../node_modules/magnific-popup/dist/magnific-popup.css";
/* import all styles -- entry */
@import "assets/scss/app.scss";
#app {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
