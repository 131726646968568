<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 500px;">
      <form
        class="card-block form-horizontal"
        @submit.prevent="finishUserRegistration"
        v-if="!submitSuccess"
      >
        <template v-if="isTokenValid">
          <div class="form-group">
            <div class="col-xs-12">
              <h3 class="text-center">Join the Team</h3>
              <p class="font-14 text-center font-bold">
                Hi {{ email }}, please complete the form to finish creating your
                LSLGA account
              </p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-xs-12 font-bold form-label">
              User's Picture
              <span class="text-danger">*</span>
            </label>
            <div class="col-xs-12">
              <input
                type="file"
                data-max-file-size="15M"
                data-allowed-file-extensions="jpeg jpg png"
                data-height="100"
                data-show-loader="true"
                class="form-control rounded-0 dropify"
                @change="setImage"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <label class="font-bold form-label" for="first_name">
                First Name
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-xs-12 input-group">
              <input
                class="form-control rounded-0"
                type="text"
                v-model.trim="first_name"
                :class="{ 'form-error animated shake': $v.first_name.$error }"
                @input="$v.first_name.$touch"
                required
                placeholder="Enter Your First Name"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <label class="font-bold form-label" for="middle_name"
                >Middle Name</label
              >
            </div>
            <div class="col-xs-12 input-group">
              <input
                class="form-control rounded-0"
                type="text"
                v-model.trim="middle_name"
                placeholder="Enter Your Middle Name"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <label class="font-bold form-label" for="last_name">
                Last Name
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-xs-12 input-group">
              <input
                class="form-control rounded-0"
                type="text"
                v-model.trim="last_name"
                :class="{ 'form-error animated shake': $v.last_name.$error }"
                @input="$v.last_name.$touch"
                required
                placeholder="Enter Your Last Name"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <label class="font-bold form-label" for="mobile">
                Phone Number
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-xs-12 input-group">
              <input
                class="form-control rounded-0"
                type="text"
                inputmode="numeric"
                v-model.trim="mobile"
                :class="{ 'form-error animated shake': $v.mobile.$error }"
                @input="$v.mobile.$touch"
                required
                placeholder="Enter Your Phone Number"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <label class="font-bold form-label" for="gender">
                Gender
                <span class="text-danger">*</span>
              </label>
            </div>
            <div class="col-xs-12 input-group">
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="gender"
                @change="$v.gender.$touch"
              >
                <option value disabled>-- select gender --</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-12">
              <label class="font-bold form-label" for="mobile">
                Password
                <span class="text-danger">*</span>
              </label>
            </div>

            <div class="col-xs-12 input-group">
              <input
                id="login-password"
                class="form-control rounded-0"
                :type="password_visibility ? 'text' : 'password'"
                :class="{ 'form-error animated shake': $v.password.$error }"
                v-model.trim="password"
                @input="$v.password.$touch"
                required="required"
                placeholder="Password"
              />
              <div
                @click="password_visibility = !password_visibility"
                class="input-group-addon rounded-0 pointer no-select"
              >
                <i
                  class="fa"
                  :class="password_visibility ? 'mdi-eye' : 'mdi-eye-slash'"
                  aria-hidden="true"
                ></i>
                <!-- {{password_visibility ? 'HIDE' : 'SHOW'}} -->
              </div>
            </div>
            <div class="form-group mt-2" v-show="password">
              <div class="col-xs-12 font-14">
                At least;
                <ul class="list-unstyled">
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.password.containsLowerCase
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One lowercase letter
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.password.containsUpperCase
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One uppercase letter
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.password.containsSpecialCharacter
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One special character (allowed characters ! @ # $ % ^ & _ -)
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.password.containsNumber
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    One number
                  </li>
                  <li class="font-14">
                    <i
                      class="mdi"
                      :class="
                        $v.password.isGreaterThanEight
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'
                      "
                    ></i>
                    Eight characters
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button
                :disabled="isLoading || $v.$invalid"
                class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                type="submit"
              >
                Finish Registration
                <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-if="!isTokenValid">
          <div class="form-group text-center">
            <h4>Unauthorized Link</h4>
            <!-- <i class="text-danger mdi mdi-cancel ml-1 display-1"></i> -->
            <p class="font-14">
              Hey there, you're trying to access data on a business that you do
              not have access to. If you clicked this from an invitation link,
              please contact the inviter to confirm you're still added to the
              business.
            </p>
          </div>
          <div class="form-group m-b-0">
            <div class="col-sm-12 text-center">
              <router-link
                :to="{ name: 'HomeView' }"
                class="btn btn-warning btn-block"
                >Go Home</router-link
              >
            </div>
          </div>
        </template>
      </form>
      <div v-else>
        <div class="form-group text-center pt-4">
          <h4>Registration Completed</h4>
          <!-- <i class="text-danger mdi mdi-cancel ml-1 display-1"></i> -->
          <p class="font-14">
            Congratulations on completing your registration as a Lagos State
            Lotteries Board Enforcer. You can now access the Enforcer mobile app
            with your email and the password you just created
          </p>
        </div>
        <div class="form-group m-b-0 pb-4">
          <div class="col-sm-12 text-center">
            <a
              href="http://www.google.com"
              target="_blank"
              class="btn btn-warning btn-block"
              >Download App</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import store from "@/store";

import {
  isGreaterThanEight,
  containsNumber,
  containsLowerCase,
  containsUpperCase,
  containsSpecialCharacter
} from "@/helpers/customValidations";

export default {
  name: "RegisterView",
  async beforeRouteEnter(to, from, next) {
    try {
      let response = await store.dispatch(
        types.VERIFY_USER_REGISTER_TOKEN,
        to.params.token
      );
      next(vm => {
        if (response.role === "lslb-enforcer") {
          vm.enforcerRole = true;
        }
        vm.email = response.email;
      });
      return;
    } catch (error) {
      if (error && error.data) {
        next(vm => {
          vm.isTokenValid = false;
        });
        return;
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      // initialize dropify
      let drEvent = $(".dropify").dropify({
        messages: {
          default: "Drag and drop a passport image here or click",
          replace: "Drag and drop or click to replace",
          remove: "REMOVE",
          error: "Ooops, something happened."
        }
      });

      drEvent.on("dropify.afterClear", () => {
        this.image = "";
        this.photo = "";
      });
    });
  },
  updated() {
    this.$nextTick(() => {
      // initialize dropify
      let drEvent = $(".dropify").dropify({
        messages: {
          default: "Drag and drop a passport image here or click",
          replace: "Drag and drop or click to replace",
          remove: "REMOVE",
          error: "Ooops, something happened."
        }
      });

      drEvent.on("dropify.afterClear", () => {
        this.image = "";
        this.photo = "";
      });
    });
  },
  data() {
    return {
      isLoading: false,
      enforcerRole: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      mobile: "",
      gender: "",
      photo: "",
      image: "",
      password: "",
      email: "",
      submitSuccess: false,
      isTokenValid: true,
      password_visibility: false
    };
  },
  validations: {
    first_name: {
      required
    },
    middle_name: {},
    last_name: {
      required
    },
    mobile: {
      required,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    gender: {
      required
    },
    image: {
      required
    },
    password: {
      required,
      isGreaterThanEight,
      containsLowerCase,
      containsUpperCase,
      containsNumber,
      containsSpecialCharacter
    }
  },
  methods: {
    async finishUserRegistration() {
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      try {
        this.isLoading = true;
        if (!this.photo) {
          let photo = await this.$store.dispatch(
            types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION,
            {
              file: this.image
            }
          );
          this.photo = photo.location;
        }
        await this.$store.dispatch(types.REGISTER_USER_ACTION, {
          ...this.$data,
          token: this.$route.params.token
        });
        this.$notify.success({ message: "Registration successful" });
        if (this.enforcerRole === true) {
          this.submitSuccess = true;
        } else {
          this.$router.push({ name: "GetStartedView" });
        }
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    setImage(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        this.photo = "";
        this.$v.image.$touch();
      }
    }
  }
};
</script>
