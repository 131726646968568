import * as types from "@/store/types";
import VerificationService from "@/services/verifications";

const actions = {
  async [types.CONFIRM_GUARANTOR_CONSENT_ACTION](_, payload) {
    let response = await VerificationService.confirmGuarantorConsent(payload);
    //commit(types.SET_GUARANTOR_DETAILS, response.data);
    console.log(response.data);
    return response.data;
  },

  async [types.GET_GUARANTOR_DETAILS]({ commit }, payload) {
    let response = await VerificationService.getGuarantorDetails(payload);
    commit(types.SET_GUARANTOR_DETAILS, response.data);
    //console.log(response.data);
    return response.data;
  },

  async [types.UPDATE_GUARANTOR_PERSONAL_INFO](_, payload) {
    let response = await VerificationService.updateGuarantorPersonalInfo(
      payload
    );
    //console.log(response.data);
    return response.data;
  },

  async [types.UPDATE_GUARANTOR_INFORMATION_ACTION_ADMIN]({ commit }, payload) {
    let response = await VerificationService.updateGuarantorPersonalInformationAdmin(
      payload
    );
    commit(types.SET_VERIFICATION_REPORT, response.data);
    return response.data;
  },

  async [types.UPDATE_AGENT_INFORMATION_ACTION_ADMIN]({ commit }, payload) {
    let response = await VerificationService.updateAgentPersonalInformationAdmin(
      payload
    );
    commit(types.SET_VERIFICATION_REPORT, response.data);
    return response.data;
  },

  async [types.UPDATE_GUARANTOR_IDENTITY_INFO](_, payload) {
    console.log(payload);
    let response = await VerificationService.updateGuarantorIdentityInfo(
      payload
    );
    //console.log(response.data);
    return response.data;
  },

  async [types.SUBMIT_GUARANTOR_DETAILS](_, payload) {
    console.log(payload);
    let response = await VerificationService.submitGuarantorDetails(payload);
    //console.log(response.data);
    return response.data;
  }
};

export default actions;
