var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.verified)?_c('div',{staticClass:"no-card w-100 position-relative",staticStyle:{"z-index":"2"}},[_c('div',{staticClass:"card-block"},[_vm._m(0),_c('ul',{staticClass:"nav nav-tabs customtab scrollable bg-light mb-3 d-md-none rounded",attrs:{"role":"tablist"}},[_vm._l((_vm.tabRoutes),function(ref){
var name = ref.name;
var routeName = ref.routeName;
var verificationKey = ref.verificationKey;
return _c('li',{key:name,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link d-flex",attrs:{"role":"tab","to":{ name: routeName },"active-class":"active","aria-expanded":"false"}},[(_vm.guarantor[verificationKey].completed)?_c('i',{staticClass:"mdi mdi-check-circle text-success mr-1"}):_vm._e(),_vm._v(" "+_vm._s(name)+" ")])],1)}),_c('li',{staticClass:"nav-item"},[(!_vm.cannotSubmit)?_c('router-link',{staticClass:"nav-link",class:{ disabled: _vm.cannotSubmit },attrs:{"to":{ name: 'GuarantorPreviewView' },"active-class":"active","role":"tab"}},[_vm._v("Form Preview ")]):_vm._e(),(_vm.cannotSubmit)?_c('a',{staticClass:"nav-link not-allowed disabled text-muted",attrs:{"href":"javascript:void(0)"}},[_vm._v(" Form Preview ")]):_vm._e()],1)],2),_c('div',{staticClass:"vtabs customvtab mx-auto w-100"},[_c('ul',{staticClass:"nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded",attrs:{"role":"tablist"}},[_vm._l((_vm.tabRoutes),function(ref){
var name = ref.name;
var routeName = ref.routeName;
var verificationKey = ref.verificationKey;
return _c('li',{key:name,staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link d-flex",attrs:{"role":"tab","to":{ name: routeName },"active-class":"active","aria-expanded":"false"}},[(_vm.guarantor[verificationKey].completed)?_c('i',{staticClass:"mdi mdi-check-circle text-success mr-1"}):_vm._e(),_vm._v(" "+_vm._s(name)+" ")])],1)}),_c('li',{staticClass:"nav-item"},[(_vm.cannotSubmit)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Please complete the form'),expression:"'Please complete the form'"}],staticClass:"nav-link not-allowed",staticStyle:{"color":"#adadad"}},[_vm._v("Form Preview")]):_vm._e(),(!_vm.cannotSubmit)?_c('router-link',{staticClass:"nav-link d-flex",attrs:{"to":{ name: 'GuarantorPreviewView' },"active-class":"active","role":"tab","aria-expanded":"false"}},[_c('span',[_vm._v("Form Preview")])]):_vm._e()],1)],2),_c('div',{staticClass:"tab-content w-100 p-t-0"},[_c('div',{staticClass:"tab-pane active bg-white rounded py-3 pr-3",attrs:{"role":"tabpanel","aria-expanded":"true"}},[_c('router-view')],1)])])])]):_c('div',{staticClass:"row"},[_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center m-b-10"},[_c('h1',{staticClass:"mt-5",staticStyle:{"color":"#031b4d"}},[_vm._v(" Guarantor Details Form ")]),_c('h6',{staticClass:"card-subtitle text-center"},[_vm._v(" Please complete the form below and submit ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"no-card mb-0"},[_c('div',{staticClass:"card-block"},[_c('div',{staticClass:"py-5 px-2 flex-box d-flex w-100"},[_c('div',{staticClass:"text-center align-self-center m-auto"},[_c('h1',[_vm._v("Token doesn't exist")]),_c('i',{staticClass:"text-danger mdi mdi-cancel ml-1 display-1"}),_c('p',{staticClass:"font-18"},[_vm._v(" Your details have either been saved successfully or token doesn't exist ")]),_c('p',{staticClass:"display-6"},[_vm._v("Thank you.")])])])])])])}]

export { render, staticRenderFns }