// components

import AdminContainer from "@/containers/Admin";

import AdminCouponView from "@/modules/admin/coupon/Index";
import AdminTransactionView from "@/modules/admin/transactions/Index";

export default [
  {
    path: "/admin",
    component: AdminContainer,
    redirect: {
      name: "AdminCouponView"
    },
    children: [
      {
        path: "coupons",
        component: AdminCouponView,
        name: "AdminCouponView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["coupon-view"],
          documentTitle: "Coupons"
        }
      },
      {
        path: "transactions",
        component: AdminTransactionView,
        name: "AdminTransactionView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["transaction-view"],
          documentTitle: "Transactions"
        }
      }
    ]
  }
];
