<template>
  <div class="my-4 px-3">
    <p class="d-flex align-items-center">
      <strong class="mr-1">Upload CSV List.</strong>
      <button
        type="button"
        class="text-warning btn btn-link"
        @click="downloadSampleCSV"
      >
        Download CSV template here
      </button>
    </p>
    <form ref="uploadForm" class="my-4">
      <label for="uploadCsv" class="py-5 d-flex justify-content-center w-100">
        <div class="d-flex flex-column align-items-center">
          <img
            src="/static/bulk-upload-icon.svg"
            alt="bulk upload icon"
            style="width: 5rem"
          />
          <p class="mt-4 font-14">Click to upload a CSV file</p>
          <p v-if="file" class="font-14">{{ file.name }}</p>
        </div>
      </label>
      <input
        id="uploadCsv"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        type="file"
        name="file"
        class="sr-only"
        @change="handleChange"
      />
      <div
        class="my-2 d-flex justify-content-between flex-wrap align-items-center"
        style="gap: 1rem"
      >
        <p class="text-muted font-14">Supported formats: XLS, XLSX, CSV</p>
        <p class="text-muted font-14">Maximum size: 25MB</p>
      </div>
    </form>
    <div v-if="progress" class="d-flex align-items-center" style="gap: 0.5rem">
      <div class="progress" style="flex-grow: 1; height: 0.75rem">
        <div
          class="progress-bar bg-warning"
          role="progressbar"
          :style="{ width: progress }"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p class="text-muted font-14 mb-0">{{ progress }}%</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BulkUpload",
  props: {
    action: {
      type: String,
      default: ""
    },
    uploadProgress: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      file: null,
      downloading: false,
      progress: this.uploadProgress
    };
  },
  beforeDestroy() {
    this.file = null;
  },
  methods: {
    async downloadSampleCSV() {
      try {
        this.downloading = true;
        const res = await this.$store.dispatch(this.action);
        const link = res.data;
        link && this.downloadFile(link);
      } catch (error) {
        const message =
          "An error occured trying to download a sample template, please try again";
        const err =
          error.response.status > 500
            ? message
            : error.response.message || message;
        this.$toasted.error(err);
      } finally {
        this.downloading = false;
      }
    },
    downloadFile(url) {
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "template.csv");
      a.click();
    },
    validateImage(image) {
      const validTypes = ["application/csv", "application/xslx"];

      if (validTypes.every(type => image.type !== type)) {
        this.error = `Only .xslx, .csv file types are allowed`;
      } else if (image.size > 1024 * 1024 * 25) {
        this.error = `Image must be less than 25mb`;
      }
      return;
    },
    handleChange(e) {
      const file = e.target.files.item(0);
      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          this.file = file;
        });
        reader.readAsDataURL(file);
      }

      this.$emit("upload-csv", file);
    }
  }
};
</script>
<style scoped>
label {
  background-color: #fffbf3;
  border: 1px dotted #f5b600;
  border-radius: 0.5rem;
}
</style>
