<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <div class="row" v-if="$User.hasPermission('agent-search')">
        <div class="col-xs-12 col-sm-6 m-b-10 offset-sm-6 col-md-4 offset-md-8">
          <div class="d-flex align-items-center">
            <div class="form-group m-0 flex-grow">
              <input
                type="text"
                class="form-control rounded-0"
                placeholder="Search using name, email address or phone number"
                v-model.trim="search_term"
                @input="searchAgents"
              />
            </div>
            <router-link
              tag="button"
              v-if="
                $User.hasPermission('user-add') &&
                  profile.user_type === 'company'
              "
              class="btn btn-warning ml-2"
              :to="{
                name: 'AgentsInvite'
              }"
            >
              <i class="mr-1 mdi mdi-account-plus"></i>
              Invite Agent
            </router-link>
          </div>
          <p
            class="text-danger font-14 font-bold mt-1"
            v-if="isSearching && agents.length === 0"
          >
            No agents found for '{{ $route.query.search_term }}'
          </p>
        </div>
      </div>
      <form id="filter-report-form" class="filter-report-form">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="status" class="control-label">
                Status
                <span class="text-danger">*</span>
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="status"
                :class="{ 'form-error animated shake': $v.status.$error }"
              >
                <option value disabled>-- select status --</option>
                <option value="all">All</option>
                <option value="invited">Invited</option>
                <option value="verified">Verified</option>
                <option value="not_verified">Not Verified</option>
                <option value="pending">Pending</option>
                <option value="incomplete">Incomplete</option>
                <option value="completed">Completed</option>
                <option value="guarantor_outstanding"
                  >Guarantor Outstanding</option
                >
              </select>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label
                for="start_date"
                class="control-label"
                style="display:block"
              >
                Start Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <div class="clearfix">
                <flat-pickr
                  v-model.trim="start_date"
                  :class="{ 'form-error animated shake': $v.start_date.$error }"
                  class="form-control rounded-0"
                  :config="startDateConfig"
                  placeholder="Start Date"
                ></flat-pickr>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="end_date" class="control-label" style="display:block">
                End Date
                <!-- <span class="text-danger">*</span> -->
              </label>
              <!-- <div
                class="clearfix"
                v-tooltip="!start_date ? 'Please select a start date' : ''"
              > -->
              <flat-pickr
                v-model.trim="end_date"
                :class="{ 'form-error animated shake': $v.end_date.$error }"
                class="form-control rounded-0"
                :config="endDateConfig"
                placeholder="End Date"
              ></flat-pickr>
              <!-- </div> -->
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="status" class="control-label">
                LGA/LCDA
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="region"
                :class="{ 'form-error animated shake': $v.region.$error }"
              >
                <option value="" disabled>-- select LGA/LCDA --</option>
                <option value="all">All</option>
                <option
                  v-for="region in regions"
                  :key="region"
                  :value="region"
                  >{{ region }}</option
                >
              </select>
            </div>
          </div>
          <div v-if="profile.user_type !== 'company'" class="col-md-3">
            <div class="form-group m-b-5">
              <label for="operator_id" class="control-label">
                Operator
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="operator_id"
                :class="{ 'form-error animated shake': $v.operator_id.$error }"
              >
                <option value="" disabled>-- select Operator --</option>
                <option value="all">All</option>
                <option
                  v-for="operator in businesses"
                  :key="operator.id"
                  :value="operator.id"
                  >{{ operator.trade_name || operator.business_name }}</option
                >
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group m-b-5">
              <label for="permit_type" class="control-label">
                Permit Type
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="permit_type"
                :class="{ 'form-error animated shake': $v.permit_type.$error }"
              >
                <option value="" disabled>-- select Permit type --</option>
                <option value="all">All</option>
                <option
                  v-for="permit in business_license_categories"
                  :key="permit.category_slug"
                  :value="permit.category_slug"
                  >{{ permit.category_name }}</option
                >
              </select>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group m-b-5 float-right">
              <label class="control-label" style="display:block">&nbsp;</label>
              <button
                class="btn btn-warning btn-sm normalize-filter-btn rounded-0 m-r-5"
                :disabled="isLoading || $v.$invalid"
                @click.prevent="filterAgentLogs"
              >
                <i class="mdi mdi-filter"></i>
                Filter
              </button>
              <span
                v-tooltip="'CSV will be sent to your email address'"
                v-if="$User.hasPermission('agent-export')"
              >
                <button
                  :disabled="isLoading || $v.$invalid"
                  @click.prevent="filterAgentLogs({ exportCsv: true })"
                  class="btn btn-warning btn-sm normalize-filter-btn rounded-0"
                >
                  <i class="mdi mdi-cloud-download"></i>
                  Export CSV
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-md-12">
          <template v-if="!isSearching">
            <div class="p-t-10 p-b-10" v-if="isFilter">
              <label class="text-info font-normal font-14">
                Agent log filtered by
                <span
                  class="badge badge-pill badge-default mx-1"
                  v-if="$route.query.status"
                  ><strong>Status: </strong
                  >{{ $route.query.status | remove_underscore }}</span
                ><span
                  class="badge badge-pill badge-default mx-1"
                  v-if="$route.query.region"
                  ><strong>Region: </strong>{{ $route.query.region }}</span
                ><span v-if="$route.query.start_date || $route.query.end_date">
                  between {{ startDateDefault | date }} and
                  {{ endDateDefault | date }}
                </span>
                <span
                  class="badge badge-pill badge-default mx-1"
                  v-if="$route.query.operator_id"
                  ><strong>Operator: </strong
                  >{{
                    getOperatorById($route.query.operator_id) &&
                      getOperatorById($route.query.operator_id).trade_name
                  }}</span
                >
                <span
                  class="badge badge-pill badge-default mx-1"
                  v-if="$route.query.permit_type"
                  ><strong>Operator: </strong
                  >{{
                    getPermitBySlug($route.query.permit_type) &&
                      getPermitBySlug($route.query.permit_type).category_name
                  }}</span
                >
              </label>
              <button
                @click="clearFilter"
                class="ml-3 btn btn-outline-info btn-xs font-bold"
              >
                clear filter
              </button>
            </div>
            <div class="p-t-10 p-b-10" v-if="!isFilter">
              <label class="text-info font-medium font-14"
                >Showing all agents</label
              >
            </div>
          </template>
          <template v-else>
            <div class="p-t-10 p-b-10">
              <label class="text-info font-medium font-14">
                <span class="p-r-10"
                  >Showing all agents by search term "{{
                    $route.query.search_term || ""
                  }}"</span
                >
                <button
                  @click="clearFilter"
                  class="p-l-5 btn btn-outline-info btn-xs font-bold"
                >
                  clear search
                </button>
              </label>
            </div>
          </template>
          <div class="table-responsive">
            <template v-if="agents.length > 0">
              <table class="table table-hover">
                <thead>
                  <tr class="link-row">
                    <th width="70" class="text-center">#</th>
                    <th>Status</th>
                    <th class="text-nowrap">Email Address</th>
                    <th class="text-nowrap">Name</th>
                    <th class="text-nowrap">Permit ID</th>
                    <th class="text-nowrap">Phone Number</th>
                    <!--                    <th class="text-nowrap">Region</th>-->
                    <th class="text-nowrap">Created At</th>
                    <th class="text-nowrap">Modified At</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="" v-for="(agent, index) in agents" :key="agent.id">
                    <template v-if="profile.user_type !== 'company'">
                      <td class="text-center">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          {{ pagination.from + index }}
                        </router-link>
                      </td>
                      <td v-tooltip="statusMeanings[agent.status]">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          <span
                            class="label font-bold text-capitalize rounded-0 text-nowrap"
                            :class="[
                              agent.status === 'verified'
                                ? 'label-light-success'
                                : agent.status === 'not_verified'
                                ? 'label-light-danger'
                                : agent.status === 'awaiting_acceptance'
                                ? 'label-light-info'
                                : agent.status === 'pending'
                                ? 'label-light-warning'
                                : agent.status === 'incomplete'
                                ? 'label-light-primary'
                                : 'label-light-warning'
                            ]"
                            >{{ agent.status | remove_underscore }}</span
                          >
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                          >{{
                            agent.email.value || "Not available"
                          }}</router-link
                        >
                      </td>
                      <td class="text-capitalize">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          <template v-if="agent.last_name.value"
                            >{{ agent.first_name }}
                            {{ agent.middle_name ? agent.middle_name + " " : ""
                            }}{{ agent.last_name.value }}</template
                          >
                          <template v-else>Not available</template>
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                          >{{
                            agent.verification.lslb_license_number ||
                              "Not available"
                          }}</router-link
                        >
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                          >{{
                            agent.mobile.value || "Not available"
                          }}</router-link
                        >
                      </td>
                      <td class="text-capitalize">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          {{ agent.created_at | long_date }}
                        </router-link>
                      </td>
                      <td class="text-capitalize">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          {{ agent.updated_at | long_date }}
                        </router-link>
                      </td>
                    </template>

                    <template v-else>
                      <td class="text-center">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          {{ pagination.from + index }}
                        </router-link>
                      </td>
                      <td
                        v-if="getAgentBusiness(agent)"
                        v-tooltip="
                          statusMeanings[getAgentBusiness(agent).status]
                        "
                      >
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          <span
                            class="label font-bold text-capitalize rounded-0 text-nowrap"
                            :class="[
                              getAgentBusiness(agent).status === 'active'
                                ? 'label-light-success'
                                : getAgentBusiness(agent).status === 'revoked'
                                ? 'label-light-danger'
                                : getAgentBusiness(agent).status ===
                                  'awaiting_acceptance'
                                ? 'label-light-info'
                                : getAgentBusiness(agent).status === 'pending'
                                ? 'label-light-warning'
                                : getAgentBusiness(agent).status ===
                                  'incomplete'
                                ? 'label-light-primary'
                                : 'label-light-warning'
                            ]"
                            >{{
                              getAgentBusiness(agent).status | remove_underscore
                            }}</span
                          >
                        </router-link>
                        <!-- <template v-else>
                          <span
                            class="label font-bold text-capitalize rounded-0 text-nowrap"
                            :class="[
                              getAgentBusiness(agent).status === 'active'
                                ? 'label-light-success'
                                : getAgentBusiness(agent).status === 'revoked'
                                ? 'label-light-danger'
                                : getAgentBusiness(agent).status ===
                                  'awaiting_acceptance'
                                ? 'label-light-info'
                                : getAgentBusiness(agent).status === 'pending'
                                ? 'label-light-warning'
                                : getAgentBusiness(agent).status ===
                                  'incomplete'
                                ? 'label-light-primary'
                                : 'label-light-warning'
                            ]"
                            >{{
                              getAgentBusiness(agent).status | remove_underscore
                            }}</span
                          >
                        </template> -->
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                          >{{
                            agent.email.value || "Not available"
                          }}</router-link
                        >
                      </td>
                      <td class="text-capitalize">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          <template v-if="agent.last_name.value"
                            >{{ agent.first_name }}
                            {{ agent.middle_name ? agent.middle_name + " " : ""
                            }}{{ agent.last_name.value }}</template
                          >
                          <template v-else>Not available</template>
                        </router-link>
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                          >{{
                            agent.verification.lslb_license_number ||
                              "Not available"
                          }}</router-link
                        >
                      </td>
                      <td>
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                          >{{
                            agent.mobile.value || "Not available"
                          }}</router-link
                        >
                      </td>
                      <td class="text-capitalize">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          {{ agent.created_at | long_date }}
                        </router-link>
                      </td>
                      <td class="text-capitalize">
                        <router-link
                          :to="{
                            name: 'AgentView',
                            params: {
                              agent_id: agent.id
                            }
                          }"
                        >
                          {{ agent.updated_at | long_date }}
                        </router-link>
                      </td>
                      <td class="d-flex">
                        <template
                          v-if="
                            getAgentBusiness(agent) &&
                              getAgentBusiness(agent).status === 'pending' &&
                              profile.user_type === 'company'
                          "
                        >
                          <button
                            :disabled="isLoading"
                            @click="
                              showResolveAgentApplicationDialog(agent.id, true)
                            "
                            class="btn btn-success btn-xs"
                          >
                            Accept
                          </button>
                          <button
                            :disabled="isLoading"
                            @click="
                              showResolveAgentApplicationDialog(agent.id, false)
                            "
                            class="btn btn-danger btn-xs ml-1"
                          >
                            Reject
                          </button>
                        </template>
                        <template
                          v-if="
                            getAgentBusiness(agent) &&
                              getAgentBusiness(agent).status ===
                                'awaiting_dissociation' &&
                              profile.user_type === 'company'
                          "
                        >
                          <button
                            :disabled="isLoading"
                            @click="
                              showResolveAgentDissociationDialog(agent.id, true)
                            "
                            class="btn btn-success btn-xs"
                          >
                            Approve Dissociation
                          </button>
                          <button
                            :disabled="isLoading"
                            @click="
                              showResolveAgentDissociationDialog(
                                agent.id,
                                false
                              )
                            "
                            class="btn btn-danger btn-xs ml-1"
                          >
                            Reject Dissociation
                          </button>
                        </template>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
              <div class="p-30 test font-medium text-muted">
                <span
                  >Showing {{ pagination.from }} to {{ pagination.to }} of
                  {{ pagination.total }} entries</span
                >
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                <img
                  src="/static/empty.svg"
                  class="img-fluid mb-3"
                  alt="empty"
                  style="width: 50px;"
                />
                <p class="font-bold font-14">There are no agents to display</p>
              </div>
            </template>
          </div>
          <div class="text-center p-l-30 m-t-20">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import debounce from "@/helpers/debounce";
import eventBus from "@/helpers/event";
import api from "../_api/index";
import notify from "@/helpers/notifyMe";
import statusMeanings from "@/helpers/statusMeanings";
import store from "@/store";

export default {
  name: "AgentLogView",

  async beforeRouteEnter(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        start_date = moment("2020-12-03T10:10:42.266Z").format("YYYY-MM-DD"),
        end_date = moment().format("YYYY-MM-DD"),
        region,
        operator_id,
        permit_type
      } = to.query;
      if (search_term) {
        await Promise.all([
          store.dispatch(types.SEARCH_AGENTS_ACTION, {
            query: search_term,
            paginate: 1,
            page
          }),
          store.dispatch(types.GET_VERIFICATION_REGIONS),
          store.dispatch(types.GET_ALL_BUSINESSES),
          store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES)
        ]);
      } else {
        await Promise.all([
          store.dispatch(types.GET_AGENTS_ACTION, {
            page,
            status,
            start_date,
            end_date,
            ...(region && { region }),
            ...(operator_id && { operator_id }),
            ...(permit_type && { permit_type })
          }),
          store.dispatch(types.GET_VERIFICATION_REGIONS),
          store.dispatch(types.GET_ALL_BUSINESSES),
          store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES)
        ]);
      }
      return next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      return next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        start_date = moment("2020-12-03T10:10:42.266Z").format("YYYY-MM-DD"),
        end_date = moment().format("YYYY-MM-DD"),
        region,
        operator_id,
        permit_type
      } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_AGENTS_ACTION, {
          query: search_term,
          paginate: 1,
          page
        });
      } else {
        await store.dispatch(types.GET_AGENTS_ACTION, {
          page,
          status,
          start_date,
          end_date,
          ...(region && { region }),
          ...(operator_id && { operator_id }),
          ...(permit_type && { permit_type })
        });
      }
      return next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      return next(false);
    }
  },
  mounted() {
    eventBus.$on("lslb:agent:refresh-agents-list", () => {
      this.getLogByPage(this.pagination.current_page);
    });
  },
  created() {
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment("2020-12-03T10:10:42.266Z").format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      statusMeanings,
      csvError: [],
      operator_id: this.$route.query.operator_id || "",
      permit_type: this.$route.query.permit_type || "",
      status: this.$route.query.status || "all",
      start_date: this.$route.query.start_date || "",
      end_date: this.$route.query.end_date || "",
      region: this.$route.query.region || "",
      search_term: this.$route.query.search_term || "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      isLoading: false,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      addAgentInviteType: "single",
      csvFile: null
    };
  },
  validations: {
    status: { required },
    start_date: {},
    end_date: {},
    region: {},
    operator_id: {},
    permit_type: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      agents: "getAgentsLog",
      pagination: "getAgentsLogPagination",
      business_profile: "getBusinessProfile",
      regions: "getRequestVerificationRegions",
      businesses: "getAllBusinesses",
      business_license_categories: "getBusinessLicenseCategories",
      profile: "getCurrentUser"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment("2020-12-03T10:10:42.266Z").format("YYYY-MM-DD")
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return (
        this.$route.query.status ||
        this.$route.query.region ||
        this.$route.query.operator_id ||
        this.$route.query.permit_type ||
        this.$route.query.start_date ||
        this.$route.query.end_date
      );
    },
    isSearching() {
      return this.$route.query.search_term;
    }
  },
  methods: {
    go() {
      this.getLogByPage(this.pagination.current_page);
    },
    getAgentBusiness(agent) {
      if (agent.agent_businesses && agent.agent_businesses.length) {
        return agent.agent_businesses.filter(agentBusiness => {
          if (agentBusiness.business) {
            if (this.isSearching)
              return agentBusiness.business.id === this.profile.business.id;
            return agentBusiness.business === this.profile.business.id;
          }
        })[0];
      }
    },
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterAgentLogs(options = {}) {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let {
            start_date,
            end_date,
            status,
            region,
            operator_id,
            permit_type
          } = this;
          let { exportCsv } = options;

          let query = {
            ...(region && region !== "all" && { region }),
            ...(operator_id && operator_id !== "all" && { operator_id }),
            ...(permit_type && permit_type !== "all" && { permit_type })
          };

          if (exportCsv) {
            let response = await this.$store.dispatch(
              types.EXPORT_AGENTS_ACTION,
              {
                ...query,
                status,
                start_date: start_date || this.startDateDefault,
                end_date: end_date || this.endDateDefault
              }
            );
            this.$notify.success({
              message:
                response.success && status === "guarantor_outstanding"
                  ? "Agent export request sent successfully!"
                  : response.message
            });
          } else {
            this.$router.push({
              name: this.$route.name,
              query: {
                ...(status && status !== "all" && { status }),
                ...(start_date && { start_date }),
                ...(end_date && { end_date }),
                ...query
              }
            });
          }
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      let {
        status,
        start_date,
        end_date,
        region,
        operator_id,
        permit_type,
        search_term
      } = this.$route.query;

      let query = {
        ...(search_term && { search_term }),
        ...(status && { status }),
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date }),
        ...(region && { region }),
        ...(operator_id && { operator_id }),
        ...(permit_type && { permit_type }),
        // trigger route update
        [Date.now()]: null
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.status = "all";
        this.start_date = "";
        this.end_date = "";
        this.region = "";
        this.search_term = "";
        this.operator_id = "";
        this.permit_type = "";
        this.$v.$reset();
      });
    },
    searchAgents: debounce(function(e) {
      if (e.target.value.trim()) {
        this.$router.push({
          name: this.$route.name,
          query: {
            search_term: e.target.value.trim()
          }
        });
      }
    }, 750),
    showResolveAgentApplicationDialog(agent_id, accept) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to ${
          accept ? "accept" : "reject"
        } an association request to this agent.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, please ${
          accept ? "accept" : "reject"
        } request.`,
        cancelButtonText: "No, cancel!"
      }).then(result => {
        if (result.value) {
          this.resolveAgentApplication(agent_id, accept);
        }
      });
    },
    showResolveAgentDissociationDialog(agent_id, accept) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to ${
          accept ? "accept" : "reject"
        } a dissociation request to this agent.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, please ${
          accept ? "accept" : "reject"
        } request.`,
        cancelButtonText: "No, cancel!"
      }).then(result => {
        if (result.value) {
          this.resolveAgentDissociation(agent_id, accept);
        }
      });
    },

    async resolveAgentDissociation(agent_id, accepted) {
      this.isLoading = true;
      try {
        await api.resolveAgentDissociation({ agent_id, accepted });
        eventBus.$emit("lslb:agent:refresh-agents-list");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async resolveAgentApplication(agent_id, accepted) {
      this.isLoading = true;
      try {
        await api.resolveAgentApplication({ agent_id, accepted });
        eventBus.$emit("lslb:agent:refresh-agents-list");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },

    getOperatorById(id) {
      return this.businesses.filter(business => business.id === id)[0];
    },

    getPermitBySlug(slug) {
      return this.business_license_categories.filter(
        permit => permit.category_slug === slug
      )[0];
    }
  }
};
</script>

<style scoped>
.nav-item {
  cursor: pointer;
}

.nav-link.active {
  border-bottom-color: #f4b700 !important;
}

.nav-item:hover {
  border: none;
}

.step-circle {
  border: 1px solid black;
  border-radius: 50%;
  width: 74px;
  padding-top: 30px;
  height: 74px;
}

.step-info {
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;
}

.step-title {
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #ffb42e;
}

.download-btn {
  border: 1px solid #404e5d;
  border-radius: 5px;
  background-color: white;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.2rem;
  color: #404e5d;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.upload-btn {
  border: 1px solid #404e5d;
  border-radius: 5px;
  background-color: #4c963f;
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.2rem;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.step-info-upload {
  border: 1px dashed #404e5d;
  border-radius: 5px;
  background: rgba(235, 235, 235, 0.5);
}
</style>
