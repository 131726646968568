import * as types from "@/store/types";
import VerificationService from "@/services/verifications";

const actions = {
  async [types.REQUEST_VERIFICATION_ACTION]({ commit }, payload) {
    let verification = await VerificationService.requestVerification(payload);
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.GET_REQUEST_VERIFICATION_ACTION](
    { commit },
    { verification_id }
  ) {
    let verification = await VerificationService.getRequestVerification(
      verification_id
    );
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    commit(types.SET_VERIFICATION_PRICE, verification.data.base_price_in_kobo);
    return verification.data;
  },
  async [types.GET_REQUEST_VERIFICATION_TRANSACTIONS_ACTION](
    { commit },
    { verification_id }
  ) {
    let transactions = await VerificationService.getVerificationTransactions(
      verification_id
    );
    commit(types.SET_REQUEST_VERIFICATION_TRANSACTION_LOG, transactions.data);
    return transactions.data;
  },
  async [types.UPDATE_PERSONAL_INFORMATION_ACTION]({ commit }, payload) {
    let verification = await VerificationService.updatePersonalInformation(
      payload
    );
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },

  async [types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION](_, payload) {
    let passport = await VerificationService.uploadPersonalInformationImage(
      payload.file
    );
    return passport.data;
  },

  async [types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION_WITH_DETECTION](
    _,
    payload
  ) {
    let passport = await VerificationService.uploadPersonalInformationImageWithDetection(
      payload.file,
      payload.detectFace
    );
    return passport.data;
  },
  async [types.UPDATE_IDENTITY_INFO_ACTION]({ commit }, payload) {
    let verification = await VerificationService.updateIdentityInfo(payload);
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.UPDATE_LICENSE_INFO_ACTION]({ commit }, payload) {
    let verification = await VerificationService.updateLicenseInfo(payload);
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.UPDATE_AGENT_ADDRESS_ACTION]({ commit }, payload) {
    let verification = await VerificationService.updateAgentAddress(payload);
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.UPDATE_AGENT_RESIDENTIAL_ADDRESS_ACTION]({ commit }, payload) {
    let verification = await VerificationService.updateResidentialAgentAddress(
      payload
    );
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.UPDATE_GUARANTOR_INFORMATION_ACTION]({ commit }, payload) {
    let verification = await VerificationService.updateGuarantorInformation(
      payload
    );
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.UPDATE_AND_SUBMIT_GUARANTOR_INFORMATION_ACTION](
    { commit },
    payload
  ) {
    let verification = await VerificationService.updateAndSubmitGuarantorInformation(
      payload
    );
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.INITIATE_VERIFICATION_PAYMENT_ACTION](context, payload) {
    let payment = await VerificationService.initiateVerificationPayment(
      payload
    );
    return payment.data;
  },
  async [types.VERIFY_VERIFICATION_PAYMENT_ACTION](_, payload) {
    return new Promise((resolve, reject) => {
      VerificationService.verifyVerificationPayment(payload)
        .then(transaction => {
          resolve(transaction.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  async [types.SUBMIT_REQUEST_VERIFICATION_FORM_ACTION]({ commit }, payload) {
    let verification = await VerificationService.submitVerificationForm(
      payload
    );
    commit(types.SET_REQUEST_VERIFICATION, verification.data);
    return verification.data;
  },
  async [types.VERIFY_EMAIL_ADDRESS_ACTION](_, token) {
    let response = (
      await VerificationService.verifyRequestVerificationEmail(token)
    ).data;
    return response;
  },
  async [types.GET_VERIFICATION_PRICE]({ commit }) {
    let response = (await VerificationService.getVerificationPrice()).data;
    commit(types.SET_VERIFICATION_PRICE, response.base_price_in_kobo);
    return response;
  },
  async [types.GET_VERIFICATION_REGIONS]({ commit }) {
    let regions = (await VerificationService.getVerificationRegions()).data;
    commit(types.SET_VERIFICATION_REGIONS, regions);
    return regions;
  }
  // async [types.CONFIRM_GUARANTOR_CONSENT_ACTION](_, payload) {
  //   let response = await VerificationService.confirmGuarantorConsent(payload);
  //   return response.data;
  // }
};

export default actions;
