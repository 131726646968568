import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.INVITE_NEW_USER_ACTION](_, payload) {
    let user = (await api.inviteNewUser(payload)).data;
    return user;
  },
  async [types.GET_ALL_USERS_ACTION]({ commit }, payload) {
    let users = await api.getAllUsers(payload);
    commit(types.SET_USERS_LOG, users.data);
    commit(types.SET_USERS_META, users.meta);
    return users;
  },
  async [types.SEARCH_USERS_ACTION]({ commit }, payload) {
    let users = await api.searchAllUsers(payload);
    commit(types.SET_USERS_LOG, users.data);
    commit(types.SET_USERS_META, users.meta);
    return users;
  },
  async [types.CHANGE_USER_ROLE_ACTION](_, payload) {
    let user = await api.changeUserRole(payload);
    return user;
  },
  async [types.REINVITE_USER_ACTION](_, user) {
    let _user = await api.reinviteUser(user);
    return _user;
  },
  async [types.ACTIVATE_USER_ACTION](_, user) {
    let _user = await api.activateUser(user);
    return _user;
  },
  async [types.DEACTIVATE_USER_ACTION](_, user) {
    let _user = await api.deactivateUser(user);
    return _user;
  },
  async [types.ADD_MULTIPLE_USERS_ACTION](_, payload) {
    let response = await api.addMultipleUsers(payload);
    return response;
  }
};
export default actions;
