import http from "@/helpers/http";
// import { myApp } from "@/main";

class Api {
  static get axios() {
    return http;
  }
  static getAgents(payload) {
    if (payload && payload.status) {
      return http.get("/agents", {
        params: payload
      });
    }
    return http.get("/agents", {
      params: { page: payload.page }
    });
  }

  static searchAgents(payload) {
    let params = {
      query: payload.query,
      page: payload.page,
      paginate: payload.paginate
    };
    if (payload.status) {
      params.status = payload.status;
    }
    return http.get("/agents/search", {
      params
    });
  }

  static getAgentProfile(payload) {
    return http.get("/agents/" + payload);
  }

  static exportAgents(payload) {
    const {
      start_date,
      end_date,
      permit_type,
      region,
      status,
      operator_id
    } = payload;

    if (payload.status === "guarantor_outstanding") {
      return http.post("/reports/agent_reports", {
        start_date,
        end_date,
        region,
        permit_type,
        operator_id,
        status,
        send_to_email: true
      });
    } else {
      return http.get("/agents/export", {
        params: payload
      });
    }
  }

  static updateAgentEmail({ agent, email }) {
    return http.post(`/agents/${agent.id}/email`, {
      new_email: email
    });
  }
  static updateAgentDetails(payload) {
    const { id, email, phone } = payload;
    return http.put(`/agents/${id}`, { email, phone });
  }
  static updateAgentReferenceId({ agent, lslb_license_number }) {
    return http.post(`/agents/${agent.id}/reference`, {
      lslb_license_number
    });
  }

  static addAgentComment({ agent_id, comment }) {
    return http.post("/agents/" + agent_id + "/comments", { comment });
  }

  static removeAgentComment({ agent_id, comment }) {
    return http.delete("/agents/" + agent_id + "/comments/" + comment.id);
  }

  static inviteNewAgent(payload) {
    let { email } = payload;
    return http.post("/businesses/request-agent", {
      email
    });
  }

  static requestNewAgent(payload) {
    let { email, phone, first_name, last_name } = payload;
    return http.post("/businesses/agents/invite", {
      email,
      mobile: phone,
      first_name,
      last_name
    });
  }

  static requestBulkNewAgent(payload) {
    let { csv_file_url } = payload;
    return http.post("/businesses/agents/invite-multiple", {
      csv_file_url
    });
  }

  static resolveAgentApplication(payload) {
    const { agent_id, accepted } = payload;
    return http.post("/businesses/requests", {
      agent_id,
      accepted
      //role
    });
  }

  static resolveAgentDissociation(payload) {
    const { agent_id, accepted } = payload;
    return http.post("/businesses/dissociation-request", {
      agent_id,
      accepted
      //role
    });
  }

  static searchAgentByPermitNumber(permit_number) {
    return http.get("/agents/lookup/" + permit_number);
  }

  static resendGuarantorInvite(verification) {
    return http.post(`/verifications/${verification}/guarantor/send-reminder`);
  }

  static resendAgentInvite(agent) {
    return http.post(`/agents/${agent.id}/resend-invite`);
  }

  static retryGuarantorVerification(verification) {
    return http.post(
      `/verifications/${verification}/guarantor/retry-verification`
    );
  }

  static downloadSampleBulKUploadCSV() {
    return http.get(`/agents/onboard/manual/sample-csv`);
  }

  static downloadSampleBulKRenewCSV() {
    return http.get(`/verifications/licenses/manual-renewal/sample-csv`);
  }

  static submitBulKUploadCSV(payload) {
    return http.post(`/agents/onboard/manual/many`, payload);
  }

  static submitBulKRenewCSV(payload) {
    return http.post(`/verifications/licenses/manual-renewal`, payload);
  }

  static singlePermitRenew(payload) {
    const { verification, license } = payload;
    return http.post(
      `/verifications/${verification}/licenses/${license}/manual-renewal`
    );
  }

  static singleAgentOnboard(payload) {
    return http.post(`/agents/onboard/manual/single`, payload);
  }

  static uploadFile(payload) {
    return http.post(`/uploads/image`, payload);
  }
}
export default Api;
