<template>
  <div>
    <div class="py-3 row" style="gap: 1.5rem">
      <div class="col-sm" style="">
        <div class="m-b-10">
          <span class="text-muted font-14">Amount:</span>
        </div>
        <div>
          <span class="font-bold font-16">₦{{ verificationFee / 100 }}</span>
        </div>
      </div>
      <div class="col-sm" style="">
        <div class="m-b-10">
          <span class="text-muted font-14">Permit Type:</span>
        </div>
        <div>
          <span class="font-bold font-16">Gaming / Lottery</span>
        </div>
      </div>
      <div class="col-sm" style="">
        <div class="m-b-10">
          <span class="text-muted font-14">Expiry:</span>
        </div>
        <div class="inline-d-flex align-center">
          <span class="font-bold font-16">{{ formatExpiry }}</span>
        </div>
      </div>
    </div>
    <form class="row m-t-10">
      <div class="col-md-12 col-sm-12 mb-3">
        <div class="form-group m-b-10">
          <div
            class="d-inline-block p-2 mb-2"
            style="border:1px solid rgba(120, 130, 140, 0.13); cursor: pointer"
          >
            <div class="checkbox checkbox-warning w-100">
              <input
                :disabled="is_coupon_valid && is_coupon_valid.status === true"
                id="checkbox-signup"
                type="checkbox"
                v-model.trim="have_coupon"
              />
              <label
                class="no-select form-bold form-label"
                for="checkbox-signup"
                >Have a coupon?</label
              >
            </div>
          </div>
          <template v-if="have_coupon">
            <template
              v-if="!(is_coupon_valid && is_coupon_valid.status === true)"
            >
              <div class="input-group">
                <input
                  class="form-control rounded-0 coupon-field text-uppercase"
                  placeholder="Enter Coupon Code"
                  type="text"
                  v-model.trim="coupon_code"
                />
                <button
                  :disabled="$v.coupon_code.$invalid || isVerifying"
                  @click.prevent="verifyCoupon"
                  class="btn btn-warning input-group-addon rounded-0"
                  v-tooltip="'Apply coupon'"
                >
                  <template v-if="isVerifying">
                    <i class="mdi mdi-loading mdi-spin"></i>
                  </template>
                  <template v-else>VERIFY</template>
                </button>
              </div>
              <p
                class="mt-1 text-danger font-12 font-bold text-capitalize"
                v-if="is_coupon_valid && is_coupon_valid.status === false"
              >
                {{ is_coupon_valid.message }}
              </p>
            </template>
            <template v-else>
              <div class="clearfix">
                <p class="font-16 font-bold float-left">
                  <label class="label label-success font-bold mr-1">
                    {{ coupon.discount_type === "amount" ? "₦" : ""
                    }}{{ coupon.value
                    }}{{ coupon.discount_type === "percent" ? "%" : "" }}
                    off
                  </label>
                  {{ coupon.code }}
                </p>
                <!-- <button
                  @click.prevent="showRemoveCouponModal"
                  class="btn btn-sm btn-outline-danger float-right"
                  v-tooltip="'Remove coupon'"
                >
                  <i class="mdi mdi-close"></i>
                </button>
                <remove-coupon-modal :removeCoupon="removeCoupon"></remove-coupon-modal> -->
              </div>
            </template>
          </template>
        </div>
      </div>
    </form>
    <div class="col-12">
      <button
        class="col-12 px-0 py-2 text-center border-0 my-5"
        style="background-color: #FFB42E; color: white"
        @click="showPaymentOptionModal"
      >
        Make Payment
      </button>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <h3 class="font-bold mb-3" style="color: rgb(3, 27, 77);">
          Renewal History
        </h3>
      </div>
      <template v-if="renewalHistory.length">
        <table class="table table-hover">
          <thead>
            <tr class="link-row">
              <th class="text-center">#</th>
              <th>Date renewed</th>
              <th>Amount (N)</th>
              <th>Date Expired</th>
              <th>Transaction ID</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(license, index) in renewalHistory"
              :key="license.id"
              class="link-row"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td>
                {{ formatDateTime(license.issued) }}
              </td>
              <td>
                {{ license.amount }}
              </td>
              <td>
                {{ formatDateTime(license.expiry) }}
              </td>
              <td>
                {{ license.ref }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="p-30 test font-medium text-muted">
          <span
            >Showing 1 to {{ renewalHistory.length }} of
            {{ renewalHistory.length }} entries</span
          >
        </div>
      </template>
      <div
        v-else
        class="col-md-12 align-items-center justify-content-center d-flex"
        style="min-height: 400px"
      >
        <div class="text-center w-100">
          <img
            src="/static/empty.svg"
            class="img-fluid mb-3"
            alt="empty"
            style="width: 50px;"
          />

          <p class="font-bold font-14">
            Nothing to see here, you’ve not renewed any permit
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import { required } from "vuelidate/lib/validators";
// import RemoveCouponModal from "@/modules/request-verification/_components/RemoveCouponModal.vue";

export default {
  name: "AgentRenewPermit",
  // components: {RemoveCouponModal},
  data() {
    return {
      have_coupon: false,
      verificationFee: 0,
      coupon_code: "",
      coupon: "",
      isPaymentLoading: false,
      transaction_id: "",
      isVerifying: false,
      is_coupon_valid: false,
      isRequerying: null,
      payment: null
    };
  },
  props: {
    agentProfile: Object
  },
  validations: {
    coupon_code: {
      required
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification",
      verificationPrice: "getVerificationPrice"
    }),
    renewalHistory() {
      const transactions = this.agentProfile.transactions;

      return transactions
        .map(transaction => ({
          expiry: new Date(
            new Date(transaction.created_at).getFullYear(),
            11,
            31
          ),
          issued: transaction.created_at,
          amount: Math.round(transaction.amount_in_kobo_approved / 100),
          ref: transaction.txn_reference
        }))
        .slice()
        .reverse();
    },
    formatExpiry() {
      const activeLicense = this.agentProfile.verification.license_types;
      const license = activeLicense[activeLicense.length - 1];
      return this.formatDateTime(license.expiration_date);
    }
  },
  async mounted() {
    try {
      await store.dispatch(types.GET_VERIFICATION_PRICE);
      this.verificationFee = this.verificationPrice;
    } catch (error) {
      error &&
        error.data &&
        notify.error({
          message: error.data.message
        });
    }
  },
  methods: {
    formatDateTime(date = new Date(), config) {
      let options = config;
      if (!options) {
        options = { month: "numeric", day: "numeric", year: "numeric" };
      }
      return new Intl.DateTimeFormat("en-NG", options).format(new Date(date));
    },
    async verifyCoupon() {
      try {
        this.is_coupon_valid = false;
        this.isVerifying = true;
        let response = await this.$store.dispatch(
          types.VERIFY_COUPON_CODE_ACTION,
          {
            coupon_code: this.coupon_code.toUpperCase(),
            verification: this.agentProfile.verification
          }
        );
        this.coupon = response;
        this.verificationFee = response.amount_in_kobo_approved;
        this.is_coupon_valid = {
          status: true
        };
        this.$notify.success({ message: "Coupon applied successfully" });
      } catch (error) {
        if (error && error.data) {
          this.$notify.error({ message: error.data.message });
          this.is_coupon_valid = {
            status: false,
            message: error.data.message
          };
        }
      } finally {
        this.isVerifying = false;
      }
    },
    removeCoupon() {
      this.coupon = "";
      this.coupon_code = "";
      this.have_coupon = false;
      this.isVerifying = false;
      this.is_coupon_valid = false;
      this.verificationFee = this.verificationPrice;
      this.$v.$reset();
    },
    showRemoveCouponModal() {
      $("#remove-coupon-modal")
        .appendTo("body")
        .modal("show");
    },
    async showPaymentOptionModal() {
      const activeLicense = this.agentProfile.verification.license_types;
      const license = activeLicense[activeLicense.length - 1];

      const payload = {
        item_id: this.agentProfile.verification.id,
        license_type: license.license_type._id,
        is_renewal: true,
        payment_mode: "online"
      };

      if (this.coupon_code) {
        payload["coupon_code"] = this.coupon_code.toUpperCase();
      }

      let payment = await this.$store.dispatch(
        types.INITIATE_PERMIT_RENEWAL,
        payload
      );

      this.payment = payment;

      await this.initiatePayment(payment, "online");
    },
    async initiatePayment(payment, payment_mode) {
      if (payment_mode !== "online") return;

      this.isPaymentLoading = true;
      this.transaction_id = payment.id;
      const personal_info = this.agentProfile.verification.personal_info;

      const payStackPayload = {
        email: personal_info.email,
        tx_ref: this.payment.txn_reference,
        amount_in_kobo_approved: this.payment.amount_in_kobo_approved,
        public_key: this.payment.paystack_public_key,
        mobile: personal_info.mobile,
        firstName: personal_info.first_name,
        middleName: personal_info.middle_name,
        lastName: personal_info.last_name
      };

      try {
        await this.payWithPaytack(payStackPayload);
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isPaymentLoading = false;
      }
    },
    async payWithPaytack(payload) {
      const {
        email,
        tx_ref,
        amount_in_kobo_approved,
        mobile,
        public_key,
        firstName,
        middleName,
        lastName
      } = payload;

      let vm = this;
      // eslint-disable-next-line
      let handler = PaystackPop.setup({
        key: public_key,
        email,
        amount: amount_in_kobo_approved,
        currency: "NGN",
        split_code: process.env.VUE_APP_PAYSTACK_SPLIT_CODE,
        ref: tx_ref,
        metadata: {
          custom_fields: [
            {
              display_name: "First Name",
              variable_name: "firstName",
              value: firstName
            },
            {
              display_name: "Middle Name",
              variable_name: "middleName",
              value: middleName
            },
            {
              display_name: "Last Name",
              variable_name: "lastName",
              value: lastName
            },
            {
              display_name: "Phone Number",
              variable_name: "mobile",
              value: mobile
            }
          ]
        },
        callback: async () => {
          try {
            vm.isPaymentLoading = false;
            await vm.verifyTransaction(vm.transaction_id);
          } catch (error) {
            error &&
              error.data &&
              vm.$notify.error({ message: error.data.message });
          } finally {
            vm.isPaymentLoading = false;
          }
        },
        onClose: () => {
          notify.warning({
            message: "Application payment cancelled"
          });
          // vm.refreshTransactionsLog(vm.verification.id);
        }
      });
      handler.openIframe();
    },
    async verifyTransaction(transaction_id) {
      try {
        let transaction = await store.dispatch(
          types.VERIFY_PERMIT_RENEWAL,
          transaction_id
        );
        if (transaction.success) {
          let verification = JSON.parse(JSON.stringify(this.verification));
          verification.paid = true;
          notify.success({
            message: "Application payment successful"
          });
          this.commitVerificationToState(verification);
          return transaction;
        } else {
          notify.warning({
            message:
              "There was an issue with the application payment. Go to transactions to check status."
          });
        }
        // throw new Error("There was an issue with the application payment");
      } catch (error) {
        notify.error({
          message:
            "There was an issue with the application payment. Go to transactions to check status."
        });
        // throw new Error(
        //   "There was an issue verifying the payment, please try again."
        // );
      }
    },
    commitVerificationToState(verification) {
      store.commit(types.SET_REQUEST_VERIFICATION, verification);
    }
  }
};
</script>
