import http from "@/helpers/http";

class api {
  static get axios() {
    return http;
  }
  static generateLinks(payload) {
    let { csv, tags, title } = payload;
    let emails = [];
    tags.forEach(tag => {
      emails.push(tag.text);
    });
    return http.post("/verifications/links/generate", {
      csv_file_path: csv,
      emails,
      title
    });
  }
  static getGeneratedLinks(payload) {
    if (payload && payload.status) {
      return http.get("/verifications/links", {
        params: { ...payload, limit: 20 }
      });
    }
    return http.get("/verifications/links", {
      params: { page: payload.page, limit: 20 }
    });
  }
  static downloadGeneratedLinks(generated_link) {
    return http.get("/verifications/links/" + generated_link.id + "/download");
  }
}

export default api;
