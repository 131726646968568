import * as types from "@/store/types";

const mutations = {
  async [types.SET_REQUEST_VERIFICATION](state, payload) {
    state.request_verification = payload;
  },

  async [types.SET_REQUEST_VERIFICATION_TRANSACTION_LOG](state, payload) {
    state.request_verification_transactions = payload;
  },
  async [types.SET_VERIFICATION_PRICE](state, payload) {
    state.request_verification_price = payload;
  },
  async [types.SET_VERIFICATION_REGIONS](state, payload) {
    state.request_verification_regions = payload;
  },
  async [types.SET_REQUEST_VERIFICATION_PARTIAL](state, payload) {
    state.request_verification = { ...state.request_verification, ...payload };
  }
};

export default mutations;
