<template>
  <div class="card bordered-box">
    <div class="card-block">
      <h3 class="card-title">Search Coupon by Agent</h3>
      <form class="row m-b-10" @submit.prevent="searchCouponByReference">
        <div class="col-md-6">
          <div class="form-group m-b-10">
            <div class="input-group">
              <v-select
                v-model.trim="agent"
                label="full_name_id"
                :options="agents"
                :filterable="false"
                @search="fetchAgents"
                placeholder="Type to search for agents..."
                class="w-100"
              >
                <template slot="no-options"
                  >Type to search for agents...</template
                >
              </v-select>
              <button
                class="btn btn-warning input-group-addon"
                type="submit"
                :disabled="$v.$invalid || isLoading"
              >
                Search
                <i
                  :class="
                    isLoading ? 'mdi mdi-loading mdi-spin' : 'mdi mdi-magnify'
                  "
                ></i>
              </button>
            </div>
            <h3 class="card-title mt-5" v-if="error">{{ error }}</h3>
          </div>
        </div>
      </form>
      <div class="row" v-if="agent_coupons">
        <div class="col-md-12 m-b-10 clearfix">
          <h3 class="card-title float-left">
            Search result for "{{ agent_coupons.email }}"
          </h3>
          <a
            href="javascript:void(0)"
            @click.prevent="agent_coupons = ''"
            class="m-l-5 btn btn-outline-danger btn-xs font-bold"
            >close</a
          >
        </div>
        <div class="col-md-12 m-b-10">
          <div class="d-flex flex-wrap align-items-center">
            <img
              class="img-responsive rounded-circle img-thumbnail font-12 bg-light d-block mr-3 m-b-10"
              :src="[
                agent_coupons.photo ? agent_coupons.photo : '/static/avatar.png'
              ]"
              alt="Agent photo"
              style="width: 100px; height: 100px; object-fit: cover;"
              onerror="this.src='/static/avatar.png'"
            />
            <div>
              <div class="col-xs-12">
                <span class="font-bold">
                  <template v-if="agent_coupons.last_name">{{
                    `${agent_coupons.last_name.toUpperCase()}, ${
                      agent_coupons.first_name
                    } ${agent_coupons.middle_name}`
                  }}</template>
                  <template v-else>Name not available</template>
                </span>
              </div>
              <div class="col-xs-12">
                <span class="text-muted font-14">{{
                  agent_coupons.email || "Email not available"
                }}</span>
              </div>
              <div class="col-xs-12">
                <span class="text-muted font-14">{{
                  agent_coupons.mobile || "Phone number not available"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <h3 class="card-title">Agent Coupons</h3>
          <div class="table-responsive">
            <template v-if="agent_coupons.coupons.length > 0">
              <table class="table table-hover">
                <thead>
                  <tr class="link-row">
                    <th width="70" class="text-center">#</th>
                    <th class="text-center">State</th>
                    <th class="text-center">Coupon</th>
                    <th class="text-center">Used At</th>
                    <th class="text-center text-nowrap">Active Duration</th>
                    <th class="text-center text-nowrap">Created At</th>
                  </tr>
                </thead>
                <tbody class="text-center">
                  <tr
                    v-for="(coupon, index) in agent_coupons.coupons"
                    :key="coupon.id"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>
                      <label
                        class="label font-bold"
                        :class="
                          coupon.is_active
                            ? 'label-light-primary'
                            : 'label-light-danger'
                        "
                        >{{ coupon.is_active ? "active" : "used" }}</label
                      >
                    </td>
                    <td>
                      {{ coupon.coupon.code }}
                      <br />
                      <label class="label label-success"
                        >{{ coupon.coupon.discount_type === "amount" ? "₦" : ""
                        }}{{ coupon.coupon.value
                        }}{{
                          coupon.coupon.discount_type === "percent" ? "%" : ""
                        }}
                        off</label
                      >
                    </td>
                    <td class="text-center">
                      <template v-if="coupon.used_at">{{
                        coupon.used_at | long_date
                      }}</template>
                      <template v-else>-</template>
                    </td>
                    <td class="text-center">
                      {{ coupon.start_date | long_date }}
                      <hr class="w-25" />
                      {{ coupon.end_date | long_date }}
                    </td>
                    <td>{{ coupon.created_at | long_date }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <div class="text-center">
                <img
                  src="/static/empty.svg"
                  class="img-fluid mb-3"
                  alt="empty"
                  style="width: 50px;"
                />
                <p class="font-bold font-14">
                  There are no coupons associated with this agent
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import debounce from "@/helpers/debounce";

export default {
  name: "SearchCouponsByReferenceID",
  data() {
    return {
      agent_coupons: "",
      error: "",
      agents: [],
      agent: "",
      isLoading: false
    };
  },
  validations: {
    agent: {
      required
    }
  },
  methods: {
    async searchCouponByReference() {
      if (this.$v.$invalid || this.isLoading) return false;
      this.isLoading = true;
      this.error = "";
      this.agent_coupons = "";
      try {
        let response = await this.$store.dispatch(
          types.SEARCH_COUPON_BY_REFERENCE,
          this.agent.id
        );
        this.agent = "";
        this.$v.$reset();
        this.agent_coupons = response;
      } catch (error) {
        if (error && error.data) {
          this.error = error.data.message;
          // this.$notify.error({ message: error.data.message });
        }
      } finally {
        this.isLoading = false;
      }
    },
    fetchAgents: debounce(async function(search, loading) {
      this.error = "";
      if (search.trim().length > 1) {
        loading(true);
        this.agents = [];
        try {
          let agents = await this.$store.dispatch(
            types.GET_COUPON_AGENTS_ACTION,
            search
          );
          if (agents.length === 0) {
            this.error = `No agents found for '${search}'`;
            return;
          }
          this.agents = agents.map(agent => {
            agent.full_name_id = `${agent.first_name} ${agent.middle_name} ${
              agent.last_name.value
            } - ${agent.lslb_license_number || agent.email.value}`;
            return agent;
          });
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          loading(false);
        }
      }
    }, 500)
  }
};
</script>
