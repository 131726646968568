<template>
  <div class="position-relative" style="width: 150px; height: 150px;">
    <a
      :href="[photoMeta.url ? photoMeta.url : '/static/avatar.png']"
      class="live-photo-popup"
      v-tooltip.top="'Click to enlarge image'"
    >
      <img
        class="img-responsive rounded-circle font-12 bg-light d-block m-auto"
        :src="[photoMeta.url ? photoMeta.url : '/static/avatar.png']"
        alt="Agent photo"
        style="width: 100%; height: 100%; object-fit: cover;"
        onerror="this.src='/static/avatar.png'"
        :class="[
          photoMeta.verified ? 'verified-border' : 'not-verified-border'
        ]"
      />
    </a>
    <div
      class="image-percentage font-bold text-center"
      :class="[photoMeta.verified ? 'verified-image' : 'not-verified-image']"
      v-if="photoMeta.score !== -1"
    >
      <div
        class="image-percentage-child"
        v-tooltip="'Percentage of image recognition'"
      >
        {{ photoMeta.score }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagePercentage",
  props: ["photoMeta"]
};
</script>

<style></style>
