const getters = {
  getTransactionsLog: state => {
    return state.transactions_log;
  },
  getTransactionsLogPagination: state => {
    return state.transactions_meta && state.transactions_meta.pagination;
  },
  getTransactionsLogMeta: state => {
    return state.transactions_meta;
  },
  getTransaction: state => {
    return state.transaction;
  }
};

export default getters;
