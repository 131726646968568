<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Transactions" />
      <!-- ============================================================== -->
      <!--  component -->
      <!-- ============================================================== -->
      <transaction-log></transaction-log>
    </div>
  </div>
</template>

<script>
import TransactionLog from "@/components/transaction/TransactionLog.vue";
import AppTitle from "@/components/layouts/AppTitle";
import store from "@/store";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";

export default {
  components: {
    TransactionLog,
    AppTitle
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.GET_TRANSACTIONS_ACTION, {
        page: 1
      });
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
    }
  }
};
</script>
