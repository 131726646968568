export default {
  verified: "Application has passed all required checks",
  not_verified: "Application failed required checks",
  incomplete: "Application has not yet been submitted",
  invited:
    "Application invitation has been sent to an agent but it has not yet been completed",
  pending: "Submitted but has not been accepted",
  awaiting_acceptance: "Yet to be accepted",
  active: "Agent is active",
  revoked: "Agent has been revoked"
};
