<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100 position-relative"
    style="z-index: 2;"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 400px;">
      <form class="card-block form-horizontal" @submit.prevent="submitForm">
        <div class="form-group">
          <div class="col-xs-12 text-center">
            <h3>Agent Profile</h3>
          </div>
        </div>
        <transition
          mode="out-in"
          enter-active-class="animated fastest zoomIn"
          leave-active-class="animated fastest zoomOut"
        >
          <div v-if="!showOTPForm" key="email">
            <div class="form-group p-t-15">
              <div class="col-xs-12">
                <input
                  class="form-control rounded-0"
                  type="text"
                  v-model.trim="email"
                  required="required"
                  :class="{
                    'form-error animated shake': $v.email.$error
                  }"
                  @input="$v.email.$touch"
                  placeholder="Enter Email Address"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="col-xs-12">
                <input
                  class="form-control rounded-0"
                  type="text"
                  :class="{ 'form-error animated shake': $v.mobile.$error }"
                  v-model.trim="mobile"
                  @input="$v.mobile.$touch"
                  required="required"
                  placeholder="Phone Number"
                />
              </div>
            </div>
            <div class="form-group text-center m-t-20">
              <div class="col-xs-12">
                <button
                  :disabled="$v.$invalid || isLoading"
                  class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                  type="submit"
                  @click.prevent="requestAgentProfileOTP"
                >
                  Continue
                  <i
                    :class="{
                      'mdi mdi-loading mdi-spin': isLoading,
                      'mdi mdi-arrow-right': !isLoading
                    }"
                  ></i>
                </button>
              </div>
            </div>
          </div>

          <div v-else key="otp">
            <div class="form-group text-center">
              <a
                @click.prevent="showOTPForm = false"
                v-if="showOTPForm"
                class="d-block text-info font-14 font-bold mb-3 text-left"
                style="cursor: pointer;"
              >
                <i class="mdi mdi-arrow-left font-20 align-middle"></i>
                Go Back
              </a>
              <div
                class="alert alert-success-light font-14 text-left rounded-0"
              >
                Please enter the code sent to your email <br />
                &lt; {{ otp_email }} &gt;
              </div>
              <div class="col-xs-12 input-group">
                <input
                  id="agent-profile-otp"
                  class="form-control rounded-0"
                  type="text"
                  :class="{ 'form-error animated shake': $v.otp.$error }"
                  v-model.trim="otp"
                  @input="$v.otp.$touch"
                  required="required"
                  placeholder="Enter OTP"
                  autocomplete="false"
                />
              </div>
            </div>
            <div class="form-group clearfix">
              <a
                class="text-dark font-14 font-bold pointer"
                @click.prevent="resendOTP"
                href="javascript:void(0)"
              >
                <i
                  class="mdi mdi-refresh"
                  :class="{ 'mdi-spin': shouldResend }"
                ></i>
                Resend OTP
              </a>
            </div>
            <div class="form-group text-center m-t-20">
              <div class="col-xs-12">
                <button
                  :disabled="$v.$invalid || isLoading"
                  class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
                  type="submit"
                  @click.prevent="agentProfile"
                >
                  View Profile
                  <i class="mdi mdi-loading mdi-spin" v-if="isLoading"></i>
                </button>
              </div>
            </div>
          </div>
        </transition>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  requiredIf,
  numeric,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";

export default {
  name: "AgentProfileModule",
  data() {
    return {
      email: "",
      mobile: "",
      isLoading: false,
      showOTPForm: false,
      otp: "",
      shouldResend: false,
      otp_email: ""
    };
  },
  validations: {
    email: {
      required,
      email
    },
    mobile: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    otp: {
      required: requiredIf(function() {
        return this.showOTPForm === true;
      }),
      numeric,
      minLength: minLength(8),
      maxLength: maxLength(8)
    }
  },
  methods: {
    agentProfile() {
      let vm = this;
      vm.isLoading = true;
      vm.$store
        .dispatch(types.AGENT_PROFILE_ACTION, vm.$data)
        .then(profile => {
          if (localStorage.getItem("request_deeplink")) {
            vm.$router.push({
              name: "AgentCompanies"
            });
          } else {
            vm.$router.push({
              name: "AgentDashboard",
              params: { profile_id: profile.id }
            });
          }
        })
        .catch(error => {
          error &&
            error.data &&
            notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    },
    requestAgentProfileOTP($event, shouldResend = false) {
      let vm = this;
      vm.isLoading = true;
      vm.$store
        .dispatch(types.REQUEST_AGENT_PROFILE_OTP_ACTION, vm.$data)
        .then(response => {
          vm.otp_email = response.email;
          vm.showOTPForm = true;
          if (shouldResend) {
            notify.success({
              message: "OTP resent successfully!"
            });
            vm.shouldResend = false;
          }
        })
        .catch(error => {
          error &&
            error.data &&
            notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
          this.shouldResend = false;
        });
    },
    resendOTP() {
      this.shouldResend = true;
      this.requestAgentProfileOTP({}, this.shouldResend);
    },
    submitForm() {
      if (this.showOTPForm) {
        this.requestAgentProfileOTP();
      } else {
        this.agentProfile();
      }
    }
  }
};
</script>
