import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  audit_log_events: [],
  audit_log: [],
  audit_meta: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
