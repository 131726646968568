<template>
  <div v-if="verified" class="row mx-auto mb-5" style="max-width: 600px;">
    <div class="col-sm-12">
      <h1 style="color: rgb(3, 27, 77);" class="mt-5 text-center">
        Consent
      </h1>
    </div>
    <div class="col-sm-12">
      <p class="font-14 text-justify">
        By clicking on 'Continue', you are consenting freely for us to use the
        data you will provide to conduct a background check on you. As part of
        this process, we will verify your identity or carry out checks related
        to your identity. we are entrusted with the protection and security of
        the identity data provided to us. In our Privacy Policy, we help you
        understand how we use the information we collect to provide across our
        system and build trust in our system.<br /><br />
        In order to provide the Agent Verification Services, there’s certain
        information we need from you before we begin. Typically, this includes
        the name and contact information of the user as well as any other
        information needed to complete all requested checks. This can include
        your Passport picture, Nigeria Driver's license, National identification
        number and biometric verification number (BVN). If we are verifying an
        identity document, for example, we will also collect the document number
        of that identity document and we will share this information with our
        users and data providers. Users are our representatives and our data
        providers are used to validate the information you have provided to us.
        For example, If you provide your BVN and recent passport picture, our
        data providers will retrieve your passport image from BVN database and
        match it to the picture you have provided to us. Another example, If you
        provide your physical address, we will share it with our address
        verification provider through our data provider and they might send a
        field agent to visit the address to confirm your availability at the
        location.
        <br /><br />
        We also keep logs of how you, users, and data providers interact with
        our Agent Verification Services. This might include timestamps of when
        information is submitted, and information about the device that was used
        to submit that information.
        <br /><br />
        We store and process your data in the European Union where data privacy
        is governed by the General Data Protection Regulation (“GDPR”) in
        compliance with the Nigeria Data Protection Regulation (“NDPR”) of 2019.
        <br /><br />
        In addition to sharing information with you, users, and data providers
        (as described above), We also shares information with external parties
        that are performing tasks on our behalf (including our affiliates), and
        with other companies, organisations, government bodies, and individuals
        outside LSLGA where we have a legitimate legal reason for so doing (for
        example, in connection with any merger or acquisition) or where we have
        been instructed to share the information.
        <br /><br />
        We perform the Agent Verification for a variety of different reasons.
        Those reasons are identified based on requirements from our risk or
        regulatory concerns, and we rely on them to tell us when they no longer
        need us to store the information we’ve collected. Once instructed,
        either through our agreement with the client or through an ad hoc
        request, we delete the information we have collected about agents when
        performing the requested Identity Verification Services. If you would
        like to access a copy of your information, have your information
        deleted, or otherwise exercise control over how your information is
        used, you can log in and delete or download your information freely and
        at will.
        <br /><br />
        For any other information or clarification, you can contact our Data
        Protection Officer at
        <a class="text-warning" href="mailto:privacy@youverify.co"
          >privacy@youverify.co</a
        >
      </p>
    </div>
    <div class="col-sm-6 mb-3">
      <button
        type="button"
        @click.prevent="grantConsent"
        class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
      >
        I Understand, Continue
        <i class="mdi mdi-loading mdi-spin ml-1" v-if="isLoading"></i>
      </button>
    </div>
    <div class="col-sm-6 mb-3">
      <router-link
        tag="button"
        :to="{
          name: 'RequestVerificationView'
        }"
        class="btn btn-danger rounded-0 waves-effect p-10 w-100 p-10"
        >Cancel</router-link
      >
    </div>
  </div>

  <div
    v-else
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 500px;">
      <div class="card-block form-horizontal text-center">
        <template>
          <div class="form-group text-center">
            <h4 class="m-b-20">Token Not Valid</h4>
            <!-- <i class="text-danger mdi mdi-cancel ml-1 display-1"></i> -->
            <p class="font-14">
              Hey there, you're trying to consent with a token that has already
              been used or is invalid.
              <br />
              <br />If you're not sure, please click the link sent to your email
              address.
              <br />
              <br />Thank you.
            </p>
          </div>
          <div class="form-group m-b-0">
            <div class="col-sm-12 text-center">
              <router-link
                :to="{ name: 'HomeView' }"
                class="btn btn-warning btn-block"
                >Go Home</router-link
              >
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";

export default {
  // name: "FormSubmittedSuccessfullyView",
  data: () => ({
    verified: true,
    isLoading: false
  }),
  // async beforeRouteEnter(to, from, next) {
  //   //confirm valid action
  //   // if (!["confim", "reject"].includes(to.params.action)) {
  //   //   return next({ name: "RequestVerificationView" });
  //   // }
  //   try {
  //     await store.dispatch(types.CONFIRM_GUARANTOR_CONSENT_ACTION, {
  //       token: to.params.guarantor_token,
  //       action: to.params.action
  //     });
  //     return next(vm => {
  //       vm.verified = true;
  //     });
  //   } catch (error) {
  //     console.log("error :>> ", error);
  //     return next(vm => {
  //       vm.verified = false;
  //     });
  //   }
  // },

  methods: {
    async grantConsent() {
      try {
        await store.dispatch(types.CONFIRM_GUARANTOR_CONSENT_ACTION, {
          token: this.$route.params.guarantor_token,
          action: this.$route.params.action
        });

        //check if accepted or rejected
        if (this.$route.params.action === "reject") {
          this.$router.push({
            name: "GuarantorFormSubmittedSuccessfullyView",
            params: {
              title: "Guarantor request rejected",
              message: `You have successfully opted out from being guarantor`
            }
          });
          return;
        } else {
          this.$router.push({
            name: "GuarantorContainer",
            params: { guarantor_token: this.$route.params.guarantor_token }
          });
        }
      } catch (error) {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
      }
    }
  },

  computed: {
    ...mapGetters({
      verification: "getRequestVerification"
    })
  }
};
</script>
