<template>
  <div style="width: 100%;">
    <content-loader
      style="width:100%;"
      :height="150"
      :speed="2"
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="12" y="10" rx="5" ry="5" :width="40" height="15" />

      <rect x="12" y="35" rx="5" ry="5" :width="60" height="10" />
      <rect x="82" y="35" rx="5" ry="5" :width="60" height="10" />
      <rect x="152" y="35" rx="5" ry="5" :width="60" height="10" />

      <rect x="12" y="55" rx="5" ry="5" width="95%" height="60" />

      <rect x="12" y="125" rx="5" ry="5" width="95%" height="20" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ContentLoader
  }
};
</script>
