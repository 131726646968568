import * as types from "@/store/types";

const mutations = {
  async [types.SET_IDENTITY_LOG](state, payload) {
    state.identity_log = payload;
  },
  async [types.SET_IDENTITY_LOG_META](state, payload) {
    state.identity_log_meta = payload;
  },
  async [types.SET_IDENTITY_REPORT](state, payload) {
    state.identity_report = payload;
  }
};

export default mutations;
