import http from "@/helpers/http";

class Api {
  static get axios() {
    return http;
  }

  static requestAgentData(payload) {
    let { email, mobile } = payload;
    return http.post("/accounts/data/request", {
      email,
      mobile
    });
  }

  static downloadAgentData(payload) {
    let { email, mobile } = payload;
    return http.get("/accounts/data/download-link", {
      params: {
        email,
        mobile
      }
    });
  }
  static deleteAgentData(payload) {
    let { email, mobile } = payload;
    return http.post("/accounts/data/remove", {
      email,
      mobile
    });
  }

  static requestAgentProfileOTP(payload) {
    let { email, mobile } = payload;
    return http.post("/agents/check/otp", {
      email,
      mobile
    });
  }

  static agentProfile(payload) {
    let { email, mobile, otp } = payload;
    return http.post("/agents/check", {
      email,
      mobile,
      otp
    });
  }

  static agentInitiatePermitRenewal(payload) {
    return http.post("/transactions/initiate/verification", payload);
  }

  static agentVerifyPermitRenewal(id) {
    return http.get(`/transactions/${id}/verify`);
  }

  static searchCompaniesByLicenseCategory(slug, query) {
    return http.get("/businesses", {
      params: {
        category_slug: slug,
        q: query
      }
    });
  }

  static requestJoinBusiness(payload) {
    let { agent_id, business_id } = payload;
    return http.post("/businesses/join", {
      agent_id,
      business_id
    });
  }

  static resolveAgentInvitation(payload) {
    const { business_id, accepted, profile_id } = payload;
    return http.post(`/agents/${profile_id}/business-requests`, {
      business_id,
      accepted
      //role
    });
  }

  static dissociateAgentInvitation(payload) {
    const { business_id, reason, profile_id } = payload;
    return http.post(`/agents/${profile_id}/request-dissociation`, {
      business_id,
      reason
    });
  }

  static dissociateFromAgentInvitation(payload) {
    const { agent_id, reason } = payload;
    return http.post(`/businesses/agents/dissociate`, {
      agent_id,
      reason
    });
  }

  static getNonPrintedApi() {
    return http.get(`/agents?page=1&status=verified&print=false`);
  }
  static updateAgentAddress(payload) {
    const {
      business_id,
      address,
      _id,
      verification_id,
      latitude,
      longitude
    } = payload;
    if (_id === "") {
      return http.put(`/verifications/${verification_id}/shop_address`, {
        business_id,
        latitude,
        longitude,
        address
      });
    } else {
      return http.put(`/verifications/${verification_id}/shop_address`, {
        business_id,
        address,
        latitude,
        longitude,
        _id
      });
    }
  }

  static markNotificationAsRead(payload) {
    const { notification_id, profile_id } = payload;
    return http.put(`/agents/${profile_id}/notifications`, {
      notification_id
    });
  }
  static deleteShopAddress(payload) {
    const { business_id, _id, verification_id } = payload;
    // /:verification_id/businesses/:business_id/shop_addresses/:shop_address
    return http.delete(
      `/verifications/${verification_id}/businesses/${business_id}/shop_addresses/${_id}`
    );
  }
}
export default Api;
