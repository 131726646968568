import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  transactions_log: [],
  transactions_meta: null,
  transaction: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
