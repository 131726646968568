// components
import FAQView from "@/modules/faq/Index";

export default [
  {
    path: "/faq",
    name: "FAQView",
    component: FAQView,
    meta: {
      showProgressBar: true,
      documentTitle: "FAQ",
      plainLayout: true
    }
  }
];
