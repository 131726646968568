<template>
  <div class="row">
    <!-- Column -->
    <div class="col-lg-12">
      <div class="card card-wrapper">
        <h3 class="card-title">Identity Metrics</h3>
        <div class="row px-3 justify-content-between">
          <div class="col-lg-2 col-sm-6 text-center  identity">
            <div class="py-3 row text-center identity-heading">BVN</div>
            <div class="row py-4 flex-column data-wrapper">
              <h2 class="">
                <i-count-up
                  :startVal="0"
                  :endVal="identityMetrics.total_bvn"
                  :decimals="0"
                  :duration="1.5"
                  :options="options"
                  :callback="noop"
                ></i-count-up>
              </h2>
              <h4>Identity Verifications</h4>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6 text-center  identity">
            <div class="py-3 row text-center identity-heading">PASSPORT</div>
            <div class="row py-4 flex-column data-wrapper">
              <h2 class="">
                <i-count-up
                  :startVal="0"
                  :endVal="identityMetrics.total_passport"
                  :decimals="0"
                  :duration="1.5"
                  :options="options"
                  :callback="noop"
                ></i-count-up>
              </h2>
              <h4>Identity Verifications</h4>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6 text-center  identity">
            <div class="py-3 row text-center identity-heading">
              DRIVER’S LICENSE
            </div>
            <div class="row py-4 flex-column data-wrapper">
              <h2 class="">
                <i-count-up
                  :startVal="0"
                  :endVal="identityMetrics.total_drivers_license"
                  :decimals="0"
                  :duration="1.5"
                  :options="options"
                  :callback="noop"
                ></i-count-up>
              </h2>
              <h4>Identity Verifications</h4>
            </div>
          </div>
          <div class="col-lg-2 col-sm-6 text-center  identity">
            <div class="py-3 row text-center identity-heading">NIN</div>
            <div class="row py-4 flex-column data-wrapper">
              <h2 class="">
                <i-count-up
                  :startVal="0"
                  :endVal="identityMetrics.total_nin"
                  :decimals="0"
                  :duration="1.5"
                  :options="options"
                  :callback="noop"
                ></i-count-up>
              </h2>
              <h4>Identity Verifications</h4>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-sm-6 text-center  identity">
            <div class="py-3 row text-center identity-heading">PVC</div>
            <div class="row py-4 flex-column data-wrapper">
              <h2 class="">
                <i-count-up
                  :startVal="0"
                  :endVal="identityMetrics.total_pvc"
                  :decimals="0"
                  :duration="1.5"
                  :options="options"
                  :callback="noop"
                ></i-count-up>
              </h2>
              <h4>Identity Verifications</h4>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import { mapGetters } from "vuex";

export default {
  name: "IdentityMetrics",
  components: { ICountUp },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      }
    };
  },
  computed: {
    ...mapGetters({
      identityMetrics: "getIdentityMetrics"
    })
  },
  methods: {
    noop() {}
  }
};
</script>

<style scoped>
.identity {
  height: 196.51px;
  margin-bottom: 10px;
}

.card-wrapper {
  padding: 1.25rem;
}
.identity-heading {
  background: #f4f4f4;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.data-wrapper {
  background: #f9d79b;
}
.data-wrapper h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}
.data-wrapper h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #000000;
}
</style>
