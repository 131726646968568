<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100 position-relative"
    style="z-index: 2"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 400px">
      <form
        class="card-block form-horizontal"
        @submit.prevent="showRequestVerificationConsent"
      >
        <div class="form-group">
          <div class="col-xs-12 text-center">
            <h3>Register as an Agent</h3>
          </div>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <div class="font-bold form-label mb-2">
              Email Address
              <span class="text-danger">*</span>
            </div>
            <input
              class="form-control rounded-0"
              type="text"
              v-model.trim="email"
              required="required"
              :class="{
                'form-error animated shake': $v.email.$error
              }"
              @input="$v.email.$touch"
              placeholder="Enter Email Address"
            />
            <span class="font-12">
              <span class="font-bold">Note:</span> Please use a valid email
              address
            </span>
          </div>
        </div>
        <div class="form-group">
          <div class="font-bold form-label mb-2">
            Phone Number
            <span class="text-danger">*</span>
          </div>
          <div class="col-xs-12">
            <input
              class="form-control rounded-0"
              type="text"
              :class="{ 'form-error animated shake': $v.mobile.$error }"
              v-model.trim="mobile"
              @input="$v.mobile.$touch"
              required="required"
              placeholder="Enter Phone Number"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="font-bold form-label mb-2">
            Select Permit Category(s)
            <span class="text-danger">*</span>
          </div>
          <ul class="col-xs-12 list-unstyled">
            <li
              class="radio radio-warning mb-2"
              v-for="license_type in license_types"
              :key="license_type.category_slug"
            >
              <input
                :id="('checkbox-' + license_type.category_slug) | slugify"
                type="radio"
                :value="license_type"
                v-model="selected_license_types"
              />
              <label
                class="no-select font-14 font-bold"
                :for="('checkbox-' + license_type.category_slug) | slugify"
              >
                {{ license_type.category_name }}
              </label>
            </li>
          </ul>
        </div>
        <div class="form-group">
          <div class="col-xs-12">
            <div class="font-bold form-label mb-2">
              Select Company
              <span class="text-danger">*</span>
            </div>
            <select
              class="form-control rounded-0"
              :class="{
                'form-error animated shake': $v.selected_company.$error
              }"
              v-model.trim="selected_company"
              @change="$v.selected_company.$touch"
              required="required"
            >
              <option value="">-- select company --</option>
              <option
                v-for="(company, i) in trimmedListOfCompanies"
                :value="company._id"
                :key="`company-${i}`"
              >
                {{ company.trade_name || company.business_name }}
              </option>
              <!--              <option value="first">First</option>-->
              <!--              <option value="second">Second</option>-->
              <!--              <option value="third">Third</option>-->
            </select>
            <span class="font-12">
              <span class="font-bold">Note:</span> You can pick a company you
              would like to be associated with on successful registration
            </span>
          </div>
        </div>
        <div class="form-group text-center m-t-20">
          <div class="col-xs-12">
            <button
              :disabled="$v.$invalid"
              class="btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0"
              type="submit"
              @click.prevent="showRequestVerificationConsent"
            >
              Continue
              <i class="mdi mdi-arrow-right ml-1"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  required,
  numeric,
  minLength,
  maxLength,
  email
} from "vuelidate/lib/validators";
import * as types from "@/store/types";
import store from "@/store";
import notifyMe from "@/helpers/notifyMe";
import http from "@/helpers/http";

export default {
  name: "RequestVerificationModule",
  data() {
    return {
      email: "",
      mobile: "",
      invite_code: "",
      license_types: [],
      selected_license_types: [],
      selected_company: "",
      companies: []
    };
  },
  validations: {
    email: {
      required,
      email
    },
    mobile: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    selected_license_types: {
      required
    },
    selected_company: {
      required
    }
  },

  computed: {
    trimmedListOfCompanies() {
      return this.companies.filter(
        company => company.license_category === this.selected_license_types._id
      );
    }
  },
  watch: {
    selected_license_types() {
      this.selected_company = "";
    }
  },
  methods: {
    async showRequestVerificationConsent() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const agentInfo = {
        personal_info: {
          email: this.email,
          mobile: this.mobile
        },
        license_types: [this.selected_license_types.category_slug],
        agent_business: [this.selected_company]
      };

      if (this.invite_code) {
        agentInfo.invite_code = this.invite_code;
      }
      this.$store.commit(types.SET_REQUEST_VERIFICATION_PARTIAL, agentInfo);
      this.$router.push({ name: "RequestVerificationConsentView" });
    }
  },
  async beforeRouteEnter(to, __, next) {
    const requests = [
      store.dispatch(types.GET_ALL_BUSINESSES),
      store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES)
    ];
    const { query } = to;
    if (query.c) {
      console.log("Code ", query.c);
      // Verify invite code
      requests.push(http.post("/agents/check-invite", { code: query.c }));
    }
    try {
      let [
        businesses,
        businessLicenseCategories,
        invitedAgentResponse
      ] = await Promise.all(requests);
      console.log(businessLicenseCategories);
      //let businessLicenseCategories = await store.dispatch(types.GET_ALL_BUSINESS_LICENSE_CATEGORIES);
      //console.log(businessLicenseCategories);

      const invitedAgent = invitedAgentResponse
        ? invitedAgentResponse.data.agent
        : null;
      let agentLicenseType = null;
      if (invitedAgent) {
        const operator = businesses.find(
          el => el.id == invitedAgent.agent_businesses[0].business
        );
        console.log({ operator });
        agentLicenseType = businessLicenseCategories.find(
          el => el._id === operator.license_category
        );
      }
      console.log({ invitedAgent });
      console.log({ agentLicenseType });
      next(vm => {
        vm.companies = businesses;
        vm.license_types = businessLicenseCategories;
        vm.email = invitedAgent ? invitedAgent.email.value : "";
        vm.mobile = invitedAgent ? invitedAgent.mobile.value : "";
        vm.selected_license_types = agentLicenseType ? agentLicenseType : [];
        vm.selected_company = invitedAgent
          ? invitedAgent.agent_businesses[0].business
          : "";
        vm.invite_code = invitedAgent ? invitedAgent.invite_code : "";
      });
    } catch (error) {
      error?.data?.message && notifyMe.error({ message: error.data.message });
      next(false);
    }
  }
};
</script>
