import * as types from "@/store/types";
const mutations = {
  async [types.SET_VERIFICATIONS_LOG](state, payload) {
    state.verifications_log = payload;
  },
  async [types.SET_VERIFICATIONS_META](state, payload) {
    state.verifications_meta = payload;
  },
  async [types.SET_VERIFICATION_REPORT](state, payload) {
    state.verification_report = payload;
  },
  async [types.SET_INFRACTION_PHOTOS](state, payload) {
    state.infraction_photos = payload;
  }
};

export default mutations;
