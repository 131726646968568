import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  request_verification: {},
  request_verification_transactions: [],
  request_verification_price: "",
  request_verification_regions: []
};

export default {
  state,
  actions,
  getters,
  mutations
};
