<template>
  <div class="container-fluid">
    <app-title title="Audit Log"></app-title>
    <div class="row m-t-15">
      <div class="col-md-12">
        <div class="card bordered-box pagination-center">
          <div class="card-block">
            <form class="filter-report-form">
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group m-b-5">
                    <label class="control-label" for="user">Select User</label>
                    <v-select
                      id="user"
                      v-model.trim="user"
                      @search="searchUsers"
                      :options="users"
                      :filterable="false"
                      label="full_name_email"
                      placeholder="Type to search for users..."
                    >
                      <template slot="no-options"
                        >Type to search for users...</template
                      >
                    </v-select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group m-b-5">
                    <label class="control-label" for="event">
                      Event
                      <span class="text-danger">*</span>
                    </label>
                    <select
                      id="event"
                      class="form-control custom-select rounded-0"
                      v-model.trim="event"
                      :class="{ 'form-error animated shake': $v.event.$error }"
                    >
                      <option value disabled>-- select event --</option>
                      <option value="all">All</option>
                      <option
                        v-for="(event, index) in audit_log_events"
                        :value="event"
                        :key="index"
                        >{{ event | title_case }}</option
                      >
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group m-b-5">
                    <label
                      for="start_date"
                      class="control-label"
                      style="display:block"
                    >
                      Start Date
                      <!-- <span class="text-danger">*</span> -->
                    </label>
                    <div class="clearfix">
                      <flat-pickr
                        v-model.trim="start_date"
                        :class="{
                          'form-error animated shake': $v.start_date.$error
                        }"
                        class="form-control rounded-0"
                        :config="startDateConfig"
                        placeholder="Start Date"
                      ></flat-pickr>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group m-b-5">
                    <label
                      for="end_date"
                      class="control-label"
                      style="display:block"
                    >
                      End Date
                      <!-- <span class="text-danger">*</span> -->
                    </label>
                    <!-- <div
                      class="clearfix"
                      v-tooltip="
                        !start_date ? 'Please select a start date' : ''
                      "
                    > -->
                    <!-- <input type="text" class="form-control" placeholder="End Date"> -->
                    <flat-pickr
                      v-model.trim="end_date"
                      :class="{
                        'form-error animated shake': $v.end_date.$error
                      }"
                      class="form-control rounded-0"
                      :config="endDateConfig"
                      placeholder="End Date"
                    ></flat-pickr>
                    <!-- </div> -->
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="form-group m-b-5 float-right">
                    <button
                      :disabled="isLoading || $v.$invalid"
                      class="btn btn-sm btn-warning normalize-filter-btn rounded-0 m-r-5"
                      @click.prevent="filterAuditLogs"
                    >
                      <i class="mdi mdi-filter"></i>
                      Filter
                    </button>
                    <span
                      v-tooltip="'CSV will be sent to your email address'"
                      v-if="$User.hasPermission('audit-log-export')"
                    >
                      <button
                        class="btn btn-sm btn-warning normalize-filter-btn rounded-0"
                        :disabled="isLoading || $v.$invalid"
                        @click.prevent="filterAuditLogs({ exportCsv: true })"
                      >
                        <i class="mdi mdi-filter"></i> Export CSV
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div class="row">
              <div class="col-md-12">
                <div class="p-t-10 p-b-10" v-if="isFilter">
                  <label class="text-info font-normal font-14">
                    Entries filtered by
                    <span
                      class="badge badge-pill badge-default mx-1"
                      v-if="$route.query.user_id"
                      ><strong>User</strong></span
                    >
                    <span
                      class="badge badge-pill badge-default mx-1"
                      v-if="$route.query.event"
                      ><strong>Event: </strong>{{ $route.query.event }}</span
                    ><span
                      v-if="$route.query.start_date || $route.query.end_date"
                    >
                      between {{ startDateDefault | date }} and
                      {{ endDateDefault | date }}
                    </span>
                  </label>
                  <button
                    @click="clearFilter"
                    class="ml-3 btn btn-outline-info btn-xs font-bold"
                  >
                    clear filter
                  </button>
                </div>
                <div class="p-t-10 p-b-10" v-if="!isFilter">
                  <label class="text-info font-medium font-14"
                    >Showing all entries</label
                  >
                </div>
                <div class="row">
                  <div class="col-md-7">
                    <div class="table-responsive">
                      <template v-if="audit_log.length > 0">
                        <table class="table table-hover">
                          <thead>
                            <tr class="link-row">
                              <th width="70" class="text-center">#</th>
                              <!-- <th>Name</th> -->
                              <!-- <th>Email</th> -->
                              <!-- <th>Event</th> -->
                              <!-- <th class="text-nowrap">IP Address</th> -->
                              <th>Activity</th>
                              <th>Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="link-row"
                              v-for="(entry, index) in audit_log"
                              :class="
                                selectedEntry && selectedEntry.id === entry.id
                                  ? 'text-success active'
                                  : ''
                              "
                              :key="entry.id"
                              @click.prevent.stop="selectEntry(entry.id)"
                            >
                              <td class="text-center">
                                {{ pagination.from + index }}
                              </td>
                              <!-- <td>{{`${entry.user.first_name} ${entry.user.middle_name} ${entry.user.last_name}`}}</td> -->
                              <!-- <td>{{entry.user.email}}</td> -->
                              <!-- <td class="text-capitalize">{{entry.event | title_case}}</td> -->
                              <!-- <td>
                            <a
                              v-tooltip="'Click for more details on the IP address'"
                              target="_blank"
                              :href="`https://www.infobyip.com/ip-${entry.ip_address}.html`"
                            >{{entry.ip_address}}</a>
                          </td> -->
                              <td>
                                {{
                                  entry.user.first_name +
                                    " " +
                                    (entry.user.middle_name
                                      ? entry.user.middle_name + " "
                                      : "") +
                                    entry.user.last_name +
                                    " " +
                                    entry.activity
                                }}
                              </td>
                              <td class="text-nowrap font-12">
                                {{ entry.created_at | from_now }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="p-30 test font-medium text-muted">
                          Showing {{ pagination.from }} to
                          {{ pagination.to }} of {{ pagination.total }} entries
                        </div>
                      </template>
                      <template v-else>
                        <div class="text-center">
                          <img
                            src="/static/empty.svg"
                            class="img-fluid mb-3"
                            alt="empty"
                            style="width: 50px;"
                          />
                          <p class="font-bold font-14">
                            There are no entries to display
                          </p>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="col-md-5" id="audit-table-details">
                    <transition
                      enter-active-class="animated fastest fadeIn"
                      leave-active-class="animated fastest fadeOut"
                      mode="out-in"
                    >
                      <div
                        class="card"
                        v-if="selectedEntry"
                        :key="selectedEntry.id"
                      >
                        <div class="card-block">
                          <h3 class="card-title">
                            Audit Details #{{
                              pagination.from + selectedEntry.index
                            }}
                          </h3>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">User</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">{{
                              selectedEntry.user.first_name +
                                " " +
                                (selectedEntry.user.middle_name
                                  ? selectedEntry.user.middle_name + " "
                                  : "") +
                                selectedEntry.user.last_name
                            }}</span>
                          </div>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">Phone Number</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">{{
                              selectedEntry.user.mobile
                            }}</span>
                          </div>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">Email Address</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">{{
                              selectedEntry.user.email
                            }}</span>
                          </div>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">Event</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">{{
                              selectedEntry.event | title_case
                            }}</span>
                          </div>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">IP Address</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">
                              <a
                                v-tooltip="
                                  'Click for more details on the IP address'
                                "
                                target="_blank"
                                :href="
                                  'https://www.infobyip.com/ip-' +
                                    selectedEntry.ip_address +
                                    '.html'
                                "
                                >{{ selectedEntry.ip_address }}</a
                              >
                            </span>
                          </div>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">Activity</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">{{
                              selectedEntry.activity
                            }}</span>
                          </div>
                          <div class="col-xs-12">
                            <span class="font-bold font-14">Date</span>
                          </div>
                          <div class="col-xs-12 m-b-10">
                            <span class="text-muted font-14">{{
                              selectedEntry.created_at | long_date
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card"
                        v-if="!selectedEntry && audit_log.length > 0"
                      >
                        <div class="card-block">
                          <div class="my-3 text-center">
                            <img
                              src="/static/select.svg"
                              class="img-fluid mb-3"
                              alt="select"
                              style="width: 50px;"
                            />
                            <p class="font-14 font-bold">
                              Select an entry to view details
                            </p>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="text-center m-t-20">
                  <pagination
                    :data="pagination"
                    :limit="4"
                    :show-disabled="true"
                    @pagination-change-page="getLogByPage"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import debounce from "@/helpers/debounce";

export default {
  name: "AuditLogView",
  components: {
    AppTitle
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        page = 1,
        event = "all",
        user_id = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      await Promise.all([
        store.dispatch(types.GET_AUDIT_LOG_EVENTS_ACTION),
        store.dispatch(types.GET_AUDIT_LOG_ACTION, {
          page,
          event,
          start_date,
          end_date,
          user_id
        })
      ]);
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        page = 1,
        event = "all",
        user_id = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      await Promise.all([
        store.dispatch(types.GET_AUDIT_LOG_EVENTS_ACTION),
        store.dispatch(types.GET_AUDIT_LOG_ACTION, {
          page,
          event,
          start_date,
          end_date,
          user_id
        })
      ]);
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
    }
  },
  mounted() {
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      user: "",
      users: [],
      event: this.$route.query.event || "",
      start_date: this.$route.query.start_date || "",
      end_date: this.$route.query.end_date || "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      isLoading: false,
      selectedEntryId: ""
    };
  },
  validations: {
    user: {},
    event: { required },
    start_date: {},
    end_date: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      audit_log_events: "getAuditLogEvents",
      audit_log: "getAuditLog",
      pagination: "getAuditPagination",
      business_profile: "getBusinessProfile"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment(this.$store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        )
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return this.$route.query.user_id || this.$route.query.event;
    },
    selectedEntry() {
      let index;
      let selectedEntry = this.audit_log.find((entry, key) => {
        index = key;
        return entry.id === this.selectedEntryId;
      });
      selectedEntry && (selectedEntry.index = index);
      return selectedEntry;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterAuditLogs(options = {}) {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let { start_date, end_date, user, event } = this;

          let { exportCsv } = options;

          if (exportCsv) {
            let response = await this.$store.dispatch(
              types.EXPORT_AUDIT_LOG_ACTION,
              {
                start_date: start_date || this.startDateDefault,
                end_date: end_date || this.endDateDefault,
                user_id: (user && user.id) || "all",
                event
              }
            );
            this.$notify.success({
              message: response.message
            });
          } else {
            let query = {
              ...(event && event !== "all" && { event }),
              ...(user && user.id && { user_id: user.id }),
              ...(start_date && { start_date }),
              ...(end_date && { end_date })
            };
            this.$router.push({
              name: this.$route.name,
              query
            });
          }
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      let { start_date, end_date, user_id, event } = this.$route.query;

      let query = {
        [Date.now()]: null,
        ...(event && { event }),
        ...(user_id && { user_id }),
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date })
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    async clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.event = "";
        this.start_date = "";
        this.end_date = "";
        this.user = "";
        this.$v.$reset();
      });
    },
    getAuditLog(payload) {
      return this.$store.dispatch(types.GET_AUDIT_LOG_ACTION, payload);
    },
    searchUsers: debounce(async function(search, loading) {
      if (search.trim()) {
        try {
          loading(true);
          let users = await store.dispatch(types.SEARCH_USERS_ACTION, {
            query: search,
            paginate: 0
          });
          this.users = users.data.map(user => {
            user.full_name_email = `${user.first_name} ${user.middle_name} ${user.last_name}`;
            return user;
          });
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          loading(false);
        }
      }
    }, 500),
    selectEntry(id) {
      let offset = $("#audit-table-details").offset();
      offset.top -= 100;
      $("html, body").animate({
        scrollTop: offset.top
      });
      this.selectedEntryId = id;
    }
  }
};
</script>
