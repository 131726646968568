<template>
  <div class="card bordered-box">
    <div class="card-block">
      <form @submit.prevent="getCouponQuotation">
        <h3 class="card-title">Request Coupon</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                Category
                <span class="text-danger">*</span>
              </label>
              <select
                type="text"
                v-model.trim="category"
                class="form-control rounded-0"
                @change="category === 'single' ? (quantity = 1) : false"
              >
                <option value="single">Single</option>
                <option value="multiple">Multiple</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                Coupon Code
                <span class="text-danger">*</span>
              </label>
              <input
                type="text"
                v-model.trim="coupon_code"
                :class="{ 'form-error animated shake': $v.coupon_code.$error }"
                @input="$v.coupon_code.$touch"
                class="form-control rounded-0 text-uppercase"
                placeholder="Enter Coupon Code"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                Quantity
                <span class="text-danger">*</span>
              </label>
              <input
                :disabled="category === 'single'"
                type="text"
                v-model.trim="quantity"
                :class="{ 'form-error animated shake': $v.quantity.$error }"
                @input="$v.quantity.$touch"
                class="form-control rounded-0"
                placeholder="0"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                Start Date
                <span class="text-danger">*</span>
              </label>

              <flat-pickr
                v-model.trim="start_date"
                :class="{ 'form-error animated shake': $v.start_date.$error }"
                class="form-control rounded-0"
                :config="startDateConfig"
                placeholder="Start Date"
              ></flat-pickr>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                End Date
                <span class="text-danger">*</span>
              </label>

              <div v-tooltip="!start_date ? 'Please select a start date' : ''">
                <flat-pickr
                  :disabled="!start_date"
                  :class="{ 'form-error animated shake': $v.end_date.$error }"
                  v-model.trim="end_date"
                  class="form-control rounded-0"
                  :config="endDateConfig"
                  placeholder="End Date"
                ></flat-pickr>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label" for="discount_type">
                Discount Type
                <span class="text-danger">*</span>
              </label>
              <select
                id="discount_type"
                name="discount_type"
                class="form-control rounded-0"
                v-model.trim="discount_type"
              >
                <option value="percent">% discount</option>
                <option value="amount">₦ discount</option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group m-b-10">
              <label class="font-bold form-label">
                Discount Value
                <span class="text-danger">*</span>
                <span
                  class="mdi mdi-help-circle-o ml-1"
                  v-tooltip="
                    'Discount value cannot be more than the available discount i.e. ₦' +
                      verificationPrice / 100 +
                      ' or 100%'
                  "
                ></span>
              </label>

              <input
                type="text"
                v-model.trim="discount_value"
                :class="{
                  'form-error animated shake': $v.discount_value.$error
                }"
                @input="$v.discount_value.$touch"
                class="form-control rounded-0"
                placeholder="Enter Discount Value"
              />
            </div>
          </div>
        </div>
        <template v-if="category === 'single'">
          <h3 class="card-title m-t-10">Agent</h3>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group m-b-10">
                <label class="font-bold form-label"
                  >Agent Name
                  <span class="text-danger">*</span>
                </label>
                <v-select
                  v-model.trim="agent"
                  label="full_name_lslb_license_number"
                  :options="agents"
                  :filterable="false"
                  @search="fetchAgents"
                  placeholder="Type to search for agents..."
                >
                  <template slot="no-options"
                    >Type to search for agents...</template
                  >
                </v-select>
              </div>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-md-6 form-group m-b-10">
            <span class="font-14">
              <strong class="font-weight-bold">Note: </strong>₦{{
                (verificationPrice / 100) | amount
              }}
              per verification
            </span>
            <button
              :disabled="$v.$invalid || is_getting_coupon_quotation"
              class="btn btn-warning waves-effect rounded-0 w-100 m-t-5"
              type="submit"
            >
              Get Quotation
              <i
                :class="
                  is_getting_coupon_quotation
                    ? 'mdi mdi-loading mdi-spin'
                    : 'mdi mdi-arrow-right'
                "
              ></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  alphaNum,
  maxValue,
  minValue,
  numeric,
  required
} from "vuelidate/lib/validators";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";
import debounce from "@/helpers/debounce";
import eventBus from "@/helpers/event";
import { mapGetters } from "vuex";

export default {
  mounted() {
    eventBus.$on("lslb:coupon:coupon_payment_successful", () => {
      this.category = "multiple";
      this.coupon_code = "";
      this.quantity = "";
      this.discount_type = "percent";
      this.discount_value = "";
      this.start_date = moment().format("YYYY-MM-DD");
      this.end_date = moment()
        .add(1, "d")
        .format("YYYY-MM-DD");
      this.agent = "";
      this.agents = [];
      this.$v.$reset();
    });
  },
  data() {
    return {
      is_getting_coupon_quotation: false,
      category: "multiple",
      coupon_code: "",
      quantity: "",
      discount_type: "percent",
      discount_value: "",
      start_date: "",
      end_date: "",
      startDateConfig: {
        minDate: moment().format("YYYY-MM-DD")
      },
      endDateConfig: {
        minDate: moment().format("YYYY-MM-DD")
      },
      agent: "",
      agents: []
    };
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "d")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      verificationPrice: "getVerificationPrice"
    })
  },
  validations() {
    let validations = {
      coupon_code: {
        required,
        alphaNum
      },
      quantity: {
        required,
        numeric,
        minValue: minValue(2)
      },
      discount_value: {
        required,
        numeric
      },
      start_date: {
        required
      },
      end_date: {
        required
      }
    };
    if (this.discount_type === "percent") {
      validations.discount_value.maxValue = maxValue(100);
    } else if (this.discount_type === "amount") {
      validations.discount_value.maxValue = maxValue(
        this.verificationPrice / 100
      );
    }
    if (this.category === "single") {
      validations.quantity.minValue = minValue(1);
      validations.agent = {};
      validations.agent.required = required;
    }
    return validations;
  },
  methods: {
    fetchAgents: debounce(async function(search, loading) {
      if (search.trim().length > 1) {
        try {
          loading(true);
          this.agents = [];
          let agents = await this.$store.dispatch(
            types.GET_COUPON_AGENTS_ACTION,
            search
          );
          if (agents.length === 0) {
            return;
          }
          this.agents = agents.map(agent => {
            agent.full_name_lslb_license_number = `${agent.first_name} ${
              agent.middle_name
            } ${agent.last_name.value} - ${agent.lslb_license_number ||
              agent.email.value}`;
            return agent;
          });
        } catch (error) {
          error && error.data && notify.error({ message: error.data.message });
        } finally {
          loading(false);
        }
      }
    }, 500),
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async getCouponQuotation() {
      if (this.validateForm()) {
        this.is_getting_coupon_quotation = true;
        try {
          let quotation = await this.$store.dispatch(
            types.GET_COUPON_QUOTATION_ACTION,
            {
              category: this.category,
              coupon_code: this.coupon_code.toUpperCase(),
              discount_type: this.discount_type,
              value: this.discount_value,
              quantity: this.quantity,
              agent_id: this.agent._id
            }
          );
          this.is_getting_coupon_quotation = false;
          eventBus.$emit("lslb:coupon:coupon_quotation", quotation);
          eventBus.$emit("lslb:coupon:coupon_data", {
            agent_id: this.agent._id,
            category: this.category,
            coupon_code: this.coupon_code,
            quantity: this.quantity,
            discount_type: this.discount_type,
            discount_value: this.discount_value,
            start_date: this.start_date,
            end_date: this.end_date
          });
          $("#get-coupon-quotation-modal").modal("show");
        } catch (error) {
          this.is_getting_coupon_quotation = false;
          error && error.data && notify.error({ message: error.data.message });
        }
      }
    }
  }
};
</script>
