import * as types from "@/store/types";
import VerificationService from "@/services/verifications";

const actions = {
  async [types.GET_VERIFICATIONS_ACTION]({ commit }, payload) {
    let verifications = await VerificationService.getVerifications(payload);
    commit(types.SET_VERIFICATIONS_LOG, verifications.data);
    commit(types.SET_VERIFICATIONS_META, verifications.meta);
    return verifications;
  },
  async [types.GET_VERIFICATION_REPORT_ACTION](
    { commit },
    { verification_id }
  ) {
    let verification = await VerificationService.getRequestVerification(
      verification_id
    );
    commit(types.SET_VERIFICATION_REPORT, verification.data);
    return verification.data;
  },
  async [types.EXPORT_VERIFICATIONS_ACTION](_, payload) {
    let response = await VerificationService.exportVerifications(payload);
    return response;
  },
  async [types.GET_REPORT_DOWNLOAD_URL](_, verification_id) {
    let response = (await VerificationService.getDownloadUrl(verification_id))
      .data;
    return response;
  },
  async [types.REQUEST_ADDRESS_VERIFICATION_ACTION](
    { commit },
    verification_id
  ) {
    let verification = await VerificationService.requestAddressVerification(
      verification_id
    );
    commit(types.SET_VERIFICATION_REPORT, verification.data);
    return verification.data;
  },
  async [types.RETRY_AGENT_VERIFICATION]({ commit }, payload) {
    const response = await VerificationService.retryAgentVerification(payload);
    commit(types.SET_VERIFICATION_REPORT, response.data);
    return response;
  }
};

export default actions;
