<template>
  <div class="bordered-box" :class="{ card: card === 'yes' }">
    <AgentPermitRenewal v-if="permit_renewal" :agentProfile="agent_profile" />
    <AgentRenewPermit v-else-if="renew_permit" :agentProfile="agent_profile" />
    <div v-else class="card-block">
      <div class="row align-items-md-center">
        <div
          :class="{
            'col-md-6': layout === 'horizontal',
            'col-md-12': layout === 'vertical'
          }"
        >
          <div :class="[{ 'd-xl-flex': layout === 'horizontal' }]">
            <ImagePercentage
              :class="[
                'mx-auto',
                { 'mr-xl-5 ml-xl-0': layout === 'horizontal' }
              ]"
              :photoMeta="agent_profile.photo"
            />
            <div class="text-center text-xl-left">
              <h4 class="card-title m-t-10">
                <template v-if="agent_profile.last_name.value">
                  {{
                    `${agent_profile.first_name} ${
                      agent_profile.middle_name
                    } ${agent_profile.last_name.value.toUpperCase()}`
                  }}
                  <span
                    v-if="agent_profile.last_name.verified"
                    class="text-success ml-1 mdi mdi-check-circle"
                  ></span>
                </template>
                <template v-else>Not available</template>
              </h4>
              <div>
                <span>Permit ID: </span>
                <span class="font-weight-bold">{{
                  agent_profile.verification.lslb_license_number ||
                    "Not Available"
                }}</span>
              </div>
              <h6
                style="font-weight: 200"
                class="font-weight-normal small my-3"
              >
                Date of Verification: {{ agent_profile.created_at | date }}
              </h6>
              <h6 class="permit-details  small my-3">
                Permit ID Issue Date:
                {{ verifiedLicenses.date_issued | long_date("") }}
              </h6>
              <h6 class="permit-details  small my-3">
                Permit ID Expiry Date:
                {{ verifiedLicenses.expiration_date | long_date("") }}
              </h6>
              <template
                v-if="
                  admin &&
                    (admin.user_type === 'lslb' ||
                      admin.user_type === 'youverify')
                "
              >
                <button
                  v-if="$route.name === 'AgentView' && hasPermitExpired"
                  class="btn btn-warning btn-sm mb-3"
                  @click="handleRenewPermit"
                >
                  Renew Permit
                  <span v-if="isRenewing">
                    <i class="mdi mdi-loading mdi-spin"></i>
                  </span>
                </button>
              </template>
              <template v-else>
                <router-link
                  v-if="$route.name === 'AgentDashboard' && hasPermitExpired"
                  :to="{
                    name: 'AgentDashboard',
                    params: { profile_id: agent_profile.id },
                    query: { permit_renewal: 1 }
                  }"
                  class="btn btn-warning btn-sm mb-3"
                >
                  Renew Permit
                </router-link>
              </template>
              <router-link
                v-if="
                  showEditProfile &&
                    !(
                      agent_profile.status === 'pending' ||
                      agent_profile.status === 'verified'
                    )
                "
                :to="{
                  name: 'NewVerificationContainer',
                  params: { verification_id: agent_profile.verification.id }
                }"
                class="btn btn-warning btn-sm mb-3"
              >
                Edit Profile
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="p-3 bg-faded rounded"
          :class="{
            'col-md-6': layout === 'horizontal',
            'col-md-12': layout === 'vertical'
          }"
        >
          <div class="row">
            <div :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]">
              <div>
                <span class="font-bold font-14">Email Address</span>
              </div>
              <div class="m-b-10">
                <span class="text-muted font-14">{{
                  agent_profile.email.value || "Not available"
                }}</span>
              </div>
            </div>

            <div :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]">
              <div>
                <span class="font-bold font-14">Mobile Number</span>
              </div>
              <div class="m-b-10">
                <span class="text-muted font-14">{{
                  agent_profile.mobile.value || "Not available"
                }}</span>
              </div>
            </div>

            <div :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]">
              <div>
                <span class="font-bold font-14">Date of Birth</span>
              </div>
              <div class="m-b-10">
                <span class="text-muted font-14">{{
                  (agent_profile.dob && agent_profile.dob.value) ||
                    "Not available"
                }}</span>
              </div>
            </div>

            <div :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]">
              <div>
                <span class="font-bold font-14">Other Mobile Number</span>
              </div>
              <div class="m-b-10">
                <span class="text-muted font-14">{{
                  agent_profile.other_mobile || "Not available"
                }}</span>
              </div>
            </div>

            <div :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]">
              <div>
                <span class="font-bold font-14">Gender</span>
              </div>
              <div class="m-b-10">
                <span class="text-muted font-14 text-capitalize">{{
                  agent_profile.verification.personal_info.gender ||
                    "Not available"
                }}</span>
              </div>
            </div>

            <div :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]">
              <span class="font-bold font-14">Application Link</span>
              <button
                class="btn ml-2"
                type="button"
                v-clipboard:copy="agent_profile.verification.application_link"
                v-clipboard:success="onCopy"
              >
                <i
                  v-tooltip="{
                    content: tooltipMessage,
                    delay: { hide: 500 }
                  }"
                  class="mdi mdi-content-copy text-warning"
                >
                </i>
              </button>
            </div>

            <div
              v-if="agent_profile.invite_code"
              :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]"
            >
              <div>
                <span class="font-bold font-14">Invite Link</span>
              </div>
              <div class="m-b-10">
                <span class="text-muted font-14 m-r-10">{{
                  `${host}/request-verification?c=${agent_profile.invite_code}`
                }}</span>
                <button
                  class="btn"
                  type="button"
                  v-clipboard:copy="
                    `${host}/request-verification?c=${agent_profile.invite_code}`
                  "
                  v-clipboard:success="onCopy"
                >
                  <i
                    v-tooltip="{
                      content: tooltipMessage,
                      delay: { hide: 500 }
                    }"
                    class="mdi mdi-content-copy text-warning"
                  >
                  </i>
                </button>
              </div>
            </div>

            <div
              :class="['col-12', { 'col-xl-6': layout === 'horizontal' }]"
              v-if="$User.hasPermission('agent-view')"
            >
              <div class="m-t-10">
                <button
                  class="btn btn-block btn-warning"
                  @click.prevent="resendAgentInvite"
                >
                  Resend Invite
                  <i
                    class="mdi"
                    :class="
                      resendingAgentInvite ? 'mdi-loading mdi-spin' : 'mdi-send'
                    "
                  ></i>
                </button>
              </div>
            </div>
          </div>

          <!-- <div class="col-xs-12">
          <span class="font-bold font-14">Agent Region</span>
        </div>
        <div class="col-xs-12 m-b-10">
          <span class="text-muted font-14">{{
            agent_profile.region || "Not available"
          }}</span>
        </div>
        <div class="col-xs-12">
          <span class="font-bold font-14">Date of Birth</span>
        </div>
        <div class="col-xs-12 m-b-10">
          <span class="text-muted font-14">
            {{ agent_profile.dob.value | date }}
            <span
              v-if="agent_profile.dob.verified"
              class="text-success ml-1 mdi mdi-check-circle"
            ></span>
          </span>
        </div> -->
        </div>
      </div>

      <div :class="[{ 'd-xl-flex': layout === 'horizontal' }]">
        <div
          style="height: 150px; width: 150px"
          :class="[
            'hidden-lg-down mr-5',
            { 'hidden-xl-down': layout === 'vertical' }
          ]"
        ></div>
        <div :class="['row flex-grow my-5']">
          <div
            :class="[
              'col-12',
              {
                'col-md-6': layout === 'horizontal',
                'mb-3': layout === 'vertical'
              }
            ]"
          >
            <span class="font-bold font-14">Residential Address</span>
            <div>
              <span class="text-muted font-14">{{
                agent_profile.verification.residential_address &&
                agent_profile.verification.residential_address.address
                  ? `${agent_profile.verification.residential_address.address}`
                  : "Not available"
              }}</span>
            </div>
          </div>
          <div
            v-if="!$User.hasPermission('transaction-view')"
            :class="['col-12', { 'col-md-6': layout === 'horizontal' }]"
          >
            <span
              class="font-bold font-14 d-block d-md-none"
              data-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              >Business Address
            </span>
            <span
              class="font-bold font-14 d-none d-md-block"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
              >Business Address(es)</span
            >
            <div id="collapseExample" class="collapse d-block d-md-none">
              <div
                v-for="(business, index) in agent_profile.agent_businesses"
                :key="index"
              >
                <div v-if="profile !== null">
                  <div v-if="profile.business.id === business.business.id">
                    <h6 class="font-bold mb-0 mt-3">
                      {{ business.business.trade_name }}
                    </h6>
                    <span
                      v-if="business['shop_addresses'].length < 1"
                      class="font-bold text-muted font-14"
                    >
                      No address found
                    </span>
                    <div
                      v-for="(address, key) in business['shop_addresses']"
                      :key="key"
                    >
                      <span class="text-muted font-14 d-inline-flex"
                        >{{ `${key + 1}. ${address.address}` }}
                        <img class="ml-auto" :src="address.qrcode_url" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <h6 class="font-bold mb-0 mt-3">
                      {{ business.business.trade_name }}
                    </h6>
                    <span
                      v-if="business['shop_addresses'].length < 1"
                      class="font-bold text-muted font-14"
                    >
                      No address found
                    </span>
                    <div
                      v-if="selectedBarcodeUrl !== ''"
                      class="swal2-container swal2-center swal2-shown"
                      style="overflow-y: auto;"
                    >
                      <div
                        aria-labelledby="swal2-title"
                        aria-describedby="swal2-content"
                        class="swal2-popup swal2-modal animated fadeInDown faster swal2-noanimation"
                        tabindex="-1"
                        role="dialog"
                        aria-live="assertive"
                        aria-modal="true"
                        style="display: flex;"
                      >
                        <div class="swal2-header">
                          <ul
                            class="swal2-progresssteps"
                            style="display: none;"
                          ></ul>
                          <div
                            class="swal2-icon swal2-error"
                            style="display: none;"
                          >
                            <span class="swal2-x-mark"
                              ><span class="swal2-x-mark-line-left"></span
                              ><span class="swal2-x-mark-line-right"></span
                            ></span>
                          </div>
                          <div
                            class="swal2-icon swal2-question"
                            style="display: none;"
                          >
                            <span class="swal2-icon-text">?</span>
                          </div>
                          <div
                            class="swal2-icon swal2-warning"
                            style="display: none;"
                          >
                            <span class="swal2-icon-text">!</span>
                          </div>
                          <div
                            class="swal2-icon swal2-info"
                            style="display: none;"
                          >
                            <span class="swal2-icon-text">i</span>
                          </div>
                          <div
                            class="swal2-icon swal2-success"
                            style="display: none;"
                          >
                            <div
                              class="swal2-success-circular-line-left"
                              style="background-color: rgb(255, 255, 255);"
                            ></div>
                            <span class="swal2-success-line-tip"></span>
                            <span class="swal2-success-line-long"></span>
                            <div class="swal2-success-ring"></div>
                            <div
                              class="swal2-success-fix"
                              style="background-color: rgb(255, 255, 255);"
                            ></div>
                            <div
                              class="swal2-success-circular-line-right"
                              style="background-color: rgb(255, 255, 255);"
                            ></div>
                          </div>
                          <img class="swal2-image" style="display: none;" />
                          <h2 class="swal2-title" id="swal2-title"></h2>
                          <button
                            type="button"
                            class="swal2-close"
                            style="display: none;"
                          >
                            ×
                          </button>
                        </div>
                        <div class="swal2-content">
                          <div id="swal2-content" style="display: block;">
                            <img
                              :src="selectedBarcodeUrl"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <input
                            class="swal2-input"
                            style="display: none;"
                          /><input
                            type="file"
                            class="swal2-file"
                            style="display: none;"
                          />
                          <div class="swal2-range" style="display: none;">
                            <input type="range" /><output></output>
                          </div>
                          <select
                            class="swal2-select"
                            style="display: none;"
                          ></select>
                          <div class="swal2-radio" style="display: none;"></div>
                          <label
                            for="swal2-checkbox"
                            class="swal2-checkbox"
                            style="display: none;"
                            ><input type="checkbox"/><span
                              class="swal2-label"
                            ></span
                          ></label>
                          <textarea
                            class="swal2-textarea"
                            style="display: none;"
                          ></textarea>
                          <div
                            class="swal2-validation-message"
                            id="swal2-validation-message"
                            style="display: none;"
                          ></div>
                        </div>
                        <div class="swal2-actions" style="display: flex;">
                          <button
                            type="button"
                            class="swal2-cancel btn btn-outline-secondary mr-3"
                            @click="selectedBarcodeUrlToPrint"
                            style="background: #404E5D;
border-radius: 2.02978px;"
                            aria-label=""
                          >
                            Print
                          </button>
                          <button
                            type="button"
                            class="swal2-confirm btn btn-secondary mr-3"
                            style="border: 0.869906px solid #000000; background: none"
                            @click="selectedBarcodeUrl = ''"
                            aria-label=""
                          >
                            <a
                              style=" color: #000000"
                              :href="selectedBarcodeUrl"
                              target="_blank"
                            >
                              Download
                            </a>
                          </button>

                          <button
                            type="button"
                            class="swal2-cancel btn btn-danger"
                            @click="selectedBarcodeUrl = ''"
                            aria-label=""
                          >
                            Close
                          </button>
                        </div>
                      </div>
                      <div class="swal2-footer" style="display: none;"></div>
                    </div>
                    <div
                      v-for="(address, key) in business['shop_addresses']"
                      :key="key"
                      style="background: #F5F5F5; cursor: pointer; border-bottom: 1px solid #C4C4C4;"
                      class="py-2 px-2"
                    >
                      <div
                        class="d-inline-flex col-12"
                        @click="selectedBarcodeUrl = address.qrcode_url"
                      >
                        <span
                          class="text-muted font-14"
                          :data-toggle="`exampleModal${key}`"
                          :data-target="`#exampleModal${key}`"
                          >{{ `${address.address}` }}
                        </span>
                        <img
                          class="ml-auto"
                          width="16px"
                          height="16px"
                          :src="address.qrcode_url"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="" class="d-none d-md-block p-3 view-biz-address">
              <div
                v-for="(business, index) in agent_profile.agent_businesses"
                :key="index"
              >
                <div v-if="profile !== null">
                  <div v-if="profile.business.id === business.business.id">
                    <h6 class="font-bold mb-0 mt-3">
                      {{ business.business.trade_name }}
                    </h6>
                    <span
                      v-if="business['shop_addresses'].length < 1"
                      class="font-bold text-muted font-14"
                    >
                      No address found
                    </span>
                    <div
                      v-for="(address, indexer) in business['shop_addresses']"
                      :key="indexer"
                    >
                      <span
                        class="text-muted font-14 d-inline-flex"
                        style="border-bottom: 1px solid #C4C4C4"
                        >{{ `${key + 1}. ${address.address}` }}
                        <img class="ml-auto" :src="address.qrcode_url" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <h6 class="font-bold mb-0 mt-3">
                      {{ business.business.trade_name }}
                    </h6>
                    <span
                      v-if="business['shop_addresses'].length < 1"
                      class="font-bold text-muted font-14"
                    >
                      No address found
                    </span>
                    <div
                      v-if="selectedBarcodeUrl !== ''"
                      class="swal2-container swal2-center swal2-shown"
                      style="overflow-y: auto;"
                    >
                      <div
                        aria-labelledby="swal2-title"
                        aria-describedby="swal2-content"
                        class="swal2-popup swal2-modal animated fadeInDown faster swal2-noanimation"
                        tabindex="-1"
                        role="dialog"
                        aria-live="assertive"
                        aria-modal="true"
                        style="display: flex;"
                      >
                        <div class="swal2-header">
                          <ul
                            class="swal2-progresssteps"
                            style="display: none;"
                          ></ul>
                          <div
                            class="swal2-icon swal2-error"
                            style="display: none;"
                          >
                            <span class="swal2-x-mark"
                              ><span class="swal2-x-mark-line-left"></span
                              ><span class="swal2-x-mark-line-right"></span
                            ></span>
                          </div>
                          <div
                            class="swal2-icon swal2-question"
                            style="display: none;"
                          >
                            <span class="swal2-icon-text">?</span>
                          </div>
                          <div
                            class="swal2-icon swal2-warning"
                            style="display: none;"
                          >
                            <span class="swal2-icon-text">!</span>
                          </div>
                          <div
                            class="swal2-icon swal2-info"
                            style="display: none;"
                          >
                            <span class="swal2-icon-text">i</span>
                          </div>
                          <div
                            class="swal2-icon swal2-success"
                            style="display: none;"
                          >
                            <div
                              class="swal2-success-circular-line-left"
                              style="background-color: rgb(255, 255, 255);"
                            ></div>
                            <span class="swal2-success-line-tip"></span>
                            <span class="swal2-success-line-long"></span>
                            <div class="swal2-success-ring"></div>
                            <div
                              class="swal2-success-fix"
                              style="background-color: rgb(255, 255, 255);"
                            ></div>
                            <div
                              class="swal2-success-circular-line-right"
                              style="background-color: rgb(255, 255, 255);"
                            ></div>
                          </div>
                          <img class="swal2-image" style="display: none;" />
                          <h2 class="swal2-title" id="swal2-title"></h2>
                          <button
                            type="button"
                            class="swal2-close"
                            style="display: none;"
                          >
                            ×
                          </button>
                        </div>
                        <div class="swal2-content">
                          <div id="swal2-content" style="display: block;">
                            <img
                              :src="selectedBarcodeUrl"
                              width="100%"
                              alt=""
                            />
                          </div>
                          <input
                            class="swal2-input"
                            style="display: none;"
                          /><input
                            type="file"
                            class="swal2-file"
                            style="display: none;"
                          />
                          <div class="swal2-range" style="display: none;">
                            <input type="range" /><output></output>
                          </div>
                          <select
                            class="swal2-select"
                            style="display: none;"
                          ></select>
                          <div class="swal2-radio" style="display: none;"></div>
                          <label
                            for="swal2-checkbox"
                            class="swal2-checkbox"
                            style="display: none;"
                            ><input type="checkbox"/><span
                              class="swal2-label"
                            ></span
                          ></label>
                          <textarea
                            class="swal2-textarea"
                            style="display: none;"
                          ></textarea>
                          <div
                            class="swal2-validation-message"
                            id="swal2-validation-message"
                            style="display: none;"
                          ></div>
                        </div>
                        <div class="swal2-actions" style="display: flex;">
                          <button
                            type="button"
                            class="swal2-cancel btn btn-outline-secondary mr-3"
                            @click="selectedBarcodeUrlToPrint"
                            style="background: #404E5D;
border-radius: 2.02978px;"
                            aria-label=""
                          >
                            Print
                          </button>
                          <button
                            type="button"
                            class="swal2-confirm btn btn-secondary mr-3"
                            style="border: 0.869906px solid #000000; background: none"
                            @click="selectedBarcodeUrl = ''"
                            aria-label=""
                          >
                            <a
                              style=" color: #000000"
                              :href="selectedBarcodeUrl"
                              target="_blank"
                            >
                              Download
                            </a>
                          </button>

                          <button
                            type="button"
                            class="swal2-cancel btn btn-danger"
                            @click="selectedBarcodeUrl = ''"
                            aria-label=""
                          >
                            Close
                          </button>
                        </div>
                      </div>
                      <div class="swal2-footer" style="display: none;"></div>
                    </div>
                    <div
                      v-for="(address, key) in business['shop_addresses']"
                      :key="key"
                      style="background: #F5F5F5; cursor: pointer; border-bottom: 1px solid #C4C4C4;"
                      class="py-2 px-2"
                    >
                      <div
                        class="d-inline-flex col-12"
                        @click="selectedBarcodeUrl = address.qrcode_url"
                      >
                        <span
                          class="text-muted font-14"
                          :data-toggle="`exampleModal${key}`"
                          :data-target="`#exampleModal${key}`"
                          >{{ `${address.address}` }}
                        </span>
                        <img
                          class="ml-auto"
                          width="16px"
                          height="16px"
                          :src="address.qrcode_url"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-else
            :class="['col-12', { 'col-md-6': layout === 'horizontal' }]"
          >
            <span class="font-bold font-14">Business Address</span>
            <div class="d-none d-md-block view-biz-address p-3">
              <div
                v-for="(business, index) in agent_profile.agent_businesses"
                :key="index"
              >
                <h6 class="font-bold mb-0 mt-3">
                  {{ business.business.trade_name }}
                </h6>
                <div
                  v-if="selectedBarcodeUrl !== ''"
                  class="swal2-container swal2-center swal2-shown"
                  style="overflow-y: auto;"
                >
                  <div
                    aria-labelledby="swal2-title"
                    aria-describedby="swal2-content"
                    class="swal2-popup swal2-modal animated fadeInDown faster swal2-noanimation"
                    tabindex="-1"
                    role="dialog"
                    aria-live="assertive"
                    aria-modal="true"
                    style="display: flex;"
                  >
                    <div class="swal2-header">
                      <ul
                        class="swal2-progresssteps"
                        style="display: none;"
                      ></ul>
                      <div
                        class="swal2-icon swal2-error"
                        style="display: none;"
                      >
                        <span class="swal2-x-mark"
                          ><span class="swal2-x-mark-line-left"></span
                          ><span class="swal2-x-mark-line-right"></span
                        ></span>
                      </div>
                      <div
                        class="swal2-icon swal2-question"
                        style="display: none;"
                      >
                        <span class="swal2-icon-text">?</span>
                      </div>
                      <div
                        class="swal2-icon swal2-warning"
                        style="display: none;"
                      >
                        <span class="swal2-icon-text">!</span>
                      </div>
                      <div class="swal2-icon swal2-info" style="display: none;">
                        <span class="swal2-icon-text">i</span>
                      </div>
                      <div
                        class="swal2-icon swal2-success"
                        style="display: none;"
                      >
                        <div
                          class="swal2-success-circular-line-left"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                        <span class="swal2-success-line-tip"></span>
                        <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div
                          class="swal2-success-fix"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                        <div
                          class="swal2-success-circular-line-right"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                      </div>
                      <img class="swal2-image" style="display: none;" />
                      <h2 class="swal2-title" id="swal2-title"></h2>
                      <button
                        type="button"
                        class="swal2-close"
                        style="display: none;"
                      >
                        ×
                      </button>
                    </div>
                    <div class="swal2-content">
                      <div id="swal2-content" style="display: block;">
                        <img :src="selectedBarcodeUrl" width="100%" alt="" />
                      </div>
                      <input class="swal2-input" style="display: none;" /><input
                        type="file"
                        class="swal2-file"
                        style="display: none;"
                      />
                      <div class="swal2-range" style="display: none;">
                        <input type="range" /><output></output>
                      </div>
                      <select
                        class="swal2-select"
                        style="display: none;"
                      ></select>
                      <div class="swal2-radio" style="display: none;"></div>
                      <label
                        for="swal2-checkbox"
                        class="swal2-checkbox"
                        style="display: none;"
                        ><input type="checkbox"/><span
                          class="swal2-label"
                        ></span
                      ></label>
                      <textarea
                        class="swal2-textarea"
                        style="display: none;"
                      ></textarea>
                      <div
                        class="swal2-validation-message"
                        id="swal2-validation-message"
                        style="display: none;"
                      ></div>
                    </div>
                    <div class="swal2-actions" style="display: flex;">
                      <button
                        type="button"
                        class="swal2-cancel btn btn-outline-secondary mr-3"
                        @click="selectedBarcodeUrlToPrint"
                        style="background: #404E5D;
border-radius: 2.02978px;"
                        aria-label=""
                      >
                        Print
                      </button>
                      <button
                        type="button"
                        class="swal2-confirm btn btn-secondary mr-3"
                        style="border: 0.869906px solid #000000; background: none"
                        @click="selectedBarcodeUrl = ''"
                        aria-label=""
                      >
                        <a
                          style=" color: #000000"
                          :href="selectedBarcodeUrl"
                          target="_blank"
                        >
                          Download
                        </a>
                      </button>
                      <button
                        type="button"
                        class="swal2-cancel btn btn-danger"
                        @click="selectedBarcodeUrl = ''"
                        aria-label=""
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div class="swal2-footer" style="display: none;"></div>
                </div>
                <div
                  v-for="(address, key) in business['shop_addresses']"
                  :key="key"
                  style="background: #F5F5F5; cursor: pointer; border-bottom: 1px solid #C4C4C4;"
                  class="py-2 px-2"
                >
                  <div
                    class="d-inline-flex col-12"
                    @click="selectedBarcodeUrl = address.qrcode_url"
                  >
                    <span
                      class="text-muted font-14"
                      :data-toggle="`exampleModal${key}`"
                      :data-target="`#exampleModal${key}`"
                      >{{ `${address.address}` }}
                    </span>
                    <img
                      class="ml-auto"
                      width="16px"
                      height="16px"
                      :src="address.qrcode_url"
                      alt=""
                    />
                  </div>
                </div>
                <!--              <div v-for="(address, key) in business['shop_addresses']">
                  <span class="text-muted font-14"
                    >{{ `${key + 1}. ${address.address}` }}
                  </span>
                </div>-->
              </div>
            </div>
            <div class="d-block d-md-none ">
              <div
                v-for="(business, index) in agent_profile.agent_businesses"
                :key="index"
              >
                <h6 class="font-bold mb-0 mt-3">
                  {{ business.business.trade_name }}
                </h6>
                <div
                  v-if="selectedBarcodeUrl !== ''"
                  class="swal2-container swal2-center swal2-shown"
                  style="overflow-y: auto;"
                >
                  <div
                    aria-labelledby="swal2-title"
                    aria-describedby="swal2-content"
                    class="swal2-popup swal2-modal animated fadeInDown faster swal2-noanimation"
                    tabindex="-1"
                    role="dialog"
                    aria-live="assertive"
                    aria-modal="true"
                    style="display: flex;"
                  >
                    <div class="swal2-header">
                      <ul
                        class="swal2-progresssteps"
                        style="display: none;"
                      ></ul>
                      <div
                        class="swal2-icon swal2-error"
                        style="display: none;"
                      >
                        <span class="swal2-x-mark"
                          ><span class="swal2-x-mark-line-left"></span
                          ><span class="swal2-x-mark-line-right"></span
                        ></span>
                      </div>
                      <div
                        class="swal2-icon swal2-question"
                        style="display: none;"
                      >
                        <span class="swal2-icon-text">?</span>
                      </div>
                      <div
                        class="swal2-icon swal2-warning"
                        style="display: none;"
                      >
                        <span class="swal2-icon-text">!</span>
                      </div>
                      <div class="swal2-icon swal2-info" style="display: none;">
                        <span class="swal2-icon-text">i</span>
                      </div>
                      <div
                        class="swal2-icon swal2-success"
                        style="display: none;"
                      >
                        <div
                          class="swal2-success-circular-line-left"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                        <span class="swal2-success-line-tip"></span>
                        <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div
                          class="swal2-success-fix"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                        <div
                          class="swal2-success-circular-line-right"
                          style="background-color: rgb(255, 255, 255);"
                        ></div>
                      </div>
                      <img class="swal2-image" style="display: none;" />
                      <h2 class="swal2-title" id="swal2-title"></h2>
                      <button
                        type="button"
                        class="swal2-close"
                        style="display: none;"
                      >
                        ×
                      </button>
                    </div>
                    <div class="swal2-content">
                      <div id="swal2-content" style="display: block;">
                        <img :src="selectedBarcodeUrl" width="100%" alt="" />
                      </div>
                      <input class="swal2-input" style="display: none;" /><input
                        type="file"
                        class="swal2-file"
                        style="display: none;"
                      />
                      <div class="swal2-range" style="display: none;">
                        <input type="range" /><output></output>
                      </div>
                      <select
                        class="swal2-select"
                        style="display: none;"
                      ></select>
                      <div class="swal2-radio" style="display: none;"></div>
                      <label
                        for="swal2-checkbox"
                        class="swal2-checkbox"
                        style="display: none;"
                        ><input type="checkbox"/><span
                          class="swal2-label"
                        ></span
                      ></label>
                      <textarea
                        class="swal2-textarea"
                        style="display: none;"
                      ></textarea>
                      <div
                        class="swal2-validation-message"
                        id="swal2-validation-message"
                        style="display: none;"
                      ></div>
                    </div>
                    <div class="swal2-actions" style="display: flex;">
                      <button
                        type="button"
                        class="swal2-cancel btn btn-outline-secondary mr-3"
                        @click="selectedBarcodeUrlToPrint"
                        style="background: #404E5D;
border-radius: 2.02978px;"
                        aria-label=""
                      >
                        Print
                      </button>
                      <button
                        type="button"
                        class="swal2-confirm btn btn-secondary mr-3"
                        style="border: 0.869906px solid #000000; background: none"
                        @click="selectedBarcodeUrl = ''"
                        aria-label=""
                      >
                        <a
                          style=" color: #000000"
                          :href="selectedBarcodeUrl"
                          target="_blank"
                        >
                          Download
                        </a>
                      </button>
                      <button
                        type="button"
                        class="swal2-cancel btn btn-danger"
                        @click="selectedBarcodeUrl = ''"
                        aria-label=""
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div class="swal2-footer" style="display: none;"></div>
                </div>
                <div
                  v-for="(address, key) in business['shop_addresses']"
                  :key="key"
                  style="background: #F5F5F5; cursor: pointer; border-bottom: 1px solid #C4C4C4;"
                  class="py-2 px-2"
                >
                  <div
                    class="d-inline-flex col-12"
                    @click="selectedBarcodeUrl = address.qrcode_url"
                  >
                    <span
                      class="text-muted font-14"
                      :data-toggle="`exampleModal${key}`"
                      :data-target="`#exampleModal${key}`"
                      >{{ `${address.address}` }}
                    </span>
                    <img
                      class="ml-auto"
                      width="16px"
                      height="16px"
                      :src="address.qrcode_url"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePercentage from "@/components/images/ImagePercentage";
import moment from "moment";
import { mapGetters } from "vuex";
import print from "print-js";
import * as types from "@/store/types";
import AgentPermitRenewal from "./AgentPermitRenewal";
import AgentRenewPermit from "./AgentRenewPermit";

export default {
  name: "AgentProfile",
  data() {
    return {
      resendingAgentInvite: false,
      choosenBarcode: "",
      selectedBarcodeUrl: "",
      host: location.host,
      permit_renewal: false,
      renew_permit: false,
      isRenewing: false,
      tooltipMessage: "Copy to clipboard"
    };
  },
  components: {
    AgentRenewPermit,
    AgentPermitRenewal,
    ImagePercentage
    // AgentProfileReferenceId
  },
  props: {
    agent_profile: Object,
    show_business_address: {
      default: false
    },
    showEditProfile: {
      default: false
    },
    layout: {
      default: "vertical",
      validator: function(value) {
        return ["horizontal", "vertical"].includes(value);
      }
    },
    card: {
      default: "yes",
      validator: function(value) {
        return ["yes", "no"].includes(value);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        $(".live-photo-popup").magnificPopup({
          type: "image",
          closeOnContentClick: true,
          mainClass: "mfp-img-mobile mfp-with-zoom",
          image: {
            verticalFit: true
          },
          zoom: {
            enabled: true, // By default it's false, so don't forget to enable it
            duration: 300, // duration of the effect, in milliseconds
            easing: "ease-in-out", // CSS transition easing function
            // The "opener" function should return the element from which popup will be zoomed in
            // and to which popup will be scaled down
            // By defailt it looks for an image tag:
            opener: function(openerElement) {
              // openerElement is the element on which popup was initialized, in this case its <a> tag
              // you don't need to add "opener" option if this code matches your needs, it's defailt one.
              return openerElement.is("img")
                ? openerElement
                : openerElement.find("img");
            }
          }
        });
      }, 0);
    });

    let vm = this;
    if (
      this.agent_profile.verification.status === "not_verified" &&
      this.$route.name === "AgentDashboard"
    ) {
      this.$swal({
        title: "Application failed",
        html: `<p>Your application has failed because of the following reason: <span class="font-bold">${this.agent_profile.verification.identity_info.reason}</span>
<br/>Do you want to review the application and re-submit?</p>`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, please!",
        cancelButtonText: "Cancel"
      }).then(result => {
        if (result.value) {
          this.$router.push({
            name: "NewVerificationContainer",
            params: { verification_id: vm.agent_profile.verification.id }
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      admin: "getCurrentUser",
      verifications: "getAgentVerifications",
      transactions: "getAgentVerifications",
      profile: "getCurrentUser",
      current_user: "getCurrentUser"
    }),
    verifiedLicenses() {
      const licenses = this.agent_profile.verification.license_types;
      return licenses[licenses.length - 1];
    },
    businessAddresses() {
      if (this.current_user === null) {
        return [];
      }
      for (let i = 0; i < this.agent_profile.agent_businesses.length; i++) {
        if (
          this.agent_profile.agent_businesses[i].business._id ===
          this.profile.business._id
        ) {
          return this.agent_profile.agent_businesses[i]["shop_addresses"];
        }
      }
      return [];
    },
    hasPermitExpired() {
      const licenses = this.agent_profile.verification.license_types;
      const license = licenses[licenses.length - 1];
      return license.status === "expired";
    }
  },
  methods: {
    async handleRenewPermit() {
      const licenses = this.agent_profile.verification.license_types;
      const license = licenses[licenses.length - 1].license_type;

      try {
        this.isRenewing = true;
        await this.$store.dispatch(types.SINGLE_PERMIT_RENEW, {
          verification: this.agent_profile.verification.id,
          license: license._id
        });

        this.$notify.success({
          message: "Permit renewed successfully"
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      } finally {
        this.isRenewing = false;
      }
    },
    async resendAgentInvite() {
      try {
        this.resendingAgentInvite = true;
        await this.$store.dispatch(
          types.RESEND_AGENT_INVITE,
          this.agent_profile
        );

        this.$notify.success({
          message: "Agent invite sent successfully"
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      } finally {
        this.resendingAgentInvite = false;
      }
    },
    expiryDate(date) {
      return moment(date).add(5, "years");
    },
    getBusinessName(business) {
      for (let i = 0; i < this.agent_profile.agent_businesses.length; i++) {
        if (business === this.profile.business._id) {
          return this.agent_profile.agent_businesses[i]["shop_addresses"];
        }
      }
    },
    sendRequestToAgent() {
      this.$swal({
        title: "Are you sure?",
        text: "You are about to send an association request to this agent.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, please send request.",
        cancelButtonText: "No, cancel!"
      }).then(result => {
        if (result.value) {
          console.log("canceled :>> ");
        }
      });
    },
    selectedBarcodeUrlToPrint() {
      print({
        printable: this.selectedBarcodeUrl,
        type: "image"
        // modalMessage: 'Retrieving document from external server...'
      });
      this.selectedBarcodeUrl = "";
    },
    onCopy() {
      this.tooltipMessage = "Copied!";
      setTimeout(() => (this.tooltipMessage = "Copy to clipboard"), 600);
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.renew_permit) {
      this.renew_permit = true;
    } else {
      this.renew_permit = false;
    }
    if (to.query.permit_renewal) {
      this.permit_renewal = true;
    } else {
      this.permit_renewal = false;
    }
    next();
  }
};
</script>

<style lang="scss" scoped>
.license-photo {
  top: 45%;
  left: 60%;
  object-fit: cover;
  object-position: center;
  width: calc(100px + 0.5vw);
  height: calc(120px + 0.5vw);
}
.license-number {
  font-size: calc(22px + 0.5vw);
  top: 7%;
  left: 4%;
}
.license-name {
  top: 26%;
  left: 4%;
  font-size: calc(24px + 0.5vw);
}
.license-date {
  left: 4%;
  font-size: calc(10px + 0.5vw);
  font-weight: 600;
  &--issued {
    top: 67%;
  }
  &--expiry {
    top: 82%;
  }
}

.view-biz-address {
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: inset 1px 4px 6px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}
.permit-details {
  font-weight: 200;
}
</style>
