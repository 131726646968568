import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.INVITE_NEW_COMPANY_ACTION](_, payload) {
    let company = (await api.inviteNewCompany(payload)).data;
    return company;
  },
  async [types.GET_ALL_COMPANIES_ACTION]({ commit }, payload) {
    let companies = await api.getAllCompanies(payload);
    commit(types.SET_COMPANIES_LOG, companies.data);
    commit(types.SET_COMPANIES_META, companies.meta);
    return companies;
  },
  async [types.INVITE_NEW_COMPANY_ACTION](_, payload) {
    let company = (await api.inviteNewCompany(payload)).data;
    return company;
  },
  async [types.GET_ALL_BUSINESSES]({ commit }) {
    let response = await api.getAllBusinesses();
    commit(types.SET_ALL_BUSINESSES, response.data);
    return response.data;
  },
  async [types.GET_ALL_BUSINESS_LICENSE_CATEGORIES]({ commit }) {
    let response = await api.getAllBusinessLicenseCategories();
    commit(types.SET_BUSINESS_LICENSE_CATEGORIES, response.data);
    return response.data;
  }
};
export default actions;
