import http from "@/helpers/http";

class api {
  static get axios() {
    return http;
  }

  static inviteNewUser(payload) {
    let { email, role } = payload;
    return http.post("/businesses/users/invite", {
      email,
      role
    });
  }

  static getAllUsers(payload) {
    if (payload && payload.role) {
      return http.get("/businesses/users", {
        params: payload
      });
    }
    return http.get("/businesses/users", {
      params: { page: payload.page }
    });
  }
  static searchAllUsers(payload) {
    return http.get("/businesses/users/search", {
      params: {
        query: payload.query,
        page: payload.page,
        paginate: payload.paginate
      }
    });
  }

  static reinviteUser(user) {
    return http.post("/businesses/users/re-invite", { email: user.email });
  }

  static changeUserRole(payload) {
    let { user, role } = payload;
    return http.post("/businesses/users/" + user.id + "/reassign-role", {
      role
    });
  }
  static activateUser(user) {
    return http.post("/businesses/users/" + user.id + "/activate");
  }
  static deactivateUser(user) {
    return http.post("/businesses/users/" + user.id + "/deactivate");
  }
  static addMultipleUsers(payload) {
    let { csv } = payload;
    return http.post("/businesses/users/invite-multiple", {
      csv_file_url: csv
    });
  }
}

export default api;
