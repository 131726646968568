import http from "@/helpers/http";

class UploadService {
  static get axios() {
    return http;
  }
  static uploadCsv(payload) {
    let { file } = payload;
    let form = new FormData();
    form.append("file", file);
    return http.post("/uploads/csv", form, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }
}

export default UploadService;
