<template>
  <div>
    <div class="py-3 flex-wrap d-flex align-items-center" style="gap: 2rem">
      <div class="w-100 flex-nowrap" style="max-width: 12rem">
        <div class="m-b-10">
          <span class="text-muted font-14">Permit Type:</span>
        </div>
        <div>
          <span class="font-bold font-16">Gaming / Lottery</span>
        </div>
      </div>
      <div class="w-100 flex-nowrap" style="max-width: 12rem">
        <div class="m-b-10">
          <span class="text-muted font-14">Date I joined:</span>
        </div>
        <div>
          <span class="font-bold font-16">{{ formatDateJoined }}</span>
        </div>
      </div>
      <div class="w-100 flex-nowrap" style="max-width: 12rem">
        <div class="m-b-10">
          <span class="text-muted font-14">Status:</span>
        </div>
        <div>
          <span
            class="label text-capitalize font-bold rounded-0 text-nowrap font-14"
            :class="[
              license.status === 'verified' ||
              license.status === 'success' ||
              license.status === 'active'
                ? 'label-light-success'
                : license.status === 'expired'
                ? 'label-light-danger'
                : license.status === 'pending'
                ? 'label-light-warning'
                : ''
            ]"
            >{{ license.status | remove_underscore }}</span
          >
        </div>
      </div>
      <div class="w-100 flex-nowrap" style="max-width: 12rem">
        <div class="m-b-10">
          <span class="text-muted font-14">Expiry:</span>
        </div>
        <div class="inline-d-flex align-center">
          <span class="font-bold mr-3 font-16">{{ formatExpiry }}</span>
          <router-link
            :to="{
              name: 'AgentDashboard',
              params: { profile_id: agentProfile.id },
              query: { renew_permit: 1 }
            }"
            class="btn btn-warning btn-sm"
          >
            Renew
          </router-link>
        </div>
      </div>
    </div>
    <hr style="height: 1.5px; background-color: #e3e3e3" />
    <section class="my-2">
      <h2 class="font-18 font-bold">My Shop address(es)</h2>
      <template v-if="shopAddresses.length">
        <p v-for="(address, i) in shopAddresses" :key="i" class="p-3 b-b">
          <span class="text-black font-medium ">{{ address }}</span>
        </p>
      </template>
      <div
        v-else
        class="w-100 d-flex align-items-center justify-content-center"
        style="height: 8rem"
      >
        <p>You do not have any registered address</p>
      </div>
      <div
        v-if="addNewAddress"
        class="p-4 rounded mt-5"
        style="background-color: rgba(0, 0, 0, 0.05)"
      >
        <h3 class="font-bold font-16">
          Add Shop Address
        </h3>
        <div class="row py-4">
          <div class="col-md-4">
            <div class="flex-1" style="position: relative">
              <button
                class="d-flex align-items-center justify-content-between font-14 py-2 px-3 w-100 rounded bg-white"
                style="border: 1px solid rgba(0, 0, 0, 0.15)"
                @click="handleDropdownClick"
              >
                <span class="text-white-50">Select operators</span>
                &#9662;
              </button>
              <div
                v-if="operatorsDropdown"
                class="operatorsDropdownBackdrop"
                @click="operatorsDropdown = false"
              ></div>
              <form v-if="operatorsDropdown" class="operatorsDropdown">
                <checkbox-input
                  v-for="partner in agentPartners"
                  :key="partner.id"
                  v-model="partners"
                  class="font-14 my-3"
                  name="operator"
                  :value="partner.id"
                  >{{ partner.name }}</checkbox-input
                >
              </form>
            </div>
          </div>
          <div class="col-md-8">
            <vue-google-autocomplete
              id="map2"
              classname="form-control address-input-field"
              placeholder="Enter new address"
              v-on:placechanged="getAddressDataCreate"
            >
            </vue-google-autocomplete>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-end"
          style="gap: 0.75rem"
        >
          <button
            class="btn btn-warning"
            style="width: 8rem"
            @click="updateAgentAddress"
          >
            Submit
          </button>
          <button
            class="btn btn-outline-dark"
            style="width: 8rem; border: 1px solid #a2a2a2"
            @click="addNewAddress = !addNewAddress"
          >
            Cancel
          </button>
        </div>
      </div>
      <div class="col-12 px-4">
        <button
          class="col-12 px-0 py-2 text-center border-0 my-5"
          style="background-color: #FFB42E; color: white"
          @click="addNewAddress = !addNewAddress"
          v-if="!addNewAddress"
        >
          Add Address
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import * as types from "@/store/types";
import CheckboxInput from "@/components/checkbox";
// import AgentProfileReferenceId from "./AgentProfileReferenceId";

export default {
  name: "AgentPermitRenewal",
  components: { VueGoogleAutocomplete, CheckboxInput },
  data() {
    return {
      operatorsDropdown: false,
      addNewAddress: "",
      addAddress: "",
      partners: [],
      selectedBarcodeUrl: ""
    };
  },
  props: {
    agentProfile: Object
  },
  computed: {
    license() {
      const activeLicense = this.agentProfile.verification.license_types;
      const license = activeLicense[activeLicense.length - 1];
      return {
        id: license._id,
        expiry: license.expiration_date,
        status: license.status
      };
    },
    formatDateJoined() {
      return this.formatDateTime(this.agentProfile.created_at);
    },
    formatExpiry() {
      return this.formatDateTime(this.license.expiry);
    },
    agentPartners() {
      return this.agentProfile.agent_businesses
        .filter(item => item.status === "active")
        .map(partner => ({
          id: partner.business.id,
          name: partner.business.trade_name
        }));
    },
    shopAddresses() {
      let shopAddresses = [];
      this.agentProfile.agent_businesses
        .filter(item => item.status === "active")
        .forEach(shops => {
          const addresses = shops.shop_addresses.map(shop => {
            if (shopAddresses.includes(shop)) return;
            return shop.address;
          });
          shopAddresses.push(...addresses);
        });
      return shopAddresses;
    }
  },
  methods: {
    formatDateTime(date = new Date(), config) {
      let options = config;
      if (!options) {
        options = { month: "numeric", day: "numeric", year: "numeric" };
      }
      return new Intl.DateTimeFormat("en-NG", options).format(new Date(date));
    },
    handleDropdownClick() {
      if (this.agentPartners) {
        this.$notify.error({
          message: "You are not associated with any operators."
        });
        return;
      }
      this.operatorsDropdown = !this.operatorsDropdown;
    },
    async updateAgentAddress() {
      if (!this.partners.length) {
        this.$notify.error({
          message: "Please select an operator in this address"
        });
        return;
      }
      if (!this.newAddress) {
        this.$notify.error({ message: "Please input an address" });
        return;
      }
      this.loading = true;
      try {
        await this.$store.dispatch(types.ADD_NEW_AGENT_ADDRESS, {
          business_id: this.partners[0],
          address: this.newAddress,
          latitude: this.newAddressLatitude,
          longitude: this.newAddressLongitude,
          verification_id: this.agentProfile.verification.id
        });
        this.newAddress = "";
        this.addNewAddress = false;
        this.currentRequest = null;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
        this.currentRequest = null;
      }
    },
    getAddressDataCreate(addressData) {
      if ("street_number" in addressData) {
        this.newAddress = `${addressData.street_number}, ${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      } else {
        this.newAddress = `${addressData.route}, ${addressData.locality}, ${addressData.country}`;
      }
      this.newAddressLatitude = addressData.latitude;
      this.newAddressLongitude = addressData.longitude;
    }
  }
};
</script>
<style scoped>
.operatorsDropdownBackdrop {
  inset: 0;
  z-index: 99;
  position: fixed;
}
.operatorsDropdown {
  background-color: white;
  right: 0;
  left: 0;
  top: 100%;
  position: absolute;
  overflow-y: auto;
  border-radius: 4px;
  z-index: 999;
  padding-top: 1rem;
  padding-top: 1rem;
  max-height: 16rem;
}
input[type="checkbox"] {
  appearance: none;
  color: currentColor;
  width: 1.25rem;
  border-radius: 4px;
  height: 1.25rem;
  border: 1px solid #d7d7d7;
  display: grid;
  place-content: center;
}
input[type="checkbox"]:checked {
  border: 1px solid rgb(255, 180, 46);
  background-color: rgb(255, 180, 46);
}
input[type="checkbox"]::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 0.75rem 0.75rem #fff;
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.section + .section {
  margin-top: 0.25rem;
}
</style>
