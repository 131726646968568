import http from "@/helpers/http";
import StorageService from "@/helpers/storage";

class api {
  static get axios() {
    return http;
  }

  static updateBusinessProfile(payload) {
    const business_id = StorageService.getBusinessProfileID();
    return http.put("/businesses/" + business_id, payload);
  }
  static updateSuperAgentSettings(payload) {
    return http.put("/verifications/price", payload);
  }
  static fetchSuperAgentSettings() {
    return http.get("/verifications/permit-settings");
  }
}

export default api;
