import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  address_verifications_log: [],
  address_verifications_meta: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
