import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  business_account_balance: null,
  business_transaction_total: null,
  agent_analytics: null,
  dashboard_metrics: null,
  verification_by_cities: null,
  verification_metrics: null,
  identity_metrics: null
};

export default {
  state,
  actions,
  getters,
  mutations
};
