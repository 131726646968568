<template>
  <div>
    <div
      class="modal animated fade pulse_ modal-border"
      id="bulk-upload-modal"
      data-backdrop="static"
      tabindex="-1"
      role="dialog"
      aria-labelledby="change-role-modal"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-border-radius">
          <div class="modal-header b-0 p-l-30">
            <h3 class="modal-title text-center font-medium">Bulk upload</h3>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body p-b-0">
            <div v-if="error" class="alert alert-danger px-3" role="alert">
              {{ error }}
            </div>
            <form
              id="change-password-form"
              class="form-horizontal"
              @submit.prevent="submitBulkUpload"
            >
              <template v-if="!showUploadCSV">
                <div class="form-group col-md-12 mb-4">
                  <input
                    type="radio"
                    value="onboard_agent"
                    v-model.trim="bulk_upload_action"
                    id="onboard_agent"
                    class="sr-only"
                  />
                  <label for="onboard_agent" class="modal-label">
                    <img
                      src="/static/bulk-upload.svg"
                      alt="bulk upload icon"
                      style="width: 1rem"
                    />
                    Onboard agents only
                  </label>
                </div>
                <div class="form-group col-md-12 mb-4">
                  <input
                    type="radio"
                    value="renew_permit"
                    v-model.trim="bulk_upload_action"
                    id="renew_permit"
                    class="sr-only"
                  />
                  <label for="renew_permit" class="modal-label">
                    <img
                      src="/static/renew_permit.svg"
                      alt="bulk upload icon"
                      style="width: 1rem"
                    />
                    Renew permits only
                  </label>
                </div>
                <div class="form-group col-md-12 mb-4">
                  <input
                    type="radio"
                    value="onboard_agent_and_renew_permit"
                    v-model.trim="bulk_upload_action"
                    id="onboard_agent_and_renew_permit"
                    class="sr-only"
                  />
                  <label
                    for="onboard_agent_and_renew_permit"
                    class="modal-label"
                  >
                    <img
                      src="/static/onboard_renew.svg"
                      alt="bulk upload icon"
                      style="width: 1rem"
                    />
                    Onboard Agents and Renew Permit
                  </label>
                </div>
              </template>
              <div v-if="bulk_upload_action" class="px-3">
                <h5 v-if="!showUploadCSV" class="">
                  Fill input field
                </h5>
                <h5 v-if="showUploadCSV">
                  You’ve selected {{ bulk_upload_action.replaceAll("_", " ") }}
                </h5>
                <div v-if="false" class="form-group my-4">
                  <label for="permit_type" class="control-label">
                    Permit Type
                  </label>
                  <select
                    class="form-control custom-select rounded-0"
                    v-model.trim="permit"
                    :class="{ 'form-error animated shake': $v.permit.$error }"
                  >
                    <option value="" disabled>-- Select permit type --</option>
                    <option
                      v-for="permit in permits"
                      :key="permit.category_slug"
                      :value="permit.category_slug"
                      >{{ permit.category_name }}</option
                    >
                  </select>
                </div>
                <div class="form-group mb-4">
                  <label for="Operator" class="control-label">
                    Operator
                  </label>
                  <select
                    class="form-control custom-select rounded-0"
                    v-model.trim="operator"
                    :class="{ 'form-error animated shake': $v.operator.$error }"
                  >
                    <option value disabled>-- Select operator --</option>
                    <option
                      v-for="operator in operators"
                      :key="operator.id"
                      :value="operator.id"
                      >{{
                        operator.trade_name || operator.business_name
                      }}</option
                    >
                  </select>
                </div>
                <csv-upload
                  v-if="showUploadCSV"
                  :key="progress"
                  :uploadProgress="progress"
                  :action="action"
                  @upload-csv="file = $event"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer b-0">
            <div v-if="showUploadCSV">
              <button
                type="button"
                class="btn btn-link text-warning"
                @click="showUploadCSV = false"
              >
                Go Back
              </button>
            </div>
            <div class="justify-content-end d-flex" style="flex-grow: 1">
              <button
                v-if="showUploadCSV"
                type="button"
                :disabled="isLoading"
                @click="submitBulkUpload"
                class="btn btn-warning rounded waves-effect p-10"
              >
                Onboard Agents
                <span v-if="isLoading">
                  <i class="mdi mdi-loading mdi-spin"></i>
                </span>
              </button>
              <button
                v-else
                type="button"
                @click="handleNext"
                class="btn btn-warning rounded waves-effect p-10"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <success-modal :email="email" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import SuccessModal from "./success-modal";
import CsvUpload from "@/components/csv-upload";

export default {
  components: { CsvUpload, SuccessModal },
  props: {
    permits: Array,
    operators: Array
  },
  data() {
    return {
      bulk_upload_action: "",
      operator: "",
      permit: "online-sports-betting",
      csvLink: "",
      file: null,
      progress: 0,
      isLoading: false,
      error: "",
      email: "",
      showUploadCSV: false
    };
  },
  validations: {
    operator: { required },
    permit: { required }
  },
  computed: {
    action() {
      if (this.bulk_upload_action === "renew_permit") {
        return types.DOWNLOAD_SAMPLE_BULK_RENEW_CSV;
      }
      return types.DOWNLOAD_SAMPLE_BULK_UPLOAD_CSV;
    }
  },
  methods: {
    handleNext() {
      if (!this.bulk_upload_action) {
        this.error = "First select the action you want to carry out";
        return;
      }
      if (!this.permit) {
        this.error = "Permit type is required";
        return;
      }
      if (!this.operator && this.bulk_upload_action.includes("onboard_agent")) {
        this.error = "Operator is required";
        return;
      }
      this.showUploadCSV = true;
      this.error = "";
    },
    async handleCsvUpload() {
      try {
        const response = await this.$store.dispatch(types.UPLOAD_CSV, {
          file: this.file
        });
        this.csvLink = response.location;
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    async submitBulkUpload() {
      if (!this.file) {
        this.error = "You have not selected a CSV file.";
        return;
      }

      this.isLoading = true;
      await this.handleCsvUpload();

      if (!this.csvLink) return;

      const business = this.operators.find(
        operator => operator.id === this.operator
      );
      const license_type = this.permits.find(
        permit => permit.category_slug === this.permit
      );
      const payload = {
        license_type: license_type?._id,
        business_id: business?.id,
        csv_link: this.csvLink
      };
      const type =
        this.bulk_upload_action === "renew_permit"
          ? types.SUBMIT_BULK_RENEW_CSV
          : types.SUBMIT_BULK_UPLOAD_CSV;

      if (this.bulk_upload_action.includes("onboard_agent")) {
        payload["onboard_agent_only"] =
          this.bulk_upload_action === "onboard_agent";
      }

      try {
        const res = await this.$store.dispatch(type, payload);
        this.isLoading = false;
        this.email = res.data.contactEmail;
        this.$notify.success({ message: "Agents successfully uploaded" });
        this.resetForm();
        this.closeModal();
        this.handleSuccessModal();
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    },
    resetForm() {
      this.operator = "";
      this.file = null;
      this.error = "";
      this.email = "";
      this.showUploadCSV = false;
      this.$v.$reset();
    },
    handleSuccessModal() {
      $("#success-modal").modal("show");
    },
    closeModal() {
      $("#bulk-upload-modal").modal("hide");
    }
  }
};
</script>

<style scoped>
.modal-label {
  padding: 1rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: 300ms ease-in-out background-color;
}
.modal-label:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
input[type="radio"]:checked + .modal-label {
  border: 1px solid #ffbc34;
}
</style>
