import http from "@/helpers/http";

class Api {
  static get axios() {
    return http;
  }

  static loginUser({ email, password, auth_type, challenge_token }) {
    if (auth_type === "otp") {
      let otp = password;
      return http.post("/auth/token", {
        email,
        otp,
        auth_type,
        challenge_token
      });
    }
    return http.post("/auth/token", {
      email,
      password,
      auth_type,
      challenge_token
    });
  }
  static sendPasswordResetLink(email) {
    return http.post("/auth/password/forgot", {
      email
    });
  }
  static verifyResetPasswordToken(token) {
    return http.get("/auth/token/" + token + "/verify");
  }
  static resetUserPassword(payload) {
    return http.post("/auth/token/" + payload.token + "/reset/password", {
      password: payload.password,
      confirm_password: payload.confirm_password
    });
  }
  static verifyUserRegisterToken(token) {
    return http.get("/auth/user/token/" + token + "/verify");
  }
  static registerUser({
    first_name,
    middle_name,
    last_name,
    mobile,
    gender,
    photo,
    password,
    token
  }) {
    return http.post("/auth/register/" + token, {
      first_name,
      middle_name,
      last_name,
      mobile,
      gender,
      photo,
      password
    });
  }

  static lookupEmail(email) {
    return http.post("/auth/lookup", {
      email
    });
  }
}

export default Api;
