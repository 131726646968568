import iziToast from "izitoast";

iziToast.settings({
  theme: "light",
  timeout: 3000,
  resetOnHover: false,
  pauseOnHover: true,
  position: "topCenter",
  icon: "mdi mdi-exclamation",
  transitionIn: "fadeInDown"
  // onOpening: function () {   console.log('callback opening!'); }, onClosing:
  // function () {   console.log("callback closing!"); }
});

const success = message_obj => {
  let new_object = Object.assign(
    {
      title: "Success",
      icon: "mdi mdi-check-circle"
    },
    message_obj
  );
  iziToast.success(new_object);
};

const error = message_obj => {
  let new_object = Object.assign(
    {
      title: "Error",
      icon: "mdi mdi-cancel"
    },
    message_obj
  );
  iziToast.error(new_object);
};

const warning = message_obj => {
  let new_object = Object.assign(
    {
      title: "Warning",
      icon: "mdi mdi-alert"
    },
    message_obj
  );
  iziToast.warning(new_object);
};

export default {
  warning,
  success,
  error
};
