<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card bordered-box pagination-center">
        <div class="card-block">
          <h3 class="card-title text-capitalize">Transaction History</h3>
          <div class="row" v-if="false">
            <div class="col-xs-12 col-sm-6 offset-sm-6 col-md-4 offset-md-8">
              <div class="form-group m-b-10">
                <input
                  type="text"
                  class="form-control rounded-0"
                  placeholder="Search using Agent LSLGA Permit Number..."
                  v-model.trim="search_term"
                  @input="searchTransactions"
                />
                <p
                  class="text-danger font-14 font-bold mt-1"
                  v-if="isSearching && transactions.length === 0"
                >
                  {{ $route.query.search_term }}
                </p>
              </div>
            </div>
          </div>
          <form id="filter-report-form" class="filter-report-form">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group m-b-5">
                  <label class="control-label" for="user">Select User</label>
                  <v-select
                    id="user"
                    v-model.trim="user"
                    @search="searchUsers"
                    :options="users"
                    :filterable="false"
                    label="full_name"
                    placeholder="Type to search for users..."
                  >
                    <template slot="no-options">No results found</template>
                  </v-select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group m-b-5">
                  <label for="status" class="control-label">
                    Status
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    id="status"
                    class="form-control custom-select rounded-0"
                    v-model.trim="status"
                    :class="{ 'form-error animated shake': $v.status.$error }"
                  >
                    <option value disabled>-- select status --</option>
                    <option value="all">All</option>
                    <option value="initiated">Initiated</option>
                    <option value="abandoned">Abandoned</option>
                    <option value="success">Success</option>
                    <option value="failed">Failed</option>
                    <option value="reversed">Reversed</option>
                    <option value="declined">Declined</option>
                    <option value="fraud">Fraud</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group m-b-5">
                  <label
                    for="start_date"
                    class="control-label"
                    style="display:block"
                  >
                    Start Date
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <div class="clearfix">
                    <flat-pickr
                      v-model.trim="start_date"
                      :class="{
                        'form-error animated shake': $v.start_date.$error
                      }"
                      class="form-control rounded-0"
                      :config="startDateConfig"
                      placeholder="Start Date"
                    ></flat-pickr>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group m-b-5">
                  <label
                    for="end_date"
                    class="control-label"
                    style="display:block"
                  >
                    End Date
                    <!-- <span class="text-danger">*</span> -->
                  </label>
                  <!-- <div
                    class="clearfix"
                    v-tooltip="!start_date ? 'Please select a start date' : ''"
                  > -->
                  <flat-pickr
                    v-model.trim="end_date"
                    :class="{
                      'form-error animated shake': $v.end_date.$error
                    }"
                    class="form-control rounded-0"
                    :config="endDateConfig"
                    placeholder="End Date"
                  ></flat-pickr>
                  <!-- </div> -->
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group m-b-5 float-right">
                  <button
                    class="btn btn-warning normalize-filter-btn rounded-0 m-r-5"
                    :disabled="isLoading || $v.$invalid"
                    @click.stop.prevent="filterTransactionLogs"
                  >
                    <i class="mdi mdi-filter"></i>
                    Filter
                  </button>
                  <span
                    v-tooltip="'CSV will be sent to your email address'"
                    v-if="$User.hasPermission('transaction-export')"
                  >
                    <button
                      :disabled="isLoading || $v.$invalid"
                      @click.stop.prevent="
                        filterTransactionLogs({ exportCsv: true })
                      "
                      class="btn btn-warning normalize-filter-btn rounded-0"
                    >
                      <i class="mdi mdi-cloud-download"></i>
                      Export CSV
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </form>

          <div class="row">
            <div class="col-md-12">
              <template v-if="!isSearching">
                <div class="p-t-10 p-b-10" v-if="isFilter">
                  <label class="text-info font-normal font-14">
                    Transactions log filtered by
                    <span
                      class="badge badge-pill badge-default mx-1"
                      v-if="$route.query.user_id"
                      ><strong>User</strong></span
                    >
                    <span
                      class="badge badge-pill badge-default mx-1"
                      v-if="$route.query.status"
                      ><strong>Status: </strong
                      >{{ $route.query.status | remove_underscore }}</span
                    ><span
                      v-if="$route.query.start_date || $route.query.end_date"
                    >
                      between {{ startDateDefault | date }} and
                      {{ endDateDefault | date }}
                    </span>
                  </label>
                  <button
                    @click.stop="clearFilter"
                    class="ml-3 btn btn-outline-info btn-xs font-bold"
                  >
                    clear filter
                  </button>
                </div>
                <div class="p-t-10 p-b-10" v-if="!isFilter">
                  <label class="text-info font-medium font-14"
                    >Showing all transactions</label
                  >
                </div>
              </template>
              <template v-else>
                <div class="p-t-10 p-b-10">
                  <label class="text-info font-medium font-14">
                    <span class="p-r-10"
                      >Showing all transactions by LSLGA permit Number "{{
                        $route.query.search_term || ""
                      }}"</span
                    >
                    <button
                      @click.stop="clearFilter"
                      class="p-l-5 btn btn-outline-info btn-xs font-bold"
                    >
                      clear search
                    </button>
                  </label>
                </div>
              </template>
              <div class="table-responsive">
                <template v-if="transactions.length > 0">
                  <table class="table table-hover">
                    <thead>
                      <tr class="link-row">
                        <th width="20" class="text-center">#</th>
                        <th width="10">Status</th>
                        <th class="text-nowrap">Transaction ID</th>
                        <th>Paid</th>
                        <th>Payment Type</th>
                        <th>Type</th>
                        <th
                          class="text-nowrap"
                          v-if="$User.hasPermission('transaction-reconcile')"
                        >
                          Reconciled By
                        </th>
                        <th width="10" class="text-nowrap">Initiated By</th>
                        <th>Action</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <!-- <router-link
                        tag="tr"
                        class="link-row"
                        v-for="(transaction, index) in transactions"
                        :to="''"
                        :key="transaction.id"
                      > -->
                      <tr
                        class="link-row"
                        v-for="(transaction, index) in transactions"
                        :key="transaction.id"
                      >
                        <td class="text-center">
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            {{ index + pagination.from }}
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            <span
                              class="label font-bold text-capitalize rounded-0"
                              :class="[
                                transaction.status === 'success'
                                  ? 'label-light-success'
                                  : transaction.status === 'failed'
                                  ? 'label-light-danger'
                                  : transaction.status === 'fraud'
                                  ? 'label-light-danger'
                                  : transaction.status === 'abandoned'
                                  ? 'label-light-warning'
                                  : transaction.status === 'initiated'
                                  ? 'label-light-megna'
                                  : 'label-light-primary'
                              ]"
                              >{{ transaction.status }}</span
                            >
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            {{ transaction.id }}
                          </router-link>
                        </td>
                        <td class="text-capitalize">
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            <!--                            ₦{{-->
                            <!--                              (parseInt(transaction.amount_in_kobo_approved) /-->
                            <!--                                100)-->
                            <!--                                | amount-->
                            <!--                            }}-->
                            {{
                              transaction.status === "success"
                                ? "Paid"
                                : "Unpaid"
                            }}
                          </router-link>
                        </td>
                        <td class="text-capitalize">
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            {{
                              transaction.payment_mode
                                | remove_underscore
                                | title_case
                            }}
                          </router-link>
                        </td>
                        <td>
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            {{ transaction.item_type | title_case }}
                          </router-link>
                        </td>
                        <td v-if="$User.hasPermission('transaction-reconcile')">
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            <template v-if="transaction.reconciled_by">{{
                              transaction.reconciled_by.first_name +
                                " " +
                                (transaction.reconciled_by.middle_name
                                  ? transaction.reconciled_by.middle_name + " "
                                  : "") +
                                transaction.reconciled_by.last_name
                            }}</template>
                          </router-link>
                        </td>
                        <td class="text-capitalize">
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            <template
                              v-if="
                                transaction.user &&
                                  transaction.user.last_name &&
                                  transaction.user.last_name.value
                              "
                              >{{ transaction.user.last_name.value }}
                              {{ transaction.user.first_name }}</template
                            >
                            <template
                              v-else-if="
                                transaction.user &&
                                  transaction.user.last_name &&
                                  transaction.user.last_name.value !== ''
                              "
                              >{{ transaction.user.last_name }}
                              {{ transaction.user.first_name }}</template
                            >
                            <template v-else>Not available</template>
                          </router-link>
                        </td>
                        <td>
                          <button
                            v-if="
                              transaction.status === 'initiated' &&
                                transaction.payment_mode !== 'bank_transfer'
                            "
                            class="btn btn-xs btn-warning"
                            :disabled="transaction.isRequerying"
                            @click.stop="
                              requeryTransaction(transaction.id, index)
                            "
                          >
                            Re-query
                            <span
                              v-if="transaction.isRequerying"
                              class="mdi mdi-loading mdi-spin ml-1"
                            ></span>
                          </button>
                        </td>
                        <td class="text-capitalize">
                          <router-link
                            :to="{
                              name: 'TransactionView',
                              params: { transaction_id: transaction.id }
                            }"
                          >
                            {{ transaction.created_at | long_date }}
                          </router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="p-30 test font-medium text-muted">
                    <span
                      >Showing {{ pagination.from }} to {{ pagination.to }} of
                      {{ pagination.total }} entries</span
                    >
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    <img
                      src="/static/empty.svg"
                      class="img-fluid mb-3"
                      alt="empty"
                      style="width: 50px;"
                    />
                    <p class="font-bold font-14">
                      There are no transactions to display
                    </p>
                  </div>
                </template>
              </div>
              <div class="text-center p-l-30 m-t-20">
                <pagination
                  :data="pagination"
                  :limit="4"
                  :show-disabled="true"
                  @pagination-change-page="getLogByPage"
                ></pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import eventBus from "@/helpers/event";
import debounce from "@/helpers/debounce";

export default {
  mounted() {
    console.log(this.transactions);
    eventBus.$on("lslb:transaction:refresh-transaction-log", () => {
      this.getLogByPage(this.pagination.current_page);
    });
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      user: "",
      users: [],
      status: this.$route.query.status || "",
      start_date: this.$route.query.start_date || "",
      end_date: this.$route.query.end_date || "",
      search_term: this.$route.query.search_term || "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      isLoading: false,
      search_response: ""
    };
  },
  validations: {
    user: {},
    status: { required },
    start_date: {},
    end_date: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      transactions: "getTransactionsLog",
      pagination: "getTransactionsLogPagination",
      business_profile: "getBusinessProfile"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment(this.$store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        )
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return this.$route.query.user_id || this.$route.query.status;
    },
    isSearching() {
      return this.$route.query.search_term;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterTransactionLogs(options = {}) {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let { start_date, end_date, user, status } = this;
          let { exportCsv } = options;

          if (exportCsv) {
            let response = await store.dispatch(
              types.EXPORT_TRANSACTIONS_ACTION,
              {
                start_date: start_date || this.startDateDefault,
                end_date: end_date || this.endDateDefault,
                user_id: (user && user.id) || "all",
                status
              }
            );
            this.$notify.success({
              message: response.message
            });
          } else {
            let query = {
              ...(status && status !== "all" && { status }),
              ...(user && user.id && { user_id: user.id }),
              ...(start_date && { start_date }),
              ...(end_date && { end_date })
            };
            this.$router.push({
              name: this.$route.name,
              query
            });
          }
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      let { start_date, end_date, user_id, status } = this.$route.query;

      let query = {
        [Date.now()]: null,
        ...(status && { status }),
        ...(user_id && { user_id }),
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date })
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    async clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.status = "";
        this.start_date = "";
        this.end_date = "";
        this.search_term = "";
        this.user = "";
        this.$v.$reset();
      });
    },
    searchUsers: debounce(async function(search, loading) {
      if (search.trim()) {
        try {
          loading(true);

          let response = await Promise.all([
            store.dispatch(types.SEARCH_USERS_ACTION, {
              query: search,
              paginate: 0
            }),

            store.dispatch(types.SEARCH_AGENTS_ACTION, {
              query: search,
              paginate: 0
            })
          ]);

          let [users, agents] = response;
          let total = [...users.data, ...agents.data];

          this.users = total.map(user => {
            user.role ? (user.type = "User") : (user.type = "Agent");
            user.full_name = `${user.first_name} ${user.middle_name} ${user
              .last_name.value || user.last_name} - ${user.type}`;
            return user;
          });
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          loading(false);
        }
      }
    }, 500),
    searchTransactions: debounce(async function(e) {
      this.search_response = "";
      if (e.target.value.trim()) {
        try {
          await store.dispatch(types.SEARCH_TRANSACTIONS_ACTION, {
            query: e.target.value.trim(),
            page: 1
          });
          if (this.transactions.length === 0) {
            this.search_response =
              "No transactions found for '" + e.target.value + "'";
          }
        } catch (error) {
          this.search_response = "";
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        }
      }
    }, 500),
    verifyTransaction(transaction_id) {
      return store.dispatch(
        types.VERIFY_VERIFICATION_PAYMENT_ACTION,
        transaction_id
      );
    },
    requeryTransaction(transaction_id, index) {
      let vm = this;
      let _transactions = [...vm.transactions];
      _transactions[index].isRequerying = true;

      //commit _transactions to state
      vm.commitTransactionsToTransactionLog(_transactions);
      vm.verifyTransaction(transaction_id)
        .then(transaction => {
          let __transactions = [...vm.transactions];
          __transactions[index] = transaction;

          //commit _transactions to state
          vm.commitTransactionsToTransactionLog(__transactions);
          notify.success({
            message: "Transaction requery successful"
          });
        })
        .catch(error => {
          let ___transactions = [...vm.transactions];
          ___transactions[index].isRequerying = false;

          //commit _transactions to state
          vm.commitTransactionsToTransactionLog(___transactions);
          error &&
            error.data &&
            notify.error({
              message: error.data.message
            });
        });
    },
    commitTransactionsToTransactionLog(transactions) {
      return store.commit(types.SET_TRANSACTIONS_LOG, transactions);
    }
  }
};
</script>
