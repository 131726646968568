<template>
  <div class="row pagination-center">
    <div class="col-md-12">
      <div>
        <modal
          name="id-loading-modal"
          :scrollable="false"
          height="auto"
          :adaptive="true"
          :clickToClose="false"
        >
          <div class="modal-content">
            <h4>Please wait...</h4>
            <p class="mt-3">Getting selected IDs ready for printing..</p>
          </div>
        </modal>
        <div class="table-responsive">
          <div class="form-group col-md-6 ml-auto flex-grow">
            <div class="d-flex align-items-baseline">
              <input
                type="text"
                class="form-control col-6 rounded-0 mr-4"
                placeholder="Search using name, email address"
                v-model.trim="search_term"
                @input="handleSearch"
              />
              <form
                class="row m-b-10"
                @submit.prevent="searchCouponByLicenseNumber"
              >
                <div class="form-group m-b-10">
                  <div class="input-group">
                    <input
                      type="text"
                      v-model.trim="lslb_license_number"
                      @input="handleSearchPermit"
                      :class="{
                        'form-error animated shake':
                          $v.lslb_license_number.$error
                      }"
                      placeholder="Enter Agent's LSLGA Permit Number"
                      class="form-control  rounded"
                    />
                    <button
                      class="btn btn-warning input-group-addon"
                      type="submit"
                      :disabled="$v.$invalid || isLoading"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <p
              class="text-danger font-14 font-bold mt-1"
              v-if="isSearching && agents.length === 0"
            >
              No items found for '{{ $route.query.search_term }}'
            </p>
          </div>

          <template
            v-if="isSearching || (isPermitSearch && lslb_license_number)"
          >
            <div class="p-t-10 p-b-10">
              <label class="text-info font-medium font-14">
                <span class="p-r-10"
                  >Showing all items by search term "{{
                    $route.query.search_term || lslb_license_number
                  }}"</span
                >
                <button
                  @click="clearFilter"
                  class="p-l-5 btn btn-outline-info btn-xs font-bold"
                >
                  clear search
                </button>
              </label>
            </div>
          </template>

          <template v-if="agents.length > 0">
            <div class="row col-12">
              <div class="col-md-7">
                <div class="row col-12 pt-2">
                  <h6 class="mr-2 mt-2">Select All</h6>
                  <input
                    @change="handleSelectAll"
                    v-model="selectAll"
                    type="checkbox"
                    class="mt-2 mr-5"
                  />
                  <!--  <button
                    class="btn btn-sm ml-5 font-bold"
                    style="background-color: #C4C4C4; color: white"
                    @click="printNewlyPrintedLicenseIDBulkApi"
                  >
                    <span class="mdi mdi-printer mr-1"></span>
                    Print Bulk Id (API)
                  </button> -->
                  <button
                    class="btn font-bold"
                    :class="selected.length > 1 ? 'btn-warning' : ''"
                    :disabled="selected.length < 2 || isPrinting"
                    @click="printMultipleIds"
                  >
                    <span class="mdi mdi-printer mr-1"></span>
                    Print Bulk
                  </button>
                </div>
              </div>

              <div class="col-md-6 ml-auto"></div>
            </div>
            <table class="table table-hover mt-3">
              <thead>
                <tr class="link-row">
                  <th width="40" class="text-center"></th>
                  <th width="40" class="text-center">#</th>
                  <th>Status</th>
                  <th class="text-nowrap">Email Address</th>
                  <th class="text-nowrap">Name</th>
                  <th class="text-nowrap">Permit ID</th>
                  <th class="text-nowrap">Phone Number</th>
                  <!--                    <th class="text-nowrap">Region</th>-->
                  <th class="text-nowrap">Created At</th>
                  <th class="text-nowrap">Modified At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-for="(agent, index) in agents" :key="agent.id">
                  <template>
                    <td class="">
                      <div>
                        <input
                          type="checkbox"
                          :value="agent.id"
                          v-model="selected"
                        />
                      </div>
                    </td>
                    <td class="text-center">
                      <div>{{ pagination.from + index }}</div>
                    </td>
                    <td>
                      <template>
                        <span
                          class="label font-bold text-capitalize rounded-0 text-nowrap"
                          :class="[
                            agent.status === 'verified'
                              ? 'label-light-success'
                              : agent.status === 'revoked'
                              ? 'label-light-danger'
                              : agent.status === 'awaiting_acceptance'
                              ? 'label-light-info'
                              : agent.status === 'pending'
                              ? 'label-light-warning'
                              : agent.status === 'incomplete'
                              ? 'label-light-primary'
                              : 'label-light-warning'
                          ]"
                          >{{ agent.status | remove_underscore }}</span
                        >
                      </template>
                    </td>
                    <td>
                      {{ agent.email.value || "Not available" }}
                    </td>
                    <td class="text-capitalize">
                      <div>
                        <template v-if="agent.last_name.value"
                          >{{ agent.first_name }}
                          {{ agent.middle_name ? agent.middle_name + " " : ""
                          }}{{ agent.last_name.value }}</template
                        >
                        <template v-else>Not available</template>
                      </div>
                    </td>
                    <td>
                      {{
                        agent.verification.lslb_license_number ||
                          "Not available"
                      }}
                    </td>
                    <td>
                      {{ agent.mobile.value || "Not available" }}
                    </td>
                    <td class="text-capitalize">
                      {{ agent.created_at | long_date }}
                    </td>
                    <td class="text-capitalize">
                      {{ agent.updated_at | long_date }}
                    </td>
                    <td class="d-flex">
                      <template>
                        <button
                          :disabled="isLoading"
                          @click="printLicenseID(agent.id, agent.id_url)"
                          class="btn btn-success btn-xs"
                        >
                          Print ID
                        </button>
                      </template>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
            <div
              class="p-30 test font-medium text-muted"
              v-if="!isLoading || !isPermitSearch"
            >
              <span
                >Showing {{ pagination.from }} to {{ pagination.to }} of
                {{ pagination.total }} entries</span
              >
            </div>
            <div
              class="text-center m-t-20"
              v-if="!isLoading || !isPermitSearch"
            >
              <pagination
                :data="pagination"
                :limit="4"
                :show-disabled="true"
                @pagination-change-page="getAgentList"
              ></pagination>
            </div>
          </template>
          <template v-else-if="!agents.length && isError">
            <p class="font-bold font-14 text-center px-3 px-md-5">
              {{ errorMessage }}
            </p>
          </template>
          <template v-else>
            <div class="text-center">
              <img
                src="/static/empty.svg"
                class="img-fluid mb-3"
                alt="empty"
                style="width: 50px;"
              />
              <p class="font-bold font-14 px-3 px-md-5">
                You have no agents that need a permit card to be printed
                currently. <br />
                Once an agent registers and is verified their details will show
                up here and you can print a permit card for them.
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import print from "print-js";
import store from "@/store";
import api from "../_api/index";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";
import PDFMerger from "pdf-merger-js/browser";
import axios from "axios";
import notify from "@/helpers/notifyMe";
import debounce from "@/helpers/debounce";
import { required } from "vuelidate/lib/validators";

export default {
  name: "PendingIDs",
  data() {
    return {
      search_term: this.$route.query.search_term || "",
      isLoading: false,
      selected: [],
      PDFMerger: [],
      allDownloadedFiles: [],
      selectAll: false,
      isPrinting: false,
      lslb_license_number: "",
      isError: "",
      isPermitSearch: false,
      preventToast: false
    };
  },
  validations: {
    lslb_license_number: {
      required
    }
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let { page = 1, status = "verified", search_term = "" } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_VERIFIED_AGENTS_ACTION, {
          query: search_term,
          paginate: 1,
          page,
          print: false,
          status
        });
      } else {
        await store.dispatch(types.GET_VERIFIED_AGENTS_ACTION, {
          page,
          status,
          print: false
        });
      }

      return next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      return next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let { page = 1, status = "verified", search_term = "" } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_VERIFIED_AGENTS_ACTION, {
          query: search_term,
          paginate: 1,
          page,
          print: false,
          status
        });
      } else {
        await store.dispatch(types.GET_VERIFIED_AGENTS_ACTION, {
          page,
          status,
          print: false
        });
      }

      return next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      return next(false);
    }
  },
  mounted() {
    eventBus.$on("lslb:agent:refresh-verified-agents-list", () => {
      this.getAgentList(this.pagination.current_page);
    });
  },
  computed: {
    ...mapGetters({
      agents: "getVerifiedAgentsLog",
      pagination: "getVerifiedAgentsLogPagination",
      business_profile: "getBusinessProfile",
      profile: "getCurrentUser"
    }),
    isSearching() {
      return this.$route.query.search_term;
    }
  },
  methods: {
    handleSearchPermit(event) {
      const permit = event.target.value.trim();
      if (!permit) {
        this.getAgentList(this.pagination.current_page);
      }
      this.isPermitSearch = false;
    },
    clearFilter() {
      if (this.isSearching) {
        this.search_term = "";
        this.$router.push({ name: this.$route.name }, () => {
          //   this.search_term = "";
          this.selected = [];
          this.selectAll = false;
        });
      } else {
        this.lslb_license_number = "";
        this.isLoading = false;
        this.isPermitSearch = false;
        this.getAgentList(this.pagination.current_page);
        this.$v.$reset();
      }
    },
    handleSelectAll() {
      console.log("Running select all");
      if (this.selectAll) {
        return (this.selected = this.agents.map(el => el.id));
      }
      this.selected = [];
    },
    getAgentList(page) {
      let { search_term } = this.$route.query;

      let query = {
        ...(search_term && { search_term }),
        page,
        [Date.now()]: null
      };
      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    downloadFile(url) {
      return new Promise((resolve, reject) => {
        axios
          .get(url, { responseType: "arraybuffer" })
          .then(response => {
            let blob = new Blob([response.data], {
              type: "application/pdf;charset=utf-8"
            });
            this.allDownloadedFiles.push(blob);
            return resolve(blob);
          })
          .catch(error => reject(error));
      });
    },
    async printBulkIds() {
      this.$modal.show("id-loading-modal");
      this.isPrinting = true;
      const merger = new PDFMerger();
      let urls = this.selected.map(
        el => this.agents.find(agent => agent.id === el).id_url
      );
      console.log("IDs to print", urls);
      const downloadedFiles = await Promise.all(
        urls.map(url => this.downloadFile(url))
      );
      await Promise.all(downloadedFiles.map(file => merger.add(file, [1])));
      const mergedPdf = await merger.saveAsBlob();
      const url = URL.createObjectURL(mergedPdf);
      this.$modal.hide("id-loading-modal");
      this.isPrinting = false;
      print({
        printable: url,
        type: "pdf",
        showModal: true,
        modalMessage: "Please wait...",
        onError: function(error) {
          console.log(error);
        },
        onPrintDialogClose: () => {
          console.log("Closed dialog");
          this.selected.forEach(this.markLicenseIdAsPrinted);
          this.selected = [];
          this.selectAll = false;
          this.isPrinting = false;
          this.lslb_license_number = "";
        }
      });
    },
    async printMultipleIds() {
      // Alternative to printBulkIds() because id_url is not available
      this.preventToast = true;
      this.selected.forEach(this.markLicenseIdAsPrinted);
      this.$notify.success({
        message: "ID printing is in progress. Check back later."
      });
      setTimeout(() => {
        this.getAgentList(this.pagination.current_page);
      }, 6000);
      this.preventToast = false;
    },
    handleSearch: debounce(function(event) {
      const search_term = event.target.value.trim();
      this.isLoading = false;
      this.lslb_license_number = "";
      if (search_term) {
        this.$router.push({
          name: this.$route.name,
          query: {
            search_term
          }
        });
      }
    }, 750),
    printLicenseID(agent_id, source) {
      console.log("Okay", source);
      // Skipped printing as source is not available
      // print({
      //   printable: source,
      //   type: "pdf",
      //   showModal: true,
      //   modalMessage: "Please wait..."
      // });
      this.markLicenseIdAsPrinted(agent_id);
    },
    async markLicenseIdAsPrinted(agent_id) {
      console.log("Printed ", agent_id);
      this.isLoading = true;
      try {
        await api.markLicenseIdAsPrinted({ agent_id });
        eventBus.$emit("lslb:agent:refresh-verified-agents-list");
      } catch (error) {
        // The first error is expected because the agent ID is not available so a success toast is shown instead or error toast
        // this.preventToast is a condition for the printing of multiple IDs, so that the toast is not shown multiple times
        if (
          error.data &&
          error.data.status_code === 404 &&
          !this.preventToast
        ) {
          this.$notify.success({
            message: "ID printing is in progress. Check back later."
          });
        } else if (error && error.data && !this.preventToast) {
          this.$notify.error({ message: error.data.message });
        }
      } finally {
        this.isLoading = false;
        this.lslb_license_number = "";
        setTimeout(() => {
          this.getAgentList(this.pagination.current_page);
        }, 6000);
      }
    },
    async searchCouponByLicenseNumber() {
      this.isPermitSearch = true;
      this.isLoading = true;
      try {
        let response = await this.$store.dispatch(
          types.SEARCH_AGENT_BY_PERMIT_NUMBER,
          this.lslb_license_number
        );

        this.$v.$reset();
        let result = [];
        result.push(response.agent);
        this.$store.commit(types.SET_VERIFIED_AGENTS_LOG, result);
        console.log(response.agent);
      } catch (error) {
        if (error && error.data) {
          this.errorMessage = error.data.message;
          this.isError = "error";
          this.$store.commit(types.SET_VERIFIED_AGENTS_LOG, []);
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
.modal-content {
  padding: 20px !important;
  text-align: center;
}
</style>
