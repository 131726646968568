<template>
  <div class="clearfix">
    <div class="col-xs-12">
      <span class="font-bold font-14">Identity Status</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span
        class="text-muted font-14 font-bold text-capitalize text-nowrap"
        :class="
          guarantor_info.status === 'verified'
            ? 'text-success'
            : guarantor_info.status === 'completed'
            ? 'text-info'
            : guarantor_info.status === 'not_verified'
            ? 'text-danger'
            : guarantor_info.status === 'pending'
            ? 'text-warning'
            : guarantor_info.status === 'incomplete'
            ? 'text-primary'
            : guarantor_info.status === 'awaiting_qa'
            ? 'text-info'
            : null
        "
      >
        <template v-if="guarantor_info.status === 'awaiting_qa'"
          >Awaiting Quality Assurance Check</template
        >
        <template v-else>
          {{ guarantor_info.status | remove_underscore }}
        </template>
        <i
          class="mdi ml-1"
          :class="
            guarantor_info.status === 'verified'
              ? 'mdi-check-circle'
              : guarantor_info.status === 'completed'
              ? 'mdi-check-circle'
              : guarantor_info.status === 'not_verified'
              ? 'mdi-alert'
              : guarantor_info.status === 'pending'
              ? 'mdi-clock'
              : guarantor_info.status === 'incomplete'
              ? 'mdi-alert-outline'
              : guarantor_info.status === 'awaiting_qa'
              ? 'mdi-clock'
              : 'mdi-alert'
          "
        ></i>
      </span>
      <p class="small" v-if="guarantor_info.identity_info.reason">
        ({{ guarantor_info.identity_info.reason }})
      </p>
    </div>
    <template v-if="guarantor_info.status !== 'verified'">
      <template v-if="guarantor_info.verification_link">
        <div class="col-xs-12">
          <span class="font-bold font-14">Verification Link</span>
          <button
            class="btn ml-2"
            type="button"
            v-clipboard:copy="guarantor_info.verification_link"
            v-clipboard:success="onCopy"
          >
            <i
              v-tooltip="{
                content: 'Copy to clipboard',
                delay: { hide: 500 }
              }"
              class="mdi mdi-content-copy text-warning"
            >
            </i>
          </button>
        </div>
      </template>
      <template v-if="guarantor_info.form_link">
        <div class="col-xs-12 my-2">
          <span class="font-bold font-14">Guarantor Form Link</span>
          <button
            class="btn ml-2"
            type="button"
            v-clipboard:copy="guarantor_info.form_link"
            v-clipboard:success="onCopy"
          >
            <i
              v-tooltip="{
                content: 'Copy to clipboard',
                delay: { hide: 500 }
              }"
              class="mdi mdi-content-copy text-warning"
            >
            </i>
          </button>
        </div>
      </template>
    </template>
    <div class="col-xs-12">
      <span class="font-bold font-14">Facial Match</span>
    </div>
    <div class="m-b-10">
      <div class="d-flex align-items-center">
        <ImagePercentage :photoMeta="guarantor_info.personal_info.photo_meta" />
        <div class="ml-2">
          <button
            class="btn btn-sm btn-outline-secondary m-1 font-bold"
            v-tooltip="'Click to upload photo'"
            v-if="$User.hasPermission('agent-edit')"
            @click.prevent="selectFile"
            :disabled="loading === 'guarantorUpdate'"
          >
            <i
              class="mdi"
              :class="
                loading === 'guarantorUpdate'
                  ? 'mdi-loading mdi-spin'
                  : 'mdi-pencil'
              "
            ></i>
            Upload Photo
          </button>
          <input
            type="file"
            accept="image/*"
            ref="selectFile"
            class="d-none"
            @change="uploadPhoto"
          />
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Guarantor's Full Name</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <template
        v-if="
          guarantorUpdateState === 'idle' ||
            guarantorUpdateState !== 'isNameUpdating'
        "
      >
        <span class="text-muted font-14">{{
          full_name || "Not available"
        }}</span>

        <button
          class="btn btn-sm btn-outline-secondary m-1 font-bold"
          v-tooltip="'Click to edit full name'"
          v-if="
            guarantorUpdateState === 'idle' && $User.hasPermission('agent-edit')
          "
          @click.prevent="guarantorUpdateState = 'isNameUpdating'"
        >
          <i class="mdi mdi-pencil"></i> Edit
        </button>
      </template>

      <template v-if="guarantorUpdateState === 'isNameUpdating'">
        <div class="row">
          <div class="col-md-4 mt-2 mt-md-0">
            <input
              class="form-control rounded-0 input-sm"
              type="email"
              :class="{
                'form-error animated shake': $v.guarantorName.firstName.$error
              }"
              v-model.trim="guarantorName.firstName"
              name="guarantorName.firstName"
              @input="$v.guarantorName.firstName.$touch"
              placeholder="Guarantor First Name"
            />
          </div>

          <div class="col-md-4 mt-2 mt-md-0">
            <input
              class="form-control rounded-0 input-sm"
              type="email"
              :class="{
                'form-error animated shake': $v.guarantorName.middleName.$error
              }"
              v-model.trim="guarantorName.middleName"
              name="guarantorName.middleName"
              @input="$v.guarantorName.middleName.$touch"
              placeholder="Guarantor Middle Name"
            />
          </div>
          <div class="col-md-4 mt-2 mt-md-0">
            <input
              class="form-control rounded-0 input-sm"
              type="email"
              :class="{
                'form-error animated shake': $v.guarantorName.lastName.$error
              }"
              v-model.trim="guarantorName.lastName"
              name="guarantorName.lastName"
              @input="$v.guarantorName.lastName.$touch"
              placeholder="Guarantor Last Name"
            />
          </div>

          <div class="col-12 mt-1">
            <button
              class="btn btn-sm btn-outline-success font-bold"
              @click.prevent="updateGuarantorInformation"
              :disabled="
                loading === 'guarantorUpdate' || $v.guarantorName.$invalid
              "
            >
              <i
                class="mdi"
                :class="
                  loading === 'guarantorUpdate'
                    ? 'mdi-loading mdi-spin'
                    : 'mdi-floppy'
                "
              ></i>
              Save
            </button>
            <button
              @click.prevent="resetForms"
              class="btn btn-sm btn-outline-danger ml-1 font-bold"
              :disabled="loading === 'guarantorUpdate'"
            >
              <i class="mdi mdi-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </template>
    </div>

    <div class="col-xs-12">
      <span class="font-bold font-14">Guarantor's Email Address</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <template
        v-if="
          guarantorUpdateState === 'idle' ||
            guarantorUpdateState !== 'isEmailUpdating'
        "
      >
        <span class="text-muted font-14">{{
          guarantor_info.personal_info.email || "Not available"
        }}</span>
        <button
          class="btn btn-sm btn-outline-secondary m-1 font-bold"
          v-tooltip="'Click to edit email address'"
          v-if="
            guarantorUpdateState === 'idle' && $User.hasPermission('agent-edit')
          "
          @click.prevent="guarantorUpdateState = 'isEmailUpdating'"
        >
          <i class="mdi mdi-pencil"></i> Edit
        </button>
      </template>

      <template v-if="guarantorUpdateState === 'isEmailUpdating'">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control rounded-0 input-sm"
              type="email"
              :class="{
                'form-error animated shake': $v.guarantorEmail.$error
              }"
              v-model.trim="guarantorEmail"
              name="guarantorEmail"
              @input="$v.guarantorEmail.$touch"
              placeholder="Guarantor Email Address"
              autocomplete="email"
            />
          </div>
          <div class="col-12 mt-1">
            <button
              class="btn btn-sm btn-outline-success font-bold"
              @click.prevent="updateGuarantorInformation"
              :disabled="
                loading === 'guarantorUpdate' ||
                  $v.guarantorEmail.$invalid ||
                  guarantorEmail === guarantor_info.personal_info.email
              "
            >
              <i
                class="mdi"
                :class="
                  loading === 'guarantorUpdate'
                    ? 'mdi-loading mdi-spin'
                    : 'mdi-floppy'
                "
              ></i>
              Save
            </button>
            <button
              @click.prevent="resetForms"
              class="btn btn-sm btn-outline-danger ml-1 font-bold"
              :disabled="loading === 'guarantorUpdate'"
            >
              <i class="mdi mdi-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </template>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">Guarantor's Mobile</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <template
        v-if="
          guarantorUpdateState === 'idle' ||
            guarantorUpdateState !== 'isMobileUpdating'
        "
      >
        <span class="text-muted font-14">{{
          guarantor_info.personal_info.mobile || "Not available"
        }}</span>
        <button
          class="btn btn-sm btn-outline-secondary m-1 font-bold"
          v-tooltip="'Click to edit mobile number'"
          v-if="
            guarantorUpdateState === 'idle' && $User.hasPermission('agent-edit')
          "
          @click.prevent="guarantorUpdateState = 'isMobileUpdating'"
        >
          <i class="mdi mdi-pencil"></i> Edit
        </button>
      </template>
      <template v-if="guarantorUpdateState === 'isMobileUpdating'">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control rounded-0 input-sm"
              type="text"
              :class="{
                'form-error animated shake': $v.guarantorMobile.$error
              }"
              v-model.trim="guarantorMobile"
              name="guarantorMobile"
              @input="$v.guarantorMobile.$touch"
              placeholder="Guarantor Mobile Number"
              autocomplete="mobile"
            />
          </div>
          <div class="col-12 mt-1">
            <button
              class="btn btn-sm btn-outline-success font-bold"
              @click.prevent="updateGuarantorInformation"
              :disabled="
                loading === 'guarantorUpdate' ||
                  $v.guarantorMobile.$invalid ||
                  guarantorMobile === guarantor_info.personal_info.mobile
              "
            >
              <i
                class="mdi"
                :class="
                  loading === 'guarantorUpdate'
                    ? 'mdi-loading mdi-spin'
                    : 'mdi-floppy'
                "
              ></i>
              Save
            </button>
            <button
              @click.prevent="resetForms"
              class="btn btn-sm btn-outline-danger ml-1 font-bold"
              :disabled="loading === 'guarantorUpdate'"
            >
              <i class="mdi mdi-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </template>
    </div>

    <div class="col-xs-12">
      <span class="font-bold font-14">Name on Identity</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">{{
        full_name_bvn || "Not available"
      }}</span>
    </div>
    <div class="col-xs-12">
      <span class="font-bold font-14">
        {{
          guarantor_info.identity_info.id_type === "bvn"
            ? "Bank Verification Number"
            : guarantor_info.identity_info.id_type === "nin"
            ? "National Identification Number"
            : guarantor_info.identity_info.id_type === "passport"
            ? "International Passport Number"
            : guarantor_info.identity_info.id_type === "drivers_license"
            ? "Driver's License Number"
            : "Identity Number"
        }}
      </span>
    </div>
    <div class="col-xs-12 m-b-10">
      <template
        v-if="
          guarantorUpdateState === 'idle' ||
            guarantorUpdateState !== 'isIdUpdating'
        "
      >
        <span class="text-muted font-14">{{
          guarantor_info.identity_info.id_number || "Not available"
        }}</span>
        <button
          class="btn btn-sm btn-outline-secondary m-1 font-bold"
          v-tooltip="'Click to edit ID number'"
          v-if="
            guarantorUpdateState === 'idle' &&
              $User.hasPermission('agent-edit') &&
              guarantorIdNumber
          "
          @click.prevent="guarantorUpdateState = 'isIdUpdating'"
        >
          <i class="mdi mdi-pencil"></i> Edit
        </button>
      </template>
      <template v-if="guarantorUpdateState === 'isIdUpdating'">
        <div class="row">
          <div class="col-md-4">
            <input
              class="form-control rounded-0 input-sm"
              type="text"
              :class="{
                'form-error animated shake': $v.guarantorIdNumber.$error
              }"
              v-model.trim="guarantorIdNumber"
              name="guarantorIdNumber"
              @input="$v.guarantorIdNumber.$touch"
              placeholder="Guarantor ID Number"
            />
          </div>
          <div class="col-12 mt-1">
            <button
              class="btn btn-sm btn-outline-success font-bold"
              @click.prevent="updateGuarantorInformation"
              :disabled="
                loading === 'guarantorUpdate' ||
                  $v.guarantorIdNumber.$invalid ||
                  guarantorIdNumber === guarantor_info.identity_info.id_number
              "
            >
              <i
                class="mdi"
                :class="
                  loading === 'guarantorUpdate'
                    ? 'mdi-loading mdi-spin'
                    : 'mdi-floppy'
                "
              ></i>
              Save
            </button>
            <button
              @click.prevent="resetForms"
              class="btn btn-sm btn-outline-danger ml-1 font-bold"
              :disabled="loading === 'guarantorUpdate'"
            >
              <i class="mdi mdi-cancel"></i> Cancel
            </button>
          </div>
        </div>
      </template>
    </div>

    <div class="col-xs-12">
      <span class="font-bold font-14">Submissions</span>
    </div>
    <div class="col-xs-12 m-b-10">
      <span class="text-muted font-14">
        {{
          guarantor_info.submissions.length === 0
            ? "Not yet submitted"
            : guarantor_info.submissions.length === 1
            ? "Submitted only once"
            : `Submitted ${guarantor_info.submissions.length} times`
        }}
        <ul class="mt-2 list-style-none">
          <li
            v-for="submission in guarantor_info.submissions"
            :key="submission"
            class="font-12"
          >
            <i class="mdi mdi-clock-in"></i>
            {{ submission | long_date }}
          </li>
        </ul>
      </span>
    </div>
    <div class="col-xs-12 m-b-10">
      <button
        v-if="guarantor_info.status === 'pending'"
        @click.prevent="resendInvite"
        class="btn btn-warning"
        :disabled="loading === 'reminder'"
      >
        Send Reminder
        <i
          v-if="loading === 'reminder'"
          class="ml-1 mdi mdi-loading mdi-spin"
        ></i>
      </button>
      <button
        v-if="
          guarantor_info.identity_info.can_retry &&
            $User.hasPermission('agent-edit')
        "
        @click.prevent="retryGuarantorVerification"
        class="ml-2 btn btn-warning"
        :disabled="loading === 'retry'"
      >
        Retry Guarantor Verification
        <i v-if="loading === 'retry'" class="ml-1 mdi mdi-loading mdi-spin"></i>
      </button>
    </div>
  </div>
</template>

<script>
import ImagePercentage from "@/components/images/ImagePercentage";
import store from "@/store";
import * as types from "@/store/types";
import {
  email,
  maxLength,
  minLength,
  numeric,
  required
} from "vuelidate/lib/validators";
import { getIdentityValidations } from "@/helpers/getIdentityValidations";
import { removeFalsyObjectFields } from "@/helpers/removeFalsyObjectFields";

export default {
  data() {
    return {
      loading: null,
      guarantorUpdateState: "idle",
      guarantorEmail: null,
      guarantorMobile: null,
      guarantorIdNumber: null,
      guarantorIdType: null,
      guarantorPhoto: null,
      guarantorName: {
        lastName: null,
        firstName: null,
        middleName: null
      }
    };
  },
  name: "GuarantorReport",
  props: ["guarantor_info", "id"],
  components: {
    ImagePercentage
  },
  created() {
    this.resetForms();
  },
  validations() {
    let validations = {
      guarantorName: {
        lastName: { required },
        firstName: { required },
        middleName: {}
      },
      guarantorEmail: { required, email },
      guarantorMobile: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      }
    };

    let idValidations = getIdentityValidations.bind(this)(
      "guarantorIdType",
      "guarantorIdNumber"
    );

    return Object.assign({}, validations, idValidations);
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        $(".live-photo-popup").magnificPopup({
          type: "image",
          closeOnContentClick: true,
          mainClass: "mfp-img-mobile mfp-with-zoom",
          image: {
            verticalFit: true
          },
          zoom: {
            enabled: true, // By default it's false, so don't forget to enable it
            duration: 300, // duration of the effect, in milliseconds
            easing: "ease-in-out", // CSS transition easing function
            // The "opener" function should return the element from which popup will be zoomed in
            // and to which popup will be scaled down
            // By defailt it looks for an image tag:
            opener: function(openerElement) {
              // openerElement is the element on which popup was initialized, in this case its <a> tag
              // you don't need to add "opener" option if this code matches your needs, it's defailt one.
              return openerElement.is("img")
                ? openerElement
                : openerElement.find("img");
            }
          }
        });
      }, 0);
    });
  },
  computed: {
    full_name() {
      if (
        this.guarantor_info.personal_info.first_name ||
        this.guarantor_info.personal_info.middle_name ||
        this.guarantor_info.personal_info.last_name
      ) {
        return `${this.guarantor_info.personal_info.first_name} ${
          this.guarantor_info.personal_info.middle_name
        } ${this.guarantor_info.personal_info.last_name.toUpperCase()}`;
      }
      return "Not available";
    },
    full_name_bvn() {
      if (
        this.guarantor_info.identity_info.first_name ||
        this.guarantor_info.identity_info.middle_name ||
        this.guarantor_info.identity_info.last_name
      ) {
        return `${this.guarantor_info.identity_info.first_name} ${
          this.guarantor_info.identity_info.middle_name
        } ${this.guarantor_info.identity_info.last_name.toUpperCase()}`;
      }
      return "Not available";
    }
  },
  methods: {
    async uploadPhoto(input) {
      if (this.loading === "guarantorUpdate") {
        return;
      }

      if (input.target.files && input.target.files[0]) {
        const { files } = input.target;
        const file = files[0];

        try {
          this.loading = "guarantorUpdate";
          const response = await this.$store.dispatch(
            types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION_WITH_DETECTION,
            {
              file,
              detectFace: true
            }
          );

          console.log(response);

          this.loading = null;

          this.guarantorPhoto = response.location;

          await this.updateGuarantorInformation();
        } catch (error) {
          this.loading = null;

          error &&
            error.data &&
            this.$notify.error({
              message: error.data.message
            });
        }
      }
    },
    selectFile() {
      this.$refs.selectFile.click();
    },
    resetForms() {
      this.guarantorUpdateState = "idle";
      this.loading = null;
      this.guarantorEmail = this.guarantor_info.personal_info.email;
      this.guarantorMobile = this.guarantor_info.personal_info.mobile;
      this.guarantorIdNumber = this.guarantor_info.identity_info.id_number;
      this.guarantorIdType = this.guarantor_info.identity_info.id_type;
      this.guarantorPhoto = this.guarantor_info.personal_info.photo_meta.url;
      this.guarantorName = {
        lastName: this.guarantor_info.personal_info.last_name,
        firstName: this.guarantor_info.personal_info.first_name,
        middleName: this.guarantor_info.personal_info.middle_name
      };
    },
    onCopy() {
      this.tooltipMessage = "Copied!";
      setTimeout(() => (this.tooltipMessage = "Copy to clipboard"), 600);
    },
    async updateGuarantorInformation() {
      if (this.loading === "guarantorUpdate") {
        return;
      }

      console.log(this.id);

      this.loading = "guarantorUpdate";

      try {
        await store.dispatch(types.UPDATE_GUARANTOR_INFORMATION_ACTION_ADMIN, {
          verification_id: this.id,
          guarantor_id: this.guarantor_info._id,
          guarantorInfo: {
            ...(this.guarantorPhoto && { photo: this.guarantorPhoto }),
            email: this.guarantorEmail,
            mobile: this.guarantorMobile,
            ...(this.guarantorIdNumber && {
              id_number: this.guarantorIdNumber
            }),
            ...removeFalsyObjectFields({
              last_name: this.guarantorName.lastName,
              first_name: this.guarantorName.firstName,
              middle_name: this.guarantorName.middleName
            })
          }
        });

        this.$notify.success({
          message: "Guarantor detail updated successfully"
        });
        this.guarantorUpdateState = "idle";
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = null;
      }
    },
    async resendInvite() {
      try {
        this.loading = "reminder";
        let response = await store.dispatch(
          types.RESEND_GUARANTOR_INVITE,
          this.id
        );
        this.$notify.success({
          message: response.message
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = false;
      }
    },
    async retryGuarantorVerification() {
      try {
        this.loading = "retry";
        let response = await store.dispatch(
          types.RETRY_GUARANTOR_VERIFICATION,
          this.id
        );
        this.$notify.success({
          message: response.message
        });
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.loading = null;
      }
    }
  }
};
</script>
