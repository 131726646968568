import http from "@/helpers/http";

class api {
  static get axios() {
    return http;
  }

  static inviteNewCompany(payload) {
    let {
      email,
      business_name,
      trade_name,
      first_name,
      last_name,
      category_id,
      phone_number
    } = payload;
    return http.post("/businesses", {
      email,
      business_name,
      trade_name,
      first_name,
      last_name,
      category_id,
      phone_number
    });
  }

  static getAllCompanies(payload) {
    return http.get("/businesses/", {
      params: payload
    });
  }

  static searchAllUsers(payload) {
    return http.get("/businesses/users/search", {
      params: {
        query: payload.query,
        page: payload.page,
        paginate: payload.paginate
      }
    });
  }

  static reinviteCompany(user) {
    return http.post("/businesses/users/re-invite", { email: user.email });
  }

  static getAllBusinessLicenseCategories() {
    return http.get("/businesses/categories", {
      params: {
        type: "agent"
      }
    });
  }
  static getAllBusinesses() {
    return http.get("/businesses");
  }
  static getBusinessById(id) {
    return http.get(`/businesses/${id}`);
  }
  static editBusiness(payload) {
    const { id, ...rest } = payload;
    return http.put(`/businesses/${id}`, { ...rest });
  }
}

export default api;
