<template>
  <div
    class="py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"
  >
    <div class="card bordered-box w-100 m-auto" style="max-width: 500px;">
      <form class="card-block form-horizontal">
        <div class="form-group text-center">
          <h1 class="display-1">404</h1>
          <h1>Not Found</h1>
          <p class="font-14">
            Hey there, you have reached a route that does not exist. Please
            confirm if the address is correct. <br />We will never lead you here
            intentionally.
          </p>
        </div>
        <div class="form-group m-b-0">
          <div class="col-sm-12 text-center">
            <router-link
              :to="{ name: 'HomeView' }"
              class="btn btn-warning btn-block"
              >Go Home</router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundComponent"
};
</script>
