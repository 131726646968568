<template>
  <div
    class="modal animated fadeIn fastest modal-border"
    id="session-timeout-modal"
    data-backdrop="static"
    data-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="session-timeout-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            {{ isLoggedOut ? "Your Session Has Expired" : "Session Timeout" }}
          </h4>
          <button
            v-if="!isLoggedOut"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <template v-if="isLoggedOut">
          <div class="modal-body p-b-0">
            <form
              id="session-timeout-form"
              class="form-horizontal"
              @submit.prevent="loginUser"
            >
              <!-- Password for Admin -->
              <div v-if="getAuthData.auth_type == 'password'" class="col-sm-12">
                <p>Please enter your password to continue</p>
                <div class="form-group m-b-10">
                  <label for="user-password" class="control-label form-label">
                    Password
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    id="user-password"
                    type="password"
                    v-model.trim="password"
                    :class="{ 'form-error animated shake': $v.password.$error }"
                    @input="$v.password.$touch"
                    class="form-control rounded-0"
                    placeholder="Enter Your Password"
                  />
                </div>
              </div>

              <!-- OTP for operators -->
              <div v-if="getAuthData.auth_type == 'otp'" class="col-sm-12">
                <div v-if="!sentOtp">
                  <p>Please request for a new OTP to retrieve session</p>
                </div>
                <div v-else class="form-group m-b-10">
                  <p>Please enter your OTP to continue</p>
                  <label for="user-password" class="control-label form-label">
                    OTP
                    <span class="text-danger">*</span>
                  </label>
                  <input
                    id="user-otp"
                    type="text"
                    v-model.trim="otp"
                    :class="{ 'form-error animated shake': $v.otp.$error }"
                    @input="$v.otp.$touch"
                    class="form-control rounded-0"
                    placeholder="Enter OTP"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer b-0">
            <div class="col-sm-12">
              <button
                v-if="getAuthData.auth_type == 'password' || sentOtp"
                type="button"
                :disabled="$v.$invalid || isLoading"
                @click.prevent="loginUser"
                class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
              >
                Retrieve Session
                <span v-if="isLoading">
                  <i class="mdi mdi-loading mdi-spin"></i>
                </span>
              </button>
              <button
                v-else
                type="button"
                :disabled="isLoading"
                @click.prevent="getOtp"
                class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
              >
                Request OTP
                <span v-if="isLoading">
                  <i class="mdi mdi-loading mdi-spin"></i>
                </span>
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="modal-body p-b-0">
            <form id="session-timeout-form" class="form-horizontal">
              <div class="col-sm-12">
                <p class="font-16">
                  You will be logged out in
                  <span class="font-bold font-18">{{ timer }}</span> second{{
                    timer > 1 ? "s" : ""
                  }}
                </p>
              </div>
            </form>
          </div>
          <div class="modal-footer b-0">
            <div class="col-sm-12">
              <button
                type="button"
                class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
                data-dismiss="modal"
                aria-label="Close"
              >
                Cancel
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";
import logout from "@/helpers/logout";
import store from "@/store";

import { Idle } from "idlejs";

export default {
  data() {
    return {
      password: "",
      otp: "",
      isLoading: false,
      isLoggedOut: false,
      timer: 60,
      timerHandle: null,
      idleTimer: null,
      sentOtp: false
    };
  },
  mounted() {
    let vm = this;

    // initialize session timeout
    this.initSessionTimeout();

    //when session modal is shown
    $("#session-timeout-modal").on("shown.bs.modal", () => {
      //stop idleTimer
      vm.idleTimer.stop();
      //start countdown timer
      vm.timer = 60;
      vm.timerHandle = setInterval(() => {
        vm.timer = vm.timer - 1;
        if (vm.timer < 1) {
          clearInterval(vm.timerHandle);
          logout();
          vm.isLoggedOut = true;
        }
      }, 1000);
    });

    //when session modal is hidden
    $("#session-timeout-modal").on("hidden.bs.modal", () => {
      vm.password = "";
      vm.$v.$reset();
      // $("session-timeout-modal").modal("dispose");
      clearInterval(vm.timerHandle);

      //restart the idle tracker if the user has not been logged out
      if (!vm.isLoggedOut) {
        vm.idleTimer.restart();
      }
    });
  },
  computed: {
    ...mapGetters(["getCurrentUser", "getAuthData"])
  },
  validations: {
    password: { minLength: minLength(8) },
    otp: { minLength: minLength(8) }
  },
  methods: {
    async getOtp() {
      this.isLoading = true;
      await this.$store.dispatch(
        types.LOOKUP_EMAIL_ACTION,
        this.getCurrentUser.email
      );
      this.sentOtp = true;
      this.isLoading = false;
    },
    async loginUser() {
      this.isLoading = true;
      try {
        let loginPayload = {
          email: this.getCurrentUser.email,
          auth_type: this.getAuthData.auth_type
        };
        if (this.getAuthData.auth_type == "password") {
          let { challenge_token } = await this.$store.dispatch(
            types.LOOKUP_EMAIL_ACTION,
            this.getCurrentUser.email
          );
          loginPayload.challenge_token = challenge_token;
          loginPayload.password = this.password;
        } else {
          loginPayload.password = this.otp;
        }
        await this.$store.dispatch(types.LOGIN_USER_ACTION, loginPayload);
        await this.$store.dispatch(types.GET_PROFILE_ACTION);
        this.isLoading = false;
        this.isLoggedOut = false;
        this.otp = "";
        this.idleTimer.reset().start();
        $("#session-timeout-modal").modal("hide");
      } catch (error) {
        error && notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    initSessionTimeout() {
      // setup session timer
      let vm = this;

      vm.idleTimer = new Idle()
        .whenNotInteractive()
        .within(10 * 1000 * 60, 1000)
        .do(() => {
          if (!vm.$route.meta.plainLayout) {
            store.commit(
              types.SET_LOGIN_ERROR,
              "Logged out due to session inactivity"
            );
            vm.$router.push({ name: "LogoutView" }).catch(() => {});
          }
        })
        .start();
    },
    showSessionTimeoutModal() {
      // $("#session-timeout-modal").modal({
      //   keyboard: false
      // });
      $("#session-timeout-modal").modal("show");
    }
  }
};
</script>
