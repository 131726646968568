import * as types from "@/store/types";
import VerificationService from "@/services/verifications";

const actions = {
  async [types.GET_ADDRESS_VERIFICATIONS_ACTION]({ commit }, payload) {
    let verifications = await VerificationService.getAddressVerifications(
      payload
    );
    commit(types.SET_ADDRESS_VERIFICATIONS_LOG, verifications.data);
    commit(types.SET_ADDRESS_VERIFICATIONS_META, verifications.meta);
    return verifications;
  },
  async [types.SEARCH_ADDRESS_VERIFICATIONS_ACTION]({ commit }, payload) {
    let verifications = await VerificationService.searchAddressVerifications(
      payload
    );
    commit(types.SET_ADDRESS_VERIFICATIONS_LOG, verifications.data);
    commit(types.SET_ADDRESS_VERIFICATIONS_META, verifications.meta);
    return verifications;
  },
  async [types.EXPORT_ADDRESS_VERIFICATIONS_ACTION](_, payload) {
    let response = await VerificationService.exportAddressVerifications(
      payload
    );
    return response;
  }
};

export default actions;
