import * as types from "@/store/types";
import api from "../../agents/_api";

const actions = {
  async [types.GET_VERIFIED_AGENTS_ACTION]({ commit }, payload) {
    let agents = await api.getAgents(payload);
    commit(types.SET_VERIFIED_AGENTS_LOG, agents.data);
    commit(types.SET_VERIFIED_AGENTS_META, agents.meta);
    return agents;
  },
  async [types.SEARCH_VERIFIED_AGENTS_ACTION]({ commit }, payload) {
    let agents = await api.searchAgents(payload);
    commit(types.SET_VERIFIED_AGENTS_LOG, agents.data);
    commit(types.SET_VERIFIED_AGENTS_META, agents.meta);
    return agents;
  }
};

export default actions;
