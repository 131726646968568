var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('div',{staticClass:"card bordered-box"},[_c('div',{staticClass:"card-block"},[_c('h3',{staticClass:"card-title"},[_vm._v("Payment Summary")]),_c('form',{staticClass:"row m-t-10"},[_c('div',{staticClass:"col-md-12 col-sm-12 mb-3"},[(!_vm.verification.paid)?_c('div',{staticClass:"form-group m-b-10"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click here if you have a coupon'),expression:"'Click here if you have a coupon'"}],staticClass:"d-inline-block p-2 mb-2",staticStyle:{"border":"1px solid rgba(120, 130, 140, 0.13)","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();_vm.have_coupon = !_vm.have_coupon}}},[_c('div',{staticClass:"checkbox checkbox-warning w-100"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.have_coupon),expression:"have_coupon",modifiers:{"trim":true}}],attrs:{"disabled":_vm.is_coupon_valid && _vm.is_coupon_valid.status === true,"id":"checkbox-signup","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.have_coupon)?_vm._i(_vm.have_coupon,null)>-1:(_vm.have_coupon)},on:{"change":function($event){var $$a=_vm.have_coupon,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.have_coupon=$$a.concat([$$v]))}else{$$i>-1&&(_vm.have_coupon=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.have_coupon=$$c}}}}),_c('label',{staticClass:"no-select form-bold form-label",attrs:{"for":"checkbox-signup"}},[_vm._v("Have a coupon?")])])]),(_vm.have_coupon)?[(
                      !(_vm.is_coupon_valid && _vm.is_coupon_valid.status === true)
                    )?[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.coupon_code),expression:"coupon_code",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 coupon-field text-uppercase",attrs:{"placeholder":"Enter Coupon Code","type":"text"},domProps:{"value":(_vm.coupon_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.coupon_code=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Apply coupon'),expression:"'Apply coupon'"}],staticClass:"btn btn-warning input-group-addon rounded-0",attrs:{"disabled":_vm.$v.coupon_code.$invalid || _vm.isVerifying},on:{"click":function($event){$event.preventDefault();return _vm.verifyCoupon($event)}}},[(_vm.isVerifying)?[_c('i',{staticClass:"mdi mdi-loading mdi-spin"})]:[_vm._v("VERIFY")]],2)]),(
                        _vm.is_coupon_valid && _vm.is_coupon_valid.status === false
                      )?_c('p',{staticClass:"mt-1 text-danger font-12 font-bold text-capitalize"},[_vm._v(" "+_vm._s(_vm.is_coupon_valid.message)+" ")]):_vm._e()]:[_c('div',{staticClass:"clearfix"},[_c('p',{staticClass:"font-16 font-bold float-left"},[_c('label',{staticClass:"label label-success font-bold mr-1"},[_vm._v(" "+_vm._s(_vm.coupon.discount_type === "amount" ? "₦" : "")+_vm._s(_vm.coupon.value)+_vm._s(_vm.coupon.discount_type === "percent" ? "%" : "")+" off ")]),_vm._v(" "+_vm._s(_vm.coupon.code)+" ")]),_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Remove coupon'),expression:"'Remove coupon'"}],staticClass:"btn btn-sm btn-outline-danger float-right",on:{"click":function($event){$event.preventDefault();return _vm.showRemoveCouponModal($event)}}},[_c('i',{staticClass:"mdi mdi-close"})])])]]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"form-group m-b-10 clearfix display-6"},[_vm._v(" "+_vm._s(_vm.verification.paid ? "Paid" : "Total")+" "),_c('div',{staticClass:"float-right"},[(_vm.is_coupon_valid && _vm.is_coupon_valid.status === true)?_c('span',{staticClass:"mr-2 font-30",staticStyle:{"color":"#adadad","text-decoration":"line-through"}},[_vm._v("₦"+_vm._s(_vm._f("amount")(parseInt(_vm.verificationPrice / 100))))]):_vm._e(),(!_vm.successfulTransaction)?_c('span',[_vm._v("₦"+_vm._s(_vm._f("amount")((parseInt(_vm.amount_in_kobo) / 100))))]):_c('span',[_vm._v(" ₦"+_vm._s(_vm._f("amount")((parseInt( _vm.transactions.length > 0 ? _vm.successfulTransaction.amount_in_kobo_approved : _vm.amount_in_kobo ) / 100))))])])])]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                !_vm.verification.personal_info.completed
                  ? 'Please complete the form before attempting to submit'
                  : ''
              ),expression:"\n                !verification.personal_info.completed\n                  ? 'Please complete the form before attempting to submit'\n                  : ''\n              "}],staticClass:"col-md-12 col-sm-12"},[_c('button',{staticClass:"btn btn-warning waves-effect rounded-0 w-100 mb-3",attrs:{"disabled":_vm.isPaymentLoading ||
                    _vm.verification.status === 'verified' ||
                    _vm.verification.status === 'pending' ||
                    !_vm.verification.personal_info.completed},on:{"click":function($event){$event.preventDefault();return _vm.showPaymentOptionModal($event)}}},[(_vm.amount_in_kobo === 0)?[_vm._v("Confirm Payment")]:[_vm._v("Click to Submit")],_c('span',{class:{
                    'mdi mdi-loading mdi-spin ml-1': _vm.isPaymentLoading,
                    'mdi mdi-arrow-right-thick ml-1': !_vm.isPaymentLoading
                  }})],2)])])])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"card bordered-box"},[_c('div',{staticClass:"card-block"},[_c('h3',{staticClass:"card-title"},[_vm._v("Payment History")]),_c('table',{staticStyle:{"table-layout":"fixed","width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[(_vm.transactions.length > 0)?[_c('table',{staticClass:"table table-hover"},[_vm._m(0),_c('tbody',_vm._l((_vm.transactions),function(transaction,index){return _c('tr',{key:transaction.id,staticClass:"link-row"},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))]),_c('td',[_c('span',{staticClass:"label font-bold text-capitalize rounded-0",class:[
                                transaction.status === 'success'
                                  ? 'label-light-success'
                                  : transaction.status === 'failed'
                                  ? 'label-light-danger'
                                  : transaction.status === 'fraud'
                                  ? 'label-light-danger'
                                  : transaction.status === 'abandoned'
                                  ? 'label-light-warning'
                                  : transaction.status === 'initiated'
                                  ? 'label-light-megna'
                                  : 'label-light-primary'
                              ]},[_vm._v(_vm._s(transaction.status))])]),_c('td',[_vm._v(_vm._s(transaction.id))]),_c('td',{staticClass:"text-capitalize"},[_vm._v(" ₦"+_vm._s(_vm._f("amount")((parseInt(transaction.amount_in_kobo_approved) / 100)))+" ")]),_c('td',[_vm._v(_vm._s(_vm._f("title_case")(transaction.item_type)))]),_c('td',{staticClass:"text-capitalize"},[(
                                transaction.user.last_name &&
                                  transaction.user.last_name.value
                              )?[_vm._v(_vm._s(transaction.user.last_name.value)+" "+_vm._s(transaction.user.first_name))]:(
                                transaction.user.last_name &&
                                  transaction.user.last_name.value != ''
                              )?[_vm._v(_vm._s(transaction.user.last_name)+" "+_vm._s(transaction.user.first_name))]:[_vm._v("Not available")]],2),_c('td',[(
                                transaction.status === 'initiated' &&
                                  transaction.payment_mode !== 'bank_transfer'
                              )?_c('button',{staticClass:"btn btn-xs btn-warning",attrs:{"disabled":_vm.isRequerying},on:{"click":function($event){return _vm.requeryTransaction(transaction.id)}}},[_vm._v(" Re-query "),(_vm.isRequerying === transaction.id)?_c('span',{staticClass:"mdi mdi-loading mdi-spin ml-1"}):_vm._e()]):_vm._e()]),_c('td',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("long_date")(transaction.created_at))+" ")])])}),0)]),_c('div',{staticClass:"p-30 test font-medium text-muted"},[_c('span',[_vm._v("Showing 1 to "+_vm._s(_vm.transactions.length)+" of "+_vm._s(_vm.transactions.length)+" entries")])])]:[_vm._m(1)]],2)]),_c('td')])])])])]),_c('div',{staticClass:"col-md-6 col-sm-6"},[_c('router-link',{staticClass:"btn btn-outline-inverse waves-effect rounded-0 w-100",attrs:{"tag":"button","to":{ name: 'RequestVerificationPreviewView' }}},[_c('span',{staticClass:"mdi mdi-arrow-left-thick"}),_vm._v(" Back ")])],1),_c('remove-coupon-modal',{attrs:{"removeCoupon":_vm.removeCoupon}})],1),_c('get-payment-option-modal',{attrs:{"initiatePayment":_vm.initiatePayment,"have_coupon":_vm.have_coupon,"coupon_code":_vm.coupon_code,"verification":_vm.verification}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"link-row"},[_c('th',{staticClass:"text-center",attrs:{"width":"70"}},[_vm._v("#")]),_c('th',[_vm._v("Status")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Transaction ID")]),_c('th',[_vm._v("Amount")]),_c('th',[_vm._v("Type")]),_c('th',{staticClass:"text-nowrap"},[_vm._v("Initiated By")]),_c('th',[_vm._v("Action")]),_c('th',[_vm._v("Date")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('img',{staticClass:"img-fluid mb-3",staticStyle:{"width":"50px"},attrs:{"src":"/static/empty.svg","alt":"empty"}}),_c('p',{staticClass:"font-bold font-14"},[_vm._v(" There are no transactions to display ")])])}]

export { render, staticRenderFns }