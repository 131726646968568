<template>
  <div class="container-fluid">
    <div class="row page-titles">
      <div class="col-md-6 col-8 align-self-center">
        <h3 class="text-themecolor m-b-0 m-t-0 app-title">Reports</h3>
      </div>
    </div>
    <div v-if="tableDate.length < 1" class="bg-white">
      <ul class="nav nav-tabs customtab" role="tablist">
        <li
          :class="{
            'nav-item': true,
            active: this.addAgentInviteType === 'single'
          }"
          @click="addAgentInviteType = 'single'"
          role="tab"
        >
          <span class="nav-link hidden-xs-down">Standard Report</span>
        </li>
        <li
          :class="{
            'nav-item': true,
            active: this.addAgentInviteType === 'inspection'
          }"
          @click="addAgentInviteType = 'inspection'"
          role="tab"
        >
          <span class="nav-link hidden-xs-down">Inspection Report</span>
        </li>
      </ul>
      <div class="col-12">
        <div class="col-12" v-if="this.addAgentInviteType === 'single'">
          <div v-if="tableDate.length < 1" class="row mt-5">
            <div class="col-12 row mt-2 mb-4">
              <div class="form-group col-md-6 m-b-5">
                <label for="first-name" class="control-label">
                  Category
                  <span class="text-danger">*</span>
                </label>
                <select
                  v-model="category"
                  class="form-control rounded-0 col-12"
                  name=""
                  id=""
                  @change="selectedDataPoints = []"
                >
                  <option value="">
                    -- select category --
                  </option>
                  <option value="agent_details">
                    Agent Details
                  </option>
                  <option value="verification_failed">
                    Verification Failed
                  </option>
                  <option value="guarantor_outstanding">
                    Guarantor Outstanding
                  </option>
                  <option value="transaction_reports">
                    Transaction Reports
                  </option>
                  <option value="infraction_report">
                    Infraction Report
                  </option>
                  <option value="enforcer_report">
                    Enforcer Report
                  </option>
                  <option value="unregistered_agent_infraction">
                    Unregistered Agent Infraction Report
                  </option>
                  <option
                    v-if="
                      profile.role.permissions.includes(
                        'agent-summary-report-view'
                      )
                    "
                    value="agent_summary_report"
                  >
                    Agent Summary Report
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group col-md-6 m-b-5">
              <label for="first-name" class="control-label">
                <small>Start Date</small>
              </label>
              <div class="clearfix">
                <flat-pickr
                  v-model.trim="startDate"
                  class="form-control rounded-0"
                  :config="startDateConfig"
                  placeholder="Start Date"
                ></flat-pickr>
              </div>
            </div>
            <div class="form-group col-md-6 m-b-5">
              <label for="first-name" class="control-label">
                <small>End Date</small>
              </label>
              <flat-pickr
                v-model.trim="endDate"
                class="form-control rounded-0"
                :config="endDateConfig"
                placeholder="End Date"
              ></flat-pickr>
            </div>
            <div
              class="col-12 pl-0 mt-4"
              v-if="category !== 'agent_summary_report'"
            >
              <div>
                <div class="form-group col-md-6 mb-1">
                  <label for="first-name" class="control-label mb-0">
                    <input
                      id="checkbox-signin"
                      type="checkbox"
                      :value="true"
                      v-model="send_via_mail"
                    />
                    Send Report to mail
                  </label>
                </div>
              </div>
              <small class="col-12 text-muted">
                Reports may take between 1 to 5 minutes to arrive at your mail
              </small>
            </div>
            <button
              @click.prevent="generateData"
              class="col-4 text-center py-3 btn ml-3 mb-4  mt-5"
              style="background: #FFB42E; color: white"
              :disabled="!category || loading"
            >
              Generate Report
            </button>
          </div>
          <table v-else class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="row">SN</th>
                <th
                  v-for="header in tableDate.headers"
                  :key="header.label"
                  scope="col"
                  class="font-weight-bold"
                >
                  {{ header.label }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in tableDate.data" :key="index">
                <th scope="row">{{ index + 1 }}</th>
                <td
                  v-for="(header, indexer) in tableDate.headers"
                  :key="indexer"
                >
                  {{ data[header.value] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-12"
          v-else-if="this.addAgentInviteType === 'inspection'"
        >
          <div class="row mt-5" v-if="selectedInspectionReport === null">
            <div class="form-group col-md-6 m-b-5">
              <label for="first-name" class="control-label">
                <small>Start Date</small>
                <span class="text-danger">*</span>
              </label>
              <div class="clearfix">
                <flat-pickr
                  v-model.trim="startDateInspect"
                  class="form-control rounded-0"
                  :config="startDateInspectConfig"
                  placeholder="Start Date"
                ></flat-pickr>
              </div>
            </div>
            <div class="form-group col-md-6 m-b-5">
              <label for="first-name" class="control-label">
                <small>End Date</small>
                <span class="text-danger">*</span>
              </label>
              <flat-pickr
                v-model.trim="endDateInspect"
                class="form-control rounded-0"
                :config="endDateInspectConfig"
                placeholder="End Date"
              ></flat-pickr>
            </div>
            <button
              class="col-4 text-center py-3 btn ml-3 mb-4"
              style="background: #FFB42E; color: white"
              @click.prevent="fetchInspectionReportsPage(1)"
            >
              Filter
            </button>
          </div>
          <table v-if="selectedInspectionReport === null" class="table">
            <thead class="thead-dark">
              <tr>
                <th scope="row">SN</th>
                <th scope="row">Agent Visited</th>
                <th scope="row">Category</th>
                <th scope="row">License No</th>
                <th scope="row">Date</th>
                <th scope="row">Time</th>
                <th scope="row">Enforcer</th>
                <th scope="row">Permanent Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="pointer-cursor"
                @click="selectedInspectionReport = data"
                v-for="(data, index) in inspectionReports"
                :key="index"
              >
                <th scope="row">{{ index + 1 }}</th>
                <th scope="row">
                  {{
                    `${data.agent["last_name"]["value"]} ${data.agent["first_name"]}`
                  }}
                </th>
                <th scope="row"></th>
                <th scope="row">{{ data.agent._id }}</th>
                <th scope="row">
                  {{ formatDate(data.inspection_date) }}
                </th>
                <th scope="row">
                  {{ formatTime(data.inspection_date) }}
                </th>
                <th scope="row">
                  {{
                    `${data.enforcer["first_name"]} ${data.enforcer["last_name"]}`
                  }}
                </th>
                <th scope="row" v-if="data.was_visited === true">
                  <span
                    class="label font-bold text-capitalize rounded-0 text-nowrap label-light-success"
                  >
                    Visited
                  </span>
                </th>
                <th scope="row" class="" v-else>
                  <span
                    class="label font-bold text-capitalize rounded-0 text-nowrap label-light-danger"
                  >
                    Not Visited
                  </span>
                </th>
              </tr>
            </tbody>
            <div class="row">
              <div class="d-inline-flex justify-content-center  ">
                <svg
                  @click="
                    fetchInspectionReportsPage(
                      allInspectionReportsMetaData.pagination.current_page - 1
                    )
                  "
                  v-if="
                    allInspectionReportsMetaData.pagination.current_page > 1
                  "
                  class="ml-2 mr-2 mt-2 ml-auto"
                  width="11"
                  height="13"
                  viewBox="0 0 11 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-3.0598e-07 6.30005L10.5 0.237871L10.5 12.3622L-3.0598e-07 6.30005Z"
                    fill="#F5B600"
                  />
                </svg>
                <p class="ml-2 mr-2 mt-2 border-page">
                  {{ allInspectionReportsMetaData.pagination.current_page }}
                </p>
                <p class="ml-2 mr-2 mt-2">of</p>
                <p class="ml-2 mr-2 mt-2">
                  {{ allInspectionReportsMetaData.pagination.last_page }}
                </p>
                <svg
                  @click="
                    fetchInspectionReportsPage(
                      allInspectionReportsMetaData.pagination.current_page + 1
                    )
                  "
                  v-if="
                    allInspectionReportsMetaData.pagination.current_page <
                      allInspectionReportsMetaData.pagination.last_page
                  "
                  class="ml-2 mr-2 mt-2 mr-auto"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.3 6.30005L0.800048 12.3622L0.800049 0.237871L11.3 6.30005Z"
                    fill="#F5B600"
                  />
                </svg>
              </div>
            </div>
          </table>
          <div class="row" v-else>
            <div class="col-12">
              <div class="row info-section py-4">
                <div
                  @click="selectedInspectionReport = null"
                  class="col-md-4 d-inline-flex pointer-cursor"
                >
                  <img
                    src="./../../assets/images/back-inspect.png"
                    class="pr-4"
                    alt=""
                  />Back
                </div>
              </div>
              <div class="row info-section py-4">
                <div class="col-md-4">
                  <h6 class="col-12 px-0 font-weight-bold">Agent's Name:</h6>
                  <small class="col-12 px-0">{{
                    `${selectedInspectionReport.agent["last_name"]["value"]} ${selectedInspectionReport.agent["first_name"]}`
                  }}</small>
                </div>
                <div class="col-md-4">
                  <h6 class="col-12 px-0 font-weight-bold">Permit Type:</h6>
                  <small class="col-12 px-0">{{
                    selectedInspectionReport.agent.verification.license_types[0]
                      .license_type.category_name
                  }}</small>
                </div>
                <div class="col-md-4">
                  <h6 class="col-12 px-0 font-weight-bold">Permit Number:</h6>
                  <small class="col-12 px-0"> </small>
                </div>
              </div>
              <div class="row info-section py-4">
                <div class="col-md-4">
                  <h6 class="col-12 px-0 font-weight-bold">Permit Expiry:</h6>
                  <small class="col-12 px-0">{{
                    formatDate(
                      selectedInspectionReport.agent.verification
                        .license_types[0].expiration_date
                    )
                  }}</small>
                </div>
                <div class="col-md-4">
                  <h6 class="col-12 px-0 font-weight-bold">Permit Status:</h6>
                  <p
                    class="px-0 label text-capitalize rounded-0 text-nowrap label-light-warning"
                  >
                    {{
                      selectedInspectionReport.agent.verification
                        .license_types[0].status
                    }}
                  </p>
                </div>
              </div>
              <div class="row info-section py-4">
                <div class="col-md-6">
                  <h6 class="col-12 px-0 font-weight-bold">
                    Business address:
                  </h6>
                  <h6 class="col-12 px-0 font-weight-bold">Bet9ja</h6>
                  <small class="col-12 px-0">
                    {{ selectedInspectionReport.address_visited.address }}
                  </small>
                </div>
                <div class="col-md-6">
                  <h6 class="col-12 px-0 font-weight-bold">
                    GPS Co-ordinates:
                  </h6>
                  <div class="col-md-12 d-inline-flex px-0">
                    <div class="col-md-3">
                      <small class="col-12 px-0 font-weight-bold">
                        Longitude:
                      </small>
                      <small class="col-12 px-0 font-weight-bold">
                        {{
                          selectedInspectionReport.address_visited.location
                            .coordinates[0]
                        }}
                      </small>
                    </div>
                    <div class="col-md-3">
                      <small class="col-12 px-0 font-weight-bold">
                        Latitude:
                      </small>
                      <small class="col-12 px-0 font-weight-bold">
                        {{
                          selectedInspectionReport.address_visited.location
                            .coordinates[1]
                        }}
                      </small>
                    </div>
                    <div
                      v-if="selectedInspectionReport.was_visited === true"
                      class="col-md-6"
                    >
                      <img src="./../../assets/images/green-check.png" alt="" />
                      <h6></h6>
                    </div>
                    <div v-else class="col-md-6">
                      <img src="./../../assets/images/red-visited.png" alt="" />
                      <h6></h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="col-12 bg-white ">
      <div class="col-12 d-inline-flex lotto-line-div px-4 py-2 mt-3 ">
        <h6 style="color: white" class="pt-4">{{ category.toUpperCase() }}</h6>
      </div>
      <div class="table-responsive scroll">
        <table class="table">
          <thead class="thead-dark">
            <tr>
              <th scope="row">SN</th>
              <th
                v-for="header in tableDate.headers"
                :key="header.label"
                scope="col"
                class="font-weight-bold"
              >
                {{ header.label }}
              </th>
            </tr>
          </thead>
          <tbody v-if="tableDate.data.length !== 0">
            <tr v-for="(data, index) in displayedPosts" :key="index">
              <th scope="row">{{ index + 1 }}</th>
              <td
                v-for="header in tableDate.headers"
                :key="header.value"
                class=""
              >
                <span
                  v-if="
                    Array.isArray(data[header.value]) &&
                      header.value !== 'report_photos'
                  "
                >
                  <span
                    v-for="(header, indexer) in data[header.value]"
                    :key="indexer"
                  >
                    {{ `${indexer + 1}. ${header}`.replace(/_/g, " ") }}
                    break<br />
                  </span>
                </span>
                <span
                  v-else-if="
                    data[header.value] === '' ||
                      data[header.value] === undefined ||
                      data[header.value] === null
                  "
                  >{{ "" }}</span
                >
                <span
                  v-tooltip="data[header.value]"
                  :class="
                    header.value === 'report_photos' ? 'view-photo' : 'truncate'
                  "
                  @click.prevent="
                    previewReportPhoto(header, data[header.value])
                  "
                  >{{
                    header.value === "report_photos"
                      ? "View Photo (s)"
                      : data[header.value]
                  }}</span
                >
              </td>
            </tr>
          </tbody>
          <div v-else class="row">
            <h5 class="col-12 text-center font-weight-bold">
              No Data Available
            </h5>
          </div>
        </table>
      </div>
      <div class="row">
        <div class="d-inline-flex ml-auto mr-auto">
          <svg
            @click="page = page - 1"
            v-if="page > 1"
            class="ml-2 mr-2 mt-2"
            width="11"
            height="13"
            viewBox="0 0 11 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M-3.0598e-07 6.30005L10.5 0.237871L10.5 12.3622L-3.0598e-07 6.30005Z"
              fill="#F5B600"
            />
          </svg>
          <p class="ml-2 mr-2 mt-2 border-page">{{ page }}</p>
          <p class="ml-2 mr-2 mt-2">of</p>
          <p class="ml-2 mr-2 mt-2">
            {{ Math.ceil(tableDate.data.length / 10) }}
          </p>
          <svg
            @click="page = page + 1"
            v-if="tableDate.data.length / 10 > page"
            class="ml-2 mr-2 mt-2"
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3 6.30005L0.800048 12.3622L0.800049 0.237871L11.3 6.30005Z"
              fill="#F5B600"
            />
          </svg>
        </div>
      </div>
      <div class="row">
        <button
          @click.prevent="tableDate = []"
          class="col-12 col-sm-5 col-md-3 ml-auto mr-3 text-center py-3 btn back-button mb-4"
        >
          Back
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/helpers/http";
import moment from "moment";
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import * as types from "@/store/types";

export default {
  name: "Report",
  data() {
    return {
      addAgentInviteType: "single",
      category: "",
      startDate: "",
      endDate: "",
      startDateInspect: "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      startDateInspectConfig: {},
      endDateInspectConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      endDateInspect: "",
      send_via_mail: false,
      categories: [],
      selectedDataPoints: [],
      tableDate: [],
      inspectionReports: [],
      allInspectionReports: [],
      allInspectionReportsMetaData: [],
      page: 1,
      perPage: 10,
      pages: [],
      selectedInspectionReport: null,
      loading: false
    };
  },
  mounted() {
    this.fetchDataPoints();
    this.fetchInspectionReports();
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
    this.startDateInspectConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile.created_at).format("YYYY-MM-DD")
    };
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.tableDate.data);
    },
    ...mapGetters({
      business_profile: "getBusinessProfile",
      profile: "getCurrentUser"
    })
  },
  watch: {
    posts() {
      this.setPages();
    },
    startDate() {
      this.endDateConfig.minDate = moment(this.startDate)
        .add("days")
        .format("YYYY-MM-DD");
    },
    startDateInspect() {
      this.endDateInspectConfig.minDate = moment(this.startDateInspect)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  methods: {
    previewReportPhoto(header, data) {
      if (header.value === "report_photos") {
        this.$store.commit(types.SET_INFRACTION_PHOTOS, data);
        this.$emit("toggle");
      }
    },

    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    formatTime(date) {
      return moment(date).format("h:m");
    },
    filterInspectionReport() {
      this.inspectionReports = [];
      for (let i = 0; i < this.inspectionReports.length; i++) {
        if (
          this.allInspectionReports[i].created_at > this.startDateInspect &&
          this.allInspectionReports[i].created_at < this.endDateInspect
        ) {
          this.inspectionReports.push(this.allInspectionReports[i]);
        }
      }
    },
    fetchDataPoints() {
      http.get("/reports/custom-reports").then(response => {
        this.categories = response.data.custom_reports;
      });
    },
    fetchInspectionReports() {
      http.get("/addresses").then(response => {
        this.allInspectionReports = response.data.activities;
        this.allInspectionReportsMetaData = response.data.meta;
        this.inspectionReports = response.data.activities;
      });
    },
    fetchInspectionReportsPage(page) {
      if (this.startDateInspect === "" && this.endDateInspect === "") {
        http.get(`/addresses?page=${page}`).then(response => {
          this.allInspectionReports = response.data.activities;
          this.allInspectionReportsMetaData = response.data.meta;
          this.inspectionReports = response.data.activities;
        });
      } else {
        http
          .get(
            `/addresses?page=${page}&start_date=${this.startDateInspect}&end_date=${this.endDateInspect}`
          )
          .then(response => {
            this.allInspectionReports = response.data.activities;
            this.allInspectionReportsMetaData = response.data.meta;
            this.inspectionReports = response.data.activities;
          });
      }
    },
    generateData() {
      if (this.category === "agent_summary_report") {
        this.generateAgentSummaryReport();
      } else {
        this.generateStandardData();
      }
    },
    generateAgentSummaryReport() {
      this.loading = true;
      if (this.startDate === "" || this.endDate === "") {
        return http
          .get(`/reports/${this.category}`, {
            responseType: "arraybuffer"
          })
          .then(res => {
            const blob = new Blob([res], { type: "application/pdf" });
            const downloadUrl = URL.createObjectURL(blob);
            saveAs(downloadUrl, "agent-summary-report.pdf");
            this.category = "";
          })
          .finally(() => (this.loading = false));
      } else {
        return http
          .get(
            `/reports/${this.category}?start_date=${this.startDate}&end_date=${this.endDate}`,
            {
              responseType: "arraybuffer"
            }
          )
          .then(res => {
            const blob = new Blob([res], { type: "application/pdf" });
            const downloadUrl = URL.createObjectURL(blob);
            saveAs(downloadUrl, "agent-summary-report.pdf");
            this.category = "";
            this.startDate = "";
            this.endDate = "";
          })
          .finally(() => (this.loading = false));
      }
    },

    generateStandardData() {
      console.log(this.startDate, this.endDate);
      if (this.startDate === "" || this.endDate === "") {
        http
          .post(`/reports/${this.category}`, {
            send_to_email: this.send_via_mail
          })
          .then(response => {
            this.page = 1;
            this.selectedDataPoints = [];
            this.tableDate = response.data.report;
          })
          .catch(error => {
            this.$notify.error({ message: error.data.message });
          });
      } else {
        http
          .post(`/reports/${this.category}`, {
            send_to_email: this.send_via_mail,
            start_date: this.startDate,
            end_date: this.endDate
          })
          .then(response => {
            this.page = 1;
            this.selectedDataPoints = [];
            this.tableDate = response.data.report;
          })
          .catch(error => {
            this.$notify.error({ message: error.data.message });
          });
      }
    },
    submitDataPoint() {
      http
        .post("/reports/custom-reports", {
          data_points: this.selectedDataPoints,
          category: this.categories[this.category]["value"],
          start_date: this.startDate,
          end_date: this.endDate,
          send_to_email: this.send_via_mail
        })
        .then(response => {
          this.selectedDataPoints = [];
          this.tableDate = response.data.report;
        })
        .catch(error => {
          this.$notify.error({ message: error.data.message });
        });
    },
    setPages() {
      let numberOfPages = Math.ceil(this.tableDate.data.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return posts.slice(from, to);
    }
  }
};
</script>

<style scoped>
.info-section {
  border-bottom: solid rgba(78, 82, 83, 0.3) 1px;
}
.customtab li {
  width: 50%;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  padding-bottom: 0.7rem;
  padding-top: 0.7rem;
}

.customtab .active {
  border-bottom: solid 2px #f5b600;
}

.pointer-cursor {
  cursor: pointer !important;
}

.datapoint-section {
  border-bottom: 1px solid #c4c4c4;
}

.bg-pic {
  background: rgba(245, 245, 245, 0.76);
  border-radius: 6px;
  color: white;
}

.data-point {
  background: #ffffff;
  border: 1.18328px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 2.36657px;
}

.label-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15.3827px;
  line-height: 18px;
  /* or 117% */

  color: #404e5d;
}

.lotto-line-div {
  background: #404e5d;
}

.lotto-line-div h5 {
  color: white !important;
}

tr:nth-child(2n + 3) {
  background: rgba(229, 229, 229, 0.19) !important;
}

.back-button {
  border: 1px solid #404e5d;
  box-sizing: border-box;
  border-radius: 3px;
  background: white;
  color: #404e5d;
}
.border-page {
  background: #f7f7f7;
  border: 0.7px solid #c4c4c4;
  box-sizing: border-box;
}

.view-photo {
  color: #404e5d;
  font-weight: 500;
  cursor: pointer;
}
.scroll {
  overflow-y: hidden !important;
  overflow-x: auto !important;
  /* max-height: 48vh !important; */
}

.scroll::-webkit-scrollbar {
  background: transparent !important;
  width: 5px;
  height: 5px;
}

.scroll::-webkit-scrollbar-thumb {
  background: #404e5d !important;
  max-height: 10px !important;
  border-radius: 6px;
}
.truncate {
  max-width: 85px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
</style>
