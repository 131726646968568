const getters = {
  getVerifiedAgentsLog: state => {
    return state.verified_agents_log;
  },
  getVerifiedAgentsLogMeta: state => {
    return state.verified_agents_meta;
  },
  getVerifiedAgentsLogPagination: state => {
    return state.verified_agents_meta && state.verified_agents_meta.pagination;
  }
};

export default getters;
