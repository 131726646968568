import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  guarantorDetails: null,
  guarantorPayload: {
    first_name: null,
    last_name: null,
    middle_name: null,
    email: null,
    mobile: null,
    id_type: null,
    id_number: null,
    photo: null
  }
};

export default {
  state,
  actions,
  getters,
  mutations
};
