import {
  alpha,
  alphaNum,
  maxLength,
  minLength,
  numeric,
  required
} from "vuelidate/lib/validators";

export function getIdentityValidations(
  identityTypeModelName = "identity_type",
  identityNumberModelName = "identity_number"
) {
  let validations = {
    [identityTypeModelName]: {
      required,
      mustBeAValidType: function(value) {
        return ["nin", "bvn", "passport", "drivers_license"].includes(value);
      }
    },
    [identityNumberModelName]: {
      required
    }
  };

  if (this[identityTypeModelName] === "passport") {
    validations[identityNumberModelName] = Object.assign(
      validations[identityNumberModelName],
      {
        alphaNum: alphaNum,
        minLength: minLength(8),
        maxLength: maxLength(10),
        mustStartWithAlpha: function(value) {
          return alpha(value.charAt(0));
        },
        mustEndWithNumeric: function(value) {
          return numeric(value.slice(1));
        }
      }
    );
  }

  if (this[identityTypeModelName] === "drivers_license") {
    validations[identityNumberModelName] = Object.assign(
      validations[identityNumberModelName],
      {
        alphaNum: alphaNum,
        minLength: minLength(12),
        maxLength: maxLength(12),
        mustStartWithNumberTwo: function(value) {
          return alpha(value.slice(0, 2));
        }
      }
    );
  }

  if (this[identityTypeModelName] === "bvn") {
    validations[identityNumberModelName] = Object.assign(
      validations[identityNumberModelName],
      {
        minLength: minLength(11),
        maxLength: maxLength(11),
        numeric: numeric,
        mustStartWithNumberTwo: function(value) {
          return Number(value.charAt(0)) === 2;
        }
      }
    );
  }
  if (this[identityTypeModelName] === "nin") {
    validations[identityNumberModelName] = Object.assign(
      validations[identityNumberModelName],
      {
        minLength: minLength(10),
        maxLength: maxLength(11),
        numeric: numeric
      }
    );
  }

  return validations;
}
