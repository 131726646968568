// components
import DashboardContainer from "@/containers/Dashboard";
import DashboardView from "@/modules/dashboard/Index";

export default [
  {
    path: "/dashboard",
    name: "DashboardContainer",
    component: DashboardContainer,
    redirect: {
      name: "DashboardView"
    },
    meta: {
      requiresAuth: true,
      allowed_permissions: ["metric-view", "business-balance-view"]
    },
    children: [
      {
        path: "analytics",
        component: DashboardView,
        name: "DashboardView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["metric-view", "business-balance-view"],
          documentTitle: "Dashboard"
        }
      }
    ]
  }
];
