var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal animated fade pulse_ modal-border",attrs:{"id":"change-password-modal","data-backdrop":"static","tabindex":"-1","role":"dialog","aria-labelledby":"change-role-modal"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content modal-border-radius"},[_vm._m(0),_c('div',{staticClass:"modal-body p-b-0"},[_c('form',{staticClass:"form-horizontal",attrs:{"id":"change-password-form"},on:{"submit":function($event){$event.preventDefault();return _vm.changeUserPassword($event)}}},[_c('div',{staticClass:"form-group col-md-12 m-b-10"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.current_password),expression:"current_password",modifiers:{"trim":true}}],staticClass:"form-control rounded-0",class:{
                'form-error animated shake': _vm.$v.current_password.$error
              },attrs:{"type":"password","id":"user-password-c","placeholder":"Enter your current password"},domProps:{"value":(_vm.current_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.current_password=$event.target.value.trim()},_vm.$v.current_password.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form-group col-md-12 m-b-10"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.new_password),expression:"new_password",modifiers:{"trim":true}}],staticClass:"form-control rounded-0",class:{ 'form-error animated shake': _vm.$v.new_password.$error },attrs:{"type":"password","id":"user-password-new","placeholder":"Enter your new password"},domProps:{"value":(_vm.new_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.new_password=$event.target.value.trim()},_vm.$v.new_password.$touch],"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.new_password),expression:"new_password"}],staticClass:"col-xs-12 form-group mt-2 mb-0"},[_c('div',{staticClass:"col-xs-12 font-14"},[_vm._v(" At least; "),_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsLowerCase
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'}),_vm._v(" One lowercase letter ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsUpperCase
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'}),_vm._v(" One uppercase letter ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsSpecialCharacter
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'}),_vm._v(" One special character (allowed characters ! @ # $ % ^ & _ -) ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsNumber
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'}),_vm._v(" One number ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.isGreaterThanEight
                          ? 'mdi-check-circle text-success'
                          : 'mdi-circle text-muted'}),_vm._v(" Eight characters ")])])])])]),_c('transition',{attrs:{"enter-active-class":"animated fastest fadeIn","leave-active-class":"animated fastest fadeOut"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.new_password.$invalid),expression:"!$v.new_password.$invalid"}],staticClass:"form-group col-md-12 m-b-10"},[_c('label',{staticClass:"form-label",attrs:{"for":"user-password-confirm"}},[_vm._v("Confirm Password")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirm_password),expression:"confirm_password",modifiers:{"trim":true}}],staticClass:"form-control rounded-0",class:{
                  'form-error animated shake': _vm.$v.confirm_password.$error
                },attrs:{"type":"password","id":"user-password-confirm","placeholder":"Confirm your new password"},domProps:{"value":(_vm.confirm_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.confirm_password=$event.target.value.trim()},_vm.$v.confirm_password.$touch],"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.confirm_password.sameAsNewPassword)?_c('p',{staticClass:"text-danger font-12 font-bold"},[_vm._v(" Must be the same as new password ")]):_vm._e()])])],1)]),_c('div',{staticClass:"modal-footer b-0"},[_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-warning rounded-0 waves-effect p-10 w-100 p-10",attrs:{"type":"button","disabled":_vm.$v.$invalid || _vm.isLoading},on:{"click":_vm.changeUserPassword}},[_vm._v(" Change Password "),(_vm.isLoading)?_c('span',[_c('i',{staticClass:"mdi mdi-loading mdi-spin"})]):_vm._e()])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-header b-0 p-l-30"},[_c('h4',{staticClass:"modal-title text-center font-medium"},[_vm._v("Change Password")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{staticClass:"mdi mdi-close",attrs:{"aria-hidden":"true"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label",attrs:{"for":"user-password-c"}},[_vm._v("Current Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"form-label",attrs:{"for":"user-password-new"}},[_vm._v("New Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }