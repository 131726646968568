<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Dashboard" />
      <!-- ============================================================== -->
      <div class="row analytics-dashboard">
        <div class="col-12">
          <div class="no-card">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs customtab" role="tablist">
              <li
                class="nav-item"
                v-if="
                  $User.hasPermission('metric-view') ||
                    $User.hasPermission('business-balance-view')
                "
              >
                <router-link
                  :to="{ name: 'DashboardView' }"
                  class="nav-link"
                  active-class="active"
                  role="tab"
                >
                  <span class="hidden-sm-up">
                    <i class="mdi mdi-format-align-left"></i>
                  </span>
                  <span
                    v-if="
                      $User.hasPermission('metric-view') &&
                        $User.hasPermission('business-balance-view')
                    "
                    class="hidden-xs-down"
                    >Balance & Analytics</span
                  >
                  <span
                    v-else-if="$User.hasPermission('metric-view')"
                    class="hidden-xs-down"
                    >Agent Analytics</span
                  >
                  <span
                    v-else-if="$User.hasPermission('business-balance-view')"
                    class="hidden-xs-down"
                    >Business Balance</span
                  >
                </router-link>
              </li>
            </ul>
            <!-- Tab panes -->
            <!-- /content -->
            <!-- ============================================================== -->
            <!-- Router view -->
            <!-- ============================================================== -->
            <div class="tab-content m-t-15">
              <div class="tab-pane active" role="tabpanel">
                <div class>
                  <router-view></router-view>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
export default {
  name: "dashboardContainer",
  components: {
    AppTitle
  }
};
</script>

<style lang="scss">
.analytics-dashboard {
  .dashboard-metric-card {
    transition: all 0.25s ease-in-out;
    &:hover {
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
      cursor: pointer;
    }
    &.disabled {
      box-shadow: 0;
    }
  }

  .card-block {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
