import * as types from "@/store/types";
import api from "../_api";
const actions = {
  async [types.GET_TRANSACTIONS_ACTION]({ commit }, payload) {
    let transactions = await api.getTransactions(payload);
    commit(types.SET_TRANSACTIONS_LOG, transactions.data);
    commit(types.SET_TRANSACTIONS_META, transactions.meta);
    return transactions;
  },
  async [types.SEARCH_TRANSACTIONS_ACTION]({ commit }, payload) {
    let transactions = await api.searchTransactions(payload);
    commit(types.SET_TRANSACTIONS_LOG, transactions.data);
    commit(types.SET_TRANSACTIONS_META, transactions.meta);
    return transactions;
  },
  async [types.GET_TRANSACTION_ACTION]({ commit, getters }, payload) {
    let transaction;

    let transactions = getters.getTransactionsLog;
    if (transactions.length > 1) {
      transaction = transactions.find(
        transaction => transaction.id === payload.transaction_id
      );
      if (transaction) {
        commit(types.SET_TRANSACTION, transaction);
        return transaction;
      }
    }
    transaction = (await api.getTransaction(payload)).data;
    commit(types.SET_TRANSACTION, transaction);
    return transaction;
  },
  async [types.RECONCILE_TRANSACTION_ACTION](_, payload) {
    let response = (await api.reconcileTransaction(payload)).data;
    return response;
  },
  async [types.EXPORT_TRANSACTIONS_ACTION](_, payload) {
    let response = await api.exportTransactions(payload);
    return response;
  }
};

export default actions;
