import Vue from "vue";
import Router from "vue-router";

import { ACCESS_TOKEN_KEY, USER_ID_KEY } from "@/config/constant";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";
import store from "@/store";
import User from "@/helpers/User";
import Report from "@/components/reports/ReportComponent";

// routes
import dashboardRoute from "./dashboard";
import homeRoute from "./home";
import requestVerificationRoute from "./request-verification";
import agentProfileRoute from "./agent-profile";
import getStartedRoute from "./get-started";
import verificationsRoute from "./verifications";
import ecosystemsRoute from "./ecosystems";
import identityRoute from "./identity";
import accountsRoute from "./accounts";
import faqRoute from "./faq";
import settingsRoute from "./settings";
import searchRoute from "./search";
import authRoute from "./auth";
// import lotteryCompaniesRoute from "./lottery-company";
// import contactUsRoute from "./contact-us";
import printLicenseIdsRoute from "./print-license-ids";
import adminRoute from "./admin";
import rolesRoute from "./roles";
import NotFound from "@/modules/errors/NotFoundComponent.vue";
import guarantorRoute from "./guarantor";

Vue.use(Router);

const routes = [
  ...homeRoute,
  ...dashboardRoute,
  ...getStartedRoute,
  ...verificationsRoute,
  ...accountsRoute,
  ...ecosystemsRoute,
  ...identityRoute,
  ...faqRoute,
  ...settingsRoute,
  ...authRoute,
  // ...contactUsRoute,
  //...lotteryCompaniesRoute,
  ...searchRoute,
  ...adminRoute,
  ...requestVerificationRoute,
  ...agentProfileRoute,
  ...rolesRoute,
  ...guarantorRoute,
  ...printLicenseIdsRoute,
  {
    path: "/reports",
    component: Report,
    name: "Report",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["business-manage"],
      documentTitle: "Business Profile"
    }
  },
  {
    path: "*",
    component: NotFound,
    name: "NotFound",
    meta: {
      plainLayout: true,
      documentTitle: "404 - Not Found"
    }
  }
];

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.path !== from.path) {
        return {
          x: 0,
          y: 0
        };
      } else {
        return savedPosition;
      }
    }
    // if (to.path != from.path) {
    //   return { x: 0, y: 0 };
    // } else {
    //   return savedPosition;
    // }
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.deepLink)) {
    localStorage.setItem("request_deeplink", to.fullPath);
    let link = to.fullPath;
    console.log(link.split("/")[2]);
    next(`/ecosystems/agents/${link.split("/")[2]}`);
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //if to route requires auth, set intended to
    if (to.name !== "LoginView" && to.name !== "LogoutView") {
      store.commit(types.SET_INTENDED_TO, to.fullPath);
    }

    if (
      !localStorage.getItem(ACCESS_TOKEN_KEY) &&
      !localStorage.getItem(USER_ID_KEY)
    ) {
      // show not authenticated notification
      notify.error({
        message: "Please login to continue"
      });
      store.commit(types.SET_LOGIN_ERROR, "Please login to continue");
      next({
        name: "LoginView"
      });
    } else {
      // check if user can access route
      if (to.matched.some(record => record.meta.allowed_permissions)) {
        try {
          await store.dispatch(types.GET_PROFILE_ACTION);

          if (User.isEnforcer()) {
            notify.warning({
              message: "You don't have access to this portal"
            });

            return next({ name: "LogoutView" });
          }

          if (
            // User.hasPermission(to.meta.allowed_permissions[0]) ||
            // to.meta.allowed_permissions.length === 0
            to.meta.allowed_permissions.some(User.hasPermission) ||
            to.meta.allowed_permissions.length === 0
          ) {
            /*if (localStorage.getItem("twk_deeplink")) {
              next(localStorage.getItem("twk_deeplink"));
            }*/
            next();
          } else {
            if (from.name === "LoginView") {
              next({ name: "GetStartedView" });
              return;
            }
            if (from.name) {
              notify.warning({
                message:
                  "You don't have the necessary permission to view this resource"
              });
              next(false);
              return;
            }
            next({ name: "GetStartedView" });
          }
        } catch (error) {
          error &&
            error.data &&
            notify.error({
              message: error.data.message
            });
          // next({ name: "HomeView" });

          //stop routing on error to prevent infinite retries
          next(false);
        }
      } else {
        next();
      }
    }
  } else {
    if (to.matched.some(record => record.meta.guest)) {
      if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
        next({ name: "DashboardView" });
        return;
      }
    }
    next();
  }
});

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = `${to.meta.documentTitle || "Permit Portal"} - LSLGA`;
  });

  // hide content loader placeholder
  store.commit(types.SET_PAGE_READY);
});

export default router;
