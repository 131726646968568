import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.GET_IDENTITY_LOG_ACTION]({ commit }, payload) {
    let identity_log = await api.getIdentityLog(payload);
    commit(types.SET_IDENTITY_LOG, identity_log.data);
    commit(types.SET_IDENTITY_LOG_META, identity_log.meta);
    return identity_log;
  },
  async [types.EXPORT_IDENTITY_LOG_ACTION](_, payload) {
    let response = await api.exportIdentityLog(payload);
    return response;
  },
  async [types.GET_IDENTITY_REPORT_ACTION]({ commit }, payload) {
    // let report = getters.getIdentityReport;
    // if (
    //   report &&
    //   report.id === payload.report_id &&
    //   report.status !== "pending"
    // ) {
    //   return(report);
    // }
    let response = (await api.getIdentityReport(payload)).data;
    commit(types.SET_IDENTITY_REPORT, response);
    return response;
  },
  async [types.VERIFY_IDENTITY_ACTION]({ commit }, payload) {
    let response = (await api.verifyIdentity(payload)).data;
    commit(types.SET_IDENTITY_REPORT, response);
    return response;
  },
  async [types.SEARCH_IDENTITY_LOG_ACTION]({ commit }, payload) {
    let identity_log = await api.searchIdentityLog(payload);
    commit(types.SET_IDENTITY_LOG, identity_log.data);
    commit(types.SET_IDENTITY_LOG_META, identity_log.meta);
    return identity_log;
  }
};
export default actions;
