import GuarantorConsentActionView from "@/modules/guarantor/_components/GuarantorConsentActionView";
import GuarantorPersonalInformationView from "@/modules/guarantor/_components/GuarantorPersonalInformation";
import GuarantorIdentityDetailsView from "@/modules/guarantor/_components/GuarantorIdentityDetails";
import GuarantorPreviewView from "@/modules/guarantor/_components/GuarantorPreviewView";
import GuarantorContainer from "@/containers/Guarantor";
import FormSubmittedSuccessfullyView from "@/modules/guarantor/_components/FormSubmittedSuccessfullyView";

export default [
  {
    path: "/request-verification/guarantor-consent/:action/:guarantor_token",
    name: "GuarantorConsentActionView",
    component: GuarantorConsentActionView,
    meta: {
      showProgressBar: true,
      plainLayout: true,
      documentTitle: "Grant consent"
    }
  },
  {
    path: "/guarantor/:guarantor_token",
    name: "GuarantorContainer",
    component: GuarantorContainer,
    redirect: {
      name: "GuarantorPersonalInformationView"
    },
    children: [
      {
        path: "personal-information",
        name: "GuarantorPersonalInformationView",
        component: GuarantorPersonalInformationView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Personal Information"
        }
      },
      {
        path: "identity-details",
        name: "GuarantorIdentityDetailsView",
        component: GuarantorIdentityDetailsView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Identity Details"
        }
      },
      {
        path: "preview",
        name: "GuarantorPreviewView",
        component: GuarantorPreviewView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Guarantor Details Preview"
        }
      }
    ]
  },
  {
    path: "/guarantor/:guarantor_token/guarantor-details-success",
    name: "GuarantorFormSubmittedSuccessfullyView",
    component: FormSubmittedSuccessfullyView,
    meta: {
      showProgressBar: true,
      plainLayout: true,
      documentTitle: "Details saved Successfully"
    }
  }
];
