import http from "@/helpers/http";

class VerificationService {
  static get axios() {
    return http;
  }

  static getVerifications(payload) {
    if (payload && payload.status) {
      return http.get("/verifications", {
        params: payload
      });
    }
    return http.get("/verifications", {
      params: { page: payload.page }
    });
  }

  static getAddressVerifications(payload) {
    if (payload && payload.status) {
      return http.get("/verifications/addresses", {
        params: payload
      });
    }
    return http.get("/verifications/addresses", {
      params: { page: payload.page }
    });
  }

  static requestVerification(payload) {
    let { email, mobile, license_types, agent_business, invite_code } = payload;
    const body = {
      email,
      mobile,
      license_types,
      agent_business
    };
    if (invite_code) {
      body.invite_code = invite_code;
    }
    return http.post("/verifications/request", body);
  }

  static requestAddressVerification(verification_id) {
    return http.post("/verifications/" + verification_id + "/address/request");
  }

  static getRequestVerification(verification_id) {
    return http.get("/verifications/" + verification_id);
  }

  static getVerificationTransactions(verification_id) {
    return http.get("/verifications/" + verification_id + "/transactions");
  }

  static updatePersonalInformation(payload) {
    let {
      photo,
      last_name,
      first_name,
      middle_name,
      dob,
      gender,
      mobile,
      other_mobile,
      email,
      //region,
      verification_id,
      selected_gaming_permit_types
    } = payload;

    return http.put("/verifications/" + verification_id + "/personal_info", {
      photo,
      last_name,
      first_name,
      middle_name,
      dob,
      gender,
      mobile,
      email,
      other_mobile,
      //region,
      gaming_permit_types: selected_gaming_permit_types
    });
  }

  static updateIdentityInfo(payload) {
    let { verification_id, id_number, id_type } = payload;
    return http.put("/verifications/" + verification_id + "/identity_info", {
      id_number,
      id_type
    });
  }

  static updateLicenseInfo(payload) {
    let { verification_id, license_number, license_image } = payload;
    return http.put("/verifications/" + verification_id + "/license_info", {
      license_number,
      license_image
    });
  }
  static updateAgentAddress(payload) {
    let { verification_id, ...address_details } = payload;
    address_details.isLoading = undefined;
    return http.put(
      "/verifications/" + verification_id + "/address_info",
      address_details
    );
  }
  static updateResidentialAgentAddress(payload) {
    let { verification_id, ...address_details } = payload;
    address_details.isLoading = undefined;
    return http.put(
      "/verifications/" + verification_id + "/residential_address",
      address_details
    );
  }
  static updateGuarantorInformation(payload) {
    let {
      //photo,
      last_name,
      first_name,
      middle_name,
      mobile,
      email,
      // other_mobile,
      // identity_type: id_type,
      // identity_number: id_number,
      verification_id
    } = payload;

    return http.put("/verifications/" + verification_id + "/guarantor_info", {
      //photo,
      last_name,
      first_name,
      middle_name: middle_name || undefined,
      mobile,
      email
      // other_mobile,
      // id_type,
      // id_number
    });
  }
  static updateAndSubmitGuarantorInformation(payload) {
    let {
      //photo,
      last_name,
      first_name,
      middle_name,
      mobile,
      email,
      // other_mobile,
      // identity_type: id_type,
      // identity_number: id_number,
      verification_id
    } = payload;

    return http.put(
      "/verifications/" + verification_id + "/guarantor_info/submit",
      {
        //photo,
        last_name,
        first_name,
        middle_name: middle_name || undefined,
        mobile,
        email
        // other_mobile,
        // id_type,
        // id_number
      }
    );
  }
  static initiateVerificationPayment(payload) {
    return http.post("/transactions/initiate/verification", payload);
  }
  static verifyVerificationPayment(payload) {
    return http.get("/transactions/" + payload + "/verify");
  }
  static submitVerificationForm(payload) {
    return http.post("verifications/" + payload + "/submit");
  }
  static uploadPersonalInformationImage(file) {
    let form = new FormData();
    form.append("file", file);
    return http.post("/uploads/image", form, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }

  static uploadPersonalInformationImageWithDetection(file, detectFace) {
    let form = new FormData();
    form.append("file", file);
    return http.post(`/uploads/image?detectFace=${detectFace}`, form, {
      headers: {
        "content-type": "multipart/form-data"
      }
    });
  }

  static exportVerifications(payload) {
    return http.get("/verifications/export", {
      params: payload
    });
  }

  static verifyRequestVerificationEmail(token) {
    return http.get("/verifications/verify/email/" + token);
  }

  static getDownloadUrl(verification_id) {
    return http.get("/verifications/" + verification_id + "/download");
  }
  static getVerificationPrice() {
    return http.get("/verifications/price");
  }
  static getVerificationRegions() {
    return http.get("/verifications/regions");
  }

  static searchAddressVerifications(payload) {
    return http.get("/verifications/addresses/search", {
      params: {
        query: payload.query,
        page: payload.page,
        paginate: payload.paginate
      }
    });
  }

  static exportAddressVerifications(payload) {
    return http.get("/verifications/addresses/export", {
      params: payload
    });
  }

  static confirmGuarantorConsent({ token, action }) {
    return http.get("/verifications/verify/guarantor/" + action + "/" + token);
  }
  static getGuarantorDetails(token) {
    return http.get("/verifications/guarantor/" + token);
  }

  static updateGuarantorPersonalInfo({
    token,
    first_name,
    last_name,
    middle_name,
    email,
    mobile,
    photo,
    dob
  }) {
    return http.post("/verifications/verify/guarantor/personal_info", {
      token,
      first_name,
      last_name,
      middle_name: middle_name || undefined,
      email,
      mobile,
      photo,
      dob
    });
  }

  static updateGuarantorIdentityInfo({ token, id_number, id_type }) {
    return http.post("/verifications/verify/guarantor/identity_info", {
      token,
      id_number,
      id_type
    });
  }

  static submitGuarantorDetails({ token }) {
    return http.post("/verifications/verify/guarantor/identity", {
      token
    });
  }

  static retryAgentVerification(verification) {
    console.log("verification", verification);
    return http.post(`/verifications/${verification}/retry`);
  }

  static updateGuarantorPersonalInformationAdmin({
    verification_id,
    guarantor_id,
    guarantorInfo
  }) {
    return http.put(
      "/verifications/" + verification_id + "/guarantor/" + guarantor_id,
      guarantorInfo
    );
  }

  static updateAgentPersonalInformationAdmin({ verification_id, agentInfo }) {
    return http.put("/verifications/" + verification_id, agentInfo);
  }
}

export default VerificationService;
