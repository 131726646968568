const getters = {
  getGeneratedCouponsLog: state => {
    return state.generated_coupons_log;
  },
  getGeneratedCouponsLogPagination: state => {
    return (
      state.generated_coupons_meta && state.generated_coupons_meta.pagination
    );
  },
  getGeneratedCouponsLogMeta: state => state.generated_coupons_meta
};

export default getters;
