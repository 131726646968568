import * as types from "@/store/types";
import profileService from "../_api";
import StorageService from "@/helpers/storage";

const actions = {
  async [types.GET_PROFILE_ACTION]({ commit }) {
    let profile = (await profileService.getUserProfile()).data;
    commit(types.SET_PROFILE, profile);
    return profile;
  },
  async [types.UPDATE_USER_PROFILE_ACTION]({ commit }, payload) {
    let profile = await profileService.updateUserProfile(payload);
    commit(types.SET_PROFILE, profile.data);
    return profile.data;
  },

  async [types.CHANGE_USER_PASSWORD_ACTION]({ commit }, payload) {
    let response = (await profileService.changeUserPassword(payload)).data;

    //save token in storage
    StorageService.saveTokenToStorage(response.token);
    commit(types.SET_PROFILE, response.user);
    return response;
  }
};

export default actions;
