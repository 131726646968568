import * as types from "@/store/types";
import Api from "../_api";
import StorageService from "@/helpers/storage";

const actions = {
  async [types.LOGIN_USER_ACTION]({ commit }, payload) {
    let response = (await Api.loginUser(payload)).data;
    //save token in storage
    StorageService.saveTokenToStorage(response.token);
    commit(types.SET_PROFILE, response.user);
    return response;
  },

  async [types.SEND_PASSWORD_RESET_LINK_ACTION](_, payload) {
    return await Api.sendPasswordResetLink(payload);
  },

  async [types.VERIFY_RESET_PASSWORD_TOKEN_ACTION](_, payload) {
    return await Api.verifyResetPasswordToken(payload);
  },
  async [types.RESET_USER_PASSWORD_ACTION](_, payload) {
    return await Api.resetUserPassword(payload);
  },
  async [types.VERIFY_USER_REGISTER_TOKEN](_, token) {
    let response = (await Api.verifyUserRegisterToken(token)).data;
    return response;
  },
  async [types.REGISTER_USER_ACTION]({ commit }, payload) {
    let response = (await Api.registerUser(payload)).data;
    //save token in storage
    StorageService.saveTokenToStorage(response.token);
    commit(types.SET_PROFILE, response.user);
    return response;
  },
  async [types.LOOKUP_EMAIL_ACTION]({ commit }, payload) {
    let response = (await Api.lookupEmail(payload)).data;
    commit(types.SET_AUTH_DATA, {
      email: response.account,
      auth_type: response.auth_type
    });
    return response;
  }
};
export default actions;
