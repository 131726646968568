const getters = {
  getCurrentUser: state => {
    return state.current_user;
  },
  getBusinessProfile: state => {
    return state.current_user && state.current_user.business;
  },
  getAllPermissions: state => {
    return (
      state.current_user &&
      state.current_user.business &&
      state.current_user.business.permissions
    );
  },
  getUserPermissions: state => {
    return (
      state.current_user &&
      state.current_user.role &&
      state.current_user.role.permissions
    );
  }
};

export default getters;
