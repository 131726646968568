import {
  USER_ID_KEY,
  ACCESS_TOKEN_KEY,
  BUSINESS_ID_KEY
} from "@/config/constant";
import notify from "@/helpers/notifyMe";
import { myApp } from "@/main";

class StorageService {
  static saveUserProfileID(id) {
    this.setItem(USER_ID_KEY, id);
  }
  static saveBusinessProfileID(id) {
    this.setItem(BUSINESS_ID_KEY, id);
  }

  static getUserProfileID() {
    if (!this.getItem(USER_ID_KEY)) {
      // log user-out
      this.removeItem(ACCESS_TOKEN_KEY);
      StorageService.removeUserProfileID();
      myApp.$router.push({
        name: "LoginView"
      });
      //notify of error
      notify.error({
        message: "Please login to continue"
      });
    }
    return this.getItem(USER_ID_KEY);
  }
  static getBusinessProfileID() {
    if (!this.getItem(BUSINESS_ID_KEY)) {
      // log user-out
      this.removeItem(ACCESS_TOKEN_KEY);
      StorageService.removeBusinessProfileID();
      myApp.$router.push({
        name: "LoginView"
      });
      //notify of error
      notify.error({
        message: "Please login to continue"
      });
    }
    return this.getItem(BUSINESS_ID_KEY);
  }

  static removeUserProfileID() {
    this.removeItem(USER_ID_KEY);
  }
  static removeBusinessProfileID() {
    this.removeItem(BUSINESS_ID_KEY);
  }

  static saveTokenToStorage(token) {
    return this.setItem(ACCESS_TOKEN_KEY, token);
  }
  static getTokenFromStorage() {
    return this.getItem(ACCESS_TOKEN_KEY);
  }
  static removeTokenFromStorage() {
    this.removeItem(ACCESS_TOKEN_KEY);
  }

  static removeItem(key) {
    localStorage.removeItem(key);
  }
  static getItem(key) {
    return localStorage.getItem(key);
  }
  static setItem(key, string) {
    if (string) {
      localStorage.setItem(key, string);
      return string;
    }
  }
}

export default StorageService;
