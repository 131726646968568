// components
import NewVerificationContainer from "@/containers/NewVerification";
import RequestVerificationView from "@/modules/request-verification/Index";
import PersonalInformationView from "@/modules/request-verification/_components/PersonalInformation";
import GuarantorInformationView from "@/modules/request-verification/_components/GuarantorInformation";
import IdentityVerificationNumberView from "@/modules/request-verification/_components/IdentityVerificationNumber";
import AgentLicenseNumberView from "@/modules/request-verification/_components/DriverLicenseNumber";
import AgentAddressView from "@/modules/request-verification/_components/AgentAddress";
import AgentResidentialAddressView from "@/modules/request-verification/_components/AgentResidentialAddress";
import VerificationPaymentView from "@/modules/request-verification/_components/VerificationPayment";
import RequestVerificationPreviewView from "@/modules/request-verification/_components/RequestVerificationPreviewView";
import RequestVerificationVerifyEmailView from "@/modules/request-verification/_components/RequestVerificationVerifyEmailView";
import FormSubmittedSuccessfullyView from "@/modules/request-verification/_components/FormSubmittedSuccessfullyView";
import RequestVerificationConsentView from "@/modules/request-verification/_components/RequestVerificationConsentView";

export default [
  {
    path: "/request-verification",
    name: "RequestVerificationView",
    component: RequestVerificationView,
    meta: {
      plainLayout: true,
      showProgressBar: true,
      documentTitle: "Application Form"
    }
  },
  {
    path: "/request-verification/consent/:verification_id?",
    name: "RequestVerificationConsentView",
    component: RequestVerificationConsentView,
    meta: {
      showProgressBar: true,
      plainLayout: true,
      documentTitle: "Verification Consent"
    }
  },
  {
    path: "/request-verification/verify-email/:token",
    name: "RequestVerificationVeriifyEmailView",
    component: RequestVerificationVerifyEmailView,
    meta: {
      showProgressBar: true,
      plainLayout: true,
      documentTitle: "Verify Email Address"
    }
  },
  {
    path: "/request-verification/:verification_id/verification-success",
    name: "FormSubmittedSuccessfullyView",
    component: FormSubmittedSuccessfullyView,
    meta: {
      showProgressBar: true,
      plainLayout: true,
      documentTitle: "Application Submitted Successfully"
    }
  },
  {
    path: "/request-verification/:verification_id",
    name: "NewVerificationContainer",
    component: NewVerificationContainer,
    redirect: {
      name: "PersonalInformationView"
    },
    children: [
      {
        path: "personal-information",
        name: "PersonalInformationView",
        component: PersonalInformationView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Personal Information"
        }
      },
      {
        path: "identity-details",
        name: "IdentityVerificationNumberView",
        component: IdentityVerificationNumberView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Identity Information"
        }
      },
      {
        path: "frsc-details",
        name: "AgentLicenseNumberView",
        component: AgentLicenseNumberView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Driver License Information"
        }
      },
      {
        path: "agent-address",
        name: "AgentAddressView",
        component: AgentAddressView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Agent Address"
        }
      },
      {
        path: "residential-address",
        name: "AgentResidentialAddressView",
        component: AgentResidentialAddressView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Agent Address"
        }
      },
      {
        path: "guarantor-information",
        name: "GuarantorInformationView",
        component: GuarantorInformationView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Guarantor Information"
        }
      },
      {
        path: "preview",
        name: "RequestVerificationPreviewView",
        component: RequestVerificationPreviewView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Form Preview"
        }
      },
      {
        path: "verification-payment",
        name: "VerificationPaymentView",
        component: VerificationPaymentView,
        meta: {
          showProgressBar: true,
          plainLayout: true,
          documentTitle: "Application Payment"
        }
      }
    ]
  }
];
