<template>
  <div class="card bordered-box m-t-15">
    <div class="card-block">
      <div class="row">
        <div class="col-md-8 offset-md-2 text-center">
          <h2 class="card-title">
            Hi, {{ current_user.first_name }} - Welcome to LSLGA Permit Portal
          </h2>
          <p>
            Below are some modules to help you get started. You can always refer
            to this modules whenever you need to.
          </p>
        </div>
      </div>
      <div class="row p-t-5">
        <!-- <div class="col-md-4">
          <div class="card bordered-box">
            <div class="card-block text-center">
              <div class="card-icon text-success">
                <span class="mdi mdi-access-point"></span>
              </div>
              <h4 class="card-title text-capitalize font-medium">Activate Your Business</h4>
              <p class="card-text">To go live mode complete a few tasks</p>
              <button class="btn btn-outline-success">Start</button>
            </div>
          </div>
        </div>-->
        <div class="offset-md-2 col-md-4">
          <div class="card bordered-box">
            <div class="card-block text-center">
              <div class="card-icon text-success">
                <span class="mdi mdi-comment-question-outline"></span>
              </div>
              <h4 class="card-title text-capitalize font-medium">FAQ</h4>
              <p class="card-text">Have questions? Why not view our FAQ</p>
              <router-link
                :to="{ name: 'FAQView' }"
                class="btn btn-outline-success"
                >View</router-link
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card bordered-box">
            <div class="card-block text-center">
              <div class="card-icon text-success">
                <span class="mdi mdi-van-passenger"></span>
              </div>
              <h4 class="card-title text-capitalize font-medium">
                Dashboard Tour
              </h4>
              <p class="card-text">
                See the awesome things you can do with the Portal
              </p>
              <button @click="startTour" class="btn btn-outline-success">
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      steps: []
    };
  },
  computed: {
    ...mapGetters({
      current_user: "getCurrentUser",
      permisions: "getUserPermissions"
    })
  },
  mounted() {
    let vm = this;
    this.scrollView = $(".scroll-sidebar");

    if (this.current_user.user_type === "company") {
      this.steps = [
        // {
        //   element: "#tour-dashboard-view",
        //   title: "Dashboard",
        //   content:
        //           "View balance, agent and application metrics with a graph for easy visualization of trends",
        //   placement: "right",
        //   permission: "metric-view,business-balance-view"
        // },
        // {
        //   element: "#tour-report-view",
        //   title: "Report",
        //   content: "View all agent application logs",
        //   placement: "right",
        //   permission: "report-view"
        // },
        // {
        //   element: "#tour-identity-view",
        //   title: "Identity",
        //   content:
        //           "Carry out identity verification for individuals and view identity logs and reports",
        //   placement: "right",
        //   permission:
        //           "verify-identity-nin,verify-identity-ibvn,verify-identity-frsc,identity-view"
        // },
        // {
        //   element: "#tour-generate-links-view",
        //   title: "Generate Links",
        //   content: "Generate Application Links for quick agent onboarding",
        //   placement: "right",
        //   permission: "verification-link-view"
        // },
        // {
        //   element: "#tour-coupon-view",
        //   title: "Coupons",
        //   content:
        //     "View logs and generate single and multiple application coupons",
        //   placement: "right",
        //   permission: "coupon-view"
        // },
        // {
        //   element: "#tour-address-view",
        //   title: "Addresses",
        //   content: "View and manage agent address verifications",
        //   placement: "right",
        //   permission: "*"
        // },
        {
          element: "#tour-agent-view",
          title: "Agents",
          content: "View agent logs and access agent application reports",
          placement: "right",
          permission: "agent-view"
        },
        {
          element: "#tour-user-view",
          title: "Users",
          content:
            "View and manage users and roles, which includes, inviting a new user, activating and deactivating users and changing user roles",
          placement: "right",
          permission: "user-view"
        },
        {
          element: "#tour-profile-view",
          title: "User Profile",
          content: "You can view and edit your personal information here",
          placement: "right",
          permission: "*"
        },
        {
          element: "#tour-transaction-view",
          title: "Transactions",
          content:
            "Keep track of every transaction carried-out on the portal and requery transactions",
          placement: "right",
          permission: "transaction-view"
        },
        {
          element: "#tour-audit-view",
          title: "Audit Log",
          content:
            "Keep track of every action taken on the dashboard and by who, ensure proper control and auditing",
          placement: "right",
          permission: "audit-log-view"
        },
        // {
        //   element: "#tour-faq-view",
        //   title: "FAQ",
        //   content: "Get quick answers to frequently asked questions",
        //   placement: "right",
        //   permission: "*"
        // },
        {
          element: "#tour-setting-view",
          title: "Settings",
          content:
            "Edit business profile and configure the portal to work the way you want it to",
          placement: "right",
          permission: "setting-view"
        }
      ];
    } else {
      this.steps = [
        {
          element: "#tour-dashboard-view",
          title: "Dashboard",
          content:
            "View balance, agent and application metrics with a graph for easy visualization of trends",
          placement: "right",
          permission: "metric-view,business-balance-view"
        },
        {
          element: "#tour-report-view",
          title: "Report",
          content: "View all agent application logs",
          placement: "right",
          permission: "report-view"
        },
        {
          element: "#tour-identity-view",
          title: "Identity",
          content:
            "Carry out identity verification for individuals and view identity logs and reports",
          placement: "right",
          permission:
            "verify-identity-nin,verify-identity-ibvn,verify-identity-frsc,identity-view"
        },
        // {
        //   element: "#tour-generate-links-view",
        //   title: "Generate Links",
        //   content: "Generate Application Links for quick agent onboarding",
        //   placement: "right",
        //   permission: "verification-link-view"
        // },
        // {
        //   element: "#tour-coupon-view",
        //   title: "Coupons",
        //   content:
        //     "View logs and generate single and multiple application coupons",
        //   placement: "right",
        //   permission: "coupon-view"
        // },
        {
          element: "#tour-address-view",
          title: "Addresses",
          content: "View and manage agent address verifications",
          placement: "right",
          permission: "*"
        },
        {
          element: "#tour-agent-view",
          title: "Agents",
          content: "View agent logs and access agent application reports",
          placement: "right",
          permission: "agent-view"
        },
        {
          element: "#tour-user-view",
          title: "Users",
          content:
            "View and manage users and roles, which includes, inviting a new user, activating and deactivating users and changing user roles",
          placement: "right",
          permission: "user-view"
        },
        {
          element: "#tour-profile-view",
          title: "User Profile",
          content: "You can view and edit your personal information here",
          placement: "right",
          permission: "*"
        },
        {
          element: "#tour-transaction-view",
          title: "Transactions",
          content:
            "Keep track of every transaction carried-out on the portal and requery transactions",
          placement: "right",
          permission: "transaction-view"
        },
        {
          element: "#tour-audit-view",
          title: "Audit Log",
          content:
            "Keep track of every action taken on the dashboard and by who, ensure proper control and auditing",
          placement: "right",
          permission: "audit-log-view"
        },
        // {
        //   element: "#tour-faq-view",
        //   title: "FAQ",
        //   content: "Get quick answers to frequently asked questions",
        //   placement: "right",
        //   permission: "*"
        // },
        {
          element: "#tour-setting-view",
          title: "Settings",
          content:
            "Edit business profile and configure the portal to work the way you want it to",
          placement: "right",
          permission: "setting-view"
        }
      ];
    }

    this.steps = this.steps.filter(step => {
      return step.permission.split(",").some(permission => {
        return this.permisions.includes(permission) || permission === "*";
      });
    });
    // Instance the tour
    this.tour = new Tour({
      name: "youverify_tour",
      steps: this.steps,
      container: "body",
      smartPlacement: true,
      keyboard: true,
      storage: false,
      debug: false,
      backdrop: true,
      backdropContainer: "body",
      backdropPadding: 0,
      redirect: false,
      orphan: false,
      duration: false,
      delay: false,
      autoscroll: false,
      onNext: function(tour) {
        let step = tour._current == null ? 0 : tour._current + 1;
        vm.scrollMenu(tour._options.steps[step].element);
      },
      onPrev: function(tour) {
        let step = tour._current == null ? 0 : tour._current - 1;
        vm.scrollMenu(tour._options.steps[step].element);
      },
      template: `<div class='popover tour'>
                            <div class='arrow'></div>
                            <h3 class='popover-title'></h3>
                            <div class='popover-content'></div>
                            <div class='popover-navigation p-20'>
                                <div class='btn-group'>
                                    <button class='btn btn-sm btn-outline-success' data-role='prev'>« Prev</button>
                                    <button class='btn btn-sm btn-outline-success' data-role='next'>Next »</button>
                                </div>
                                <button class='btn btn-sm btn-outline-success rounded-0 m-l-5' data-role='end'>End tour</button>
                            </div>
                        </div>`
    });
  },
  methods: {
    startTour() {
      // Initialize the tour
      this.tour.init();
      // Start the tour and scrollTo dashboard Menu
      this.scrollMenu(this.steps[0].element);
      this.tour.restart();
    },
    scrollMenu(element) {
      let scrollToVal = parseInt(
        this.scrollView.scrollTop() + $(element).position().top - 50
      );
      this.scrollView.slimScroll({ scrollTo: scrollToVal + "px" });
    }
  }
};
</script>
