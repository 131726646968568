<template>
  <div>
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Manage Roles component -->
    <!-- ============================================================== -->
    <manage-roles></manage-roles>
    <!-- ============================================================== -->
    <!-- Create/Update/Delete Role Modal -->
    <!-- ============================================================== -->
    <crud-custom-role-modal></crud-custom-role-modal>
    <!-- View all Role Users Modal -->
    <!-- ============================================================== -->
    <view-all-role-users-modal></view-all-role-users-modal>
  </div>
</template>

<script>
import ManageRoles from "./_components/ManageRoles";
import CrudCustomRoleModal from "./_components/CrudCustomRoleModal";
import ViewAllRoleUsersModal from "./_components/ViewRoleUsersModal";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";

export default {
  components: {
    ManageRoles,
    CrudCustomRoleModal,
    ViewAllRoleUsersModal
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.GET_ALL_ROLES_ACTION, true);
      let default_role = store.getters.getAllDefaultRoles[0];
      await store.dispatch(types.GET_ROLE_WITH_USERS_ACTION, default_role);
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  beforeDestroy() {
    // $("#crud-custom-role-modal").modal("dispose");
    // $("#view-all-role-users-modal").modal("dispose");

    $("#crud-custom-role-modal").modal("hide");
    $("#crud-custom-role-modal").on("hidden.bs.modal", function() {
      $("#crud-custom-role-modal").modal("dispose");
    });

    $("#view-all-role-users-modal").modal("hide");
    $("#view-all-role-users-modal").on("hidden.bs.modal", function() {
      $("#view-all-role-users-modal").modal("dispose");
    });
  }
};
</script>
