<template>
  <div>
    <div class="row" v-if="verifications.length > 0">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr class="link-row">
                <th width="70" class="text-center">#</th>
                <th class="text-nowrap">Application ID</th>
                <th class="text-nowrap">License Type(s)</th>
                <th>Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(verification, index) in verifications"
                :key="verification.id"
                class="link-row"
              >
                <td class="text-center">
                  <router-link
                    :to="{
                      name: type,
                      params: {
                        verification_id: verification.id,
                        profile_id: profile_id
                      }
                    }"
                  >
                    {{ index + 1 }}
                  </router-link>
                </td>

                <td>
                  <router-link
                    :to="{
                      name: type,
                      params: {
                        verification_id: verification.id,
                        profile_id: profile_id
                      }
                    }"
                  >
                    {{ verification.id }}
                  </router-link>
                </td>
                <td class="text-capitalize">
                  <router-link
                    :to="{
                      name: type,
                      params: {
                        verification_id: verification.id,
                        profile_id: profile_id
                      }
                    }"
                  >
                    {{
                      verification.license_types.length > 0
                        ? verification.license_types
                            .map(
                              license_type =>
                                license_type.license_type.category_name
                            )
                            .join(", ")
                        : "None"
                    }}
                  </router-link>
                </td>
                <td class="text-capitalize">
                  <router-link
                    :to="{
                      name: type,
                      params: {
                        verification_id: verification.id,
                        profile_id: profile_id
                      }
                    }"
                  >
                    {{ verification.created_at | long_date }}
                  </router-link>
                </td>
                <td v-tooltip="statusMeanings[verification.status]">
                  <router-link
                    :to="{
                      name: type,
                      params: {
                        verification_id: verification.id,
                        profile_id: profile_id
                      }
                    }"
                  >
                    <span
                      class="label text-capitalize font-bold rounded-0 text-nowrap"
                      :class="[
                        verification.status === 'verified'
                          ? 'label-light-success'
                          : verification.status === 'not_verified'
                          ? 'label-light-danger'
                          : verification.status === 'pending'
                          ? 'label-light-warning'
                          : verification.status === 'incomplete'
                          ? 'label-light-primary'
                          : ''
                      ]"
                    >
                      {{ verification.status | remove_underscore }}
                    </span>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!--          <div class="p-30 test font-medium text-muted">-->
          <!--            <span-->
          <!--              >Showing 1 to {{ verifications.length }} of-->
          <!--              {{ verifications.length }} entries</span-->
          <!--            >-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <img
        src="/static/empty.svg"
        class="img-fluid mb-3"
        alt="empty"
        style="width: 50px"
      />
      <p class="font-bold font-14">There are no applications to display</p>
    </div>
  </div>
</template>

<script>
import statusMeanings from "@/helpers/statusMeanings";

export default {
  name: "AgentVerificationsLog",
  data() {
    return {
      statusMeanings
    };
  },
  props: [
    //"verifications",
    "verification",
    "type",
    "profile_id"
  ],
  computed: {
    verifications() {
      console.log(this.verification);
      return [this.verification];
    }
  }
};
</script>
