<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="add-permit-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="add-permit-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Add New Permit</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="add-permit-form">
            <div
              class="col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"
              v-if="message"
            >
              {{ message }}
            </div>
            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="name" class="control-label form-label">
                  Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="category_name"
                  :class="{
                    'form-error animated shake': $v.category_name.$error
                  }"
                  @input="$v.category_name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Permit Name"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              @click.prevent="addNewPermit"
              :disabled="$v.$invalid || isLoading"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Add New Permit
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as types from "@/store/types";
import eventBus from "@/helpers/event";

export default {
  data() {
    return {
      category_name: "",
      isLoading: false,
      message: ""
    };
  },
  validations: {
    category_name: { required }
    // role: { required }
  },
  methods: {
    async addNewPermit() {
      //check if form is valid
      if (this.$v.$invalid || this.isLoading) {
        return;
      }
      //clear message if exists
      this.message = "";
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.ADD_NEW_PERMIT_ACTION, {
          category_name: this.category_name
        });
        eventBus.$emit("lslb:permit:refresh-permits-list");
        this.category_name = "";
        // this.role = "";
        this.isLoading = false;
        this.$v.$reset();
        this.message = "Permit created sucessfully";
        // this.$notify.success({ message: "Invitation sent successfully" });
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      }
    }
  }
};
</script>
