<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="crud-custom-role-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium text-capitalize">
            {{
              mode === "create"
                ? "Create"
                : mode === "update"
                ? "Update"
                : "Delete"
            }}
            Custom Role
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <form id="crud-custom-role-form" v-if="mode != 'delete'">
            <div class="col-sm-12">
              <div class="form-group m-b-10">
                <label for="role-name" class="control-label form-label">
                  Role Name
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="name"
                  :class="{ 'form-error animated shake': $v.name.$error }"
                  @input="$v.name.$touch"
                  class="form-control rounded-0"
                  placeholder="Enter Role Name"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="form-group m-b-10">
                <label for="role-desc" class="control-label form-label">
                  Role Description
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  v-model.trim="description"
                  class="form-control rounded-0"
                  :class="{
                    'form-error animated shake': $v.description.$error
                  }"
                  @input="$v.description.$touch"
                  placeholder="Enter Role Description"
                />
              </div>
            </div>
            <div
              class="col-md-12 mt-4"
              v-for="(permission, index) in all_permissions"
              :key="index"
            >
              <label class="control-label form-label text-capitalize">{{
                permission.name
              }}</label>
              <div
                class="form-group m-b-10 checkbox checkbox-success"
                v-for="(_permission, index) in permission.permissions"
                :key="index"
              >
                <input
                  class="__form-check-input text-capitalize"
                  type="checkbox"
                  v-model.trim="selected_permissions"
                  :value="_permission.permission"
                  @change="$v.selected_permissions.$touch"
                  :id="'custom-role-modal-' + _permission.permission"
                />
                <label
                  class="__form-check-label no-select font-14 text-capitalize"
                  :for="'custom-role-modal-' + _permission.permission"
                >
                  <span class="font-bold mr-1">Can </span>{{ _permission.name }}
                </label>
              </div>
            </div>
          </form>
          <template v-if="mode === 'delete'">
            <div class="col-sm-12">
              This action will delete the role
              <span class="font-bold">{{ role.name }}</span> and you will no
              longer be able to assign team members to it
            </div>
          </template>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              :disabled="$v.$invalid || isLoading"
              @click.prevent="createNewRole"
              v-if="mode === 'create'"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Create Role
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
            <button
              type="button"
              :disabled="$v.$invalid || isLoading || !$v.$anyDirty"
              @click.prevent="updateExistingRole"
              v-if="mode === 'update'"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Update Role
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
            <button
              type="button"
              :disabled="$v.$invalid || isLoading"
              @click.prevent="deleteExistingRole"
              v-if="mode === 'delete'"
              class="btn btn-danger rounded-0 waves-effect p-10 w-100 p-10"
            >
              Yes, Delete Role
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import eventBus from "@/helpers/event";
import * as types from "@/store/types";

export default {
  data() {
    return {
      name: "",
      description: "",
      selected_permissions: [],
      role: "",
      isLoading: false,
      mode: "create"
    };
  },
  mounted() {
    eventBus.$on(
      "lslb:role:open-crud-custom-role-modal",
      ({ role, mode = "create" }) => {
        this.role = role || "";
        this.name = role.name || "";
        this.description = role.description || "";
        this.selected_permissions = role.permissions || [];
        this.mode = mode;
        this.$v.$reset();
      }
    );
  },
  computed: {
    ...mapGetters({
      all_permissions: "getAllPermissions"
    })
  },
  validations: {
    name: { required },
    description: { required },
    selected_permissions: { required },
    role: {
      required: requiredIf(function() {
        return this.mode != "create";
      })
    }
  },
  methods: {
    async createNewRole() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.CREATE_NEW_CUSTOM_ROLE_ACTION, {
          ...this.$data
        });
        this.$notify.success({
          message: "New Custom Role created successfully"
        });
        $("#crud-custom-role-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async updateExistingRole() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.UPDATE_CUSTOM_ROLE_ACTION, {
          ...this.$data
        });
        this.$notify.success({
          message: "Custom Role updated successfully"
        });
        $("#crud-custom-role-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteExistingRole() {
      this.isLoading = true;
      try {
        await this.$store.dispatch(types.DELETE_CUSTOM_ROLE_ACTION, {
          ...this.$data
        });
        this.$notify.success({
          message: "Custom Role deleted successfully"
        });
        $("#crud-custom-role-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
