import http from "@/helpers/http";

class Api {
  static get axios() {
    return http;
  }

  static getAuditLogEvents() {
    return http.get("/audit-logs/events");
  }

  static getAuditLog(payload) {
    if (payload && payload.user_id) {
      return http.get("/audit-logs", {
        params: payload
      });
    }
    return http.get("/audit-logs", {
      params: { page: payload.page }
    });
  }

  static exportAuditLog(payload) {
    return http.get("/audit-logs/export", {
      params: payload
    });
  }
}
export default Api;
