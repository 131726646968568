var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-5 px-2 flex-box d-flex justify-content-center align-items-center flex-grow w-100"},[_c('div',{staticClass:"card bordered-box w-100 m-auto",staticStyle:{"max-width":"400px"}},[_c('form',{staticClass:"card-block form-horizontal form-material2",on:{"submit":function($event){$event.preventDefault();return _vm.resetUserPassword($event)}}},[(!_vm.hasResetPassword)?[_c('div',{staticClass:"form-group"},[_vm._m(0),(_vm.message)?_c('div',{staticClass:"col-xs-12 alert alert-success-light font-normal font-14 font-bold rounded-0 text-center"},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_vm._m(1),_c('div',{staticClass:"col-xs-12 input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.new_password),expression:"new_password",modifiers:{"trim":true}}],staticClass:"form-control rounded-0",class:{ 'form-error animated shake': _vm.$v.new_password.$error },attrs:{"id":"reset_new_password","type":"password","required":"","placeholder":"Enter New Password"},domProps:{"value":(_vm.new_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.new_password=$event.target.value.trim()},_vm.$v.new_password.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.new_password),expression:"new_password"}],staticClass:"form-group mt-2"},[_c('div',{staticClass:"col-xs-12 font-14"},[_vm._v(" At least; "),_c('ul',{staticClass:"list-unstyled"},[_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsLowerCase
                        ? 'mdi-check-circle text-success'
                        : 'mdi-circle text-muted'}),_vm._v(" One lowercase letter ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsUpperCase
                        ? 'mdi-check-circle text-success'
                        : 'mdi-circle text-muted'}),_vm._v(" One uppercase letter ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsSpecialCharacter
                        ? 'mdi-check-circle text-success'
                        : 'mdi-circle text-muted'}),_vm._v(" One special character (allowed characters ! @ # $ % ^ & _ -) ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.containsNumber
                        ? 'mdi-check-circle text-success'
                        : 'mdi-circle text-muted'}),_vm._v(" One number ")]),_c('li',{staticClass:"font-14"},[_c('i',{staticClass:"mdi",class:_vm.$v.new_password.isGreaterThanEight
                        ? 'mdi-check-circle text-success'
                        : 'mdi-circle text-muted'}),_vm._v(" Eight characters ")])])])])]),_c('transition',{attrs:{"enter-active-class":"animated fastest fadeIn","leave-active-class":"animated fastest fadeOut"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.new_password.$invalid),expression:"!$v.new_password.$invalid"}],staticClass:"form-group"},[_c('div',{staticClass:"col-xs-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"reset_confirm_password"}},[_vm._v(" Confirm Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('div',{staticClass:"col-xs-12"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirm_password),expression:"confirm_password",modifiers:{"trim":true}}],staticClass:"form-control rounded-0",class:{
                  'form-error animated shake': _vm.$v.confirm_password.$error
                },attrs:{"id":"reset_confirm_password","type":"password","required":"","placeholder":"Confirm New Password"},domProps:{"value":(_vm.confirm_password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.confirm_password=$event.target.value.trim()},_vm.$v.confirm_password.$touch],"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.confirm_password.sameAsNewPassword)?_c('div',{staticClass:"text-danger m-t-5 font-12 font-bold"},[_vm._v(" Must be the same as new password ")]):_vm._e()])])]),_c('div',{staticClass:"form-group text-center m-t-20"},[_c('div',{staticClass:"col-xs-12"},[_c('button',{staticClass:"btn btn-warning btn-lg btn-block text-uppercase waves-effect waves-light rounded-0",attrs:{"disabled":_vm.isLoading || _vm.$v.$invalid,"type":"submit"}},[_vm._v(" Reset Password "),(_vm.isLoading)?_c('i',{staticClass:"mdi mdi-loading mdi-spin"}):_vm._e()])])])]:_vm._e(),(_vm.hasResetPassword)?[_vm._m(2)]:_vm._e(),_c('div',{staticClass:"form-group m-b-0"},[_c('div',{staticClass:"col-sm-12 text-center"},[_c('router-link',{staticClass:"text-dark m-l-5",attrs:{"to":{ name: 'LoginView' }}},[_c('strong',[_vm._v("Back to login")])])],1)])],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('h3',{staticClass:"text-center"},[_vm._v("Reset Password")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('label',{staticClass:"form-label",attrs:{"for":"reset_new_password"}},[_vm._v(" New Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group text-center"},[_c('h1',[_vm._v("Password Reset Successful")]),_c('i',{staticClass:"text-success mdi mdi-check-circle-outline ml-1 display-1"}),_c('p',{staticClass:"font-14 font-bold"},[_vm._v(" Please click the link below to go back to login ")])])}]

export { render, staticRenderFns }