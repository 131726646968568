<template>
  <div class="col-md-12">
    <div
      class="card bordered-box position-relative w-100"
      style="overflow: hidden;"
    >
      <div class="card-block">
        <h3 class="card-title">Report Progress</h3>
        <ul class="timeline">
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.created_at ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-check"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.created_at ? 'text-warning' : ''"
                >
                  Application Request
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Created</span> &mdash;
                  {{ report.created_at | long_date | not_available }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Started</span> &mdash;
                  {{ report.started_at | long_date | not_available }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.personal_info.completed ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-account"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.personal_info.completed ? 'text-warning' : ''"
                >
                  Personal Information
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Completed</span> &mdash;
                  {{
                    report.personal_info.completed_at
                      | long_date
                      | not_available
                  }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Updated</span> &mdash;
                  {{
                    report.personal_info.updated_at | long_date | not_available
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.identity_info.completed ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-account-check"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.identity_info.completed ? 'text-warning' : ''"
                >
                  Identity Information
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Completed</span> &mdash;
                  {{
                    report.identity_info.completed_at
                      | long_date
                      | not_available
                  }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Updated</span> &mdash;
                  {{
                    report.identity_info.updated_at | long_date | not_available
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.address_info.completed ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-home"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.address_info.completed ? 'text-warning' : ''"
                >
                  Address Information
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Completed</span> &mdash;
                  {{
                    report.address_info.completed_at | long_date | not_available
                  }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Updated</span> &mdash;
                  {{
                    report.address_info.updated_at | long_date | not_available
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.guarantor_info.completed ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-account-check"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.guarantor_info.completed ? 'text-warning' : ''"
                >
                  Guarantor
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Completed</span> &mdash;
                  {{
                    report.guarantor_info.completed_at
                      | long_date
                      | not_available
                  }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Updated</span> &mdash;
                  {{
                    report.guarantor_info.updated_at | long_date | not_available
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.paid ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-cash"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.paid ? 'text-warning' : ''"
                >
                  Payment
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Paid</span> &mdash;
                  {{ report.paid_at | long_date | not_available }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.submitted ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-send"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="report.submitted ? 'text-warning' : ''"
                >
                  Submission
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Submitted</span> &mdash;
                  {{ report.submitted_at | long_date | not_available }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Form Completed In</span> &mdash;
                  {{ report.form_completed_time | not_available }}
                </p>
              </div>
            </div>
          </li>
          <li class="timeline-inverted">
            <div
              class="timeline-badge"
              :class="report.verification_completed_at ? 'warning' : 'pending'"
            >
              <i class="mdi mdi-file-chart"></i>
            </div>
            <div class="timeline-panel">
              <div class="timeline-heading">
                <h4
                  class="timeline-title font-16 text-capitalize"
                  :class="
                    report.verification_completed_at ? 'text-warning' : ''
                  "
                >
                  Verification
                </h4>
              </div>
              <div class="timeline-body">
                <p class="font-14">
                  <span class="font-bold">Started</span> &mdash;
                  {{
                    report.verification_started_at | long_date | not_available
                  }}
                </p>

                <p class="font-14">
                  <span class="font-bold">Completed</span> &mdash;
                  {{
                    report.verification_completed_at | long_date | not_available
                  }}
                </p>
                <p class="font-14">
                  <span class="font-bold">Turnaround Time</span> &mdash;
                  {{ report.tat_formated | not_available }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportProgressTimeline",
  props: ["report"]
};
</script>
