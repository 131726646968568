<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Ecosystem" />
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Tab component -->
    <!-- ============================================================== -->
    <div class="row">
      <div class="col-12">
        <div class="no-card">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs customtab" role="tablist">
            <li class="nav-item" v-if="$User.hasPermission('permit-create')">
              <router-link
                :to="{ name: 'PermitsView' }"
                class="nav-link"
                active-class="active"
                role="tab"
              >
                <span class="hidden-sm-up">
                  <i class="mdi-card-account-details"></i>
                </span>
                <span class="hidden-xs-down">Permits</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$User.hasPermission('agent-view')">
              <router-link
                :to="{ name: 'AgentsView' }"
                class="nav-link"
                active-class="active"
                role="tab"
              >
                <span class="hidden-sm-up">
                  <i class="mdi-card-account-details"></i>
                </span>
                <span class="hidden-xs-down">Agents</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="$User.hasPermission('user-view')">
              <router-link
                :to="{ name: 'UsersView' }"
                active-class="active"
                class="nav-link"
                role="tab"
              >
                <span class="hidden-sm-up">
                  <i class="mdi mdi-account-group"></i>
                </span>
                <span class="hidden-xs-down">Users</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="
                $User.hasPermission('user-view') &&
                  current_user.user_type !== 'company'
              "
            >
              <router-link
                :to="{ name: 'CompaniesView' }"
                active-class="active"
                class="nav-link"
                role="tab"
              >
                <span class="hidden-sm-up">
                  <i class="mdi mdi-domain"></i>
                </span>
                <span class="hidden-xs-down">Companies</span>
              </router-link>
            </li>
          </ul>
          <!-- Tab panes -->
          <!-- /content -->
          <!-- ============================================================== -->
          <!-- Router view -->
          <!-- ============================================================== -->
          <div class="tab-content">
            <div class="tab-pane active" role="tabpanel">
              <div class="m-t-15">
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import { mapGetters } from "vuex";
export default {
  name: "EcosystemContainer",
  computed: {
    ...mapGetters({
      current_user: "getCurrentUser",
      agents: "getAgentsLog"
    })
  },
  components: {
    AppTitle
  }
  // created() {
  //   if (localStorage.getItem("request_deeplink")) {
  //     for ()
  //   }
  // }
};
</script>
