import * as types from "@/store/types";

const mutations = {
  async [types.SET_USERS_LOG](state, payload) {
    state.users_log = payload;
  },
  async [types.SET_USERS_META](state, payload) {
    state.users_meta = payload;
  }
};

export default mutations;
