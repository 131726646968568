<template>
  <div>
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- App Title -->
      <!-- ============================================================== -->
      <app-title title="Coupons" />

      <!-- ============================================================== -->
      <!--  component -->
      <!-- ============================================================== -->
      <generated-coupons-log></generated-coupons-log>
    </div>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import GeneratedCouponsLog from "@/components/coupon/GeneratedCouponsLog";
import store from "@/store";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";

export default {
  components: {
    AppTitle,
    GeneratedCouponsLog
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.GET_GENERATED_COUPONS_ACTION, {
        page: 1
      });
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
