var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clearfix"},[_vm._m(0),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14 font-bold text-capitalize text-nowrap",class:_vm.guarantor_info.status === 'verified'
          ? 'text-success'
          : _vm.guarantor_info.status === 'completed'
          ? 'text-info'
          : _vm.guarantor_info.status === 'not_verified'
          ? 'text-danger'
          : _vm.guarantor_info.status === 'pending'
          ? 'text-warning'
          : _vm.guarantor_info.status === 'incomplete'
          ? 'text-primary'
          : _vm.guarantor_info.status === 'awaiting_qa'
          ? 'text-info'
          : null},[(_vm.guarantor_info.status === 'awaiting_qa')?[_vm._v("Awaiting Quality Assurance Check")]:[_vm._v(" "+_vm._s(_vm._f("remove_underscore")(_vm.guarantor_info.status))+" ")],_c('i',{staticClass:"mdi ml-1",class:_vm.guarantor_info.status === 'verified'
            ? 'mdi-check-circle'
            : _vm.guarantor_info.status === 'completed'
            ? 'mdi-check-circle'
            : _vm.guarantor_info.status === 'not_verified'
            ? 'mdi-alert'
            : _vm.guarantor_info.status === 'pending'
            ? 'mdi-clock'
            : _vm.guarantor_info.status === 'incomplete'
            ? 'mdi-alert-outline'
            : _vm.guarantor_info.status === 'awaiting_qa'
            ? 'mdi-clock'
            : 'mdi-alert'})],2),(_vm.guarantor_info.identity_info.reason)?_c('p',{staticClass:"small"},[_vm._v(" ("+_vm._s(_vm.guarantor_info.identity_info.reason)+") ")]):_vm._e()]),(_vm.guarantor_info.status !== 'verified')?[(_vm.guarantor_info.verification_link)?[_c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Verification Link")]),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.guarantor_info.verification_link),expression:"guarantor_info.verification_link",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"btn ml-2",attrs:{"type":"button"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Copy to clipboard',
              delay: { hide: 500 }
            }),expression:"{\n              content: 'Copy to clipboard',\n              delay: { hide: 500 }\n            }"}],staticClass:"mdi mdi-content-copy text-warning"})])])]:_vm._e(),(_vm.guarantor_info.form_link)?[_c('div',{staticClass:"col-xs-12 my-2"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Guarantor Form Link")]),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.guarantor_info.form_link),expression:"guarantor_info.form_link",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"btn ml-2",attrs:{"type":"button"}},[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Copy to clipboard',
              delay: { hide: 500 }
            }),expression:"{\n              content: 'Copy to clipboard',\n              delay: { hide: 500 }\n            }"}],staticClass:"mdi mdi-content-copy text-warning"})])])]:_vm._e()]:_vm._e(),_vm._m(1),_c('div',{staticClass:"m-b-10"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('ImagePercentage',{attrs:{"photoMeta":_vm.guarantor_info.personal_info.photo_meta}}),_c('div',{staticClass:"ml-2"},[(_vm.$User.hasPermission('agent-edit'))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to upload photo'),expression:"'Click to upload photo'"}],staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate'},on:{"click":function($event){$event.preventDefault();return _vm.selectFile($event)}}},[_c('i',{staticClass:"mdi",class:_vm.loading === 'guarantorUpdate'
                ? 'mdi-loading mdi-spin'
                : 'mdi-pencil'}),_vm._v(" Upload Photo ")]):_vm._e(),_c('input',{ref:"selectFile",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.uploadPhoto}})])],1)]),_vm._m(2),_c('div',{staticClass:"col-xs-12 m-b-10"},[(
        _vm.guarantorUpdateState === 'idle' ||
          _vm.guarantorUpdateState !== 'isNameUpdating'
      )?[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.full_name || "Not available"))]),(
          _vm.guarantorUpdateState === 'idle' && _vm.$User.hasPermission('agent-edit')
        )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to edit full name'),expression:"'Click to edit full name'"}],staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();_vm.guarantorUpdateState = 'isNameUpdating'}}},[_c('i',{staticClass:"mdi mdi-pencil"}),_vm._v(" Edit ")]):_vm._e()]:_vm._e(),(_vm.guarantorUpdateState === 'isNameUpdating')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 mt-2 mt-md-0"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.guarantorName.firstName),expression:"guarantorName.firstName",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.guarantorName.firstName.$error
            },attrs:{"type":"email","name":"guarantorName.firstName","placeholder":"Guarantor First Name"},domProps:{"value":(_vm.guarantorName.firstName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.guarantorName, "firstName", $event.target.value.trim())},_vm.$v.guarantorName.firstName.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-md-4 mt-2 mt-md-0"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.guarantorName.middleName),expression:"guarantorName.middleName",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.guarantorName.middleName.$error
            },attrs:{"type":"email","name":"guarantorName.middleName","placeholder":"Guarantor Middle Name"},domProps:{"value":(_vm.guarantorName.middleName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.guarantorName, "middleName", $event.target.value.trim())},_vm.$v.guarantorName.middleName.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-md-4 mt-2 mt-md-0"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.guarantorName.lastName),expression:"guarantorName.lastName",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.guarantorName.lastName.$error
            },attrs:{"type":"email","name":"guarantorName.lastName","placeholder":"Guarantor Last Name"},domProps:{"value":(_vm.guarantorName.lastName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.guarantorName, "lastName", $event.target.value.trim())},_vm.$v.guarantorName.lastName.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-12 mt-1"},[_c('button',{staticClass:"btn btn-sm btn-outline-success font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate' || _vm.$v.guarantorName.$invalid},on:{"click":function($event){$event.preventDefault();return _vm.updateGuarantorInformation($event)}}},[_c('i',{staticClass:"mdi",class:_vm.loading === 'guarantorUpdate'
                  ? 'mdi-loading mdi-spin'
                  : 'mdi-floppy'}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger ml-1 font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate'},on:{"click":function($event){$event.preventDefault();return _vm.resetForms($event)}}},[_c('i',{staticClass:"mdi mdi-cancel"}),_vm._v(" Cancel ")])])])]:_vm._e()],2),_vm._m(3),_c('div',{staticClass:"col-xs-12 m-b-10"},[(
        _vm.guarantorUpdateState === 'idle' ||
          _vm.guarantorUpdateState !== 'isEmailUpdating'
      )?[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.guarantor_info.personal_info.email || "Not available"))]),(
          _vm.guarantorUpdateState === 'idle' && _vm.$User.hasPermission('agent-edit')
        )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to edit email address'),expression:"'Click to edit email address'"}],staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();_vm.guarantorUpdateState = 'isEmailUpdating'}}},[_c('i',{staticClass:"mdi mdi-pencil"}),_vm._v(" Edit ")]):_vm._e()]:_vm._e(),(_vm.guarantorUpdateState === 'isEmailUpdating')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.guarantorEmail),expression:"guarantorEmail",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.guarantorEmail.$error
            },attrs:{"type":"email","name":"guarantorEmail","placeholder":"Guarantor Email Address","autocomplete":"email"},domProps:{"value":(_vm.guarantorEmail)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.guarantorEmail=$event.target.value.trim()},_vm.$v.guarantorEmail.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-12 mt-1"},[_c('button',{staticClass:"btn btn-sm btn-outline-success font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate' ||
                _vm.$v.guarantorEmail.$invalid ||
                _vm.guarantorEmail === _vm.guarantor_info.personal_info.email},on:{"click":function($event){$event.preventDefault();return _vm.updateGuarantorInformation($event)}}},[_c('i',{staticClass:"mdi",class:_vm.loading === 'guarantorUpdate'
                  ? 'mdi-loading mdi-spin'
                  : 'mdi-floppy'}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger ml-1 font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate'},on:{"click":function($event){$event.preventDefault();return _vm.resetForms($event)}}},[_c('i',{staticClass:"mdi mdi-cancel"}),_vm._v(" Cancel ")])])])]:_vm._e()],2),_vm._m(4),_c('div',{staticClass:"col-xs-12 m-b-10"},[(
        _vm.guarantorUpdateState === 'idle' ||
          _vm.guarantorUpdateState !== 'isMobileUpdating'
      )?[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.guarantor_info.personal_info.mobile || "Not available"))]),(
          _vm.guarantorUpdateState === 'idle' && _vm.$User.hasPermission('agent-edit')
        )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to edit mobile number'),expression:"'Click to edit mobile number'"}],staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();_vm.guarantorUpdateState = 'isMobileUpdating'}}},[_c('i',{staticClass:"mdi mdi-pencil"}),_vm._v(" Edit ")]):_vm._e()]:_vm._e(),(_vm.guarantorUpdateState === 'isMobileUpdating')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.guarantorMobile),expression:"guarantorMobile",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.guarantorMobile.$error
            },attrs:{"type":"text","name":"guarantorMobile","placeholder":"Guarantor Mobile Number","autocomplete":"mobile"},domProps:{"value":(_vm.guarantorMobile)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.guarantorMobile=$event.target.value.trim()},_vm.$v.guarantorMobile.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-12 mt-1"},[_c('button',{staticClass:"btn btn-sm btn-outline-success font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate' ||
                _vm.$v.guarantorMobile.$invalid ||
                _vm.guarantorMobile === _vm.guarantor_info.personal_info.mobile},on:{"click":function($event){$event.preventDefault();return _vm.updateGuarantorInformation($event)}}},[_c('i',{staticClass:"mdi",class:_vm.loading === 'guarantorUpdate'
                  ? 'mdi-loading mdi-spin'
                  : 'mdi-floppy'}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger ml-1 font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate'},on:{"click":function($event){$event.preventDefault();return _vm.resetForms($event)}}},[_c('i',{staticClass:"mdi mdi-cancel"}),_vm._v(" Cancel ")])])])]:_vm._e()],2),_vm._m(5),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.full_name_bvn || "Not available"))])]),_c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v(" "+_vm._s(_vm.guarantor_info.identity_info.id_type === "bvn" ? "Bank Verification Number" : _vm.guarantor_info.identity_info.id_type === "nin" ? "National Identification Number" : _vm.guarantor_info.identity_info.id_type === "passport" ? "International Passport Number" : _vm.guarantor_info.identity_info.id_type === "drivers_license" ? "Driver's License Number" : "Identity Number")+" ")])]),_c('div',{staticClass:"col-xs-12 m-b-10"},[(
        _vm.guarantorUpdateState === 'idle' ||
          _vm.guarantorUpdateState !== 'isIdUpdating'
      )?[_c('span',{staticClass:"text-muted font-14"},[_vm._v(_vm._s(_vm.guarantor_info.identity_info.id_number || "Not available"))]),(
          _vm.guarantorUpdateState === 'idle' &&
            _vm.$User.hasPermission('agent-edit') &&
            _vm.guarantorIdNumber
        )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Click to edit ID number'),expression:"'Click to edit ID number'"}],staticClass:"btn btn-sm btn-outline-secondary m-1 font-bold",on:{"click":function($event){$event.preventDefault();_vm.guarantorUpdateState = 'isIdUpdating'}}},[_c('i',{staticClass:"mdi mdi-pencil"}),_vm._v(" Edit ")]):_vm._e()]:_vm._e(),(_vm.guarantorUpdateState === 'isIdUpdating')?[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.guarantorIdNumber),expression:"guarantorIdNumber",modifiers:{"trim":true}}],staticClass:"form-control rounded-0 input-sm",class:{
              'form-error animated shake': _vm.$v.guarantorIdNumber.$error
            },attrs:{"type":"text","name":"guarantorIdNumber","placeholder":"Guarantor ID Number"},domProps:{"value":(_vm.guarantorIdNumber)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.guarantorIdNumber=$event.target.value.trim()},_vm.$v.guarantorIdNumber.$touch],"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"col-12 mt-1"},[_c('button',{staticClass:"btn btn-sm btn-outline-success font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate' ||
                _vm.$v.guarantorIdNumber.$invalid ||
                _vm.guarantorIdNumber === _vm.guarantor_info.identity_info.id_number},on:{"click":function($event){$event.preventDefault();return _vm.updateGuarantorInformation($event)}}},[_c('i',{staticClass:"mdi",class:_vm.loading === 'guarantorUpdate'
                  ? 'mdi-loading mdi-spin'
                  : 'mdi-floppy'}),_vm._v(" Save ")]),_c('button',{staticClass:"btn btn-sm btn-outline-danger ml-1 font-bold",attrs:{"disabled":_vm.loading === 'guarantorUpdate'},on:{"click":function($event){$event.preventDefault();return _vm.resetForms($event)}}},[_c('i',{staticClass:"mdi mdi-cancel"}),_vm._v(" Cancel ")])])])]:_vm._e()],2),_vm._m(6),_c('div',{staticClass:"col-xs-12 m-b-10"},[_c('span',{staticClass:"text-muted font-14"},[_vm._v(" "+_vm._s(_vm.guarantor_info.submissions.length === 0 ? "Not yet submitted" : _vm.guarantor_info.submissions.length === 1 ? "Submitted only once" : ("Submitted " + (_vm.guarantor_info.submissions.length) + " times"))+" "),_c('ul',{staticClass:"mt-2 list-style-none"},_vm._l((_vm.guarantor_info.submissions),function(submission){return _c('li',{key:submission,staticClass:"font-12"},[_c('i',{staticClass:"mdi mdi-clock-in"}),_vm._v(" "+_vm._s(_vm._f("long_date")(submission))+" ")])}),0)])]),_c('div',{staticClass:"col-xs-12 m-b-10"},[(_vm.guarantor_info.status === 'pending')?_c('button',{staticClass:"btn btn-warning",attrs:{"disabled":_vm.loading === 'reminder'},on:{"click":function($event){$event.preventDefault();return _vm.resendInvite($event)}}},[_vm._v(" Send Reminder "),(_vm.loading === 'reminder')?_c('i',{staticClass:"ml-1 mdi mdi-loading mdi-spin"}):_vm._e()]):_vm._e(),(
        _vm.guarantor_info.identity_info.can_retry &&
          _vm.$User.hasPermission('agent-edit')
      )?_c('button',{staticClass:"ml-2 btn btn-warning",attrs:{"disabled":_vm.loading === 'retry'},on:{"click":function($event){$event.preventDefault();return _vm.retryGuarantorVerification($event)}}},[_vm._v(" Retry Guarantor Verification "),(_vm.loading === 'retry')?_c('i',{staticClass:"ml-1 mdi mdi-loading mdi-spin"}):_vm._e()]):_vm._e()])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Identity Status")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Facial Match")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Guarantor's Full Name")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Guarantor's Email Address")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Guarantor's Mobile")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Name on Identity")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12"},[_c('span',{staticClass:"font-bold font-14"},[_vm._v("Submissions")])])}]

export { render, staticRenderFns }