<template>
  <div class="row">
    <!-- Column -->
    <div class="col-md-6 col-lg-6 col-xlg-6">
      <div class="card">
        <div class="card-block text-center">
          <div class="box d-flex justify-content-center">
            <template v-if="!isError">
              <div v-if="!isLoading">
                <h1 class="font-bold">
                  <!-- <sup class="font-light">₦</sup> -->
                  <i-count-up
                    :startVal="0"
                    :endVal="agent_analytics.verified"
                    :decimals="0"
                    :duration="1.5"
                    :options="options"
                    :callback="noop"
                  ></i-count-up>
                </h1>
              </div>
              <i v-if="isLoading" class="mdi mdi-loading mdi-spin"></i>
            </template>
          </div>
          <template v-if="isError">
            <button
              @click.prevent="getAccountBalance"
              class="m-2 btn btn-warning"
              :disabled="isLoading"
            >
              Retry
              <i class="ml-1 mdi mdi-refresh"></i>
            </button>
          </template>
          <h6 class="text-uppercase">Verified Agents</h6>
        </div>
      </div>
    </div>
    <!-- Column -->
    <div class="col-md-6 col-lg-6 col-xlg-6">
      <div class="card">
        <div class="card-block p-1 text-center">
          <div class="box p-0 d-flex justify-content-center">
            <template v-if="!isError">
              <div v-if="!isLoading">
                <h1 class="font-bold">
                  <sup class="font-light">₦</sup>
                  <i-count-up
                    :startVal="0"
                    :endVal="
                      business_transaction_total.total_amount_approved / 100
                    "
                    :decimals="0"
                    :duration="1.5"
                    :options="options"
                    :callback="noop"
                  ></i-count-up>
                </h1>
                <h6 class="text-uppercase">Total Revenue Collected</h6>
              </div>
              <i v-if="isLoading" class="mdi mdi-loading mdi-spin"></i>
            </template>
          </div>
          <div class="box p-0 d-flex justify-content-center">
            <div class="border-box mr-3">
              <template v-if="!isError">
                <div v-if="!isLoading">
                  <h1 class="amount-style">
                    <sup class="font-light">₦</sup>
                    <i-count-up
                      :startVal="0"
                      :endVal="business_transaction_total.total_amount / 100"
                      :decimals="0"
                      :duration="1.5"
                      :options="options"
                      :callback="noop"
                    ></i-count-up>
                  </h1>
                  <h6 class="text-uppercase">Coupon and Non-Coupon Value</h6>
                </div>

                <i v-if="isLoading" class="mdi mdi-loading mdi-spin"></i>
              </template>
            </div>
            <div class="border-box">
              <template v-if="!isError">
                <div v-if="!isLoading">
                  <h1 class="amount-style">
                    <sup class="font-light">₦</sup>
                    <i-count-up
                      :startVal="0"
                      :endVal="business_transaction_total.coupon_discount / 100"
                      :decimals="0"
                      :duration="1.5"
                      :options="options"
                      :callback="noop"
                    ></i-count-up>
                  </h1>
                  <h6 class="text-uppercase">Total Coupon Value</h6>
                </div>
                <i v-if="isLoading" class="mdi mdi-loading mdi-spin"></i>
              </template>
            </div>
          </div>
          <template v-if="isError">
            <button
              @click.prevent="getAccountBalance"
              class="m-2 btn btn-warning"
              :disabled="isLoading"
            >
              Retry
              <i class="ml-1 mdi mdi-refresh"></i>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  components: {
    ICountUp
  },
  data() {
    return {
      isLoading: true,
      isError: false,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: ""
      }
    };
  },
  async mounted() {
    this.getAccountBalance();
  },
  computed: {
    ...mapGetters({
      agent_analytics: "getAgentAnalytics",
      business_transaction_total: "getBusinessTransactionTotal"
    })
  },
  methods: {
    noop() {},
    async getAccountBalance() {
      try {
        this.isLoading = true;
        this.isError = false;
        await Promise.all([
          this.$store.dispatch(types.GET_AGENT_ANALYTICS_ACTION),
          this.$store.dispatch(types.GET_BUSINESS_TRANSACTION_TOTAL_ACTION)
        ]);
      } catch (error) {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style scoped>
.border-box {
  border: 2px solid #f1f1f1 !important;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  display: flex;
  justify-content: center;
  min-width: 240px !important;
}
.amount-style {
  line-height: 30px;
  font-size: 24px;
}
</style>
