<template>
  <div class="container position-relative w-100">
    <h3 class="card-title">Download Your Data</h3>
    <p class="font-14" v-if="agent_profile.email.value">
      You can request a file with the information that we believe is most
      relevant and useful to you. You'll get a notification and an email sent to
      {{ agent_profile.email.value }} with a link when it's ready to be
      downloaded.
    </p>
    <p class="font-14" v-if="!agent_profile.email.value">
      You can request a file with the information that we believe is most
      relevant and useful to you. A download link will be active immediately
      your data is ready. Please, You can check back periodically to check the
      status and download your data.
    </p>
    <div
      v-if="requestResponse"
      class="alert font-14 rounded-0"
      :class="
        requestResponse.type === 'success'
          ? 'alert-success-light'
          : 'alert-danger-light'
      "
    >
      {{ requestResponse.message }}
    </div>

    <form class="row" @submit.prevent="requestData">
      <template v-if="showRequestForm">
        <div class="col-md-12">
          <div class="alert alert-success-light font-14 rounded-0">
            Please enter your Email Address and Phone Number to continue
          </div>
        </div>
        <div class="form-group col-md-6">
          <label class="font-bold form-label" for="req_email">
            Email Address
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="Email Address"
            v-model="$v.requestForm.email.$model"
            class="form-control rounded-0"
            :class="{
              'form-error animated shake': $v.requestForm.email.$error
            }"
            id="req_email"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="font-bold form-label" for="req_mobile">
            Phone Number
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="Phone Number"
            v-model="$v.requestForm.mobile.$model"
            class="form-control rounded-0"
            :class="{
              'form-error animated shake': $v.requestForm.mobile.$error
            }"
            id="req_mobile"
          />
        </div>
      </template>
      <div class="form-group col-md-12">
        <template v-if="!agent_profile.account_info.download_available">
          <span
            v-tooltip="
              agent_profile.account_info.download_requested
                ? 'Request for download has already been sent'
                : ''
            "
          >
            <button
              type="submit"
              class="btn btn-warning"
              :disabled="
                agent_profile.account_info.download_requested ||
                  requestForm.isLoading
              "
            >
              <i class="mdi mdi-download mr-1"></i>
              {{
                agent_profile.account_info.download_requested
                  ? "Request Sent"
                  : "Request Your Data"
              }}
              <i
                v-if="requestForm.isLoading"
                class="mdi mdi-loading mdi-spin"
              ></i>
            </button>
          </span>
          <br />
          <small
            class="font-12"
            v-if="agent_profile.account_info.download_requested"
            >Request sent at
            {{
              agent_profile.account_info.download_requested_at | long_date
            }}</small
          >
        </template>
        <template v-if="agent_profile.account_info.download_available">
          <button
            type="button"
            class="btn btn-warning"
            @click.prevent="downloadData"
            :disabled="downloadForm.isLoading"
          >
            <i class="mdi mdi-download mr-1"></i>
            Download Your Data
            <i
              v-if="downloadForm.isLoading"
              class="mdi mdi-loading mdi-spin"
            ></i>
          </button>
          <br />
          <small class="font-12"
            >Expires at
            {{ agent_profile.account_info.download_expiry | long_date }}</small
          >
        </template>
      </div>
    </form>
    <hr />
    <h3 class="card-title">Delete Your Data</h3>
    <p class="font-14 text-danger font-bold">Deleting your data will:</p>
    <ul class="font-14">
      <li>Delete all your personal information from the portal.</li>
      <li>Delete all your application reports from the portal.</li>
      <li>Erase your transaction history.</li>
    </ul>
    <p class="font-14 font-bold">Note:</p>
    <ul class="font-14">
      <li>
        Once your data has been erased, it cannot be restored or recovered.
      </li>
      <li>
        If you want to download your data, you will need to complete both the
        request and download process before deleting your data.
      </li>
    </ul>

    <div
      v-if="deleteResponse"
      class="alert font-14 rounded-0"
      :class="
        deleteResponse.type === 'success'
          ? 'alert-success-light'
          : 'alert-danger-light'
      "
    >
      {{ deleteResponse.message }}
    </div>

    <form class="row" @submit.prevent="deleteData">
      <template v-if="showDeleteForm">
        <div class="col-md-12">
          <div class="alert alert-success-light font-14 rounded-0">
            Please enter your Email Address and Phone Number to continue
          </div>
        </div>
        <div class="form-group col-md-6">
          <label class="font-bold form-label" for="del_email">
            Email Address
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="Email Address"
            v-model="$v.deleteForm.email.$model"
            class="form-control rounded-0"
            :class="{
              'form-error animated shake': $v.deleteForm.email.$error
            }"
            id="del_email"
          />
        </div>
        <div class="form-group col-md-6">
          <label class="font-bold form-label" for="del_mobile">
            Phone Number
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            placeholder="Phone Number"
            v-model="$v.deleteForm.mobile.$model"
            class="form-control rounded-0"
            :class="{
              'form-error animated shake': $v.deleteForm.mobile.$error
            }"
            id="del_mobile"
          />
        </div>
      </template>

      <div class="form-group col-md-12">
        <button
          type="submit"
          class="btn btn-danger"
          :disabled="deleteForm.isLoading"
        >
          <i class="mdi mdi-delete-sweep mr-1"></i>
          Delete Your Data
          <i v-if="deleteForm.isLoading" class="mdi mdi-loading mdi-spin"></i>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {
  required,
  numeric,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  name: "AgentVerificationSettings",
  data() {
    return {
      showRequestForm: false,
      showDeleteForm: false,
      requestForm: {
        email: "",
        mobile: "",
        isLoading: false
      },
      downloadForm: {
        isLoading: false
      },
      deleteForm: {
        email: "",
        mobile: "",
        isLoading: false
      },
      requestResponse: null,
      deleteResponse: null
    };
  },
  validations: {
    requestForm: {
      email: {
        required,
        email,
        sameAsEmail(email) {
          return email === this.agent_profile.email.value;
        }
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
        sameAsMobile(mobile) {
          return mobile === this.agent_profile.mobile.value;
        }
      }
    },
    deleteForm: {
      email: {
        required,
        email,
        sameAsEmail(email) {
          return email === this.agent_profile.email.value;
        }
      },
      mobile: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
        sameAsMobile(mobile) {
          return mobile === this.agent_profile.mobile.value;
        }
      }
    }
  },
  methods: {
    async requestData() {
      //show the form if not visible
      if (!this.showRequestForm) {
        this.showRequestForm = true;
        this.requestResponse = null;
        return;
      }

      //validate the form
      this.$v.requestForm.$touch();
      if (this.$v.requestForm.$anyError) return;

      //send request to prepare agent data
      try {
        this.requestResponse = null;
        this.requestForm.isLoading = true;
        let response = await this.$store.dispatch(
          types.REQUEST_AGENT_DATA_ACTION,
          {
            email: this.requestForm.email,
            mobile: this.requestForm.mobile
          }
        );
        this.requestForm.email = "";
        this.requestForm.mobile = "";
        this.$v.$reset();
        this.showRequestForm = false;
        this.requestResponse = {
          message: response.message,
          type: "success"
        };

        // await this.$store.dispatch(types.AGENT_PROFILE_ACTION, {
        //   email: this.agent_profile.email.value,
        //   mobile: this.agent_profile.mobile.value
        // });
      } catch (error) {
        if (error && error.data) {
          this.requestResponse = {
            message: error.data.message,
            type: "error"
          };
        }
      } finally {
        this.requestForm.isLoading = false;
      }
    },
    async downloadData() {
      //send request to prepare agent data
      try {
        this.requestResponse = null;
        this.downloadForm.isLoading = true;
        let response = await this.$store.dispatch(
          types.DOWNLOAD_AGENT_DATA_ACTION,
          {
            email: this.agent_profile.email.value,
            mobile: this.agent_profile.mobile.value
          }
        );
        window.location.replace(response.download_url);
      } catch (error) {
        if (error && error.data) {
          this.requestResponse = {
            message: error.data.message,
            type: "error"
          };
        }
      } finally {
        this.downloadForm.isLoading = false;
      }
    },
    async deleteData() {
      //show the form if not visible
      if (!this.showDeleteForm) {
        this.showDeleteForm = true;
        return;
      }

      //validate the form
      this.$v.deleteForm.$touch();
      if (this.$v.deleteForm.$anyError) return;

      //send request to delete agent data
      try {
        this.deleteResponse = null;
        this.deleteForm.isLoading = true;

        let response = await this.$store.dispatch(
          types.DELETE_AGENT_DATA_ACTION,
          {
            email: this.deleteForm.email,
            mobile: this.deleteForm.mobile
          }
        );

        // notify with a success message
        this.$notify.success({
          message: response.message
        });

        this.$router.push({
          name: "HomeView"
        });
      } catch (error) {
        if (error && error.data) {
          this.deleteResponse = {
            message: error.data.message,
            type: "error"
          };
        }
      } finally {
        this.deleteForm.isLoading = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      agent_profile: "getCheckAgentProfile"
    })
  }
};
</script>
