<template>
  <div>
    <new-permit-modal />
    <set-permit-fee-modal />
    <permits-log />
  </div>
</template>

<script>
import PermitsLog from "./_components/PermitsLog";
import NewPermitModal from "./_components/NewPermitModal";
import * as types from "@/store/types";
import store from "@/store";
import notify from "@/helpers/notifyMe";
import SetPermitFeeModal from "./_components/SetPermitFeeModal";

export default {
  components: { SetPermitFeeModal, PermitsLog, NewPermitModal },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status
        // start_date = moment(store.getters.getBusinessProfile.created_at).format(
        //   "YYYY-MM-DD"
        // ),
        // end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_PERMITS_ACTION, {
          query: search_term,
          //paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_PERMITS_ACTION, {
          page,
          ...(status && { status })
          // start_date,
          // end_date,
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status
        // start_date = moment(store.getters.getBusinessProfile.created_at).format(
        //   "YYYY-MM-DD"
        // ),
        // end_date = moment().format("YYYY-MM-DD")
      } = to.query;
      if (search_term) {
        await store.dispatch(types.SEARCH_PERMITS_ACTION, {
          query: search_term,
          //paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_PERMITS_ACTION, {
          page,
          ...(status && { status })
          // start_date,
          // end_date,
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  beforeDestroy() {
    $("#add-permit-modal").modal("hide");
    $("#add-permit-modal").on("hidden.bs.modal", function() {
      $("#add-permit-modal").modal("dispose");
    });

    $("#set-permit-fee-modal").modal("hide");
    $("#set-permit-fee-modal").on("hidden.bs.modal", function() {
      $("#set-permit-fee-modal").modal("dispose");
    });
  }
};
</script>

<style scoped></style>
