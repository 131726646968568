const getters = {
  getAddressVerificationsLog: state => {
    return state.address_verifications_log;
  },
  getAddressVerificationsLogPagination: state => {
    return (
      state.address_verifications_meta &&
      state.address_verifications_meta.pagination
    );
  },
  getAddressVerificationsLogMeta: state => {
    return state.address_verifications_meta;
  }
};

export default getters;
