// auth
export const LOGIN_USER_ACTION = "LOGIN_USER_ACTION";
export const SEND_PASSWORD_RESET_LINK_ACTION =
  "SEND_PASSWORD_RESET_LINK_ACTION";
export const RESET_USER_PASSWORD_ACTION = "RESET_USER_PASSWORD_ACTION";
export const VERIFY_RESET_PASSWORD_TOKEN_ACTION =
  "VERIFY_RESET_PASSWORD_TOKEN_ACTION";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_INTENDED_TO = "SET_INTENDED_TO";
export const REGISTER_USER_ACTION = "REGISTER_USER_ACTION";
export const VERIFY_USER_REGISTER_TOKEN = "VERIFY_USER_REGISTER_TOKEN";
export const LOOKUP_EMAIL_ACTION = "LOOKUP_EMAIL_ACTION";
export const SET_AUTH_DATA = "SET_AUTH_DATA";

//agent profile
export const RESOLVE_AGENT_INVITATION = "RESOLVE_AGENT_INVITATION";
export const DISSOCIATE_FROM_OPERATOR = "DISSOCIATE_FROM_OPERATOR";
export const DISSOCIATE_AGENT_FROM_OPERATOR = "DISSOCIATE_AGENT_FROM_OPERATOR";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const ADD_NEW_AGENT_ADDRESS = "ADD_NEW_AGENT_ADDRESS";
export const DELETE_AGENT_ADDRESS = "DELETE_AGENT_ADDRESS";
export const INITIATE_PERMIT_RENEWAL = "INITIATE_PERMIT_RENEWAL";
export const VERIFY_PERMIT_RENEWAL = "VERIFY_PERMIT_RENEWAL";

//profile
export const GET_PROFILE_ACTION = "GET_PROFILE_ACTION";
export const SET_PROFILE = "SET_PROFILE";
export const UPDATE_USER_PROFILE_ACTION = "UPDATE_USER_PROFILE_ACTION";
export const CHANGE_USER_PASSWORD_ACTION = "CHANGE_USER_PASSWORD_ACTION";
export const SET_BUSINESS_PROFILE = "SET_BUSINESS_PROFILE";
export const UPDATE_BUSINESS_PROFILE_ACTION = "UPDATE_BUSINESS_PROFILE_ACTION";
export const GET_SUPER_AGENTS_SETTINGS = "GET_SUPER_AGENTS_SETTINGS";
export const UPDATE_SUPER_AGENTS_SETTINGS = "UPDATE_SUPER_AGENTS_SETTINGS";
export const GET_SUPER_AGENT_SETTINGS = "GET_SUPER_AGENT_SETTINGS";

//transactions
export const GET_TRANSACTIONS_ACTION = "GET_TRANSACTIONS_ACTION";
export const SET_TRANSACTIONS_LOG = "SET_TRANSACTIONS_LOG";
export const SET_TRANSACTIONS_META = "SET_TRANSACTIONS_META";
export const GET_TRANSACTION_ACTION = "GET_TRANSACTION_ACTION";
export const SET_TRANSACTION = "SET_TRANSACTION";
export const RECONCILE_TRANSACTION_ACTION = "RECONCILE_TRANSACTION_ACTION";
export const EXPORT_TRANSACTIONS_ACTION = "EXPORT_TRANSACTIONS_ACTION";
export const SEARCH_TRANSACTIONS_ACTION = "SEARCH_TRANSACTIONS_ACTION";

//verifications
export const GET_VERIFICATIONS_ACTION = "GET_VERIFICATIONS_ACTION";
export const SET_VERIFICATIONS_LOG = "SET_VERIFICATIONS_LOG";
export const SET_VERIFICATIONS_META = "SET_VERIFICATIONS_META";

//verifications
export const GET_ADDRESS_VERIFICATIONS_ACTION =
  "GET_ADDRESS_VERIFICATIONS_ACTION";
export const SET_ADDRESS_VERIFICATIONS_LOG = "SET_ADDRESS_VERIFICATIONS_LOG";
export const SET_ADDRESS_VERIFICATIONS_META = "SET_ADDRESS_VERIFICATIONS_META";
export const EXPORT_ADDRESS_VERIFICATIONS_ACTION =
  "EXPORT_ADDRESS_VERIFICATIONS_ACTION";
export const SEARCH_ADDRESS_VERIFICATIONS_ACTION =
  "SEARCH_ADDRESS_VERIFICATIONS_ACTION";

//verification
export const GET_VERIFICATION_REPORT_ACTION = "GET_VERIFICATION_REPORT_ACTION";
export const SET_VERIFICATION_REPORT = "SET_VERIFICATION_REPORT";
export const EXPORT_VERIFICATIONS_ACTION = "EXPORT_VERIFICATIONS_ACTION";
export const GET_REPORT_DOWNLOAD_URL = "GET_REPORT_DOWNLOAD_URL";
export const RESEND_GUARANTOR_INVITE = "RESEND_GUARANTOR_INVITE";
export const RETRY_GUARANTOR_VERIFICATION = "RETRY_GUARANTOR_VERIFICATION";

export const GET_VERIFICATION_PRICE = "GET_VERIFICATION_PRICE";
export const SET_VERIFICATION_PRICE = "SET_VERIFICATION_PRICE";
export const GET_VERIFICATION_REGIONS = "SET_VERIFICATION_REGIONS";
export const SET_VERIFICATION_REGIONS = "SET_VERIFICATION_REGIONS";
export const SET_REQUEST_VERIFICATION_PARTIAL =
  "SET_REQUEST_VERIFICATION_PARTIAL";
export const SET_INFRACTION_PHOTOS = "SET_INFRACTION_PHOTOS";

//agent
export const SET_AGENT_PROFILE = "SET_AGENT_PROFILE";
export const SET_AGENT_VERIFICATIONS = "SET_AGENT_VERIFICATIONS";
export const GET_AGENT_PROFILE_ACTION = "GET_AGENT_PROFILE_ACTION";

export const GET_AGENTS_ACTION = "GET_AGENTS_ACTION";
export const SET_AGENTS_LOG = "SET_AGENTS_LOG";
export const SET_AGENTS_META = "SET_AGENTS_META";

export const GET_VERIFIED_AGENTS_ACTION = "GET_VERIFIED_AGENTS_ACTION";
export const SET_VERIFIED_AGENTS_LOG = "SET_VERIFIED_AGENTS_LOG";
export const SET_VERIFIED_AGENTS_META = "SET_VERIFIED_AGENTS_META";
export const SEARCH_VERIFIED_AGENTS_ACTION = "SEARCH_VERIFIED_AGENTS_ACTION";

export const SEARCH_AGENTS_ACTION = "SEARCH_AGENTS_ACTION";
export const EXPORT_AGENTS_ACTION = "EXPORT_AGENTS_ACTION";
export const ADD_AGENT_COMMENT = "ADD_AGENT_COMMENT";
export const REMOVE_AGENT_COMMENT = "REMOVE_AGENT_COMMENT";
export const SET_AGENT_COMMENTS = "SET_AGENT_COMMENTS";

export let RESEND_AGENT_INVITE = "RESEND_AGENT_INVITE";

export const UPDATE_AGENT_EMAIL_ADDRESS = "UPDATE_AGENT_EMAIL_ADDRESS";
export const UPDATE_AGENT_DETAILS = "UPDATE_AGENT_DETAILS";
export const UPDATE_AGENT_LSLB_LICENSE_NUMBER =
  "UPDATE_AGENT_LSLB_LICENSE_NUMBER";
export const INVITE_NEW_AGENT_ACTION = "INVITE_NEW_AGENT_ACTION";
export const REQUEST_NEW_AGENT_ACTION = "REQUEST_NEW_AGENT_ACTION";
export const REQUEST_BULK_NEW_AGENT_ACTION = "REQUEST_BULK_NEW_AGENT_ACTION";
export const SEARCH_AGENT_BY_PERMIT_NUMBER = "SEARCH_AGENT_BY_PERMIT_NUMBER";

export const DOWNLOAD_SAMPLE_BULK_UPLOAD_CSV =
  "DOWNLOAD_SAMPLE_BULK_UPLOAD_CSV";
export const SUBMIT_BULK_UPLOAD_CSV = "SUBMIT_BULK_UPLOAD_CSV";
export const SUBMIT_BULK_RENEW_CSV = "SUBMIT_BULK_RENEW_CSV";
export const DOWNLOAD_SAMPLE_BULK_RENEW_CSV = "DOWNLOAD_SAMPLE_BULK_RENEW_CSV";
export const SINGLE_PERMIT_RENEW = "SINGLE_PERMIT_RENEW";
export const SINGLE_AGENT_ONBOARD = "SINGLE_AGENT_ONBOARD";
export const UPLOAD_FILE = "UPLOAD_FILE";

//permits
export const SEARCH_PERMITS_ACTION = "SEARCH_PERMITS_ACTION";
export const GET_PERMITS_ACTION = "GET_PERMITS_ACTION";
export const SET_PERMITS_LOG = "SET_PERMITS_LOG";
export const SET_PERMITS_META = "SET_PERMITS_META";
export const ADD_NEW_PERMIT_ACTION = "ADD_NEW_PERMIT_ACTION";
export const SET_PERMIT_FEE_ACTION = "SET_PERMIT_FEE_ACTION";

//coupon
export const GET_COUPON_QUOTATION_ACTION = "GET_COUPON_QUOTATION_ACTION";
export const GET_COUPON_AGENTS_ACTION = "GET_COUPON_AGENTS_ACTION";
export const GET_GENERATED_COUPONS_ACTION = "GET_GENERATED_COUPONS_ACTION";
export const SET_GENERATED_COUPONS_LOG = "SET_GENERATED_COUPONS_LOG";
export const SET_GENERATED_COUPONS_META = "SET_GENERATED_COUPONS_META";
export const REQUEST_COUPON_ACTION = "REQUEST_COUPON_ACTION";
export const INITIATE_COUPON_PAYMENT_ACTION = "INITIATE_COUPON_PAYMENT_ACTION";
export const SET_REQUESTED_COUPON = "SET_REQUESTED_COUPON";
export const SET_QUOTATION = "SET_QUOTATION";
export const SET_COUPON_DATA = "SET_COUPON_DATA";
export const DOWNLOAD_COUPON_CODES_ACTION = "DOWNLOAD_COUPON_CODES_ACTION";
export const VERIFY_COUPON_CODE_ACTION = "VERIFY_COUPON_CODE_ACTION";
export const SEARCH_COUPON_BY_REFERENCE = "SEARCH_COUPON_BY_REFERENCE";

//dashboard
export const GET_AGENT_ANALYTICS_ACTION = "GET_AGENT_ANALYTICS_ACTION";
export const SET_AGENT_ANALYTICS = "SET_AGENT_ANALYTICS";
export const GET_DASHBOARD_METRICS_ACTION = "GET_DASHBOARD_METRICS_ACTION";
export const SET_DASHBOARD_METRICS = "SET_DASHBOARD_METRICS";
export const GET_VERIFICATIONS_BY_CITIES_ACTION =
  "GET_VERIFICATIONS_BY_CITIES_ACTION";
export const SET_VERIFICATIONS_BY_CITIES = "SET_VERIFICATIONS_BY_CITIES";
export const GET_BUSINESS_ACCOUNT_BALANCE_ACTION =
  "GET_BUSINESS_ACCOUNT_BALANCE_ACTION";
export const SET_BUSINESS_ACCOUNT_BALANCE = "SET_BUSINESS_ACCOUNT_BALANCE";
export const GET_BUSINESS_TRANSACTION_TOTAL_ACTION =
  "GET_BUSINESS_TRANSACTION_TOTAL_ACTION";
export const SET_BUSINESS_TRANSACTION_TOTAL = "SET_BUSINESS_TRANSACTION_TOTAL";
export const SET_VERIFICATIONS_METRICS = "SET_VERIFICATIONS_METRICS";
export const GET_VERIFICATIONS_METRICS_ACTION =
  "GET_VERIFICATIONS_METRICS_ACTION";

export const SET_IDENTITY_METRICS = "SET_IDENTITY_METRICS";
export const GET_IDENTITY_METRICS_ACTION = "GET_IDENTITY_METRICS_ACTION";

// loader
export const SET_PAGE_READY = "SET_PAGE_READY";

// request verification
export const GET_REQUEST_VERIFICATION_ACTION =
  "GET_REQUEST_VERIFICATION_ACTION";
export const REQUEST_VERIFICATION_ACTION = "REQUEST_VERIFICATION_ACTION";
export const SET_REQUEST_VERIFICATION = "SET_REQUEST_VERIFICATION";
export const VERIFY_EMAIL_ADDRESS_ACTION = "VERIFY_EMAIL_ADDRESS_ACTION";
export const RETRY_AGENT_VERIFICATION = "RETRY_AGENT_VERIFICATION";

//personal information
export const UPDATE_PERSONAL_INFORMATION_ACTION =
  "UPDATE_PERSONAL_INFORMATION_ACTION";
export const UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION =
  "UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION";

export const UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION_WITH_DETECTION =
  "UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION_WITH_DETECTION";

//identity information
export const UPDATE_IDENTITY_INFO_ACTION = "UPDATE_IDENTITY_INFO_ACTION";

//license information
export const UPDATE_LICENSE_INFO_ACTION = "UPDATE_LICENSE_INFO_ACTION";

//address information
export const UPDATE_AGENT_ADDRESS_ACTION = "UPDATE_AGENT_ADDRESS_ACTION";
export const UPDATE_AGENT_RESIDENTIAL_ADDRESS_ACTION =
  "UPDATE_AGENT_RESIDENTIAL_ADDRESS_ACTION";

//guarantor information
export const UPDATE_GUARANTOR_INFORMATION_ACTION =
  "UPDATE_GUARANTOR_INFORMATION_ACTION";
export const UPDATE_GUARANTOR_INFORMATION_ACTION_ADMIN =
  "UPDATE_GUARANTOR_INFORMATION_ACTION_ADMIN";

export const UPDATE_AGENT_INFORMATION_ACTION_ADMIN =
  "UPDATE_AGENT_INFORMATION_ACTION_ADMIN";

export const UPDATE_AND_SUBMIT_GUARANTOR_INFORMATION_ACTION =
  "UPDATE_AND_SUBMIT_GUARANTOR_INFORMATION_ACTION";
export const CONFIRM_GUARANTOR_CONSENT_ACTION =
  "CONFIRM_GUARANTOR_CONSENT_ACTION";
export const SET_GUARANTOR_DETAILS = "SET_GUARANTOR_DETAILS";
export const GET_GUARANTOR_DETAILS = "GET_GUARANTOR_DETAILS";
export const SET_GUARANTOR_PAYLOAD = "SET_GUARANTOR_PAYLOAD";
export const SUBMIT_GUARANTOR_DETAILS = "SUBMIT_GUARANTOR_DETAILS";
export const UPDATE_GUARANTOR_PERSONAL_INFO = "UPDATE_GUARANTOR_PERSONAL_INFO";
export const UPDATE_GUARANTOR_IDENTITY_INFO = "UPDATE_GUARANTOR_IDENTITY_INFO";

//check verification
export const AGENT_PROFILE_ACTION = "AGENT_PROFILE_ACTION";
export const SET_AGENT_PROFILE_AGENT = "SET_AGENT_PROFILE_AGENT";
export const GET_AGENT_PROFILE_REPORT_ACTION =
  "GET_AGENT_PROFILE_REPORT_ACTION";
export const SET_AGENT_PROFILE_REPORT = "SET_AGENT_PROFILE_REPORT";
export const REQUEST_AGENT_PROFILE_OTP_ACTION =
  "REQUEST_AGENT_PROFILE_OTP_ACTION";

export const REQUEST_AGENT_DATA_ACTION = "REQUEST_AGENT_DATA_ACTION";
export const DOWNLOAD_AGENT_DATA_ACTION = "DOWNLOAD_AGENT_DATA_ACTION";
export const DELETE_AGENT_DATA_ACTION = "DELETE_AGENT_DATA_ACTION";

//request verification payment
export const INITIATE_VERIFICATION_PAYMENT_ACTION =
  "INITIATE_VERIFICATION_PAYMENT_ACTION";
//verify verification payment
export const VERIFY_VERIFICATION_PAYMENT_ACTION =
  "VERIFY_VERIFICATION_PAYMENT_ACTION";
export const SUBMIT_REQUEST_VERIFICATION_FORM_ACTION =
  "SUBMIT_REQUEST_VERIFICATION_FORM_ACTION";
export const REQUEST_ADDRESS_VERIFICATION_ACTION =
  "REQUEST_ADDRESS_VERIFICATION_ACTION";

//request verification transactions
export const GET_REQUEST_VERIFICATION_TRANSACTIONS_ACTION =
  "GET_REQUEST_VERIFICATION_TRANSACTIONS_ACTION";
export const SET_REQUEST_VERIFICATION_TRANSACTION_LOG =
  "SET_REQUEST_VERIFICATION_TRANSACTION_LOG";

//users
export const GET_ALL_ROLES_ACTION = "GET_ALL_ROLES_ACTION";
export const SET_ALL_ROLES = "SET_ALL_ROLES";
export const INVITE_NEW_USER_ACTION = "INVITE_NEW_USER_ACTION";
export const CREATE_NEW_CUSTOM_ROLE_ACTION = "CREATE_NEW_CUSTOM_ROLE_ACTION";
export const UPDATE_CUSTOM_ROLE_ACTION = "UPDATE_CUSTOM_ROLE_ACTION";
export const DELETE_CUSTOM_ROLE_ACTION = "DELETE_CUSTOM_ROLE_ACTION";
export const GET_ROLE_WITH_USERS_ACTION = "GET_ROLE_WITH_USERS_ACTION";
export const SET_SELECTED_ROLE = "SET_SELECTED_ROLE";
export const GET_ALL_USERS_ACTION = "GET_ALL_USERS_ACTION";
export const SET_USERS_LOG = "SET_USERS_LOG";
export const SET_USERS_META = "SET_USERS_META";
export const SEARCH_USERS_ACTION = "SEARCH_USERS_ACTION";
export const REINVITE_USER_ACTION = "REINVITE_USER_ACTION";
export const ACTIVATE_USER_ACTION = "ACTIVATE_USER_ACTION";
export const CHANGE_USER_ROLE_ACTION = "CHANGE_USER_ROLE_ACTION";
export const DEACTIVATE_USER_ACTION = "DEACTIVATE_USER_ACTION";
export const ADD_MULTIPLE_USERS_ACTION = "ADD_MULTIPLE_USERS_ACTION";

//companies
export const INVITE_NEW_COMPANY_ACTION = "INVITE_NEW_COMPANY_ACTION";
export const GET_ALL_COMPANIES_ACTION = "GET_ALL_COMPANIES_ACTION";
export const SET_COMPANIES_LOG = "SET_COMPANIES_LOG";
export const SET_COMPANIES_META = "SET_COMPANIES_META";
export const SET_BUSINESS_LICENSE_CATEGORIES =
  "SET_BUSINESS_LICENSE_CATEGORIES";

//generate links
export const GENERATE_LINKS_ACTION = "GENERATE_LINKS_ACTION";
export const UPLOAD_CSV = "UPLOAD_CSV";
export const GET_GENERATED_LINKS_ACTION = "GET_GENERATED_LINKS_ACTION";
export const SET_GENERATED_LINKS_LOG = "SET_GENERATED_LINKS_LOG";
export const SET_GENERATED_LINKS_META = "SET_GENERATED_LINKS_META";
export const EXPORT_GENERATED_COUPONS_ACTION =
  "EXPORT_GENERATED_COUPONS_ACTION";
export const DOWNLOAD_GENERATED_LINKS_ACTION =
  "DOWNLOAD_GENERATED_LINKS_ACTION";

//contact us
export const SUBMIT_CONTACT_FORM_ACTION = "SUBMIT_CONTACT_FORM_ACTION";

//audit-log
export const GET_AUDIT_LOG_EVENTS_ACTION = "GET_AUDIT_LOG_EVENTS_ACTION";
export const SET_AUDIT_LOG_EVENTS = "SET_AUDIT_LOG_EVENTS";
export const GET_AUDIT_LOG_ACTION = "GET_AUDIT_LOG_ACTION";
export const SET_AUDIT_LOG = "SET_AUDIT_LOG";
export const SET_AUDIT_META = "SET_AUDIT_META";
export const EXPORT_AUDIT_LOG_ACTION = "EXPORT_AUDIT_LOG_ACTION";

//identities
export const GET_IDENTITY_LOG_ACTION = "GET_IDENTITY_LOG_ACTION";
export const EXPORT_IDENTITY_LOG_ACTION = "EXPORT_IDENTITY_LOG_ACTION";
export const SEARCH_IDENTITY_LOG_ACTION = "SEARCH_IDENTITY_LOG_ACTION";
export const SET_IDENTITY_LOG = "SET_IDENTITY_LOG";
export const SET_IDENTITY_LOG_META = "SET_IDENTITY_LOG_META";
export const GET_IDENTITY_REPORT_ACTION = "GET_IDENTITY_REPORT_ACTION";
export const SET_IDENTITY_REPORT = "SET_IDENTITY_REPORT";
export const VERIFY_IDENTITY_ACTION = "VERIFY_IDENTITY_ACTION";

//business
export const GET_ALL_BUSINESSES = "GET_ALL_BUSINESSES";
export const GET_ALL_BUSINESS_LICENSE_CATEGORIES =
  "GET_ALL_BUSINESS_LICENSE_CATEGORIES";
export const SET_ALL_BUSINESSES = "SET_ALL_BUSINESSES";
