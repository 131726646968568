<template>
  <div class="no-card w-100 position-relative" style="z-index: 2;">
    <div class="card-block">
      <div class="text-center m-b-10">
        <h1 class="mt-5" style="color:#031b4d;">
          Agent Application Form
        </h1>
        <!-- <h4 class="card-title text-center">Field Agent Verification Form</h4> -->
        <h6 class="card-subtitle text-center">
          Please complete the form below and submit
        </h6>
      </div>
      <div
        class="text-center text-md-right mb-3 font-bold"
        v-if="$route.name !== 'VerificationPaymentView'"
      >
        Application Cost: ₦{{ (verification_price / 100) | amount }}
      </div>
      <!--      <div-->
      <!--        class="text-center text-md-right mb-3 font-bold"-->
      <!--        v-if="$route.name !== 'VerificationPaymentView' && verification.license_types.length > 0"-->
      <!--      >-->
      <!--        Application Permit Type:-->
      <!--        <span class="text-capitalize">{{-->
      <!--          ((verification.license_types.length) === 1-->
      <!--            ? verification.license_types[0]-->
      <!--            : verification.license_types[0] +-->
      <!--              " & " +-->
      <!--              verification.license_types[1])-->
      <!--        }}</span>-->
      <!--      </div>-->
      <!-- Nav tabs -->
      <ul
        class="nav nav-tabs customtab scrollable bg-light mb-3 d-md-none rounded"
        role="tablist"
      >
        <li
          class="nav-item"
          v-for="{ name, routeName } in tabRoutes"
          :key="name"
        >
          <router-link
            active-class="active"
            :to="{ name: routeName }"
            class="nav-link"
            role="tab"
          >
            <!-- <span class="mr-2" :class="link.linkIcon"></span> -->
            {{ name }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            v-if="!cannotSubmit"
            class="nav-link"
            :class="{ disabled: cannotSubmit }"
            :to="{ name: 'RequestVerificationPreviewView' }"
            active-class="active"
            role="tab"
            >Form Preview</router-link
          >
          <a
            v-if="cannotSubmit"
            href="javascript:void(0)"
            class="nav-link not-allowed disabled text-muted"
          >
            Form Preview
          </a>
        </li>
        <li class="nav-item">
          <router-link
            v-if="!cannotSubmit"
            class="nav-link"
            :class="{ disabled: cannotSubmit }"
            :to="{ name: 'VerificationPaymentView' }"
            active-class="active"
            role="tab"
            >Application Payment</router-link
          >
          <a
            v-if="cannotSubmit"
            href="javascript:void(0)"
            class="nav-link not-allowed disabled text-muted"
          >
            Application Payment
          </a>
        </li>
      </ul>
      <div class="vtabs customvtab mx-auto w-100">
        <ul
          class="nav nav-tabs tabs-vertical d-none d-md-block bg-light rounded"
          role="tablist"
        >
          <li
            class="nav-item"
            v-for="{ name, routeName, verificationKey } in tabRoutes"
            :key="name"
          >
            <a
              class="nav-link not-allowed"
              style="color: #adadad;"
              v-if="
                verification.guarantor_info.status === 'needs_update' &&
                  verificationKey !== 'guarantor_info'
              "
              v-tooltip="'Please fill the Guarantor Information'"
              >{{ name }}</a
            >

            <router-link
              v-else
              class="nav-link d-flex"
              role="tab"
              :to="{ name: routeName }"
              active-class="active"
              aria-expanded="false"
            >
              <i
                v-if="verification[verificationKey].completed"
                class="mdi mdi-check-circle text-success mr-1"
              ></i>
              {{ name }}
            </router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link not-allowed"
              style="color: #adadad;"
              v-if="
                cannotSubmit ||
                  verification.guarantor_info.status === 'needs_update'
              "
              v-tooltip="
                cannotSubmit
                  ? 'Please complete the form'
                  : 'Please fill the Guarantor Information'
              "
              >Form Preview</a
            >
            <router-link
              v-else
              class="nav-link d-flex"
              :to="{ name: 'RequestVerificationPreviewView' }"
              active-class="active"
              role="tab"
              aria-expanded="false"
            >
              <!-- <span class="hidden-sm-up"><i class="mdi mdi-home"></i></span>
                  <span class="hidden-xs-down">Payment</span>-->
              <span>Form Preview</span>
            </router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link not-allowed"
              style="color: #adadad;"
              v-if="
                cannotSubmit ||
                  verification.guarantor_info.status === 'needs_update'
              "
              v-tooltip="
                cannotSubmit
                  ? 'Please complete the form'
                  : 'Please fill the Guarantor Information'
              "
              >Payment</a
            >
            <router-link
              v-else
              class="nav-link d-flex"
              :to="{ name: 'VerificationPaymentView' }"
              active-class="active"
              role="tab"
              aria-expanded="false"
            >
              <!-- <span class="hidden-sm-up"><i class="mdi mdi-home"></i></span>
                  <span class="hidden-xs-down">Payment</span>-->
              <span>
                Payment
                <i
                  v-if="verification.paid"
                  class="mdi mdi-check-cirlce text-success"
                ></i>
              </span>
            </router-link>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content w-100 p-t-0">
          <div
            class="tab-pane active bg-white rounded py-3 pr-3"
            role="tabpanel"
            aria-expanded="true"
          >
            <router-view></router-view>
          </div>
          <div
            class="row mt-5"
            v-if="
              !(
                $route.name === 'VerificationPaymentView' ||
                $route.name === 'RequestVerificationPreviewView'
              )
            "
          >
            <div class="col-12">
              <div class="offset-md-6 col-md-6">
                <router-link
                  tag="button"
                  :to="{
                    name: 'RequestVerificationPreviewView',
                    params: { verification_id: verification.id }
                  }"
                  class="btn btn-warning waves-effect rounded-0 w-100"
                  :disabled="cannotSubmit"
                >
                  Preview and Submit
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notify from "@/helpers/notifyMe";
import store from "@/store";
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  name: "NewVerificationComponent",
  data() {
    return {
      tabRoutes: [
        {
          name: "Personal Information",
          routeName: "PersonalInformationView",
          verificationKey: "personal_info"
        },
        {
          name: "Identity Information",
          routeName: "IdentityVerificationNumberView",
          verificationKey: "identity_info"
        },
        {
          name: "Business Address",
          routeName: "AgentAddressView",
          verificationKey: "address_info"
        },
        {
          name: "Residential Address",
          routeName: "AgentResidentialAddressView",
          verificationKey: "residential_address"
        },
        {
          name: "Guarantor Information",
          routeName: "GuarantorInformationView",
          verificationKey: "guarantor_info"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification",
      verification_price: "getVerificationPrice"
    }),
    cannotSubmit() {
      return (
        !this.verification.personal_info.completed ||
        !this.verification.identity_info.completed ||
        !this.verification.address_info.completed ||
        !this.verification.residential_address.completed ||
        !this.verification.guarantor_info.completed
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    let verification_id = to.params.verification_id;
    Promise.all([
      store.dispatch(types.GET_REQUEST_VERIFICATION_ACTION, {
        verification_id
      }),
      // store.dispatch(types.GET_VERIFICATION_PRICE),
      store.dispatch(types.GET_VERIFICATION_REGIONS)
    ])
      .then(([verification]) => {
        if (verification.guarantor_info.status === "needs_update") {
          notify.success({
            message: "Please update your Guarantor's information"
          });
          if (to.name !== "GuarantorInformationView") {
            return next({
              name: "GuarantorInformationView",
              params: { verification_id: verification.id }
            });
          }

          return next();
        }

        if (
          verification.status === "verified" ||
          verification.status === "pending"
        ) {
          notify.error({
            message: "Application submitted"
          });
          return next({ name: "RequestVerificationView" });
        }

        if (!verification.is_pristine) {
          notify.success({
            message: "You can continue where you left off"
          });
        } else {
          notify.success({
            message: "You can always save your progress and continue later"
          });
        }
        return next();
      })
      .catch(error => {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
        return next(false);
      });
  },
  beforeRouteUpdate(to, from, next) {
    let verification_id = to.params.verification_id;
    store
      .dispatch(types.GET_REQUEST_VERIFICATION_ACTION, {
        verification_id
      })
      .then(verification => {
        if (verification.guarantor_info.status === "needs_update") {
          notify.warning({
            message: "Please update your Guarantor's information"
          });
          if (to.name !== "GuarantorInformationView") {
            return next({
              name: "GuarantorInformationView",
              params: { verification_id: verification.id }
            });
          }
        }

        if (
          verification.status === "verified" ||
          verification.status === "pending"
        ) {
          notify.error({
            message: "Application submitted"
          });
          return next({ name: "RequestVerificationView" });
        }

        if (!verification.is_pristine) {
          notify.success({
            message: "You can continue where you left off"
          });
        } else {
          notify.success({
            message: "You can always save your progress and continue later"
          });
        }
        return next();
      })
      .catch(error => {
        error &&
          error.data &&
          notify.error({
            message: error.data.message
          });
        return next(false);
      });
  }
};
</script>

<style lang="scss" scoped>
.dropdown-item.active,
.dropdown-item:active {
  color: #5cb85c;
  text-decoration: none;
  background: none;
}
</style>
