<template>
  <div class="card bordered-box">
    <div class="card-block">
      <form @submit.prevent="updateBusinessProfile">
        <div class="d-flex align-items-center">
          <h3 class="card-title " style="flex: 1">Super Agents Settings</h3>
          <div class="form-group m-b-0">
            <button
              v-if="editing"
              :disabled="$v.$invalid || isLoading || !$v.$anyDirty"
              type="submit"
              class="btn btn-warning p-t-10 p-b-10 rounded-0 waves-effect m-t-10"
            >
              Save Changes
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
            <button
              v-else
              type="button"
              class="btn btn-warning p-t-10 p-b-10 rounded-0 waves-effect m-t-10"
              @click="editing = true"
            >
              Edit
            </button>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-lg-5">
            <div
              class="form-group mb-4 d-flex flex-wrap justify-content-center"
              style="gap: 1rem"
            >
              <label for="numberOfShop" class="font-bold form-label">
                Shop Limit:</label
              >
              <input
                type="number"
                v-model.trim="numberOfShop"
                :class="{ 'form-error animated shake': $v.numberOfShop.$error }"
                @input="$v.numberOfShop.$touch"
                class="form-control rounded-0"
                style="min-width: 4rem; flex: 1"
                id="numberOfShop"
                :disabled="!editing"
                placeholder="Enter shop limit"
              />
            </div>
          </div>
          <div class="col-lg-5">
            <div
              class="form-group mb-4 d-flex flex-wrap justify-content-center"
              style="gap: 1rem"
            >
              <label for="extraShopFee" class="font-bold form-label">
                Fee for an extra shop:</label
              >
              <input
                type="number"
                v-model.trim="extraShopFee"
                :class="{ 'form-error animated shake': $v.extraShopFee.$error }"
                @input="$v.extraShopFee.$touch"
                class="form-control rounded-0"
                style="min-width: 4rem; flex: 1"
                id="extraShopFee"
                :disabled="!editing"
                placeholder="Enter Fee"
              />
            </div>
          </div>
          <div class="col-lg-2">
            <div class="form-group mb-4">
              <label
                for="enabled"
                class="d-inline-flex align-items-center font-bold form-label"
                style="gap: 1rem"
              >
                Enabled
                <span
                  class="d-inline-block"
                  :class="enabled ? 'bg-warning' : 'bg-secondary'"
                  style="width: 2.25rem; height: 1rem; position: relative; border-radius: 9rem"
                >
                  <span
                    class="d-inline-block rounded-circle bg-white"
                    :style="{
                      width: '1rem',
                      height: '1rem',
                      transition: '300ms ease-in-out transform',
                      transform: enabled
                        ? 'translateX(19px)'
                        : 'translateX(1px)'
                    }"
                  ></span>
                </span>
              </label>
              <input
                id="enabled"
                v-model="enabled"
                type="checkbox"
                class="sr-only"
                :disabled="!editing"
                @input="enabled = !enabled"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import * as types from "@/store/types";

export default {
  data() {
    return {
      numberOfShop: "",
      extraShopFee: "",
      enabled: false,
      editing: false,
      isLoading: false
    };
  },
  async mounted() {
    try {
      const response = await this.$store.dispatch(
        types.GET_SUPER_AGENT_SETTINGS
      );

      const {
        super_agent_shop_count,
        charge_per_shop,
        super_agent_charge_enabled
      } = response;
      this.numberOfShop = super_agent_shop_count || 2;
      this.extraShopFee = charge_per_shop
        ? Number(charge_per_shop / 100).toFixed(2)
        : 0;
      this.enabled = super_agent_charge_enabled || false;
    } catch (error) {
      this.isLoading = false;
      error &&
        error.data &&
        this.$notify.error({ message: error.data.message });
    }
  },
  validations: {
    numberOfShop: {
      required
    },
    extraShopFee: {
      required
    }
  },
  methods: {
    async updateBusinessProfile() {
      try {
        let payload = {
          super_agent_shop_count: this.numberOfShop,
          charge_per_shop: this.extraShopFee,
          super_agent_charge_enabled: this.enabled
        };
        await this.$store.dispatch(types.UPDATE_SUPER_AGENTS_SETTINGS, payload);
        this.$notify.success({
          message: "Super agent settings updated successfully"
        });
        this.isLoading = false;
        this.editing = false;
      } catch (error) {
        this.isLoading = false;
        error &&
          error.data &&
          this.$notify.error({
            message: error.data.message
          });
      }
    }
  }
};
</script>

<style scoped>
.bg-secondary {
  background-color: #c4c4c4;
}
</style>
