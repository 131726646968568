<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="view-all-role-users-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">
            {{ selected_role.name }} Users ({{ selected_role.users.length }})
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <ul class="list-group list-group-flush">
              <template
                v-if="
                  selected_role &&
                    selected_role.users &&
                    selected_role.users.length > 0
                "
              >
                <li
                  class="list-group-item p-l-0 font-14 text-capitalize"
                  v-for="user in selected_role.users"
                  :key="user.id"
                >
                  {{
                    `${user.first_name} ${user.middle_name} ${user.last_name}`
                  }}
                </li>
              </template>
              <template v-else>
                <li class="list-group-item p-l-0 font-14">
                  There are no team members in this role
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ViewAllRoleUsersModal",
  computed: {
    ...mapGetters({
      selected_role: "getSelectedRole"
    })
  }
};
</script>
