// components
import SearchView from "@/modules/search/Index";

export default [
  {
    path: "/search",
    name: "SearchView",
    component: SearchView,
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["business-partner-admin"],
      documentTitle: "Omni Search"
    }
  }
];
