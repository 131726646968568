var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('report-component',_vm._b({attrs:{"hide_report":_vm.hide_report},on:{"show_report":function($event){_vm.show_report = true}}},'report-component',{ verification: _vm.verification },false),[_c('template',{slot:"header"},[_c('table',{staticStyle:{"table-layout":"fixed","width":"100%"}},[_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"width":"100%","overflow-x":"auto"}},[_c('ul',{staticClass:"nav nav-tabs customtab scrollable bg-light rounded",attrs:{"role":"tablist"}},_vm._l((_vm.actualTabRoutes),function(ref){
                  var name = ref.name;
                  var componentName = ref.componentName;
                  var verificationKey = ref.verificationKey;
return _c('li',{key:name,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:_vm.active_component === componentName ? 'active' : null,attrs:{"href":"#","role":"tab"},on:{"click":function($event){$event.preventDefault();_vm.active_component = componentName}}},[_vm._v(" "+_vm._s(name)+" "),_c('i',{staticClass:"mdi",class:_vm.verification[verificationKey].status === 'verified'
                          ? 'text-success mdi-check-circle'
                          : _vm.verification[verificationKey].status ===
                            'not_verified'
                          ? 'text-danger mdi-alert'
                          : _vm.verification[verificationKey].status === 'pending'
                          ? 'text-warning mdi-clock'
                          : _vm.verification[verificationKey].status ===
                            'incomplete'
                          ? 'text-primary mdi-alert-outline'
                          : _vm.verification[verificationKey].status ===
                            'awaiting_qa'
                          ? 'text-info mdi-clock'
                          : _vm.verification[verificationKey].status ===
                            'completed'
                          ? null
                          : 'text-warning mdi-alert'})])])}),0)])])])])]),_c(_vm.active_component,_vm._b({tag:"component"},'component',_vm.verification,false))],2),(_vm.hide_report)?_c('div',{staticClass:"overlay"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }