<template>
  <form @submit.prevent="updatePersonalInformation">
    <modal
      @before-open="beforeModalOpen"
      @before-close="beforeModalClose"
      :width="innerWidth > 900 ? '60%' : '80%'"
      name="guideline-modal"
      :scrollable="false"
      height="auto"
      :adaptive="true"
      :clickToClose="true"
    >
      <div
        class="row"
        :style="{
          overflowY: 'scroll',
          height: innerWidth > 900 ? 'auto' : '500px',
          paddingBottom: '50px'
        }"
      >
        <div class="col-md-6 p-5">
          <p class="font-bold font-20">
            Good Photo <i class="mdi mdi-check-circle text-success"></i>
          </p>
          <img
            src="@/assets/images/good_photo.png"
            class="img-responsive"
            alt="Good photo"
          />
          <p class="p-3 font-14">
            Your face should be within the dotted oval before clicking on the
            snap button.
          </p>
        </div>
        <div class="col-md-6 p-5">
          <p class="font-bold font-20">
            Bad Photo <i class="mdi mdi-close-circle text-danger"></i>
          </p>
          <img
            src="@/assets/images/bad_photo.png"
            class="img-responsive"
            alt="Bad photo"
          />

          <p class="p-3 font-14">
            Any photo that do not reveal your face fully within the frame will
            <span class="font-bold">NOT</span> be accepted.
          </p>
        </div>
        <div
          class="col-12"
          :style="{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'white'
          }"
        >
          <div class="row justify-content-around mod-footer">
            <button class="btn" @click.prevent="handleModalCancel">
              Cancel
            </button>
            <button
              class="btn proceed font-bold"
              @click.prevent="handleModalOkay"
            >
              Okay, Understood
            </button>
          </div>
        </div>
      </div>
    </modal>

    <div class="row photo-upload" v-show="this.showCanvas === false">
      <div class="col-12">
        <div class="form-group m-b-10">
          <label class="font-bold form-label">
            Agent's Picture
            <span class="text-danger">*</span>
            <span
              class="mdi mdi-help-circle-o ml-1"
              v-tooltip="
                'Uploaded photos should be with the sufficient brightness and the keep the face fully visible, please make sure the photo is as clear as possible.'
              "
            ></span>
          </label>
          <br />
          <small>
            Please note that this picture will be used on your ID card
          </small>
          <div class="col-md-12 row align-items-center photo-selection">
            <div
              class="d-flex justify-content-center col-md-8"
              @click="handleUseCamera"
            >
              <div>
                <svg
                  width="77"
                  height="108"
                  viewBox="0 0 127 158"
                  class="mr-auto ml-auto"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_f)">
                    <rect
                      x="14"
                      y="45"
                      width="99"
                      height="99"
                      rx="49.5"
                      fill="#C4C4C4"
                      fill-opacity="0.58"
                    />
                    <rect
                      x="14.5"
                      y="45.5"
                      width="98"
                      height="98"
                      rx="49"
                      stroke="#C4C4C4"
                    />
                  </g>
                  <rect
                    x="0.5"
                    y="0.5"
                    width="126"
                    height="126"
                    rx="63"
                    fill="white"
                    stroke="#C4C4C4"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M70.0983 51.3125H79.3438L80.5625 52.5312V74.4688L79.3438 75.6875H47.6562L46.4375 74.4688V52.5312L47.6562 51.3125H56.9017L58.9809 49.2309L59.8438 48.875H67.1562L68.0191 49.2309L70.0983 51.3125ZM48.875 73.25H78.125V53.75H69.5938L68.7309 53.3941L66.6517 51.3125H60.3483L58.2691 53.3941L57.4062 53.75H48.875V73.25ZM52.5312 56.1875C52.208 56.1875 51.898 56.3159 51.6695 56.5445C51.4409 56.773 51.3125 57.083 51.3125 57.4062C51.3125 57.7295 51.4409 58.0395 51.6695 58.268C51.898 58.4966 52.208 58.625 52.5312 58.625C52.8545 58.625 53.1645 58.4966 53.393 58.268C53.6216 58.0395 53.75 57.7295 53.75 57.4062C53.75 57.083 53.6216 56.773 53.393 56.5445C53.1645 56.3159 52.8545 56.1875 52.5312 56.1875ZM63.5 58.625C64.7929 58.625 66.0329 59.1386 66.9471 60.0529C67.8614 60.9671 68.375 62.2071 68.375 63.5C68.375 64.7929 67.8614 66.0329 66.9471 66.9471C66.0329 67.8614 64.7929 68.375 63.5 68.375C62.2071 68.375 60.9671 67.8614 60.0529 66.9471C59.1386 66.0329 58.625 64.7929 58.625 63.5C58.625 62.2071 59.1386 60.9671 60.0529 60.0529C60.9671 59.1386 62.2071 58.625 63.5 58.625ZM63.5 56.1875C61.5606 56.1875 59.7006 56.9579 58.3293 58.3293C56.9579 59.7006 56.1875 61.5606 56.1875 63.5C56.1875 65.4394 56.9579 67.2994 58.3293 68.6707C59.7006 70.0421 61.5606 70.8125 63.5 70.8125C65.4394 70.8125 67.2994 70.0421 68.6707 68.6707C70.0421 67.2994 70.8125 65.4394 70.8125 63.5C70.8125 61.5606 70.0421 59.7006 68.6707 58.3293C67.2994 56.9579 65.4394 56.1875 63.5 56.1875Z"
                    fill="#F5B600"
                  />
                  <defs>
                    <filter
                      id="filter0_f"
                      x="0"
                      y="31"
                      width="127"
                      height="127"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="7"
                        result="effect1_foregroundBlur"
                      />
                    </filter>
                  </defs>
                </svg>
                <h6 class="col-12 pl-0 text-center">
                  Use Camera
                </h6>
              </div>
            </div>
            <!--            <div-->
            <!--              class="col-md-4 d-flex justify-content-center"-->
            <!--              @click="clickOnFile"-->
            <!--            >-->
            <!--              <div>-->
            <!--                <svg-->
            <!--                  width="77"-->
            <!--                  height="108"-->
            <!--                  class="mr-auto ml-auto"-->
            <!--                  viewBox="0 0 127 158"-->
            <!--                  fill="none"-->
            <!--                  xmlns="http://www.w3.org/2000/svg"-->
            <!--                >-->
            <!--                  <g filter="url(#filter0_f)">-->
            <!--                    <rect-->
            <!--                      x="14"-->
            <!--                      y="45"-->
            <!--                      width="99"-->
            <!--                      height="99"-->
            <!--                      rx="49.5"-->
            <!--                      fill="#C4C4C4"-->
            <!--                      fill-opacity="0.58"-->
            <!--                    />-->
            <!--                    <rect-->
            <!--                      x="14.5"-->
            <!--                      y="45.5"-->
            <!--                      width="98"-->
            <!--                      height="98"-->
            <!--                      rx="49"-->
            <!--                      stroke="#C4C4C4"-->
            <!--                    />-->
            <!--                  </g>-->
            <!--                  <rect-->
            <!--                    x="0.5"-->
            <!--                    y="0.5"-->
            <!--                    width="126"-->
            <!--                    height="126"-->
            <!--                    rx="63"-->
            <!--                    fill="white"-->
            <!--                    stroke="#C4C4C4"-->
            <!--                  />-->
            <!--                  <path-->
            <!--                    d="M63.9998 60.5V79.25M55.2498 70.5C43.9998 71.75 45.2498 58 55.2498 59.25C51.4998 45.5 72.7498 45.5 71.4998 55.5C83.9998 51.75 83.9998 71.75 72.7498 70.5H55.2498ZM57.7498 65.5L63.9998 60.5L70.2498 65.5H57.7498Z"-->
            <!--                    stroke="#F5B600"-->
            <!--                    stroke-width="2.5"-->
            <!--                    stroke-linecap="round"-->
            <!--                    stroke-linejoin="round"-->
            <!--                  />-->
            <!--                  <defs>-->
            <!--                    <filter-->
            <!--                      id="filter0_f"-->
            <!--                      x="0"-->
            <!--                      y="31"-->
            <!--                      width="127"-->
            <!--                      height="127"-->
            <!--                      filterUnits="userSpaceOnUse"-->
            <!--                      color-interpolation-filters="sRGB"-->
            <!--                    >-->
            <!--                      <feFlood flood-opacity="0" result="BackgroundImageFix" />-->
            <!--                      <feBlend-->
            <!--                        mode="normal"-->
            <!--                        in="SourceGraphic"-->
            <!--                        in2="BackgroundImageFix"-->
            <!--                        result="shape"-->
            <!--                      />-->
            <!--                      <feGaussianBlur-->
            <!--                        stdDeviation="7"-->
            <!--                        result="effect1_foregroundBlur"-->
            <!--                      />-->
            <!--                    </filter>-->
            <!--                  </defs>-->
            <!--                </svg>-->
            <!--                <h6 class="col-12 pl-0 text-center">Upload Photo</h6>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="col-md-4 d-flex justify-content-center image-preview">
              <img
                id="output"
                :src="
                  image
                    ? image
                    : require('../../../assets/images/placeholder-image.jpg')
                "
              />
            </div>
            <!-- <h6 v-if="!image" class="col-md-8 text-center text-warning mt-2">
              No image choosen yet
            </h6> -->

            <input
              type="file"
              data-max-file-size="20M"
              data-allowed-file-extensions="jpeg jpg png"
              data-height="100"
              data-show-loader="true"
              ref="uploadFile"
              class="file-input"
              @change="setImage"
            />
          </div>
        </div>
      </div>
      <div class="col-12 p-b-10 p-t-20">
        <h5>Picture Upload Tips</h5>
        <div class="row">
          <div class="col-10 col-md-11 m-t-10 photo-tips">
            <p class="col-12 pl-0 m-b-1" style="font-size: 14px;">
              Make sure your picture is:
            </p>
            <ul class="col-12 d-block d-md-inline-flex m-b-0">
              <li class="col-12 col-md-6 font-light">
                <small>Bright and colourful</small>
              </li>
              <li class="col-12 col-md-6" font-light>
                <small>Positioned the right way up</small>
              </li>
            </ul>
            <ul class="col-12 d-block d-md-inline-flex">
              <li class="col-12 col-md-6 font-light">
                <small>Not more than 20MB</small>
              </li>
              <li class="col-12 col-md-6 font-light">
                <small>A passport photograph</small>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
            <div class="form-group m-b-10">
              <div class="col-md-12">
                <p class="font-12 mt-2">
                  <span class="font-bold">Note: </span>Image to be uploaded must
                  not be more than 20MB
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group m-b-10">
              <div class="col-md-12">
                <p class="font-12 mt-2">
                  <span class="font-bold">Note: </span>We recommend you use the
                  name as it is on your Identity document
                </p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div v-show="this.showCanvas === true">
      <div class="webcam-preview">
        <div class="video-container">
          <div class="video-player">
            <video
              ref="video"
              class="video col-12"
              id="video"
              playsinline
              muted
              autoplay
            ></video>
          </div>
          <div class="camera-guide">
            <div class="face-rect">
              <div class="face-border"></div>
            </div>
            <div class="corner-border">
              <p class="instruction">
                Make sure your face is within the circle
              </p>
            </div>
          </div>
          <!-- <div class="camera-overlay"></div> -->
        </div>
        <div class="camera-controls">
          <button
            class="btn waves-effect rounded-0 capture"
            @click.prevent="toggleCamera"
          >
            Cancel
          </button>

          <button
            class="btn btn-warning waves-effect rounded-0 capture"
            @click.prevent="takePhoto"
          >
            Capture
            <span
              :class="{
                'mdi mdi-loading mdi-spin': isLoading,
                'mdi mdi-arrow-right-thick': !isLoading
              }"
            ></span>
          </button>
        </div>
        <div class="preview">
          <canvas ref="canvas" class="canvas d-none" id="canvas"></canvas>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <small class="col-md-12">
            We recommend you use the name as it is on your Identity document
          </small>
          <label class="col-md-12 font-bold form-label">
            Last Name or Surname
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="last_name"
              :class="{ 'form-error animated shake': $v.last_name.$error }"
              @input="$v.last_name.$touch"
              class="form-control rounded-0"
              placeholder="Enter Agent's last name or Surname"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            First Name
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="first_name"
              :class="{ 'form-error animated shake': $v.first_name.$error }"
              @input="$v.first_name.$touch"
              class="form-control rounded-0"
              placeholder="Enter Agent's first name"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">Middle Name</label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="middle_name"
              class="form-control rounded-0"
              placeholder="Enter Agent's middle name"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Gender
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <select
              class="form-control custom-select rounded-0"
              v-model.trim="gender"
              :class="{ 'form-error animated shake': $v.gender.$error }"
              @change="$v.gender.$touch"
            >
              <option value disabled>-- select gender --</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Date of Birth
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <flat-pickr
              v-model.trim="dob"
              :class="{ 'form-error animated shake': $v.dob.$error }"
              @input="$v.dob.$touch"
              class="form-control rounded-0"
              :config="dateConfig"
              placeholder="Enter Agent's date of birth"
            ></flat-pickr>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Email Address
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="email"
              v-model.trim="email"
              :class="{ 'form-error animated shake': $v.email.$error }"
              @input="$v.email.$touch"
              class="form-control rounded-0"
              placeholder="Enter Agent's email address"
            />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Phone Number
            <span class="text-danger">*</span>
          </label>
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="mobile"
              :class="{ 'form-error animated shake': $v.mobile.$error }"
              @input="$v.mobile.$touch"
              class="form-control rounded-0"
              placeholder="Enter Agent's phone number"
            />
          </div>
        </div>
      </div>
      <!--      <div class="col-md-6">-->
      <!--        <div class="form-group m-b-10">-->
      <!--          <label class="col-md-12 font-bold form-label">-->
      <!--            Region-->
      <!--            <span class="text-danger">*</span>-->
      <!--          </label>-->
      <!--          <div class="col-md-12">-->
      <!--            <select-->
      <!--              v-model.trim="region"-->
      <!--              :class="{ 'form-error animated shake': $v.region.$error }"-->
      <!--              @change="$v.region.$touch"-->
      <!--              class="form-control rounded-0"-->
      <!--            >-->
      <!--              <option value="" disabled>&#45;&#45; select LGA/LCDA &#45;&#45;</option>-->
      <!--              <option v-for="region in regions" :key="region" :value="region">{{-->
      <!--                region-->
      <!--              }}</option>-->
      <!--            </select>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="col-md-6">
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label"
            >Other Mobile Number</label
          >
          <div class="col-md-12">
            <input
              type="text"
              v-model.trim="other_mobile"
              :class="{ 'form-error animated shake': $v.other_mobile.$error }"
              @input="$v.other_mobile.$touch"
              class="form-control rounded-0"
              placeholder="Enter Other mobile number"
            />
          </div>
        </div>
      </div>
      <div
        class="col-md-6"
        v-if="verification.license_types.includes('gaming')"
      >
        <div class="form-group m-b-10">
          <label class="col-md-12 font-bold form-label">
            Gaming Permit Types
          </label>
          <div class="col-md-12">
            <ul class="list-unstyled">
              <li
                v-for="(gaming_type, index) in gaming_permit_types"
                :key="gaming_type"
              >
                <div class="checkbox checkbox-warning p-t-0">
                  <input
                    :id="'gaming-permit-checkbox-' + index"
                    type="checkbox"
                    :value="gaming_type"
                    v-model="selected_gaming_permit_types"
                  />
                  <label
                    class="no-select font-14 font-bold"
                    :for="'gaming-permit-checkbox-' + index"
                  >
                    {{ gaming_type }}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6 offset-md-6 m-t-10">
        <div class="form-group m-b-10">
          <div class="col-md-12 m-t-5">
            <button
              class="btn btn-warning waves-effect rounded-0 w-100"
              :disabled="
                $v.$invalid ||
                  isLoading ||
                  verification.status === 'verified' ||
                  verification.status === 'pending'
              "
              type="submit"
            >
              Save & Continue
              <span
                :class="{
                  'mdi mdi-loading mdi-spin': isLoading,
                  'mdi mdi-arrow-right-thick': !isLoading
                }"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  requiredIf
} from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      image: "",
      stream: "",
      video: "",
      imageUploaded: false,
      useCamera: false,
      cameraBase64String: "",
      imageShowUrl: "",
      showCanvas: false,
      photo: "",
      last_name: "",
      first_name: "",
      middle_name: "",
      dob: "",
      gender: "",
      email: "",
      mobile: "",
      other_mobile: "",
      region: "",
      selected_gaming_permit_types: [],
      gaming_permit_types: [
        "Public Online Lottery",
        "Online Sports Betting",
        "Scratch Card and Interactive Games",
        "Gaming Machine",
        "Pool Betting",
        "Other Lotteries",
        "Other Games"
      ],
      isLoading: false,
      dateConfig: {
        maxDate: moment()
          .subtract(18, "years")
          .format("YYYY-MM-DD")
      }
    };
  },
  async mounted() {
    // initialize dropify
    // let drEvent = $(".dropify").dropify({
    //   messages: {
    //     default: "Drag and drop a passport image here or click",
    //     replace: "Drag and drop or click to replace",
    //     remove: "REMOVE",
    //     error: "Ooops, something happened."
    //   }
    // });
    //
    // drEvent.on("dropify.afterClear", () => {
    //   this.image = "";
    //   this.imageUploaded = false;
    // });

    let photo = this.verification.personal_info.photo_meta.url || "";
    if (photo) {
      this.image = photo;
      this.imageUploaded = true;
    }
    this.imageUploaded = photo;
    this.photo = photo;
    this.last_name = this.verification.personal_info.last_name;
    this.first_name = this.verification.personal_info.first_name;
    this.middle_name = this.verification.personal_info.middle_name;
    this.dob = this.verification.personal_info.dob;
    this.gender = this.verification.personal_info.gender;
    this.email = this.verification.personal_info.email;
    this.mobile = this.verification.personal_info.mobile;
    this.other_mobile = this.verification.personal_info.other_mobile;
    this.region = this.verification.personal_info.region;
    this.selected_gaming_permit_types =
      this.verification.personal_info.gaming_permit_types || [];
  },
  validations: {
    // let validations = {
    image: {
      required
    },
    last_name: {
      required
    },
    first_name: {
      required
    },
    dob: {
      required
    },
    gender: {
      required
    },
    email: {
      required,
      email
    },
    mobile: {
      required,
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    other_mobile: {
      numeric,
      minLength: minLength(11),
      maxLength: maxLength(11)
    },
    // region: {
    //   required,
    //   mustBeAValidRegion(region) {
    //     return this.regions.includes(region);
    //   }
    // },
    selected_gaming_permit_types: {
      required: requiredIf(function() {
        return this.verification.license_types.includes("gaming");
      })
    }

    // if (this.other_mobile) {
    //   validations["other_mobile"] = {
    //     numeric,
    //     minLength: minLength(11),
    //     maxLength: maxLength(11)
    //   };
    // }
    // };
    // return validations;
  },
  methods: {
    captureBytes() {
      // const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const width = this.video.videoWidth;
      const height = this.video.videoHeight;

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(this.video, 0, 0, width, height);
      this.image = canvas.toDataURL("image/jpg", 0.7);

      this.toggleCamera();
      this.imageUploaded = false;
    },
    async takePhoto() {
      this.video.play();
      this.useCamera = true;
      this.captureBytes();
    },
    async toggleCamera() {
      this.showCanvas = !this.showCanvas;
      this.video = this.$refs.video;

      this.video.style.width = document.width + "px";
      this.video.style.height = document.height + "px";
      this.video.setAttribute("autoplay", "");
      this.video.setAttribute("muted", "");
      this.video.setAttribute("playsinline", "");

      const constraints = {
        video: {
          facingMode: "user"
        },
        audio: false
      };

      if (this.showCanvas) {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          try {
            const stream = await navigator.mediaDevices.getUserMedia(
              constraints
            );
            this.stream = stream;
            this.video.srcObject = stream;
          } catch (error) {
            console.log("error accessing camera", error);
          }

          return;
        }

        if (navigator.webkitGetUserMedia) {
          navigator.webkitGetUserMedia(
            constraints,
            stream => {
              this.stream = stream;
              this.video.srcObject = stream;
            },
            err => {
              console.log("error accessing camera", err);
            }
          );
        } else if (navigator.mozGetUserMedia) {
          navigator.mozGetUserMedia(
            constraints,
            stream => {
              this.stream = stream;
              this.video.srcObject = stream;
            },
            err => {
              console.log("error accessing camera", err);
            }
          );
        } else {
          this.$notify.error({
            message: "Your device does not support the camera option"
          });
        }
      } else {
        if (this.stream) {
          this.stream.getTracks()[0].stop();
        }
      }
    },
    handleModalOkay() {
      this.$modal.hide("guideline-modal");
      this.toggleCamera();
    },
    beforeModalOpen() {
      // When the modal is shown, we want a fixed body
      document.body.style.position = "fixed";
      document.body.style.top = `-${window.scrollY}px`;
    },
    beforeModalClose() {
      // When the modal is hidden...
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    },
    handleModalCancel() {
      this.$modal.hide("guideline-modal");
    },
    handleUseCamera() {
      this.$modal.show("guideline-modal");
    },
    clickOnFile() {
      this.$refs.uploadFile.click();
    },
    updatePersonalInformation() {
      this.$v.$touch();
      if (
        this.$v.$invalid ||
        this.isLoading ||
        this.verification.status === "verified" ||
        this.verification.status === "pending"
      ) {
        return;
      }
      this.isLoading = true;
      if (this.image && !this.imageUploaded) {
        this._uploadPassport();
      } else {
        if (this.photo || this.imageUploaded) {
          this._updatePersonalInformation();
        }
      }
    },
    setImage(input) {
      if (input.target.files && input.target.files[0]) {
        const { files } = input.target;
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.image = fr.result;
        });
        this.photo = input.target.files[0];
        this.imageUploaded = false;
      }
    },
    _uploadPassport() {
      let vm = this;
      fetch(this.image)
        .then(result => result.blob())
        .then(blob =>
          vm.$store.dispatch(
            types.UPLOAD_PERSONAL_INFORMATION_IMAGE_ACTION_WITH_DETECTION,
            {
              file: blob,
              detectFace: true
            }
          )
        )
        .then(passport => {
          vm.photo = passport.location;
          vm.imageUploaded = true;
          vm._updatePersonalInformation();
        })
        .catch(error => {
          vm.isLoading = false;
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        });
    },
    _updatePersonalInformation() {
      let vm = this;
      // let payload = { ...vm.$data };
      // payload.mobile = vm.verification.personal_info.mobile;
      // payload.email = vm.verification.personal_info.email;
      vm.$store
        .dispatch(types.UPDATE_PERSONAL_INFORMATION_ACTION, {
          ...vm.$data,
          verification_id: vm.$route.params.verification_id
        })
        .then(verification => {
          vm.$notify.success({
            message: "Personal information saved successfully"
          });
          vm.$router.push({
            name: "IdentityVerificationNumberView",
            params: { verification_id: verification.id }
          });
        })
        .catch(error => {
          error &&
            error.data &&
            vm.$notify.error({
              message: error.data.message
            });
        })
        .finally(() => {
          vm.isLoading = false;
        });
    }
  },
  computed: {
    ...mapGetters({
      verification: "getRequestVerification",
      regions: "getRequestVerificationRegions"
    }),
    innerWidth() {
      return window.innerWidth;
    }
  }
};
</script>

<style scoped>
.video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.camera-controls {
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.camera-controls button {
  margin-right: 10px;
}

.photo-tips {
  border-left: 3px;
  border-left-color: #f5b600;
  border-left-style: solid;
}

.video-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .video-container {
    width: 50%;
  }
}

.video-container .video-player {
  width: 100%;
}

.video-player video {
  padding: 0;
}

.camera-guide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid red;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.face-rect {
  width: 90%;
  height: 90%;
  position: relative;
  display: flex;
  justify-content: center;
}

.corner-border {
  position: absolute;
  width: 90%;
  height: 90%;
  background: linear-gradient(to right, #fff 4px, transparent 4px) 0 0,
    linear-gradient(to right, #fff 4px, transparent 4px) 0 100%,
    linear-gradient(to left, #fff 4px, transparent 4px) 100% 0,
    linear-gradient(to left, #fff 4px, transparent 4px) 100% 100%,
    linear-gradient(to bottom, #fff 4px, transparent 4px) 0 0,
    linear-gradient(to bottom, #fff 4px, transparent 4px) 100% 0,
    linear-gradient(to top, #fff 4px, transparent 4px) 0 100%,
    linear-gradient(to top, #fff 4px, transparent 4px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.face-border {
  width: 80%;
  border-radius: 100%;
  border: 1px dashed #d7d7d7;
  height: 90%;
  box-shadow: 0px 0px 0px 350px rgba(0, 0, 0, 0.916);
}

.photo-upload {
  margin: 20px 0;
}

.photo-selection {
  border: 1px dashed #404e5d;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  margin: 10px 0;
}

.file-input {
  display: none;
}

.image-preview {
  padding-bottom: 20%;
  position: relative;
  min-height: 150px;
}

.image-preview img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.mod-footer {
  border-top: 1px solid #c4c4c4;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.mod-footer button {
  border: none;
  background-color: transparent;
  color: #404e5d;
}

button.proceed {
  color: #f5b600;
}

.instruction {
  color: white;
  font-weight: bold;
  text-align: center;
  margin: 0;
  font-size: 14px;
}
</style>
