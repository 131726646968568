import * as types from "@/store/types";
import api from "../_api";

const actions = {
  async [types.GET_AGENT_PROFILE_ACTION]({ commit }, payload) {
    let profile = await api.getAgentProfile(payload);
    commit(types.SET_AGENT_PROFILE, profile.data);
    return profile.data;
  },
  async [types.GET_AGENTS_ACTION]({ commit }, payload) {
    let agents = await api.getAgents(payload);
    commit(types.SET_AGENTS_LOG, agents.data);
    commit(types.SET_AGENTS_META, agents.meta);
    return agents;
  },
  async [types.SEARCH_AGENTS_ACTION]({ commit }, payload) {
    let agents = await api.searchAgents(payload);
    commit(types.SET_AGENTS_LOG, agents.data);
    commit(types.SET_AGENTS_META, agents.meta);
    return agents;
  },
  async [types.EXPORT_AGENTS_ACTION](_, payload) {
    let response = await api.exportAgents(payload);
    return response;
  },
  async [types.UPDATE_AGENT_EMAIL_ADDRESS](
    { commit, getters },
    { agent, email }
  ) {
    await api.updateAgentEmail({ agent, email });
    let agent_profile = JSON.parse(JSON.stringify(getters.getAgentProfile));
    agent_profile.email.value = email;
    commit(types.SET_AGENT_PROFILE, agent_profile);
    return agent_profile;
  },
  async [types.UPDATE_AGENT_DETAILS]({ commit, getters }, payload) {
    await api.updateAgentDetails(payload);
    let agent_profile = JSON.parse(JSON.stringify(getters.getAgentProfile));
    agent_profile.email.value = payload.email;
    agent_profile.mobile.value = payload.mobile;
    commit(types.SET_AGENT_PROFILE, agent_profile);
    return agent_profile;
  },
  async [types.UPDATE_AGENT_LSLB_LICENSE_NUMBER](
    { commit, getters },
    { agent, lslb_license_number }
  ) {
    await api.updateAgentReferenceId({ agent, lslb_license_number });
    let agent_profile = JSON.parse(JSON.stringify(getters.getAgentProfile));
    agent_profile.lslb_license_number = lslb_license_number;
    commit(types.SET_AGENT_PROFILE, agent_profile);
    return agent_profile;
  },
  async [types.ADD_AGENT_COMMENT]({ commit }, payload) {
    let response = await api.addAgentComment(payload);
    commit(types.SET_AGENT_COMMENTS, response.data);
    return response.data;
  },
  async [types.REMOVE_AGENT_COMMENT]({ commit, getters }, payload) {
    let response = await api.removeAgentComment(payload);
    let comments = getters.getAgentComments.filter(
      comment => comment.id !== payload.comment.id
    );
    commit(types.SET_AGENT_COMMENTS, comments);
    return response.data;
  },
  async [types.INVITE_NEW_AGENT_ACTION](_, payload) {
    return (await api.inviteNewAgent(payload)).data;
  },
  async [types.REQUEST_NEW_AGENT_ACTION](_, payload) {
    return (await api.requestNewAgent(payload)).data;
  },
  async [types.REQUEST_BULK_NEW_AGENT_ACTION](_, payload) {
    return (await api.requestBulkNewAgent(payload)).data;
  },
  async [types.SEARCH_AGENT_BY_PERMIT_NUMBER](_, agent_id) {
    return (await api.searchAgentByPermitNumber(agent_id)).data;
  },
  async [types.RESEND_GUARANTOR_INVITE](_, payload) {
    return api.resendGuarantorInvite(payload);
  },
  async [types.RETRY_GUARANTOR_VERIFICATION]({ commit }, payload) {
    const response = await api.retryGuarantorVerification(payload);
    commit(types.SET_VERIFICATION_REPORT, response.data);
    return response;
  },
  async [types.RESEND_AGENT_INVITE](_, payload) {
    return api.resendAgentInvite(payload);
  },
  async [types.DOWNLOAD_SAMPLE_BULK_UPLOAD_CSV]() {
    return api.downloadSampleBulKUploadCSV();
  },
  async [types.DOWNLOAD_SAMPLE_BULK_RENEW_CSV]() {
    return api.downloadSampleBulKRenewCSV();
  },
  async [types.SUBMIT_BULK_UPLOAD_CSV](_, payload) {
    return api.submitBulKUploadCSV(payload);
  },
  async [types.SUBMIT_BULK_RENEW_CSV](_, payload) {
    return api.submitBulKRenewCSV(payload);
  },
  async [types.SINGLE_PERMIT_RENEW](_, payload) {
    return api.singlePermitRenew(payload);
  },
  async [types.SINGLE_AGENT_ONBOARD](_, payload) {
    return api.singleAgentOnboard(payload);
  },
  async [types.UPLOAD_FILE](_, payload) {
    return api.uploadFile(payload);
  }
};

export default actions;
