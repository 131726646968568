<template>
  <div
    class="modal animated fade modal-border"
    id="verify-address-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30">
          <h4 class="modal-title text-center font-medium">Verify Address</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body p-b-0">
          <div class="col-md-12">
            <p class="font-14">
              Are you sure you want to verify this address?
              <br />
              <strong>Note:</strong> This will cost ₦750
            </p>
          </div>
        </div>
        <div class="modal-footer b-0">
          <div class="col-sm-12">
            <button
              type="button"
              @click.prevent="verifyAddress"
              :disabled="isLoading"
              class="btn btn-warning rounded-0 waves-effect p-10 w-100 p-10"
            >
              Yes, verify address
              <span v-if="isLoading">
                <i class="mdi mdi-loading mdi-spin"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as types from "@/store/types";

export default {
  props: ["verification"],
  data() {
    return {
      isLoading: false
    };
  },
  methods: {
    async verifyAddress() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(
          types.REQUEST_ADDRESS_VERIFICATION_ACTION,
          this.verification.id
        );
        this.$notify.success({
          message: "Address verification request sent successfully"
        });

        $("#verify-address-modal").modal("hide");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
