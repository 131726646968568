<template>
  <div class="card bordered-box p-20">
    <div class="card-block p-l-0 col-xs-12 col-md-6">
      <h3 class="card-title m-t-0 p-t-0" style="margin-top: -10px;">
        Report search result
      </h3>
      <div class="row"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
