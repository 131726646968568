<template>
  <div class="card bordered-box pagination-center">
    <div class="card-block">
      <!--            v-if="$User.hasPermission('permit-search')"-->
      <div class="row">
        <div
          class="col-xs-12 col-sm-10 m-b-10 offset-sm-2 col-md-8 offset-md-4"
        >
          <div class="d-flex align-items-center">
            <div class="form-group m-0 flex-grow">
              <input
                type="text"
                class="form-control rounded-0"
                placeholder="Search using name"
                v-model.trim="search_term"
                @input="searchPermits"
              />
            </div>
            <button
              v-if="$User.hasPermission('permit-create')"
              class="btn btn-warning ml-2"
              @click.stop="showAddPermitModal"
            >
              <i class="mr-1 mdi mdi-clipboard-account"></i>
              Add Permit
            </button>

            <button
              v-if="$User.hasPermission('permit-set-fee')"
              class="btn btn-warning ml-2"
              @click.stop="showSetPermitFeeModal"
            >
              <i class="mdi mdi-loading mdi-spin" v-if="getPriceLoading"></i>
              <i class="mr-1 mdi mdi-cash" v-else></i>
              Set Permit Fee
            </button>
          </div>
          <p
            class="text-danger font-14 font-bold mt-1"
            v-if="isSearching && permits.length === 0"
          >
            No permit found for '{{ $route.query.search_term }}'
          </p>
        </div>
      </div>
      <form id="filter-report-form" class="filter-report-form">
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="status" class="control-label">
                Status
                <span class="text-danger">*</span>
              </label>
              <select
                class="form-control custom-select rounded-0"
                v-model.trim="status"
                :class="{ 'form-error animated shake': $v.status.$error }"
              >
                <option value disabled>-- select status --</option>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="disabled">Disabled</option>
              </select>
            </div>
          </div>
          <!--                    <div class="col-md-3">-->
          <!--                        <div class="form-group m-b-5">-->
          <!--                            <label-->
          <!--                                for="start_date"-->
          <!--                                class="control-label"-->
          <!--                                style="display:block"-->
          <!--                            >-->
          <!--                                Start Date-->
          <!--                                &lt;!&ndash; <span class="text-danger">*</span> &ndash;&gt;-->
          <!--                            </label>-->
          <!--                            <div class="clearfix">-->
          <!--                                <flat-pickr-->
          <!--                                    v-model.trim="start_date"-->
          <!--                                    :class="{ 'form-error animated shake': $v.start_date.$error }"-->
          <!--                                    class="form-control rounded-0"-->
          <!--                                    :config="startDateConfig"-->
          <!--                                    placeholder="Start Date"-->
          <!--                                ></flat-pickr>-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    <div class="col-md-3">-->
          <!--                        <div class="form-group m-b-5">-->
          <!--                            <label for="end_date" class="control-label" style="display:block">-->
          <!--                                End Date-->
          <!--                                &lt;!&ndash; <span class="text-danger">*</span> &ndash;&gt;-->
          <!--                            </label>-->
          <!--                            &lt;!&ndash; <div-->
          <!--                              class="clearfix"-->
          <!--                              v-tooltip="!start_date ? 'Please select a start date' : ''"-->
          <!--                            > &ndash;&gt;-->
          <!--                            <flat-pickr-->
          <!--                                    v-model.trim="end_date"-->
          <!--                                    :class="{ 'form-error animated shake': $v.end_date.$error }"-->
          <!--                                    class="form-control rounded-0"-->
          <!--                                    :config="endDateConfig"-->
          <!--                                    placeholder="End Date"-->
          <!--                            ></flat-pickr>-->
          <!--                            &lt;!&ndash; </div> &ndash;&gt;-->
          <!--                        </div>-->
          <!--                    </div>-->
          <!--                    Used to be col-md-12-->
          <div class="col-md-3 align-self-end">
            <div class="form-group">
              <label class="control-label" style="display:block">&nbsp;</label>
              <button
                class="btn btn-warning btn-sm normalize-filter-btn rounded-0 m-r-5"
                :disabled="isLoading || $v.$invalid"
                @click.prevent="filterPermitLogs"
              >
                <i class="mdi mdi-filter"></i>
                Filter
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="row">
        <div class="col-md-12">
          <template v-if="!isSearching">
            <div class="p-t-10 p-b-10" v-if="isFilter">
              <label class="text-info font-normal font-14">
                Permit log filtered by
                <span
                  class="badge badge-pill badge-default mx-1"
                  v-if="$route.query.status"
                  ><strong>Status: </strong
                  >{{ $route.query.status | remove_underscore }}</span
                ><span
                  class="badge badge-pill badge-default mx-1"
                  v-if="$route.query.region"
                  ><strong>Region: </strong>{{ $route.query.region }}</span
                ><span v-if="$route.query.start_date || $route.query.end_date">
                  between {{ startDateDefault | date }} and
                  {{ endDateDefault | date }}
                </span>
              </label>
              <button
                @click="clearFilter"
                class="ml-3 btn btn-outline-info btn-xs font-bold"
              >
                clear filter
              </button>
            </div>
            <div class="p-t-10 p-b-10" v-if="!isFilter">
              <label class="text-info font-medium font-14"
                >Showing all permits</label
              >
            </div>
          </template>
          <template v-else>
            <div class="p-t-10 p-b-10">
              <label class="text-info font-medium font-14">
                <span class="p-r-10"
                  >Showing all permits by search term "{{
                    $route.query.search_term || ""
                  }}"</span
                >
                <button
                  @click="clearFilter"
                  class="p-l-5 btn btn-outline-info btn-xs font-bold"
                >
                  clear search
                </button>
              </label>
            </div>
          </template>
          <div class="table-responsive">
            <template v-if="permits.length > 0">
              <table class="table table-hover">
                <thead>
                  <tr class="link-row">
                    <th width="70" class="text-center">#</th>
                    <th>Status</th>
                    <th class="text-nowrap">Name</th>
                    <!--                                    <th class="text-nowrap">Created At</th>-->
                    <!--                                    <th class="text-nowrap">Modified At</th>-->
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class=""
                    v-for="(permit, index) in permits"
                    :key="permit._id"
                  >
                    <td>
                      {{ pagination.from + index }}
                    </td>
                    <td>
                      <span
                        class="label font-bold text-capitalize rounded-0 text-nowrap"
                        :class="[
                          !permit.is_disabled
                            ? 'label-light-success'
                            : 'label-light-danger'
                        ]"
                        >{{ !permit.is_disabled ? "Active" : "Disabled" }}</span
                      >
                    </td>
                    <td>{{ permit.category_name }}</td>
                    <!--                                    <td>{{ permit.created_at | long_date }}</td>-->
                    <!--                                    <td>{{ permit.updated_at | long_date }}</td>-->
                    <td>
                      <button
                        v-if="$User.hasPermission('permit-disable')"
                        :disabled="isLoading"
                        @click="
                          showResolvePermitActivationDialog(
                            permit.category_slug,
                            !permit.is_disabled
                          )
                        "
                        :class="
                          `btn ${
                            !permit.is_disabled ? 'btn-danger' : 'btn-success'
                          } btn-xs`
                        "
                      >
                        {{ !permit.is_disabled ? "Disable" : "Activate" }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="p-30 test font-medium text-muted">
                <!--                                <span>-->
                <!--                                    Showing {{ pagination.from }} to {{ pagination.to }} of-->
                <!--                                  {{ pagination.total }} entries-->
                <!--                                </span>-->
              </div>
            </template>
            <template v-else>
              <div class="text-center">
                <img
                  src="/static/empty.svg"
                  class="img-fluid mb-3"
                  alt="empty"
                  style="width: 50px;"
                />
                <p class="font-bold font-14">There are no permits to display</p>
              </div>
            </template>
          </div>
          <div class="text-center p-l-30 m-t-20">
            <pagination
              :data="pagination"
              :limit="4"
              :show-disabled="true"
              @pagination-change-page="getLogByPage"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import debounce from "@/helpers/debounce";
import eventBus from "@/helpers/event";
import api from "../_api/index";

export default {
  name: "PermitsLog",
  mounted() {
    eventBus.$on("lslb:permit:refresh-permits-list", () => {
      this.getLogByPage(this.pagination && this.pagination.current_page);
    });
    console.log(this.permits);
  },
  created() {
    this.startDateConfig = {
      maxDate: moment().format("YYYY-MM-DD"),
      minDate: moment(this.business_profile?.created_at).format("YYYY-MM-DD")
    };
  },
  data() {
    return {
      status: this.$route.query.status || "all",
      start_date: this.$route.query.start_date || "",
      end_date: this.$route.query.end_date || "",
      search_term: this.$route.query.search_term || "",
      startDateConfig: {},
      endDateConfig: {
        maxDate: moment().format("YYYY-MM-DD"),
        minDate: moment().format("YYYY-MM-DD")
      },
      isLoading: false,
      getPriceLoading: false
    };
  },
  validations: {
    status: { required },
    start_date: {},
    end_date: {}
  },
  watch: {
    start_date() {
      this.endDateConfig.minDate = moment(this.start_date)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
  },
  computed: {
    ...mapGetters({
      permits: "getPermitsLog",
      pagination: "getPermitsLogPagination",
      business_profile: "getBusinessProfile",
      profile: "getCurrentUser"
    }),
    startDateDefault() {
      return (
        this.$route.query.start_date ||
        moment(this.$store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        )
      );
    },
    endDateDefault() {
      return this.$route.query.end_date || moment().format("YYYY-MM-DD");
    },
    isFilter() {
      return this.$route.query.status;
    },
    isSearching() {
      return this.$route.query.search_term;
    }
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async filterPermitLogs() {
      if (this.validateForm()) {
        try {
          this.isLoading = true;
          let { start_date, end_date, status } = this;
          let query = {
            ...(status && status !== "all" && { status }),
            ...(start_date && { start_date }),
            ...(end_date && { end_date })
          };
          this.$router.push({
            name: this.$route.name,
            query
          });
        } catch (error) {
          error &&
            error.data &&
            this.$notify.error({ message: error.data.message });
        } finally {
          this.isLoading = false;
        }
      }
    },
    getLogByPage(page) {
      let { status, start_date, end_date } = this.$route.query;

      let query = {
        [Date.now()]: null,
        ...(status && { status }),
        page,
        ...(start_date && { start_date }),
        ...(end_date && { end_date }),
        timestamp: Date.now()
      };

      this.$router.push({
        name: this.$route.name,
        query
      });
    },
    async clearFilter() {
      this.$router.push({ name: this.$route.name }, () => {
        this.status = "all";
        this.start_date = "";
        this.end_date = "";
        this.search_term = "";
        this.$v.$reset();
        //this.isLoading = false;
      });
    },
    searchPermits: debounce(async function(e) {
      if (e.target.value.trim()) {
        this.$router.push({
          name: this.$route.name,
          query: {
            search_term: e.target.value.trim()
          }
        });
      }
    }, 750),

    showAddPermitModal() {
      $("#add-permit-modal").modal("show");
    },

    async showSetPermitFeeModal() {
      try {
        this.getPriceLoading = true;
        await this.$store.dispatch(types.GET_VERIFICATION_PRICE);
        $("#set-permit-fee-modal").modal("show");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.getPriceLoading = false;
      }
    },

    showResolvePermitActivationDialog(slug, is_disabled) {
      this.$swal({
        title: "Are you sure?",
        text: `You are about to ${
          !is_disabled ? "activate" : "disable"
        } this permit.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Yes, please ${
          !is_disabled ? "activate" : "disable"
        } permit.`,
        cancelButtonText: "No, cancel!"
      }).then(result => {
        if (result.value) {
          this.resolvePermitActivation(slug, is_disabled);
        }
      });
    },
    //
    async resolvePermitActivation(slug, is_disabled) {
      this.isLoading = true;
      try {
        await api.resolvePermitActivation({ slug, is_disabled });
        eventBus.$emit("lslb:permit:refresh-permits-list");
      } catch (error) {
        error &&
          error.data &&
          this.$notify.error({ message: error.data.message });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped></style>
