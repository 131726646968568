<template>
  <div>
    <!-- ============================================================== -->
    <!--  reconcile transaction -->
    <!-- ============================================================== -->
    <reconcile-transaction
      v-if="$User.hasPermission('transaction-reconcile')"
    ></reconcile-transaction>
    <!-- ============================================================== -->
    <!--  component -->
    <!-- ============================================================== -->
    <transaction-log></transaction-log>
    <!-- ============================================================== -->
    <!--  reconcile transaction modal -->
    <!-- ============================================================== -->
    <reconcile-transaction-modal
      v-if="$User.hasPermission('transaction-reconcile')"
    ></reconcile-transaction-modal>
  </div>
</template>

<script>
import ReconcileTransaction from "./_components/ReconcileTransaction.vue";
import ReconcileTransactionModal from "./_components/ReconcileTransactionModal.vue";
import TransactionLog from "@/components/transaction/TransactionLog.vue";
import store from "@/store";
import * as types from "@/store/types";
import notify from "@/helpers/notifyMe";
import moment from "moment";

export default {
  name: "TransactionsIndex",
  components: {
    TransactionLog,
    ReconcileTransaction,
    ReconcileTransactionModal
  },
  async beforeRouteEnter(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        user_id = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;

      if (search_term) {
        await store.dispatch(types.SEARCH_TRANSACTIONS_ACTION, {
          query: search_term,
          paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_TRANSACTIONS_ACTION, {
          page,
          status,
          start_date,
          end_date,
          user_id
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      let {
        search_term = "",
        page = 1,
        status = "all",
        user_id = "all",
        start_date = moment(store.getters.getBusinessProfile.created_at).format(
          "YYYY-MM-DD"
        ),
        end_date = moment().format("YYYY-MM-DD")
      } = to.query;

      if (search_term) {
        await store.dispatch(types.SEARCH_TRANSACTIONS_ACTION, {
          query: search_term,
          paginate: 1,
          page: 1
        });
      } else {
        await store.dispatch(types.GET_TRANSACTIONS_ACTION, {
          page,
          status,
          start_date,
          end_date,
          user_id
        });
      }
      next();
      return;
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  },
  beforeDestroy() {
    // $("#reconcile-transaction-modal").modal("dispose");

    $("#reconcile-transaction-modal").modal("hide");
    $("#reconcile-transaction-modal").on("hidden.bs.modal", function() {
      $("#reconcile-transaction-modal").modal("dispose");
    });
  }
};
</script>
