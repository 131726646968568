<template>
  <div
    class="modal animated fade pulse_ modal-border"
    id="error-modal"
    data-backdrop="static"
    tabindex="-1"
    role="dialog"
    aria-labelledby="change-role-modal"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content modal-border-radius">
        <div class="modal-header b-0 p-l-30 justify-content-end">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true" class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div
            class="d-flex flex-column align-items-center mx-auto"
            style="max-width: 24rem"
          >
            <img
              src="/static/cancel.svg"
              alt="bulk upload icon"
              style="width: 6rem"
            />
            <p class="mt-4 text-dark">
              <strong>Uploading and processing failed</strong>
            </p>
            <p class="font-14" style="text-align: center">
              Agent did not meet the requirements, check your mail for more
              details
            </p>
            <button
              type="button"
              class="btn text-warning btn-link"
              data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    email: String
  },
  methods: {
    closeModal() {
      $("#error-modal").modal("hide");
    }
  }
};
</script>
