// components
import AgentProfileLogin from "@/modules/agent-profile/Index";
import AgentProfile from "@/modules/agent-profile/_components/AgentProfile";
import AgentProfileReportView from "@/modules/agent-profile/_components/AgentProfileReportView";
import AgentDashboard from "@/components/agent/AgentProfile";
import AgentApplications from "@/components/agent/AgentVerificationsLog";
import AgentCompanies from "@/components/agent/AgentCompaniesLog";
import AgentCompany from "@/components/agent/AgentCompany";
import AgentComments from "@/modules/agents/_components/AgentComments";
import AgentPayments from "@/components/agent/AgentTransactionsLog";
import AgentSettings from "@/modules/agent-profile/_components/AgentVerificationSettings";
import AgentCompanyAssociationRequest from "@/components/agent/AgentCompanyAssociationRequest";

export default [
  {
    path: "/agent-profile",
    name: "AgentProfileLogin",
    component: AgentProfileLogin,
    meta: {
      plainLayout: true,
      showProgressBar: true
    }
  },
  {
    path: "/agent-profile/:profile_id",
    name: "AgentProfile",
    component: AgentProfile,
    meta: {
      plainLayout: true,
      showProgressBar: true,
      documentTitle: "Agent Profile"
    },
    redirect: {
      name: "AgentDashboard"
    },
    children: [
      {
        path: "/agent-profile/:profile_id/dashboard",
        name: "AgentDashboard",
        component: AgentDashboard,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Dashboard"
        }
      },
      {
        path: "/agent-profile/:profile_id/applications",
        name: "AgentApplications",
        component: AgentApplications,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Applications"
        }
      },
      {
        path: "/agent-profile/:profile_id/companies/:request_id",
        name: "AgentApplicationsCompany",
        component: AgentCompanies,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          deepLink: true,
          documentTitle: "Agent Applications"
        }
      },
      {
        path: "/agent-profile/:profile_id/companies",
        name: "AgentCompanies",
        component: AgentCompanies,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Companies"
        }
      },
      {
        path: "/agent-profile/:profile_id/payments",
        name: "AgentPayments",
        component: AgentPayments,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Payments"
        }
      },
      {
        path: "/agent-profile/:profile_id/settings",
        name: "AgentSettings",
        component: AgentSettings,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Settings"
        }
      },
      {
        path: "/agent-profile/:profile_id/comments",
        name: "AgentComments",
        component: AgentComments,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Comments"
        }
      },
      {
        path: "/agent-profile/:profile_id/applications/:verification_id",
        name: "AgentProfileReportView",
        component: AgentProfileReportView,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Application Report"
        }
      },
      {
        path: "/agent-profile/:profile_id/operator-request/:business.id",
        name: "AgentCompanyAssociationRequest",
        component: AgentCompanyAssociationRequest,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          deepLink: true,
          documentTitle: "Agent Companies"
        }
      },
      {
        path: "/agent-profile/:profile_id/operator/example",
        name: "AgentCompany",
        component: AgentCompany,
        meta: {
          plainLayout: true,
          showProgressBar: true,
          documentTitle: "Agent Companies"
        }
      }
    ]
  }
];
