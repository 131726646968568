// components
import NinView from "@/modules/identity/Nin/Index";
import BvnView from "@/modules/identity/Bvn/Index";
import AgentLicenseView from "@/modules/identity/DriverLicense/Index";
import IdentityReport from "@/modules/identity/_components/IdentityReport";
import Identity from "@/modules/identity/Index";
import User from "@/helpers/User";

export default [
  {
    path: "/identity",
    component: Identity,
    // redirect: {
    //   name: "NinView"
    // },
    meta: {
      allowed_permissions: [
        "verify-identity-nin",
        "verify-identity-ibvn",
        "verify-identity-frsc",
        "identity-view"
      ]
    },
    redirect: () => {
      if (User.hasPermission("verify-identity-nin")) {
        return { name: "NinView" };
      }
      if (User.hasPermission("verify-identity-ibvn")) {
        return { name: "BvnView" };
      }
      if (User.hasPermission("verify-identity-frsc")) {
        return { name: "AgentLicenseView" };
      }
      return undefined;
    },
    name: "IdentityContainer",
    children: [
      {
        path: "nin",
        component: NinView,
        name: "NinView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["verify-identity-nin"],
          documentTitle: "NIN Verification"
        }
      },
      {
        path: "bvn",
        component: BvnView,
        name: "BvnView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["verify-identity-ibvn"],
          documentTitle: "NIN Verification"
        }
      },
      {
        path: "agent-license",
        component: AgentLicenseView,
        name: "AgentLicenseView",
        meta: {
          requiresAuth: true,
          showProgressBar: true,
          allowed_permissions: ["verify-identity-frsc"],
          documentTitle: "NIN Verification"
        }
      }
    ]
  },
  {
    path: "/identity/reports/:report_id",
    component: IdentityReport,
    name: "IdentityReport",
    meta: {
      requiresAuth: true,
      showProgressBar: true,
      allowed_permissions: ["identity-view"],
      documentTitle: "Identity Report"
    }
  }
];
