import * as types from "@/store/types";

const mutations = {
  async [types.SET_LOGIN_ERROR](state, payload) {
    state.login_error = payload;
  },
  async [types.SET_INTENDED_TO](state, payload) {
    state.intended_to = payload;
  },
  async [types.SET_AUTH_DATA](state, payload) {
    // set auth data
    state.auth_data = payload;
  }
};

export default mutations;
