<template>
  <aside
    class="left-sidebar"
    :class="{ 'bg-white no-shadow': !isPageContentReady }"
  >
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar transition" v-if="isPageContentReady">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav" class="my-sidebar-menu">
          <li class="nav-small-cap">OVERVIEW</li>
          <router-link
            tag="li"
            :to="{ name: 'GetStartedView' }"
            active-class="active"
          >
            <a>
              <i class="mdi mdi-earth"></i>
              <span class="hide-menu">Get Started</span>
            </a>
          </router-link>
          <router-link
            id="tour-dashboard-view"
            tag="li"
            :to="{ name: 'DashboardView' }"
            active-class="active"
            aria-expanded="false"
            v-if="
              ($User.hasPermission('metric-view') ||
                $User.hasPermission('business-balance-view')) &&
                userType !== 'company'
            "
          >
            <a>
              <i class="mdi mdi-gauge"></i>
              <span class="hide-menu">Dashboard</span>
            </a>
          </router-link>
          <!-- <li
            class="nav-small-cap"
            v-if="$User.hasPermission('transaction-view') || $User.hasPermission('coupon-view')"
          >ADMINISTRATION</li>
          -->
          <!-- <router-link
            v-if="$User.hasPermission('transaction-view')"
            id="vforms-tour"
            tag="li"
            :to="{ name: 'AdminTransactionView' }"
            active-class="active"
          >
            <a>
              <i class="mdi mdi-chart-bubble"></i>
              <span class="hide-menu">Transactions</span>
            </a>
          </router-link> -->

          <router-link
            tag="li"
            :to="{ path: '/verifications/coupons' }"
            active-class="active"
            v-if="$User.hasPermission('coupon-view')"
          >
            <a>
              <i class="mdi mdi-ticket-percent"></i>
              <span class="hide-menu">Coupon</span>
            </a>
          </router-link>
          <li
            class="nav-small-cap"
            v-if="
              $User.hasPermission('report-view') ||
                $User.hasPermission('address-report-view') ||
                $User.hasPermission('verification-link-view')
            "
          >
            VERIFICATIONS
          </li>
          <!-- <li
            class="nav-small-cap"
            v-if="
              $User.hasPermission('report-view') ||
                $User.hasPermission('address-report-view') ||
                $User.hasPermission('verification-link-view') ||
                $User.hasPermission('coupon-view') ||
                $User.hasPermission('verify-identity-nin') ||
                $User.hasPermission('verify-identity-ibvn') ||
                $User.hasPermission('verify-identity-frsc') ||
                $User.hasPermission('identity-view')
            "
          >
            VERIFICATIONS
          </li> -->
          <router-link
            v-if="$User.hasPermission('report-view')"
            tag="li"
            id="tour-report-view"
            :to="{ name: 'ReportsView' }"
            active-class="active"
          >
            <a>
              <i class="mdi mdi-file-chart"></i>
              <span class="hide-menu">Identity</span>
            </a>
          </router-link>
          <!-- <router-link
            v-if="
              $User.hasPermission('verify-identity-nin') ||
                $User.hasPermission('verify-identity-ibvn') ||
                $User.hasPermission('verify-identity-frsc') ||
                $User.hasPermission('identity-view')
            "
            tag="li"
            id="tour-identity-view"
            :to="{ name: 'IdentityContainer' }"
            active-class="active"
          >

            <a>
              <i class="mdi mdi-account-check"></i>
              <span class="hide-menu">Identity</span>
            </a>
          </router-link> -->
          <!--          <router-link-->
          <!--            v-if="$User.hasPermission('verification-link-view')"-->
          <!--            id="tour-generate-links-view"-->
          <!--            tag="li"-->
          <!--            :to="{ name: 'GeneratedLinksView' }"-->
          <!--            active-class="active"-->
          <!--          >-->
          <!--            <a>-->
          <!--              <i class="mdi mdi-link-variant"></i>-->
          <!--              <span class="hide-menu">Generate Link</span>-->
          <!--            </a>-->
          <!--          </router-link>-->
          <!--          <router-link-->
          <!--            v-if="$User.hasPermission('coupon-view')"-->
          <!--            id="tour-coupon-view"-->
          <!--            tag="li"-->
          <!--            :to="{ name: 'CouponView' }"-->
          <!--            active-class="active"-->
          <!--          >-->
          <!--            <a>-->
          <!--              <i class="mdi mdi-ticket-percent"></i>-->
          <!--              <span class="hide-menu">Coupons</span>-->
          <!--            </a>-->
          <!--          </router-link>-->
          <router-link
            v-if="$User.hasPermission('address-report-view')"
            id="tour-address-view"
            tag="li"
            :to="{ name: 'AddressView' }"
            active-class="active"
          >
            <a>
              <i class="mdi mdi-home"></i>
              <span class="hide-menu">Addresses</span>
            </a>
          </router-link>
          <li
            class="nav-small-cap"
            v-if="
              $User.hasPermission('agent-view') ||
                $User.hasPermission('user-view')
            "
          >
            ECOSYSTEM
          </li>
          <!--          Used to have v-if="$User.hasPermission('permit-create')"-->
          <router-link
            id="tour-permit-view"
            tag="li"
            :to="{ name: 'PermitsView' }"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-clipboard-account"></i>
              <span class="hide-menu">Permits</span>
            </a>
          </router-link>
          <router-link
            v-if="$User.hasPermission('agent-view')"
            id="tour-agent-view"
            tag="li"
            :to="{ name: 'AgentsView' }"
            active-class="active"
          >
            <a aria-expanded="false">
              <i class="mdi mdi-account-tie"></i>
              <span class="hide-menu">Agents</span>
            </a>
          </router-link>
          <router-link
            v-if="$User.hasPermission('user-view')"
            id="tour-user-view"
            tag="li"
            :to="{ name: 'UsersView' }"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-account-group"></i>
              <span class="hide-menu">Users</span>
            </a>
          </router-link>

          <router-link
            v-if="$User.hasPermission('user-view') && userType !== 'company'"
            id="tour-user-view"
            tag="li"
            :to="{ name: 'CompaniesView' }"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-domain"></i>
              <span class="hide-menu">Companies</span>
            </a>
          </router-link>
          <li class="nav-small-cap">ACCOUNT</li>
          <router-link
            id="tour-profile-view"
            tag="li"
            :to="{ name: 'ProfileView' }"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-account-circle"></i>
              <span class="hide-menu">Profile</span>
            </a>
          </router-link>
          <router-link
            v-if="$User.hasPermission('transaction-view')"
            id="tour-transaction-view"
            tag="li"
            :to="{ name: 'TransactionLogView' }"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-cash-usd"></i>
              <span class="hide-menu">Transactions</span>
            </a>
          </router-link>
          <li class="nav-devider"></li>
          <template v-if="$User.hasPermission('audit-log-view')">
            <router-link
              tag="li"
              :to="{ name: 'AuditView' }"
              active-class="active"
              id="tour-audit-view"
            >
              <a href="#" aria-expanded="false">
                <i class="mdi mdi-file-chart"></i>
                <span class="hide-menu">Audit Log</span>
              </a>
            </router-link>
            <li class="nav-devider"></li>
          </template>
          <!-- <router-link
            id="tour-faq-view"
            :to="{ name: 'FAQView' }"
            tag="li"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-comment-question"></i>
              <span class="hide-menu">FAQ</span>
            </a>
          </router-link> -->
          <!--          v-if="$User.hasPermission('agent-license-print')"-->
          <router-link
            v-if="$User.hasPermission('agent-license-print')"
            id="tour-license-print-view"
            tag="li"
            :to="{ name: 'AgentsLicensePrintView' }"
            active-class="active"
          >
            <a aria-expanded="false">
              <i class="mdi mdi-printer"></i>
              <span class="hide-menu">Print License ID</span>
            </a>
          </router-link>
          <router-link
            v-if="$User.hasPermission('report-view')"
            id="tour-transaction-view"
            tag="li"
            :to="{ name: 'Report' }"
            active-class="active"
          >
            <a href="#" aria-expanded="false">
              <i>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M5.625 10.125H10.125V11.25H5.625V10.125Z"
                      fill="white"
                    />
                    <path
                      d="M5.625 7.3125H12.375V8.4375H5.625V7.3125Z"
                      fill="white"
                    />
                    <path
                      d="M5.625 12.9375H8.4375V14.0625H5.625V12.9375Z"
                      fill="white"
                    />
                    <path
                      d="M14.0625 2.8125H12.375V2.25C12.375 1.95163 12.2565 1.66548 12.0455 1.4545C11.8345 1.24353 11.5484 1.125 11.25 1.125H6.75C6.45163 1.125 6.16548 1.24353 5.9545 1.4545C5.74353 1.66548 5.625 1.95163 5.625 2.25V2.8125H3.9375C3.63913 2.8125 3.35298 2.93103 3.142 3.142C2.93103 3.35298 2.8125 3.63913 2.8125 3.9375V15.75C2.8125 16.0484 2.93103 16.3345 3.142 16.5455C3.35298 16.7565 3.63913 16.875 3.9375 16.875H14.0625C14.3609 16.875 14.647 16.7565 14.858 16.5455C15.069 16.3345 15.1875 16.0484 15.1875 15.75V3.9375C15.1875 3.63913 15.069 3.35298 14.858 3.142C14.647 2.93103 14.3609 2.8125 14.0625 2.8125ZM6.75 2.25H11.25V4.5H6.75V2.25ZM14.0625 15.75H3.9375V3.9375H5.625V5.625H12.375V3.9375H14.0625V15.75Z"
                      fill="white"
                    />
                    <rect
                      x="-306"
                      y="-687.938"
                      width="3394.69"
                      height="1268.44"
                      stroke="#7000FF"
                      stroke-width="33.75"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="18" height="18" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </i>
              <span class="hide-menu">Report</span>
            </a>
          </router-link>
          <router-link
            id="tour-setting-view"
            :to="{ name: 'SettingsView' }"
            v-if="$User.hasPermission('setting-view')"
            tag="li"
            active-class="active"
            style="padding-bottom: 70px;"
          >
            <a href="#" aria-expanded="false">
              <i class="mdi mdi-cog"></i>
              <span class="hide-menu">Settings</span>
            </a>
          </router-link>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
    <!-- Bottom points-->
    <div class="sidebar-footer transition" v-if="isPageContentReady">
      <!-- item-->
      <router-link
        :to="{ name: 'ProfileView' }"
        active-class="active"
        class="link"
        data-toggle="tooltip"
        title="User Profile"
      >
        <i class="mdi mdi-account-circle"></i>
      </router-link>
      <!-- item -->
      <router-link
        v-if="$User.hasPermission('business-manage')"
        :to="{ name: 'BusinessProfileView' }"
        active-class="active"
        class="link"
        data-toggle="tooltip"
        title="Business Profile"
      >
        <i class="mdi mdi-domain"></i>
      </router-link>
      <!-- item -->
      <router-link
        :to="{ name: 'LogoutView' }"
        active-class="active"
        class="link"
        data-toggle="tooltip"
        title="Logout"
      >
        <i class="mdi mdi-power"></i>
      </router-link>
    </div>
    <!-- End Bottom points-->
    <div
      style="max-height: 800px; text-align: center; padding: 15px;"
      v-if="!isPageContentReady"
    >
      <content-loader
        style="width:100%;"
        :height="800"
        :width="240"
        :speed="2"
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
      >
        <circle cx="15" cy="20" r="12" />
        <rect x="40" y="10" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="50" r="12" />
        <rect x="40" y="40" rx="5" ry="5" width="200" height="20" />

        <!-- <circle cx="15" cy="80" r="12" />
        <rect x="40" y="70" rx="5" ry="5" width="200" height="20" />-->
        <circle cx="15" cy="110" r="12" />
        <rect x="40" y="100" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="140" r="12" />
        <rect x="40" y="130" rx="5" ry="5" width="200" height="20" />

        <!-- <circle cx="15" cy="170" r="12" />
        <rect x="40" y="160" rx="5" ry="5" width="200" height="20" />-->
        <circle cx="15" cy="200" r="12" />
        <rect x="40" y="190" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="230" r="12" />
        <rect x="40" y="220" rx="5" ry="5" width="200" height="20" />

        <!-- <circle cx="15" cy="260" r="12" />
        <rect x="40" y="250" rx="5" ry="5" width="200" height="20" />-->
        <circle cx="15" cy="290" r="12" />
        <rect x="40" y="280" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="320" r="12" />
        <rect x="40" y="310" rx="5" ry="5" width="200" height="20" />

        <!-- <circle cx="15" cy="350" r="12" />
        <rect x="40" y="340" rx="5" ry="5" width="200" height="20" />-->
        <circle cx="15" cy="380" r="12" />
        <rect x="40" y="370" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="410" r="12" />
        <rect x="40" y="400" rx="5" ry="5" width="200" height="20" />

        <!-- <circle cx="15" cy="440" r="12" />
        <rect x="40" y="430" rx="5" ry="5" width="200" height="20" />-->
        <circle cx="15" cy="470" r="12" />
        <rect x="40" y="460" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="500" r="12" />
        <rect x="40" y="490" rx="5" ry="5" width="200" height="20" />

        <!-- <circle cx="15" cy="530" r="12" />
        <rect x="40" y="520" rx="5" ry="5" width="200" height="20" />-->
        <circle cx="15" cy="560" r="12" />
        <rect x="40" y="550" rx="5" ry="5" width="200" height="20" />

        <circle cx="15" cy="590" r="12" />
        <rect x="40" y="580" rx="5" ry="5" width="200" height="20" />
      </content-loader>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import { ContentLoader } from "vue-content-loader";
export default {
  name: "app-left-sidebar",
  components: {
    ContentLoader
  },
  updated() {
    let set = function() {
      var width = window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      var topOffset = 70;
      if (width < 1170) {
        $("body").addClass("mini-sidebar");
        $(".navbar-brand span").hide();
        $(".scroll-sidebar, .slimScrollDiv")
          .css("overflow-x", "visible")
          .parent()
          .css("overflow", "visible");
        $(".sidebartoggler i").addClass("mdi-menu");
      } else {
        $("body").removeClass("mini-sidebar");
        $(".navbar-brand span").show();
        $(".sidebartoggler i").removeClass("mdi-menu");
      }
      var height =
        (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;
      height = height - topOffset;
      if (height < 1) height = 1;
      if (height > topOffset) {
        $(".page-wrapper").css("min-height", height + "px");
      }
    };
    // $(window).ready(set);
    set();
    $(window).on("resize", set);
    // initialize metismenu
    $("#sidebarnav").metisMenu();
    // Sidebar Slimscrollbars
    // ==============================================================
    $(".scroll-sidebar").slimScroll({
      position: "left",
      size: "3px",
      height: "100%",
      color: "rgba(0,0,0,0.5)"
    });
  },
  mounted() {
    console.log(this.current_user);
    let set = function() {
      var width = window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      var topOffset = 70;
      if (width < 1170) {
        $("body").addClass("mini-sidebar");
        $(".navbar-brand span").hide();
        $(".scroll-sidebar, .slimScrollDiv")
          .css("overflow-x", "visible")
          .parent()
          .css("overflow", "visible");
        $(".sidebartoggler i").addClass("mdi-menu");
      } else {
        $("body").removeClass("mini-sidebar");
        $(".navbar-brand span").show();
        $(".sidebartoggler i").removeClass("mdi-menu");
      }
      var height =
        (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;
      height = height - topOffset;
      if (height < 1) height = 1;
      if (height > topOffset) {
        $(".page-wrapper").css("min-height", height + "px");
      }
    };
    // $(window).ready(set);
    set();
    $(window).on("resize", set);
    // initialize metismenu
    $("#sidebarnav").metisMenu();
    // Sidebar Slimscrollbars
    // ==============================================================
    $(".scroll-sidebar").slimScroll({
      position: "left",
      size: "3px",
      height: "100%",
      color: "rgba(0,0,0,0.5)"
    });
  },
  computed: {
    ...mapGetters({
      current_user: "getCurrentUser",
      isPageContentReady: "isPageContentReady"
    }),

    userType() {
      return this.current_user && this.current_user.user_type;
    }
  }
};
</script>
