<template>
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- App Title -->
    <!-- ============================================================== -->
    <app-title title="Identity" />
    <identity-report-component></identity-report-component>
  </div>
</template>

<script>
import AppTitle from "@/components/layouts/AppTitle";
import notify from "@/helpers/notifyMe";
import * as types from "@/store/types";
import store from "@/store";
import IdentityReportComponent from "./IdentityReportComponent";

export default {
  name: "IdentityReport",
  components: {
    AppTitle,
    IdentityReportComponent
  },
  async beforeRouteEnter(to, from, next) {
    try {
      await store.dispatch(types.GET_IDENTITY_REPORT_ACTION, {
        report_id: to.params.report_id
      });
      next();
    } catch (error) {
      error && error.data && notify.error({ message: error.data.message });
      next(false);
      return;
    }
  }
};
</script>
