import * as types from "@/store/types";
import api from "../_api";
const actions = {
  async [types.UPDATE_BUSINESS_PROFILE_ACTION]({ commit }, payload) {
    let business_profile = (await api.updateBusinessProfile(payload)).data;
    commit(types.SET_BUSINESS_PROFILE, business_profile);
    return business_profile;
  },
  async [types.GET_SUPER_AGENT_SETTINGS](_, payload) {
    const response = await api.fetchSuperAgentSettings(payload);
    // commit(types.SET_SUPER_AGENT_SETTINGS, response.data);
    return response.data;
  },
  async [types.UPDATE_SUPER_AGENTS_SETTINGS](_, payload) {
    const response = await api.updateSuperAgentSettings(payload);
    // commit(types.SET_SUPER_AGENT_SETTINGS, response.data);
    return response.data;
  }
};

export default actions;
