import * as types from "@/store/types";
import api from "../_api";
import UploadService from "@/services/upload";

const actions = {
  async [types.GENERATE_LINKS_ACTION](_, payload) {
    let response = (await api.generateLinks(payload)).data;
    return response;
  },
  async [types.UPLOAD_CSV](_, payload) {
    let response = (await UploadService.uploadCsv(payload)).data;
    return response;
  },
  async [types.GET_GENERATED_LINKS_ACTION]({ commit }, payload) {
    let generated_links = await api.getGeneratedLinks(payload);
    commit(types.SET_GENERATED_LINKS_LOG, generated_links.data);
    commit(types.SET_GENERATED_LINKS_META, generated_links.meta);
    return generated_links;
  },
  async [types.DOWNLOAD_GENERATED_LINKS_ACTION](_, generated_link) {
    let response = (await api.downloadGeneratedLinks(generated_link)).data;
    return response;
  }
};
export default actions;
